import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';
// import { ModalDialog } from '../../components/ModalDialog';
import { Interpolate } from 'react-i18next';
import Checkbox from 'material-ui/Checkbox';
import TextField from '../../components/textfields/TextField';
import { validate, maxValue, minValue } from "../../components/validation";
import { OPT_IN_STATES } from '../../util/Constants.js';

const styles = {
  button: {
    //padding: '5px',
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: '8px',
    color: '#FFFFFF'
  },
  rowWithMargin: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: '8px',
    marginBottom: '24px',
    color: '#FFFFFF'
  },
  licenseTextInput: {
    width: '180px',
    marginLeft: '16px',
    marginRight: '16px'
  },
  licensePriceText: {
    color: '#00C1A7'
  },
  licenseTaxText: {
    marginLeft: '6px',
    fontSize: '14px'
  },
  whiteText: {
    fontSize: '16px',
    fontWeight: 'regular',
    color: '#FFFFFF',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  greenText: {
    color: '#00C1A7',
    marginLeft: '6px',
  }

};

const initState = {
  open: false,
  licenseCount: 0,
  licenseCountText: "",
  licenseCountErrorText: "",
  agbCheck: false,
  privacyCheck: false,
  optInStateCheck: false,
  openData: false
};

const CHECKBOX_AGB = "checkboxAgb";
const CHECKBOX_PRIVACY = "checkboxPrivacy";
const CHECKBOX_OPT_IN_STATE = "checkboxOptInState";

class OrderLicenseDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;

    this.showDataDetails = this.showDataDetails.bind(this);
  }

  open(parameter) {

    let buttonOrderHandler = null;
    let price = null;
    let dpagAccount = false;

    if (typeof parameter.buttonOrderHandler === "function")
    {
      buttonOrderHandler = parameter.buttonOrderHandler;
    }

    if (typeof parameter.price === "string")
    {
      price = parameter.price;
    }

    if (typeof parameter.optInState === "string")
    {
      dpagAccount = parameter.optInState === OPT_IN_STATES.NOT_SET;
    }

    this.setState({
      buttonOrderHandler: buttonOrderHandler,
      price: price,
      open: true,
      dpagAccount: dpagAccount,
      openData: false
    });
  }

  handleCancelButton() {
    this.setState(initState);
  }

  handleCancelDataDialogButton() {
    this.setState({
      openData: false
    });
  }

  handleOrderButton() {
    let optInState = this.state.optInStateCheck ? OPT_IN_STATES.ACCEPTED : OPT_IN_STATES.NOT_SET;

    if (typeof this.state.buttonOrderHandler === "function")
    {
      this.state.buttonOrderHandler(this.state.licenseCount, optInState);
    }

    this.setState(initState);
  }

  handleChanged(event, newValue: string) {
    const {t} = this.props;
    //validation von ivo nutzen
    let validationErrors = validate(newValue, [minValue(1, {
      t
    }), maxValue(500, {
      t
    })]);

    let licenseCountErrorText = "";
    let licenseCount = 0;
    if (validationErrors.length > 0)
    {
      licenseCountErrorText = validationErrors[0];
    }
    else
    {
      licenseCount = newValue;
    }

    let filteredValue = newValue;

    if (typeof filteredValue === "string")
    {
      var matches = filteredValue.match(/[0-9]*/i)
      if (matches.length === 1)
      {
        filteredValue = matches[0]
      }
    }

    this.setState({
      licenseCountErrorText: licenseCountErrorText,
      licenseCount: licenseCount,
      licenseCountText: filteredValue
    });
  }

  onCheck(event, isInputChecked, checkboxId) {
    if (checkboxId === CHECKBOX_AGB)
    {
      this.setState({
        agbCheck: isInputChecked
      });
    }
    else if (checkboxId === CHECKBOX_PRIVACY)
    {
      this.setState({
        privacyCheck: isInputChecked
      });
    }
    else if (checkboxId === CHECKBOX_OPT_IN_STATE)
    {
      this.setState({
        optInStateCheck: isInputChecked
      });
    }

  }

  getPriceText() {
    const {t} = this.props;

    if (!this.state.price)
    {
      return "";
    }

    if (this.state.price.lastIndexOf(".00") === (this.state.price.length - 3))
    {
      return this.state.price.substr(0, this.state.price.length - 3) + t("settings-order-in-year");
    }
    else
    {
      return this.state.price + t("settings-order-in-year");
    }
  }

  isOrderButtonDisabled() {
    if (this.state.dpagAccount)
    {
      return !(this.state.optInStateCheck && this.state.licenseCount > 0 && this.state.licenseCount < 501);
    }

    return !(this.state.agbCheck && this.state.privacyCheck && this.state.licenseCount > 0 && this.state.licenseCount < 501);
  }

  render() {
    if (this.state.openData)
    {
      return (
        <div>
          { this.renderDataDialog() }
        </div>);
    }
    else
    {
      if (this.state.dpagAccount)
      {
        return (
          <div>
            { this.renderDPAGOrderLicenceDialog() }
          </div>);
      }
      else
      {
        return (
          <div>
            { this.renderBrabblerOrderLicenceDialog() }
          </div>);
      }
    }
  }

  showDataDetails() {
    // const {t} = this.props;

    this.setState({
      openData: true
    });
  }

  renderDataDialog() {
    const {t} = this.props;

    var actions = [
      <RaisedButton style={ Object.assign({}, styles.button, {
                        width: 'inherit'
                      }) } label={ t('modules-license-DPAGOrderLicenseDialog-data-details-close-button-title') } primary={ true } onTouchTap={ () => this.handleCancelDataDialogButton() } />
    ];

    let dataTransitionLink = <a href={ t('modules-license-DPAGOrderLicenseDialog-data-transition-link-url') } target="_blank" rel="noopener">
                               { t('modules-license-DPAGOrderLicenseDialog-data-transition-link-text') }
                             </a>;

    let dataTransitionLinkText = <Interpolate i18nKey='modules-license-DPAGOrderLicenseDialog-data-text4' v1={ dataTransitionLink } />;


    return (
      <div>
        <Dialog title="" actions={ actions } modal={ true } open={ this.state.openData }>
          <div style={ styles.rowWithMargin }>
            { t('modules-license-DPAGOrderLicenseDialog-data-text1') }
          </div>
          <div style={ styles.rowWithMargin }>
            { t('modules-license-DPAGOrderLicenseDialog-data-text2') }
          </div>
          <div style={ styles.rowWithMargin }>
            { t('modules-license-DPAGOrderLicenseDialog-data-text3') }
          </div>
          <div style={ styles.rowWithMargin }>
            { dataTransitionLinkText }
          </div>
        </Dialog>
      </div>
      );
  }

  renderDPAGOrderLicenceDialog() {
    const {t} = this.props;

    var actions = [
      <FlatButton style={ Object.assign({}, styles.button, {
                      position: 'absolute',
                      display: 'flex'
                    }) } label={ t('cancel') } onTouchTap={ () => this.handleCancelButton() } />,
      <RaisedButton style={ styles.button } label={ t('settings-orderhistory-order') } primary={ true } onTouchTap={ () => this.handleOrderButton() } disabled={ this.isOrderButtonDisabled() } />,
    ];

    let agb = <a href={ t('modules-license-OrderLicenseDialog-agb-link-url') } target="_blank" rel="noopener">
                { t('settings-orderhistory-agb-link') }
              </a>;

    let privacy = <a href={ t('modules-license-OrderLicenseDialog-privacy-link-url') } target="_blank" rel="noopener">
                    { t('modules-license-DPAGOrderLicenseDialog-privacy-link') }
                  </a>;

    let termsAndCondAndPrivacyText = <Interpolate i18nKey='modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy' v1={ agb } v2={ privacy } />;

    let dataDetails = <a href="" onClick={ this.showDataDetails }>
                        { t('modules-license-DPAGOrderLicenseDialog-data-link') }
                      </a>

    let optInCheckboxText = <Interpolate i18nKey='modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text' v1={ dataDetails } />;

    let priceText = <span className="regular" style={ styles.greenText }>{ this.getPriceText() }</span>;
    let taxInfo = <span className="light" style={ styles.licenseTaxText }>{ t('settings-order-tax-info') }</span>;

    let priceAndTaxInfo = <Interpolate i18nKey='modules-license-DPAGOrderLicenseDialog-order2' priceText={ priceText } taxInfo={ taxInfo } />;

    return (
      <div>
        <Dialog contentStyle={ { minWidth: '620px' } } title={ t('modules-license-License-btn-new') } actions={ actions } modal={ true } open={ this.state.open }>
          <div style={ styles.row }>
            { t('modules-license-DPAGOrderLicenseDialog-text1') }
          </div>
          <div style={ styles.rowWithMargin }>
            <span className="regular" style={ styles.whiteText }>{ t('modules-license-DPAGOrderLicenseDialog-order1') }</span>
            <TextField style={ styles.licenseTextInput } hintText={ t('settings-order-input-hint') } onChange={ (event, newValue) => this.handleChanged(event, newValue) } errorText={ this.state.licenseCountErrorText } value={ this.state.licenseCountText }
            />
            { priceAndTaxInfo }
          </div>
          <div style={ styles.row }>
            <Checkbox style={ { width: "auto" } } defaultChecked={ this.state.optInStateCheck } onCheck={ (event, isInputChecked) => this.onCheck(event, isInputChecked, CHECKBOX_OPT_IN_STATE) } />
            { optInCheckboxText }
          </div>
          <div style={ { height: '16px' } } />
          <div style={ styles.row }>
            { termsAndCondAndPrivacyText }
          </div>
        </Dialog>
      </div>
      );
  }

  renderBrabblerOrderLicenceDialog() {
    const {t} = this.props;

    var actions = [
      <FlatButton style={ styles.button } label={ t('cancel') } onTouchTap={ () => this.handleCancelButton() } />,
      <RaisedButton style={ styles.button } label={ t('settings-orderhistory-order') } primary={ true } onTouchTap={ () => this.handleOrderButton() } disabled={ this.isOrderButtonDisabled() } />,
    ];

    let agb = <a href={ t('modules-license-OrderLicenseDialog-agb-link-url') } target="_blank" rel="noopener">
                { t('settings-orderhistory-agb-link') }
              </a>;

    let agbText = <Interpolate i18nKey='settings-orderhistory-agb' v1={ agb } />;

    let privacy = <a href={ t('modules-license-OrderLicenseDialog-privacy-link-url') } target="_blank" rel="noopener">
                    { t('settings-orderhistory-privacy-link') }
                  </a>;

    let privacyText = <Interpolate i18nKey='settings-orderhistory-privacy' v1={ privacy } />;

    let priceText = <span className="regular" style={ styles.licensePriceText }>{ this.getPriceText() }</span>
    let taxInfo = <span className="light" style={ styles.licenseTaxText }>{ t('settings-order-tax-info') }</span>
    let priceAndTaxInfo = <Interpolate i18nKey='modules-license-DPAGOrderLicenseDialog-order2' priceText={ priceText } taxInfo={ taxInfo } />;

    return (
      <div>
        <Dialog title={ t('modules-license-License-btn-new') } actions={ actions } modal={ true } open={ this.state.open }>
          <div style={ styles.rowWithMargin }>
            <TextField style={ styles.licenseTextInput } hintText={ t('settings-order-input-hint') } onChange={ (event, newValue) => this.handleChanged(event, newValue) } errorText={ this.state.licenseCountErrorText } value={ this.state.licenseCountText } />
            { priceAndTaxInfo }
          </div>
          <div style={ styles.row }>
            <Checkbox style={ { width: "auto" } } onCheck={ (event, isInputChecked) => this.onCheck(event, isInputChecked, CHECKBOX_AGB) } />
            { agbText }
          </div>
          <div style={ styles.row }>
            <Checkbox style={ { width: "auto" } } onCheck={ (event, isInputChecked) => this.onCheck(event, isInputChecked, CHECKBOX_PRIVACY) } />
            { privacyText }
          </div>
        </Dialog>
      </div>
      );
  }

}

export default OrderLicenseDialog;
