const TranslationCommonDe ={
    "SERVICE-ADMIN-ERR-0019": "Verbindung zu ginlo Business fehlgeschlagen",
    "SERVICE-ADMIN-ERR-0022": "Ungültige Eingabe",
    "SERVICE-ADMIN-ERR-0098": "Die Mobilnummer ist ungültig. Geben Sie Mobilnummern mit Vorwahl und Nummer im Format \"012345678910\" ein. Für eine Mobilnummer außerhalb Deutschlands ergänzen Sie bitte noch die Ländervorwahl z. B. \"+31...\".",
    "SERVICE-ADMIN-ERR-0106": "Die Anmeldedaten sind nicht korrekt.",
    "SERVICE-ADMIN-ERR-0106-1": "Das Passwort ist nicht korrekt.",
    "SERVICE-ADMIN-ERR-0109": "Die Sitzung ist abgelaufen.",
    "SERVICE-ADMIN-ERR-0110": "Das Bild konnte nicht geladen werden.",
    "SERVICE-ADMIN-ERR-0111": "Das Bild ist zu groß. Die maximal erlaubte Auflösung beträgt 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "Die Telefonnummer wird bereits verwendet.",
    "SERVICE-ADMIN-ERR-0114": "Die E-Mail-Adresse wird bereits verwendet.",
    "SERVICE-ADMIN-ERR-0115": "Das Format der .csv Datei ist ungültig.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "Das Format der .csv Datei ist ungültig. Spalte Vorname ist nicht ausgefüllt.",
    "SERVICE-ADMIN-ERR-0115-NAME": "Das Format der .csv Datei ist ungültig. Spalte Name ist nicht ausgefüllt.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "Das Format der .csv Datei ist ungültig. Spalte Mobilnummer ist nicht ausgefüllt.",
    "SERVICE-ADMIN-ERR-0135": "Das Bild ist zu klein. Die minimal erforderliche Auflösung beträgt 680 x 80 Pixel.",
    "SERVICE-ADMIN-ERR-0153": "Es sind nicht genügend Lizenzen für diese Aktion vorhanden. Sie haben die Möglichkeit, weitere Lizenzen in der Lizenzverwaltung zu erwerben.",
    "SERVICE-ADMIN-ERR-0154": "Der Stellvertreter hat für diese Aktion keine Berechtigung.",
    "SERVICE-ADMIN-ERR-0156": "Es ist bereits ein anderer Administrator am System angemeldet. Um Konflikte zu vermeiden, ist die Anmeldung von mehr als 1 Stellvertreter nicht erlaubt.",
    "SERVICE-ADMIN-ERR-0157": "Der Administrator wurde gelöscht.",
    "SERVICE-ADMIN-ERR-0158": "Der Administrator wurde deaktiviert.",
    "SERVICE-ADMIN-ERR-0176": "Das Laden der Vorschau für den RSS Feed ist fehlgeschlagen.",
    "SERVICE-ADMIN-ERR-0177": "Die API wurde durch einen anderen Nutzer deaktiviert.",
    "SERVICE-ADMIN-ERR-0181": "Die Daten wurden durch den Nutzer {{username}} am {{date}} geändert.",
    "SERVICE-ADMIN-ERR-0182": "Die Daten wurden durch den Nutzer {{username}} am {{date}} gelöscht.",
    "SERVICE-ADMIN-ERR-0183": "Die API ist bereits aktiviert.",
    "SERVICE-ERR-0057": "Zeitüberschreitung. Sie wurden automatisch abgemeldet.",
    "SERVICE-ERR-0123": "Die E-Mail-Adresse ist ungültig.",
    "SERVICE-ERR-0124": "Die angegebene Domain wird von einem privaten E-Mail-Provider betrieben und wird aus Datenschutzgründen nicht unterstützt.",
    "SERVICE-IMP-1": "Diese Datei ist zu groß.",
    "SERVICE-IMP-23": "Falsches Dateiformat.",
    "SERVICE-IMP-30": "Das Format der Importdatei ist fehlerhaft (bspw. falsche Spaltenanzahl).",
    "SERVICE-IMP-35": "Ein verwendeter Gruppenname ist mehrfach im ginlo Business Cockpit vorhanden.",
    "SERVICE-IMP-36": "Ein verwendeter Kanal-Name ist mehrfach im ginlo Business Cockpit vorhanden.",
    "SERVICE-NO_SERVER_RESPONSE": "Keine Verbindung zum Server.",
    "SERVICE-RSSTRANS-CLI-0001": "Der RSS Feed unter der angeforderten URL ist nicht erreichbar. Ggf. ist die angegebene URL durch die Firewall-Einstellungen blockiert.",
    "SERVICE-RSSTRANS-ERR-0007": "Der Inhalt unter der angegebenen URL entspricht nicht den RSS 2.0 Spezifikationen. Es wurden ungültige Zeichen gefunden.",
    "SERVICE-RSSTRANS-ERR-0008": "Der Inhalt unter der angegebenen URL entspricht nicht den RSS 2.0 Spezifikationen. Es sind nicht alle Pflichtfelder vorhanden.",
    "SERVICE-RSSTRANS-ERR-0011": "Unter der angegebenen URL wurde kein valider RSS Feed gefunden.",
    "SERVICE-RSSTRANS-ERR-0012": "Beim Laden der Vorschau für den RSS Feed ist ein Fehler aufgetreten. Die angeforderte URL ist nicht verfügbar.",
    "SERVICE-RSSTRANS-ERR-0014": "Unter der angegebenen URL wurde ein Atom Feed gefunden. Dieses Format wird nicht unterstützt. Bitte stellen Sie sicher, dass es sich um einen RSS 2.0 konformen RSS Feed handelt.",
    "SERVICE-RSSTRANS-ERR-0015": "Unter der angegebenen URL wurde kein Inhalt gefunden.",
    "SERVICE-RSSTRANS-ERR-0016": "Das Format des angegebenen RSS Feeds wird nicht unterstützt.",
    "SERVICE-RSSTRANS-ERR-0017": "Der angegebene RSS Feed enthält ungültige, nicht zulässige Zeichen.",
    "cancel": "Abbrechen",
    "common-delete-btn": "Löschen",
    "common-edit-btn": "Bearbeiten",
    "components-ModalDialog-Warning": "Hinweis",
    "components-ModalDialog-cancel": "Abbrechen",
    "components-ModalDialog-looseDataQuestion": "Wenn Sie abbrechen, werden Ihre Änderungen nicht gespeichert. Abbrechen?",
    "components-ModalDialog-no": "Nein",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Ja",
    "components-NavigationLeft-appdesign": "App Design",
    "components-NavigationLeft-appsettings": "App Settings",
    "components-NavigationLeft-channel": "Kanäle",
    "components-NavigationLeft-dashboard": "Dashboard",
    "components-NavigationLeft-groups": "Gruppen",
    "components-NavigationLeft-license": "Lizenzen",
    "components-NavigationLeft-roles": "Nutzerrollen",
    "components-NavigationLeft-user": "Nutzer",
    "components-chatmessagetextfield-default-hint": "Nachrichtentext eingeben",
    "components-chatmessagetextfield-preview-time-placeholder": "Jetzt",
    "components-continue": "Fortsetzen",
    "components-messagecomposer-datepicker": "Datum wählen",
    "components-messagecomposer-messagesendtime-delayed": "Zum Zeitpunkt",
    "components-messagecomposer-messagesendtime-now": "Sofort versenden",
    "components-messagecomposer-send": "Senden",
    "components-messagecomposer-send-AttachmentTooBig": "Diese Datei ist zu groß.",
    "components-messagecomposer-timepicker": "Zeit wählen",
    "components-messagecomposer-uploadattachment": "Bild oder Datei wählen",
    "components-messageeditor-button-delete": "Nachrichten löschen",
    "components-messageeditor-button-showolder": "Ältere anzeigen",
    "components-messageeditor-confirmdeletemessage": "Es werden alle Nachrichten in diesem Kanal gelöscht. Löschen durchführen?",
    "components-messageeditor-header-messages": "Nachrichten in diesem Kanal",
    "components-messageeditor-header-newmessage": "Neue Nachricht",
    "components-messageeditor-header-timedmessages": "Zeitverzögert",
    "components-messageeditor-rss-linkmore": "mehr…",
    "components-messageeditor-type-audio": "Audio",
    "components-messageeditor-type-location": "Standort",
    "components-messageeditor-type-video": "Video",
    "components-reload-data": "Neu laden",
    "email": "E-Mail",
    "err-empty-textfield": "Das Feld darf nicht leer sein.",
    "error": "Fehler",
    "firstName": "Vorname",
    "footer-link-details": "ginlo Handbuch",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/de/",
    "footer-link-faq": "FAQ",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/de/faq/",
    "footer-link-impress": "Impressum",
    "footer-link-impress-url": "https://app-help.ginlo.net/de/imprint",
    "footer-link-press": "Presse",
    "footer-link-press-url": "https://www.ginlo.net/de/about-us/",
    "footer-link-privacy": "Datenschutzhinweise",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/de/privacy",
    "footer-link-security": "ginlo Data",
    "footer-link-security-url": "https://www.ginlo.net/de/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Business",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/de/ginlo-business/",
    "footer-link-support": "Support",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "AGB",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/de/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "EN",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Passwort vergessen",
    "header-btn-fr": "FR",
    "header-btn-logout": "Logout",
    "header-btn-settings": "Einstellungen",
    "header-btn-support": "Support",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Beispielfirma",
    "lastName": "Nachname",
    "login-2fa-token-textfield-hint": "Zwei-Faktor-Token (falls aktiviert)",
    "login-email-textfield-hint": "E-Mail-Adresse",
    "login-forgot-pwd-button": "Festlegen",
    "login-forgot-pwd-desc": "Bitte geben Sie Ihr Recovery Passwort an.",
    "login-forgot-pwd-desc-min-char": "Mindestens 8 Zeichen",
    "login-forgot-pwd-desc-spez-char": "Mindestens eine Ziffer (0-9) oder Sonderzeichen",
    "login-forgot-pwd-desc-upper-char": "Klein- und Großbuchstaben (a-z,A-Z)",
    "login-forgot-pwd-err-empty": "Das Feld wird benötigt.",
    "login-forgot-pwd-err-not-equal": "Die Passwörter stimmen nicht überein.",
    "login-forgot-pwd-new-pwd-desc": "Bitte vergeben Sie ein neues Passwort.",
    "login-forgotPwd-button": "Passwort vergessen",
    "login-initial-pwd-button": "Speichern",
    "login-initial-pwd-new-pwd-desc": "Bitte vergeben Sie Ihr eigenes Passwort.",
    "login-login-button": "Login",
    "login-pwd-new-textfield-hint": "Neues Passwort eingeben",
    "login-pwd-repeat-textfield-hint": "Neues Passwort wiederholen",
    "login-pwd-textfield-hint": "Passwort eingeben",
    "login-remember-email": "E-Mail Adresse merken",
    "modules-appdesign-appdesign-btnResetLayout": "App Design zurücksetzen",
    "modules-appdesign-appdesign-btncancel": "Abbrechen",
    "modules-appdesign-appdesign-btnsave": "Speichern",
    "modules-appdesign-appdesign-header": "App Design",
    "modules-appdesign-colorpicker-actionelements": "Aktionselemente",
    "modules-appdesign-colorpicker-blue": "Blau",
    "modules-appdesign-colorpicker-color": "Farbe",
    "modules-appdesign-colorpicker-contrast": "Kontrast",
    "modules-appdesign-colorpicker-contrastdark": "Kontrastfarbe dunkel",
    "modules-appdesign-colorpicker-contrastlight": "Kontrastfarbe hell",
    "modules-appdesign-colorpicker-dark": "dunkel",
    "modules-appdesign-colorpicker-green": "Grün",
    "modules-appdesign-colorpicker-heavytrust": "Hoher Vertrauensstatus",
    "modules-appdesign-colorpicker-hex": "Hexadezimalwert",
    "modules-appdesign-colorpicker-light": "hell",
    "modules-appdesign-colorpicker-maincolor": "Hauptfarbe",
    "modules-appdesign-colorpicker-normaltrust": "Mittlerer Vertrauensstatus",
    "modules-appdesign-colorpicker-red": "Rot",
    "modules-appdesign-colorpicker-unsafetrust": "Unsicherer Vertrauensstatus",
    "modules-appdesign-colorpicker-yellow": "Blau",
    "modules-appdesign-cropimage-accept-btn": "Anwenden",
    "modules-appdesign-cropimage-title": "Bitte schneiden Sie Ihr Logo entsprechend zu.",
    "modules-appdesign-cropimage-zoom-hint": "* Mit dem Scroll-Rad der Maustaste können Sie den Ausschnitt vergrößern oder verkleinern.",
    "modules-appdesign-image-description": "*.png mit Alpha",
    "modules-appdesign-image-description2": "Seitenverhältnis 5:1",
    "modules-appdesign-mainView-description": "Passen Sie die App in nur wenigen Schritten an die Corporate Identity Ihres Unternehmens an.",
    "modules-appdesign-mainView-removeCompanyLogo": "Bild löschen",
    "modules-appdesign-mainView-subheading": "Passen Sie die App nach Ihren Wünschen an.",
    "modules-appdesign-mainView-uploadImage": "Bild wählen",
    "modules-appsettings-appsettings-btnResetConfig": "App Einstellungen zurücksetzen",
    "modules-appsettings-header": "App Einstellungen",
    "modules-appsettings-hinttext-range1": "0 bis 10 Minuten",
    "modules-appsettings-hinttext-range2": "0 bis 100 WIederholungen",
    "modules-appsettings-hinttext-range3": "0 bis 99 Zeichen",
    "modules-appsettings-hinttext-range4": "0 bis 365 Tage",
    "modules-appsettings-hinttext-range5": "0 bis 4 Klassen",
    "modules-appsettings-label-chars": "Zeichen",
    "modules-appsettings-label-days": "Tage",
    "modules-appsettings-label-minutes": "Min",
    "modules-appsettings-label-repeat": "Wdh.",
    "modules-appsettings-mainView-contactpolicy": "Kontakterichtlinien",
    "modules-appsettings-mainView-mediapolicy": "Chatrichtlinien",
    "modules-appsettings-mainView-pwcomplexity": "Passwortkomplexität",
    "modules-appsettings-mainView-pwpolicy": "Passwortrichtlinien",
    "modules-appsettings-selectfield-deleteaccount-inactive": "deaktivieren",
    "modules-appsettings-selectfield-deleteaccount-tries": "Versuche",
    "modules-appsettings-selectfield-deleteaccount-try": "Versuch",
    "modules-appsettings-table-companyindexname": "Name des Firmenverzeichnisses",
    "modules-appsettings-table-companyindexnamedescr": "Verändern Sie hier den Namen des Firmenverzeichnisses, der den Nutzern unter Kontakte angezeigt werden soll.",
    "modules-appsettings-table-contactClosedUserGroup": "Geschlossene Nutzergruppe aktivieren",
    "modules-appsettings-table-contactClosedUserGroupDescr": "Wenn diese Funktion aktiviert ist, können Nutzer nur mit im ginlo Cockpit gepflegten Kontakten kommunizieren. Ein Chat mit Nutzern außerhalb der Cockpitverwaltung ist nicht möglich.",
    "modules-appsettings-table-deletedatacount": "Daten löschen nach Anzahl der Anmeldeversuche",
    "modules-appsettings-table-deletedatacountdescr": "Setzen Sie hier die Anzahl der zulässigen Loginversuche, bevor die Inhalte der App automatisch gelöscht werden.",
    "modules-appsettings-table-disablebackup": "App Backup in iCloud/Google Drive unterbinden",
    "modules-appsettings-table-disablebackupdescr": "Wenn diese Einstellung gesetzt ist, können Nutzer kein Backup in iCloud bzw. Google Drive erstellen oder Backups von diesen Anbietern einspielen. Zur Datensicherung wird dann die Kopplung mit einem zweiten Gerät empfohlen.",
    "modules-appsettings-table-disablecamera": "Zugriff auf die Kamera unterbinden.",
    "modules-appsettings-table-disablecameradescr": "Wenn diese Funktion aktiv ist, kann ein Mitarbeiter Fotos und Video über die Kamera nicht mehr aufnehmen und senden.",
    "modules-appsettings-table-disablecopypaste": "Zugriff auf Copy / Paste unterbinden",
    "modules-appsettings-table-disablecopypastedescr": "Wenn diese Funktion aktiv ist, kann ein Mitarbeiter Texte und Medien nicht mehr kopieren und in Chats oder fremde Anwendungen einfügen.",
    "modules-appsettings-table-disableexportchat": "Chatinhalte als Textdatei exportieren deaktivieren",
    "modules-appsettings-table-disableexportchatdescr": "Wenn diese Einstellung gesetzt ist, können einzelne Chats nicht mehr in eine Textdatei aus der App heraus exportiert werden.",
    "modules-appsettings-table-disablefaceandtouchid": "Touch-/Face-ID unterbinden",
    "modules-appsettings-table-disablefaceandtouchiddescr": "Wenn diese Einstellung gesetzt ist, können Nutzer zum Entsperren der App nicht Touch-/Face ID (iPhone) oder Fingerprint (Android) verwenden.",
    "modules-appsettings-table-disablelocation": "Zugriff auf die Geodaten unterbinden",
    "modules-appsettings-table-disablelocationdescr": "Wenn diese Funktion aktiv ist, kann ein Mitarbeiter Standorte nicht mehr senden.",
    "modules-appsettings-table-disablemicrophone": "Zugriff auf das Mikrofon unterbinden",
    "modules-appsettings-table-disablemicrophonedescr": "Wenn diese Funktion aktiv ist, kann ein Mitarbeiter Audioaufnahmen nicht mehr senden.",
    "modules-appsettings-table-disablepushpreview": "Vorschau in Push Mitteilungen unterbinden",
    "modules-appsettings-table-disablepushpreviewdescr": "Wenn diese Einstellung gesetzt ist, werden die Inhalte von Nachrichten auf Mobil-Geräten nicht in der Push Vorschau angezeigt. Dies erhöht die Sicherheit, da Inhalte nur mit entsperrter App gelesen werden können.",
    "modules-appsettings-table-disablerecoverycode": "Wiederherstellungscode deaktivieren",
    "modules-appsettings-table-disablerecoverycodedescr": "Wenn diese Funktion aktiviert ist, kann der Nutzer die Funktion 'Passwort vergessen' nicht verwenden.",
    "modules-appsettings-table-disablesendmedia": "Senden und Weiterleiten von Mediendateien deaktivieren",
    "modules-appsettings-table-disablesendmediadescr": "Wenn diese Einstellung gesetzt ist, können keine Anlagen versendet oder weitergeleitet werden.",
    "modules-appsettings-table-disablesendvcard": "Zugriff auf Kontakte unterbinden",
    "modules-appsettings-table-disablesendvcarddescr": "Wenn diese Funktion aktiv ist, kann ein Mitarbeiter Kontakte nicht mehr senden und empfangen.",
    "modules-appsettings-table-disablesimsmerecovery": "Wiederherstellungscode über Administrator erzwingen",
    "modules-appsettings-table-disablesimsmerecoverydescr": "Wenn diese Funktion aktiviert ist, wird im Fall eines vergessenen Passworts der Wiederherstellungscode zum Zurücksetzen erst nach Administratorfreigabe an den Nutzer geschickt.",
    "modules-appsettings-table-forcePwComplexity": "Passwortkomplexität erzwingen",
    "modules-appsettings-table-forcePwComplexityDescr": "Komplexes App Passwort erzwingen",
    "modules-appsettings-table-localmedia": "Lokales Speichern von Mediendateien unterbinden",
    "modules-appsettings-table-localmediadescr": "Ist diese Einstellung gesetzt entfällt in der App die Einstellung 'Medien sichern' und Bilder und Videos können nicht mehr lokal gespeichert werden.",
    "modules-appsettings-table-passwordmaxduration": "Passwortgültigkeit in Tagen",
    "modules-appsettings-table-passwordmaxdurationdescr": "Setzen Sie hier die Passwortgültigkeit in Tagen (0-365). Ein Wert von 30 bedeutet beispielsweise, dass nach 30 Tagen das Passwort durch den Nutzer geändert werden muss.",
    "modules-appsettings-table-passwordminclasses": "Mindestkomplexität (Ziffern, Sonderzeichen, Kleinbuchstaben oder Großbuchstaben)",
    "modules-appsettings-table-passwordminclassesdescr": "Mit dieser Einstellung bestimmen Sie die Mindestzahl der im Passwort geforderten Zeichenklassen (Ziffern, Sonderzeichen, Kleinbuchstaben oder Großbuchstaben). 2 bedeutet, dass das Passwort mindestens zwei Klassen (z.B. Ziffern + Kleinbuchstaben oder Sonderzeichen + Ziffern) enthalten muss.",
    "modules-appsettings-table-passwordminlength": "Mindestanzahl Zeichenlänge",
    "modules-appsettings-table-passwordminlengthdescr": "Setzen Sie hier die Mindestanzahl der Zeichenlänge der Passwort-Komplexität. 0 Zeichen bedeutet, dass das Passwort keine Mindestlänge erfordert.",
    "modules-appsettings-table-passwordminlowcase": "Mindestanzahl Kleinbuchstaben",
    "modules-appsettings-table-passwordminlowcasedescr": "Setzen Sie hier die Mindestanzahl der Kleinbuchstaben, wie z.B. 'abcde'. 0 Zeichen bedeutet, dass das Passwort keine Kleinbuchstaben erfordert.",
    "modules-appsettings-table-passwordminnumbers": "Mindestanzahl Ziffern",
    "modules-appsettings-table-passwordminnumbersdescr": "Setzen Sie hier die Mindestanzahl der Ziffern, wie z.B. '12345'. 0 Zeichen bedeutet, dass das Passwort keine Ziffern erfordert.",
    "modules-appsettings-table-passwordminspecialchars": "Mindestanzahl Sonderzeichen",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Setzen Sie hier die Mindestanzahl der Sonderzeichen, z.B. '!§$%', die verwendet werden müssen. 0 Zeichen bedeutet, dass kein Sonderzeichen erforderlich ist.",
    "modules-appsettings-table-passwordminuppercase": "Mindestanzahl Großbuchstaben",
    "modules-appsettings-table-passwordminuppercasedescr": "Setzen Sie hier die Mindestanzahl der Großbuchstaben, wie z.B. 'ABCDE'. 0 Zeichen bedeutet, dass das Passwort keine Großbuchstaben erfordert.",
    "modules-appsettings-table-pwinact": "Passwortabfrage nach Inaktivität erzwingen nach…",
    "modules-appsettings-table-pwinactdescr": "Setzen Sie hier den erlaubten Zeitraum, nach dem die Eingabe des Passworts wieder erforderlich ist.",
    "modules-appsettings-table-pwpolicy": "Passwortabfrage beim App Start erzwingen",
    "modules-appsettings-table-pwpolicydescr": "Wenn diese Funktion aktiviert ist, fragt die App bei jedem Start nach dem Nutzer-Passwort.",
    "modules-appsettings-table-pwwdh": "Passwortwiederholungen nach X Änderungen erlauben",
    "modules-appsettings-table-pwwdhdescr": "Setzen Sie hier die Anzahl der Änderungen, ab der ein Nutzer ein altes Passwort wieder verwenden darf. 1 Wiederholung bedeutet, dass das Passwort nach 1 Änderung wieder verwendet werden darf.",
    "modules-appsettings-table-sharemedia": "Mediendateien über andere Apps teilen deaktivieren",
    "modules-appsettings-table-sharemediadescr": "Wenn diese Einstellung gesetzt ist, können Bilder und Videos nicht mehr gespeichert und Dateien nicht mehr angezeigt werden.",
    "modules-appsettings-table-simplecode": "Einfachen 4-stelligen Code als Passwort unterbinden",
    "modules-appsettings-table-simplecodedescr": "Wenn diese Einstellung gesetzt ist, können Nutzer kein einfaches 4-stelliges Passwort setzen. Um sicher zu stellen, dass bestehende 4-stellige Passwörter geändert werden müssen, definieren Sie eine entsprechende Password-Komplexität.",
    "modules-channel-commonbutton-btnUp": "nach oben",
    "modules-channels-Channel-channel": "Kanäle verwalten",
    "modules-channels-Channel-confirmDeleteChannel": "Sind Sie sicher, dass Sie den Kanal löschen möchten?",
    "modules-channels-ChannelList-table-column1": "Kanalname",
    "modules-channels-NewChannel": "Neuen Kanal anlegen",
    "modules-channels-NewChannel-noMember": "Keine Kanalmitglieder",
    "modules-channels-channel-btnDeleteChannel": "Kanal löschen",
    "modules-channels-channel-tab-editChannel": "Kanal bearbeiten",
    "modules-channels-channel-tab-messages": "Nachrichten",
    "modules-channels-messages-senddateinvalid": "Bitte wählen Sie ein Datum in der Zukunft.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Aktivität",
    "modules-dashboard-AktivitatenChart-displayValue1": "Aktive Nutzer pro Tag",
    "modules-dashboard-AktivitatenChart-displayValue2": "Aktive Nutzer pro Monat",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} Nachrichten",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Aktive Nutzer pro Tag",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Aktive Nutzer pro Monat",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Anzahl der Nutzer, die täglich die App öffnen",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Anzahl der Nutzer, die monatlich die App öffnen",
    "modules-dashboard-AktivitatenChart-timeValue1": "Eine Woche",
    "modules-dashboard-AktivitatenChart-timeValue2": "Ein Monat",
    "modules-dashboard-AktivitatenChart-timeValue3": "Gesamtzeitraum",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} Nutzer",
    "modules-dashboard-ChannelChart-channels": "Kanäle",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Bitte wählen Sie einen Kanal.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Bitte wählen Sie eine Gruppe.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "Noch keine Daten vorhanden",
    "modules-dashboard-ChannelChart-popOver-text1": "Die Top 5 sind die Nachrichten, deren Dateien, Bilder oder Links von den Nutzern am häufigsten geöffnet oder geklickt wurden.",
    "modules-dashboard-ChannelChart-table-column1": "Name",
    "modules-dashboard-ChannelChart-table-column2": "Nutzer",
    "modules-dashboard-ChannelChart-table-column3": "Nachrichten",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "Nutzergenerierte Gruppen",
    "modules-dashboard-ChannelChart-table-showAll": "Alle anzeigen …",
    "modules-dashboard-ChannelChart-table-title": "Top 5 Unterhaltungen",
    "modules-dashboard-ChannelChart-table-titleInfo": "Anzahl der Linkaufrufe",
    "modules-dashboard-ChannelChart-timeValue1": "Eine Woche",
    "modules-dashboard-ChannelChart-timeValue2": "Ein Monat",
    "modules-dashboard-Dashboard-dashboard": "Übersicht",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} von {{v2}} Lizenzen in Nutzung",
    "modules-dashboard-GruppenChart-groups": "Administrierte Gruppen",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} Nachrichten",
    "modules-dashboard-GruppenChart-table-column1": "Name",
    "modules-dashboard-GruppenChart-table-column2": "Nutzer",
    "modules-dashboard-GruppenChart-table-column3": "Nachrichten",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} Nachrichten in nutzergenerierten Gruppen",
    "modules-dashboard-GruppenChart-table-showAll": "Alle anzeigen …",
    "modules-dashboard-GruppenChart-table-title": "Nachrichten",
    "modules-dashboard-GruppenChart-timeValue1": "Eine Woche",
    "modules-dashboard-GruppenChart-timeValue2": "Ein Monat",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Nachrichtenzusammensetzung",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "Eine Woche",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "Ein Monat",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Gesamtzeitraum",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} Nachrichten gesamt",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} Einzelchats",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} Gruppen",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} Kanäle",
    "modules-group-group-identifier": "Gruppe",
    "modules-group-groups-identifier": "Gruppen",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Prüfen",
    "modules-groups-GroupList-table-column1": "Gruppenname",
    "modules-groups-GroupList-table-column2": "Eingeladen",
    "modules-groups-GroupList-table-column3": "Beigetreten",
    "modules-groups-GroupList-table-column4": "Ausstehend",
    "modules-groups-GroupList-table-column5": "Letzte Aktivität",
    "modules-groups-Groups-btnCancelNewGroup": "Abbrechen",
    "modules-groups-Groups-btnCreateGroup": "Neue Gruppe anlegen",
    "modules-groups-Groups-btnDeleteGroup": "Gruppe löschen",
    "modules-groups-Groups-confirmDeleteGroup": "Sind Sie sicher, dass Sie die Gruppe löschen möchten?",
    "modules-groups-Groups-groups": "Gruppen verwalten",
    "modules-groups-NewGroup-btnImportImage": "Bild wählen",
    "modules-groups-NewGroup-newgroup": "Neue Gruppe anlegen",
    "modules-groups-NewGroup-noMember": "Keine Gruppenmitglieder",
    "modules-groups-NewGroup-placeholder-RSS-preview": "RSS Vorschau",
    "modules-groups-NewGroup-placeholder-feed": "Legen Sie eine externe RSS Quelle fest.",
    "modules-groups-NewGroup-placeholder-name": "Name",
    "modules-groups-NewGroup-searchHint": "Nach Vorname, Name oder Schlagwort suchen",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Schließen",
    "modules-license-DPAGOrderLicenseDialog-data-link": "Daten",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "Daten sind",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) Vertrags- und Bestandsdaten, z. B. Firma, Adressen, E-Mail-Adressen oder Telefonnummern persönlicher Ansprechpartner und Customer Service-Informationen sowie die Zahlungs- und Rechnungsdaten, und",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) Verkehrsdaten, z. B. Informationen über Zeitpunkt, Dauer und Volumen von durch Nutzer verschickten Nachrichten sowie die noch in der Übermittlung befindlichen, verschlüsselten Nachrichten selbst.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "Nähere Informationen hierzu finden Sie {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "hier",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "https://sims.me/datenuebergang/business/de",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "Ich möchte die nahtlose Fortführung des ginlo Business Messengers für meine Nutzer sicherstellen und beauftrage daher hiermit die ginlo.net GmbH mit der Erbringung der ginlo Business-Leistungen für meine bestehenden Lizenzen ab dem Zeitpunkt, zu dem diese bei Deutsche Post AG auslaufen. Für die Leistungserbringung stimme ich der Übertragung meiner sowie der {{v1}} meiner Nutzer von Brabbler AG an die ginlo.net GmbH zu. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "Ich bestelle hiermit",
    "modules-license-DPAGOrderLicenseDialog-order2": "neue Lizenzen.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Datenschutzbestimmungen",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "Es gelten für alle Lizenzen die {{v1}} und {{v2}} der ginlo.net GmbH.",
    "modules-license-DPAGOrderLicenseDialog-text1": "SIMSme Business (jetzt ginlo Business) hat mit der ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH) einen neuen Eigentümer bekommen. Neue Lizenzen können nur noch bei der ginlo.net GmbH bestellt werden. ",
    "modules-license-License-btn-new": "Neue Lizenzen bestellen",
    "modules-license-License-btn-select": "Lizenz zuweisen",
    "modules-license-License-delete-user-btn": "Nutzer löschen",
    "modules-license-License-delete-user-text": "Sind Sie sicher, dass Sie den Nutzer löschen und damit den Zugriff auf die App sperren wollen?",
    "modules-license-License-delete-user-title": "{{v1}} löschen?",
    "modules-license-License-extend-header": "Lizenz verlängern",
    "modules-license-License-extend-text": "Lizenz zuweisen für ",
    "modules-license-License-license": "Lizenzverwaltung",
    "modules-license-License-revoke-btn": "Lizenz entziehen",
    "modules-license-License-revoke-text": "Sind Sie sicher, dass Sie dem Nutzer die Lizenz entziehen wollen?",
    "modules-license-License-revoke-title": "{{v1}} Lizenz entziehen?",
    "modules-license-License-searchfield-hint": "Suche nach Vorname, Name oder Schlagwort",
    "modules-license-License-table-column-count": "Anzahl",
    "modules-license-License-table-column-expireDate": "Gültig bis",
    "modules-license-License-table-column-license": "Lizenz",
    "modules-license-License-table-column-licenses": "Lizenzen",
    "modules-license-License-table-column-more-assign": "Lizenz zuweisen",
    "modules-license-License-table-column-more-delete": "Lizenz löschen",
    "modules-license-License-table-column-more-extend": "Lizenz verlängern",
    "modules-license-License-table-column-more-revoke": "Lizenz entziehen",
    "modules-license-License-table-header-availableUser": "Verfügbare Nutzer",
    "modules-license-License-table-header-freeLicense": "Freie Lizenzen",
    "modules-license-License-table-header-usedLicense": "Verwendete Lizenzen",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/de/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/de/privacy",
    "modules-license-license-assignment-error": "Zuweisen nicht möglich. Es sind nur noch {{free}} freie Lizenz/en verfügbar.",
    "modules-roles-NewRole-noMember": "Keine Mitglieder",
    "modules-roles-NewRole-placeholder-name": "Name",
    "modules-roles-NewRole-searchHint": "Suche nach Vorname, Name oder Schlagwort",
    "modules-roles-Newrole-newrole": "Neue Rolle anlegen",
    "modules-roles-RoleList-table-column1": "Rollenname",
    "modules-roles-RoleList-table-column2": "Anzahl Nutzer",
    "modules-roles-Roles-btnCreateRole": "Neue Rolle",
    "modules-roles-Roles-btnDeleteRole": "Rolle löschen",
    "modules-roles-Roles-confirmDeleteRole": "Sind Sie sicher, dass Sie die Rolle löschen möchten?",
    "modules-roles-Roles-roles": "Nutzerrollen verwalten",
    "modules-settings-Admin-admin": "Stellvertreter",
    "modules-settings-Admin-btnActivateAdmin": "Aktivieren",
    "modules-settings-Admin-btnCancelNewAdmin": "Abbrechen",
    "modules-settings-Admin-btnCreateNewAdmin": "Neuen Stellvertreter anlegen",
    "modules-settings-Admin-btnDeactivateAdmin": "Deaktivieren",
    "modules-settings-Admin-btnDeleteAdmin": "Löschen",
    "modules-settings-Admin-btnSaveNewAdmin": "Speichern",
    "modules-settings-Admin-confirmDeleteAdmin": "Sind Sie sich sicher, dass Sie den Stellvertreter löschen möchten?",
    "modules-settings-Admin-search": "Suchbegriff eingeben",
    "modules-settings-Admin-setAdminEnabled": "Möchten Sie {{adminName}} alle Rechte als Stellvertreter einräumen? Dies beinhaltet das Anlegen, Bearbeiten und Löschen neuer Nutzer, Gruppen und Kanäle.",
    "modules-settings-Admin-setAdminEnabled-title": "Stellvertreter wurde angelegt",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Abbrechen",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Aktivieren",
    "modules-settings-AdminDetails-last-login": "Letzte Anmeldung am",
    "modules-settings-AdminList-table-adminStatus1": "angelegt",
    "modules-settings-AdminList-table-adminStatus2": "deaktiviert",
    "modules-settings-AdminList-table-adminStatus3": "aktiv",
    "modules-settings-AdminList-table-adminStatus4": "gelöscht",
    "modules-settings-AdminList-table-column1": "Nachname",
    "modules-settings-AdminList-table-column2": "Vorname",
    "modules-settings-AdminList-table-column3": "E-Mail",
    "modules-settings-AdminList-table-column4": "Mobilnummer",
    "modules-settings-AdminList-table-column6": "Aktivität",
    "modules-settings-AdminList-table-column7": "Letzte Anmeldung",
    "modules-settings-AdminList-table-row-never-log-in": "nie",
    "modules-settings-ApiCredentialsDialog-close-button": "Schließen",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Authentifizierungstoken",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "Fingerabdruck Nutzerzertifikate",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "Monitoring URL",
    "modules-settings-ApiCredentialsDialog-column-password": "Passwort",
    "modules-settings-ApiCredentialsDialog-column-username": "Nutzername",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Gültig bis",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Abgelaufen",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "URL kopieren",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "Mit dem Public Key der .CRT Datei können Sie die Gültigkeit des Zertifikates überprüfen.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Das .CRT-Zertifikat herunterladen",
    "modules-settings-ApiCredentialsDialog-edit-button": "Bearbeiten",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "Mit dieser URL können Sie den Status der Importe über Ihr Monitoring System überwachen. Es werden dabei keine personenbezogenen Daten übertragen. Das Format des Aufrufs ist in der API Dokumentation beschrieben.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Neues Zertifikat senden",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Neue URL generieren",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Neues Passwort generieren",
    "modules-settings-ApiCredentialsDialog-title": "Zugangsdaten",
    "modules-settings-DeleteApiUserDialog-text": "Dies beendet die automatische Synchronisierung. Ihre Anmeldedaten und die Monitoring URL werden gelöscht.",
    "modules-settings-DeleteApiUserDialog-title": "Wollen Sie die API deaktivieren?",
    "modules-settings-NewAdmin-hint": "Er kann Nutzer, Gruppen, Kanäle anlegen, bearbeiten und löschen. Der Stellvertreter kann aber keine weiteren Stellvertreter anlegen.",
    "modules-settings-NewAdmin-hint-title": "Der Stellvertreter erhält alle Adminrechte.",
    "modules-settings-NewAdmin-newadmin": "Neuen Stellvertreter anlegen",
    "modules-user-AdminList-table-auditLog-active": "Aktiviert am",
    "modules-user-AdminList-table-auditLog-created": "Angelegt am",
    "modules-user-AdminList-table-auditLog-deactive": "Deaktiviert am",
    "modules-user-AdminList-table-auditLog-delete": "Gelöscht am",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Alle verwendeten Geräte in eine CSV Datei exportieren.",
    "modules-user-ChooseCSVExport-title": "Daten für den Export auswählen",
    "modules-user-ChooseTemplate-csv-detail": "Vereinfachtes CSV für die einmalige Nutzeranlage",
    "modules-user-ChooseTemplate-ldap-detail": "Erweitertes CSV für eine kontinuierliche LDAP-Aktualisierung und automatischen Zuweisungen in Gruppen und Kanäle",
    "modules-user-ChooseTemplate-title": "Importvorlage herunterladen",
    "modules-user-Keyword-deleteKeyword": "Möchten Sie das Schlagwort {{keyword}} und alle seine Verwendungen wirklich löschen?",
    "modules-user-Keyword-jumpToUser": "Möchten Sie die aktuellen Änderungen verwerfen und zu {{displayName}} wechseln?",
    "modules-user-Keyword-searchHint": "Schlagwort suchen",
    "modules-user-Keyword-showUsage": "Anzeigen",
    "modules-user-Keyword-table-column1": "Schlagwort",
    "modules-user-Keyword-table-column2": "Verwendet",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Abbrechen",
    "modules-user-NewUser-btnNewUserSave": "Speichern",
    "modules-user-NewUser-keyword": "Schlagwort",
    "modules-user-NewUser-keywordEdit": "Schlagwörter bearbeiten",
    "modules-user-NewUser-keywordEditEnd": "Bearbeitung beenden",
    "modules-user-NewUser-newuser": "Neuen Nutzer anlegen",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "import_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "Möchten Sie eine Lizenz zuweisen?",
    "modules-user-User-assignVoucher-title": "{{displayName}} wurde angelegt.",
    "modules-user-User-assignVoucherBtnCancel": "Später zuweisen ",
    "modules-user-User-assignVoucherBtnOk": "Lizenz zuweisen",
    "modules-user-User-assignVouchersByImportID": "Möchten Sie allen importierten Nutzern automatisch eine Lizenz zuweisen?",
    "modules-user-User-assignVouchersByImportID-title": "Erfolgreich importierte Nutzer: {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Lizenzen zuweisen",
    "modules-user-User-btnCancelNewUser": "Abbrechen",
    "modules-user-User-btnCreateNewuser": "Neuen Nutzer anlegen",
    "modules-user-User-btnDeleteUser": "Nutzer löschen",
    "modules-user-User-btnImportUser": "Nutzerdaten importieren",
    "modules-user-User-btnImportVorlage": "CSV Import & -Export",
    "modules-user-User-btnRevokeLicence": "Lizenz sperren",
    "modules-user-User-btnSaveNewUser": "Speichern",
    "modules-user-User-btnSaveUser": "Änderungen speichern",
    "modules-user-User-confirmDeleteUser": "Sind Sie sich sicher, dass Sie den Nutzer löschen möchten?",
    "modules-user-User-confirmRevokeLicense": "Sind Sie sich sicher, dass Sie die Lizenz des Nutzers und damit den Zugriff auf die App sperren wollen?",
    "modules-user-User-email-hinttext": "E-Mail",
    "modules-user-User-importLdap-applyData": "Fortsetzen",
    "modules-user-User-importLdap-cancel": "Abbrechen",
    "modules-user-User-importLdap-done": "Schließen",
    "modules-user-User-importLdap-exportApplyData": "Importbericht exportieren",
    "modules-user-User-importLdap-exportPreviewData": "Prüfbericht exportieren",
    "modules-user-User-importLdap-state-applyData": "Daten werden verarbeitet",
    "modules-user-User-importLdap-state-done": "Änderungen übernommen",
    "modules-user-User-importLdap-state-fatal": "Die Aktion wurde aufgrund eines Fehlers abgebrochen.",
    "modules-user-User-importLdap-state-preflight": "Daten werden geprüft.",
    "modules-user-User-importLdap-state-waitConfirm": "Überprüfung abgeschlossen",
    "modules-user-User-importLdap-table-column1": "Kategorie",
    "modules-user-User-importLdap-table-column2": "Anzahl Einträge",
    "modules-user-User-importLdap-table-column3": "Hinweise",
    "modules-user-User-importLdap-table-column4": "Warnungen",
    "modules-user-User-importLdap-table-column5": "Fehler",
    "modules-user-User-importLdap-table-row1": "Nutzer",
    "modules-user-User-importLdap-table-row2": "Gruppen",
    "modules-user-User-importLdap-table-row3": "Kanäle",
    "modules-user-User-importLdap-table-row4": "Schlagwörter",
    "modules-user-User-search": "Suchbegriff eingeben",
    "modules-user-User-user": "Nutzerverwaltung ({{v1}})",
    "modules-user-UserList-popover-phone": "Geben Sie Mobilnummern mit Vorwahl und Nummer im Format „012345678910“ ein. Sollten Sie Nummern außerhalb Deutschlands anlegen, ergänzen Sie bitte die Ländervorwahl ohne „0“ also „+4312345678910“.",
    "modules-user-UserList-table-cmdDeleteUser": "Nutzer löschen",
    "modules-user-UserList-table-cmdEdit": "Bearbeiten",
    "modules-user-UserList-table-cmdRevokeLicense": "Lizenz sperren",
    "modules-user-UserList-table-column1": "Nachname",
    "modules-user-UserList-table-column2": "Vorname",
    "modules-user-UserList-table-column3": "E-Mail",
    "modules-user-UserList-table-column4": "Mobilnummer",
    "modules-user-UserList-table-column6": "Schlagwort",
    "modules-user-UserList-table-placeHolder-column1": "Nachname",
    "modules-user-UserList-table-placeHolder-column2": "Vorname",
    "modules-user-UserList-table-placeHolder-column3": "E-Mail",
    "modules-user-UserList-table-placeHolder-column4": "Mobilnummer",
    "modules-user-UserList-table-placeHolder-column6": "Schlagwort",
    "modules-user-UserList-table-placeHolder-column8": "Abteilung",
    "modules-user-UserList-table-roomWriterHeader": "Schreibrecht",
    "modules-user-UserList-table-userStatus1": "Nutzer angelegt",
    "modules-user-UserList-table-userStatus2": "Lizenz zugewiesen",
    "modules-user-UserList-table-userStatus3": "Lizenz aktiv",
    "modules-user-UserList-table-userStatus4": "Lizenz abgelaufen",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Lizenz abgelaufen - Nutzer gesperrt",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Lizenz entzogen - Nutzer gesperrt",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Lizenz aktiv - Verwaltung angenommen",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "E-Mail Validierung abgebrochen",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "E-Mail Validierung ausstehend",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Mobilnummervalidierung abgebrochen",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "Mobilnummervalidierung ausstehend",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Konto durch Nutzer gelöscht",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Verwaltungsanfrage abgelehnt",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "Nutzer angelegt - keine Lizenz zugewiesen",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Lizenz zugewiesen - Verwaltung ausstehend ",
    "modules-user-UserList-userCreated": "Der Nutzer {{firstName}} {{lastName}} wurde erfolgreich angelegt.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Lizenz zuweisen",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Lizenz später zuweisen",
    "modules-user-alert-btnSendManagementRequest": "Anfrage erneut stellen",
    "modules-user-alert-sendManagementRequestHint": "Der Nutzer hat die Anfrage zur Verwaltung seiner App abgelehnt.",
    "modules-user-device-deviceList": "Verwendete Geräte",
    "modules-user-device-unknown-devicename": "Unbekannter Gerätename",
    "modules-user-managementrequest-messagesent": "Die Anfrage zur Verwaltung der App wurde erneut an den Nutzer versandt.",
    "modules-user-password-btnSendRecoveryEMail": "Wiederherstellungscode senden",
    "modules-user-password-btnShowRecoveryCode": "Wiederherstellungscode anzeigen",
    "modules-user-password-revoverPasswordHint": "Dieser Nutzer hat sein Gerätepasswort vergessen. Bitte schicken Sie ihm seinen Wiederherstellungscode.",
    "modules-user-recover-bottomBarText": "Der Nutzer hat sein Passwort vergessen.",
    "modules-user-recoverycode-messagesent": "Der Wiederherstellungscode ist unterwegs. Eine Kopie der E-Mail finden Sie in Ihrem Posteingang.",
    "modules-user-recoverycode-title": "Wiederherstellungscode",
    "okay": "Okay",
    "phone": "Mobilnummer",
    "save": "Speichern",
    "secret": "Secret",
    "settings-admin": "Administrator",
    "settings-admin-change-pwd": "Passwort ändern",
    "settings-btnDeleteCompany": "Account löschen",
    "settings-company-label-ustIdNr": "USt-ID",
    "settings-companycockpittoken": "EMM Rollout",
    "settings-companycockpittoken-documentation-link": "Dokumentation",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/de/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "Wenn Ihr Unternehmen eine Enterprise Mobility Management-Lösung verwendet, können Sie automatische Registrierung für den Rollout aktivieren.",
    "settings-companycockpittoken-switch-enable-label": "Automatische EMM Registrierung",
    "settings-companycockpittoken-switch-force-desc": "Wenn diese Funktion aktiviert ist, wird die Registrierung des Messengers vollautomatisch durchgeführt. Beachten Sie hierzu die Hinweise in der {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Schnell-Registrierung auf Endgerät erzwingen",
    "settings-companycockpittoken-token-desc": "Sie benötigen das Sicherheitstoken für die automatische EMM Registrierung. Siehe hierzu die {{v1}}.",
    "settings-companycockpittoken-token-label": "Das Sicherheitstoken lautet: ",
    "settings-companycockpittoken-token-notset": "Automatische Registrierung nicht aktiv",
    "settings-companyinformation": "Unternehmensinformation",
    "settings-companylanguage": "Spracheinstellungen",
    "settings-companylanguage-de": "Deutsch",
    "settings-companylanguage-desc": "Sprache in Ihrem Unternehmen für E-Mail-Einladungen und weitere Kommunikation.",
    "settings-companylanguage-el": "Griechisch",
    "settings-companylanguage-en": "Englisch",
    "settings-companylanguage-es": "Spanisch",
    "settings-companylanguage-fr": "Französisch",
    "settings-companyprofilimage": "Unternehmensprofilbild",
    "settings-companyprofilimage-btnImportImage": "Bild wählen.",
    "settings-companyprofilimage-desc": "Wählen Sie ein Profilbild, das in Kanälen neben Nachrichten erscheint.",
    "settings-companyprofilimage-desc2": "Größenempfehlung 200 x 200 px.",
    "settings-confirmdeletecompanydialog-desc": "Durch das Löschen des Accounts werden alle Nutzeraccounts gelöscht und die dazugehörigen Lizenzen gesperrt.",
    "settings-confirmdeletecompanydialog-hint": "Passwort eingeben",
    "settings-confirmdeletecompanydialog-ok": "Account unwiderruflich löschen",
    "settings-confirmdeletecompanydialog-title": "Wollen Sie Ihren Account wirklich löschen?",
    "settings-ldap-sync-delete-api-user-button": "API deaktivieren",
    "settings-ldap-sync-descr-text": "Die ginlo API ist ein RESTful Service. Darüber können Sie Nutzer, Gruppen und Kanäle automatisiert erstellen, bearbeiten und löschen.",
    "settings-ldap-sync-documentation-link-pretext": "Hier finden Sie die dazugehörige {{v1}}.",
    "settings-ldap-sync-documentation-link-text": "Dokumentation",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/de/cockpit/index.html#ldap-daten",
    "settings-ldap-sync-ldap-activated": "API ist aktiviert",
    "settings-ldap-sync-ldap-activated-hint": "Manuelle Änderungen werden möglicherweise überschrieben.",
    "settings-ldap-sync-new-api-user-button": "API aktivieren",
    "settings-ldap-sync-show-api-user-credentials-button": "Zugangsdaten",
    "settings-ldap-sync_title": "ginlo Business API",
    "settings-order-in-year": "€ je Jahr",
    "settings-order-input-hint": "0 bis 500 Lizenzen",
    "settings-order-tax-info": "zzgl. gesetzlicher MwSt.",
    "settings-orderhistory": "Bestellhistorie",
    "settings-orderhistory-agb": "Ja, ich stimme den {{v1}} zu.",
    "settings-orderhistory-agb-link": "AGB",
    "settings-orderhistory-license": "Lizenz",
    "settings-orderhistory-licenses": "Lizenzen",
    "settings-orderhistory-order": "Jetzt verbindlich bestellen",
    "settings-orderhistory-privacy": "Von den {{v1}} habe ich Kenntnis genommen.",
    "settings-orderhistory-privacy-link": "Datenschutzhinweise",
    "settings-orderhistory-table-column-date": "Datum",
    "settings-primarydomain": "Hauptdomain",
    "settings-pwd-current-hint": "aktuelles Passwort eingeben",
    "settings-save-error": "Beim Speichern der Daten sind folgende Fehler aufgetreten:",
    "settings-settings": "Einstellungen",
    "settings-stellvertreter": "Stellvertreter",
    "settings-stellvertreter-btn-edit": "Stellvertreter bearbeiten",
    "settings-stellvertreter-details": "{{activeAdminCount}} aktiv {{adminCount}} angelegt",
    "settings-stellvertreter-last-login-never": "Noch nie angemeldet",
    "settings-stellvertreter-last-login-ok": "Letzte Anmeldung ",
    "settings-two-fa": "Zwei-Faktor-Authentifizierung",
    "settings-two-fa-btn-show-recovery-codes": "Wiederherstellungscodes anzeigen",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Einziehungscodes akzeptieren",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Bitte kopieren Sie diese Wiederherstellungscodes",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "Ihre Wiederherstellungscodes",
    "settings-two-fa-create-recovery-codes-dialog-title": "Ihre Wiederherstellungscodes",
    "settings-two-fa-disable-dialog-btn-submit": "Deaktivieren",
    "settings-two-fa-disable-dialog-description": "Möchten Sie die Zwei-Faktor-Authentifizierung wirklich deaktivieren?",
    "settings-two-fa-disable-dialog-title": "Deaktivierung der Zwei-Faktor-Authentifizierung",
    "settings-two-fa-disabled": "2FA ist inaktiv",
    "settings-two-fa-enable-dialog-btn-submit": "Token absenden und 2FA aktivieren",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Aktivierung der Zwei-Faktor-Authentifizierung",
    "settings-two-fa-enable-dialog-token-help-text": "Bitte scannen Sie den QR-Code oder geben Sie ihn manuell in Ihre Authenticator-App ein.",
    "settings-two-fa-enable-dialog-token-not-valid": "Token ist nicht gültig",
    "settings-two-fa-enabled": "2FA ist aktiv",
    "settings-two-fa-label": "Zwei-Faktor-Authentifizierung ist:",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Bitte kopieren Sie diese Wiederherstellungs-Codes. Damit können Sie sich in das Cockpit einloggen, wenn Sie Ihr Passwort verloren haben.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Recovery-Codes",
    "settings-two-fa-show-recovery-codes-dialog-title": "Bitte kopieren Sie diese Wiederherstellungscodes",
    "state": "Nutzerstatus",
    "token": "Token",
    "validation-errormessages-maxvalue": "maximal {{max}} erlaubt",
    "validation-errormessages-minvalue": "mindestens {{min}} erforderlich",
    "welcome-channel-btn": "Kanäle anlegen",
    "welcome-channel-description": "Kanäle ermöglichen Ihnen, Push-Mitteilungen an Ihre Nutzer zu senden. So können Sie Nachrichten, Bilder und Dateien zentral an Ihre Nutzer versenden und bekommen die Auswertung im Dashboard angezeigt.",
    "welcome-channel-subheadline-create": "Kanäle anlegen",
    "welcome-chat-subheadline": "Kommunikation vereinfachen",
    "welcome-config-subheadline": "App konfigurieren",
    "welcome-design-btn": "App Design anpassen",
    "welcome-design-description": "Hier können Sie das App Design nach den Corporate-Design-Richtlinien Ihres Unternehmens anpassen. Sie können Farben und Logo jederzeit ändern.",
    "welcome-design-subheadline-create": "App Design ändern",
    "welcome-group-btn": "Gruppen anlegen",
    "welcome-group-description": "Erstellen Sie Gruppen und erleichtern Sie so die Zusammenarbeit unter den Nutzern. Die Aktivität der Gruppen können Sie im Dashboard einsehen.",
    "welcome-group-subheadline-add": "Gruppen anlegen",
    "welcome-headline": "Herzlich Willkommen {{v1}}",
    "welcome-intro": "Mit dem ginlo Business Cockpit können Sie den sicheren ginlo Business Messenger für Android und iPhone komfortabel nach Ihren Wünschen konfigurieren.",
    "welcome-license-btn": "Lizenz zuweisen",
    "welcome-license-description": "Weisen Sie den angelegten Nutzern verfügbare Lizenzen zu. Sollten Sie nicht über ausreichend Lizenzen verfügen, {{v1}} fügen Sie einfach die gewünschte Menge hinzu.",
    "welcome-license-description-link": "bestellen",
    "welcome-license-subheadline-add": "Lizenzen zuweisen",
    "welcome-settings-btn": "Einstellungen ändern",
    "welcome-settings-description": "Individualisieren Sie die Sicherheitsanforderungen und die Einstellungen der App nach Ihren Sicherheitsvorgaben.",
    "welcome-settings-subheadline-create": "App Einstellungen festlegen",
    "welcome-user-btn": "Neuen Nutzer anlegen",
    "welcome-user-description": "Legen Sie neue Nutzeraccounts schnell und einfach an. Verwenden Sie dazu die Einzelanlage oder importieren Sie Nutzerlisten mit der CSV Vorlage.",
    "welcome-user-subheadline": "Teilnehmer verwalten",
    "welcome-user-subheadline-create": "Nutzer anlegen"
}
;
    
    export default TranslationCommonDe;
    
