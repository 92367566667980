import React, { Component } from 'react';
import TextFieldUi from 'material-ui/TextField';

class TextField extends Component {
  render() {
    const {underlineFocusStyle, hintStyle, underlineStyle,...other} = this.props;

    const mergedUnderlineFocusStyle = Object.assign({}, {
      borderColor:"#00C1A7",
    }, underlineFocusStyle);

    const mergedHintStyle = Object.assign({},{
      color: "#7B98AB",
    },hintStyle)

    const mergedUnderLineStyle = Object.assign({},{
      borderColor:'#7B98AB'
    },underlineStyle)

    return (
     <TextFieldUi
      underlineFocusStyle={mergedUnderlineFocusStyle}
      hintStyle={mergedHintStyle}
      underlineStyle={mergedUnderLineStyle}
       {...other} />
    );
  }
}
export default TextField;
