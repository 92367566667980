/**** HELPER **********/
// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
export function expandShorthandHex(hex, alpha) {
  if (typeof hex !== "string")
  {
    return hex;
  }
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    if (typeof alpha === "string")
    {
      return '#' + alpha + r + r + g + g + b + b;
    }
    return '#' + r + r + g + g + b + b;
  });
  for (var i = 0; i < 6; i++)
  {
    if (hex.length < 7)
    {
      if (hex.startsWith('#'))
      {
        hex = hex.replace('#', '#0')
      }
      else
      {
        hex = '0' + hex;
      }

    }
  }
  if (typeof alpha === "string")
  {
    if (hex.startsWith('#'))
    {
      hex += alpha
    }
    else
    {
      hex = '#' + hex + alpha
    }
  }
  return hex;
}
//http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  //  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  //  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
  //      return r + r + g + g + b + b;
  //  });
  hex = expandShorthandHex(hex);
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
