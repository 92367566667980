import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconCheckFilled(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 20 21">
              <path fill="#00C1A7" d="M18,9 C18,13.9705692 13.9705692,18 9,18 C4.02943076,18 0,13.9705692 0,9 C0,4.02943076 4.02943076,0 9,0 C13.9705692,0 18,4.02943076 18,9 Z M7.95899225,13.7654439 L14.6364116,7.08802451 C14.8631198,6.86128087 14.8631198,6.49359328 14.6364116,6.26684964 L13.8152367,5.44571021 C13.5885285,5.21893114 13.2208409,5.21893114 12.9940619,5.44571021 L7.5483871,10.891385 L5.00593813,8.34890058 C4.77919449,8.12215694 4.4115069,8.12215694 4.18476326,8.34890058 L3.36358839,9.17004001 C3.1368802,9.39678364 3.1368802,9.76447124 3.36358839,9.99121488 L7.13778194,13.7654084 C7.36456102,13.9921521 7.73221317,13.9921521 7.95899225,13.7654439 Z" transform="translate(1 1.5)"/>
            </svg>
         </SvgIcon>;
}
;

export default IconCheckFilled;
