import React from 'react';
import BaseModul from '../BaseModul.js'

import { PageHeader, BackSubPageHeader } from '../../components/pageheader';
import { translate, Interpolate } from 'react-i18next';
import RaisedButton from '../../components/buttons/RaisedButton';
import {Tabs, Tab} from 'material-ui/Tabs';
import UsedLicenseList from './UsedLicenseList';
import FreeLicenseList from './FreeLicenseList';
import LicenseAssignment from './LicenseAssignment';
import { connect } from 'react-redux';
import { getAdminInfo, getListUsedVoucher, getListFreeVoucher, getUserList, assignFreeVoucher, revokeVoucher, deleteUser, purchaseCompanyVoucher } from '../../actions/index';
import DataProvider from './DataProvider';
import { WaitDialog } from '../../components/WaitDialog'
import { ModalDialog } from '../../components/ModalDialog'
import ExtendLicenseDialog from './ExtendLicenseDialog.js'
import OrderLicenseDialog from './OrderLicenseDialog.js'
import { IsNullOrEmpty } from '../../components/StringExtensions';

//import { connect } from 'react-redux';
//import { setCompanyAdress } from './actions/index';
const STATE_LIST_VOUCHER = 0;
const STATE_ASSIGN_VOUCHER = 1;

const styles = {
  root: {
    width: '100%',
    height: '100%'
  },
  headerAssign: {
    width: '100%',
    height: '60px'
  },
  right: {
    textAlign: 'right',
    paddingRight: '10px',
    width: '100%'
  },
  left: {
    width: '100%'
  },
  button: {
    margin: '5px'
  },
  test: {

  }
};

const tabs = {
  style: {
    height: '100%'
  },
  inkBarStyle: {
    backgroundColor: 'Transparent',
    width: 500
  },
  tabStyle: {
    style: {
      backgroundColor: '#00375D',
      width: 250
    },
    button: {
      backgroundColor: '#00375D',
      width: 'inherit',
      backgroundRepeat: 'none',
      marginRight: 10
    }
  }
}
class License extends BaseModul {

  constructor(props) {
    super(props);

    this.state = {
      currentViewMode: STATE_LIST_VOUCHER,
      canAssignLicense: false,
    }

    this.dataProvider = new DataProvider();

    this.onBtnSelectPressed = this.onBtnSelectPressed.bind(this);
    this.onAssignFreeVoucherSuccessHandler = this.onAssignFreeVoucherSuccessHandler.bind(this);
    this.onStartUserAction = this.onStartUserAction.bind(this);
    this.onActionSuccessHandler = this.onActionSuccessHandler.bind(this);
    this.onActionErrorHandler = this.onActionErrorHandler.bind(this);
    this.onExtendUsersVoucher = this.onExtendUsersVoucher.bind(this);
    this.onRevokeUsersVoucher = this.onRevokeUsersVoucher.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.onOrderLicenseHandler = this.onOrderLicenseHandler.bind(this);

    this.handleAvailableUsersSelected = this.handleAvailableUsersSelected.bind(this);
    this.handleFreeVoucherSelected = this.handleFreeVoucherSelected.bind(this);

    this.onAssignFreeVoucherErrorHandler = this.onAssignFreeVoucherErrorHandler.bind(this);
    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.getUserListSuccessNullHandler = this.getUserListSuccessNullHandler.bind(this);

    this.onLoadDataUsedVoucherSuccess = this.onLoadDataUsedVoucherSuccess.bind(this);
    this.onLoadDataFreeVoucherSuccess = this.onLoadDataFreeVoucherSuccess.bind(this);
    this.onLoadDataUserListSuccess = this.onLoadDataUserListSuccess.bind(this);
    this.onLoadDataError = this.onLoadDataError.bind(this);

    this.getAdminInfoSuccessHandler = this.getAdminInfoSuccessHandler.bind(this);

  }

  handleAvailableUsersSelected(selectedUsers) {
    let freeVoucherGuid = this.dataProvider.getSelectedFreeVoucherGuid();
    let isFreeVoucherGroupSelected = typeof freeVoucherGuid !== 'undefined' && freeVoucherGuid !== null;
    let isUserSelected = selectedUsers.length > 0;
    let canAssignLicense = isUserSelected && isFreeVoucherGroupSelected;
    this.setState({
      canAssignLicense: canAssignLicense
    });
  }

  handleFreeVoucherSelected(selectedFreeVoucherGuid) {
    let isFreeVoucherSelected = typeof selectedFreeVoucherGuid === 'string';
    let isUserSelected = this.dataProvider.getSelectedUserGuids().length > 0;
    let canAssignLicense = isUserSelected && isFreeVoucherSelected;
    this.setState({
      canAssignLicense: canAssignLicense
    });
  }

  componentDidMount() {
    super.componentDidMount()

    this.dataProvider.onAvailableUsersSelected = this.handleAvailableUsersSelected;
    this.dataProvider.onFreeVoucherSelected = this.handleFreeVoucherSelected;

    const {dispatch} = this.props;

    this.loadAdminInfo();

    this.refs.waitDialog.open()

    dispatch(getListUsedVoucher(this.dataProvider.getUsedVoucherLastQueryDate(), this.onLoadDataUsedVoucherSuccess, this.onLoadDataError))
  }

  onLoadDataUsedVoucherSuccess(json) {
    this.dataProvider.setUsedVoucherData(json, true);
    this.setState({
      forceUpdate: new Date()
    })
    const {dispatch} = this.props;
    dispatch(getListFreeVoucher(this.onLoadDataFreeVoucherSuccess, this.onLoadDataError))
  }

  onLoadDataFreeVoucherSuccess(json) {
    this.dataProvider.setFreeVoucherData(json.data)
    this.setState({
      forceUpdate: new Date()
    });
    const {dispatch} = this.props;
    dispatch(getUserList(this.dataProvider.getUserDataLastQueryDate(), this.onLoadDataUserListSuccess, this.onLoadDataError))
  }

  onLoadDataUserListSuccess(json) {
    this.dataProvider.setAvailableUser(json);
    this.setState({
      forceUpdate: new Date()
    })
    this.refs.waitDialog.close();
  }

  onLoadDataError(errorJO) {
    this.refs.waitDialog.close();
    // const {t, dispatch} = this.props;
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);
  }

  loadAdminInfo() {
    this.props.dispatch(getAdminInfo(false, this.getAdminInfoSuccessHandler, this.onLoadDataError));
  }

  getAdminInfoSuccessHandler(json) {
    this.setState({
      adminInfo: json
    })
  }

  handleReloadData() {
    const {dispatch} = this.props;

    dispatch(getListUsedVoucher(this.dataProvider.getUsedVoucherLastQueryDate(), this.onLoadDataUsedVoucherSuccess, this.onLoadDataError))
  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    this.props.dispatch(getUserList(this.getUserListSuccessNullHandler));
  }

  getUserListSuccessNullHandler(json) {
    this.refs.waitDialog.close();
  }

  componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.params !== "undefined" &&
      typeof nextProps.params.mode !== "undefined" &&
      nextProps.params.mode === "assignFreeVoucher"
    ) {
      this.setState({
        currentViewMode: STATE_ASSIGN_VOUCHER
      })
    }
  }


  onBtnSelectPressed() {
    const {t} = this.props;
    let listFreeVoucher = this.dataProvider.getFreeVoucherData();
    if (this.state.currentViewMode === STATE_LIST_VOUCHER) {
      this.setState({
        currentViewMode: STATE_ASSIGN_VOUCHER
      })
      return;
    }

    if (this.state.currentViewMode === STATE_ASSIGN_VOUCHER) {
      const {dispatch} = this.props;

      let voucherGroupGuid = this.dataProvider.getSelectedFreeVoucherGuid();
      let selectedUserGuids = this.dataProvider.getSelectedUserGuids();

      if (voucherGroupGuid && selectedUserGuids && selectedUserGuids !== "") {
        let data = listFreeVoucher.find((vg => vg.VoucherGroup.guid === voucherGroupGuid));
        let voucherCount = data.VoucherGroup.voucherCount;

        if (voucherCount >= selectedUserGuids.split(",").length) {
          this.refs.waitDialog.open();
          dispatch(assignFreeVoucher(voucherGroupGuid, selectedUserGuids, this.onAssignFreeVoucherSuccessHandler, this.onAssignFreeVoucherErrorHandler));
        } else {
          let licenseWarning = {
            text: t("modules-license-license-assignment-error", {
              free: voucherCount
            }),
            button1Text: t("components-ModalDialog-ok"),
            button1Handler: null,
            title: t("components-ModalDialog-Warning"),
          };
          //!!Message!!
          this.refs.modalDialog.open(licenseWarning);
        }
      }
    }
  }

  onAssignFreeVoucherSuccessHandler(json) {

    this.refs.waitDialog.close();
    const {dispatch, router} = this.props;

    this.dataProvider.resetSelectedUserAndVoucher();

    if (this.props.params && this.props.params.mode) {
      router.replace("/license/");
    }

    dispatch(getListUsedVoucher(this.dataProvider.getUsedVoucherLastQueryDate(), this.onLoadDataUsedVoucherSuccess, this.onLoadDataError))

    this.setState({
      currentViewMode: STATE_LIST_VOUCHER
    })
  }

  onAssignFreeVoucherErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t, dispatch} = this.props;

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);

  }

  onActionSuccessHandler(json) {
    this.refs.waitDialog.close();
    const {dispatch} = this.props;

    dispatch(getListUsedVoucher(this.dataProvider.getUsedVoucherLastQueryDate(), this.onLoadDataUsedVoucherSuccess, this.onLoadDataError))
  }

  onActionErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t, dispatch} = this.props;

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);

  }

  onStartUserAction(action, voucherGuid, userGuid) {
    const { t } = this.props;
    switch (action) {
      case 'revoke': {
        this.dataProvider.setSelectedUsedVoucherUserGuid(userGuid);
        let usedVoucher = this.dataProvider.searchUsedVoucher(voucherGuid);

        if (usedVoucher) {
          let name = <span style={ { color: '#7B98AB' } }>{ usedVoucher.CompanyUser.firstName + " " + usedVoucher.CompanyUser.name }</span>;
          let title = <Interpolate i18nKey='modules-license-License-revoke-title' v1={ name } />;
          this.refs.modalDialog.open({
            title: title,
            text: t('modules-license-License-revoke-text'),
            button2Text: t('modules-license-License-revoke-btn'),
            button2Handler: this.onRevokeUsersVoucher,
            button1Text: t('cancel')
          });
        }
        break;
      }
      case 'deleteUser': {
        this.dataProvider.setSelectedUsedVoucherUserGuid(userGuid);
        let usedVoucher = this.dataProvider.searchUsedVoucher(voucherGuid);

        if (usedVoucher) {
          let name = <span style={ { color: '#7B98AB' } }>{ usedVoucher.CompanyUser.firstName + " " + usedVoucher.CompanyUser.name }</span>;
          let title = <Interpolate i18nKey='modules-license-License-delete-user-title' v1={ name } />;
          this.refs.modalDialog.open({
            title: title,
            text: t('modules-license-License-delete-user-text'),
            button2Text: t('modules-license-License-delete-user-btn'),
            button2Handler: this.onDeleteUser,
            button1Text: t('cancel')
          });
        }
        break;
      }
      case 'extend': {
        this.dataProvider.setSelectedUsedVoucherUserGuid(userGuid);
        let usedVoucher = this.dataProvider.searchUsedVoucher(voucherGuid);
        this.refs.extendLicenseDialog.open({
          dataProvider: this.dataProvider,
          buttonSelectHandler: this.onExtendUsersVoucher,
          voucher: usedVoucher
        });
        break;
      }
      default:
    }
  }

  onDeleteUser() {
    const {dispatch} = this.props;
    let guid = this.dataProvider.getSelectedUsedVoucherUserGuid();
    this.refs.waitDialog.open();
    dispatch(deleteUser(guid, this.onActionSuccessHandler, this.onActionErrorHandler))
    this.dataProvider.setSelectedUsedVoucherUserGuid(null);
  }

  onRevokeUsersVoucher() {
    const {dispatch} = this.props;
    let guid = this.dataProvider.getSelectedUsedVoucherUserGuid();

    this.refs.waitDialog.open();
    dispatch(revokeVoucher(guid, this.onActionSuccessHandler, this.onActionErrorHandler))
    this.dataProvider.setSelectedUsedVoucherUserGuid(null);
  }

  onExtendUsersVoucher() {
    const {dispatch} = this.props;

    let voucherGroupGuid = this.dataProvider.getSelectedFreeVoucherGuid();
    let selectedUserGuid = this.dataProvider.getSelectedUsedVoucherUserGuid();

    if (voucherGroupGuid && selectedUserGuid && selectedUserGuid !== "")
    {
      this.refs.waitDialog.open();
      dispatch(assignFreeVoucher(voucherGroupGuid, selectedUserGuid, this.onAssignFreeVoucherSuccessHandler, this.onAssignFreeVoucherErrorHandler));
    }
  }

  onOrderLicenseHandler(licenseCount, optInState) {
    const {dispatch} = this.props;

    this.refs.waitDialog.open();
    dispatch(purchaseCompanyVoucher(licenseCount, optInState, this.onActionSuccessHandler, this.onActionErrorHandler));
  }

  handleOrderLicenseClick() {
    this.refs.orderLicenseDialog.open({
      buttonOrderHandler: this.onOrderLicenseHandler,
      price: this.state.adminInfo.LicensePrice,
      optInState: this.state.adminInfo.Company.optInState
    });
  }

  canOrderLicense(adminInfo) {
    if (typeof adminInfo === "undefined") {
      return false;
    }

    if (!IsNullOrEmpty(adminInfo.Company.salesPartnerID)) {
      return false;
    }

    return true;
  }

  renderOrderButton() {
    const {t} = this.props;
    let adminInfo = this.state.adminInfo;

    if (this.canOrderLicense(adminInfo))
    {
      return (
        <div style={ this.getBaseStyle().right }>
          <RaisedButton label={ t('modules-license-License-btn-new') } default={ true } style={ styles.button } onTouchTap={ () => this.handleOrderLicenseClick() } />
        </div>
      )
    }
    return (<div></div>)
  }

  renderHeaderView() {
    const {t} = this.props;
    // let adminInfo = this.state.adminInfo;

    if (this.state.currentViewMode === STATE_LIST_VOUCHER) {
      return (
        <div>
          <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
            <PageHeader style={ { paddingLeft: '25px', margin: 'auto' } }>
              { t("modules-license-License-license") }
            </PageHeader>
          </div>
          { this.renderOrderButton() }
        </div>);
    }

    if (this.state.currentViewMode === STATE_ASSIGN_VOUCHER) {
      return (<div style={ styles.headerAssign }>
                <BackSubPageHeader label={ t('modules-license-License-btn-select') } onBackClicked={ () => {
                                                                                                       this.dataProvider.resetSelectedUserAndVoucher();
                                                                                                       this.setState({
                                                                                                         canAssignLicense: false,
                                                                                                         currentViewMode: STATE_LIST_VOUCHER,
                                                                                                       })
                                                                                                     } } />
              </div>);
    }

  }

  renderMainView() {
    const {t} = this.props;
    if (this.state.currentViewMode === STATE_LIST_VOUCHER) {
      var data = this.dataProvider.getUsedVoucherSorted(this.state.sortColumnName, this.state.direction),
          usedLicenseListTabLabel = t('modules-license-License-table-header-usedLicense') + " (" + (data ? Object.keys(data).length : 0) + ")",
          voucherCount = this.dataProvider.getFreeVoucherCount(),
          freeLicenseListTabLabel = t('modules-license-License-table-header-freeLicense') + " (" + voucherCount + ")";
      return (
        <div style={ styles.root }>
          <Tabs
            style={ tabs.style }
            tabItemContainerStyle={ tabs.inkBarStyle }
            tabTemplate={this.tabTemplate}
          >
            <Tab
              label={ usedLicenseListTabLabel }
              value="UsedLicenseListTab"
              style={tabs.tabStyle.style}
              buttonStyle={tabs.tabStyle.button}
            >
              <UsedLicenseList
                  dataProvider={ this.dataProvider }
                  onUserAction={ this.onStartUserAction }
              />
            </Tab>
            <Tab
              label={ freeLicenseListTabLabel }
              value="FreeLicenseList"
              style={tabs.tabStyle.style}
              buttonStyle={tabs.tabStyle.button}
            >
              <FreeLicenseList
                  dataProvider={ this.dataProvider }
              />
            </Tab>
          </Tabs>
        </div>
      );
    }

    if (this.state.currentViewMode === STATE_ASSIGN_VOUCHER) {
      return (<div>
        <LicenseAssignment dataProvider={ this.dataProvider } viewPortHeight={ this.state.height } />
      </div>);
    }
  }

  // Temporary solution to override the functionality of the tab panel. It just add display: none on tabs swicth.

  tabTemplate(_ref) {
    var _interopRequireDefault = function(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
    var children = _ref.children,
        selected = _ref.selected,
        style = _ref.style;

    var _simpleAssign = require('simple-assign');
    var _simpleAssign2 = _interopRequireDefault(_simpleAssign);
    var _react = require('react');
    var _react2 = _interopRequireDefault(_react);


    var templateStyle = (0, _simpleAssign2.default)({}, styles, style);
    if (!selected) {
      templateStyle.height = 0;
      templateStyle.display = 'none';
      templateStyle.overflow = 'hidden'; // This line is added.
    }

    return _react2.default.createElement(
      'div',
      { style: templateStyle },
      children
    );
  }

  renderHiddenDialog() {
    const {t} = this.props;

    return (<div>
              <OrderLicenseDialog ref="orderLicenseDialog" t={ t } />
              <ExtendLicenseDialog ref="extendLicenseDialog" t={ t } />
              <WaitDialog ref="waitDialog" />
              <ModalDialog ref="modalDialog" />
              <ModalDialog ref="commonErrorDialog" />
            </div>);
  }

  renderCommonButton() {
    const {t} = this.props;
    let assignLicenseEnabled = (this.state.currentViewMode === STATE_LIST_VOUCHER) || (
      (this.state.currentViewMode === STATE_ASSIGN_VOUCHER) && this.state.canAssignLicense
    )
    return <div style={ this.getBaseStyle().fabRight }>
             <RaisedButton label={ t('modules-license-License-btn-select') } disabled={ !assignLicenseEnabled } primary={ true } style={ this.getBaseStyle().fab } onClick={ () => this.onBtnSelectPressed() } />
           </div>
  }
}


function mapStateToProps(state) {

  // const {session} = state;

  return {
  }
}

const LicenseTranslated = translate(['common'])(License);

export default connect(mapStateToProps)(LicenseTranslated);
