export class BaseDataProvider {

  sortData(data, sortColumnName, sortDirection) {
    let t = this;
    data = data.sort(function(a, b) {
      let rc = 0;
      if (sortColumnName === "state")
      {
        rc = t.sortState(a, b);
      }
      else if (typeof a[sortColumnName] === 'undefined')
      {
        return rc;
      }
      else if (typeof a[sortColumnName] === 'number')
      {
        rc = a[sortColumnName] - b[sortColumnName];
      }
      else if (typeof a[sortColumnName] === 'object')
      {
        //sortiere Arrays ...
        let astringified = JSON.stringify(a[sortColumnName]);
        let bstringified = JSON.stringify(b[sortColumnName]);
        rc = astringified.localeCompare(bstringified);
      }
      else
      {
        rc = a[sortColumnName].localeCompare(b[sortColumnName]);
      }
      if (rc === 0)
      {
        rc = a["guid"].localeCompare(b["guid"]);
      }
      if (sortDirection === 0)
      {
        return rc;
      }
      else
      {
        return rc * (-1);
      }

    });
    return data;
  }

  sortState(a, b) {
    return 0;
  }
}


export default BaseDataProvider;
