import BaseDataProvider from '../../BaseDataProvider';
import DataCache from '../../../cache/DataCache';

let SINGLETON = null;

export class DataProvider extends BaseDataProvider {
  fetchRoomData = (json) => {
    return json.ManagedRoom;
  };

  constructor() {
    super();
    if (!SINGLETON) {
      this.init();
    }
    return SINGLETON;
  }

  init() {
    this.data = JSON.parse("[]");
    this.dataCache = new DataCache();
    this.mHasUserData = false;
    SINGLETON = this;
  }

  setFetchRoomDataJsonFetchFunction(fetchFunction) {
    this.fetchRoomData = fetchFunction;
    //*** Bugfix zu Bug 43918 ***
    //Problem: beim Wechsel zwischen Gruppen und Kanälen
    //bleiben die "alten" Daten kurzzeitig sichtbar
    //Grund: Wiederverwendung der GroupList mit dem DataProvider als SINGLETON
    //
    //Lösungsansatz:
    //wenn dise Funktion hier neu gesetzt wird,
    //haben wir es mit einem "anderen" DataProvider-Typ zu tun
    //Da sich dementsprechend die Daten ändern,
    //"resetten" wir daher den DataProvider
    //
    //Gleichzeitig bleibt beim Wechsel zwischen Liste + Detailansicht
    //der Voteil des Singletons erhalten, da die Daten nicht noch mal
    //komplett neu geladen werden müssen.
    this.init();
  }

  getDataSorted(sortColumn, sortDirection, filterText) {
    // TODO : eingeladen usw. sortieren
    var sortColumnName = "name";
    if (sortColumn === 2) {
      sortColumnName = "memberCount";
    }
    if (sortColumn === 3) {
      sortColumnName = "activeMemberCount";
    }
    if (sortColumn === 4) {
      sortColumnName = "pendingMemberCount";
    }
    if (sortColumn === 5) {
      sortColumnName = "lastMessage";
    }
    let data = super.sortData(this.data, sortColumnName, sortDirection),
        filteredData = [];
    if(filterText) {
      filteredData = data.filter( row => row.name.indexOf(filterText) !== -1 );  
    } else {
      filteredData = data;
    }
    return filteredData;
  }

  mapData(newData) {
    newData.memberCount = 0;

    if (typeof newData.members !== "undefined") {
      newData.memberCount = newData.members.length;
      newData.activeMemberCount = newData.members.filter(function(groupMember) {
        return groupMember.accepted === "1";
      }).length;
      newData.pendingMemberCount = newData.memberCount - newData.activeMemberCount;
    }

    this.data.push(newData);
  }

  updateData(newData) {
    this.data = [];

    for (let user of newData) {
      if (!(this.fetchRoomData(user) === undefined)) {
        let newData = JSON.parse(JSON.stringify(this.fetchRoomData(user)));
        this.mapData(newData);
      }
    }
    if (typeof this.callback === "function") {
      this.callback();
    }
  }

  searchGroup(groupGuid) {
    for (let group of this.data) {
      if (group.guid === groupGuid) {
        return group;
      }
    }
    return null;
  }

  searchAndUpdate(newData) {

    for (let user of newData) {
      if (!(this.fetchRoomData(user) === undefined)) {
        let newData = JSON.parse(JSON.stringify(this.fetchRoomData(user)));
        this.deleteGroup(this.searchGroup(newData.guid))
        this.mapData(newData);
      }
    }
  }

  insertGroup(json) {
    if (!(this.fetchRoomData(json) === undefined)) {
      let newData = JSON.parse(JSON.stringify(this.fetchRoomData(json)));
      this.mapData(newData)
    }
    if (typeof this.callback === "function") {
      this.callback();
    }

  }
  deleteGroup(group) {
    let index = this.data.indexOf(group)
    if (index !== -1) {
      this.data.splice(index, 1)
    }
  }

  addCallback(callback) {
    this.callback = callback;
  }

  getDataCount() {
    return this.data.length;
  }

  getUserDataLastQueryDate() {
    return this.dataCache.dataUserLastQueryDate
  }

  updateUserData(newData) {
    this.dataCache.searchAndUpdateUser(newData)
    this.mHasUserData = true
    if (typeof this.callback === "function") {
      this.callback();
    }
  }

  getAvailableUser() {
    return JSON.parse(JSON.stringify(this.dataCache.getUserData()));
  }
  hasUserData() {
    return this.mHasUserData;
  }
}

export class DataProviderUserList extends BaseDataProvider {

  constructor() {
    super()
    this.data = [];
    this.selectedUser = [];
  }

  setUserData(userData, selectedUser) {
    this.data = JSON.parse(JSON.stringify(userData))
    if (typeof selectedUser !== "undefined") {
      var selectedUserGuids = {};
      for (var i = 0; i < selectedUser.length; i++) {
        selectedUserGuids[selectedUser[i].guid] = selectedUser[i];
      }
      this.selectedUser = [];
      for (i = 0; i < this.data.length; i++) {
        if (typeof selectedUserGuids[this.data[i].guid] !== "undefined") {
          this.selectedUser.push(this.data[i]);
        }
      }
    }
    if (typeof this.callback === "function") {
      this.callback();
    }

  }

  getDataSorted(sortColumn, sortDirection) {
    var sortColumnName = "name";
    if (sortColumn === 2) {
      sortColumnName = "firstName";
    }
    if (sortColumn === 3) {
      sortColumnName = "keyword"
    }
    // TODO ortColumn 3 ==> schlagworte

    this.data = super.sortData(this.data, sortColumnName, sortDirection)

    return this.data;
  }

  addCallback(callback) {
    this.callback = callback;
  }

  getData() {
    return this.data;
  }

  getDataCount() {
    return this.data.length;
  }

  isSelected(user) {
    let idx = this.selectedUser.indexOf(user);
    return idx !== -1;
  }


  setSelectedUsers(user) {
    this.selectedUser = user
    if (typeof this.callback === "function") {
      this.callback();
    }
  }
  setSelectedUser(user) {
    let idx = this.selectedUser.indexOf(user);
    if (idx === -1) {
      this.selectedUser.push(user)
    }
    if (typeof this.callback === "function") {
      this.callback();
    }
  }

  unsetSelectedUser(user) {
    let idx = this.selectedUser.indexOf(user);
    if (idx !== -1) {
      this.selectedUser.splice(idx, 1)
    }
    if (typeof this.callback === "function") {
      this.callback();
    }
  }

  getSelectedUser() {
    return this.selectedUser;
  }
}

export class DataProviderMessageList extends BaseDataProvider {
  constructor() {
    super()
    this.data = JSON.parse("[]");
    this.messageBatch = JSON.parse("[]");
  }

  updateMessageGuids(messageGuids) {
    this.data = messageGuids;
  }

  updateMessageBatch(messageBatch) {
    this.messageBatch = this.messageBatch.concat(messageBatch);
    if (typeof this.callback === 'function') {
      this.callback(this.messageBatch);
    }
  }

  isGuidInMessageBatch(messageGuid) {
    //look for elements
    //TODO: durch getMessageBatchIndex-Funktion ersetzen (Doppelter Code!)
    for (var j = 0; j < this.messageBatch.length; j++) {
      var messageObject = this.messageBatch[j];
      if (messageObject.Message.guid === messageGuid) {
        return true;
      }
    }
    return false;
  }

  getMessages() {
    //if(this.messageBatch.length > 0) {
    //  this.messageBatch = super.sortData(this.messageBatch, "datesend", 0)
    //}
    // TODO: Ist das notwendig ? -
    // IRE: Nein ;) string-compare reicht aus!
    return this.messageBatch.sort(function(a, b) {
      //let date1 = new Date(a.Message.datesend);
      //let date2 = new Date(b.Message.datesend);
      //automatisch nach dem SendeDatum sortieren (timedMessages)
      //if (typeof a.Message.datetosend != 'undefined')
      //  {
      //    date2 = new Date(a.Message.datetosend);
      //    date1 = new Date(b.Message.datetosend);
      //  } //ascending
      //return date2 - date1;
      return b.Message.datesend.localeCompare(a.Message.datesend);
    });
  }

  updateableMessages() {
    var guids = [];
    for (var i = 0, len = this.data.length; i < len; i++) {
      var element = this.data[i];
      let isInMessageBatch = this.isGuidInMessageBatch(element);
      if (!isInMessageBatch) {
        guids.push(element);
      }
    };
    return guids;
  }

  getMessageBatchIndex(guid) {
    for (var j = 0; j < this.messageBatch.length; j++) {
      var messageObject = this.messageBatch[j];
      if (messageObject.Message.guid === guid) {
        return j;
      }
    }
    return -1;
  }

  removeMessage(guid) {
    let guidIndex = this.data.indexOf(guid);
    if (guidIndex > -1) {
      this.data.splice(guidIndex, 1);
    }
    let messageBatchIndex = this.getMessageBatchIndex(guid);
    if (messageBatchIndex > -1) {
      this.messageBatch.splice(messageBatchIndex, 1);
    }
    if (typeof this.callback === 'function') {
      this.callback(this.messageBatch);
    }
  }

  reset() {
    this.data = JSON.parse("[]");
    this.messageBatch = JSON.parse("[]");
  }

  addCallback(callback) {
    this.callback = callback;
  }
}

export class DataProviderRoomWriter extends BaseDataProvider {
  // eslint-disable-next-line
  constructor() {
    super()
  }

  getRoomWriter() {
    return this.roomWriters;
  }

  isRoomWritersCapable() {
    //wir gehen davon aus, dass es dann mindestens eine
    //leere Liste von RoomWriters gibt!
    return (typeof this.roomWriters !== 'undefined');
  //return this.isCapable;
  }

  setRoomWriters(roomWriters) {
    //clear and setData...
    if (typeof roomWriters !== 'undefined') {
      this.roomWriters = [];
      for (var i = 0; i < roomWriters.length; i++) {
        this.roomWriters.push(roomWriters[i].guid);
      }
    }
  }

  addRoomWriter(user) {
    if (!this.isRoomWriter(user)) {
      this.roomWriters.push(user.guid);
    }
  }

  removeRoomWriter(user) {
    let guidIndex = this.roomWriters.indexOf(user.guid);
    if (guidIndex > -1) {
      this.roomWriters.splice(guidIndex, 1);
    }
  }

  isRoomWriter(user) {
    return this.roomWriters.includes(user.guid);
  }
}
export default DataProvider;