import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function DeviceAndroid(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 24 24">
              <path fill="#FFF" d="M15.7187285,3.62503546 C16.5986092,3.62503546 17.3124747,4.33890095 17.3124747,5.21878166 L17.3124747,19.0312487 C17.3124747,19.9111294 16.5986092,20.6249949 15.7187285,20.6249949 L8.2812462,20.6249949 C7.40136549,20.6249949 6.6875,19.9111294 6.6875,19.0312487 L6.6875,5.21878166 C6.6875,4.33890095 7.40136549,3.62503546 8.2812462,3.62503546 L15.7187285,3.62503546 Z M13.5937335,18.6328122 L13.5937335,18.3671878 C13.5937335,18.1480607 13.4144241,17.9687513 13.195297,17.9687513 L10.8046777,17.9687513 C10.5855506,17.9687513 10.4062411,18.1480607 10.4062411,18.3671878 L10.4062411,18.6328122 C10.4062411,18.8519393 10.5855506,19.0312487 10.8046777,19.0312487 L13.195297,19.0312487 C13.4144241,19.0312487 13.5937335,18.8519393 13.5937335,18.6328122 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default DeviceAndroid;
