/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getStatistics } from '../../actions/index';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Measure from 'react-measure';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { SubPageHeader } from '../../components/pageheader';

import CircularProgress from 'material-ui/CircularProgress';

import ActionStars from 'material-ui/svg-icons/action/stars';

import { colors, styles } from './DashboardStyle';
import { getAdminMuiTheme } from '../../components/styles.js';
const adminMuiTheme = getAdminMuiTheme();

function MessageDetailAktivitaetenChartTooltip(props) {

  const {t} = props;
  if (props.payload != null)
  {
    if (props.payload.length >= 4)
    {
      return <div style={ styles.dashboard_tooltip }>
               { t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1', {
                   v1: props.payload[0].value
                 }) }
               <br/>
               { t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2', {
                   v1: props.payload[1].value
                 }) }
               <br/>
               { t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3', {
                   v1: props.payload[2].value
                 }) }
               <br/>
               { t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4', {
                   v1: props.payload[3].value
                 }) }
             </div>;
    }
  }
  return <div style={ styles.dashboard_tooltip }></div>;
}

const DISPLAY_TIME_WEEK = 1;
const DISPLAY_TIME_MONTH = 2;
const DISPLAY_TIME_ALL = 3;

export class MessageDetailAktivitaetenChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      timeValue: DISPLAY_TIME_WEEK,
    };
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.currentData = this.currentData.bind(this);
    this.renderMessageDetailAktivitaetenChartSubTitle = this.renderMessageDetailAktivitaetenChartSubTitle.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.props.dispatch(getStatistics())
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  currentData() {

    if (this.state.timeValue === DISPLAY_TIME_WEEK)
    {
      return this.props.data.dataMessagesWeek
    }
    if (this.state.timeValue === DISPLAY_TIME_MONTH)
    {
      return this.props.data.dataMessagesMonth
    }
    return this.props.data.dataMessagesAll
  }

  countMessages(type) {

    var globalMessages = 0;

    switch (type) {
      case 'messages':
        globalMessages = this.currentData().reduce(function(sum, message) {
          return sum + message.messages;
        }, 0);
        break;

      case 'messagesSingle':
        globalMessages = this.currentData().reduce(function(sum, message) {
          return sum + message.messagesSingle;
        }, 0);
        break;

      case 'messagesGroup':
        globalMessages = this.currentData().reduce(function(sum, message) {
          return sum + message.messagesGroup;
        }, 0);
        break;

      case 'messagesChannel':
        globalMessages = this.currentData().reduce(function(sum, message) {
          return sum + message.messagesChannel;
        }, 0);
        break;

      default:
        globalMessages = this.currentData().reduce(function(sum, message) {
          return sum + message.messages;
        }, 0);

    }
    return globalMessages;
  }

  handleChangeTime(event, index, value) {
    this.setState({
      timeValue: value
    });
  }

  renderContent() {
    if (this.props.data != null)
    {
      return this.renderContentWithData()
    }
    else
    {
      return this.renderProgress()
    }
  }

  renderProgress() {
    return <div style={ styles.dashboard_outerDiv }>
             <div style={ { width: '100%' } }>
               <center>
                 <CircularProgress />
               </center>
             </div>
           </div>
  }

  renderContentWithData() {
    const {t} = this.props;

    var {width} = this.state.dimensions;
    var windowWidth = this.state.width;
    if (width > windowWidth - 120)
    {
      width = windowWidth - 120;
    }

    return <div>
             { this.renderMessageDetailAktivitaetenChartSubTitle() }
             <div style={ styles.dashboard_right }>
               <SelectField listStyle={ { backgroundColor: adminMuiTheme.palette.primary2Color } } value={ this.state.timeValue } onChange={ this.handleChangeTime } style={ styles.dashboard_selectbox }>
                 <MenuItem value={ DISPLAY_TIME_WEEK } primaryText={ t('modules-dashboard-MessageDetailAktivitaetenChart-timeValue1') } />
                 <MenuItem value={ DISPLAY_TIME_MONTH } primaryText={ t('modules-dashboard-MessageDetailAktivitaetenChart-timeValue2') } />
                 <MenuItem value={ DISPLAY_TIME_ALL } primaryText={ t('modules-dashboard-MessageDetailAktivitaetenChart-timeValue3') } />
               </SelectField>
             </div>
             <div style={ styles.dashboard_outerDiv }>
               <div style={ styles.dashboard_column1 }>
                 <LineChart width={ width } height={ 200 } data={ this.currentData() } style={ styles.dashboard_lineChart }>
                   <XAxis dataKey="dateStr" />
                   <YAxis yAxisId="left" orientation="left" stroke={ colors.YELLOW } type="number" domain={ ['dataMin-5', 'dataMax+5'] } tick={ false } tickLine={ false }
                   />
                   <YAxis yAxisId="right" orientation="right" stroke={ colors.WHITE } type="number" domain={ ['dataMin-5', 'dataMax+5'] } tick={ false } tickLine={ false }
                   />
                   <Tooltip content={ <MessageDetailAktivitaetenChartTooltip t={ t } /> } />
                   <CartesianGrid horizontal={ false } />
                   <Line type="monotone" dataKey="messages" stroke={ colors.YELLOW } yAxisId="left" strokeWidth={ 2 } />
                   <Line type="monotone" dataKey="messagesSingle" stroke={ colors.WHITE } yAxisId="left" strokeWidth={ 2 } />
                   <Line type="monotone" dataKey="messagesGroup" stroke={ colors.BLUE } yAxisId="left" strokeWidth={ 2 } />
                   <Line type="monotone" dataKey="messagesChannel" stroke={ colors.GREEN } yAxisId="left" strokeWidth={ 2 } />
                 </LineChart>
               </div>
             </div>
           </div>
  }

  renderMessageDetailAktivitaetenChartSubTitle() {
    const {t} = this.props;

    return <div style={ styles.dashboard_subTitle }>
             <ActionStars style={ styles.dashboard_subTitleIconYellow } />
             <span style={ styles.dashboard_subTitle2Yellow }>{ t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1', {
                                                                  v1: this.countMessages('messages')
                                                                }) }</span>
             <ActionStars style={ styles.dashboard_subTitleIconWhite } />
             <span style={ styles.dashboard_subTitle2White }>{ t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2', {
                                                                 v1: this.countMessages('messagesSingle')
                                                               }) }</span>
             <ActionStars style={ styles.dashboard_subTitleIconBlue } />
             <span style={ styles.dashboard_subTitle2Blue }>{ t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3', {
                                                                v1: this.countMessages('messagesGroup')
                                                              }) }</span>
             <ActionStars style={ styles.dashboard_subTitleIconGreen } />
             <span style={ styles.dashboard_subTitle2Green }>{ t('modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4', {
                                                                 v1: this.countMessages('messagesChannel')
                                                               }) }</span>
           </div>;
  }

  render() {
    const {t} = this.props;

    return (
      <Measure onMeasure={ (dimensions) => {
                       this.setState({
                         dimensions
                       })
                     } }>
        <div>
          <SubPageHeader>
            { t('modules-dashboard-MessageDetailAktivitaetenChart-messages') }
          </SubPageHeader>
          { this.renderContent() }
        </div>
      </Measure>
      );
  }
}

MessageDetailAktivitaetenChart.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object
}

function mapStateToProps(state) {
  //debugger;
  const {statistics} = state;

  return {
    isFetching: statistics.isFetching ? statistics.isFetching : false,
    error: statistics.error,
    data: statistics.hasData ? statistics.data : null
  }
}

const MessageDetailAktivitaetenChartTranslated = translate(['common'])(MessageDetailAktivitaetenChart);

export default connect(mapStateToProps)(MessageDetailAktivitaetenChartTranslated);
