/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
// import { connect } from 'react-redux';

import TextField from '../../../components/textfields/TextField';
import Avatar from 'material-ui/Avatar';

// import Chip from 'material-ui/Chip';

import FlatButton from '../../../components/buttons/FlatButton';

import ModalDialog from '../../../components/ModalDialog'

import { DataProvider, DataProviderUserList, DataProviderRoomWriter } from './DataProvider'
import UserList from './UserList'
// import IconSearch from '../../../ressources/icon_search';

// import { IsNullOrEmpty } from "../../../components/StringExtensions";
import { detectIE } from "../../../util/Browser";

import ChatMessagePreview from '../../../components/textfields/MessageTextField';

import { loadLatestFeedMessage } from '../../../actions/index';

const styles = {
  root: {
    width: 'fill',
    height: 'fill',
    display: 'flex',

  },
  newUserDiv: {
    minWidth: '580px',
    width: '600px',
    flex: '0 1 auto',
    overflowY: 'auto'
  },
  spacerDiv: {
    minWidth: '60px',
    width: '60px',
    flex: '0 1 auto',
    overflowY: 'none',
    background: 'linear-gradient(to right,#00375D, #00375D)',

  },
  keywordsDiv: {
    minWidth: '250px',
    flex: '1 1 auto',
    margin: '10px'
  },
  keywordsHeaderDiv: {
    display: 'block',
    width: 'fill',
    height: '30px'
  },
  right: {
    float: 'right',
    top: '0px',
    height: '30px'
  },
  keywordListDiv: {
    display: 'block',

  },
  keywordList: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '4px'
  },
  button: {
    margin: '5px'
  },
  editFieldDiv: {
    width: 'fill',
    display: 'flex',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '20px'

  },
  editField: {
    flex: '1 1 auto',
    width: 'fill'
  },
  editFieldLastName: {
    flex: '1 1 auto',
    width: 'fill',
    marginLeft: '10px'
  },
  buttonNewUserCancel: {
    flex: '1 1 auto',
    width: 'fill'
  },
  selectedUsersDiv: {
    width: 'fill',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '30px',
    paddingRight: '20px',
    paddingLeft: '5px',
    paddingBottom: '5px'
  },
  availableUsersDiv: {
    minWidth: '250px',
    flex: '1 1 auto',
    margin: '10px',
    height: 'fill'
  },
  availableUserHeaderDiv: {
    display: 'block',
    width: 'fill',
    height: '30px'
  },

  availableUsersListDiv: {
    display: 'block',
    paddingTop: '20px'
  },
  grayText: {
    color: '#7B98AB',
    paddingLeft: '20px',
    fontSize: '14px'
  },


};

const USERLIST_ASSIGNED_USER = 1;
const USERLIST_AVAILABLE_USER = 2;

class GroupDetail extends Component {

  constructor(props) {
    super(props);
    this.dataprovider = new DataProvider();

    var groupData = props.group;
    if (typeof groupData === 'undefined')
    {
      groupData = {
        name: '',
      }
    }

    var assignedUser = new DataProviderUserList();
    var availableUser = new DataProviderUserList();
    var roomWriter = new DataProviderRoomWriter();

    availableUser.setUserData(this.dataprovider.getAvailableUser(), groupData.members);
    if (props.roomType === 'RestrictedRoom' && props.viewMode === 1)
    {
      roomWriter.setRoomWriters([]);
    }
    else
    {
      roomWriter.setRoomWriters(groupData.writers);
    }

    let selectedUser = availableUser.getSelectedUser();

    assignedUser.setUserData(selectedUser)

    this.state = {
      groupData: groupData,
      assignedUser: assignedUser,
      availableUser: availableUser,
      roomWriter: roomWriter,
      viewMode: props.viewMode,
      searchText: '',
      latestFeedMessagePreview: {
        title: '',
        text: '',
        link: '',
        guid: '',
      },
    }
    this.handleNewGroupCancelClicked = this.handleNewGroupCancelClicked.bind(this);
    this.handleNewGroupSaveClicked = this.handleNewGroupSaveClicked.bind(this);
    this.handleUpdateGroupSaveClicked = this.handleUpdateGroupSaveClicked.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleTableRowSelectionAvailableUser = this.handleTableRowSelectionAvailableUser.bind(this);
    this.handleTableRowDeleteAssignedUser = this.handleTableRowDeleteAssignedUser.bind(this);
    this.handleImportFileClicked = this.handleImportFileClicked.bind(this);
    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.fileContentLoaded = this.fileContentLoaded.bind(this);
    this.handleRoomWriterUpdate = this.handleRoomWriterUpdate.bind(this);

    this.handleLoadGroupImage = this.handleLoadGroupImage.bind(this);
    this.loadGroupImageSuccess = this.loadGroupImageSuccess.bind(this);
    if (groupData.smallImage != null && typeof groupData.smallImage !== "undefined")
    {
      this.handleLoadGroupImage();
    }

    this.loadLatestFeedMessageSuccess = this.loadLatestFeedMessageSuccess.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.loadLatestFeedMessage();

  }

  loadGroup(groupData) {

    var assignedUser = this.state.assignedUser;
    var availableUser = this.state.availableUser;
    var roomWriter = this.state.roomWriter;

    availableUser.setUserData(this.dataprovider.getAvailableUser(), groupData.members);
    if (this.state.viewMode === 1)
    {
      roomWriter.setRoomWriters([]);
    }
    else
    {
      roomWriter.setRoomWriters(groupData.writers);
    }

    let selectedUser = availableUser.getSelectedUser();

    assignedUser.setUserData(selectedUser)

    this.setState({
      groupData: groupData,
      assignedUser: assignedUser,
      availableUser: availableUser,
      roomWriter: roomWriter,
    })

  }

  notifyPendingChanges(isPending) {
    if (typeof this.props.onPendingChanges === 'function')
    {
      this.props.onPendingChanges(isPending);
    }
  }

  handleChangeData(event, name) {
    let oldData = JSON.parse(JSON.stringify(this.state.groupData))
    oldData[name] = event.target.value;
    if (name === "search")
    {
      this.setState({
        searchText: event.target.value
      })
    }
    else
    {
      /**Bug 44314 - Eingabefelder reagieren sehr langsam auf IE 11 **/
      /** IE11 Bug -> as setState has high performance cost and as we do not need
                      to update the userList on typing in - we can manually
                      notify Group.js on changed Input ...
          **/
      /*this.setState({
        groupData: oldData,
      });*/
      // eslint-disable-next-line
      this.state.groupData = oldData;
      this.notifyPendingChanges(true);
    }
  }

  handleNewGroupCancelClicked() {
    this.setState({
      groupData: {
        name: '',
        member: []
      },
    });
    this.notifyPendingChanges(false);
  }

  handleNewGroupSaveClicked() {
    if (typeof this.props.onCreateGroup === 'function')
    {
      var image = null;
      if (typeof this.state.imageUploaded !== "undefined" && this.state.imageUploaded !== null)
      {
        image = this.state.imageUploaded;
      }

      var roomwriters = null;
      if (typeof this.state.roomWriter !== 'undefined')
      {
        roomwriters = this.state.roomWriter.getRoomWriter();
      }

      this.props.onCreateGroup(this.state.groupData, image, this.state.assignedUser.getData(), roomwriters);
    }
  }

  handleUpdateGroupSaveClicked() {
    if (typeof this.props.onUpdateGroup === 'function')
    {
      var image = null;
      if (typeof this.state.imageUploaded !== "undefined" && this.state.imageUploaded !== null)
      {
        image = this.state.imageUploaded;
      }

      var roomwriters = null;
      if (typeof this.state.roomWriter !== 'undefined')
      {
        roomwriters = this.state.roomWriter.getRoomWriter();
      }

      this.props.onUpdateGroup(this.state.groupData, image, this.state.assignedUser.getData(), roomwriters);
    }
  }

  handleLoadGroupImage() {
    if (typeof this.props.loadGroupImage === 'function')
    {
      this.props.loadGroupImage(this.state.groupData.guid, this.loadGroupImageSuccess)
    }

  }

  loadGroupImageSuccess(image) {
    this.setState({
      imageExisting: image
    });
  }


  isEmpty(value) {
    if (value == null || value.length === 0)
    {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.groupData === undefined)
    {
      if (typeof this.props.listEditModeListener === "function")
      {
        this.props.listEditModeListener(false, false);
      }
    }
    else
    {
      let visible = true;
      let enabled = visible && !this.isEmpty(nextState.groupData.name);
      if (typeof this.props.listEditModeListener === "function")
      {
        this.props.listEditModeListener(visible, enabled);
      }
    }
  }


  getInnerHeight(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "maxHeight": currentHeight + "px",

    });
  }

  getInnerHeightFixed(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "minHeight": currentHeight + "px",
      "maxHeight": currentHeight + "px",

    });
  }

  handleTableRowSelectionAvailableUser() {
    let selectedUser = this.state.availableUser.getSelectedUser();

    var assignedUser = this.state.assignedUser;
    assignedUser.setUserData(selectedUser)
    this.setState({
      assignedUser: assignedUser,
    });
    this.notifyPendingChanges(true);
  }

  handleTableRowDeleteAssignedUser(user) {
    //TODO: Die Sleektion mag nicht aktualisieren --> Umbau auf eigene Selection notwendig ?!

    let selectedUser = this.state.availableUser.getSelectedUser();

    // let index = -1;
    for (var i = 0; i < selectedUser.length; i++)
    {
      if (user.guid === selectedUser[i].guid)
      {
        this.state.availableUser.unsetSelectedUser(selectedUser[i])
        break;
      }
    }

    var assignedUser = this.state.assignedUser;
    assignedUser.setUserData(this.state.availableUser.getSelectedUser())
    this.setState({
      assignedUser: assignedUser,
    });
    this.notifyPendingChanges(true);

    //der Nutzer muss auch aus den roomWriters gelöscht werden:
    //aber nur, wenn wir in den Kanälen sind
    var roomWriters = this.state.roomWriters;
    if (typeof roomWriters !== 'undefined')
    {
      roomWriters.removeRoomWriter(user);
      this.setState({
        roomWriters: roomWriters,
      });
      this.notifyPendingChanges(true);
    }
  }


  fileContentLoaded(e) {
    var content = e.target.result;
    var base64 = content.substr(content.indexOf(',') + 1)

    this.setState({
      imageUploaded: base64
    });
    this.notifyPendingChanges(true);
  }
  onFileLoaded(e) {
    e.preventDefault();


    var files;
    if (e.dataTransfer)
    {
      files = e.dataTransfer.files;
    }
    else if (e.target)
    {
      files = e.target.files;
    }

    if (files.length === 1)
    {
      var fileReader = new FileReader();
      fileReader.onloadend = this.fileContentLoaded;
      fileReader.readAsDataURL(files[0])
    }
  }

  handleImportFileClicked() {
    this.refs.fileInput.click();
  }

  handleRoomWriterUpdate(roomWriterDataProvider) {
    //Wird nicht benötigt, da der Dataprovider bereits in der
    //Userlist "geupdated" wird
    this.notifyPendingChanges(true);
  }

  renderNoMemberText() {
    const {t} = this.props;
    if (typeof this.props.customNoMemberText !== 'undefined')
    {
      return this.props.customNoMemberText;
    }
    else
    {
      return t('modules-groups-NewGroup-noMember');
    }
  }

  renderAssignedUser() {
    // const {t} = this.props;

    if (typeof this.state.assignedUser === "undefined"
      || this.state.assignedUser.getDataCount() === 0)
    {
      return <div style={ styles.selectedUsersDiv }>
               <span style={ styles.grayText }>{ this.renderNoMemberText() }</span>
             </div>;
    }
    return <div style={ styles.selectedUsersDiv }>
             <UserList dataprovider={ this.state.assignedUser } searchText={ this.state.searchText } roomWriterDataProvider={ this.state.roomWriter } viewMode={ USERLIST_ASSIGNED_USER } onTableRowDelete={ this.handleTableRowDeleteAssignedUser }
               onRoomWriterUpdate={ this.handleRoomWriterUpdate } CustomKeywordColumnStyle={ (typeof this.state.roomWriter !== 'undefined' && this.state.roomWriter.isRoomWritersCapable()) ? {
                                                                                               width: 'auto'
                                                                                             } : {} } />
           </div>;
  }



  renderGroupImage() {
    const isIE = detectIE();
    if (typeof this.state.imageUploaded !== "undefined" && this.state.imageUploaded !== null)
    {
      return <Avatar size={ 80 } style={ (isIE !== false) ? {
                              backgroundSize: "cover",
                              backgroundPosition: "center center"
                            } : {
                              objectFit: 'cover'
                            } } src={ "data:image/jpeg;base64," + this.state.imageUploaded } />;
    }
    if (typeof this.state.imageExisting !== "undefined" && this.state.imageExisting !== null)
    {
      return <Avatar size={ 80 } src={ "data:image/jpeg;base64," + this.state.imageExisting } />;
    }
    return <Avatar size={ 80 } backgroundColor={ "#7B98AB" } color={ "#7B98AB" }>+</Avatar>;
  }

  renderFeedUrlTextfield() {
    const {t} = this.props;

    let messageText = t('modules-groups-NewGroup-placeholder-RSS-preview');
    let showRssPreview = false;

    let rssMessage = null;

    if (this.props.viewMode === 2)
    {
      if ((typeof this.state.groupData.feed !== 'undefined') && this.state.groupData.feed.length > 0)
      {
        showRssPreview = true;
        if (this.state.latestFeedMessagePreview.title.length > 0)
        {
          rssMessage = this.state.latestFeedMessagePreview;
        }
      }
    }

    // bei einem ManagedRoom ist writers undefined, ansonsten mindestens eine leere Liste
    if (typeof this.state.roomWriter !== 'undefined' && this.state.roomWriter.isRoomWritersCapable())
    {
      return (<div>
                <div style={ styles.editFieldDiv }>
                  <TextField style={ styles.editField } hintText={ t('modules-groups-NewGroup-placeholder-feed') } onChange={ (event) => this.handleChangeData(event, 'feed') } value={ this.state.groupData['feed'] } />
                  <FlatButton label={ t('modules-groups-GroupDetail-btnCheckFeedUrl') } secondary={ true } onClick={ () => this.loadLatestFeedMessage() } />
                </div>
                { showRssPreview &&
                  <div style={ styles.editFieldDiv }>
                    <ChatMessagePreview picture='' attachmentInfo='' messageText={ messageText } rssMessage={ rssMessage } sendTime='' sendDate='' ShowMessagePreview={ true }
                      attachment='' />
                  </div> }
              </div>
        );
    }

    return
  }

  loadLatestFeedMessage() {
    // beim Anlegen einer neuen Gruppe wird die latestFeedMessage nicht geladen
    if (typeof this.state.groupData === 'undefined')
    {
      return;
    }
    if ((typeof this.state.groupData.feed !== 'undefined') && this.state.groupData.feed.length > 0)
    {
      this.props.dispatch(loadLatestFeedMessage(this.state.groupData.feed, this.loadLatestFeedMessageSuccess, this.errorHandler));
    }
  }

  loadLatestFeedMessageSuccess(json) {
    if (typeof json.latestMessage.RssMessage !== 'undefined')
    {
      this.setState({
        latestFeedMessagePreview: json.latestMessage.RssMessage,
      });
    }
  }

  errorHandler(errorJO) {
    const {t} = this.props;

    let errorCode = "SERVICE-" + errorJO.ident;
    let errorText = t(errorCode);
    this.refs.modalDialog.open({
      text: errorText,
      button1Text: t("components-ModalDialog-ok")
    });
  }

  render() {
    const {t} = this.props;
    return (
      <div style={ styles.root }>
        <ModalDialog ref="modalDialog" />
        <input style={ { display: 'none' } } accept=".jpg" type='file' ref='fileInput' onChange={ this.onFileLoaded } />
        <div style={ this.getInnerHeight(styles.newUserDiv) }>
          <div style={ styles.editFieldDiv }>
            <TextField style={ styles.editField } hintText={ t('modules-groups-NewGroup-placeholder-name') } onChange={ (event) => this.handleChangeData(event, 'name') } value={ this.state.groupData['name'] } />
          </div>
          <div style={ styles.editFieldDiv }>
            { this.renderGroupImage() }
            <FlatButton label={ t('modules-groups-NewGroup-btnImportImage') } secondary={ true } onClick={ this.handleImportFileClicked } style={ { marginTop: "22px" } } />
          </div>
          { this.renderFeedUrlTextfield() }
          { this.renderAssignedUser() }
        </div>
        <div style={ this.getInnerHeightFixed(styles.spacerDiv) }>
        </div>
        <div style={ styles.availableUsersDiv }>
          <div style={ styles.availableUserHeaderDiv }>
            <div style={ styles.searchText }>
              <TextField hintText={ t('modules-groups-NewGroup-searchHint') } fullWidth={ true } onChange={ (event) => this.handleChangeData(event, 'search') } />
            </div>
          </div>
          <div style={ styles.availableUsersListDiv }>
            <UserList ref="userList" searchText={ this.state.searchText } dataprovider={ this.state.availableUser } viewMode={ USERLIST_AVAILABLE_USER } onTableRowSelection={ this.handleTableRowSelectionAvailableUser }
            />
          </div>
        </div>
      </div>)


  }
}

export default translate(['common'], {
  withRef: true
})(GroupDetail);
