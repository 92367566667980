/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';

const styles = {
  button: {
    padding: '5px',
    boxShadow: '',
  },
  content: {
    maxWidth: 400,
  },

};

export class ModalDialogLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      text: "c",
      button1: "c",
      button2: "c",
    };
    this.handleCloseButton1 = this.handleCloseButton1.bind(this);
    this.handleCloseButton2 = this.handleCloseButton2.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  // TODO :: ButtonHandler registrien
  isOpen() {
    return this.state.open;
  }

  open(parameter) {
    let button1Text = null;
    let button1Handler = null;
    let button2Text = null;
    let button2Handler = null;

    if (typeof parameter.button1Text === "string")
    {
      button1Text = parameter.button1Text
    }
    if (typeof parameter.button1Handler === "function")
    {
      button1Handler = parameter.button1Handler
    }
    if (typeof parameter.button2Text === "string")
    {
      button2Text = parameter.button2Text
    }
    if (typeof parameter.button2Handler === "function")
    {
      button2Handler = parameter.button2Handler
    }
    this.setState({
      button1: button1Text,
      button1Handler: button1Handler,
      button2: button2Text,
      button2Handler: button2Handler,
      open: true
    });

  }

  handleCloseButton1() {
    if (typeof this.state.button1Handler === "function")
    {
      this.state.button1Handler();
    }
    this.setState({
      open: false
    });
  }
  handleCloseButton2() {
    if (typeof this.state.button2Handler === "function")
    {
      this.state.button2Handler();
    }
    this.setState({
      open: false
    });
  }
  handleClose() {
    this.setState({
      open: false
    });
  }

  render() {
    return (
      <div>
        <Dialog contentStyle={ styles.content } modal={ false } open={ this.state.open } onRequestClose={ this.handleClose }>
          <RaisedButton style={ styles.button } label={ this.state.button1 } primary={ true } onTouchTap={ this.handleCloseButton1 } fullWidth={ true } />
          <br />
          <RaisedButton style={ styles.button } label={ this.state.button2 } primary={ true } onTouchTap={ this.handleCloseButton2 } fullWidth={ true }
          />
        </Dialog>
      </div>
      );
  }
}

export default ModalDialogLink;
