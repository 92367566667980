import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconCheck(props) {
  return <SvgIcon {...props}>
             <svg viewBox="0 0 20 21">
               <path fill="#7B98AB" d="M9,0 C13.9717742,0 18,4.02822581 18,9 C18,13.9717742 13.9717742,18 9,18 C4.02822581,18 0,13.9717742 0,9 C0,4.02822581 4.02822581,0 9,0 Z M9,16.2580645 C13.0100806,16.2580645 16.2580645,13.0100806 16.2580645,9 C16.2580645,4.98991935 13.0100806,1.74193548 9,1.74193548 C4.98991935,1.74193548 1.74193548,4.98991935 1.74193548,9 C1.74193548,13.0100806 4.98991935,16.2580645 9,16.2580645 Z" transform="translate(1 1.5)"/>
             </svg>
         </SvgIcon>;
}
;

export default IconCheck;
