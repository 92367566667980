/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
// import Dialog from 'material-ui/Dialog';
// import RefreshIndicator from 'material-ui/RefreshIndicator';
import Spinner from '../ressources/symbols-loader.png'

const styles = {
  waitDialogVisible: {
    visibility: 'visible'
  },
  waitDialogHidden: {
    visibility: 'hidden'
  },
};

export class WaitDialog extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  open() {
    this.setState({
      open: true
    });
  }
  close() {
    this.setState({
      open: false
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {

    return (
      <div className="waitDialogDiv" style={ (this.state.open === true) ? styles.waitDialogVisible : styles.waitDialogHidden }>
        <img alt="" className="waitDialogImage" src={ Spinner } />
      </div>
      );
  }
}

export default WaitDialog;
