import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function DeviceIPhone(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 24 24">
              <path fill="#FFF" d="M15.7187285,3.62503546 C16.5986092,3.62503546 17.3124747,4.33890095 17.3124747,5.21878166 L17.3124747,19.0312487 C17.3124747,19.9111294 16.5986092,20.6249949 15.7187285,20.6249949 L8.2812462,20.6249949 C7.40136549,20.6249949 6.6875,19.9111294 6.6875,19.0312487 L6.6875,5.21878166 C6.6875,4.33890095 7.40136549,3.62503546 8.2812462,3.62503546 L15.7187285,3.62503546 Z M11.9999873,19.5624975 C12.5876877,19.5624975 13.0624848,19.0877004 13.0624848,18.5 C13.0624848,17.9122996 12.5876877,17.4375025 11.9999873,17.4375025 C11.4122869,17.4375025 10.9374899,17.9122996 10.9374899,18.5 C10.9374899,19.0877004 11.4122869,19.5624975 11.9999873,19.5624975 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default DeviceIPhone;
