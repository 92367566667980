/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
// import { connect, compose } from 'react-redux';

import TextField from '../../components/textfields/TextField';
//  import Snackbar from 'material-ui/Snackbar';
import Popover from 'react-simple-popover';

// import Avatar from 'material-ui/Avatar';

// import FlatButton from '../../components/buttons/FlatButton';
// import RaisedButton from '../../components/buttons/RaisedButton';
import IconButton from 'material-ui/IconButton';

import { WaitDialog } from '../../components/WaitDialog';
import { ModalDialog } from '../../components/ModalDialog';
// import { CustomModalDialog } from '../../components/CustomModalDialog';

import DataProvider from './DataProvider';

// import IconSearch from '../../ressources/icon_search';
// import IconTabelleNotActivated from '../../ressources/tabelle_not_activated';
import IconInfo from '../../ressources/icon_info';

// import { IsNullOrEmpty } from '../../components/StringExtensions';
// import { COLOR_PRIMARY_1 } from '../../util/Constants.js';

import IconLastLoginOk from '../../ressources/tabelle_status_on';
import IconLastLoginNever from '../../ressources/tabelle_status_eingeladen';

require('../../components/StringExtensions');

const styles = {
  root: {
    width: 'fill',
    height: 'fill',
    display: 'flex',

  },
  newAdminDiv: {
    minWidth: '470px',
    width: '450px',
    flex: '0 1 auto',
    overflowY: 'none'
  },
  spacerDivWidth: {
    minWidth: '60px',
    width: '60px',
    flex: '0 1 auto',
    overflowY: 'none'
  },
  searchText: {
    color: '#7B98AB',
    float: 'left',
    paddingTop: '0px',
    height: '30px'
  },
  right: {
    float: 'right',
    top: '0px',
    height: '30px'
  },
  editFieldDiv: {
    width: 'fill',
    display: 'flex',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  editField: {
    flex: '1 1 auto',
    width: 'fill',
  },
  editFieldFirstName: {
    flex: '1 1 auto',
    width: 'fill'
  },
  editFieldLastName: {
    flex: '1 1 auto',
    width: 'fill',
    marginLeft: '10px'
  },
  floatingIcon: {
    float: 'left',
    marginRight: 10,
  },
  infoButton: {
    backgroundColor: '#FFFFFF',
  },
  popupText: {
    color: '#333333',
    fontSize: '12px',
    fontWeight: 'medium',
  },
  textWhiteCenterFlex: {
    display: 'flex',
    flexFlow: 'row',
    alignContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    fontSize: '14px',
  },
  spacerDivHeight: {
    height: '40px',
    minHeight: '40px'
  },
  textYellow: {
    color: '#EEC643',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  textWhite: {
    color: ' #FFFFFF',
    fontSize: '14px'
  }
};

const NEW_ADMIN = 1;
const SHOW_ADMIN = 2;


export class AdminDetail extends Component {

  constructor(props) {
    super(props);
    this.dataprovider = new DataProvider();

    var adminData = props.admin;
    if (typeof adminData === 'undefined')
    {
      adminData = {
        name: '',
        firstName: '',
        mail: '',
        phoneNumber: '',
        lastAuditLog: '',
        lastLogin: ''
      }
    }

    this.state = {
      visible: true,
      adminData: adminData,
      viewMode: props.viewMode,
      sortColumn: 1,
      sortDirection: 0,
      searchText: '',
      showPhoneInfoBox: false,
    }
    this.handleChangeData = this.handleChangeData.bind(this);

    this.loadAdmin = this.loadAdmin.bind(this);

    this.handleSwitchAdminClicked = this.handleSwitchAdminClicked.bind(this);

    this.errorHandler = this.errorHandler.bind(this);

    this.handlePhoneInfoButtonClick = this.handlePhoneInfoButtonClick.bind(this);
    this.handlePhoneInfoButtonClose = this.handlePhoneInfoButtonClose.bind(this);
  }

  errorHandler(errorJO) {
    this.refs.waitDialog.close();
    const {t} = this.props;

    let errorCode = "SERVICE-" + errorJO.ident;
    let errorText = t(errorCode);
    this.refs.modalDialog.open({
      text: errorText,
      button1Text: t("components-ModalDialog-ok")
    });
  }


  loadAdmin(admin) {
    this.setState({
      adminData: admin,
      viewMode: SHOW_ADMIN,
    });
  }

  notifyPendingChanges(isPending) {
    if (typeof this.props.onPendingChanges === 'function')
    {
      this.props.onPendingChanges(isPending);
    }
  }

  handleChangeData(event, name) {
    let oldData = JSON.parse(JSON.stringify(this.state.adminData))
    oldData[name] = event.target.value;
    if (name === "search")
    {
      this.setState({
        searchText: event.target.value
      })
    }
    else
    {
      this.notifyPendingChanges(true);
    }
    this.setState({
      adminData: oldData
    });
  }

  handleNewAdminSaveClicked() {
    if (typeof this.props.onCreateAdmin === 'function')
    {
      this.props.onCreateAdmin(this.state.adminData);
    }
  }


  isEmpty(value) {
    if (value == null || value.length === 0)
    {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.adminData === undefined)
    {
      if (typeof this.props.listEditModeListener === "function")
      {
        this.props.listEditModeListener(false, false);
      }
    }
    else
    {
      let visible = true;
      let enabled = visible && !this.isEmpty(nextState.adminData.name) && !this.isEmpty(nextState.adminData.firstName);

      if (this.state.viewMode === NEW_ADMIN)
      {
        visible = (!this.isEmpty(nextState.adminData.name) || !this.isEmpty(nextState.adminData.firstName)
          || !this.isEmpty(nextState.adminData.mail) || !this.isEmpty(nextState.adminData.phoneNumber));
        enabled = visible && !this.isEmpty(nextState.adminData.name) && !this.isEmpty(nextState.adminData.firstName)
          && !this.isEmpty(nextState.adminData.mail) && !this.isEmpty(nextState.adminData.phoneNumber);

      }

      if (typeof this.props.listEditModeListener === "function")
      {
        this.props.listEditModeListener(visible, enabled);
      }
    }
  }

  handleSwitchAdminClicked(admin) {
    if (typeof this.props.onSwitchAdminClicked === 'function')
    {
      this.props.onSwitchAdminClicked(admin);
    }

  }

  getInnerHeight(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "maxHeight": currentHeight + "px",

    });
  }

  getInnerHeightFixed(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "minHeight": currentHeight + "px",
      "maxHeight": currentHeight + "px",

    });
  }

  handlePhoneInfoButtonClick() {
    this.setState({
      showPhoneInfoBox: !this.state.showPhoneInfoBox
    });
  }

  handlePhoneInfoButtonClose() {
    this.setState({
      showPhoneInfoBox: false
    })
  }

  renderAdminLastLogin() {
    const {t} = this.props;

    let admin = this.state.adminData;

    // durch die Admin Infos iterieren
    if (admin && admin.lastLogin && admin.lastLogin.length > 0)
    {
       return <div style={ styles.textWhiteCenterFlex }>
                <IconLastLoginOk style={ styles.floatingIcon } />
                { t("modules-settings-AdminDetails-last-login").concat(" ", new Date().parseUtcDate(admin.lastLogin).getShortDateTime()) }
             </div>;
    }
    else
    {
        return <div style={ styles.textWhiteCenterFlex }>
                 <IconLastLoginNever style={ styles.floatingIcon }/>
                 { t("settings-stellvertreter-last-login-never") }
               </div>;
    }
  }

  renderAdminInput() {
    const {t} = this.props;
    if (this.state.viewMode === NEW_ADMIN)
    {
      return <div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editFieldFirstName } onChange={ (event) => this.handleChangeData(event, 'firstName') } value={ this.state.adminData['firstName'] } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column2') } />
                 <TextField style={ styles.editFieldLastName } onChange={ (event) => this.handleChangeData(event, 'name') } value={ this.state.adminData['name'] } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column1') } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-User-email-hinttext') } onChange={ (event) => this.handleChangeData(event, 'mail') } value={ this.state.adminData['mail'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column4') } onChange={ (event) => this.handleChangeData(event, 'phoneNumber') } value={ this.state.adminData['phoneNumber'] } />
                 <IconButton onClick={ this.handlePhoneInfoButtonClick } ref="phoneInfoButton">
                   <IconInfo />
                 </IconButton>
                 <Popover placement='right' show={ this.state.showPhoneInfoBox } onHide={ this.handlePhoneInfoButtonClose } target={ this.refs.phoneInfoButton } style={ styles.infoButton }>
                   <span style={ styles.popupText }>{ t('modules-user-UserList-popover-phone') }</span>
                 </Popover>
               </div>
             </div>
    }
    else
    {
      return <div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editFieldFirstName } disabled={ true } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column2') } onChange={ (event) => this.handleChangeData(event, 'firstName') } value={ this.state.adminData['firstName'] } />
                 <TextField style={ styles.editFieldLastName } disabled={ true } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column1') } onChange={ (event) => this.handleChangeData(event, 'name') } value={ this.state.adminData['name'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } disabled={ true } floatingLabelText={ t('modules-user-User-email-hinttext') } onChange={ (event) => this.handleChangeData(event, 'mail') } value={ this.state.adminData['mail'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } disabled={ true } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column4') } onChange={ (event) => this.handleChangeData(event, 'phoneNumber') } value={ this.state.adminData['phoneNumber'] } />
               </div>
             </div>
    }
  }

  renderAdminDetails() {
    const {t} = this.props;
    if (this.state.viewMode === NEW_ADMIN)
    {
      return <div>
               <div style={ styles.spacerDivHeight }>
               </div>
               <div style={ styles.textYellow }>
                  { t("modules-settings-NewAdmin-hint-title") }
               </div>
               <div style={ styles.textWhite }>
                  { t("modules-settings-NewAdmin-hint") }
               </div>
             </div>
    }
    else
    {
      return <div>
                <div style={ styles.spacerDivHeight }>
                </div>
                { this.renderAdminLastLogin() }
             </div>
    }
  }

  render() {
    // const {t} = this.props;
    if (!this.state.visible)
    {
      return <div></div>;
    }

    return (
      <div style={ styles.root }>
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        <div style={ this.getInnerHeight(styles.newAdminDiv) }>
          { this.renderAdminInput() }
        </div>
        <div style={ this.getInnerHeight(styles.spacerDivWidth) }>
        </div>
        <div style={ this.getInnerHeight(styles.spacerDivWidth) }>
        </div>
        <div style={ this.getInnerHeight(styles.newAdminDiv) }>
          { this.renderAdminDetails() }
        </div>
      </div>)


  }
}

/*
function mapStateToProps(state) {
  //TODO
  return {
  }
}

const AdminDetailTranslated = translate(['common'], {
  withRef: true
})(AdminDetail);
*/

/* export default connect(  mapStateToProps,
  null,
  null,
  // IMPORTANT: must pass this flag to react-redux/connect
 {
    withRef: true,
  })(translate(['common'])(AdminDetail)); */
export default translate(['common'], {
  withRef: true
})(AdminDetail);
