/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';

const HEADER_HEIGHT = '60px';

const baseStyles = {
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerDiv: {
    minHeight: HEADER_HEIGHT,
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
    paddingBottom: '0px',
  },
  headerPageLabelDiv: {
    float: 'left',
    display: 'inline-flex',
    height: HEADER_HEIGHT,
  },
  headerPageLabelDiv2: {
    float: 'left',
    display: 'inline-flex',
    height: HEADER_HEIGHT,
    marginLeft: '25px'

  },
  contentDiv: {
    width: '100%',
    //height: '100%',
    overflowY: 'auto',
  //flexGrow:"1",
  //flexBasis:"100%",
  //paddingBottom: '30px',
  },
  right: {
    float: 'right',
    paddingRight: '20px',
    display: 'inline-flex',
    height: HEADER_HEIGHT,
    alignItems: 'center',
  },
  button: {
    margin: 'auto 5px'
  },
  cancelButton: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  footer: {
    height: '80px',
    maxHeight: '80px',
    bottom: '0px',
    top: 'auto',
    left: '0px',
    right: '0px',
    width: '100%',
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
    display: 'block',
    //    position: 'absolute'

  },
  fabRight: {
    float: 'right',
    paddingRight: '20px',
    display: 'inline-flex',
    height: '80px',
    alignItems: 'center',
  },
  fab: {
    margin: 'auto'
  }
};

class BaseModul extends Component {


  constructor(props) {
    super(props);
    this.state = {
      height: '0px'
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.checkUnsavedChanges = this.checkUnsavedChanges.bind(this);

    this.routerWillLeave = this.routerWillLeave.bind(this);

    this.mIsDirty = false;

  }

  getBaseStyle() {
    return baseStyles;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    window.addEventListener("beforeunload", this.checkUnsavedChanges)

    if (typeof this.props.router !== "undefined")
    {
      this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    window.removeEventListener('beforeunload', this.checkUnsavedChanges);
  }

  setDirty() {
    this.mIsDirty = true;
  }

  clearDirty() {
    this.mIsDirty = false;
  }

  checkUnsavedChanges(event) {
    if (this.mIsDirty)
    {
      const {t} = this.props;

      event.returnValue = t("components-ModalDialog-looseDataQuestion")
    }
  }

  routerWillLeave(nextLocation) {
    if (this.mIsDirty)
    {
      const {t} = this.props;

      return t("components-ModalDialog-looseDataQuestion")
    }
  }

  commonErrorHandler(errorJO, handlerReloadData, handlerReloadDiscardData, handlerReloadDeletedData) {
    const {t} = this.props;

    let userName;
    let changeDate;
    let datum;
    let errorCode = "SERVICE-" + errorJO.ident;
    if (errorCode === "SERVICE-ADMIN-ERR-0181")
    {
      userName = "System";
      if (typeof errorJO.detail.Admin !== 'undefined')
      {
        userName = errorJO.detail.Admin.name;
      }
      userName = userName.replace("{", "[")
      changeDate = errorJO.detail.date;

      datum = new Date().parseUtcDate(changeDate)

      let dateString = datum.getShortDateTime();

      let errorText = t(errorCode, {
        username: userName,
        date: dateString
      });
      let yesNoDialog = {
        text: errorText,
        button1Text: t("components-reload-data"),
        button1Handler: handlerReloadData,
        button2Text: t("components-continue"),
        button2Handler: handlerReloadDiscardData,
        title: t("components-ModalDialog-Warning"),
      };
      this.refs.commonErrorDialog.open(yesNoDialog);
      return;
    }
    if (errorCode === "SERVICE-ADMIN-ERR-0182")
    {
      userName = "System";
      if (typeof errorJO.detail.Admin !== 'undefined')
      {
        userName = errorJO.detail.Admin.name;
      }
      userName = userName.replace("{", "[")
      changeDate = errorJO.detail.date;

      datum = new Date().parseUtcDate(changeDate);

      let dateString = datum.getShortDateTime();

      let errorText = t(errorCode, {
        username: userName,
        date: dateString
      });
      let yesNoDialog = {
        text: errorText,
        button1Text: t("components-ModalDialog-ok"),
        button1Handler: handlerReloadDeletedData,
        title: t("components-ModalDialog-Warning"),
      };
      this.refs.commonErrorDialog.open(yesNoDialog);
      return;
    }
    let errorText = t(errorCode);
    this.refs.commonErrorDialog.open({
      text: errorText,
      button1Text: t("components-ModalDialog-ok")
    });
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  renderHiddenDialog() {
    return <div>overwrite renderHiddenDialog()</div>
  }

  showCommonButton() {
    return true;
  }

  renderCommonButton() {
    return <div>overwrite renderCommonButton()</div>
  }

  getInnerStyle() {
    let currentHeight = parseFloat(this.state.height) - (this.showCommonButton() ? 200 : 150);
    return Object.assign({}, baseStyles.contentDiv, {
      "maxHeight": currentHeight + "px",
      "height": currentHeight + "px",
    //"height":this.state.height,
    //"maxHeight":this.state.height,
    });

  }

  renderHeaderView() {
    return <div>overwrite renderHeaderView()</div>
  }

  renderMainView() {
    return <div>overwrite renderMainView()</div>
  }

  render() {

    return (
      <div style={ baseStyles.root }>
        { this.renderHiddenDialog() }
        <div style={ baseStyles.headerDiv }>
          { this.renderHeaderView() }
        </div>
        <div style={ this.getInnerStyle() }>
          { this.renderMainView() }
        </div>
        { this.showCommonButton() &&
          <div style={ baseStyles.footer }>
            { this.renderCommonButton() }
          </div>
        }
      </div>)
  }
}

export default (BaseModul);
