/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import ReactDOM from 'react-dom'
import { translate } from 'react-i18next';

import FlatButton from '../../../components/buttons/FlatButton';

import { PageHeader } from '../../../components/pageheader'
import { BackSubPageHeader, ChipBackSubPageHeader } from '../../../components/pageheader';
import GroupList from '../groups/GroupList';
import GroupDetail from '../groups/GroupDetail';

import { connect } from 'react-redux';
import { Tabs, Tab } from 'material-ui/Tabs';
import TextField from '../../../components/textfields/TextField';
import IconSearch from '../../../ressources/icon_search';
import { AbstractGroups } from '../AbstractGroup';
import MessageEditor from './MessageEditor';

let styles = {
  default_tab: {
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: "bold",
    fontSize: "16px",
    color: "#7B98AB",
    textTransform: 'none',
  },
  active_tab: {
    background: '#00375D',
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: "bold",
    fontSize: "16px",
    color: "#EEC643",
    textTransform: 'none',
  },
  inkBarStyle: {
    height: '0px',
  },
  tab: [],
  searchText: {
    marginLeft: '25px',
    color: '#7B98AB',
    float: 'left',
    height: '60px',
    display: 'inline-flex',
    maxWidth: '260px',
  }
}

function mapStateToProps(state) {

  const { userList } = state;

  return {
    userList: userList
  }
}


export class ChannelManager extends AbstractGroups {
  constructor(props) {
    super(props);

    this.handleBtnUpClick = this.handleBtnUpClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.initTabs();
    styles.tab[0] = styles.active_tab;
  }

  initTabs() {
    styles.tab[0] = styles.default_tab;
    styles.tab[1] = styles.default_tab;
  }

  updateTabs(active) {
    this.initTabs();
    styles.tab[active] = styles.active_tab;
  }

  getRoomObjectFrom(json) {
    return json.RestrictedRoom;
  }

  handleTabChange(value) {
    this.setState({
      slideIndex: value
    });
    this.updateTabs(value);
  }

  renderDeleteGroupConfirmMessageText() {
    const {t} = this.props;
    return t("modules-channels-Channel-confirmDeleteChannel");
  }

  renderListGroupsHeader() {
    const {t} = this.props;
    return <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
             <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
               { t("modules-channels-Channel-channel", { v1: this.dataprovider.getDataCount() }) }
             </PageHeader>
             <div style={ styles.searchText }>
              <TextField
                style={ { margin: 'auto 0px' } }
                hintText={ t('modules-user-User-search') }
                onChange={ (event) => this.filter(event) }
                value={ this.state.channelFilterText }
              />
              <IconSearch style={ { margin: 'auto 0px', height: '40px', width: '40px' } } />
            </div>
          </div>;
  }

  filter(event) {
    this.setState({
      channelFilterText: event.target.value
    });
  }

  renderEditGroupHeader() {
    const {t} = this.props;
    return <div >
             <div style={ this.getBaseStyle().headerPageLabelDiv }>
               <ChipBackSubPageHeader Group={ this.state.editGroup } onBackClicked={ this.handleCancelNewGroupClicked } GroupChipColor={ this.props.GroupChipColor } />
               <Tabs onChange={ this.handleTabChange } value={ this.state.slideIndex } inkBarStyle={ styles.inkBarStyle }>
                 <Tab style={ styles.tab[0] } label={ t('modules-channels-channel-tab-editChannel') } value={ 0 } />
                 <Tab style={ styles.tab[1] } label={ t('modules-channels-channel-tab-messages') } value={ 1 } />
               </Tabs>
             </div>
             <div style={ this.getBaseStyle().right }>
               <FlatButton label={ t('modules-channels-channel-btnDeleteChannel') } secondary={ true } onClick={ this.beginDeleteGroupClicked } />
             </div>
           </div>;
  }

  renderNewGroupHeader() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv }>
               <BackSubPageHeader label={ t('modules-channels-NewChannel') } onBackClicked={ this.handleCancelNewGroupClicked } />
             </div>
           </div>;
  }

  renderListGroupsMainView() {
    const {t} = this.props;
    return <div>
            <GroupList
              ref="grouplist" dataprovider={ this.dataprovider }
              onRowSelection={ this.onRowSelection }
              onDeleteGroup={ this.beginDeleteGroupClicked }
              customGroupNameColumnText={ t("modules-channels-ChannelList-table-column1") }
              GroupChipColor={ this.props.GroupChipColor }
              filterText={ this.state.channelFilterText }
            />
          </div>;
  }

  renderNewGroupMainView() {
    const {t, dispatch} = this.props;

    return <div>
             <GroupDetail ref="newgroup" dispatch={ dispatch } viewMode={ this.state.currentViewMode } roomType={ this.props.RoomType } onCreateGroup={ this.createGroupHandler } listEditModeListener={ this.listEditModeListener }
               loadGroupImage={ this.loadGroupImage } customNoMemberText={ t("modules-channels-NewChannel-noMember") } onPendingChanges={ this.handleOnPendingChanges } />
           </div>;
  }

  renderEditGroupMainView(group) {
    const {t, dispatch} = this.props;
    console.log("state: ", this.state.slideIndex);
    if (!this.state.slideIndex || this.state.slideIndex === 0) {
      return (
        <div>
          <GroupDetail ref="updategroup" dispatch={ dispatch } viewMode={ this.state.currentViewMode } onUpdateGroup={ this.updateGroupHandler } listEditModeListener={ this.listEditModeListener } group={ this.state.editGroup }
            customNoMemberText={ t("modules-channels-NewChannel-noMember") } loadGroupImage={ this.loadGroupImage } onPendingChanges={ this.handleOnPendingChanges } />
        </div>);
    } else {
      return (<MessageEditor Channel={group} ref="messageEditor"/>);
    }
  }

  /* isCustomEditGroupCommonButtons() {
    return (this.state.slideIndex === 1);
  } */

  handleBtnUpClick() {
    let messageEditor = ReactDOM.findDOMNode(this.refs.messageEditor);
    messageEditor.scrollIntoView({
      behavior: "smooth"
    });
  }

  renderCustomEditGroupCommonButtons() {
    const {t} = this.props;
    return (
      <div style={ this.getBaseStyle().fabRight }>
        <FlatButton label={ t('modules-channel-commonbutton-btnUp') } style={ this.getBaseStyle().cancelButton } onClick={ this.handleBtnUpClick } />
      </div>
      );
  }

  renderCreateGroupText() {
    const {t} = this.props;
    return t("modules-channels-NewChannel");
  }
}

const ChannelTranslated = translate(['common'])(ChannelManager);

export default connect(mapStateToProps)(ChannelTranslated);
