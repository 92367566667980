import React, { Component } from 'react';
import RaisedButtonUi from 'material-ui/RaisedButton';
import { getAdminMuiTheme } from '../styles';

class RaisedButton extends Component {

  render() {
    const {labelStyle, buttonStyle, ...other} = this.props;

    const mergedLabelStyle = Object.assign({}, {
      letterSpacing: '0.45px',
    }, labelStyle);
    const mergedButtonStyle = Object.assign({}, {
      height: "40px",
    }, buttonStyle);

    //on disabled state no special hover color tweaks needed
    if(this.props.disabled) {
      return (
          <div style={{ display: 'inline-block' }}>
            <div>
              <RaisedButtonUi labelStyle={ mergedLabelStyle } buttonStyle={mergedButtonStyle} {...other}/>
            </div>
          </div>
      )
    }

    const id = "ID_" + Date.now() + Math.round(Math.random()*100);
    //it is a hacky way to get custom colors work for button hover state
    //because material-ui with lower versions does not support any customization on this
    //so we just create a custom style for this with "important" parameter to overwrite any original behavior
    return (
        <div style={{ display: 'inline-block' }}>
          <style>{this.getInlineStyle(id)}</style>
          <div>
            <RaisedButtonUi id={id} labelStyle={ mergedLabelStyle } buttonStyle={mergedButtonStyle} {...other}/>
          </div>
        </div>
      );
  }

  getInlineStyle(id) {
    const theme = getAdminMuiTheme().raisedButton;

    //determine needed hover color for different types of buttons
    let hoverBGColor = theme.defaultHoverColor;
    if(this.props.danger) {
      hoverBGColor = theme.dangerHoverColor;
    } else if (this.props.primary) {
      hoverBGColor = theme.primaryHoverColor;
    } else if (this.props.secondary) {
      hoverBGColor = theme.secondaryHoverColor;
    }

    //we're unable to customize hover background in underlying div,
    //in this part it's just being overwritten hardly with "important" parameter set
    let btnStyle = `
      #${id}:hover {
        background-color: ${hoverBGColor} !important;
      }
      #${id} > div > div:hover {
        background: none !important;
      }
      `;

    //danger is our own button type, so we have to set background color explicitly
    if(this.props.danger) {
      btnStyle+=`
        #${id} {
          background-color: ${theme.dangerColor} !important;
        }
      `;
    }

    return btnStyle;
  }
}

export default RaisedButton;
