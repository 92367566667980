export const FULL_SIDE_WIDTH = '1450px';
export const SIDEBAR_WIDTH = '160px';
export const CONTENT_WIDTH = '1290px'; //FULL_SIDE_WIDTH - SIDEBAR_WIDTH

//Colors

export const COLOR_PRIMARY_1 = '#00C1A7'
export const COLOR_PRIMARY_2 = '#7B98AB'

//MaxFile size (Channel-Files)
export const MAXFILE_SIZE = 20971520;

export const OPT_IN_STATES = {
  ACCEPTED: 'accepted',
  AUTO: 'auto',
  NOT_SET: 'notSet'
};
