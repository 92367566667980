import React, { Component, PropTypes } from 'react';
import { translate } from 'react-i18next';

import FlatButton from './components/buttons/FlatButton';

import Logo from './ressources/Login_Logo.svg';

// import IconSearch from './ressources/icon_search'

import { Link } from 'react-router';

import Cookie from 'js-cookie';


const styles = {
  root: {
    backgroundColor: '#002C4A',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  content: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '70px'
  },
  left: {
    flex: '0 1 auto',
    maxWidth: '650px'
  },
  right: {
    flex: '0 1 auto',
    textAlign: 'right',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  rightButtons: {
    textAlign: 'right',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '20px'
  },
  centerPlaceholder: {
    flex: '1 1 auto',
    padding: '10px',
    textAlign: 'center',
  },
  image: {
    display: 'block',
    padding: '10px',
    textAlign: 'center',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '0px',
    left: '50%',
    transform: 'translate(-50%,0)',
  },
  logo: {
    height: '50px'
  },
  yellow: {
    color: '#EEC643',
    fontSize: '14px',
  },
  gray: {
    color: '#7B98AB',
    paddingLeft: '5px',
    fontSize: '12px'
  },
  nameStyle: {
    color: '#7B98AB',
    paddingLeft: '5px',
    textAlign: 'right',
    fontSize: '12px'
  },
  pipe: {
    color: '#7B98AB',
  },
  bigSearch: {
    height: '40px',
    width: '40px'
  },
  logoutContent: {
    display: 'flex',
    flexFlow: 'row',
    height: '70px',
    alignItems: 'center',
  },
  logoutLeft: {
    flex: '1 1',
  },
  logoutRight: {
    flex: '1 1',
    textAlign: 'right',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  placeholder: {
    minWidth: '40px'
  },
  buttonRight: {
    minWidth: '0px',
    marginLeft: '5px',
  },
  buttonCenter: {
    minWidth: '0px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  buttonLeft: {
    minWidth: '0px',
    marginRight: '5px',
  },
  buttonLabel: {
    color: '#FFFFFF',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  activeButtonLabel: {
    color: '#EEC643',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  buttonHeight: {
    height: '20px',
    lineHeight: '20px',
  }

};

class Header extends Component {

  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);

    var language = (Cookie.get('mc-language'));

    this.state = { }

    this.changeLanguage(language, true)
  }

  changeLanguage(language, internalSet) {
    
    /*
    if (language !== "de" && language !== "en")
    {
      language = "de";
    }
    */
    this.props.i18n.changeLanguage(language, (err, t) => {
      if (!err)
      {
        // eslint-disable-next-line
        this.state.language = language;
        if (!internalSet)
        {
          Cookie.set('mc-language', language, {
            expires: 3650
          });
        }
      }
    });
  }



  getLanguageButtons() {
    const {t} = this.props;
    return (
      <div>
        <FlatButton style={ styles.buttonLeft }  label={ t('header-btn-de') } onClick={ () => this.changeLanguage('de', false) } labelStyle={ this.state.language === 'de' ? styles.activeButtonLabel : styles.buttonLabel } hoverColor="rgba(0,0,0,0)" />
        <span style={ styles.pipe }>|</span>
        <FlatButton style={ styles.buttonCenter } label={ t('header-btn-en') } onClick={ () => this.changeLanguage('en', false) } labelStyle={ this.state.language === 'en' ? styles.activeButtonLabel : styles.buttonLabel } hoverColor="rgba(0,0,0,0)" />
        <span style={ styles.pipe }>|</span>
        <FlatButton style={ styles.buttonCenter } label={ t('header-btn-es') } onClick={ () => this.changeLanguage('es', false) } labelStyle={ this.state.language === 'es' ? styles.activeButtonLabel : styles.buttonLabel } hoverColor="rgba(0,0,0,0)" />
        <span style={ styles.pipe }>|</span>
        <FlatButton style={ styles.buttonCenter } label={ t('header-btn-el') } onClick={ () => this.changeLanguage('el', false) } labelStyle={ this.state.language === 'el' ? styles.activeButtonLabel : styles.buttonLabel } hoverColor="rgba(0,0,0,0)" />
        <span style={ styles.pipe }>|</span>
        <FlatButton style={ styles.buttonCenter } label={ t('header-btn-fr') } onClick={ () => this.changeLanguage('fr', false) } labelStyle={ this.state.language === 'fr' ? styles.activeButtonLabel : styles.buttonLabel } hoverColor="rgba(0,0,0,0)" />
      </div>

      );
  }

  getCompanyName() {
    const {adminInfo} = this.props;

    if (adminInfo && adminInfo.Company)
    {
      return adminInfo.Company.name;
    }
    else
    {
      return "";
    }
  }

  getAdminName() {
    const {adminInfo} = this.props;

    if (adminInfo && adminInfo.Admin)
    {
      return adminInfo.Admin.firstName + " " + adminInfo.Admin.name
    }
    else
    {
      return "";
    }
  }

  render() {
    const { t, onLogoutClick, isLoggedIn } = this.props;
    return (
      <div style={ styles.root }>
        { isLoggedIn &&
          <div style={ styles.content }>
            <div style={ styles.image }>
              <Link to={ '/' }><img alt="" src={ Logo } style={ styles.logo } /></Link>
            </div>
            <div style={ styles.left }>
              <Link to={ '/' }><span className='medium' style={ styles.yellow }>{ t('header-title') }</span></Link>
              <span className='medium' style={ styles.gray }>{ this.getCompanyName() }</span>
            </div>
            <div style={ styles.centerPlaceholder }>
            </div>
            <div style={ styles.right }>
              <span className='medium' style={ styles.nameStyle }>{ this.getAdminName() }</span>
              <div style={ styles.rightButtons }>
                { this.getLanguageButtons() }
                <div style={ styles.placeholder }></div>
                <span style={ styles.pipe }>|</span>
                <FlatButton style={ styles.buttonRight } label={ t('header-btn-logout') } labelStyle={ styles.buttonLabel } onClick={ () => onLogoutClick() } />
              </div>
            </div>
          </div> }
        { !isLoggedIn &&
          <div style={ styles.logoutContent }>
            <div style={ styles.logoutLeft }>
              <span className='medium' style={ styles.yellow }>{ t('header-title') }</span>
            </div>
            <div style={ styles.logoutRight }>
              { this.getLanguageButtons() }
            </div>
          </div> }
      </div>
      );
  }
}

Header.propTypes = {
  onLogoutClick: PropTypes.func,
  onForgotPwdClick: PropTypes.func,
  isLoggedIn: PropTypes.bool.isRequired,
  adminInfo: PropTypes.object,
}

export default translate(['common'])(Header);
