import React from 'react';
import { translate } from 'react-i18next';
import FlatButton from '../../../components/buttons/FlatButton';
import { PageHeader } from '../../../components/pageheader'
import { BackSubPageHeader } from '../../../components/pageheader';
import GroupList from './GroupList';
import GroupDetail from './GroupDetail';
import { connect } from 'react-redux';
import { AbstractGroups } from '../AbstractGroup';
import TextField from '../../../components/textfields/TextField';
import IconSearch from '../../../ressources/icon_search';

const styles = {
  download: {
    display: "inline-flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  iconDownload: {
    width: "32px",
    height: "32px",
    paddingRight: "10px",
    float: 'left',
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  searchText: {
    marginLeft: '25px',
    color: '#7B98AB',
    float: 'left',
    height: '60px',
    display: 'inline-flex',
    maxWidth: '260px',
  }
};

function mapStateToProps(state) {
  const {userList} = state;

  return {
    userList: userList
  }
}


export class GroupsManager extends AbstractGroups {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  getRoomObjectFrom(json) {
    return json.ManagedRoom;
  }

  renderListGroupsHeader() {
    const {t} = this.props;
    return <div>
            <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
             <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
               { t("modules-groups-Groups-groups", {
                   Groups: this.props.GroupsIdentifier,
                   v1: this.dataprovider.getDataCount()
                 }) }
              </PageHeader>
            </div>
            <div style={ styles.searchText }>
              <TextField
                style={ { margin: 'auto 0px' } }
                hintText={ t('modules-user-User-search') }
                onChange={ (event) => this.filter(event) }
                value={ this.state.groupsFilterText }
              />
              <IconSearch style={ { margin: 'auto 0px', height: '40px', width: '40px' } } />
            </div>
          </div>
  }

  filter(event) {
    this.setState({
      groupsFilterText: event.target.value
    });
  }

  renderEditGroupHeader() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv }>
               <BackSubPageHeader label={ this.state.editGroup.name } onBackClicked={ this.handleCancelNewGroupClicked } />
             </div>
             <div style={ this.getBaseStyle().right }>
               <FlatButton label={ t('modules-groups-Groups-btnDeleteGroup') } secondary={ true } onClick={ this.beginDeleteGroupClicked } />
             </div>
           </div>;
  }

  renderNewGroupHeader() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv }>
               <BackSubPageHeader label={ t('modules-groups-NewGroup-newgroup') } onBackClicked={ this.handleCancelNewGroupClicked } />
             </div>
           </div>;
  }

  renderListGroupsMainView() {
    return <div>
            <GroupList
              ref="grouplist"
              dataprovider={ this.dataprovider }
              onRowSelection={ this.onRowSelection }
              onDeleteGroup={ this.beginDeleteGroupClicked }
              GroupChipColor={ this.props.GroupChipColor }
              filterText={ this.state.groupsFilterText }
            />
           </div>;
  }

  renderNewGroupMainView() {
    const {dispatch} = this.props;
    return <div>
            <GroupDetail
              ref="newgroup"
              dispatch={ dispatch }
              viewMode={ this.state.currentViewMode }
              onCreateGroup={ this.createGroupHandler }
              listEditModeListener={ this.listEditModeListener }
              loadGroupImage={ this.loadGroupImage }
              onPendingChanges={ this.handleOnPendingChanges }
            />
           </div>;
  }

  renderEditGroupMainView(group) {
    const {dispatch} = this.props;
    return <div>
            <GroupDetail
              ref="updategroup"
              dispatch={ dispatch }
              viewMode={ this.state.currentViewMode }
              onUpdateGroup={ this.updateGroupHandler }
              listEditModeListener={ this.listEditModeListener }
              group={ group }
              onPendingChanges={ this.handleOnPendingChanges }
              loadGroupImage={ this.loadGroupImage }
            />
           </div>;
  }

}

const GroupsTranslated = translate(['common'])(GroupsManager);


export default connect(mapStateToProps)(GroupsTranslated);
