/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React from 'react';

import Subheader from 'material-ui/Subheader';
import IconBack from '../ressources/icon_back';
import FlatButton from 'material-ui/FlatButton';

import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';

import {detectIE} from '../util/Browser';

const styles = {
  subpageHeader: {
    fontSize: '20px',
    color: '#FFFFFF',
    paddingLeft: '25px',
    height: '48px',
    lineHeight: '24px',
    position: 'relative',
  //paddingLeft: '0px'
  },
  backSubpageHeader: {
    fontSize: '16pt',
    color: '#FFFFFF',
    padding: '0px',
    marginLeft: '0px',
    marginRight: '10px',
    height: '48px',
    lineHeight: '24px',
    position: 'relative',
  //paddingLeft: '0px'
  },
  subpageHeaderSpan: {
    bottom: '0px',
    position: 'absolute'
  },
  button: {
    fontHeight: '18pt',
    textColor: '#FFFFFF',
    color: '#FFFFFF',
    height: '52px',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '25px',
    margin: 'auto 0px',
  },
  backButtonChip: {
    fontHeight: '18pt',
    textColor: '#FFFFFF',
    color: '#FFFFFF',
    height: '52px',
    width: '20px',
    textAlign: 'left',
    paddingLeft: '25px',
    margin: 'auto 2px',
    float: 'left',
  },
  largeIcon: {
    width: 40,
    height: 40,
    marginLeft: '0px'
  },
  chip: {
    height: 32,
    marginTop: 8,
  //  display:'-ms-inline-grid'
  },
  chipLabelStyle: {
    fontSize: '14px',
    marginLeft: '14px',
    marginRight: '14px',
    width:'100%',
    maxWidth:"400px",
    minWidth:"20px",
    overflow:"hidden",
  },
};

export function PageHeader(props) {
  const {style} = props;

  const mergedStyles = Object.assign({}, {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    padding: '0px'
  }, style);

  return (<Subheader style={ mergedStyles }>
            { props.children }
          </Subheader>);
}


export function SubPageHeader(props) {
  const {style} = props;

  const mergedStyles = Object.assign({}, styles.subpageHeader, style);

  return (<Subheader style={ mergedStyles }>
            <span style={ styles.subpageHeaderSpan }>{ props.children }</span>
          </Subheader>);
}

export class BackSubPageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.isIE = detectIE();
    this.onClick = this.onClick.bind(this);

  }

  onClick() {
    this.props.onBackClicked();
  }
  render() {
    return (<FlatButton label={ this.props.label } labelPosition="after" style={ styles.button } labelStyle={ styles.backSubpageHeader } onClick={ this.onClick } icon={ < IconBack style={ styles.largeIcon } /> }
            />)
  }
}

export class ChipBackSubPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

  }

  onClick() {
    this.props.onBackClicked();
  }

  getGroupAvatar(group) {
    if (group.smallImage != null && typeof group.smallImage !== "undefined")
    {
      return <Avatar src={ "data:image/jpeg;base64," + group.smallImage } />;
    }
    return <div></div>;
  }

  render() {

    return (
      <div style={ { marginTop: '5px', display:"table",height:'40px' } }>
        <div style={{ display:"table-cell"}}>
          <FlatButton
          label=''
          labelPosition="after"
          style={ styles.backButtonChip }
          labelStyle={ { margin: 0, width: 0 } }
          onClick={ this.onClick } icon={ < IconBack style={ styles.largeIcon } /> }  />
        </div>
        <div style={{ display:"table-cell",margin:0, padding:0, verticalAlign:"top"}}>
          <Chip style={this.isIE ? Object.assign({},styles.chip,{display:'-ms-inline-grid'}) : styles.chip } labelStyle={ this.isIE ? Object.assign({},styles.chipLabelStyle , {marginLeft:'24px'}) : styles.chipLabelStyle } onClick={ this.onClick } backgroundColor={ this.props.GroupChipColor != null ? this.props.GroupChipColor : '#737373' }>
            { this.getGroupAvatar(this.props.Group) }
            { this.props.Group.name }
            </Chip>
          </div>
      </div>)
  //return <Chip label={ this.props.label } labelPosition="after" style={ styles.button } labelStyle={ styles.backSubpageHeader } onClick={ this.onClick } icon={ < IconBack style={ styles.largeIcon } /> }
  //       />
  }
}

export default PageHeader;
