import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import Root from './Root';
import { I18nextProvider } from 'react-i18next';

// import Dashboard from './modules/dashboard/Dashboard';
// import License from './modules/license/License';
// import User from './modules/user/User';
// import Groups from './modules/groups/Groups';
// import Channel from './modules/channel/Channel';
// import AppSettings from './modules/appsettings/AppSettings';
// import AppDesign from './modules/appdesign/AppDesign';

import './index.css';
// import { Router, Route, hashHistory } from 'react-router'

import i18n from './i18n'; // initialized i18next instance

ReactDOM.render(
  <I18nextProvider i18n={ i18n }>
    <Root />
  </I18nextProvider>,
  document.getElementById('root')
);
