import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconXlsCopy(props) {
  return <SvgIcon {...props}>
           <svg viewBox="-10 -10 100 100">
             <g fill="none" fillRule="evenodd">
               <rect width="100" height="100" />
               <path fill="#FFF" fillRule="nonzero" d="M49.7499338,50.4999177 L49.7499338,54.999907 L45.2499446,54.999907 L45.2499446,50.4999177 L49.7499338,50.4999177 Z M42.2499517,42.9999356 L42.2499517,47.4999249 L37.7499624,47.4999249 L37.7499624,42.9999356 L42.2499517,42.9999356 Z M49.7499338,42.9999356 L49.7499338,47.4999249 L45.2499446,47.4999249 L45.2499446,42.9999356 L49.7499338,42.9999356 Z M42.2499517,50.4999177 L42.2499517,54.999907 L37.7499624,54.999907 L37.7499624,50.4999177 L42.2499517,50.4999177 Z M42.9999499,28.7499696 C42.9999499,29.987485 44.0124292,30.9999642 45.2499446,30.9999642 L57.9999142,30.9999642 L57.9999142,61.7498909 C57.9999142,62.9967446 56.9967733,63.9998856 55.7499195,63.9998856 L24.2499946,63.9998856 C23.0031409,63.9998856 22,62.9967446 22,61.7498909 L22,18.2499946 C22,17.0031409 23.0031409,16 24.2499946,16 L42.9999499,16 L42.9999499,28.7499696 Z M52.7499267,38.4999499 C52.7499267,37.671213 52.0786636,36.9999499 51.2499303,36.9999499 L28.7499875,36.9999499 C27.9212505,36.9999499 27.2499875,37.671213 27.2499875,38.4999499 L27.2499875,56.4999034 C27.2499875,57.3286368 27.9212505,57.9998999 28.7499875,57.9998999 L51.2499303,57.9998999 C52.0786636,57.9998999 52.7499267,57.3286368 52.7499267,56.4999034 L52.7499267,38.4999499 Z M57.3436657,25.8437265 C57.7655397,26.2656005 57.9999142,26.8374375 57.9999142,27.4281344 L57.9999142,27.9999714 L45.9999428,27.9999714 L45.9999428,16 L46.5717798,16 C47.171815,16 47.743652,16.2343744 48.165526,16.6562484 L57.3436657,25.8437265 Z M34.7499696,42.9999356 L34.7499696,47.4999249 L30.2499803,47.4999249 L30.2499803,42.9999356 L34.7499696,42.9999356 Z M34.7499696,50.4999177 L34.7499696,54.999907 L30.2499803,54.999907 L30.2499803,50.4999177 L34.7499696,50.4999177 Z"
               />
             </g>
           </svg>
         </SvgIcon>;
}
;

export default IconXlsCopy;
