/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconDownload(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 30 30">
              <path fill="#7B98AB" d="M21.125,23 L8.875,23 C8.39011129,23 8,22.6178502 8,22.1428571 L8,21.2857143 C8,20.8107213 8.39011129,20.4285714 8.875,20.4285714 L21.125,20.4285714 C21.6098887,20.4285714 22,20.8107213 22,21.2857143 L22,22.1428571 C22,22.6178502 21.6098887,23 21.125,23 Z M18.7041524,12.9821429 C19.0432434,12.6464146 19.5973816,12.6464146 19.9364726,12.9821429 L20.5562642,13.5892857 C20.8989512,13.9214216 20.8989512,14.4642857 20.5562642,14.800014 L15.6161601,19.6392997 C15.2770691,19.974993 14.7229309,19.974993 14.3802083,19.6392997 L9.44010417,14.800014 C9.09738159,14.4678432 9.09738159,13.925014 9.44010417,13.5892857 L10.0598958,12.9821429 C10.3989512,12.6464146 10.953125,12.6464146 11.2958476,12.9821429 L13.6875,15.324986 L13.6875,7.85714286 C13.6875,7.38214983 14.0776113,7 14.5625,7 L15.4375,7 C15.9223887,7 16.3125,7.38214983 16.3125,7.85714286 L16.3125,15.324986 L18.7041524,12.9821429 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default IconDownload;
