import { OPT_IN_STATES } from '../util/Constants.js';

export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_DATA'
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT'
export const REQUEST_IS_RECOVERY_PASSWORD_VALID = 'REQUEST_IS_RECOVERY_PASSWORD_VALID'
export const RECEIVE_IS_RECOVERY_PASSWORD_VALID = 'RECEIVE_RIS_RECOVERY_PASSWORD_VALID'
export const REQUEST_RESET_PWD = 'REQUEST_RESET_PWD'
export const RECEIVE_RESET_PWD = 'RECEIVE_RESET_PWD'
export const REQUEST_CHANGE_PWD = 'REQUEST_CHANGE_PWD'
export const RECEIVE_CHANGE_PWD = 'RECEIVE_CHANGE_PWD'

export const REQUEST_ADMIN_INFO = 'REQUEST_ADMIN_INFO'
export const RECEIVE_ADMIN_INFO = 'RECEIVE_ADMIN_INFO'

export const REQUEST_DELETE_COMPANY = 'REQUEST_DELETE_COMPANY'
export const RECEIVE_DELETE_COMPANY = 'RECEIVE_DELETE_COMPANY'

export const REQUEST_USER_LIST = 'REQUEST_USER_LIST'
export const RECEIVE_USER_LIST = 'RECEIVE_USER_LIST'

export const REQUEST_KEYWORD_LIST = 'REQUEST_KEYWORD_LIST'
export const RECEIVE_KEYWORD_LIST = 'RECEIVE_KEYWORD_LIST'

export const REQUEST_DELETE_KEYWORD = 'REQUEST_DELETE_KEYWORD'
export const RECEIVE_DELETE_KEYWORD = 'RECEIVE_DELETE_KEYWORD'


export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER'
export const RECEIVE_CREATE_USER = 'RECEIVE_CREATE_USER'

export const REQUEST_SET_COMPANY_ADRESS = 'REQUEST_SET_COMPANY_ADRESS'
export const RECEIVE_SET_COMPANY_ADRESS = 'RECEIVE_SET_COMPANY_ADRESS'

export const REQUEST_IMPORT_USERS = 'REQUEST_IMPORT_USERS'
export const RECEIVE_IMPORT_USERS = 'RECEIVE_IMPORT_USERS'

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER'
export const RECEIVE_UPDATE_USER = 'RECEIVE_UPDATE_USER'

export const REQUEST_GET_STATISTIC = 'REQUEST_GET_STATISTIC'
export const RECEIVE_GET_STATISTIC = 'RECEIVE_GET_STATISTIC'

export const REQUEST_GET_GROUP_STATISTIC = 'REQUEST_GET_GROUP_STATISTIC'
export const RECEIVE_GET_GROUP_STATISTIC = 'RECEIVE_GET_GROUP_STATISTIC'

export const REQUEST_GET_GROUPCHANNEL_STATISTIC = 'REQUEST_GET_GROUPCHANNEL_STATISTIC'
export const RECEIVE_GET_GROUPCHANNEL_STATISTIC = 'RECEIVE_GET_GROUPCHANNEL_STATISTIC'

export const REQUEST_GET_GROUP_STATISTIC_DETAILS = 'REQUEST_GET_GROUP_STATISTIC_DETAILS'
export const RECEIVE_GET_GROUP_STATISTIC_DETAILS = 'RECEIVE_GET_GROUP_STATISTIC_DETAILS'

export const REQUEST_GET_GROUPCHANNEL_STATISTIC_DETAILS = 'REQUEST_GET_GROUPCHANNEL_STATISTIC_DETAILS'
export const RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS = 'RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS'

export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER'
export const RECEIVE_DELETE_USER = 'RECEIVE_DELETE_USER'

export const REQUEST_CREATE_ROOM = 'REQUEST_CREATE_ROOM'
export const RECEIVE_CREATE_ROOM = 'RECEIVE_CREATE_ROOM'

export const REQUEST_UPDATE_ROOM = 'REQUEST_UPDATE_ROOM'
export const RECEIVE_UPDATE_ROOM = 'RECEIVE_UPDATE_ROOM'

export const REQUEST_DELETE_ROOM = 'REQUEST_DELETE_ROOM'
export const RECEIVE_DELETE_ROOM = 'RECEIVE_DELETE_ROOM'

export const REQUEST_GET_ROOMIMAGE = 'REQUEST_GET_ROOMIMAGE'
export const RECEIVE_GET_ROOMIMAGE = 'RECEIVE_GET_ROOMIMAGE'


export const REQUEST_ROOM_LIST = 'REQUEST_ROOM_LIST'
export const RECEIVE_ROOM_LIST = 'RECEIVE_ROOM_LIST'

export const REQUEST_SET_APPCONFIG = 'REQUEST_SET_APP_CONFIG'
export const RECEIVE_SET_APPCONFIG = 'RECEIVE_SET_APP_CONFIG'

export const REQUEST_RESET_APPCONFIG = 'REQUEST_RESET_APP_CONFIG'
export const RECEIVE_RESET_APPCONFIG = 'RECEIVE_RESET_APP_CONFIG'

export const REQUEST_APPCONFIG = 'REQUEST_APP_CONFIG'
export const RECEIVE_APPCONFIG = 'RECEIVE_APP_CONFIG'

export const REQUEST_RESULT_STATUS_SUCCESS = "success";
export const REQUEST_RESULT_STATUS_ERROR = "error";

export const REQUEST_LIST_USED_VOUCHER = 'REQUEST_LIST_USED_VOUCHER';
export const RECEIVE_LIST_USED_VOUCHER = 'RECEIVE_LIST_USED_VOUCHER';

export const REQUEST_LIST_FREE_VOUCHER = 'REQUEST_LIST_FREE_VOUCHER';
export const RECEIVE_LIST_FREE_VOUCHER = 'RECEIVE_LIST_FREE_VOUCHER';

export const REQUEST_ASSIGN_FREE_VOUCHER = 'REQUEST_ASSIGN_FREE_VOUCHER';
export const RECEIVE_ASSIGN_FREE_VOUCHER = 'RECEIVE_ASSIGN_FREE_VOUCHER';

export const REQUEST_REVOKE_VOUCHER = 'REQUEST_REVOKE_VOUCHER';
export const RECEIVE_REVOKE_VOUCHER = 'RECEIVE_REVOKE_VOUCHER';

export const REQUEST_COMPANYLAYOUT = 'REQUEST_COMPANYLAYOUT';
export const RECEIVE_COMPANYLAYOUT = 'RECEIVE_COMPANYLAYOUT';

export const REQUEST_COMPANYLOGO = 'REQUEST_COMPANYLOGO';
export const RECEIVE_COMPANYLOGO = 'RECEIVE_COMPANYLOGO';

export const REQUEST_SET_COMPANYLAYOUT = 'REQUEST_SET_COMPANYLAYOUT';
export const RECEIVE_SET_COMPANYLAYOUT = 'RECEIVE_SET_COMPANYLAYOUT';

export const REQUEST_RESET_COMPANYLAYOUT = 'REQUEST_RESET_COMPANYLAYOUT';
export const RECEIVE_RESET_COMPANYLAYOUT = 'RECEIVE_RESET_COMPANYLAYOUT';

export const REQUEST_SET_COMPANYLOGO = 'REQUEST_SET_COMPANYLOGO';
export const RECEIVE_SET_COMPANYLOGO = 'RECEIVE_SET_COMPANYLOGO';

export const REQUEST_SET_COMPANY_PROFIL_IMAGE = 'REQUEST_SET_COMPANY_PROFIL_IMAGE';
export const RECEIVE_SET_COMPANY_PROFIL_IMAGE = 'RECEIVE_SET_COMPANY_PROFIL_IMAGE';

export const REQUEST_SET_COMPANYLANGUAGE = 'REQUEST_SET_COMPANYLANGUAGE';
export const RECEIVE_SET_COMPANYLANGUAGE = 'RECEIVE_SET_COMPANYLANGUAGE';


export const REQUEST_SEND_TEXTMESSAGE = 'REQUEST_SEND_TEXTMESSAGE';
export const RECEIVE_SEND_TEXTMESSAGE = 'RECEIVE_SEND_TEXTMESSAGE';

export const REQUEST_SEND_IMAGEMESSAGE = 'REQUEST_SEND_IMAGEMESSAGE';
export const RECEIVE_SEND_IMAGEMESSAGE = 'RECEIVE_SEND_IMAGEMESSAGE';

export const REQUEST_SEND_FILEMESSAGE = 'REQUEST_SEND_FILEMESSAGE';
export const RECEIVE_SEND_FILEMESSAGE = 'RECEIVE_SEND_FILEMESSAGE';

export const REQUEST_GET_ROOMMESSAGEGUIDS = 'REQUEST_GET_ROOMMESSAGEGUIDS';
export const RECEIVE_GET_ROOMMESSAGEGUIDS = 'RECEIVE_GET_ROOMMESSAGEGUIDS';

export const REQUEST_GET_ROOMTIMEDMESSAGEGUIDS = 'REQUEST_GET_ROOMTIMEDMESSAGEGUIDS';
export const RECEIVE_GET_ROOMTIMEDMESSAGEGUIDS = 'RECEIVE_GET_ROOMTIMEDMESSAGEGUIDS';

export const REQUEST_GET_ROOMMESSAGEBATCH = 'REQUEST_GET_ROOMMESSAGEBATCH';
export const RECEIVE_GET_ROOMMESSAGEBATCH = 'RECEIVE_GET_ROOMMESSAGEBATCH';

export const REQUEST_REMOVE_ROOMMESSAGES = 'REQUEST_REMOVE_ROOMMESSAGES';
export const RECEIVE_REMOVE_ROOMMESSAGES = 'RECEIVE_REMOVE_ROOMMESSAGES';

export const REQUEST_REMOVE_TIMEDROOMMESSAGE = 'REQUEST_REMOVE_TIMEDROOMMESSAGE';
export const RECEIVE_REMOVE_TIMEDROOMMESSAGE = 'RECEIVE_REMOVE_TIMEDROOMMESSAGE';

export const REQUEST_SHOW_RECOVERY_KEY = 'REQUEST_SHOW_RECOVERY_KEY';
export const RECEIVE_SHOW_RECOVERY_KEY = 'RECEIVE_SHOW_RECOVERY_KEY';

export const REQUEST_SEND_RECOVERYPASSWORD_MAIL = 'REQUEST_SEND_RECOVERYPASSWORD_MAIL';
export const RECEIVE_SEND_RECOVERYPASSWORD_MAIL = 'RECEIVE_SEND_RECOVERYPASSWORD_MAIL';

export const REQUEST_SET_ADMIN_NAME = 'REQUEST_SET_ADMIN_NAME';
export const RECEIVE_SET_ADMIN_NAME = 'RECEIVE_SET_ADMIN_NAME';

export const REQUEST_PURCHASE_COMPANY_VOUCHER = 'REQUEST_PURCHASE_COMPANY_VOUCHER';
export const RECEIVE_PURCHASE_COMPANY_VOUCHER = 'RECEIVE_PURCHASE_COMPANY_VOUCHER';

export const REQUEST_RESET_COMPANYUSERSTATE = 'REQUEST_RESET_COMPANYUSERSTATE';
export const RECEIVE_RESET_COMPANYUSERSTATE = 'RECEIVE_RESET_COMPANYUSERSTATE';

export const REQUEST_ASSIGN_VOUCHERS_BY_IMPORT_ID = 'REQUEST_ASSIGN_VOUCHERS_BY_IMPORT_ID';
export const RECEIVE_ASSIGN_VOUCHERS_BY_IMPORT_ID = 'RECEIVE_ASSIGN_VOUCHERS_BY_IMPORT_ID';

export const REQUEST_ADMIN_LIST = 'REQUEST_ADMIN_LIST';
export const RECEIVE_ADMIN_LIST = 'RECEIVE_ADMIN_LIST';

export const REQUEST_CREATE_ADMIN = 'REQUEST_CREATE_ADMIN';
export const RECEIVE_CREATE_ADMIN = 'RECEIVE_CREATE_ADMIN';

export const REQUEST_SET_ADMIN_ENABLED = 'REQUEST_SET_ADMIN_ENABLED';
export const RECEIVE_SET_ADMIN_ENABLED = 'RECEIVE_SET_ADMIN_ENABLED';

export const REQUEST_DELETE_ADMIN = 'REQUEST_DELETE_ADMIN';
export const RECEIVE_DELETE_ADMIN = 'RECEIVE_DELETE_ADMIN';

export const REQUEST_START_IMPORT_LDAP = 'REQUEST_START_IMPORT_LDAP';
export const RECEIVE_START_IMPORT_LDAP = 'RECEIVE_START_IMPORT_LDAP';

export const REQUEST_START_APPLY_IMPORT = 'REQUEST_START_APPLY_IMPORT';
export const RECEIVE_START_APPLY_IMPORT = 'RECEIVE_START_APPLY_IMPORT';

export const REQUEST_DISCARD_IMPORT = 'REQUEST_DISCARD_IMPORT';
export const RECEIVE_DISCARD_IMPORT = 'RECEIVE_DISCARD_IMPORT';

export const REQUEST_DOWNLOAD_REPORT = 'REQUEST_DOWNLOAD_REPORT';
export const RECEIVE_DOWNLOAD_REPORT = 'RECEIVE_DOWNLOAD_REPORT';

export const REQUEST_GET_IMPORT_STATE = 'REQUEST_GET_IMPORT_STATE';
export const RECEIVE_GET_IMPORT_STATE = 'RECEIVE_GET_IMPORT_STATE';

export const REQUEST_CREATE_MAIL_TOKEN = 'REQUEST_CREATE_MAIL_TOKEN';
export const RECEIVE_CREATE_MAIL_TOKEN = 'RECEIVE_CREATE_MAIL_TOKEN';

export const REQUEST_DELETE_MAIL_TOKEN = 'REQUEST_DELETE_MAIL_TOKEN';
export const RECEIVE_DELETE_MAIL_TOKEN = 'RECEIVE_DELETE_MAIL_TOKEN';

export const REQUEST_SET_MAIL_TOKEN_MODE = 'REQUEST_SET_MAIL_TOKEN_MODE';
export const RECEIVE_SET_MAIL_TOKEN_MODE = 'RECEIVE_SET_MAIL_TOKEN_MODE';

export const REQUEST_SET_ROOM_FEED = 'REQUEST_SET_ROOM_FEED';
export const RECEIVE_SET_ROOM_FEED = 'RECEIVE_SET_ROOM_FEED';

export const REQUEST_LOAD_LATEST_FEED_MESSAGE = 'REQUEST_LOAD_LATEST_FEED_MESSAGE';
export const RECEIVE_LOAD_LATEST_FEED_MESSAGE = 'RECEIVE_LOAD_LATEST_FEED_MESSAGE';

export const REQUEST_ENABLE_2FA = 'REQUEST_ENABLE_2FA';
export const REQUEST_CREATE_API_USER = 'REQUEST_CREATE_API_USER';
export const RECEIVE_CREATE_API_USER = 'RECEIVE_CREATE_API_USER';

export const REQUEST_DELETE_API_USER = 'REQUEST_DELETE_API_USER';
export const RECEIVE_DELETE_API_USER = 'RECEIVE_DELETE_API_USER';

export const REQUEST_GET_API_USER_CREDENTIALS = 'REQUEST_GET_API_USER_CREDENTIALS';
export const RECEIVE_GET_API_USER_CREDENTIALS = 'RECEIVE_GET_API_USER_CREDENTIALS';

export const REQUEST_RENEW_API_USER_CREDENTIALS = 'REQUEST_RENEW_API_USER_CREDENTIALS';
export const RECEIVE_RENEW_API_USER_CREDENTIALS = 'RECEIVE_RENEW_API_USER_CREDENTIALS';

export const REQUEST_LIST_USER_EXPORT = 'REQUEST_LIST_USER_EXPORT';
export const RECEIVE_LIST_USER_EXPORT = 'RECEIVE_LIST_USER_EXPORT';

export const REQUEST_SET_2FA_SECRET = 'REQUEST_SET_2FA_SECRET';
export const RECEIVE_SET_2FA_SECRET = 'RECEIVE_SET_2FA_SECRET';

export const REQUEST_DELETE_2FA_SECRET = 'REQUEST_DELETE_2FA_SECRET';
export const RECEIVE_DELETE_2FA_SECRET = 'RECEIVE_DELETE_2FA_SECRET';

export const REQUEST_SET_BACKUP_CODES = 'REQUEST_SET_BACKUP_CODES';
export const RECEIVE_SET_BACKUP_CODES = 'RECEIVE_SET_BACKUP_CODES';

export const SERVER_URL = '/adminkonsole/AdminService'

const SERVER_URL_DEBUG = 'https://cockpit-stg.g3o.io/adminkonsole/AdminService'
// const SERVER_URL_DEBUG = 'https://cockpit.ginlo.net/adminkonsole/AdminService'


export function requestLogin() {
  return {
    type: REQUEST_LOGIN
  };
}

/* export function receiveLogin(json) {
  return ({
    type: RECEIVE_LOGIN,
    loginResult: json
  });
} */

export function requestLogout() {
  return {
    type: REQUEST_LOGOUT
  };
}

/* export function receiveLogout(json) {
  return ({
    type: RECEIVE_LOGOUT,
    logoutResult: json
  });
} */

export function requestResetPwd() {
  return {
    type: REQUEST_RESET_PWD
  };
}

/* export function receiveResetPwd(json) {
  return ({
    type: RECEIVE_RESET_PWD,
    loginResult: json
  });
} */

export function requestIsRecoveryPasswordValid() {
  return {
    type: REQUEST_IS_RECOVERY_PASSWORD_VALID
  };
}

/* export function receiveIsRecoveryPasswordValid(json) {
  return ({
    type: RECEIVE_IS_RECOVERY_PASSWORD_VALID
  });
} */

export function requestChangePwd() {
  return {
    type: REQUEST_CHANGE_PWD
  };
}

export function requestAdminInfo() {

  return {
    type: REQUEST_ADMIN_INFO
  };
}

/* export function receiveAdminInfo(json) {
  return ({
    type: RECEIVE_ADMIN_INFO,
    loginResult: json
  });
} */

export function requestUserList() {
  return {
    type: REQUEST_USER_LIST
  };
}

export function requestKeywordList() {
  return {
    type: REQUEST_KEYWORD_LIST
  };
}

/* export function receiveUserList(json) {
  return ({
    type: RECEIVE_USER_LIST,
    result: json
  });
} */

export function requestCreateUser() {
  return {
    type: REQUEST_CREATE_USER
  };
}

/* export function receiveCreateUser(json) {
  return ({
    type: RECEIVE_CREATE_USER,
    result: json
  });
} */

export function requestSetCompanyAdress() {
  return ({
    type: REQUEST_SET_COMPANY_ADRESS
  });
}

export function requestImportUsers() {
  return {
    type: REQUEST_IMPORT_USERS
  };
}

/* export function receiveImportUsers(json) {
  return ({
    type: RECEIVE_IMPORT_USERS,
    result: json
  });
} */

export function requestUpdateUser() {
  return {
    type: REQUEST_UPDATE_USER
  };
}

/* export function receiveUpdateUser(json) {
  return ({
    type: RECEIVE_UPDATE_USER,
    result: json
  });
} */

export function requestDeleteUser() {
  return {
    type: REQUEST_DELETE_USER
  };
}

/* export function receiveDeleteUser(json) {
  return ({
    type: RECEIVE_DELETE_USER,
    result: json
  });
} */

export function requestDeleteKeyword() {
  return {
    type: REQUEST_DELETE_KEYWORD
  };
}

/* export function receiveDeleteKeyword(json) {
  return ({
    type: RECEIVE_DELETE_KEYWORD,
    result: json
  });
} */

export function requestCreateGroup() {
  return {
    type: REQUEST_CREATE_ROOM
  };
}

/* export function receiveCreateGroup(json) {
  return ({
    type: RECEIVE_CREATE_ROOM,
    result: json
  });
} */

export function requestUpdateGroup() {
  return {
    type: REQUEST_UPDATE_ROOM
  };
}

/* export function receiveUpdateGroup(json) {
  return ({
    type: RECEIVE_UPDATE_ROOM,
    result: json
  });
} */

export function requestDeleteGroup() {
  return {
    type: REQUEST_DELETE_ROOM
  };
}

/* export function receiveDeleteGroup(json) {
  return ({
    type: RECEIVE_DELETE_ROOM,
    result: json
  });
} */

export function requestGetGroupImage() {
  return {
    type: REQUEST_GET_ROOMIMAGE
  };
}

/* export function receiveGetGroupImage(json) {
  return ({
    type: RECEIVE_GET_ROOMIMAGE,
    result: json
  });
} */

export function requestRoomList() {
  return {
    type: REQUEST_ROOM_LIST
  };
}

/* export function receiveRoomList(json) {
  return ({
    type: RECEIVE_ROOM_LIST,
    result: json
  });
} */

export function requestGetStatistic() {
  return {
    type: REQUEST_GET_STATISTIC
  };
}

/* export function receiveGetStatistic(json) {
  return ({
    type: RECEIVE_GET_STATISTIC,
    result: json
  });
} */

export function requestGetGroupStatistic() {
  return {
    type: REQUEST_GET_GROUP_STATISTIC
  };
}

/* export function receiveGetGroupStatistic(json) {
  return ({
    type: RECEIVE_GET_GROUP_STATISTIC,
    result: json
  });
} */

export function requestGetGroupChannelStatistic() {
  return {
    type: REQUEST_GET_GROUPCHANNEL_STATISTIC
  };
}

/* export function receiveGetGroupChannelStatistic(json) {
  return ({
    type: RECEIVE_GET_GROUPCHANNEL_STATISTIC,
    result: json
  });
} */

export function requestGetGroupStatisticDetails() {
  return {
    type: REQUEST_GET_GROUP_STATISTIC_DETAILS
  };
}

/* export function receiveGetGroupStatisticDetails(json) {
  return ({
    type: RECEIVE_GET_GROUP_STATISTIC_DETAILS,
    result: json
  });
} */

export function requestGetGroupChannelStatisticDetails() {
  return {
    type: REQUEST_GET_GROUPCHANNEL_STATISTIC_DETAILS
  };
}

/* export function receiveGetGroupChannelStatisticDetails(json) {
  return ({
    type: RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS,
    result: json
  });
} */

export function requestSetAppConfig() {
  return ({
    type: REQUEST_SET_APPCONFIG
  });
}

export function requestResetAppConfig() {
  return ({
    type: REQUEST_RESET_APPCONFIG
  });
}

export function requestAppConfig() {
  return ({
    type: REQUEST_APPCONFIG
  })
}

export function receiveAction(actionType, requestResultStatus, error, result) {
  return ({
    type: actionType,
    status: requestResultStatus,
    error: error,
    result: result
  });
}

export function requestListUsedVoucher() {
  return ({
    type: REQUEST_LIST_USED_VOUCHER
  });
}

export function requestListFreeVoucher() {
  return ({
    type: REQUEST_LIST_FREE_VOUCHER
  });
}

export function requestAssignFreeVoucher() {
  return ({
    type: REQUEST_ASSIGN_FREE_VOUCHER
  });
}

export function requestRevokeVoucher() {
  return ({
    type: REQUEST_REVOKE_VOUCHER
  });
}

export function requestCompanyLayout() {
  return ({
    type: REQUEST_COMPANYLAYOUT
  })
}

export function requestCompanyLogo() {
  return ({
    type: REQUEST_COMPANYLOGO
  })
}

export function requestSetCompanyLayout() {
  return ({
    type: REQUEST_SET_COMPANYLAYOUT
  });
}

export function requestResetCompanyLayout() {
  return ({
    type: REQUEST_RESET_COMPANYLAYOUT
  });
}

export function requestSetCompanyLogo() {
  return ({
    type: REQUEST_SET_COMPANYLOGO
  });
}

export function requestSetCompanyProfilImage() {
  return ({
    type: REQUEST_SET_COMPANY_PROFIL_IMAGE
  });
}

export function requestSetCompanyLanguage() {
  return ({
    type: REQUEST_SET_COMPANYLANGUAGE
  });
}
export function requestSendTextMessage() {
  return ({
    type: REQUEST_SEND_TEXTMESSAGE
  })
}

export function requestSendImageMessage() {
  return ({
    type: REQUEST_SEND_IMAGEMESSAGE
  })
}

export function requestSendFileMessage() {
  return ({
    type: REQUEST_SEND_FILEMESSAGE
  })
}

export function requestGetRoomMessageGuids() {
  return ({
    type: REQUEST_GET_ROOMMESSAGEGUIDS
  })
}

export function requestGetRoomTimedMessageGuids() {
  return ({
    type: REQUEST_GET_ROOMTIMEDMESSAGEGUIDS
  })
}

export function requestGetRoomMessageBatch() {
  return ({
    type: REQUEST_GET_ROOMMESSAGEBATCH
  })
}

export function requestRemoveRoomMessages() {
  return ({
    type: REQUEST_REMOVE_ROOMMESSAGES
  })
}

export function requestRemoveTimedRoomMessage() {
  return ({
    type: REQUEST_REMOVE_TIMEDROOMMESSAGE
  })
}

export function requestShowRecoveryKey() {
  return ({
    type: REQUEST_SHOW_RECOVERY_KEY
  })
}

export function requestSendRecoveryPasswordMail() {
  return ({
    type: REQUEST_SEND_RECOVERYPASSWORD_MAIL
  })
}

export function requestSetAdminName() {
  return ({
    type: REQUEST_SET_ADMIN_NAME
  });
}

export function requestDeleteCompany() {
  return ({
    type: REQUEST_DELETE_COMPANY
  });
}

export function requestPurchaseCompanyVoucher() {
  return ({
    type: REQUEST_PURCHASE_COMPANY_VOUCHER
  });
}

export function requestResetCompanyUserState() {
  return ({
    type: REQUEST_RESET_COMPANYUSERSTATE
  });
}

export function requestAssignVouchersByImportID() {
  return {
    type: REQUEST_ASSIGN_VOUCHERS_BY_IMPORT_ID
  };
}

export function requestAdminList() {
  return {
    type: REQUEST_ADMIN_LIST
  };
}

export function requestCreateAdmin() {
  return {
    type: REQUEST_CREATE_ADMIN
  };
}

export function requestSetAdminEnabled() {
  return {
    type: REQUEST_SET_ADMIN_ENABLED
  };
}

export function requestDeleteAdmin() {
  return {
    type: REQUEST_DELETE_ADMIN
  };
}

export function requestStartImportLdap() {
  return {
    type: REQUEST_START_IMPORT_LDAP
  };
}

export function requestDiscardImport() {
  return {
    type: REQUEST_DISCARD_IMPORT
  };
}

export function requestDownloadReport() {
  return {
    type: REQUEST_DOWNLOAD_REPORT
  };
}

export function requestGetImportState() {
  return {
    type: REQUEST_GET_IMPORT_STATE
  };
}
export function requestStartApplyImport() {
  return {
    type: REQUEST_START_APPLY_IMPORT
  };
}

export function requestCreateMailToken() {
  return {
    type: REQUEST_CREATE_MAIL_TOKEN
  };
}

export function requestDeleteMailToken() {
  return {
    type: REQUEST_DELETE_MAIL_TOKEN
  };
}

export function requestSetMailTokenMode() {
  return {
    type: REQUEST_SET_MAIL_TOKEN_MODE
  };
}

export function requestSetRoomFeed() {
  return {
    type: REQUEST_SET_ROOM_FEED
  };
}

export function requestLoadLatestFeedMessage() {
  return {
    type: REQUEST_LOAD_LATEST_FEED_MESSAGE
  };
}

export function requestCreateApiUser() {
  return {
    type: REQUEST_CREATE_API_USER
  };
}

export function requestEnable2FA() {
  return {
    type: REQUEST_ENABLE_2FA
  };
}

export function requestDeleteApiUser() {
  return {
    type: REQUEST_DELETE_API_USER
  };
}

export function requestGetApiUserCredentials() {
  return {
    type: REQUEST_GET_API_USER_CREDENTIALS
  };
}

export function requestRenewApiUserCredentials() {
  return {
    type: REQUEST_RENEW_API_USER_CREDENTIALS
  };
}

export function requestListUserExport() {
  return {
    type: REQUEST_LIST_USER_EXPORT
  };
}


/**
 *
 * @returns {{type: string}}
 */
export function requestSet2FASecret() {
  return {
    type: REQUEST_SET_2FA_SECRET
  };
}


/**
 *
 * @returns {{type: string}}
 */
export function requestDelete2FASecret() {
  return {
    type: REQUEST_DELETE_2FA_SECRET
  };
}


/**
 *
 * @returns {{type: string}}
 */
export function requestSetBackupCodes() {
  return {
    type: REQUEST_SET_BACKUP_CODES
  };
}


function needAuthorization(cmd) {
  switch (cmd) {
    case 'login':
    case 'resetPassword':
    case 'isRecoveryPasswordValid':
    case 'getCompanyOptInState':
      return false;
    default:
      return true;
  }
}

/*
function serverRequestFetch(params, nextActionType, callback, errorCallback) {
  return (dispatch, getState) => {
    var formBody = [];
    var needAuth = true;

    if (params)
    {
      needAuth = needAuthorization(params.cmd);
      for (var property in params) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }

    formBody = formBody.join("&");

    //debugger
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    };

    if (needAuth)
    {
      let state = getState();
      headers['Authorization'] = 'Bearer ' + state.session.sessionId;
      headers['X-CSRF-Token'] = state.session.csrftoken;
    }

    var url = SERVER_URL;
    if (process.env.NODE_ENV === 'development')
    {
      headers['Access-Control-Allow-Origin'] = '*'
      url = SERVER_URL_DEBUG
    }


    var request = new Request(url, {
      mode: 'cors',
      method: 'POST',
      headers: headers,
      body: formBody
    });

    return fetch(request)
      .then(function(response) {

        if (response.ok)
        {
          let jsonResponse = response.json().catch(function() {
            return dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
              ident: 'NO_JSON_RESPONSE'
            }, {}));
          });

          return jsonResponse;
        }
        else
        {
          return dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
            ident: response.status,
            message: response.statusText
          }, {}));
        }
      }
    )
      .then(function(json) {

        if (json)
        {
          let errorJO = json['Exception'];
          if (errorJO)
          {
            dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, errorJO, json))
            if (typeof errorCallback === "function")
            {
              errorCallback(errorJO);
            }
          }
          else
          {
            dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_SUCCESS, {}, json))
            if (typeof callback === "function")
            {
              callback(json);
            }
          }
        }
        else
        {
          dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
            ident: 'NO_JSON_RESPONSE'
          }, {}))
        }
      });
  }
}
*/


/**
 *
 * @param {Object.<string, *>} params
 * @param {string} nextActionType
 * @param {Function} callback
 * @param {Function} errorCallback
 * @returns {(function(*, *): void)|*}
 */
function serverRequest(params, nextActionType, callback, errorCallback) {

  return (dispatch, getState) => {
    let formBody = [];
    let needAuth = true;

    if (params) {
      needAuth = needAuthorization(params.cmd);
      for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }
    formBody = formBody.join("&");
    let request = new XMLHttpRequest();

    let url = SERVER_URL;
    if (process.env.NODE_ENV === 'development') {
      //request.setRequestHeader('Access-Control-Allow-Origin', '*')
      url = SERVER_URL_DEBUG;
    }

    request.open('POST', url);
    request.timeout = 60 * 1000;

    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

    if (needAuth) {
      let state = getState();
      //console.log(state.session.csrftoken);
      request.setRequestHeader('Authorization', 'Bearer ' + state.session.sessionId);
      request.setRequestHeader('X-CSRF-Token', state.session.csrftoken);
    }

    request.addEventListener('error', function() {
      //console.log("no connection");
      return dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
        ident: 'NO_SERVER_RESPONSE'
      }, {}));
    });

    request.addEventListener('load', function() {

      if (request.status >= 200 && request.status < 300) {
        let response = request.responseText;
        let json = undefined;
        try {
          json = JSON.parse(response)
        } catch (e) {
          return dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
            ident: 'NO_JSON_RESPONSE'
          }, {}));
        } finally {}

        if (json) {
          let errorJO = json['Exception'];
          if (errorJO) {
            dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, errorJO, json));
            if (typeof errorCallback === 'function') {
              errorCallback(errorJO);
            }
          } else {
            dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_SUCCESS, {}, json));
            if (typeof callback === "function") {
              callback(json);
            }
          }
        } else {
          dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
            ident: 'NO_JSON_RESPONSE'
          }, {}));
        }

      } else {
        return dispatch(receiveAction(nextActionType, REQUEST_RESULT_STATUS_ERROR, {
          ident: request.status,
          message: request.statusText
        }, {}));
      }
    });

    request.send(formBody);

  }
}


/**
 *
 * @param {string} email
 * @param {string} pwd
 * @param {string|undefined} twoFaToken
 * @param callback
 * @param errorCallback
 * @returns {(function(*, *): (*|undefined))|*}
 */
export function login(email, pwd, twoFaToken, callback, errorCallback) {

  return (dispatch, getState) => {
    let state = getState();

    if (!state.loginAction.crsftoken) {
      dispatch( requestLogin() );
      const params = {
        cmd: 'login',
        username: email,
        password: pwd,
        totp: twoFaToken || ''
      }
      return dispatch( serverRequest(params, RECEIVE_LOGIN, callback, errorCallback) );
    }

  }

}


function isLoggedIn(state) {
  return !!state.session.isLoggedIn;
}

export function resetPassword(username, recoveryPwd, passwordNew, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestResetPwd());

    const params = {
      cmd: 'resetPassword',
      username: username,
      recoveryPassword: recoveryPwd,
      passwordNew: passwordNew
    }

    return dispatch(serverRequest(params, RECEIVE_RESET_PWD, callback, errorCallback));
  }
}

export function isRecoveryPasswordValid(username, recoveryPwd, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestIsRecoveryPasswordValid());

    const params = {
      cmd: 'isRecoveryPasswordValid',
      username: username,
      recoveryPassword: recoveryPwd
    }

    return dispatch(serverRequest(params, RECEIVE_IS_RECOVERY_PASSWORD_VALID, callback, errorCallback));
  }
}


export function changePassword(passwordOld, passwordNew, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestChangePwd());

    const params = {
      cmd: 'changePassword',
      passwordOld: passwordOld,
      passwordNew: passwordNew
    }

    return dispatch(serverRequest(params, RECEIVE_CHANGE_PWD, callback, errorCallback));
  }
}


export function logout() {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(requestLogout())
    if (isLoggedIn(state))
    {
      const params = {
        cmd: 'logout'
      }

      return dispatch(serverRequest(params, RECEIVE_LOGOUT));
    }
  }
}


export function getAdminInfo(async, callback, errorCallback) {
  return (dispatch, getState) => {
    let state = getState();

    dispatch(requestAdminInfo())
    if (isLoggedIn(state))
    {
      const params = {
        cmd: 'getAdminInfo',
        async: async ? '1' : '0'
      }

      return dispatch(serverRequest(params, RECEIVE_ADMIN_INFO, callback, errorCallback));
    }
  }
}

export function getUserList(ifModifiedSince, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestUserList());

    const params = {
      cmd: 'listUser'
    }
    if (typeof ifModifiedSince !== "undefined" && ifModifiedSince !== null)
    {
      params["ifModifiedSince"] = ifModifiedSince
    }

    return dispatch(serverRequest(params, RECEIVE_USER_LIST, callback, errorCallback));
  }
}

export function getSingleUser(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestUserList());

    const params = {
      cmd: 'listUser',
      guids: guid
    }

    return dispatch(serverRequest(params, RECEIVE_USER_LIST, callback, errorCallback));
  }
}

export function getKeywordList(callback) {
  return (dispatch) => {
    dispatch(requestKeywordList());

    const params = {
      cmd: 'listKeyword'
    }

    return dispatch(serverRequest(params, RECEIVE_KEYWORD_LIST, callback));
  }
}


export function createUser(name, firstName, email, phone, keyword, department, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCreateUser());

    const params = {
      cmd: 'createUser',
      name: name,
      firstName: firstName,
      email: email,
      phone: phone,
      keyword: keyword,
      department: department
    }
    return dispatch(serverRequest(params, RECEIVE_CREATE_USER, callback, errorCallback));
  }
}

export function setCompanyAdress(name, street, streetNumber, postCode, city, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetCompanyAdress());

    const params = {
      cmd: 'setCompanyAdress',
      name: name,
      street: street,
      streetNumber: streetNumber,
      postCode: postCode,
      city: city
    }

    return dispatch(serverRequest(params, RECEIVE_SET_COMPANY_ADRESS, callback, errorCallback))
  }
}

export function setAdminName(name, firstName, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetAdminName());

    const params = {
      cmd: 'setAdminName',
      name: name,
      firstName: firstName
    }

    return dispatch(serverRequest(params, RECEIVE_SET_ADMIN_NAME, callback, errorCallback))
  }
}

export function deleteCompany(password, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteCompany());

    const params = {
      cmd: 'deleteCompany',
      password: password
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_COMPANY, callback, errorCallback))
  }
}


export function purchaseCompanyVoucher(licenseCount, optInState, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestPurchaseCompanyVoucher());

    const params = {
      cmd: 'purchaseCompanyVoucher',
      licenseCount: licenseCount
    }

    if (typeof optInState !== 'undefined' && optInState !== null && optInState !== OPT_IN_STATES.NOT_SET)
    {
      params.optInState = optInState;
    }

    return dispatch(serverRequest(params, RECEIVE_PURCHASE_COMPANY_VOUCHER, callback, errorCallback))
  }
}


export function importUsers(data, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestImportUsers());

    const params = {
      cmd: 'importUsers',
      data: data
    }

    return dispatch(serverRequest(params, RECEIVE_IMPORT_USERS, callback, errorCallback));
  }
}

export function updateUser(guid, name, firstName, email, phone, keyword, department, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestUpdateUser());

    const params = {
      cmd: 'updateUser',
      guid: guid,
      name: name,
      firstName: firstName,
      email: email,
      phone: phone,
      keyword: keyword,
      department: department
    }

    return dispatch(serverRequest(params, RECEIVE_UPDATE_USER, callback, errorCallback));
  }
}

export function deleteUser(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteUser());

    const params = {
      cmd: 'deleteCompanyUser',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_USER, callback, errorCallback));
  }
}

export function deleteKeyword(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteKeyword());

    const params = {
      cmd: 'deleteKeyword',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_KEYWORD, callback, errorCallback));
  }
}


export function createGroup(name, type, image, member, writer, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCreateGroup());

    const params = {
      cmd: 'createRoom',
      name: name,
      type: type,
      roommember: member,

    }
    if (typeof image !== 'undefined' && image !== null)
    {
      params.image = image;
    }

    if (typeof writer !== 'undefined' && writer !== null)
    {
      params.roomwriter = writer
    }
    return dispatch(serverRequest(params, RECEIVE_CREATE_ROOM, callback, errorCallback));
  }
}

export function updateGroup(guid, name, image, member, roomwriter, feed, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestUpdateGroup());

    const params = {
      cmd: 'updateRoom',
      guid: guid,
      name: name,
      roommember: member,
    }
    if (typeof roomwriter !== 'undefined' && roomwriter !== null)
    {
      params.roomwriter = roomwriter
    }
    if (typeof image !== 'undefined' && image !== null)
    {
      params.image = image;
    }
    if (typeof feed !== 'undefined')
    {
      params.feed = feed;
    }

    return dispatch(serverRequest(params, RECEIVE_UPDATE_ROOM, callback, errorCallback));
  }
}

export function deleteGroup(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteGroup());

    const params = {
      cmd: 'removeRoom',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_ROOM, callback, errorCallback));
  }
}

export function getGroupImage(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetGroupImage());

    const params = {
      cmd: 'getRoomImage',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_GET_ROOMIMAGE, callback, errorCallback));
  }
}


export function getGroupList(type, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRoomList());

    const params = {
      cmd: 'listRoom',
      type: type
    }

    return dispatch(serverRequest(params, RECEIVE_ROOM_LIST, callback, errorCallback));
  }
}

export function getSingleGroup(type, guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRoomList());

    const params = {
      cmd: 'listRoom',
      type: type,
      guids: guid
    }

    return dispatch(serverRequest(params, RECEIVE_ROOM_LIST, callback, errorCallback));
  }
}

export function getStatistics() {
  return (dispatch, getState) => {

    let state = getState()
    const {statistics} = state

    if (statistics && (statistics.isFetching || statistics.hasData))
    {
      return
    }

    dispatch(requestGetStatistic());

    const params = {
      cmd: 'getStatistic',
      keys: 'activities'
    }

    return dispatch(serverRequest(params, RECEIVE_GET_STATISTIC));
  }
}

export function getGroupStatistics() {
  return dispatch => {

    dispatch(requestGetGroupStatistic());

    const params = {
      cmd: 'getStatistic',
      keys: 'groups'
    }

    return dispatch(serverRequest(params, RECEIVE_GET_GROUP_STATISTIC));
  }
}

export function getGroupChannelStatistics() {
  return dispatch => {

    dispatch(requestGetGroupChannelStatistic());

    const params = {
      cmd: 'getStatistic',
      keys: 'groupChannels'
    }

    return dispatch(serverRequest(params, RECEIVE_GET_GROUPCHANNEL_STATISTIC));
  }
}

export function getGroupStatisticsDetails(guid) {
  return dispatch => {

    dispatch(requestGetGroupStatisticDetails());

    const params = {
      cmd: 'getStatisticDetails',
      key: 'group',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_GET_GROUP_STATISTIC_DETAILS));
  }
}

export function getGroupChannelStatisticsDetails(guid) {
  return dispatch => {

    dispatch(requestGetGroupChannelStatisticDetails());

    const params = {
      cmd: 'getStatisticDetails',
      key: 'groupChannel',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS));
  }
}

export function setAppConfig(configData, callback, errorCallback) {
  return (dispatch) => {

    dispatch(requestSetAppConfig());

    const params = {
      cmd: 'setAppConfig',
      config: configData,
    }
    //console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SET_APPCONFIG, callback, errorCallback));
  }
}

export function resetAppConfig(callback, errorCallback) {
  return (dispatch) => {

    dispatch(requestResetAppConfig());

    const params = {
      cmd: 'resetAppConfig'
    }
    //console.log(params);
    return dispatch(serverRequest(params, RECEIVE_RESET_APPCONFIG, callback, errorCallback));
  }
}

export function getAppConfig(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestAppConfig());

    const params = {
      cmd: 'getAppConfig'
    }

    return dispatch(serverRequest(params, RECEIVE_APPCONFIG, callback, errorCallback));
  }
}

export function getListUsedVoucher(ifModifiedSince, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestListUsedVoucher());

    const params = {
      cmd: 'listUsedVoucher'
    }
    if (typeof ifModifiedSince !== "undefined" && ifModifiedSince !== null)
    {
      params["ifModifiedSince"] = ifModifiedSince
    }


    return dispatch(serverRequest(params, RECEIVE_LIST_USED_VOUCHER, callback, errorCallback));
  }
}

export function getListFreeVoucher(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestListFreeVoucher());

    const params = {
      cmd: 'listFreeVoucher'
    }

    return dispatch(serverRequest(params, RECEIVE_LIST_FREE_VOUCHER, callback, errorCallback));
  }
}

export function assignFreeVoucher(voucherGroupGuid, userGuids, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestAssignFreeVoucher());

    const params = {
      cmd: 'assignFreeVoucher',
      voucherGroup: voucherGroupGuid,
      userGuids: userGuids
    }

    return dispatch(serverRequest(params, RECEIVE_ASSIGN_FREE_VOUCHER, callback, errorCallback));
  }
}

export function revokeVoucher(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRevokeVoucher());

    const params = {
      cmd: 'revokeVoucher',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_REVOKE_VOUCHER, callback, errorCallback));
  }
}

export function getCompanyLayout(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCompanyLayout());

    const params = {
      cmd: 'getCompanyLayout'
    }

    return dispatch(serverRequest(params, RECEIVE_COMPANYLAYOUT, callback, errorCallback));
  }
}

export function getCompanyLogo(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCompanyLogo());

    const params = {
      cmd: 'getCompanyLogo'
    }

    return dispatch(serverRequest(params, RECEIVE_COMPANYLOGO, callback, errorCallback));
  }
}

export function setCompanyLayout(layoutDataString, callback, errorCallback) {
  return (dispatch) => {

    dispatch(requestSetCompanyLayout());

    const params = {
      cmd: 'setCompanyLayout',
      layoutData: layoutDataString,
    }
    return dispatch(serverRequest(params, RECEIVE_SET_COMPANYLAYOUT, callback, errorCallback));
  }
}

export function resetCompanyLayout(callback, errorCallback) {
  return (dispatch) => {

    dispatch(requestResetCompanyLayout());

    const params = {
      cmd: 'resetCompanyLayout'
    }
    return dispatch(serverRequest(params, RECEIVE_RESET_COMPANYLAYOUT, callback, errorCallback));
  }
}

export function setCompanyLogo(imageDataString, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetCompanyLogo());

    const params = {
      cmd: 'setCompanyLogo',
      imageData: imageDataString,
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SET_COMPANYLOGO, callback, errorCallback));
  }
}

export function setCompanyProfilImage(imageDataString, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetCompanyProfilImage());

    const params = {
      cmd: 'setCompanyProfilImage',
      imageData: imageDataString,
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SET_COMPANY_PROFIL_IMAGE, callback, errorCallback));
  }
}
export function setCompanyLanguage(language, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetCompanyLanguage());

    const params = {
      cmd: 'setCompanyLanguage',
      language: language,
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SET_COMPANYLANGUAGE, callback, errorCallback));
  }
}

export function sendTextMessage(guid, messageText, dateToSend, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSendTextMessage());
    const params = {
      cmd: 'sendTextMessage',
      guid: guid,
      messageText: messageText,
    }

    if (dateToSend !== null && dateToSend !== '')
    {
      params.dateToSend = dateToSend
    }
    //console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SEND_TEXTMESSAGE, callback, errorCallback));
  }
}

export function sendImageMessage(guid, imageData, messageText, dateToSend, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSendImageMessage());

    const params = {
      cmd: 'sendImageMessage',
      guid: guid,
      imageData: imageData,
    }
    if (dateToSend !== null && dateToSend !== '')
    {
      params.dateToSend = dateToSend
    }
    if (messageText !== null && messageText !== '')
    {
      params.messageText = messageText;
    }
    return dispatch(serverRequest(params, RECEIVE_SEND_IMAGEMESSAGE, callback, errorCallback));
  }
}

export function sendFileMessage(guid, fileData, fileName, dateToSend, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSendFileMessage());

    const params = {
      cmd: 'sendFileMessage',
      guid: guid,
      fileData: fileData,
      fileName: fileName,
    }
    if (dateToSend !== null && dateToSend !== '')
    {
      params.dateToSend = dateToSend
    }
    return dispatch(serverRequest(params, RECEIVE_SEND_FILEMESSAGE, callback, errorCallback));
  }
}

export function getRoomMessageGuids(guid, limit, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetRoomMessageGuids());

    const params = {
      cmd: 'getRoomMessageGuids',
      guid: guid,
      limit: limit,
    }

    return dispatch(serverRequest(params, RECEIVE_GET_ROOMMESSAGEGUIDS, callback, errorCallback));
  }
}

export function getRoomTimedMessageGuids(guid, limit, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetRoomTimedMessageGuids());

    const params = {
      cmd: 'getRoomTimedMessageGuids',
      guid: guid,
      limit: limit,
    }

    return dispatch(serverRequest(params, RECEIVE_GET_ROOMTIMEDMESSAGEGUIDS, callback, errorCallback));
  }
}

export function getRoomMessageBatch(guid, messageGuids, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetRoomMessageBatch());

    const params = {
      cmd: 'getRoomMessageBatch',
      roomGuid: guid,
      guids: messageGuids,
    }
    return dispatch(serverRequest(params, RECEIVE_GET_ROOMMESSAGEBATCH, callback, errorCallback));
  }
}

export function removeRoomMessages(roomGuid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRemoveRoomMessages());

    const params = {
      cmd: 'removeRoomMessages',
      roomGuid: roomGuid,
    }

    return dispatch(serverRequest(params, RECEIVE_REMOVE_ROOMMESSAGES, callback, errorCallback));
  }
}

export function removeTimedRoomMessage(roomGuid, messageGuid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRemoveTimedRoomMessage());

    const params = {
      cmd: 'removeTimedRoomMessage',
      roomGuid: roomGuid,
      messageGuid: messageGuid,
    }

    return dispatch(serverRequest(params, RECEIVE_REMOVE_TIMEDROOMMESSAGE, callback, errorCallback));
  }
}

export function showRecoveryKey(accountGuid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestShowRecoveryKey());

    const params = {
      cmd: 'showRecoveryKey',
      accountGuid: accountGuid,
    }

    return dispatch(serverRequest(params, RECEIVE_SHOW_RECOVERY_KEY, callback, errorCallback));
  }
}

export function sendRecoveryPasswordMail(accountGuid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSendRecoveryPasswordMail());

    const params = {
      cmd: 'sendRecoveryPasswordMail',
      accountGuid: accountGuid,
    }

    return dispatch(serverRequest(params, RECEIVE_SEND_RECOVERYPASSWORD_MAIL, callback, errorCallback));
  }
}

export function sendResetCompanyUserState(accountGuid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestResetCompanyUserState());

    const params = {
      cmd: 'resetCompanyUserState',
      guid: accountGuid,
    }

    return dispatch(serverRequest(params, RECEIVE_RESET_COMPANYUSERSTATE, callback, errorCallback));
  }
}

export function assignVouchersByImportID(importID, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestAssignVouchersByImportID());

    const params = {
      cmd: 'assignFreeVouchersByImportID',
      importID: importID
    }

    return dispatch(serverRequest(params, RECEIVE_ASSIGN_VOUCHERS_BY_IMPORT_ID, callback, errorCallback));
  }
}

export function getAdminList(callback) {
  return (dispatch) => {
    dispatch(requestAdminList());

    const params = {
      cmd: 'listAdmin'
    }

    return dispatch(serverRequest(params, RECEIVE_ADMIN_LIST, callback));
  }
}

export function createAdmin(name, firstName, email, phone, role, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCreateAdmin());

    const params = {
      cmd: 'createAdmin',
      name: name,
      firstName: firstName,
      email: email,
      phone: phone,
      role: role
    }

    return dispatch(serverRequest(params, RECEIVE_CREATE_ADMIN, callback, errorCallback));
  }
}

export function setAdminEnabled(guid, enabled, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetAdminEnabled());

    const params = {
      cmd: 'setAdminEnabled',
      guid: guid,
      enabled: enabled
    }

    return dispatch(serverRequest(params, RECEIVE_SET_ADMIN_ENABLED, callback, errorCallback));
  }
}

export function deleteAdmin(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteAdmin());

    const params = {
      cmd: 'deleteAdmin',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_ADMIN, callback, errorCallback));
  }
}

export function startImportLdap(data, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestStartImportLdap());

    const params = {
      cmd: 'startImportLdap',
      data: data
    }

    return dispatch(serverRequest(params, RECEIVE_START_IMPORT_LDAP, callback, errorCallback));
  }
}

export function getImportState(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetImportState());

    const params = {
      cmd: 'getImportState',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_GET_IMPORT_STATE, callback, errorCallback));
  }
}

export function discardImport(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDiscardImport());

    const params = {
      cmd: 'discardImport',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DISCARD_IMPORT, callback, errorCallback));
  }
}

export function downloadReport(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDownloadReport());

    const params = {
      cmd: 'downloadReport',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_DOWNLOAD_REPORT, callback, errorCallback));
  }
}

export function startApplyImport(guid, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestStartApplyImport());

    const params = {
      cmd: 'startApplyImport',
      guid: guid
    }

    return dispatch(serverRequest(params, RECEIVE_START_APPLY_IMPORT, callback, errorCallback));
  }
}

export function createMailToken(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCreateMailToken());

    const params = {
      cmd: 'createMailToken'
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_CREATE_MAIL_TOKEN, callback, errorCallback));
  }
}

export function deleteMailToken(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteMailToken());

    const params = {
      cmd: 'deleteMailToken'
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_DELETE_MAIL_TOKEN, callback, errorCallback));
  }
}

export function setMailTokenMode(newMode, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetMailTokenMode());

    const params = {
      cmd: 'setMailTokenMode',
      mode: newMode
    }
    //    console.log(params);
    return dispatch(serverRequest(params, RECEIVE_SET_MAIL_TOKEN_MODE, callback, errorCallback));
  }
}

export function setRoomFeed(guid, feedUrl, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestSetRoomFeed());

    const params = {
      cmd: 'setRoomFeed',
      guid: guid,
      url: feedUrl,
    }

    return dispatch(serverRequest(params, RECEIVE_SET_ROOM_FEED, callback, errorCallback));
  }
}

export function loadLatestFeedMessage(feedUrl, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestLoadLatestFeedMessage());

    const params = {
      cmd: 'loadLatestFeedMessage',
      url: feedUrl,
    }

    return dispatch(serverRequest(params, RECEIVE_LOAD_LATEST_FEED_MESSAGE, callback, errorCallback));
  }
}

export function createApiUser(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestCreateApiUser());

    const params = {
      cmd: 'createApiUser'
    }

    return dispatch(serverRequest(params, RECEIVE_CREATE_API_USER, callback, errorCallback));
  }
}


/**
 *
 * @param {string} secret
 * @param {function} callback
 * @param {function} errorCallback
 * @returns {function(*): *}
 */
export function enableTwoFa( secret, callback, errorCallback) {
  return ( dispatch ) => {
    dispatch( requestSet2FASecret() );

    const params = {
      cmd: 'set2FASecret',
      secret: secret
    }

    return dispatch( serverRequest(params, RECEIVE_SET_2FA_SECRET, callback, errorCallback) );
  }
}


/**
 *
 * @param {function} callback
 * @param {function} errorCallback
 * @returns {function(*): *}
 */
export function disableTwoFa( callback, errorCallback ) {
  return ( dispatch ) => {
    dispatch( requestDelete2FASecret() );

    const params = {
      cmd: 'delete2FASecret'
    }

    return dispatch( serverRequest(params, RECEIVE_DELETE_2FA_SECRET, callback, errorCallback) );
  }
}


/**
 *
 * @param {string} recoveryCodes - CSV format (comma seperated)
 * @param {function} callback
 * @param {function} errorCallback
 */
export function setRecoveryCodes( recoveryCodes, callback, errorCallback ) {
  return ( dispatch ) => {
    dispatch( requestSetBackupCodes() );

    const params = {
      cmd: 'setBackupCodes',
      backupCodes: recoveryCodes
    }

    return dispatch( serverRequest(params, RECEIVE_SET_BACKUP_CODES, callback, errorCallback) );
  }
}


export function deleteApiUser(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestDeleteApiUser());

    const params = {
      cmd: 'deleteApiUser'
    }

    return dispatch(serverRequest(params, RECEIVE_DELETE_API_USER, callback, errorCallback));
  }
}

export function getApiUserCredentials(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestGetApiUserCredentials());
    const params = {
      cmd: 'getApiUserCredentials'
    }

    return dispatch(serverRequest(params, RECEIVE_GET_API_USER_CREDENTIALS, callback, errorCallback));
  }
}

export function renewApiUserCredentials(mode, callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestRenewApiUserCredentials());

    const params = {
      cmd: 'renewApiUserCredentials',
      mode: mode
    }

    return dispatch(serverRequest(params, RECEIVE_RENEW_API_USER_CREDENTIALS, callback, errorCallback));
  }
}

export function getCompanyOptInState(callback, errorCallback) {
  return (dispatch) => {

    const params = {
      cmd: 'getCompanyOptInState',
    }

    return dispatch(serverRequest(params, "", callback, errorCallback));
  }
}

export function listUserExport(callback, errorCallback) {
  return (dispatch) => {
    dispatch(requestListUserExport());

    const params = {
      cmd: 'listUserExport'
    }

    return dispatch(serverRequest(params, RECEIVE_LIST_USER_EXPORT, callback, errorCallback));
  }
}
