import React from 'react';
import ModalDialog from './ModalDialog.js'

class ErrorDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorText: "",
    };
  }

  isOpen() {
    return this.refs.modalErrorDialog.isOpen();
  }

  open(parameter) {
    const {t} = this.props;

    var text;
    if (typeof parameter.errorText === "string")
    {
      text = parameter.errorText;
    }

    if (typeof parameter.errorIdent === "string")
    {
      let errorCode = "SERVICE-" + parameter.errorIdent;

      text = text ? (text + " (" + parameter.errorIdent + ")") : t(errorCode);
    }

    this.setState({
      errorText: text
    })

    this.refs.modalErrorDialog.open({
      title: t("error"),
      text: text,
      button1Text: t("components-ModalDialog-ok")
    })
  }

  render() {
    return (<ModalDialog ref="modalErrorDialog" />);
  }
}

export default ErrorDialog;
