import React, { Component }  from 'react'
import GroupsManager from '../common/groups/GroupsManager'

export class Groups extends Component{
  render() {
    return (
        <GroupsManager
          GroupsIdentifier="Gruppen"
          GroupIdentifier="Gruppe"
          RoomType="ManagedRoom"
          GroupChipColor="#737373"
          />
    )
  }
}

//const ChannelsTranslated = translate(['common'])(Channel);
export default (Groups);
