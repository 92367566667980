/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRowColumn, TableRow, } from 'material-ui/Table';

import BaseModul from '../BaseModul'
import { PageHeader } from '../../components/pageheader'
// import { BackSubPageHeader } from '../../components/pageheader';
import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';
// import FloatingActionButton from 'material-ui/FloatingActionButton';

import { WaitDialog } from '../../components/WaitDialog'
import { ModalDialog } from '../../components/ModalDialog'
import DataProvider from './DataProvider';
import { ColorPicker } from './ColorPicker';
import { Preview } from '../../ressources/preview.js';

import { getCompanyLayout, getCompanyLogo, setCompanyLayout, setCompanyLogo, resetCompanyLayout } from '../../actions/index';

import { IsNullOrEmpty } from "../../components/StringExtensions";
import { expandShorthandHex } from '../../util/ColorUtil';

import IconReset from '../../ressources/icon_reset.js';

import CropImageDialog from './CropImageDialog.js'


const styles = {
  subPageHeader: {
    fontSize: '20px',
    fontWeight: 'medium',
    color: '#EEC643',
    marginLeft: '25px',
    marginTop: '20px',
  },
  description: {
    fontSize: '12px',
    fontWeight: 'regular',
    color: '#DDDEE0',
    width: '400px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    marginLeft: '25px',
    marginTop: '30px',
  },
  pureDescription: {
    fontSize: '12px',
    fontWeight: 'regular',
    color: '#7B98AB',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  colorpicker: {
    marginLeft: '25px',
    marginTop: '20px',
  },
  colormodeswitch: {
    width: '40px',
    padding: 0,
  },
  colorModeSelected: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: '#EEC643',
    marginLeft: '2px',
    marginRight: '2px',
  },
  colorModeNotSelected: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: '#FFFFFF',
    marginLeft: '2px',
    marginRight: '2px',
  },
  colorHeading: {
    fontSize: '14px',
    color: '#7B98AB',
    marginRight: '2px',
    fontWeight: "600"
  },
  marginRgb: {
    marginRight: '38px',
  },
  tableStyle: {
    marginLeft: '15px',
    marginTop: '20px',
    marginBottom: '8px',
    marginRight: '3px',
    width: '700px',
    height: 'auto',
  },
  labelColumn: {
    width: '240px',
  },
  colorColumn: {
    width: '268px',
  },
  constrastColumn: {
    width: '268px',
  },
  uploadButton: {
    verticalAlign: 'middle',
    margin: 0,
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  preview: {
    float: "right",
  },
  previewDiv: {
    position: "absolute",
    top: "-100px",
    right: "20px",
  },
  colorpickerDiv: {
    position: "relative",
    width: "1350px",
  },
  companyLogo: {
    maxWidth: 160,
    maxHeight: 50,
    width: 'auto',
    height: 'auto',
    display: 'inline-block'
  }
}


class AppDesign extends BaseModul {
  //Ein bisschen umständlich, den state vorzuinitialisieren!
  state = {
    colorMode: 'HEX',
    isPendingChanges: false,
    companyLogo: '',
    data: {
      CompanyLayout: {
        mainColor: "#FFFFFF",
        mainContrastColor: "#333333",
        actionColor: "#00c1a7",
        actionContrastColor: "#FFFFFF",
        mediumColor: "#eec643",
        mediumContrastColor: "#333333",
        highColor: "#00c1a7",
        highContrastColor: "#FFFFFF",
        lowColor: "#dc004b",
        lowContrastColor: "#FFFFFF",
        brightColor: "#FFFFFF",
        darkColor: "#333333",
      }
    },
  }

  constructor(props) {
    super(props);

    this.handleDataproviderCompanyLayoutCallback = this.handleDataproviderCompanyLayoutCallback.bind(this);
    this.handleDataProviderCompanyLogoChanged = this.handleDataProviderCompanyLogoChanged.bind(this);
    this.dataprovider = new DataProvider();
    this.dataprovider.addCompanyLayoutChangedCallback(this.handleDataproviderCompanyLayoutCallback);
    this.dataprovider.addCompanyLogoChangedCallback(this.handleDataProviderCompanyLogoChanged);

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleRemoveCompanyLogo = this.handleRemoveCompanyLogo.bind(this);

    this.companyLayoutLoadedHandler = this.companyLayoutLoadedHandler.bind(this);
    this.companyLogoLoadedHandler = this.companyLogoLoadedHandler.bind(this);
    this.companyDataSavedHandler = this.companyDataSavedHandler.bind(this);
    this.companyLogoSavedHandler = this.companyLogoSavedHandler.bind(this);
    this.companyDataErrorHandler = this.companyDataErrorHandler.bind(this);

    this.colorModeHEX = this.colorModeHEX.bind(this);
    this.colorModeRGB = this.colorModeRGB.bind(this);

    this.handleTheCancelConfirmation = this.handleTheCancelConfirmation.bind(this);
    this.onFileInputClicked = this.onFileInputClicked.bind(this);

    this.handleResetLayoutClicked = this.handleResetLayoutClicked.bind(this);
    this.resetCompanyLayoutHandler = this.resetCompanyLayoutHandler.bind(this);

    this.companyLogoCroppedHandler = this.companyLogoCroppedHandler.bind(this);

    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.companyLayoutLoadedNullHandler = this.companyLayoutLoadedNullHandler.bind(this);
  }

  updateCompanyLogoFromDataProvider() {
    const companyLogoBase64 = "data:image/png;base64," + this.dataprovider.getCompanyLogoData().companyLogo;
    this.setState({
      companyLogo: companyLogoBase64
    })
  }

  updateCompanyLayoutFromDataProvider() {
    const companyLayout = this.dataprovider.getData();
    this.setState({
      data: companyLayout
    });
  }

  handleDataproviderCompanyLayoutCallback() {
    this.updateCompanyLayoutFromDataProvider();
  }

  handleDataProviderCompanyLogoChanged() {
    this.updateCompanyLogoFromDataProvider();
  }

  handleResetLayoutClicked() {
    this.refs.waitDialog.open();
    this.props.dispatch(resetCompanyLayout(this.resetCompanyLayoutHandler, this.companyDataErrorHandler));
  }

  resetCompanyLayoutHandler(json) {
    this.dataprovider.updateFromJsonString(json);
    this.dataprovider.updateCompanyLogo("");
    this.setState({
      companyLogo: '',
      companyDataSavePending: false,
      logoSavePending: false,
      isPendingChanges: false
    });
    this.refs.waitDialog.close();
  }
  componentDidMount() {
    super.componentDidMount();
    this.loadCompanyLayout();
    this.loadCompanyLogo();
  /*    var cl = this.dataprovider.getCompanyLogoData().companyLogo;
      this.setState({
        companyLogo: cl
      }); */
  }

  handleReloadData() {
    this.loadCompanyLogo();
    this.loadCompanyLayout();
  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    this.props.dispatch(getCompanyLayout(this.companyLayoutLoadedNullHandler, this.companyDataErrorHandler));

  }
  companyLayoutLoadedNullHandler(json) {

    this.refs.waitDialog.close();
  }


  loadCompanyLogo() {
    this.refs.waitDialog.open();
    this.props.dispatch(getCompanyLogo(this.companyLogoLoadedHandler, this.companyDataErrorHandler));
  }

  loadCompanyLayout() {
    this.refs.waitDialog.open();
    this.props.dispatch(getCompanyLayout(this.companyLayoutLoadedHandler, this.companyDataErrorHandler));
  }


  companyLogoLoadedHandler(json) {
    this.dataprovider.updateCompanyLogoFromJsonString(json);
    this.refs.waitDialog.close();
  }
  companyLayoutLoadedHandler(json) {

    this.dataprovider.updateFromJsonString(json);
    this.refs.waitDialog.close();
  }

  checkCanWaitDialogOnSaveClose() {
    if (!this.state.companyDataSavePending && !this.state.logoSavePending)
    {
      this.refs.waitDialog.close();
    }
    ;
  }

  companyDataSavedHandler(json) {
    this.setState({
      companyDataSavePending: false
    });
    this.setPendingChanges(false);

    var currentCompanyLogoData = this.state.companyLogo.substr(this.state.companyLogo.indexOf(',') + 1)
    this.dataprovider.updateCompanyLogo(currentCompanyLogoData); //remove dataheader prefix!
    let cl = this.dataprovider.getCompanyLogoData().companyLogo;

    const companyLogoData = IsNullOrEmpty(cl) ? "" : cl;

    this.props.dispatch(setCompanyLogo(companyLogoData, this.companyLogoSavedHandler, this.companyDataErrorHandler));
  }

  companyLogoSavedHandler(json) {
    this.setState({
      logoSavePending: false
    });
    this.checkCanWaitDialogOnSaveClose();
  }

  companyLogoCroppedHandler(croppedImage) {
    this.setState({
      companyLogo: croppedImage
    });
  }

  companyDataErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);


  }

  onFileInputClicked(evt) {
    //falls es bereits eine Datei hochgeladen (analog: Dialog abgebrochen) wurde
    //und der Nutzer wählt für den Upload dieselbe Datei erneut
    //aus, dann wird der onChange-Event nicht ausgelöst
    //-> daher lösen wir den FileInput manuell aus
    evt.target.value = null;
  }

  onFileUpload(evt) {
    //http://stackoverflow.com/questions/36580196/reactjs-base64-file-upload
    this.refs.waitDialog.open();
    // var self = this;
    let reader = new FileReader();
    let file = evt.target.files[0];
    let cropImageDialog = this.refs.cropImageDialog;
    let companyLogoCroppedHandler = this.companyLogoCroppedHandler;

    if (file)
    {
      this.setPendingChanges(true);
      reader.onload = function(upload) {
        //self.setState({
        //  companyLogo: upload.target.result
        //});

        cropImageDialog.open({
          buttonCropHandler: companyLogoCroppedHandler,
          src: upload.target.result
        });
      };

      reader.readAsDataURL(file);
      setTimeout(function() {
        console.log("Uploaded: ", file.name);
      }, 1000);
    }
    this.refs.waitDialog.close();
  }

  handleSaveClicked() {
    this.refs.waitDialog.open();
    this.setState({
      companyDataSavePending: false
    });
    this.setState({
      logoSavePending: false
    });
    this.dataprovider.update(this.state.data);
    const companyData = this.dataprovider.getData();

    this.props.dispatch(setCompanyLayout(JSON.stringify(companyData), this.companyDataSavedHandler, this.companyDataErrorHandler));
  }

  handleTheCancelConfirmation(e) {
    //reset current data
    this.updateCompanyLayoutFromDataProvider();
    this.updateCompanyLogoFromDataProvider();
    this.setPendingChanges(false);
  }

  handleCancelClicked() {
    const {t} = this.props;
    let yesNoDialog = {
      text: t("components-ModalDialog-looseDataQuestion"),
      button1Text: t("components-ModalDialog-no"),
      button1Handler: null,
      button2Text: t("components-ModalDialog-yes"),
      button2Handler: this.handleTheCancelConfirmation,
      title: t("components-ModalDialog-Warning"),
    };
    this.refs.modalDialog.open(yesNoDialog);
  }

  renderHiddenDialog() {
    const {t} = this.props;
    //Idee: das hier könnte man eigentlich gleich ins BaseModul übernehmen...
    return (
      <div>
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        <CropImageDialog ref="cropImageDialog" t={ t } />
        <ModalDialog ref="commonErrorDialog" />
      </div>
      );
  }
  renderHeaderView() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
               <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
                 { t("modules-appdesign-appdesign-header") }
               </PageHeader>
             </div>
             <div style={ this.getBaseStyle().right }>
               <FlatButton style={ { margin: 'auto', color: '#FFFFFF' } } label={ t('modules-appdesign-appdesign-btnResetLayout') } secondary={ true } icon={ <IconReset /> } labelPosition="before" onClick={ this.handleResetLayoutClicked }
               />
             </div>
           </div>

  }

  renderCommonButton() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().right }>
               <FlatButton label={ t('modules-appdesign-appdesign-btncancel') } primary={ false } style={ this.getBaseStyle().cancelButton } onClick={ this.handleCancelClicked } disabled={ !this.state.isPendingChanges } />
               <RaisedButton label={ t('modules-appdesign-appdesign-btnsave') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleSaveClicked } disabled={ !this.state.isPendingChanges } />
             </div>
           </div>;
  }

  colorModeHEX(event) {
    this.setState({
      colorMode: 'HEX'
    });
  }
  colorModeRGB(event) {
    this.setState({
      colorMode: 'RGB'
    });
  }

  isCompanyLogoValid() {
    let cl = String(this.state.companyLogo);
    if (cl === "" || cl === "data:image/png;base64,null" || cl === 'data:image/png;base64,')
    {
      return false;
    }
    return true;

  }

  renderCompanyLogo() {
    let cl = String(this.state.companyLogo);

    if (!(this.isCompanyLogoValid()))
    {
      return <div></div>;
    }

    return <img alt="" style={ styles.companyLogo } src={ cl } />;

  }

  handleRemoveCompanyLogo() {
    this.setState({
      companyLogo: ''
    });
    this.setPendingChanges(true);
  }

  setPendingChanges(pending) {
    this.setState({
      isPendingChanges: pending
    });
    if (pending)
    {
      this.setDirty();
    }
    else
    {
      this.clearDirty();
    }
  }

  handleChangedCompanyLayoutData(newColorHex, name) {
    this.setPendingChanges(true);
    let oldData = JSON.parse(JSON.stringify(this.state.data.CompanyLayout));
    //der server erwartet die Werte als String

    oldData[name] = newColorHex;
    this.setState({
      data: {
        CompanyLayout: oldData,
      },
    }, function() {
      //Postprocessing (Hack!)
      //auffüllen des HexStrings mit nullen, falls er unvollständig ist...
      this.state.data.CompanyLayout[name] = '#' + expandShorthandHex(newColorHex).replace("#", ''); //manchmal kommt das führende # mit und manchmal nicht...
    });
  }


  renderMainView() {
    const {t} = this.props;

    const currentColorMode = this.state.colorMode;
    const isRgbMode = currentColorMode === 'RGB';
    return (
      <div>
        <div style={ styles.subPageHeader }>
          { t('modules-appdesign-mainView-subheading') } </div>
        <div style={ styles.description }>
          { t('modules-appdesign-mainView-description') } </div>
        <Table selectable={ false } style={ styles.tableStyle }>
          <TableBody displayRowCheckbox={ false }>
            <TableRow>
              <TableRowColumn style={ styles.labelColumn }>
                <span>Logo</span>
                <div style={ { marginTop: '5px' } }>
                  <span style={ styles.pureDescription }>{ t('modules-appdesign-image-description') }<br /> { t('modules-appdesign-image-description2') } </span>
                </div>
              </TableRowColumn>
              <TableRowColumn>
                <RaisedButton label={ t('modules-appdesign-mainView-uploadImage') } labelPosition="before" secondary={ true } style={ styles.uploadButton } containerElement="label">
                  <input hidden ref="fileInput" type="file" accept="image/png" style={ styles.uploadInput } onChange={ this.onFileUpload } onClick={ this.onFileInputClicked } />
                </RaisedButton>
              </TableRowColumn>
              <TableRowColumn style={ { width: "160px", textAlign: 'center' } }>
                { this.renderCompanyLogo() }
              </TableRowColumn>
              <TableRowColumn>
                <FlatButton label={ t('modules-appdesign-mainView-removeCompanyLogo') } disabled={ !(this.isCompanyLogoValid()) } onClick={ this.handleRemoveCompanyLogo } />
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
        <div style={ styles.colorpickerDiv }>
          <Table selectable={ false } style={ {...styles.tableStyle, ...{marginTop: "5px"}} }>
            <TableHeader style={{border:"none"}} displaySelectAll={ false } enableSelectAll={ false } adjustForCheckbox={ false }>
              <TableRow style={{border:"none"}}>
                <TableHeaderColumn style={ styles.labelColumn }>
                  <span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <span onClick={ this.colorModeHEX } style={ isRgbMode ? styles.colorModeNotSelected : styles.colorModeSelected }>HEX</span>
                  <span style={ { fontSize: '14px', color: '#7B98AB', marginLeft: 3, marginRight: 3 } }>|</span>
                  <span onClick={ this.colorModeRGB } style={ isRgbMode ? styles.colorModeSelected : styles.colorModeNotSelected }>RGB</span>
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn style={ styles.colorColumn }>
                  <span style={ styles.colorHeading }>{ t('modules-appdesign-colorpicker-color') }</span>
                </TableHeaderColumn>
                <TableHeaderColumn style={ styles.constrastColumn }>
                  <span style={ styles.colorHeading }>{ t('modules-appdesign-colorpicker-contrast') }</span>
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={ false }>
              <ColorPicker label={ t('modules-appdesign-colorpicker-maincolor') } color={ this.state.data.CompanyLayout.mainColor } contrastColor={ this.state.data.CompanyLayout.mainContrastColor } mode={ this.state.colorMode } contrastSelectable={ true }
                onColorChanged={ (newColor) => this.handleChangedCompanyLayoutData(newColor, "mainColor") } onContrastChanged={ (newContrast) => this.handleChangedCompanyLayoutData(newContrast, "mainContrastColor") } t={ t } />
              <ColorPicker label={ t('modules-appdesign-colorpicker-actionelements') } color={ this.state.data.CompanyLayout.actionColor } contrastColor={ this.state.data.CompanyLayout.actionContrastColor } mode={ this.state.colorMode } contrastSelectable={ true }
                onColorChanged={ (newColor) => this.handleChangedCompanyLayoutData(newColor, "actionColor") } onContrastChanged={ (newContrast) => this.handleChangedCompanyLayoutData(newContrast, "actionContrastColor") } t={ t } />
              <TableRow style={ { borderBottom: 'none' } } />
              <ColorPicker label={ t('modules-appdesign-colorpicker-normaltrust') } color={ this.state.data.CompanyLayout.mediumColor } contrastColor={ this.state.data.CompanyLayout.mediumContrastColor } mode={ this.state.colorMode } contrastSelectable={ true }
                onColorChanged={ (newColor) => this.handleChangedCompanyLayoutData(newColor, "mediumColor") } onContrastChanged={ (newContrast) => this.handleChangedCompanyLayoutData(newContrast, "mediumContrastColor") } t={ t } />
              <ColorPicker label={ t('modules-appdesign-colorpicker-heavytrust') } color={ this.state.data.CompanyLayout.highColor } contrastColor={ this.state.data.CompanyLayout.highContrastColor } mode={ this.state.colorMode } contrastSelectable={ true }
                onColorChanged={ (newColor) => this.handleChangedCompanyLayoutData(newColor, "highColor") } onContrastChanged={ (newContrast) => this.handleChangedCompanyLayoutData(newContrast, "highContrastColor") } t={ t } />
              <ColorPicker label={ t('modules-appdesign-colorpicker-unsafetrust') } color={ this.state.data.CompanyLayout.lowColor } contrastColor={ this.state.data.CompanyLayout.lowContrastColor } mode={ this.state.colorMode } contrastSelectable={ true }
                onColorChanged={ (newColor) => this.handleChangedCompanyLayoutData(newColor, "lowColor") } onContrastChanged={ (newContrast) => this.handleChangedCompanyLayoutData(newContrast, "lowContrastColor") } t={ t } />
              <TableRow style={ { borderBottom: 'none' } } />
            </TableBody>
          </Table>
          <div style={ styles.previewDiv }>
            <Preview mainColor={ this.state.data.CompanyLayout.mainColor } mainContrastColor={ this.state.data.CompanyLayout.mainContrastColor } actionColor={ this.state.data.CompanyLayout.actionColor } actionContrastColor={ this.state.data.CompanyLayout.actionContrastColor } highColor={ this.state.data.CompanyLayout.highColor }
              highContrastColor={ this.state.data.CompanyLayout.highContrastColor } mediumColor={ this.state.data.CompanyLayout.mediumColor } mediumContrastColor={ this.state.data.CompanyLayout.mediumContrastColor } lowColor={ this.state.data.CompanyLayout.lowColor } lowContrastColor={ this.state.data.CompanyLayout.lowContrastColor }
              brightColor={ this.state.data.CompanyLayout.brightColor } darkColor={ this.state.data.CompanyLayout.darkColor } companyLogo={ this.state.companyLogo } />
          </div>
        </div>
      </div>
      );
  }
}

function mapStateToProps(state) {
  const { companyData } = state;
  return {
    CompanyData: companyData,
  }
}

const AppDesignTranslated = translate(['common'])(AppDesign);
export default connect(mapStateToProps)(AppDesignTranslated);

/***************** HELPER ********************************************/
/*
function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    console.log(file.name);
  };
  reader.onerror = function(error) {
    console.log('Error: ', error);
  };
}
*/

/*export default React.createClass({
  render() {
    return (
      <div style={ styles.root }>
        <PageHeader>AppDesign</PageHeader>
      </div>
    )
  }
}) */
