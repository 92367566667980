import 'babel-polyfill';
import React, { Component, PropTypes } from 'react';
import { translate } from 'react-i18next';

import TextField from './components/textfields/TextField';
import RaisedButton from './components/buttons/RaisedButton';
import FlatButton from './components/buttons/FlatButton';
import Logo from './ressources/Login_Logo.svg';
import { ModalDialog } from './components/ModalDialog';
import Cookie from 'js-cookie';
import PasswordChangeForm from './components/forms/PasswordChangeForm.js';
import Checkbox from 'material-ui/Checkbox';

const styles = {
  root: {
    width: '100%'
  },
  parent: {
    width: '405px',
    display: 'table',
    margin: '0 auto',
    marginTop: '20px'
  },
  image: {
    flex: '1 1 auto',
    padding: '10px',
    textAlign: 'center'
  },
  logo: {
    height: '108px',
    marginTop: '54px'
  },
  buttonRow: {
    marginTop: '16px',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  buttonRowLeft: {
    textAlign: 'left',
    flex: '1'
  },
  buttonRowRight: {
    textAlign: 'right',
    flex: '1'
  },
  button: {

  },
  buttonLabel: {
    fontSize: '14px'
  },
  label: {
    fontSize: '20px'
  },
  placeholder: {
    height: '20px'
  },
  textfield: {
    fontSize: '16px',
    marginTop: '8px'
  },

  iconTextComb: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  iconLeft: {
    marginRight: '20px'
  },
  iconText: {
    fontSize: '14px',
    color: '#FFFFFF'
  },
  flatButtonLink: {
    margin: 0,
    padding: 0
  },
  checkbox: {
    marginTop: '8px'
  },
  checkboxLabel: {
    fontSize: '14px'
  }

}


class Login extends Component {

  constructor(props) {
    super(props);

    // noinspection JSCheckFunctionSignatures
    let cookieChecked = !!( Cookie.get('mc-login-email') );

    this.state = {
      data: {
        email: '',
        pwd: '',
        twoFaToken: '',
        changed: false,
        cookieChecked: cookieChecked
      },
      dataSetPwd: {
        recoveryPwd: '',
        newPwd: '',
        repeatPwd: ''
      },
      errors: {
        recoveryPwd: '',
        newPwd: '',
        repeatPwd: '',
        email: ''
      },
      pwdHasMinLength: false,
      pwdHasUpperCase: false,
      pwdHasSpezChar: false
    };

    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.onLoginError = this.onLoginError.bind(this);
  }


  onLoginError() {
  //const {t} = this.props;
  //wird nicht mehr benutzt!
  //Der Callback wird nun in der Parent-Komponente App.js behandelt
  //da nicht sichergestellt ist, dass der Callback hier zuverlässig aufgerufen wird!
  /*  if (this.props.loginError && this.props.loginError.ident && !this.props.isFetching)
    {
      let errorCode = "SERVICE-" + this.props.loginError.ident;
      let errorText = t(errorCode);
      this.refs.modalDialog.open({
        text: errorText,
        button1Text: t("components-ModalDialog-ok")
      })
    } */
  }


  /**
   *
   * @param {string} key
   * @param {string} newValue
   * @returns {void}
   */
  handleChangeData( key, newValue) {
    let /** @type {Object} */
      oldData;

    switch ( key ) {
      case 'pwd':
      case 'email':
      case 'twoFaToken':
        this.setState({
            data: {
              ...this.state.data,
              [key]: newValue,
              changed: true
            }
          },
          this.toggleEmailCookie
        );
        break;

      // @TODO: Alle drei Cases folgen dem glechen Muster --> vereinheitlichen?
      case 'newPwd':
        oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
        oldData.newPwd = newValue;

        this.setState({
          dataSetPwd: oldData
        });
        break;

      case 'recoveryPwd':
        oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
        oldData.recoveryPwd = newValue;

        this.setState({
          dataSetPwd: oldData
        });
        break;

      case 'repeatPwd':
        oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
        oldData.repeatPwd = newValue;

        this.setState({
          dataSetPwd: oldData
        });
        break;

      default:
        break;
    }
  }



  /**
   *
   * @param event
   * @param {boolean} isInputChecked
   */
  onCookieChecked(event, isInputChecked) {
    if (isInputChecked === false) {
      Cookie.remove('mc-login-email');
    }

    this.setState({
      data: {
        ...this.state.data,
        cookieChecked: isInputChecked
      }
    });
  }


  /**
   *
   * @returns {void}
   */
  toggleEmailCookie() {
    if (this.state.data.cookieChecked === true) {
      Cookie.set('mc-login-email', this.state.data.email, {
        expires: 365
      });
    } else {
      Cookie.remove('mc-login-email');
    }
  }


  /**
   *
   * @returns {void}
   */
  handleLoginClick() {
    const {onLoginClick} = this.props;

    this.setState({
      adminInfo: undefined
    })

    // noinspection JSDeprecatedSymbols
    if (!this.refs.modalDialog.isOpen()) {
      //Callbacks werden an dieser Stelle nicht ordentlich ausgelöst...
      //daher auskommentiert und im Parent (App.js) verarbeitet
      onLoginClick(this.state.data.email, this.state.data.pwd, this.state.data.twoFaToken);
    }
  }



  handleSetPasswordClick() {
    const {onResetPwdClick} = this.props;

    // noinspection JSDeprecatedSymbols
    let newPasswordValidationSuccess = this.refs.passwordChangeForm && this.refs.passwordChangeForm.validatePassword();

    let dataSetPwd = JSON.parse(JSON.stringify(this.state.dataSetPwd));
    let errors = JSON.parse(JSON.stringify(this.state.errors));

    let recoveryPwd = dataSetPwd['recoveryPwd'];
    if (!recoveryPwd || recoveryPwd.length < 1)
    {
      errors['recoveryPwd'] = 'login-forgot-pwd-err-empty';
    }
    else
    {
      errors['recoveryPwd'] = '';
    }

    let email = this.getEMailTextFieldValue();
    if (!email || email.length < 1)
    {
      errors['email'] = 'login-forgot-pwd-err-empty';
    }
    else
    {
      errors['email'] = '';
    }

    this.setState({
      errors: errors
    });

    if (!errors['recoveryPwd'] && newPasswordValidationSuccess && !errors['email'])
    {
      onResetPwdClick(email, recoveryPwd, this.state.dataSetPwd.newPwd);
    }

  }

  getPwdTextField(hintText, errorTextKey, changeDataKey) {
    const {t} = this.props;
    return (
      <TextField type='password' style={ styles.textfield } hintText={ hintText } errorText={ errorTextKey ? t(errorTextKey) : '' } fullWidth={ true } onChange={ (event, newValue) => this.handleChangeData(changeDataKey, newValue) }
      />
      );
  }

  getForgotPwdButton() {
    const {t} = this.props;
    let isBtnDisabled = true;

    // noinspection JSDeprecatedSymbols
    if (this.refs.passwordChangeForm && this.refs.passwordChangeForm.hasPasswordPassedAllGuidelines())
    {
      isBtnDisabled = false;
    }

    return (
      <RaisedButton label={ t('login-forgot-pwd-button') } labelStyle={ styles.buttonLabel } primary={ true } fullWidth={ false } style={ styles.button } onClick={ () => this.handleSetPasswordClick() }
        disabled={ isBtnDisabled } />
      );
  }


  /**
   *
   * @param event
   * @returns {void}
   */
  handleInputChange = (event) => {
    if (event.keyCode === 13) {
      this.handleLoginClick();
    }
  }

  getEMailTextFieldValue() {
    // noinspection JSCheckFunctionSignatures
    const emailCookie = Cookie.get('mc-login-email');
    const changed = (this.state.data && this.state.data.changed) ? this.state.data.changed : false;

    if (!changed && emailCookie)
    {
      // eslint-disable-next-line
      this.state.data.email = emailCookie;
      return emailCookie;
    }

    const input = (this.state.data && this.state.data.email) ? this.state.data.email : "";

    if (input !== "")
    {
      return input;
    }

    return "";
  }

  renderCookieEmailCheckbox() {
    const {t} = this.props;

    let isChecked;

    if (this.state.data && this.state.data.cookieChecked !== 'undefined') {
      isChecked = this.state.data.cookieChecked;
    } else {
      // noinspection JSCheckFunctionSignatures
      isChecked = !!Cookie.get('mc-login-email');
    }

    return (
      <Checkbox style={ styles.checkbox } labelStyle={ styles.checkboxLabel } label={ t('login-remember-email') } checked={ isChecked } onCheck={ (event, isInputChecked) => this.onCookieChecked(event, isInputChecked) } />
      );
  }


  /**
   *
   * @param {Object.<string, Function>} handlers
   * @param {Function} t
   * @returns {JSX.Element}
   */
  renderNormalLoginView( handlers, t ) {
    return (
      <div style={styles.parent}>
        <div style={styles.image}>
          <img alt="" src={Logo} style={styles.logo}/>
        </div>
        <div>
          <TextField value={this.getEMailTextFieldValue()}
                     hintText={t('login-email-textfield-hint')}
                     style={styles.textfield}
                     fullWidth={true}
                     onChange={(event, newValue) => handlers.handleChangeData('email', newValue)}
                     onKeyUp={handlers.handleInputChange}
          />
        </div>
        {this.renderCookieEmailCheckbox()}
        <div>
          <TextField type='password'
                     hintText={t('login-pwd-textfield-hint')}
                     style={styles.textfield}
                     fullWidth={true}
                     onChange={(event, newValue) => handlers.handleChangeData('pwd', newValue)}
                     onKeyUp={handlers.handleInputChange}
          />
        </div>
        <div>
          <TextField value={this.state.data.twoFaToken}
                     hintText={t('login-2fa-token-textfield-hint')}
                     style={styles.textfield}
                     fullWidth={true}
                     onChange={(event, newValue) => handlers.handleChangeData('twoFaToken', newValue)}
                     onKeyUp={handlers.handleInputChange}
          />
        </div>
        <div style={styles.buttonRow}>
          <div style={styles.buttonRowLeft}>
            <FlatButton label={t('login-forgotPwd-button')}
                        onClick={() => handlers.onForgotPwdClick()}
                        style={styles.flatButtonLink}
                        labelStyle={styles.flatButtonLink}/>
          </div>
          <div style={styles.buttonRowRight}>
            <RaisedButton label={t('login-login-button')}
                          labelStyle={styles.buttonLabel}
                          primary={true}
                          fullWidth={false} style={styles.button}
                          onClick={handlers.handleLoginClick}/>
          </div>
        </div>
      </div>
    );
  }


  /**
   *
   * @param {Object.<string, Function>} handlers
   * @param {Function} t
   * @returns {JSX.Element}
   */
  renderForgotPasswordLoginView( handlers, t ) {
    return (
      <div style={styles.parent}>
        <TextField value={this.getEMailTextFieldValue()} style={styles.textfield}
                   hintText={t('login-email-textfield-hint')}
                   errorText={this.state.errors.email ? t(this.state.errors.email) : ''} fullWidth={true}
                   onChange={(event, newValue) => handlers.handleChangeData('email', newValue)}
        />
        <div style={styles.placeholder}></div>
        <span className='medium' style={styles.label}>{t('login-forgot-pwd-desc')}</span>
        {this.getPwdTextField(t('login-pwd-textfield-hint'), this.state.errors.recoveryPwd, 'recoveryPwd')}
        <div style={styles.placeholder}></div>
        <span className='medium' style={styles.label}>{t('login-forgot-pwd-new-pwd-desc')}</span>
        <div style={styles.placeholder}></div>
        <PasswordChangeForm ref="passwordChangeForm"
                            onChange={(name, newValue) => handlers.handleChangeData(name, newValue)} t={t}/>
        <div style={styles.buttonRow}>
          <div style={styles.buttonRowLeft}>
            <FlatButton label={t('cancel')} onClick={() => handlers.onResetPwdCancelClick()}/>
          </div>
          <div style={styles.buttonRowRight}>
            {this.getForgotPwdButton()}
          </div>
        </div>
      </div>
    );
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const {t, isForgotPassword, onResetPwdCancelClick, onForgotPwdClick} = this.props;

    const handlers = {
      handleInputChange: this.handleInputChange,
      handleChangeData: this.handleChangeData,
      handleLoginClick: this.handleLoginClick,
      onResetPwdCancelClick: onResetPwdCancelClick,
      onForgotPwdClick: onForgotPwdClick
    }

    return (
      <div style={styles.root}>
        <ModalDialog ref="modalDialog"/>
        {isForgotPassword ?
          this.renderForgotPasswordLoginView( handlers, t ) :
          this.renderNormalLoginView( handlers, t )
        }
      </div>
      );
  }
}

Login.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onResetPwdClick: PropTypes.func.isRequired,
  onResetPwdCancelClick: PropTypes.func.isRequired,
  isForgotPassword: PropTypes.bool,
  onForgotPwdClick: PropTypes.func,
  loginError: PropTypes.object,
  isFetching: PropTypes.bool
}

export default translate(['common'])(Login);
