import React from 'react';
import Dialog from 'material-ui/Dialog';
import { WaitDialog } from '../../components/WaitDialog';
import ErrorDialog from '../../components/ErrorDialog';
import TextField from '../../components/textfields/TextField';
import RaisedButton from '../../components/buttons/RaisedButton';


/**
 *
 * @type {{secret: string, open: boolean}}
 */
const initState = {
  open: false,
  recoveryCodes: ''
};


class ApiTwoFaShowRecoveryCodesDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }


  /**
   *
   * @returns {void}
   */
  open( param ) {
    const that = this,
      { recoveryCodes } = param;

    that.setState({
      open: true,
      recoveryCodes: recoveryCodes
    });

  }


  /**
   *
   * @returns {void}
   */
  close() {
    this.setState(initState);
  }


  /**
   *
   * @param {string} recoveryCodes
   * @returns {string}
   */
  recoveryCodeToTextFieldValue( recoveryCodes ) {
    let recoveryCodesStr = '';

    if ( recoveryCodes.indexOf(',') !== -1 ) {
      recoveryCodesStr = recoveryCodes.split(',').join('\n');
    }

    return recoveryCodesStr;
  }




  /**
   *
   * @returns {void}
   */
  handleSubmitButton() {
    this.close();
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const that = this,
      {t} = this.props,
      /** @type {string} */
      recoveryCodeStr = that.recoveryCodeToTextFieldValue( this.state.recoveryCodes );

    return (
      <div>
        <div>
          <WaitDialog ref="waitDialog"/>
          <ErrorDialog ref="errorDialog" t={t}/>
        </div>

        <Dialog title={t('settings-two-fa-show-recovery-codes-dialog-title')}
                contentStyle={{width: "80%", maxWidth: '960px'}}
                modal={true}
                open={this.state.open}>
          <div className="dialog__content"
               style={{width: "50%"}} >
            <div>{t('settings-two-fa-show-recovery-codes-dialog-get-the-codes')}</div>

            <TextField
              type="text"
              className="dialog__recovery-codes"
              id="recovery-codes"
              value={recoveryCodeStr}
              multiLine // "multiLine" has to be "camelCase" (in React 15?) to render the line breaks "\n" !!!
              rows={5} // Has to be type {number} !
              fullWidth
              hintText={t('settings-two-fa-show-recovery-codes-dialog-text-recovery-codes')}
              floatingLabelText={t('settings-two-fa-show-recovery-codes-dialog-text-recovery-codes')}/>
          </div>

          <div className="dialog__actions">
            <RaisedButton
              className="a-btn btn--primary"
              primary={true}
              label={t('okay')}
              onTouchTap={this.handleSubmitButton.bind(this)}/>
          </div>
        </Dialog>

      </div>
      );
    }
  }


  export default ApiTwoFaShowRecoveryCodesDialog;
