import 'babel-polyfill';

import React, { Component, PropTypes } from 'react';
import './App.css';
import DataCache from './cache/DataCache'

import injectTapEventPlugin from 'react-tap-event-plugin';
//import Snackbar from 'material-ui/Snackbar';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import NavigationLeft from './components/NavigationLeft';
import Header from './Header';
import Login from './Login';
import Footer from './Footer';
import { WaitDialog } from './components/WaitDialog'
import { ModalDialog } from './components/ModalDialog';

import { connect } from 'react-redux';
import { login, logout, resetPassword, getAdminInfo, SERVER_URL } from './actions/index';
import { translate } from 'react-i18next';
import Welcome from './Welcome.js';

import { getAdminMuiTheme } from './components/styles';
import idleTimer from 'idle-timer';
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin();

/**
*
*/
class App extends Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleSetPasswordClick = this.handleSetPasswordClick.bind(this);
    this.handleSetPasswordCancelClick = this.handleSetPasswordCancelClick.bind(this);
    this.onLoginError = this.onLoginError.bind(this);
    this.callbackFn = this.callbackFn.bind(this);
    this.activeCallbackFn = this.activeCallbackFn.bind(this);
    this.onResetPasswordSuccessHandler = this.onResetPasswordSuccessHandler.bind(this);
    this.state = {
      forgotPasswordClick: false,
      settingClick: false
    }
    this.getAdminInfoSuccessHandler = this.getAdminInfoSuccessHandler.bind(this)
    //TODO: idleTimer kann ggf. von uns angepasst werden
    //      -z.B. dass er nur auf load-Events reagiert usw.
    this.timer = idleTimer({
      // function to fire after idle
      callback: this.callbackFn,
      // function to fire when active
      activeCallback: this.activeCallbackFn,
      // Amount of time in milliseconds before becoming idle. default 60000
      //idleTime: 1740000, //29 Minuten
      idleTime: 900000,
    //idleTime: 15000,
    })
  }
  callbackFn() {
    //automatically logout
    this.handleLogoutClick();
  }
  activeCallbackFn() {
    //TODO - do something
    //console.log("You're active!");
  }

  componentDidMount() {
    //this.handleLoginClick('florian.plewka@pdv.de', 'Qwertz12');
  }

  componentWillReceiveProps(nextProps) {
    const { isLoggedIn } = nextProps;

    if (nextProps.loginError && nextProps.loginError.ident && !nextProps.isFetching)
    {
      this.refs.waitDialog.close();
    }
    if (typeof isLoggedIn !== "undefined" && isLoggedIn === false && typeof this.state.adminInfo !== "undefined")
    {
      this.setState({
        adminInfo: undefined
      })
    }

    if (typeof isLoggedIn !== "undefined" && isLoggedIn && typeof this.state.adminInfo === "undefined")
    {
      this.props.dispatch(getAdminInfo(true, this.getAdminInfoSuccessHandler, this.onLoginError))
    }
  }

  getAdminInfoSuccessHandler(json) {

    this.refs.waitDialog.close();
    this.setState({
      adminInfo: json
    })
  }

  /**
   *
   * @description An dieser Stelle ist es unklar, ob callback und errorCallback überhaupt Sinn machen.
   * @TODO Lt Kommentar in Login.js werden die Callbacks von dort, nicht weiter nach hier "oben" gereicht.
   * Zumindest der errorCallback ist ja auch hier fest verdrahtet.
   * Habe noch keinen Überblick über den Flow, wo die Callbacks am sinnvollsten platziert sind.
   *
   * @param {string} email
   * @param {string} pwd
   * @param {string|undefined} twoFaToken
   */
  handleLoginClick(email, pwd, twoFaToken) {
    const that = this;

    new DataCache().resetCache();
    that.refs.waitDialog.open();

    //errorCallback wird hier behandelt und nicht an den Client weitergegeben,
    //da im Produktivsystem der callback nicht ausgelöst wird:
    //Grund: https://stackoverflow.com/questions/24103072/reactjs-onclick-handler-not-firing-when-placed-on-a-child-component
    that.props.dispatch( login(email, pwd, twoFaToken, that.onLoginSuccess.bind( that), that.onLoginError) );
  }


  /**
   *
   * @returns {void}
   */
  onLoginSuccess() {
    console.log('App.js#onLoginSuccess');
  }


  onLoginError() {
    const {t} = this.props;
    if (this.props.loginError && this.props.loginError.ident && !this.props.isFetching)
    {
      let errorCode = "SERVICE-" + this.props.loginError.ident;
      let errorText = t(errorCode);
      this.refs.modalDialog.open({
        text: errorText,
        button1Text: t("components-ModalDialog-ok")
      })
    }
  }

  handleLogoutClick() {
    this.setState({
      adminInfo: undefined
    })
    new DataCache().resetCache();
    this.props.dispatch(logout());
  }

  handleForgotPasswordClick() {
    this.setState({
      forgotPasswordClick: true
    })
  }

  onResetPasswordSuccessHandler(json) {
    //Bug 44343 - Loginscreen wird nicht geöffnet wenn vor Logout das Passwort per Recovery-Passwort zurückgesetzt wurde
    this.setState({
      forgotPasswordClick: false
    })
  }

  handleSetPasswordClick(email, recoveryPwd, newPwd) {
    this.props.dispatch(resetPassword(email, recoveryPwd, newPwd, this.onResetPasswordSuccessHandler, this.onLoginError))

  }

  handleSetPasswordCancelClick() {
    this.setState({
      forgotPasswordClick: false
    })
  }

  getBlankImage() {
    return SERVER_URL + "?cmd=getBlankImage";
  }

  getChildElement() {
    const children = this.props.children;

    return (
      <div className="content">
        { children ? children : <Welcome /> }
      </div>
    );
  }


  /**
   *
   * @param {Object} loginError
   * @param {boolean} isFetching
   * @param {Object.<string, Function>} handlers
   * @returns {JSX.Element}
   */
  renderLoggedOutView( loginError, isFetching, handlers ) {
    return(
    <div className="wrapperContent" data-comment="App.js#renderLoggedOutView">
      <header>
        <Header isLoggedIn={false}/>
      </header>
      <main>
        <Login
          isForgotPassword={this.state.forgotPasswordClick}
          onLoginClick={handlers.handleLoginClick}
          onResetPwdClick={handlers.handleSetPasswordClick}
          onResetPwdCancelClick={handlers.handleSetPasswordCancelClick}
          onForgotPwdClick={handlers.handleForgotPasswordClick}
          loginError={loginError}
          isFetching={isFetching}
        />
      </main>
      <footer>
        <Footer/>
      </footer>
    </div>
    );
  }


  /**
   *
   * @param {Object.<string, Function>} handlers
   * @returns {JSX.Element}
   */
  renderLoggedInView( handlers ) {
    return (
      <div className="wrapperContent" data-comment="App.js#renderLoggedInView">
        <header>
          <Header
            isLoggedIn={true}
            onLogoutClick={handlers.handleLogoutClick}
            adminInfo={this.state.adminInfo}
          />
        </header>
        <main>
          <div className="sidebar">
            <NavigationLeft adminInfo={this.state.adminInfo} />
          </div>
          {this.getChildElement()}
        </main>
      </div>
    );
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const {isLoggedIn, loginError, isFetching } = this.props;

    const handlers = {
      handleLoginClick: this.handleLoginClick,
      handleSetPasswordClick: this.handleSetPasswordClick,
      handleSetPasswordCancelClick: this.handleSetPasswordCancelClick,
      handleForgotPasswordClick: this.handleForgotPasswordClick,
      handleLogoutClick: this.handleLogoutClick,
    };

    return (
      <MuiThemeProvider muiTheme={ getAdminMuiTheme() }>
        <div className="wrapper" data-comment="App.js#render">
          <WaitDialog ref="waitDialog" />
          <ModalDialog ref="modalDialog" />
          { isLoggedIn ?
            this.renderLoggedInView( handlers ) :
            this.renderLoggedOutView( loginError, isFetching, handlers )
          }
        </div>
      </MuiThemeProvider>
      );
  }
}


App.contextTypes = {
  router: React.PropTypes.object
};

//{ isFetching && <Snackbar open={ true } message="Nachricht wurde gesendet!" action="Anzeigen" autoHideDuration={ 3000 } /> }
App.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  loginError: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminInfo: PropTypes.object,
  session: PropTypes.object,
}

function mapStateToProps(state) {
  const {session, loginAction, adminInfo} = state;
  return {
    isFetching: loginAction.isFetching ? loginAction.isFetching : false,
    loginError: loginAction.loginError,
    isLoggedIn: session.isLoggedIn,
    adminInfo: adminInfo,
    session: session,
  }
}

const AppTranslated = translate(['common'])(App);
export default connect(mapStateToProps)(AppTranslated);
