/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getGroupChannelStatistics, getGroupChannelStatisticsDetails } from '../../actions/index';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell } from 'recharts';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';

import CircularProgress from 'material-ui/CircularProgress';

import Measure from 'react-measure';

import { SubPageHeader } from '../../components/pageheader';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

// import IconButton from 'material-ui/IconButton';
// import IconInfo from '../../ressources/icon_info';
import PlatzhalterGruppenDe from '../../ressources/platzhalter_gruppen';
import PlatzhalterGruppenEn from '../../ressources/platzhalter_gruppen_en';
import PlatzhalterGruppenEl from '../../ressources/platzhalter_gruppen_el';
import PlatzhalterGruppenEs from '../../ressources/platzhalter_gruppen_es';
import PlatzhalterGruppenFr from '../../ressources/platzhalter_gruppen_fr';
import PlatzhalterPieChart from '../../ressources/platzhalter_pie_chart';

import { colors, styles } from './DashboardStyle';

import BaseDataProvider from '../BaseDataProvider';
import FlatButton from 'material-ui/FlatButton';
import { getAdminMuiTheme } from '../../components/styles.js';


const SHOW_WEEK = 1;
const SHOW_MONTH = 2;

const adminMuiTheme = getAdminMuiTheme();

function ChannelChartTooltip(props) {
  const {t} = props;
  if (props.payload != null)
  {
    if (props.payload.length >= 1)
    {
      return <div style={ styles.dashboard_tooltip }>
               { t('modules-dashboard-ChannelChart-messagecount', {
                   v1: props.payload[0].value
                 }) }
               <br/>
             </div>;
    }
  }
  return <div style={ styles.dashboard_tooltip }></div>
}

export class ChannelChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      timeValue: SHOW_WEEK,
      selectedGroupGuid: null,
      showAll: false
    };
    this.handleOnPieEnter = this.handleOnPieEnter.bind(this);
    this.handleNewSelectedIndex = this.handleNewSelectedIndex.bind(this);
    this.handleOnTableRowSelection = this.handleOnTableRowSelection.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleShowAllClicked = this.handleShowAllClicked.bind(this);

    this.renderTableRow = this.renderTableRow.bind(this);
    this.dataprovider = new BaseDataProvider();
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props.dispatch(getGroupChannelStatistics())
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }
  handleShowAllClicked() {
    this.setState({
      showAll: true
    });

  }
  currentTableData() {

    if (this.props.tableData)
    {
      // Sortieren nach der aktuellen Auswahl
      let sortColumn = "messageCountMonth";
      if (this.state.timeValue === SHOW_WEEK)
      {
        sortColumn = "messageCountWeek";
      }
      var rc = this.dataprovider.sortData(this.props.tableData.dataAll, sortColumn, 2)

      if (rc.length > 10 && this.state.showAll === false)
      {
        rc = rc.slice(0, 10)
      }

      return rc;
    }
  }

  currentDetailsData() {

    let tableDataCurrent = this.currentTableData()

    if (this.props.detailsData && tableDataCurrent && this.state.selectedGroupGuid != null)
    {
      let detailsData = this.props.detailsData[this.state.selectedGroupGuid]
      if (detailsData)
      {
        if (this.state.timeValue === SHOW_WEEK)
        {
          return detailsData.dataWeek
        }
        return detailsData.dataMonth
      }
    }
  }

  handleOnPieEnter(data, index) {

    this.handleNewSelectedIndex(index);
  }

  getCurrentIndexSelected() {
    let tableData = this.currentTableData();
    if (this.state.selectedGroupGuid == null)
    {
      return -1;
    }

    let index = -1;
    if (tableData)
    {
      index = tableData.findIndex(d => d.guid === this.state.selectedGroupGuid.toString())
    }
    return index;
  }

  handleNewSelectedIndex(selectedIndex) {
    let tableDataCurrent = this.currentTableData();

    if (selectedIndex < 0 || selectedIndex >= tableDataCurrent.length)
    {
      this.setState({
        selectedGroupGuid: null
      });

    }
    else
    {
      if (this.props.detailsData && tableDataCurrent && this.props.detailsData[tableDataCurrent[selectedIndex].guid])
      {
        let groupGuidSelected = tableDataCurrent[selectedIndex].guid;
        this.setState({
          selectedGroupGuid: groupGuidSelected,
        })
      }
      else
      {
        let groupGuidSelected = this.currentTableData()[selectedIndex].guid;
        this.setState({
          selectedGroupGuid: groupGuidSelected,
        })
        this.props.dispatch(getGroupChannelStatisticsDetails(groupGuidSelected));
      }
    }
  }

  handleOnTableRowSelection(selectedRows) {
    if (Array.isArray(selectedRows) && selectedRows.length === 1)
    {
      let selectedIndex = selectedRows[0];
      this.handleNewSelectedIndex(selectedIndex);
    }
  }

  handleChangeTime(event, index, value) {
    this.setState({
      timeValue: value
    })
  }

  renderTable() {
    if (this.currentTableData())
    {
      return this.renderTableContent()
    }
    else
    {
      return this.renderTableProgress()
    }
  }

  renderTableProgress() {
    return <div style={ styles.dashboard_outerDiv }>
             <div style={ { width: '100%' } }>
               <center>
                 <CircularProgress />
               </center>
             </div>
           </div>
  }

  renderDetails() {
    if (this.currentDetailsData())
    {
      return this.renderDetailsContent()
    }
    else if (this.props.isFetchingDetails)
    {
      return this.renderDetailsProgress()
    }
    else
    {
      return this.renderDetailsPlaceholder();
    }
  }

  renderDetailsPlaceholder() {
    if (this.props.i18n.language === "de") {
      return (<div style={ styles.dashboard_chartColumn3 }>
                <PlatzhalterGruppenDe/>
            </div>);
    } else if (this.props.i18n.language === "es") {
      return (<div style={ styles.dashboard_chartColumn3 }>
                <PlatzhalterGruppenEs/>
              </div>);
    } else if (this.props.i18n.language === "el") {
      return (<div style={ styles.dashboard_chartColumn3 }>
                <PlatzhalterGruppenEl/>
                </div>);
    } else if (this.props.i18n.language === "fr") {
      return (<div style={ styles.dashboard_chartColumn3 }>
                <PlatzhalterGruppenFr/>
                </div>);
    } else {
      return (<div style={ styles.dashboard_chartColumn3 }>
                <PlatzhalterGruppenEn/>
                </div>)
    }
  }

  renderDetailsProgress() {
    return <div style={ styles.dashboard_chartColumn3 }>
             <div style={ { width: '100%' } }>
               <center>
                 <CircularProgress />
               </center>
             </div>
           </div>
  }

  renderDetailsContent() {
    const {t} = this.props;

    var {width} = this.state.dimensions;
    var windowWidth = this.state.width;
    if (width > windowWidth - 120)
    {
      width = windowWidth - 120;
    }
    let selectedIndex = this.getCurrentIndexSelected();
    let tableData = this.currentTableData()[selectedIndex]
    let detailsData = this.currentDetailsData()

    return <div style={ styles.dashboard_chartColumn3 }>
             <div style={ styles.dashboard_detailTitle }>
               { tableData.name } <span style={ styles.dashboard_detailTitleLegend }>{ t('modules-dashboard-GruppenChart-table-title') }</span> </div>
             <LineChart width={ width - 660 } height={ 200 } margin={ { top: 30, right: 20, left: 20, bottom: 20 } } data={ detailsData }>
               <XAxis dataKey="dateStr" />
               <YAxis yAxisId="left" orientation="left" stroke={ colors.WHITE } type="number" domain={ ['dataMin-5', 'dataMax+5'] } tick={ false } tickLine={ false } />
               <Tooltip content={ <ChannelChartTooltip t={ t } /> } />
               <CartesianGrid horizontal={ false } />
               <Line type="monotone" dataKey="messageCount" stroke={ colors.YELLOW } yAxisId="left" activeDot={ { r: 8 } } strokeWidth={ 2 } />
             </LineChart>
           </div>
  }

  renderTableRow(group, i) {
    let selectedIndex = this.getCurrentIndexSelected();
    return (<TableRow key={ i } value={ group } displayBorder={ false } style={ selectedIndex === i ? styles.dashboard_tableRowSelected : styles.dashboard_tableRowUnselected }>
              <TableRowColumn style={ styles.dashboard_chartTableColumn1 }>
                { group.name }
              </TableRowColumn>
              <TableRowColumn>
                { group.userCount }
              </TableRowColumn>
              <TableRowColumn>
                { this.state.timeValue === SHOW_WEEK ? group.messageCountWeek : group.messageCountMonth }
              </TableRowColumn>
            </TableRow>);
  }

  renderShowAllButton(t) {
    if (this.state.showAll === false && typeof this.props.tableData.dataAll !== "undefined" && this.props.tableData.dataAll.length > 10)
    {
      return <FlatButton label={ t('modules-dashboard-ChannelChart-table-showAll') } secondary={ true } onClick={ this.handleShowAllClicked } />;
    }
    else
    {
      return <div></div>;
    }

  }

  calculateColor(index) {
    let selectedIndex = this.getCurrentIndexSelected();
    if (selectedIndex === index)
    {
      return colors.YELLOW;
    }
    let tableDataCurrent = this.currentTableData()

    var alpha = (100.0 - (100.0 * index / tableDataCurrent.length)) / 100.0;
    return "rgba( 5, 161, 234, " + alpha + ")";
  }

  renderChart(tableDataCurrent) {
    if (tableDataCurrent)
    {
      return (
        <PieChart width={ 200 } height={ 250 } onClick={ this.handleOnPieEnter }>
          <Pie data={ tableDataCurrent } valueKey={ this.state.timeValue === SHOW_WEEK ? "messageCountWeek" : "messageCountMonth" } innerRadius={ 50 } outerRadius={ 80 } fill="#82ca9d" strokeWidth={ 0 }
            startAngle={ -270 } endAngle={ -630 }>
            { tableDataCurrent.map((entry, index) => <Cell key={ entry.guid } fill={ this.calculateColor(index) } />) }
          </Pie>
          <Tooltip/>
        </PieChart>
      )
    }
    else
    {
      return <PlatzhalterPieChart />
    }
  }

  renderTableContent() {
    const {t} = this.props

    let tableDataCurrent = this.currentTableData()

    return <div>
             <div style={ styles.dashboard_outerDiv }>
               <div style={ styles.dashboard_chartColumn1 }>
                 { this.renderChart(tableDataCurrent) }
               </div>
               <div style={ styles.dashboard_chartColumn2 }>
                 <Table onRowSelection={ this.handleOnTableRowSelection } selectable={ true }>
                   <TableHeader displaySelectAll={ false } adjustForCheckbox={ false } style={ { border: 'none' } }>
                     <TableRow style={ { border: 'none' } }>
                       <TableHeaderColumn style={ styles.dashboard_chartTableColumn1 }>
                         { t('modules-dashboard-ChannelChart-table-column1') }
                       </TableHeaderColumn>
                       <TableHeaderColumn>
                         { t('modules-dashboard-ChannelChart-table-column2') }
                       </TableHeaderColumn>
                       <TableHeaderColumn>
                         { t('modules-dashboard-ChannelChart-table-column3') }
                       </TableHeaderColumn>
                     </TableRow>
                   </TableHeader>
                   <TableBody displayRowCheckbox={ false }>
                     { tableDataCurrent.map(this.renderTableRow) }
                   </TableBody>
                 </Table>
                 { this.renderShowAllButton(t) }
               </div>
               { this.renderDetails() }
             </div>
           </div>
  }

  render() {
    const {t} = this.props;

    return (
      <Measure onMeasure={ (dimensions) => {
                       this.setState({
                         dimensions
                       })
                     } }>
        <div>
          <SubPageHeader>
            { t('modules-dashboard-ChannelChart-channels') } </SubPageHeader>
          { this.currentTableData() != null && <div style={ styles.dashboard_right }>
                                                 <SelectField listStyle={ { backgroundColor: adminMuiTheme.palette.primary2Color } } value={ this.state.timeValue } onChange={ this.handleChangeTime } style={ { marginRight: '15px' } }>
                                                   <MenuItem value={ SHOW_WEEK } primaryText={ t('modules-dashboard-ChannelChart-timeValue1') } />
                                                   <MenuItem value={ SHOW_MONTH } primaryText={ t('modules-dashboard-ChannelChart-timeValue2') } />
                                                 </SelectField>
                                               </div> }
          { this.renderTable() }
          <div style={ styles.dashboard_separator }>
          </div>
        </div>
      </Measure>
      );

  }
}

ChannelChart.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  tableData: PropTypes.object,

  isFetchingDetails: PropTypes.bool.isRequired,
  errorDetails: PropTypes.object,
  detailsData: PropTypes.object
}

function mapStateToProps(state) {
  //debugger;
  const {statisticsGroupChannel, statisticsGroupChannelDetails} = state;

  return {
    isFetching: statisticsGroupChannel.isFetching ? statisticsGroupChannel.isFetching : false,
    error: statisticsGroupChannel.error,
    tableData: statisticsGroupChannel.hasData ? statisticsGroupChannel.data : null,

    isFetchingDetails: statisticsGroupChannelDetails.isFetching ? statisticsGroupChannelDetails.isFetching : false,
    errorDetails: statisticsGroupChannelDetails.error,
    detailsData: statisticsGroupChannelDetails.hasData ? statisticsGroupChannelDetails.data : null,
  }
}

const ChannelChartTranslated = translate(['common'])(ChannelChart);

export default connect(mapStateToProps)(ChannelChartTranslated);
