import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';
// import { Interpolate } from 'react-i18next';
// import Checkbox from 'material-ui/Checkbox';
// import TextField from '../../components/textfields/TextField';
// import { validate, maxValue, minValue } from "../../components/validation";

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const styles = {
  label: {
    paddingTop: '8px', 
    color: '#8E9194',
    fontSize: '14px',
    fontWeight: 'regular',
    textAlign: 'left',
    display: 'inline-block',
  }
};

const initState = {
  open: false,
  src: "",
};

class CropImageDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;

    this.cancelCrop = this.cancelCrop.bind(this);
    this.ready = this.ready.bind(this);
  }

  open(parameter) {
    let buttonCropHandler = null;
    let src = null;

    if (typeof parameter.buttonCropHandler === "function")
    {
      buttonCropHandler = parameter.buttonCropHandler
    }

    if (typeof parameter.src === "string")
    {
      src = parameter.src
    }

    this.setState({
      buttonCropHandler: buttonCropHandler,
      src: src,
      open: true
    });
  }

  cancelCrop() {
    this.setState({
        open:false,
        src:"",
    });
  }

  ready() {
    setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
    }, 0);

    let newCropBoxHeight = 160;
    let newCropBoxWidth = 800;

    let containerHeight = this.refs.cropper.getContainerData().height;
    let cropBoxOffsetTop = ((containerHeight / 2) - (newCropBoxHeight/2));

    this.refs.cropper.setCropBoxData({width: newCropBoxWidth, height: newCropBoxHeight, top: cropBoxOffsetTop});
  }

  handleCancelButton() {
    this.setState(initState);
  }

  handleCropImageButton() {
    if (typeof this.state.buttonCropHandler === "function")
    {
      this.state.buttonCropHandler(this.refs.cropper.getCroppedCanvas({
        width: 800,
        height: 160,
        minWidth: 800,
        minHeight: 160,
        maxWidth: 800,
        maxHeight: 160,
        //fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
      }).toDataURL());
    }
    this.setState(initState);
  }

  render() {
    const {t} = this.props;
    let cl = this.state.src;

    if (cl === 'undefined' || cl === null || cl === "")
    {
      return <div></div>;
    }
    var actions = [
      <FlatButton style={ styles.button } label={ t('cancel') } onTouchTap={ () => this.handleCancelButton() } />,
      <RaisedButton style={ styles.button } label={ t('modules-appdesign-cropimage-accept-btn') } primary={ true } onTouchTap={ () => this.handleCropImageButton() } />,
    ];

    return (
      <div>
        <Dialog title={ t('modules-appdesign-cropimage-title') } actions={ actions } modal={ true } open={ this.state.open } contentStyle={ {minWidth: 898} }>
            <Cropper ref="cropper"
                style={{ height: 'auto', maxHeight: 600, width: 850 }}
                preview=".img-preview"
                guides={false}
                modal={true}
                background={false}
                minCropBoxWidth={800}
                minCropBoxHeight={160}
                minContainerWidth={850}
                minContainerHeight={210}
                cropBoxResizable={false}
                dragMode='move'
                toggleDragModeOnDblclick={false}
                src={ cl }
                ready={this.ready} />
                <span style={ styles.label }>{ t('modules-appdesign-cropimage-zoom-hint') }</span>
        </Dialog>

      </div>
      );
  }
}

export default CropImageDialog;
