import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconDevices(props) {
  return <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g fill="none" fillRule="evenodd">
        <rect width="100" height="100"/>
        <path fill="#FFF" fillRule="nonzero" d="M52.9999499,27 L52.9999499,39.7499696 C52.9999499,40.987485 54.0124292,41.9999642 55.2499446,41.9999642 L67.9999142,41.9999642 L67.9999142,72.7498909 C67.9999142,73.9967446 66.9967733,74.9998856 65.7499195,74.9998856 L34.2499946,74.9998856 C33.0031409,74.9998856 32,73.9967446 32,72.7498909 L32,29.2499946 C32,28.0031409 33.0031409,27 34.2499946,27 L52.9999499,27 Z M61.65,55 L55.35,55 C54.6485294,55 54.0716263,55.5207612 54.0061826,56.1861388 L54,56.3125 L54,67.6875 C54,68.3694853 54.5356401,68.9303633 55.2200285,68.9939892 L55.35,69 L61.65,69 C62.3514706,69 62.9283737,68.4792388 62.9938174,67.8138612 L63,67.6875 L63,56.3125 C63,55.5878906 62.3953125,55 61.65,55 Z M57.4875,66.8125 L59.5125,66.8125 C59.6749123,66.8125 59.8115015,66.9255575 59.8431111,67.0747147 L59.85,67.140625 L59.85,67.359375 C59.85,67.5172758 59.7337123,67.6500709 59.5802935,67.6808025 L59.5125,67.6875 L57.4875,67.6875 C57.3250877,67.6875 57.1884985,67.5744425 57.1568889,67.4252853 L57.15,67.359375 L57.15,67.140625 C57.15,66.9827242 57.2662877,66.8499291 57.4197065,66.8191975 L57.4875,66.8125 L59.5125,66.8125 Z M58.1666667,48 L39.8333333,48 C38.8211806,48 38,48.7978516 38,49.78125 L38,49.78125 L38,61.65625 C38,62.6396484 38.8211806,63.4375 39.8333333,63.4375 L39.8333333,63.4375 L47.1666667,63.4375 L46.5555556,65.21875 L43.8055556,65.21875 C43.2975769,65.21875 42.8888889,65.6158276 42.8888889,66.109375 C42.8888889,66.6029224 43.2975769,67 43.8055556,67 L43.8055556,67 L53,67 L53,65.218 L51.4444444,65.21875 L50.8333333,63.4375 L53,63.437 L53,60.666 L40.75,60.6666667 L40.75,50.7142857 L57.25,50.7142857 L57.25,54 L60,54 L60,49.78125 C60,48.7978516 59.1788194,48 58.1666667,48 L58.1666667,48 Z M56.5717798,27 C57.171815,27 57.743652,27.2343744 58.165526,27.6562484 L67.3436657,36.8437265 C67.7655397,37.2656005 67.9999142,37.8374375 67.9999142,38.4281344 L67.9999142,38.9999714 L55.9999428,38.9999714 L55.9999428,27 L56.5717798,27 Z"/>
      </g>
    </svg>
  </SvgIcon>;

}
;

export default IconDevices;
