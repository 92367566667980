/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router';

import FlatButton from 'material-ui/FlatButton';

import IconDashBoard from '../ressources/icon_dashboard';
import IconDashBoardActive from '../ressources/icon_dashboard_active';

import IconLizenzen from '../ressources/icon_lizenzen';
import IconLizenzenActive from '../ressources/icon_lizenzen_active';

import IconUser from '../ressources/icon_user';
import IconUserActive from '../ressources/icon_user_active';

import IconGroups from '../ressources/icon_groups';
import IconGroupsActive from '../ressources/icon_groups_active';

import IconChannels from '../ressources/icon_channels';
import IconChannelsActive from '../ressources/icon_channels_active';

import IconSetting from '../ressources/icon_setting';
import IconSettingActive from '../ressources/icon_setting_active';

import IconAppSetting from '../ressources/icon_appsetting';
import IconAppSettingActive from '../ressources/icon_appsetting_active';

import IconStyle from '../ressources/icon_style';
import IconStyleActive from '../ressources/icon_style_active';

import { translate } from 'react-i18next';

import { PRIMARY_REVISION, PRIMARY_VERSION } from '../version.js'

const styles = {
  column1: {
    display: 'inline-block',
    float: 'left'
  },
  divStandard: {
    borderLeft: '5px solid #00375D',
  },
  divActive: {
    borderLeft: '5px solid #EEC643',

  },
  button: {
    fontHeight: '18pt',
    textColor: '#FFFFFF',
    color: '#FFFFFF',
    height: '52px',
    width: '100%',
    textAlign: 'left'

  },
  activeButton: {
    fontHeight: '18pt',
    textColor: '#FFFFFF',
    color: '#FFFFFF',
    height: '52px',
    width: '100%',
    textAlign: 'left'
  },
  largeIcon: {
    width: 40,
    height: 40,
  },

}


export class NavigationLeftItem extends Component {

  isPathSelected() {

    let pathname = this.context.router.location.pathname;
    if (typeof pathname === "undefined")
    {
      return false;
    }
    let index = pathname.indexOf('/', 1)
    if (index !== -1)
    {
      pathname = pathname.substring(0, index);
    }
    return (pathname === this.props.destination);
  }

  currentStyle() {
    if (this.isPathSelected())
    {
      return styles.activeButton;
    }
    else
    {
      return styles.button;
    }
  }

  currentDivStyle() {
    if (this.isPathSelected())
    {
      return styles.divActive;
    }
    else
    {
      return styles.divStandard;
    }
  }

  currentImage() {
    if (this.isPathSelected())
    {
      return this.props.activeImage;
    }
    else
    {
      return this.props.image;
    }
  }

  render() {
    return <div style={ this.currentDivStyle() }>
             <Link to={ this.props.destination }>
             <FlatButton label={ this.props.label } labelPosition="after" style={ this.currentStyle() } labelStyle={ this.currentStyle() } icon={ this.currentImage() } hoverColor="#00375D"
             />
             </Link>
           </div>

  }
}

NavigationLeftItem.contextTypes = {
  router: React.PropTypes.object
};


export class NavigationLeft extends React.Component {

  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = {

    }

  }

  changeLanguage() {
    this.props.i18n.changeLanguage('cimode', (err, t) => {
      // resources have been loaded
    });
  }

  render() {
    const { t } = this.props;

    // let optInState = null;

    /*
    if (typeof adminInfo !== 'undefined')
    {
      optInState = adminInfo.Company.optInState;
    }
    */

    var links = {
        impress: t('footer-link-impress-url'),
        privacy: t('footer-link-privacy-url'),
        tc: t('footer-link-tc-url'),
      }

    return (
      <div>
        <div>
          <NavigationLeftItem destination='/dashboard' label={ t('components-NavigationLeft-dashboard') } image={ < IconDashBoard style={ styles.largeIcon } /> } activeImage={ < IconDashBoardActive style={ styles.largeIcon } /> } />
          <br/>
          <NavigationLeftItem destination='/user' label={ t('components-NavigationLeft-user') } image={ < IconUser style={ styles.largeIcon } /> } activeImage={ < IconUserActive style={ styles.largeIcon } /> } />
          <NavigationLeftItem destination='/license' label={ t('components-NavigationLeft-license') } image={ < IconLizenzen style={ styles.largeIcon } /> } activeImage={ < IconLizenzenActive style={ styles.largeIcon } /> } />
          <NavigationLeftItem destination='/groups' label={ t('components-NavigationLeft-groups') } image={ < IconGroups style={ styles.largeIcon } /> } activeImage={ < IconGroupsActive style={ styles.largeIcon } /> } />
          <NavigationLeftItem destination='/channel' label={ t('components-NavigationLeft-channel') } image={ < IconChannels style={ styles.largeIcon } /> } activeImage={ < IconChannelsActive style={ styles.largeIcon } /> } />
          <NavigationLeftItem destination='/appsettings' label={ t('components-NavigationLeft-appsettings') } image={ <IconAppSetting style={ styles.largeIcon } /> } activeImage={ < IconAppSettingActive style={ styles.largeIcon } /> } />
          <NavigationLeftItem destination='/appdesign' label={ t('components-NavigationLeft-appdesign') } image={ <IconStyle style={ styles.largeIcon } /> } activeImage={ < IconStyleActive style={ styles.largeIcon } /> } />
          <br/>
          <NavigationLeftItem destination="/settings" label={ t('header-btn-settings') } image={ <IconSetting style={ styles.largeIcon } /> } activeImage={ < IconSettingActive style={ styles.largeIcon } /> } />
        </div>
        <div style={ { color: "#7B98AB", fontSize: '12px', margin: "10px", bottom: "0px", position: "absolute" } }>
          <div style={ { textDecoration: 'underline', marginBottom: '10px' } }>
            <a href={ links.impress }>
              { t('footer-link-impress') }
            </a>
          </div>
          <div style={ { textDecoration: 'underline', marginBottom: '10px' } }>
            <a href={ links.tc }>
              { t('footer-link-tc') }
            </a>
          </div>
          <div style={ { textDecoration: 'underline', marginBottom: '10px' } }>
            <a href={ links.privacy }>
              { t('footer-link-privacy') }
            </a>
          </div>
          <div>
            { PRIMARY_VERSION } (
            { PRIMARY_REVISION })
          </div>
        </div>
      </div>
      );
  }
}

NavigationLeft.contextTypes = {
  router: React.PropTypes.object
};

NavigationLeft.propTypes = {
  adminInfo: PropTypes.object
}

//exports.default = NavigationLeft;
export default translate(['common'])(NavigationLeft);
