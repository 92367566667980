/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconTag(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <g fill="#7B98AB">
               <path fill="#7B98AB" d="M20.9031713,14.0019429 C21.5255339,14.6243054 21.5255339,15.6334316 20.9031713,16.2558266 L14.1258266,23.0331713 C13.5033992,23.6555663 12.494273,23.6555339 11.8719429,23.0331713 L4.83678813,15.9980166 C4.57914028,15.7403687 4.37,15.2354814 4.37,14.871091 L4.37,8.0937462 C4.37,7.21354124 5.08354124,6.5 5.9637462,6.5 L12.741091,6.5 C13.1054814,6.5 13.6103687,6.70914028 13.8680166,6.96678813 L20.9031713,14.0019429 Z M8.08874113,11.8124873 C8.9689461,11.8124873 9.68248733,11.0989461 9.68248733,10.2187411 C9.68248733,9.33853617 8.9689461,8.62499493 8.08874113,8.62499493 C7.20853617,8.62499493 6.49499493,9.33853617 6.49499493,10.2187411 C6.49499493,11.0989461 7.20853617,11.8124873 8.08874113,11.8124873 Z M25.1531612,16.2558266 L18.3758164,23.0331713 C17.7534215,23.6555339 16.7442953,23.6555339 16.1219327,23.0331713 L16.109968,23.0212066 L21.8892434,17.2419311 C22.4536628,16.6775117 22.7644874,15.927071 22.7644874,15.1288685 C22.7644874,14.330666 22.4536304,13.5802577 21.8892434,13.0158383 L15.3734051,6.5 L16.9910808,6.5 C17.3554713,6.5 17.8603586,6.70914028 18.1180065,6.96678813 L25.1531612,14.0019429 C25.7755238,14.6243054 25.7755238,15.6334316 25.1531612,16.2558266 Z"
               />
             </g>
           </svg>
         </SvgIcon>;
}

export default IconTag;
