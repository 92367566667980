import React, { PropTypes } from 'react';
import { translate } from 'react-i18next';
import { Interpolate } from 'react-i18next';

import TextField from './components/textfields/TextField';
import Avatar from 'material-ui/Avatar';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import ToggleButton from 'material-ui/Toggle';
import RaisedButton from './components/buttons/RaisedButton';
import FlatButton from './components/buttons/FlatButton';
// sru_231117: import Dialog from 'material-ui/Dialog';

import { PageHeader, SubPageHeader } from './components/pageheader'
import { connect } from 'react-redux';
import { setCompanyAdress,
  changePassword,
  getAdminInfo,
  setAdminName,
  purchaseCompanyVoucher,
  setCompanyProfilImage,
  setCompanyLanguage,
  createMailToken,
  deleteMailToken,
  setMailTokenMode,
  createApiUser,
  deleteApiUser,
  getApiUserCredentials } from './actions/index';

import BaseModul from './modules/BaseModul';
import { ModalDialog } from './components/ModalDialog';
import OrderLicenseDialog from './modules/license/OrderLicenseDialog.js';
import ApiCredentialsDialog from './modules/settings/ApiCredentialsDialog.js';
import ApiTwoFaEnableDialog from './modules/settings/ApiTwoFaEnableDialog';
import ApiTwoFaDisableDialog from './modules/settings/ApiTwoFaDisableDialog';
import ApiTwoFaCreateRecoveryCodesDialog from './modules/settings/ApiTwoFaCreateRecoveryCodesDialog';
import ApiTwoFaShowRecoveryCodesDialog from './modules/settings/ApiTwoFaShowRecoveryCodesDialog';
import DeleteApiUserDialog from './modules/settings/DeleteApiUserDialog.js';

import { WaitDialog } from './components/WaitDialog';
import ErrorDialog from './components/ErrorDialog.js';
import PasswordChangeForm, { REPEAT_PASSWORD, NEW_PASSWORD } from './components/forms/PasswordChangeForm.js';
import { Table, TableBody, TableHeader, TableRow } from 'material-ui/Table';
import { TableRowColumnText, TableHeaderColumnSort, TableHeaderColumnDefault } from './components/table/TableCells'
import { getTableStyles } from './components/styles.js';
import { IsNullOrEmpty } from './components/StringExtensions';

import { detectIE } from "./util/Browser";
// import admin from "./modules/settings/Admin";

require('./components/DateExtensions');


// import IconDelete from './ressources/icon_delete_white';
// import IconAlert from './ressources/img_alert';


const styles = {
  root: {
    width: '100%',
    //position: 'relative',
    height: 'fill',
  },
  flexColum1: {
    flexGrow: '0',
    maxWidth: '600px',
    minWidth: '400px',
    width: '500px'
  },
  flexColum2: {
    flexGrow: '1',
    marginTop: '13px'
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'absolute'
  },
  formBlock: {
    marginLeft: '25px',
    marginRight: '25px'
  },
  formBlockTable: {
    marginTop: '12px',
    maxWidth: '550px'
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  rowTableButton: {
    marginLeft: '25px'
  },
  streetTextfield: {
    flex: '4 1 0%'
  },
  streetNumberTextField: {
    flex: '1 1 0%',
    marginLeft: '8px'
  },
  postCodeTextField: {
    flex: '1 1 0%'
  },
  cityTextField: {
    flex: '4 1 0%',
    marginLeft: '8px'
  },
  rightTextField: {
    marginLeft: '8px'
  },
  subPageHeader: {
    color: '#EEC643',
  },
  buttonInForm: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  buttonRight: {
    marginLeft: '16px'
  },
  subHeaderDiv: {
    minHeight: '60px',
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
    paddingBottom: '0px',
  },
  currentPwdTextField: {
    marginTop: '8px'
  },
  tableColumnDate: {
    //width: '100px'
  },
  dialog: {
    width: '550px'
  },
  dialogCenterImage: {

    display: 'flex',
    alignItems: 'center'
  },
  dialogDesc: {
    color: '#FFFFFF',
    paddingTop: '24px'
  },
  dialogPassword: {
    paddingTop: '24px'
  },
  text1: {
    color: '#EEC643',
    fontSize: '16px',
    marginBottom: '4px'
  },
  text2: {
    color: '#8E9194',
    fontSize: '14px',
    marginBottom: '4px'
  },
  text2White: {
    color: '#FFFFFF',
    fontSize: '14px',
    marginBottom: '4px'
  },
  tableText: {
    paddingLeft: '0px',
    color: '#7B98AB',
    fontSize: '14px'
  },
  text16Green: {
    marginTop: '4px',
    marginBottom: '8px',
    color: '#00C1A7'
  }
}

const tableStyles = getTableStyles();

const COMPANY_NAME = "companyName";
const COMPANY_STREET = "companyStreet";
const COMPANY_STREET_NUMBER = "companyStreetNumber";
const COMPANY_POSTCODE = "companyPostcode";
const COMPANY_CITY = "companyCity";
const PWD_CURRENT = "pwdCurrent";
const ADMIN_FIRST_NAME = "adminFirstName";
const ADMIN_NAME = "adminName";

const LANGUAGES = [ 'de', 'el', 'en', 'es', 'fr' ];
/*
class ConfirmDeleteCompanyDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pwd: ""
    };
  }

  open(deleteCompanyCallback) {

    this.setState({
      open: true,
      pwd: ""
    });
    this.deleteCompanyCallback = deleteCompanyCallback;
  }

  close() {
    this.setState({
      open: false,
      pwd: ""
    }
    );
  }

  handleCancelButton() {
    this.close();
  }

  handleChangeData(name, newValue) {
    this.setState({
      pwd: newValue
    }
    );
  }

  handleConfirmDeleteButton() {
    if (typeof this.deleteCompanyCallback === 'function')
    {
      this.deleteCompanyCallback(this.state.pwd)
    }
  }

  render() {
    const {t} = this.props;

    var actions = [
      <FlatButton style={ styles.button } label={ t('settings-confirmdeletecompanydialog-ok') } disabled={ this.state.pwd.length == 0 } onTouchTap={ () => this.handleConfirmDeleteButton() } />,
      <RaisedButton style={ styles.button } label={ t('cancel') } onTouchTap={ () => this.handleCancelButton() } primary={ true } />,
    ];


    return (
      <div>
        <Dialog title={ t('settings-confirmdeletecompanydialog-title') } contentStyle={ styles.dialog } actions={ actions } modal={ true } open={ this.state.open }>
          <div style={ styles.dialogCenterImage }>
            <IconAlert style={ { width: '50px', height: '50px', margin: 'auto' } } />
          </div>
          <div style={ styles.dialogDesc }>
            { t('settings-confirmdeletecompanydialog-desc') }
          </div>
          <div style={ styles.dialogPassword }>
            <TextField type='password' hintText={ t('settings-confirmdeletecompanydialog-hint') } fullWidth={ true } onChange={ (event, newValue) => this.handleChangeData('pwd', newValue) } />
          </div>
        </Dialog>
      </div>
      );
  }
}
*/

class Settings extends BaseModul {

  constructor(props) {
    super(props);

    this.resetState(props, true );

    this.onOrderLicenseHandler = this.onOrderLicenseHandler.bind(this);
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.getAdminInfoSuccessHandler = this.getAdminInfoSuccessHandler.bind(this);
    this.onActionSuccessHandler = this.onActionSuccessHandler.bind(this);
    this.onSaveActionSuccessHandler = this.onSaveActionSuccessHandler.bind(this);
    this.onActionErrorHandler = this.onActionErrorHandler.bind(this);

    // sru_230904: disabled
    // this.beginDeleteCompanyClicked = this.beginDeleteCompanyClicked.bind(this);
    // this.confirmDeleteCompanyClicked = this.confirmDeleteCompanyClicked.bind(this);
    // this.onDeleteActionSuccessHandler = this.onDeleteActionSuccessHandler.bind(this);

    this.handleImportFileClicked = this.handleImportFileClicked.bind(this);
    this.fileContentLoaded = this.fileContentLoaded.bind(this);
    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.handleCompanyValueChangeHandler = this.handleCompanyValueChangeHandler.bind(this);

    this.companyLogoSavedHandler = this.companyLogoSavedHandler.bind(this);

    this.handleToggleCockpitToken = this.handleToggleCockpitToken.bind(this);
    this.handleSwitchMailtokenModeClicked = this.handleSwitchMailtokenModeClicked.bind(this);

    this.onCreateApiUserSuccessHandler = this.onCreateApiUserSuccessHandler.bind(this);
    this.openTwoFaEnableSecretDialog = this.openTwoFaEnableSecretDialog.bind(this);
    this.openTwoFaDisableSecretDialog = this.openTwoFaDisableSecretDialog.bind(this);
    this.onCreateRecoveryCodesSuccess = this.onCreateRecoveryCodesSuccess.bind(this);
    this.onTwoFaShowRecoveryCodesDialog = this.onTwoFaShowRecoveryCodesDialog.bind(this);
    this.onGetApiUserCredentialsSuccessHandler = this.onGetApiUserCredentialsSuccessHandler.bind(this);
    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);

    this.onDeleteApiUserHandler = this.onDeleteApiUserHandler.bind(this);
    this.onPasswordChangedSuccessHandler = this.onPasswordChangedSuccessHandler.bind(this);
    this.onCompanyLanguageSuccessHandler = this.onCompanyLanguageSuccessHandler.bind(this);
  }

  /**
   *
   * @param props
   * @param {boolean} isInitialization - true if first run after instanciing the class
   * @returns {void}
   */
  resetState(props, isInitialization) {

    let adminInfo = this.state.adminInfo;

    let companyData = {};
    let adminData = {};
    let passwordData = {};
    passwordData[PWD_CURRENT] = '';

    let companyLanguage = 'en';

    if (typeof adminInfo !== 'undefined' && typeof adminInfo.Company !== 'undefined') {
      companyData[COMPANY_NAME] = adminInfo.Company.name;
      companyData[COMPANY_STREET] = adminInfo.Company.street;
      companyData[COMPANY_STREET_NUMBER] = adminInfo.Company.streetNumber;
      companyData[COMPANY_POSTCODE] = adminInfo.Company.postCode;
      companyData[COMPANY_CITY] = adminInfo.Company.city;

      companyData.mailToken = null;
      if (typeof adminInfo.Company.mailToken !== 'undefined') {
        companyData.mailToken = adminInfo.Company.mailToken;
      }

      companyData.mailTokenMode = adminInfo.Company.mailTokenMode;
      companyData.hasApiUser = adminInfo.hasApiUser === "true";

      adminData[ADMIN_NAME] = adminInfo.Admin.name;
      adminData[ADMIN_FIRST_NAME] = adminInfo.Admin.firstName;
      adminData.twoFactor = !!adminInfo.Admin.twoFactor;

      if (typeof adminInfo.Company.language !== 'undefined') {
        if (typeof LANGUAGES.indexOf(adminInfo.Company.language) === 'undefined') {
          companyLanguage = 'en';
        } else {
          companyLanguage = adminInfo.Company.language;
        }
      }

    }

    let state = {
      enableSave: false,
      companyData: companyData,
      adminData: adminData,
      passwordData: passwordData,
      errorTextFields: {},
      isChangePasswordClick: false,
      sortColumn: 1,
      sortDirection: 0,
      tabledata: this.getLicenseSorted(1, 0),
      imageUploaded: null,
      companyLanguage: companyLanguage,
      companyLanguageChanged: null,
      apiUserData: null
    };

    if ( isInitialization ) {
      this.state = state;
    } else {
      this.setState(state);
    }

  }


  componentDidMount() {
    super.componentDidMount();
    let showWaitDialog = true;
    this.loadAdminInfo(showWaitDialog);
  }

  loadAdminInfo(showWaitDialog) {
    if (showWaitDialog)
    {
      this.refs.waitDialog.open();
    }

    this.props.dispatch(getAdminInfo(false, this.getAdminInfoSuccessHandler, this.onActionErrorHandler));

  }

  mapAdminData(json) {
    if (typeof json !== "undefined")
    {
      let adminInfo = json;
      adminInfo.Admin.twoFactor = !!adminInfo.Admin.twoFactor;

      let companyData = {};
      let adminData = {};
      let imageData = null;
      let language = this.state.companyLanguage;
      if (typeof adminInfo.Company !== "undefined")
      {
        companyData[COMPANY_NAME] = adminInfo.Company.name;
        companyData[COMPANY_STREET] = adminInfo.Company.street;
        companyData[COMPANY_STREET_NUMBER] = adminInfo.Company.streetNumber;
        companyData[COMPANY_POSTCODE] = adminInfo.Company.postCode;
        companyData[COMPANY_CITY] = adminInfo.Company.city;

        companyData.mailToken = null;
        if (typeof adminInfo.Company.mailToken !== "undefined")
        {
          companyData.mailToken = adminInfo.Company.mailToken;
        }
        companyData.mailTokenMode = adminInfo.Company.mailTokenMode;

        if (typeof adminInfo.Company.profilImage !== "undefined")
        {
          imageData = adminInfo.Company.profilImage;
        }
        if (typeof adminInfo.Company.language !== "undefined")
        {
          language = adminInfo.Company.language;
          if (typeof LANGUAGES.indexOf(adminInfo.Company.language) === "undefined") {
            language = "en";
          }
          // console.log("Map-i adm: ", adminInfo.Company.language, "lang: ", language, " state:", this.state.companyLanguage);
        }

      }
      else
      {
        companyData[COMPANY_NAME] = this.state.companyData[COMPANY_NAME];
        companyData[COMPANY_STREET] = this.state.companyData[COMPANY_STREET];
        companyData[COMPANY_STREET_NUMBER] = this.state.companyData[COMPANY_STREET_NUMBER];
        companyData[COMPANY_POSTCODE] = this.state.companyData[COMPANY_POSTCODE];
        companyData[COMPANY_CITY] = this.state.companyData[COMPANY_CITY];
        companyData.mailToken = this.state.companyData.mailToken
        companyData.mailTokenMode = this.state.companyData.mailTokenMode

        imageData = this.state.imageExisting
        language = this.state.companyLanguage
        // console.log("Map-e adm: ", adminInfo.Company.language, "lang: ", language);
      }

      if (typeof adminInfo.hasApiUser === "undefined")
      {
        companyData.hasApiUser = this.state.companyData.hasApiUser;
      }
      else
      {
        companyData.hasApiUser = adminInfo.hasApiUser === "true";
      }

      if (typeof adminInfo.Admin !== "undefined")
      {
        adminData[ADMIN_NAME] = adminInfo.Admin.name;
        adminData[ADMIN_FIRST_NAME] = adminInfo.Admin.firstName;
        adminData.role = adminInfo.Admin.role;
        adminData.twoFactor = adminInfo.Admin.twoFactor;
      }
      else
      {
        adminInfo.Admin = this.state.adminInfo.Admin;
      }

      if (typeof adminInfo.Company === "undefined")
      {
        adminInfo.Company = this.state.adminInfo.Company
      }

      this.setState({
        companyData: companyData,
        adminData: adminData,
        imageExisting: imageData,
        companyLanguage: language,
        adminInfo: adminInfo
      });
      this.state.companyLanguage = language;
    }
  }

  getAdminInfoSuccessHandler(json) {

    this.mapAdminData(json)

    this.refs.waitDialog.close();
  }

  handleReloadData() {
    this.props.dispatch(getAdminInfo(false, this.getAdminInfoSuccessHandler, this.onActionErrorHandler));
  }

  handleReloadDiscardData() {
    this.props.dispatch(getAdminInfo(false, undefined, this.onActionErrorHandler));
  }

  getLicenseSorted(sortColumn, sortDirection) {
    let adminInfo = this.state.adminInfo;
    if (typeof adminInfo === "undefined")
    {
      return [];
    }
    let sortColumnName = "purchaseDate";
    if (sortColumn === 2)
    {
      sortColumnName = "voucherCount";
    }
    let sortedLicense = [];
    if (adminInfo.hasData && adminInfo.voucherGroup)
    {
      sortedLicense = adminInfo.voucherGroup.sort(function(a, b) {
        let rc = 0;
        if (typeof a.VoucherGroup[sortColumnName] === 'undefined')
        {
          return rc;
        }
        else if (typeof a.VoucherGroup[sortColumnName] === 'number' || sortColumnName === 'voucherCount')
        {
          rc = a.VoucherGroup[sortColumnName] - b.VoucherGroup[sortColumnName];
        }
        else if (typeof a.VoucherGroup[sortColumnName] === 'object')
        {
          //sortiere Arrays ...
          let astringified = JSON.stringify(a.VoucherGroup[sortColumnName]);
          let bstringified = JSON.stringify(b.VoucherGroup[sortColumnName]);
          rc = astringified.localeCompare(bstringified);
        }
        else
        {
          rc = a.VoucherGroup[sortColumnName].localeCompare(b.VoucherGroup[sortColumnName]);
        }
        if (rc === 0)
        {
          rc = a.VoucherGroup["guid"].localeCompare(b.VoucherGroup["guid"]);
        }
        if (sortDirection === 0)
        {
          return rc;
        }
        else
        {
          return rc * (-1);
        }
      });
      return sortedLicense;
    }
  }

  /**
  * Callback fuer sortier-buttons
  */
  handleSortTableButtonClicked(index) {
    if (this.state.sortColumn === index)
    {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.getLicenseSorted(index, newDirection)
      });

    }
    else
    {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.getLicenseSorted(index, newDirection)
      });
    }
  }

  onSaveActionSuccessHandler(json) {

    this.mapAdminData(json)

    if (this.handleSaveClick() === false)
    {
      this.refs.waitDialog.close();
      // sru_231117: const {dispatch} = this.props;

      this.setState({
        enableSave: false
      })

      this.loadAdminInfo(false)
    }
  }

  onActionSuccessHandler() {
    this.refs.waitDialog.close();
    // sru_231117: const {dispatch} = this.props;

    this.loadAdminInfo(false)
  }

  onPasswordChangedSuccessHandler(json) {

    let passwordData = {};
    passwordData[PWD_CURRENT] = "";

    this.setState({
      isChangePasswordClick: false,
      passwordData: passwordData
    });
    this.onSaveActionSuccessHandler(json)
  }

  onCompanyLanguageSuccessHandler(json) {
    this.setState({
      companyLanguageChanged: null
    });
    this.onSaveActionSuccessHandler(json)

  }

  onActionErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // sru_231117: const {t, dispatch} = this.props;

    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);
  }

  onCreateApiUserSuccessHandler(json) {
    this.refs.waitDialog.close();

    this.setState({
      apiUserData: json
    });

    this.loadAdminInfo(false)

    this.refs.apiCredentialsDialog.open({
      credentials: this.state.apiUserData.Credential
    });
  }


  /**
   *
   * @returns {void}
   */
  openTwoFaEnableSecretDialog() {
    const that = this;
    that.refs.waitDialog.close();
    that.refs.apiTwoFaEnableDialog.open();
  }


  /**
   *
   * @returns {void}
   */
  openTwoFaDisableSecretDialog() {
    const that = this;
    that.refs.waitDialog.close();
    that.refs.apiTwoFaDisableDialog.open();
  }


  /**
   *
   * @returns {void}
   */
  onCreateRecoveryCodesSuccess() {
    const that = this;
    that.refs.waitDialog.close();
    that.refs.apiCreateRecoveryCodesDialog.open();
  }


  /**
   *
   * @returns {void}
   */
  onTwoFaShowRecoveryCodesDialog() {
    const that = this;
    that.refs.waitDialog.close();
    that.refs.apiShowRecoveryCodesDialog.open({ recoveryCodes: that.state.adminInfo.Admin.backupCodes} );
  }


  onGetApiUserCredentialsSuccessHandler(json) {
    this.refs.waitDialog.close();

    this.setState({
      apiUserData: json
    });

    this.refs.apiCredentialsDialog.open({
      credentials: this.state.apiUserData.Credential
    });
  }


  handleChangeData(id, newValue) {
    switch (id) {
      case PWD_CURRENT:
      case NEW_PASSWORD:
      case REPEAT_PASSWORD: {
        let oldData = this.state.passwordData;
        if (oldData[id] !== newValue)
        {
          oldData[id] = newValue;
          let newPwdCheck = this.refs.passwordChangeForm.hasPasswordPassedAllGuidelines();
          let currentPwdCheck = oldData[PWD_CURRENT] && oldData[PWD_CURRENT].length > 0;

          this.setState({
            enableSave: (newPwdCheck && currentPwdCheck) || this.state.enableSave,
            passwordData: oldData
          })
        }
        break;
      }
      case COMPANY_CITY:
      case COMPANY_NAME:
      case COMPANY_STREET:
      case COMPANY_STREET_NUMBER:
      case COMPANY_POSTCODE: {
        let oldData = this.state.companyData;
        if (oldData[id] !== newValue)
        {
          oldData[id] = newValue;
          this.setState({
            enableSave: true,
            companyData: oldData
          })
        }
        break;
      }
      case ADMIN_FIRST_NAME:
      case ADMIN_NAME: {
        let oldData = this.state.adminData;
        if (oldData[id] !== newValue)
        {
          oldData[id] = newValue;
          this.setState({
            enableSave: true,
            adminData: oldData
          })
        }
        break;
      }
      default:
    }
  }


  hasCompanyValueChanges() {
    let adminInfo = this.state.adminInfo;

    let companyData = this.state.companyData;

    if (companyData[COMPANY_NAME] !== adminInfo.Company.name)
    {
      return true;
    }

    if (companyData[COMPANY_STREET] !== adminInfo.Company.street)
    {
      return true;
    }

    if (companyData[COMPANY_STREET_NUMBER] !== adminInfo.Company.streetNumber)
    {
      return true;
    }
    if (companyData[COMPANY_POSTCODE] !== adminInfo.Company.postCode)
    {
      return true;
    }
    return companyData[COMPANY_CITY] !== adminInfo.Company.city;
  }

  handleCompanyValueChangeHandler(event, key) {
    let language = LANGUAGES[key];
    if (typeof language === "undefined" ) {
      language = "en";
    }

    this.setState({
      companyLanguage: language,
      companyLanguageChanged: language,
      enableSave: true
    });
  }

  hasAdminNameChanges() {
    let adminInfo = this.state.adminInfo;
    let adminData = this.state.adminData

    if (typeof adminData[ADMIN_NAME] !== "undefined" && adminData[ADMIN_NAME] !== adminInfo.Admin.name)
    {
      return true;
    }

    return typeof adminData[ADMIN_FIRST_NAME] !== "undefined" && adminData[ADMIN_FIRST_NAME] !== adminInfo.Admin.firstName;
  }

  hasEmptyTextFields(ids, data) {
    let errorTextFields = this.state.errorTextFields;
    let hasEmptyFields = false;

    for (let i = 0; i < ids.length; i++)
    {
      let id = ids[i];
      let value = data[id];
      if (!value || value.length === 0)
      {
        errorTextFields[id] = true;
        hasEmptyFields = true;
      }
      else
      {
        errorTextFields[id] = false;
      }
    }

    if (Object.keys(errorTextFields).length > 0)
    {
      this.setState({
        errorTextFields: errorTextFields
      });
    }

    return hasEmptyFields;
  }

  companyLogoSavedHandler() {
    // sru_231117: let imageExisting = this.state.imageUploaded

    this.setState({
      imageUploaded: null
    })

    this.onSaveActionSuccessHandler();
  }


  handleSaveClick() {
    // sru_231117: const {onSaveSettingsClick, adminInfo} = this.props;
    // sru_231117: var showWaitingDialog = false;
    let isSaving = false;

    while (true) {

      if (this.hasCompanyValueChanges())
      {
        let ids = [COMPANY_NAME, COMPANY_STREET, COMPANY_STREET_NUMBER, COMPANY_POSTCODE, COMPANY_CITY];
        let companyData = this.state.companyData;

        if (!this.hasEmptyTextFields(ids, companyData))
        {
          this.props.dispatch(setCompanyAdress(companyData[COMPANY_NAME], companyData[COMPANY_STREET], companyData[COMPANY_STREET_NUMBER], companyData[COMPANY_POSTCODE], companyData[COMPANY_CITY], this.onSaveActionSuccessHandler, this.onActionErrorHandler));
          isSaving = true;
          break
        }

      }
      if (this.state.imageUploaded !== null && typeof this.state.imageUploaded !== "undefined")
      {
        isSaving = true;
        let currentCompanyLogoData = this.state.imageUploaded.substr(this.state.imageUploaded.indexOf(',') + 1)
        this.props.dispatch(setCompanyProfilImage(currentCompanyLogoData, this.companyLogoSavedHandler, this.onActionErrorHandler));
        break

      }
      if (this.state.companyLanguageChanged !== null && typeof this.state.companyLanguageChanged !== "undefined")
      {
        isSaving = true;
        //var currentCompanyLogoData = this.state.imageUploaded.substr(this.state.imageUploaded.indexOf(',') + 1)
        this.props.dispatch(setCompanyLanguage(this.state.companyLanguageChanged, this.onCompanyLanguageSuccessHandler, this.onActionErrorHandler));

        break;
      }

      if (this.hasAdminNameChanges())
      {
        let ids = [ADMIN_NAME, ADMIN_FIRST_NAME];
        let adminData = this.state.adminData;

        if (!this.hasEmptyTextFields(ids, adminData))
        {
          isSaving = true;
          this.props.dispatch(setAdminName(adminData[ADMIN_NAME], adminData[ADMIN_FIRST_NAME], this.onSaveActionSuccessHandler, this.onActionErrorHandler));
          break;
        }
      }

      if (this.state.isChangePasswordClick)
      {
        let pwdData = this.state.passwordData;
        let ids = [PWD_CURRENT];
        let hasEmptyFields = this.hasEmptyTextFields(ids, pwdData)
        if (this.refs.passwordChangeForm.validatePassword() && !hasEmptyFields)
        {
          isSaving = true;
          this.props.dispatch(changePassword(pwdData[PWD_CURRENT], pwdData[NEW_PASSWORD], this.onPasswordChangedSuccessHandler, this.onActionErrorHandler));
          break;
        }
      }
      break;
    }

    if (isSaving)
    {
      this.refs.waitDialog.open();
      this.setState({
        isSaving: isSaving
      });
    }

    return isSaving;
  }

  handleChangePasswordClick() {
    this.setState({
      isChangePasswordClick: true
    });
  }

  onOrderLicenseHandler(licenseCount, optInState) {
    const {dispatch} = this.props;

    this.refs.waitDialog.open();
    dispatch(purchaseCompanyVoucher(licenseCount, optInState, this.onActionSuccessHandler, this.onActionErrorHandler));
  }

  handleOrderLicenseClick() {
    this.refs.orderLicenseDialog.open({
      buttonOrderHandler: this.onOrderLicenseHandler,
      price: this.state.adminInfo.LicensePrice,
      optInState: this.state.adminInfo.Company.optInState
    });
  }




  /**
   *
   * @returns {void}
   */
  handleTwoFaToggleEnable() {
    this.refs.waitDialog.open();
    this.openTwoFaEnableSecretDialog();
  }



  /**
   *
   * @returns {void}
   */
  handleTwoFaToggleDisable() {
    this.refs.waitDialog.open();
    this.openTwoFaDisableSecretDialog();
  }


  /**
   *
   * @returns {void}
   */
  handleShowRecoveryCodes() {
    this.refs.waitDialog.open();
    this.onTwoFaShowRecoveryCodesDialog();
  }


  handleCreateApiUserClick() {
    this.refs.waitDialog.open();
    this.props.dispatch(createApiUser(this.onCreateApiUserSuccessHandler, this.onActionErrorHandler));
  }

  onDeleteApiUserHandler() {
    const {dispatch} = this.props;
    this.refs.waitDialog.open();
    dispatch(deleteApiUser(this.onActionSuccessHandler, this.onActionErrorHandler));
  }

  handleDeleteApiUserClick() {
    this.refs.deleteApiUserDialog.open({
      buttonDeleteHandler: this.onDeleteApiUserHandler
    });
  }

  handleShowApiUserCredentialsClick() {
    this.refs.waitDialog.open();
    this.props.dispatch(getApiUserCredentials(this.onGetApiUserCredentialsSuccessHandler, this.onActionErrorHandler));
  }


  getRequiredTextfield(id, value, style, isPwdField, hint) {
    const {t} = this.props;

    return (
      <TextField type={ isPwdField ? 'password' : 'text' } id={ id } hintText={ hint } fullWidth={ true } style={ style } value={ value }
        onChange={ (event, newValue) => this.handleChangeData(id, newValue) } errorText={ this.state.errorTextFields[id] ? t('err-empty-textfield') : '' } />
      );
  }

  getLicenseCountText(count) {
    const {t} = this.props;
    if (count < 2)
    {
      return count + " " + t("settings-orderhistory-license");
    }
    else
    {
      return count + " " + t("settings-orderhistory-licenses");
    }
  }

  getAdminDetails(adminInfo) {
    const {t} = this.props;

    let adminCount = 0;
    let activeAdminCount = 0;

    // durch die Admin Infos iterieren
    if (adminInfo && adminInfo.length > 0)
    {
      for (let i = 0; i < adminInfo.length; i++)
      {
        ++adminCount;
        if ('active' === adminInfo[i].Admin.state)
        {
          ++activeAdminCount;
        }
      }
    }

    return t("settings-stellvertreter-details", {
      activeAdminCount: activeAdminCount,
      adminCount: adminCount
    });
  }

  getAdminLastLogin(admin) {
    const {t} = this.props;

    if (admin && admin.lastLogin && admin.lastLogin.length > 0)
    {
      return t("settings-stellvertreter-last-login-ok") + new Date().parseUtcDate(admin.lastLogin).getShortDateTime();
    }
    else
    {
      return t("settings-stellvertreter-last-login-never");
    }
  }

  /*
  beginDeleteCompanyClicked() {
    this.refs.confirmDeleteCompanyDialog.open(this.confirmDeleteCompanyClicked);

  }
  confirmDeleteCompanyClicked(password) {
    const {dispatch} = this.props;

    this.refs.confirmDeleteCompanyDialog.close();
    this.refs.waitDialog.open();
    dispatch(deleteCompany(password, this.onDeleteActionSuccessHandler, this.onActionErrorHandler));

  }

  onDeleteActionSuccessHandler(json) {
    window.location.assign('https://www.sims.me/business/');
  }
  */

  handleToggleCockpitToken() {
    const {dispatch} = this.props;
    this.refs.waitDialog.open();

    if (typeof this.state.companyData.mailToken === "undefined" || this.state.companyData.mailToken == null)
    {
      dispatch(createMailToken(this.onActionSuccessHandler, this.onActionErrorHandler));
    }
    else
    {
      dispatch(deleteMailToken(this.onActionSuccessHandler, this.onActionErrorHandler));
    }

  }

  handleSwitchMailtokenModeClicked() {
    const {dispatch} = this.props;
    this.refs.waitDialog.open();
    let oldMode = this.state.companyData.mailTokenMode
    let newMode = "initialMDM"
    if (oldMode === newMode)
    {
      newMode = "forceMDM"
    }
    dispatch(setMailTokenMode(newMode, this.onActionSuccessHandler, this.onActionErrorHandler));
  }

  fileContentLoaded(e) {
    let content = e.target.result;
    let base64 = content.substr(content.indexOf(',') + 1)

    this.setState({
      imageUploaded: base64,
      enableSave: true
    });
  }
  onFileLoaded(e) {
    e.preventDefault();

    let files;
    if (e.dataTransfer)
    {
      files = e.dataTransfer.files;
    }
    else if (e.target)
    {
      files = e.target.files;
    }

    if (files.length === 1)
    {
      let fileReader = new FileReader();
      fileReader.onloadend = this.fileContentLoaded;
      fileReader.readAsDataURL(files[0])
    }
  }

  handleImportFileClicked() {
    this.refs.fileInput.click();
  }


  /**
   *
   * @returns {JSX.Element}
   */
  renderHeaderView() {
    const {t} = this.props;

    return (
      <div>
        <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
          <PageHeader>
            { t("settings-settings") }
          </PageHeader>
        </div>
      </div>
    );

  }

  renderCommonButton() {
    let props = this.props;
    // sru_231117: const {t, router} = props;
    const {t} = props;

    return (<div>
              <div style={ this.getBaseStyle().fabRight }>
                <FlatButton label={ t('cancel') } style={{ ...this.getBaseStyle().cancelButton, ...styles.buttonRight }} disabled={ window.history.length < 2 } onClick={ () => this.resetState(props) } />
                <RaisedButton label={ t('save') } style={ this.getBaseStyle().fab } primary={ true } disabled={ !this.state.enableSave } onClick={ () => this.handleSaveClick() } />
              </div>
            </div>);
  }

  renderHiddenDialog() {
    const that = this,
      {t, dispatch} = this.props;

    return (<div>
              <OrderLicenseDialog ref="orderLicenseDialog" t={ t } />
              <WaitDialog ref="waitDialog" />
              <ErrorDialog ref="errorDialog" t={ t } />
              <ModalDialog ref="commonErrorDialog" />
              <ApiTwoFaEnableDialog
                ref="apiTwoFaEnableDialog"
                t={ t }
                dispatch={ dispatch }
                commonErrorHandler={ that.onActionErrorHandler }
                onTwoFaEnablePostSuccess={ that.handleTwoFaEnablePostSuccess.bind(that) }
              />
              <ApiTwoFaDisableDialog
                ref="apiTwoFaDisableDialog"
                t={ t }
                dispatch={ dispatch }
                commonErrorHandler={ that.onActionErrorHandler }
                onTwoFaDisableSuccess={ that.updateTwoFaState.bind(that, {newState: false}) }
              />
              <ApiTwoFaCreateRecoveryCodesDialog
                ref="apiCreateRecoveryCodesDialog"
                t={t}
                dispatch={ dispatch }
                onTwoFaSetRecoveryCodeSuccess={ that.updateTwoFaRecoveryCodeState.bind(that) }
                commonErrorHandler={ that.onActionErrorHandler }
              />
              <ApiTwoFaShowRecoveryCodesDialog
                ref="apiShowRecoveryCodesDialog"
                t={t}
                dispatch={ dispatch }
                commonErrorHandler={ that.onActionErrorHandler }
              />
              <ApiCredentialsDialog ref="apiCredentialsDialog" t={ t } dispatch={ dispatch } commonErrorHandler={ this.onActionErrorHandler } />
              <DeleteApiUserDialog ref="deleteApiUserDialog" t={ t } />
              <input style={ { display: 'none' } } accept=".jpg" type='file' ref='fileInput' onChange={ this.onFileLoaded } />
            </div>);
  }

  renderCompanyProfilImage() {
    const isIE = detectIE();
    if (typeof this.state.imageUploaded !== "undefined" && this.state.imageUploaded !== null)
    {
      return <Avatar size={ 80 } style={ (isIE !== false) ? {
                              backgroundSize: "cover",
                              backgroundPosition: "center center"
                            } : {
                              objectFit: 'cover'
                            } } src={ "data:image/jpeg;base64," + this.state.imageUploaded } />;
    }
    if (typeof this.state.imageExisting !== "undefined" && this.state.imageExisting !== null)
    {
      return <Avatar size={ 80 } src={ "data:image/jpeg;base64," + this.state.imageExisting } />;
    }
    return <Avatar size={ 80 } backgroundColor={ "#7B98AB" } color={ "#7B98AB" }>+</Avatar>;
  }

  renderAdminEditButton() {
    const {t} = this.props;

    if (typeof this.state.adminData !== "undefined")
    {
      if (this.state.adminData.role === "companyAdmin")
      {
        return <a href='#/admin'>
                 <RaisedButton style={ styles.buttonInForm } label={ t('settings-stellvertreter-btn-edit') } secondary={ true } />
               </a>
      }

    }
    return <div></div>
  }

  renderCockpitToken() {
    const {t} = this.props;
    let tokenDesc = <a href={ t('settings-companycockpittoken-documentation-link2') } target="_blank" rel="noopener">
                      { t('settings-companycockpittoken-documentation-link') }
                    </a>;


    if (typeof this.state.adminData !== "undefined" && typeof this.state.companyData !== "undefined")
    {

      return <div style={ styles.formBlock }>
               <div style={ styles.row }>
                 <div style={ { width: '250px', flexShrink: '0' } }>
                   <div style={ styles.text2 }>
                     { t('settings-companycockpittoken-switch-enable-label') }
                   </div>
                 </div>
                 <div style={ { marginLeft: '25px', marginRight: '25px', width: '300px', flexShrink: '0' } }>
                   <div style={ { width: '50px', float: 'right' } }>
                     <ToggleButton toggled={ this.state.companyData.mailToken !== null } onToggle={ this.handleToggleCockpitToken } thumbStyle={ { backgroundColor: 'white' } } trackStyle={ { backgroundColor: 'white' } } />
                   </div>
                 </div>
                 <div style={ { marginTop: '5px', marginBottom: '5px' } }>
                   <div style={ styles.text2 }>
                     { t('settings-companycockpittoken-switch-enable-desc') }
                   </div>
                 </div>
               </div>
               <div style={ styles.row }>
                 <div style={ { width: '250px', flexShrink: '0' } }>
                   <div style={ styles.text2 }>
                     { t('settings-companycockpittoken-token-label') }
                   </div>
                 </div>
                 <div style={ { marginLeft: '25px', marginRight: '25px', width: '300px', flexShrink: '0' } }>
                   <div style={ { float: 'right' } }>
                     <div style={ styles.text2White }>
                       { (this.state.companyData.mailToken !== null) ? this.state.companyData.mailToken : t('settings-companycockpittoken-token-notset') }
                     </div>
                   </div>
                 </div>
                 <div style={ { marginTop: '5px', marginBottom: '5px' } }>
                   <div style={ styles.text2 }>
                     <Interpolate i18nKey='settings-companycockpittoken-token-desc' v1={ tokenDesc } />
                   </div>
                 </div>
               </div>
               <div style={ styles.row }>
                 <div style={ { width: '250px', flexShrink: '0' } }>
                   <div style={ styles.text2 }>
                     { t('settings-companycockpittoken-switch-force-label') }
                   </div>
                 </div>
                 <div style={ { marginLeft: '25px', marginRight: '25px', width: '300px', flexShrink: '0' } }>
                   <div style={ { width: '50px', float: 'right' } }>
                     <ToggleButton disabled={ this.state.companyData.mailToken == null } toggled={ this.state.companyData.mailTokenMode === "forceMDM" } onToggle={ this.handleSwitchMailtokenModeClicked } thumbStyle={ { backgroundColor: 'white' } } trackStyle={ { backgroundColor: 'white' } }
                     />
                   </div>
                 </div>
                 <div style={ { marginTop: '5px', marginBottom: '5px' } }>
                   <div style={ styles.text2 }>
                     <Interpolate i18nKey='settings-companycockpittoken-switch-force-desc' v1={ tokenDesc } />
                   </div>
                 </div>
               </div>
             </div>

    }
    return <div></div>
  }

  renderLdapSync() {
    const {t} = this.props;
    let ldapDescLink = <a href={ t('settings-ldap-sync-documentation-link1') } target="_blank" rel="noopener">
                         { t('settings-ldap-sync-documentation-link-text') }
                       </a>;

    if (typeof this.state.adminData !== "undefined" && typeof this.state.companyData !== "undefined")
    {
      return (<div style={ { display: 'flex' } }>
                <div style={ styles.flexColum1 }>
                  <div style={ styles.formBlock }>
                    <div style={ Object.assign({}, styles.text2, {
                                   marginTop: '16px'
                                 }) }>
                      <div style={ Object.assign({}, styles.text2White, {
                                     marginBottom: '8px'
                                   }) }>
                        { t('settings-ldap-sync-descr-text') }
                      </div>
                      <div style={ styles.text2 }>
                        <Interpolate i18nKey='settings-ldap-sync-documentation-link-pretext' v1={ ldapDescLink } />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={ styles.flexColum2 }>
                  { (this.state.companyData.hasApiUser === false) &&
                    <div style={ styles.formBlock }>
                      <RaisedButton style={ styles.buttonInForm } label={ t('settings-ldap-sync-new-api-user-button') } secondary={ true } onTouchTap={ () => this.handleCreateApiUserClick() } />
                    </div> }
                  { (this.state.companyData.hasApiUser === true) &&
                    <div style={ styles.formBlock }>
                      <div style={ styles.text16Green }>
                        { t('settings-ldap-sync-ldap-activated') }
                      </div>
                      <div style={ styles.text2 }>
                        { t('settings-ldap-sync-ldap-activated-hint') }
                      </div>
                      <div style={ styles.row }>
                        <RaisedButton style={ styles.buttonInForm } label={ t('settings-ldap-sync-delete-api-user-button') } danger={true} onTouchTap={ () => this.handleDeleteApiUserClick() }
                        />
                        <div style={ { width: '16px' } } />
                        <RaisedButton style={ styles.buttonInForm } label={ t('settings-ldap-sync-show-api-user-credentials-button') } onTouchTap={ () => this.handleShowApiUserCredentialsClick() } />
                      </div>
                    </div> }
                </div>
              </div>);
    }

    return <div></div>
  }

  canOrderLicense(adminInfo) {
    if (typeof adminInfo === "undefined")
    {
      return false;
    }

    return IsNullOrEmpty(adminInfo.Company.salesPartnerID);
  }



  renderCompanyData( adminInfo, t ) {
    return (
      <div>
        <div style={styles.subHeaderDiv}>
          <SubPageHeader style={styles.subPageHeader}>
            {t("settings-companyinformation")}
          </SubPageHeader>
        </div>
        <div style={{display: 'flex'}}>
        <div style={styles.flexColum1}>
          <div style={styles.formBlock}>
            {this.getRequiredTextfield(COMPANY_NAME, this.state.companyData[COMPANY_NAME], null)}
            <div style={styles.row}>
              {this.getRequiredTextfield(COMPANY_STREET, this.state.companyData[COMPANY_STREET], styles.streetTextfield)}
              {this.getRequiredTextfield(COMPANY_STREET_NUMBER, this.state.companyData[COMPANY_STREET_NUMBER], styles.streetNumberTextField)}
            </div>
            <div style={styles.row}>
              {this.getRequiredTextfield(COMPANY_POSTCODE, this.state.companyData[COMPANY_POSTCODE], styles.postCodeTextField)}
              {this.getRequiredTextfield(COMPANY_CITY, this.state.companyData[COMPANY_CITY], styles.cityTextField)}
            </div>
            <TextField id='companyCountry' fullWidth={true} defaultValue={adminInfo.Company.country}
                       disabled={true}/>
            <TextField id='companyTaxId' fullWidth={true}
                       defaultValue={t('settings-company-label-ustIdNr') + " " + adminInfo.Company.taxid}
                       disabled={true}/>
            <TextField id='primaryDomain' fullWidth={true} defaultValue={adminInfo.Company.domain}
                       disabled={true}/>
          </div>
        </div>
        <div style={styles.flexColum2}>
          <div style={styles.formBlock}>
            <div style={styles.row}>
              <div style={styles.text1}>
                {t("settings-companyprofilimage")}
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.text2}>
                {t("settings-companyprofilimage-desc")}
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.text2}>
                {t("settings-companyprofilimage-desc2")}
              </div>
            </div>
            <div style={styles.row}>
              <div style={{
                marginTop: "24px",
                marginBottom: "48px",
                display: "flex",
                flexDirection: "row",
                height: "82px"
              }}>
                <div>
                  {this.renderCompanyProfilImage()}
                </div>
                <div>
                  <FlatButton label={t('settings-companyprofilimage-btnImportImage')} secondary={true}
                              onClick={this.handleImportFileClicked} style={{marginTop: "22px"}}/>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.text1}>
                {t("settings-companylanguage")}
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.text2}>
                {t("settings-companylanguage-desc")}
              </div>
            </div>
            <div style={styles.row}>
              <SelectField value={LANGUAGES.indexOf(this.state.companyLanguage)}
                           onChange={this.handleCompanyValueChangeHandler}>
                <MenuItem value={0} primaryText={t("settings-companylanguage-de")}/>
                <MenuItem value={1} primaryText={t("settings-companylanguage-el")}/>
                <MenuItem value={2} primaryText={t("settings-companylanguage-en")}/>
                <MenuItem value={3} primaryText={t("settings-companylanguage-es")}/>
                <MenuItem value={4} primaryText={t("settings-companylanguage-fr")}/>
              </SelectField>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }

  renderEmmRollout( t ) {
    return (
      <div>
        <div style={styles.subHeaderDiv}>
          <SubPageHeader style={styles.subPageHeader}>
            {t("settings-companycockpittoken")}
          </SubPageHeader>
        </div>
        { this.renderCockpitToken() }
      </div>
    );
  }


  renderGinloBusinessApi( t ) {
    return (
      <div>
        <div style={ styles.subHeaderDiv }>
          <SubPageHeader style={ styles.subPageHeader }>
            { t("settings-ldap-sync_title") }
          </SubPageHeader>
        </div>
        { this.renderLdapSync() }
      </div>
    );
  }


  renderAdministrator( adminInfo, t ) {
    return (
      <div>
        <div style={styles.subHeaderDiv}>
          <SubPageHeader style={styles.subPageHeader}>
          {t("settings-admin")}
          </SubPageHeader>
        </div>
        <div style={{display: 'flex'}}>
          <div style={styles.flexColum1}>
            <div style={styles.formBlock}>
              <div style={styles.row}>
                {this.getRequiredTextfield(ADMIN_FIRST_NAME, this.state.adminData[ADMIN_FIRST_NAME])}
                {this.getRequiredTextfield(ADMIN_NAME, this.state.adminData[ADMIN_NAME], styles.rightTextField)}
              </div>
              <TextField id='adminMail' fullWidth={true} defaultValue={adminInfo.Admin.mail} disabled={true}/>
              <TextField id='adminPhonenumber' fullWidth={true} defaultValue={adminInfo.Admin.phoneNumber}
                         disabled={true}/>
              {this.state.isChangePasswordClick &&
                <div>
                  {this.getRequiredTextfield(PWD_CURRENT, this.state.passwordData[PWD_CURRENT], styles.currentPwdTextField, true, t('settings-pwd-current-hint'))}
                  <div style={styles.currentPwdTextField}>
                    <PasswordChangeForm ref="passwordChangeForm"
                                        onChange={(name, newValue) => this.handleChangeData(name, newValue)} t={t}/>
                  </div>
                </div>}
              {!this.state.isChangePasswordClick &&
                <RaisedButton style={styles.buttonInForm} secondary={true} label={t('settings-admin-change-pwd')}
                              onTouchTap={() => this.handleChangePasswordClick()}/>}
            </div>
          </div>
          <div style={styles.flexColum2}>
            <div style={styles.formBlock}>
              <div style={styles.row}>
                <div style={styles.text1}>
                  {t("settings-stellvertreter")}
                </div>
                <div style={{width: '16px'}}/>
                <div style={styles.text2}>
                  {this.getAdminDetails(adminInfo.Company.adminInfo)}
                </div>
              </div>
              <div style={styles.row}>
                <div style={styles.formBlockTable}>
                  <Table selectable={false}>
                    <TableBody displayRowCheckbox={false} style={tableStyles.tableBodyWithoutBorder}>
                      {
                        adminInfo.Company.adminInfo && adminInfo.Company.adminInfo.map((item, i) =>
                          <TableRow key={i} value={item} displayBorder={true} style={tableStyles.tableRowWithoutBorder}>
                            <TableRowColumnText style={styles.tableText}
                                                text={item.Admin.firstName.concat(" ", item.Admin.name)}/>
                            <TableRowColumnText style={styles.tableText} text={this.getAdminLastLogin(item.Admin)}/>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div style={styles.row}>
                {this.renderAdminEditButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  /**
   *
   * @param {Function} t
   * @returns {JSX.Element}
   */
  renderTwoFaSettings( t ) {
    const that = this,
      /** @type {boolean} */
      hasTwoFactor = (this.state.adminInfo.Admin['2faenabled'] === 'true');

    return(
      <div>
        <div style={styles.subHeaderDiv}>
          <SubPageHeader style={styles.subPageHeader}>
            {t('settings-two-fa')}
          </SubPageHeader>
        </div>

        <div style={ styles.formBlock }>
          <div style={styles.row}>
            <div style={{width: '250px', flexShrink: '0'}}>
              <div style={styles.text2}>
                {t('settings-two-fa-label')}
              </div>
            </div>
            <div style={{marginLeft: '25px', marginRight: '25px', width: '300px', flexShrink: '0'}}>
              <div style={{width: '50px', float: 'right'}}>
                <ToggleButton toggled={ hasTwoFactor }
                              onToggle={(hasTwoFactor) ? that.handleTwoFaToggleDisable.bind(that) : that.handleTwoFaToggleEnable.bind(that) }
                              thumbStyle={{backgroundColor: 'white'}}
                              trackStyle={{backgroundColor: 'white'}}/>
              </div>
            </div>
            <div style={{marginTop: '5px', marginBottom: '5px'}}>
              <div style={styles.text2}>
                {(hasTwoFactor) ? t('settings-two-fa-enabled') : t('settings-two-fa-disabled')}
              </div>
            </div>
          </div>

          { hasTwoFactor &&
            <div style={styles.row}>
              <RaisedButton
                style={styles.buttonInForm}
                secondary={true}
                label={ t('settings-two-fa-btn-show-recovery-codes') }
                onTouchTap={() => this.handleShowRecoveryCodes()}/>
            </div>
          }
        </div>

      </div>
    );
  }

  /**
   *
   * @returns {void}
   */
  handleTwoFaEnablePostSuccess() {
    this.updateTwoFaState( {newState: true} );
    this.triggerCreateRecoveryCodesDialog();
  }

  /**
   *
   * @returns {void}
   */
  triggerCreateRecoveryCodesDialog() {
    this.refs.waitDialog.open();
    this.props.dispatch( this.onCreateRecoveryCodesSuccess, this.onActionErrorHandler );
  }


  /**
   *
   * @param {Object.<string, *>} param
   * @returns {void}
   */
  updateTwoFaState( param ) {
    const { newState } = param;

    this.setState((prevState) => ({
      adminInfo: {
        ...prevState.adminInfo,
        Admin: {
          ...prevState.adminInfo.Admin,
          '2faenabled': (newState)? 'true' : 'false'
        }
      }
    }));
  }


  /**
   *
   * @param {Object.<string, *>} param
   * @returns {void}
   */
  updateTwoFaRecoveryCodeState( param ) {
    const { recoveryCodes } = param;

    this.setState((prevState) => ({
      adminInfo: {
        ...prevState.adminInfo,
        Admin: {
          ...prevState.adminInfo.Admin,
          backupCodes: (recoveryCodes)? recoveryCodes : ''
        }
      }
    }));
  }


  renderOrderHistory(adminInfo, t) {
    return (
      <div>
        <div style={styles.subHeaderDiv}>
          <SubPageHeader style={styles.subPageHeader}>
            {t("settings-orderhistory")}
          </SubPageHeader>
        </div>
        <div>
          {typeof adminInfo !== "undefined" &&
            <div>
              <Table selectable={false}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false} style={tableStyles.tableHeader}>
                  <TableRow style={tableStyles.tableHeaderRowWithoutBackground}>
                    <th width="7px"/>
                    <TableHeaderColumnSort index={1} sortColumn={this.state.sortColumn}
                                           sortDirection={this.state.sortDirection} style={styles.tableColumnDate}
                                           text={t('settings-orderhistory-table-column-date')}
                                           onClickCallback={this.handleSortTableButtonClicked.bind(this)}/>
                    <TableHeaderColumnSort index={2} sortColumn={this.state.sortColumn}
                                           sortDirection={this.state.sortDirection}
                                           text={t('modules-license-License-table-column-count')}
                                           onClickCallback={this.handleSortTableButtonClicked.bind(this)}
                    />
                    <TableHeaderColumnDefault/>
                    <TableHeaderColumnDefault/>
                    <TableHeaderColumnDefault/>
                    <TableHeaderColumnDefault/>
                  </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} style={tableStyles.tableBody}>
                  {adminInfo.voucherGroup && adminInfo.voucherGroup.map((item, i) => <TableRow key={i} value={item}
                                                                                               displayBorder={true}
                                                                                               style={tableStyles.tableRow}>
                      <td width="7px" height="40px"/>
                      <TableRowColumnText style={styles.tableColumnDate}
                                          text={new Date().parseUtcDate(item.VoucherGroup.purchaseDate).getShortDate()}/>
                      <TableRowColumnText style={styles.tableColumnDate}
                                          text={this.getLicenseCountText(item.VoucherGroup.voucherCount)}/>
                      <TableRowColumnText/>
                      <TableRowColumnText/>
                      <TableRowColumnText/>
                      <TableRowColumnText/>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>}
          {this.canOrderLicense(adminInfo) &&
            <div style={styles.rowTableButton}>
              <RaisedButton style={styles.buttonInForm} label={t('modules-license-License-btn-new')} secondary={true}
                            onTouchTap={() => this.handleOrderLicenseClick()}/>
            </div>}
        </div>
      </div>
    );
  }

  renderMainView() {
    // sru_231117: const {t, onSaveSettingsClick, router} = this.props;
    const {t} = this.props;
    const adminInfo = this.state.adminInfo;
    const hasAdminInfo = (typeof adminInfo !== 'undefined');

    return (
      <div style={styles.root}>
        { hasAdminInfo && this.renderCompanyData( adminInfo, t ) }
        { hasAdminInfo && this.renderEmmRollout( t ) }
        { hasAdminInfo && this.renderGinloBusinessApi( t ) }
        { hasAdminInfo && this.renderAdministrator( adminInfo, t ) }
        { hasAdminInfo && this.renderTwoFaSettings( t ) }
        { this.renderOrderHistory( adminInfo, t ) }
      </div>
    );

  }

}

Settings.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  // sru_231117: const {session, userList} = state;
  const {userList} = state;

  return {
    userList: userList
  }

}

const SettingsTranslated = translate(['common'])(Settings);

export default connect(mapStateToProps)(SettingsTranslated);
