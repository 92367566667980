import React, { Component } from 'react';
import { TableRow, TableRowColumn, } from 'material-ui/Table';

import TextField from '../../components/textfields/TextField';
import Avatar from 'material-ui/Avatar';
// import SelectField from 'material-ui/SelectField';
// import MenuItem from 'material-ui/MenuItem';
import { translate } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { hexToRgb, rgbToHex, expandShorthandHex } from '../../util/ColorUtil';
const styles = {
  label: {
    color: '#7B98AB',
    fontSize: '14px',
    fontWeight: 'regular',
    textAlign: 'left',
    display: 'inline-block',
  },
  colorFields: {
    width: '180px',
  },
  colorButton: {
    display: 'inline-block',
    height: '48px',
  },
  rgbTextfield: {
    width: '50px',
    marginRight: '10px'
  },
  colorPreview: {
    marginLeft: '30px',
  },
  ColorChooserTableRowStyle: {
    margin: 0,
    padding: 0,
    borderBottom: 'none',
  },
  labelColumn: {
    width: '240px',
  },
  colorColumn: {
    width: '268px',

  },
  constrastColumn: {
    width: '268px',
  },
  showContrast: {
    width: "120px",
    height: '48px',
    display: 'inline-block',
  },
  hideContrast: {
    display: 'none',
  },
  popover: {
    position: 'fixed',
    zIndex: '2',
    top: '150px',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}


export class ColorPicker extends Component {
  state = {
    mode: 'HEX',
    color: '',
    contrast: '',
    contrastSelectable: false,
    displayColorPicker: false,
    displayContrastPicker: false,
  }

  constructor(props) {
    super(props);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleContrastChange = this.handleContrastChange.bind(this);

    this.handleColorPickerClick = this.handleColorPickerClick.bind(this);
    this.handleColorPickerCloseClick = this.handleColorPickerCloseClick.bind(this);
    this.handleColorPickerChangeComplete = this.handleColorPickerChangeComplete.bind(this);

    this.handleContrastPickerClick = this.handleContrastPickerClick.bind(this);
    this.handleContrastPickerCloseClick = this.handleContrastPickerCloseClick.bind(this);
    this.handleContrastPickerChangeComplete = this.handleContrastPickerChangeComplete.bind(this);
    //window.onmousemove = this.onMouseMove.bind(this);

  }

  onMouseMove(e) {
    if (this.state.displayColorPicker === false && this.state.displayContrastPicker === false)
    {
      var y = e.clientY;
      var w = window.innerHeight - 300;
      if (y < w)
      {
        styles.popover.top = (y + 20) + 'px';
      }
      else
      {
        styles.popover.bottom = '0px';
      }
    }
  }

  updateState(props) {
    this.setState({
      mode: props.mode,
      color: props.color,
      contrast: props.contrastColor,
      contrastSelectable: props.contrastSelectable
    });
  }

  componentDidMount() {
    //'HEX', 'RGB'
    this.updateState(this.props);
  }

  componentWillMount() {
    //Force init state!
    this.updateState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  handleColorChange(newColor) {
    var colorHex = newColor;
    this.setState({
      color: colorHex
    });
    if (this.props.onColorChanged != null)
    {
      this.props.onColorChanged(colorHex);
    }
  }

  handleContrastChange(newContrast) {
    this.setState({
      contrast: newContrast
    });
    if (this.props.onContrastChanged != null)
    {
      this.props.onContrastChanged(newContrast);
    }
  }
  handleColorPickerClick(event) {
    var y = event.clientY;
    var w = window.innerHeight - 300;
    if (y < w)
    {
      styles.popover.top = (y + 20) + 'px';
    }
    else
    {
      styles.popover.bottom = '0px';
    }
    this.setState({
      displayColorPicker: !this.state.displayColorPicker
    });
  }

  handleColorPickerCloseClick() {
    this.setState({
      displayColorPicker: false
    });
  }

  handleColorPickerChangeComplete(newColor, event) {
    this.handleColorChange(newColor.hex);
  }

  handleContrastPickerClick(event) {
    var y = event.clientY;
    var w = window.innerHeight - 300;
    if (y < w)
    {
      styles.popover.top = (y + 20) + 'px';
    }
    else
    {
      styles.popover.bottom = '0px';
    }

    this.setState({
      displayContrastPicker: !this.state.displayContrastPicker
    });
  }

  handleContrastPickerCloseClick() {
    this.setState({
      displayContrastPicker: false
    });
  }

  handleContrastPickerChangeComplete(newColor, event) {
    this.handleContrastChange(newColor.hex);
  }

  render() {
    // const {t} = this.props;
    return (
      <TableRow displayBorder={ false } style={ styles.ColorChooserTableRowStyle }>
        <TableRowColumn style={ styles.labelColumn }>
          <span style={ styles.label }>
                                                                                                                { this.props.label }
                                                                                                              </span>
        </TableRowColumn>
        <TableRowColumn style={ styles.colorColumn }>
          <ColorField currentcolor={ this.state.color } mode={ this.state.mode } colorChangeHandler={ this.handleColorChange } />
          <Avatar color={ expandShorthandHex(this.state.color) } backgroundColor={ expandShorthandHex(this.state.color) } size={ 30 } style={ styles.colorPreview } onClick={ this.handleColorPickerClick } />
          { this.state.displayColorPicker ? <div style={ styles.popover }>
                                              <div style={ styles.cover } onClick={ this.handleColorPickerCloseClick } />
                                              <SketchPicker color={ expandShorthandHex(this.state.color) } onChangeComplete={ this.handleColorPickerChangeComplete } disableAlpha={ true } /> </div> : null }
        </TableRowColumn>
        <TableRowColumn style={ styles.constrastColumn }>
          <ColorField currentcolor={ this.state.contrast } mode={ this.state.mode } colorChangeHandler={ this.handleContrastChange } />
          <Avatar color={ expandShorthandHex(this.state.contrast) } backgroundColor={ expandShorthandHex(this.state.contrast) } size={ 30 } style={ styles.colorPreview } onClick={ this.handleContrastPickerClick } />
          { this.state.displayContrastPicker ? <div style={ styles.popover }>
                                                 <div style={ styles.cover } onClick={ this.handleContrastPickerCloseClick } />
                                                 <SketchPicker color={ expandShorthandHex(this.state.contrast) } onChangeComplete={ this.handleContrastPickerChangeComplete } disableAlpha={ true } /> </div> : null }
        </TableRowColumn>
      </TableRow>
      );
  }
}
export default translate(['common'])(ColorPicker);

function ColorField({currentcolor, mode, colorChangeHandler}) {
  const isRgbMode = mode === 'RGB';
  if (isRgbMode === true)
  {
    return <RgbField color={ currentcolor } onColorChanged={ colorChangeHandler } />
  }
  else
  {
    return <HexField color={ currentcolor } onColorChanged={ colorChangeHandler } />
  }
}



class HexField extends Component {
  state = {
    currentColor: '#FFFFFF',
  }

  constructor(props) {
    super(props);
    //console.warn('hexconstr' + this.props.color);
    this.handleChange = this.handleChange.bind(this);
  }

  update(props) {
    const c = props.color;
    this.setState({
      currentColor: c
    });
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  handleChange(event, newValue) {
    //http://stackoverflow.com/questions/14010515/javascript-remove-everything-from-string-but-hex
    var newTrimmedValue = newValue.substring(0, 7);
    let newHexValue = "#";
    if (newTrimmedValue !== null & newTrimmedValue.length > 1)
    {
      newHexValue = "#" + newTrimmedValue.replace(/[^A-Fa-f0-9]/g, "");
    }
    ;
    if (newHexValue !== null | newHexValue !== '')
    {
      this.setState({
        currentColor: newHexValue
      });
      if (this.props.onColorChanged != null)
      {
        this.props.onColorChanged(newHexValue);
      }
    }
  }

  render() {
    return (
      <TextField style={ styles.colorFields } id='hex' hintText="Enter Hex-Color" value={ this.state.currentColor } onChange={ this.handleChange } />
      );
  }
}

class RgbField extends Component {
  state = {
    rgbColor: {
      r: -1,
      g: -1,
      b: -1,
    },
  }

  constructor(props) {
    super(props);
    this.handleRChange = this.handleRChange.bind(this);
    this.handleGChange = this.handleGChange.bind(this);
    this.handleBChange = this.handleBChange.bind(this);
  }

  update(props) {
    var hexColor = props.color;
    const rgb = hexToRgb(hexColor);
    this.setState({
      rgbColor: rgb
    });
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.update(nextProps);
  }

  parseColor(newColor) {
    var colorIntValue = 0;
    //http://stackoverflow.com/questions/1862130/strip-non-numeric-characters-from-string
    var colorNumericString = newColor.replace(/\D/g, '');
    if (colorNumericString !== null & colorNumericString !== '')
    {
      colorIntValue = parseInt(colorNumericString, 10);
    }
    return colorIntValue;
  }

  handleRChange(event, newRColor) {
    var colorIntValue = this.parseColor(newRColor);
    if (colorIntValue !== 'NaN')
    {
      var currentRgb = this.state.rgbColor;
      currentRgb.r = colorIntValue;
      this.setState({
        rgbColor: currentRgb
      });
      this.notifyColorChange();
    }
  }
  handleGChange(event, newGColor) {
    var colorIntValue = this.parseColor(newGColor);
    if (colorIntValue !== 'NaN')
    {
      var currentRgb = this.state.rgbColor;
      currentRgb.g = colorIntValue;
      this.setState({
        rgbColor: currentRgb
      });
      this.notifyColorChange();
    }
  }
  handleBChange(event, newBColor) {
    var colorIntValue = this.parseColor(newBColor);
    if (colorIntValue !== 'NaN')
    {
      var currentRgb = this.state.rgbColor;
      currentRgb.b = colorIntValue;
      this.setState({
        rgbColor: currentRgb
      });
      this.notifyColorChange();
    }
  }

  notifyColorChange() {
    if (this.props.onColorChanged != null)
    {
      const currentColor = this.state.rgbColor;
      const colorHexValue = rgbToHex(currentColor.r, currentColor.g, currentColor.b);
      this.props.onColorChanged(colorHexValue);
    }
  }

  render() {
    return (
      <span style={ styles.colorFields }>
                                                                                                              <TextField id='rgb-r' value={ this.state.rgbColor.r } style={ styles.rgbTextfield } onChange={ this.handleRChange }/>
                                                                                                              <TextField id='rgb-g' value={ this.state.rgbColor.g } style={ styles.rgbTextfield } onChange={ this.handleGChange }/>
                                                                                                              <TextField id='rgb-b' value={ this.state.rgbColor.b } style={ styles.rgbTextfield } onChange={ this.handleBChange }/>
                                                                                                          </span>);
  }
}
