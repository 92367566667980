import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Attachment from 'material-ui/svg-icons/file/attachment';
import AudioIcon from 'material-ui/svg-icons/av/play-circle-outline';
import IconDelete from '../../ressources/icon_delete_red';
import IconButton from 'material-ui/IconButton';
import { IsNullOrEmpty } from '../StringExtensions';
require('../DateExtensions');
require('../StringExtensions');

const MessageTypeEnum = {
  TEXT: "text",
  PICTURE: "picture",
  ATTACHMENT: "attachment",
  VIDEO: "video",
  AUDIO: "audio",
  LOCATION: "location",
  RSS: "rss",
}

const styles = {
  maxPicWidth: {
    maxWidth: '200px',
  },
  maxPicHeight: {
    maxHeight: '200px',
  },
  messagePicture: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '-100%',
    right: '-100%',
    margin: 'auto',
  },
  pictureViewBox: {
    overflow: 'hidden',
    objectFit: 'cover',
    maxHeight: '200px',
    height: '200px',
    maxWidth: '200px',
    width: '200px',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    position: 'relative',
  },
  roundTopCorners: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  roundBottomCorners: {
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  messageTextField: {
    background: '#FFFFFF',
    color: '#7B98AB',
    minWidth: '320px',
    maxWidth: '320px',
    minHeight: '16px',
    wordWrap: "break-word",
    verticalAlign: 'middle',
    padding: 8,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    fontSize: '14px',
  },
  timeStamp: {
    fontSize: '12px',
    color: '#7B98AB',
    marginLeft: 10,
    alignSelf: 'flex-end',
  },
  messageDeleteButton: {
    margin: 0,
    padding: 0,
    width: '14px',
    height: '14px',
  },
  deleteIconStyle: {
    width: '14px',
    height: '14px',
  },
  messageDiv: {
    flex: '1 1 auto',
    border: '1px',
    borderColor: "#00375D",
    backgroundColor: "#FFFFFF",
    padding: '10px',
    borderRadius: '5px',
    maxWidth: '330px'
  },
  messageDivImage: {
    flex: '1 1 auto',
    border: '1px',
    borderColor: "#00375D00",
    backgroundColor: "#00375D00",
    maxWidth: '200px'
  },

}

class ChatMessagePreview extends Component {
  state = {
    message: {
      messageText: '',
      sendDate: '',
      sendTime: '',
      picture: '',
      attachment: '',
      location: '',
      rssMessage: null
    },
  }
  constructor(props) {
    super(props);
    this.handleMessageDelete = this.handleMessageDelete.bind(this);
    this.handleOpenAttachment = this.handleOpenAttachment.bind(this);
    this.imageLoad = this.imageLoad.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      message: nextProps
    });
  }

  isPictureValid() {
    let cl = String(this.state.message.picture);
    if (cl === "undefined" || cl === "" || cl.split(',')[1] === "null")
    {
      return false;
    }
    return true;
  }

  isVideoValid() {
    let cl = String(this.state.message.video);
    if (cl === "undefined" || cl === "" || cl === "false")
    {
      return false;
    }
    return true;
  }

  isAudioValid() {
    let cl = String(this.state.message.audio);
    if (cl === "undefined" || cl === "" || cl === "false")
    {
      return false;
    }
    else return true;
  }

  isLocationValid() {
    let cl = String(this.state.message.location);
    if (cl === "undefined" || cl === "" || cl === "false")
    {
      return false;
    }
    else return true;
  }

  isRssValid() {
    let rssMessage = this.state.message.rssMessage;

    if (rssMessage === null || typeof rssMessage === 'undefined' || typeof rssMessage.title === "undefined")
    {
      return false;
    }
    else return true;
  }

  imageLoad(width, height) {
    //Correct Image-Preview according to Landscape.-Orientation
    //we have to do that this way because of:
    //https://stackoverflow.com/questions/9994493/scale-image-to-fit-a-bounding-box
    if (height < width)
    {
      this.refs.preview.style.maxWidth = '';
      this.refs.preview.style.maxHeight = '200px';
    }
    else
    {
      this.refs.preview.style.maxWidth = '200px';
      this.refs.preview.style.maxHeight = '';
    }
  }

  renderPicture(isRoundBottomCorners) {
    if (this.isPictureValid())
    {
      let cl = String(this.state.message.picture)
      var image = new Image();
      image.src = this.state.message.picture;
      let self = this;
      image.onload = function() {
        self.imageLoad(image.width, image.height);
      }
      return (
        <div style={ styles.pictureViewBox }>
          <img ref="preview" alt="" style={ isRoundBottomCorners ? Object.assign({}, styles.messagePicture, styles.roundBottomCorners, styles.roundTopCorners, styles.maxPicWidth) : Object.assign({}, styles.messagePicture, styles.roundTopCorners, styles.maxPicWidth) } src={ cl } />
        </div>
        );
    }
    return <div></div>;
  }

  renderVideo() {
    if (this.isVideoValid())
    {
      let cl = String(this.state.message.video)
      return (
        <div style={ styles.pictureViewBox }>
          <video style={ styles.messagePicture } src={ cl } type="video/mpeg" />
        </div>
        );
    }
    return <div></div>
  }

  isAttachmentInfoValid() {
    if (typeof this.state.message.attachmentInfo === 'undefined' || this.state.message.attachmentInfo === '')
    {
      return false;
    }
    return true;
  }

  isFileAttachment() {
    return this.isAttachmentInfoValid() && !this.isPictureValid();
  }

  handleOpenAttachment() {
    if (this.props.ShowMessagePreview === false)
    {
      //TODO: console.log("fetch message from the internetz");
    }
    else
    {
      let csvURL = window.URL.createObjectURL(this.state.message.attachmentInfo);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', this.state.message.attachmentInfo.name);
      tempLink.click();
    }
  }

  renderAttachment() {
    if (this.isFileAttachment())
    {
      return (
        <div>
          <Attachment color='#00375D' style={ { float: 'left' } } onClick={ this.handleOpenAttachment } />
          <div style={ { marginLeft: 40 } }>
            <div>
              { this.state.message.attachmentInfo.name }
            </div>
            <div style={ { fontSize: '10px', margin: 0, padding: 0 } }>
              { getReadableFileSizeString(this.state.message.attachmentInfo.size) }
            </div>
          </div>
        </div>
      )
    }
  }

  handleMessageDelete() {
    if (typeof this.props.onDeleteMessage !== 'undefined')
    {
      this.props.onDeleteMessage();
    }
  }

  isEmptyMessage() {
    return (this.props.ShowMessagePreview === false
      && (IsNullOrEmpty(this.state.message.messageText) ))
  }

  //renders Multi-Line-Text
  renderMessageLines(message) {
    //inspired by
    //https://stackoverflow.com/questions/35351706/how-to-render-a-multi-line-text-string-in-react
    //https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
    // const {t} = this.props;

    let lines = message.split(/(?:\r\n|\r|\n)/g);

    return (
      <span>
        { lines.map((i, key) => {
          return <span key={ key }>{ i } <br /></span>
        }) }
      </span>
    )
  }

  getMessageText() {
    const {t} = this.props;
    return (!IsNullOrEmpty(this.state.message.messageText))
      ? this.renderMessageLines(this.state.message.messageText)
      : t("components-chatmessagetextfield-default-hint");
  }

  getRssMessageText() {
    const {t} = this.props;

    let rssMessage = this.state.message.rssMessage;

    let renderTitle = false;
    let renderText = false;
    let renderLink = false;

    if (typeof rssMessage.title !== 'undefined')
    {
      if (rssMessage.title.length > 0)
      {
        renderTitle = true;
      }
      if (typeof rssMessage.text !== 'undefined' && rssMessage.text.length > 0)
      {
        renderText = true;
      }
      if (typeof rssMessage.link !== 'undefined' && rssMessage.link.length > 0)
      {
        renderLink = true;
      }
    }

    return (
      <span>
                                             { renderTitle && <span><b>{ rssMessage.title }</b></span> }
                                             { renderText && <span><br/><br/>{ this.renderMessageLines(rssMessage.text) }</span> }
                                             { renderLink && <span><br/><br/><a href={ rssMessage.link } target="_blank">{ t("components-messageeditor-rss-linkmore") }</a><br/></span> }
                                          </span>
      );
  }

  renderTextMessage() {
    const {t} = this.props;

    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          { this.getMessageText() }
        </div>
      </MessageFrame>)
  }

  renderRssMessage() {
    const {t} = this.props;

    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          { this.getRssMessageText() }
        </div>
      </MessageFrame>)
  }

  renderAttachmentMessage() {
    const {t} = this.props;

    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          { this.renderAttachment() }
        </div>
      </MessageFrame>
    )
  }

  renderPictureMessage() {
    const {t} = this.props;

    let isRoundBottomCorners = this.isEmptyMessage();
    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } hasImage={ isRoundBottomCorners } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate }
        sendTime={ this.state.message.sendTime }>
        <div style={ { minWidth: '236px' } }>
          { this.renderPicture(isRoundBottomCorners) }
        </div>
        <div style={ this.isEmptyMessage() ? {
                       display: 'none'
                     } : {
                       clear: 'both'
                     } }>
          <div style={ styles.messageTextField }>
            { this.getMessageText() }
          </div>
        </div>
      </MessageFrame>
    )
  }

  renderVideoMessage() {
    const {t} = this.props;
    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          <AudioIcon color='#00375D' style={ { float: 'left' } } />
          <div style={ { marginLeft: 40 } }>
            <div>
              { t("components-messageeditor-type-video") }
            </div>
            <div style={ Object.assign({}, { fontSize: '10px'} , {margin: 0}, {padding: 0 }, this.isEmptyMessage() ? {display: 'none'} : {clear: 'both'} )}>
              {
                  this.renderMessageLines(this.state.message.messageText)
              }
            </div>
          </div>
        </div>
      </MessageFrame>
    )
  }

  renderAudioMessage() {
    const {t} = this.props;
    //Nur schauen, nicht anfassen (anhären) ;)
    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          <AudioIcon color='#00375D' style={ { float: 'left' } } />
          <div style={ { marginLeft: 40 } }>
            <div>
              { t("components-messageeditor-type-audio") }
            </div>
            <div style={ { fontSize: '10px', margin: 0, padding: 0 } }>
              { "" }
            </div>
          </div>
        </div>
      </MessageFrame>
    )
  }

  renderLocationMessage() {
    const {t} = this.props;
    return (
      <MessageFrame t={ t } HasDeleteButton={ this.props.HasDeleteButton } onMessageDelete={ this.handleMessageDelete } sendDate={ this.state.message.sendDate } sendTime={ this.state.message.sendTime }>
        <div style={ Object.assign({}, styles.roundTopCorners, styles.messageTextField) }>
          <div>
            <div>
              { t("components-messageeditor-type-location") }
            </div>
            <div style={ { fontSize: '10px', margin: 0, padding: 0 } }>
              { "" }
            </div>
          </div>
        </div>
      </MessageFrame>
    )
  }


  geCurrenttMessageType() {
    //TODO: refactor und MessageBody.type auswerten!
    if (this.isPictureValid()) return MessageTypeEnum.PICTURE; //beinhaltet erstmal auch Location, da wir nur das Vorschaubild zeigen...
    if (this.isAttachmentInfoValid()) return MessageTypeEnum.ATTACHMENT;
    if (this.isVideoValid()) return MessageTypeEnum.VIDEO;
    if (this.isAudioValid()) return MessageTypeEnum.AUDIO;
    if (this.isLocationValid()) return MessageTypeEnum.LOCATION;
    if (this.isRssValid()) return MessageTypeEnum.RSS;
    return MessageTypeEnum.TEXT;
  }

  renderMessage() {
    let messageType = this.geCurrenttMessageType();
    switch (messageType) {
      case MessageTypeEnum.TEXT:
        return this.renderTextMessage();
      case MessageTypeEnum.PICTURE:
        return this.renderPictureMessage();
      case MessageTypeEnum.ATTACHMENT:
        return this.renderAttachmentMessage();
      case MessageTypeEnum.VIDEO:
        return this.renderVideoMessage();
      case MessageTypeEnum.AUDIO:
        return this.renderAudioMessage();
      case MessageTypeEnum.LOCATION:
        return this.renderLocationMessage();
      case MessageTypeEnum.RSS:
        return this.renderRssMessage();
      default:
        throw new Error("MessageType not supported!")
    }

  }

  render() {
    // const {t} = this.props;
    let messageView = this.renderMessage();
    return messageView;

  }
}
export default translate(['common'])(ChatMessagePreview);

//https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
//TODO: make this global!
function getReadableFileSizeString(fileSizeInBytes) {

  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i+=1;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}
;

class MessageFrame extends Component {
  constructor(props) {
    super(props);
    this.handleMessageDelete = this.handleMessageDelete.bind(this);
  }
  handleMessageDelete() {
    if (typeof this.props.onMessageDelete === 'function')
    {
      this.props.onMessageDelete();
    }
  }

  render() {
    const {t} = this.props;

    var shortDate = t("components-chatmessagetextfield-preview-time-placeholder");
    if (this.props.sendDate !== '')
    {
      shortDate = new Date().parseUtcDate(this.props.sendDate).getShortDate();
    }
    var shortTime = '';
    if (this.props.sendTime !== '')
    {
      shortTime = ', ' + new Date().parseUtcDate(this.props.sendTime).getShortTime();
    }
    var deleteMessageButton = (this.props.HasDeleteButton === true) ?
      <div style={ { margin: 0, padding: 0, height: '27px', display: 'block' } }>
        <IconButton onClick={ this.handleMessageDelete } style={ styles.messageDeleteButton }>
          <IconDelete style={ styles.deleteIconStyle } />
        </IconButton>
      </div> :
      null;
    return (
      <div style={ { display: 'flex', position: 'relative' } }>
        <div style={ (this.props.hasImage === true) ? styles.messageDivImage : styles.messageDiv }>
          { this.props.children }
        </div>
        <div style={ styles.timeStamp }>
          { deleteMessageButton }
          { shortDate }
          { shortTime }
        </div>
      </div>
    )
  }
}
