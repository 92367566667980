import { combineReducers } from 'redux';
import { REQUEST_LOGIN, RECEIVE_LOGIN, REQUEST_LOGOUT, RECEIVE_LOGOUT, RECEIVE_RESET_PWD, REQUEST_RESET_PWD, REQUEST_USER_LIST, RECEIVE_USER_LIST } from '../actions/index';
import { statistics, statisticsGroup, statisticsGroupDetails, statisticsGroupChannel, statisticsGroupChannelDetails } from './reducerDashboard';
import { listUsedVoucher, listFreeVoucher } from './reducerVoucher.js'

const STORED_SESSION = "STORED_SESSION";
const INIT = "@@redux/INIT";
const UNKNOWN = "@@redux/PROBE_UNKNOWN_ACTION"
function session(state = {
    sessionId: '',
    crsftoken: '',
    isLoggedIn: false
  }, action) {
  if (action.type.indexOf(UNKNOWN) !== -1)
  {
    //Hole die Session zurück, falls Browser-Refresh betätigt wurde
    let s = JSON.parse(window.sessionStorage.getItem(STORED_SESSION));
    if (s)
    {
      return s
    }
    else
    {
      //es gab oder gibt kein gültiges Login mehr
      return Object.assign({}, state, {
        sessionId: '',
        csrftoken: '',
        isLoggedIn: false
      });
    }
  }
  switch (action.type) {
    case RECEIVE_LOGOUT:
      let s = Object.assign({}, state, {
        sessionId: '',
        csrftoken: '',
        isLoggedIn: false
      });
      window.sessionStorage.removeItem(STORED_SESSION);
      return s;
    case INIT: {
      //Hole die Session zurück, falls Browser-Refresh betätigt wurde
      let s = JSON.parse(window.sessionStorage.getItem(STORED_SESSION));
      if (s)
      {
        return s
      }
      else
      {
        //es gab oder gibt kein gültiges Login mehr
        return Object.assign({}, state, {
          sessionId: '',
          csrftoken: '',
          isLoggedIn: false
        });
      }
    }
    default: {
      if (!isReduxAction(action) && !isRequestAction(action))
      {
        if (action.result)
        {
          let sessionId = action.result['sessionId'];
          let csrf = action.result['csrftoken'];
          var csrfBase64 = '';

          if (csrf)
          {
            csrfBase64 = "".concat(csrf).replace(/\n/g, "")

          }
          if (sessionId == null)
          {
            let s = Object.assign({}, state, {
              csrftoken: csrfBase64,
              isLoggedIn: csrfBase64 ? true : false
            });
            window.sessionStorage.setItem(STORED_SESSION, JSON.stringify(s));
            return s;
          }
          let s = Object.assign({}, state, {
            sessionId: sessionId,
            csrftoken: csrfBase64,
            isLoggedIn: csrfBase64 ? true : false
          });
          window.sessionStorage.setItem(STORED_SESSION, JSON.stringify(s))
          return s;
        }
        else
        {
          window.sessionStorage.setItem(STORED_SESSION, JSON.stringify(state))
          return state;
        }
      }
      else
      {
        window.sessionStorage.setItem(STORED_SESSION, JSON.stringify(state))
        return state;
      }
    }
  }
}

function loginAction(state = {
    isFetching: false,
    loginError: {}
  }, action) {

  switch (action.type) {
    case REQUEST_LOGIN: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case RECEIVE_LOGIN:
    case RECEIVE_RESET_PWD: {
      return Object.assign({}, state, {
        isFetching: false,
        loginError: action.error,
      });
    }
    case REQUEST_LOGOUT:
    case REQUEST_RESET_PWD: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case RECEIVE_LOGOUT: {
      return Object.assign({}, state, {
        isFetching: false,
        loginError: action.error,
      });
    }
    default:
      return state;
  }
}

function userList(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_USER_LIST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case RECEIVE_USER_LIST: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: action.result["data"],
        hasData: true
      });
    }
    default:
      return state;

  }
}

function isReduxAction(action) {
  // rule out the @@redux/INIT action
  return action.type.indexOf('@@redux/') === 0;
}

function isRequestAction(action) {
  return action.type.indexOf('REQUEST_') === 0;
}

const rootReducer = combineReducers({
  session,
  loginAction,
  userList,
  statistics,
  statisticsGroup,
  statisticsGroupDetails,
  statisticsGroupChannel,
  statisticsGroupChannelDetails,
  listUsedVoucher,
  listFreeVoucher
})

/*const rootReducer = (state, action) => {
  if (action.type === RECEIVE_LOGOUT)
  {
    state = undefined
  }
  return appReducer;
}*/

export default rootReducer
