import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';


import RaisedButton from './components/buttons/RaisedButton';
import Logo from './ressources/Login_Logo.svg';
import { ModalDialog } from './components/ModalDialog';
import PasswordChangeForm from './components/forms/PasswordChangeForm.js';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Header from './Header';
import Footer from './Footer';
import { WaitDialog } from './components/WaitDialog'
import { getAdminMuiTheme } from './components/styles'

import { resetPassword, isRecoveryPasswordValid } from './actions/index';


const styles = {
  root: {
    width: '100%'
  },
  parent: {
    width: '405px',
    display: 'table',
    margin: '0 auto',
    marginTop: '20px'
  },
  image: {
    flex: '1 1 auto',
    padding: '10px',
    textAlign: 'center'
  },
  logo: {
    height: '108px',
    marginTop: '54px'
  },
  buttonRow: {
    marginTop: '16px',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  buttonRowLeft: {
    textAlign: 'left',
    flex: '1'
  },
  buttonRowRight: {
    textAlign: 'right',
    flex: '1'
  },
  button: {

  },
  buttonLabel: {
    fontSize: '14px'
  },
  textField: {
    width: '100%',
    fontSize: '16px'
  },
  label: {
    fontSize: '20px'
  },
  placeholder: {
    height: '20px'
  },
  textfield: {
    fontSize: '14px',
    marginTop: '8px'
  },

  iconTextComb: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  iconLeft: {
    marginRight: '20px'
  },
  iconText: {
    fontSize: '14px',
    color: '#FFFFFF'
  },
  flatButtonLink: {
    margin: 0,
    padding: 0
  },
  checkbox: {
    marginTop: '8px'
  },
  checkboxLabel: {
    fontSize: '14px'
  }

}



class InitialLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        pwd: '',
        changed: false
      },
      dataSetPwd: {
        recoveryPwd: '',
        newPwd: '',
        repeatPwd: ''
      },
      errors: {
        recoveryPwd: '',
        newPwd: '',
        repeatPwd: '',
        email: ''
      },
      pwdHasMinLength: false,
      pwdHasUpperCase: false,
      pwdHasSpezChar: false
    };

    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleSetPasswordClick = this.handleSetPasswordClick.bind(this);
    this.resetPasswordSuccessHandler = this.resetPasswordSuccessHandler.bind(this);
    this.resetPasswordErrorHandler = this.resetPasswordErrorHandler.bind(this);
    this.isRecoveryPasswordValidSuccessHandler = this.isRecoveryPasswordValidSuccessHandler.bind(this);
  }

  handleChangeData(name, newValue) {
    if (name === 'pwd' || name === 'email')
    {
      let oldData = JSON.parse(JSON.stringify(this.state.data))
      oldData[name] = newValue;
      oldData['changed'] = true;
      this.setState({
        data: oldData
      });
      return;
    }

    if (name === 'newPwd')
    {
      let oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
      oldData['newPwd'] = newValue;

      this.setState({
        dataSetPwd: oldData
      });
    }

    if (name === 'recoveryPwd')
    {
      let oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
      oldData['recoveryPwd'] = newValue;

      this.setState({
        dataSetPwd: oldData
      });
    }

    if (name === 'repeatPwd')
    {
      let oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
      oldData['repeatPwd'] = newValue;

      this.setState({
        dataSetPwd: oldData
      });
    }
  }



  resetPasswordSuccessHandler(json) {

    this.refs.waitDialog.close();
    let sessionId = json["sessionId"];
    if (typeof sessionId === "string")
    {
      const {router} = this.props;
      router.push("/");
    }

  }

  resetPasswordErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    const {t} = this.props;

    let errorCode = "SERVICE-" + errorJO.ident;
    let errorText = t(errorCode);
    this.refs.modalDialog.open({
      text: errorText,
      button1Text: t("components-ModalDialog-ok")
    })
  }


  handleSetPasswordClick() {

    let newPasswordValidationSuccess = this.refs.passwordChangeForm && this.refs.passwordChangeForm.validatePassword();

    let errors = JSON.parse(JSON.stringify(this.state.errors));

    let recoveryPwd = this.state.data.pwd;
    if (!recoveryPwd || recoveryPwd.length < 1)
    {
      errors['recoveryPwd'] = 'login-forgot-pwd-err-empty';
    }
    else
    {
      errors['recoveryPwd'] = '';
    }

    let email = this.state.data.user;
    if (!email || email.length < 1)
    {
      errors['email'] = 'login-forgot-pwd-err-empty';
    }
    else
    {
      errors['email'] = '';
    }

    this.setState({
      errors: errors
    });

    if (!errors['recoveryPwd'] && newPasswordValidationSuccess && !errors['email'])
    {
      this.refs.waitDialog.open();
      this.props.dispatch(resetPassword(email, recoveryPwd, this.state.dataSetPwd.newPwd, this.resetPasswordSuccessHandler, this.resetPasswordErrorHandler));
    }

  }

  getForgotPwdButton() {
    const {t} = this.props;
    var isBtnDisabled = true;
    if (this.refs.passwordChangeForm && this.refs.passwordChangeForm.hasPasswordPassedAllGuidelines())
    {
      isBtnDisabled = false;
    }

    return (
      <RaisedButton label={ t('login-initial-pwd-button') } labelStyle={ styles.buttonLabel } primary={ true } fullWidth={ false } style={ styles.button } onClick={ () => this.handleSetPasswordClick() }
        disabled={ isBtnDisabled } />
      );
  }

  handleInputChange = (event) => {
    //
  }

  isValidLogin() {

    let data = this.props.params.data
    if (typeof data !== "string")
    {
      return false;
    }
    let loginData = window.atob(data);
    if (typeof loginData !== "string")
    {
      return false;
    }
    let loginDataObject = JSON.parse(loginData)

    let email = loginDataObject["user"];
    if (typeof email !== "string")
    {
      return false;
    }
    let pwd = loginDataObject["pwd"];
    if (typeof pwd !== "string")
    {
      return false;
    }
    let oldData = JSON.parse(JSON.stringify(this.state.data))
    oldData["user"] = email;
    oldData["pwd"] = pwd;
    oldData['changed'] = true;
    this.setState({
      data: oldData
    });
    this.props.dispatch(isRecoveryPasswordValid(email, pwd, this.isRecoveryPasswordValidSuccessHandler, this.resetPasswordErrorHandler));
    return true;


  }


  isRecoveryPasswordValidSuccessHandler(json) {

    this.refs.waitDialog.close();
    let result = json["result"];
    if (typeof result === "undefined" || result === "false")
    {
      const {router} = this.props;
      router.push("/");
    }

  }

  componentWillMount() {
    if (!this.isValidLogin())
    {
      const {router} = this.props;
      router.push("/")
      return <div></div>;
    }
  }



  render() {


    const {t} = this.props;

    return (

      <MuiThemeProvider muiTheme={ getAdminMuiTheme() }>
        <div className="wrapper">
          <WaitDialog ref="waitDialog" />
          <div className="wrapperContent">
            <header>
              <Header isLoggedIn={ false } />
            </header>
            <main>
              <div style={ styles.root }>
                <ModalDialog ref="modalDialog" />
                <div style={ styles.parent }>
                  <div style={ styles.image }>
                    <img src={ Logo } style={ styles.logo } alt="" />
                  </div>
                  <div style={ styles.placeholder }>
                  </div>
                  <span className='medium' style={ styles.label }>{ t('login-initial-pwd-new-pwd-desc') }</span>
                  <div style={ styles.placeholder }></div>
                  <PasswordChangeForm
                    ref="passwordChangeForm"
                    onChange={ (name, newValue) => this.handleChangeData(name, newValue) }
                    t={ t } />
                  <div style={ styles.buttonRow }>
                    <div style={ styles.buttonRowRight }>
                      { this.getForgotPwdButton() }
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <footer>
              <Footer/>
            </footer>
          </div>
        </div>
      </MuiThemeProvider>


      );
  }
}

const InitialLoginTranslated = translate(['common'])(InitialLogin);
export default connect()(InitialLoginTranslated);
