/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconOverlayCollapse(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 30 30">
              <path fill="#00C1A7" d="M0.499756258,30.7237616 L0.499756258,23.6875693 C0.499756258,23.2003497 1.08882041,22.956366 1.43331345,23.3008858 L4.95140962,26.8189819 C5.16497875,27.0325244 5.16497875,27.3788065 4.95140962,27.5923489 L1.43331345,31.1104451 C1.08882041,31.4549915 0.499756258,31.2109812 0.499756258,30.7237616 Z" transform="rotate(135 2.806 27.206)"/>
            </svg>
         </SvgIcon>;
}
;

export default IconOverlayCollapse;
