import i18n from 'i18next';


import TranslationCommonDe from './locales/de/common';
import TranslationCommonEl from './locales/el/common';
import TranslationCommonEn from './locales/en/common';
import TranslationCommonEs from './locales/es/common';
import TranslationCommonFr from './locales/fr/common';
import TranslationCommonIt from './locales/it/common';

i18n.init({
    whitelist : ['de','el','en','es','fr','it'],
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    debug: true,
    
    resources: {
        
        de : {
            common : TranslationCommonDe
        },
        el : {
            common : TranslationCommonEl
        },
        en : {
            common : TranslationCommonEn
        },
        es : {
            common : TranslationCommonEs
        },
        fr : {
            common : TranslationCommonFr
        },
        it : {
            common : TranslationCommonIt
        }
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    }
  });


export default i18n;