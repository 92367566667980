const TranslationCommonEn ={
    "SERVICE-ADMIN-ERR-0019": "Connection to ginlo Business failed",
    "SERVICE-ADMIN-ERR-0022": "Invalid input",
    "SERVICE-ADMIN-ERR-0098": "The mobile number is invalid. Please enter the mobile number, including area or city code, in the following format: '012345678910'. For mobile numbers outside Germany, please include the country code (e.g. '+31…').",
    "SERVICE-ADMIN-ERR-0106": "The registration information is invalid.",
    "SERVICE-ADMIN-ERR-0106-1": "The password is not correct.",
    "SERVICE-ADMIN-ERR-0109": "The session has expired.",
    "SERVICE-ADMIN-ERR-0110": "The image could not be uploaded.",
    "SERVICE-ADMIN-ERR-0111": "The image is too large. The maximum permissible resolution is 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "The phone number is already being used.",
    "SERVICE-ADMIN-ERR-0114": "The email address is already being used.",
    "SERVICE-ADMIN-ERR-0115": "The format on the .csv file is invalid.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "The format on the .csv file is invalid. The First Name column is blank.",
    "SERVICE-ADMIN-ERR-0115-NAME": "The format on the .csv file is invalid. The Last Name column is blank.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "The format on the .csv file is invalid. The Mobile Number column is blank.",
    "SERVICE-ADMIN-ERR-0135": "The image is too small. The minimum resolution required is 680 * 80 px.",
    "SERVICE-ADMIN-ERR-0153": "There are not enough licenses for this action. You have the option to purchase additional licenses under License Administration.",
    "SERVICE-ADMIN-ERR-0154": "The representative is not authorised for this action.",
    "SERVICE-ADMIN-ERR-0156": "Another administrator is already logged into the system. In order to avoid conflicts, the registration of more than 1 representative is not allowed.",
    "SERVICE-ADMIN-ERR-0157": "The administrator has been deleted.",
    "SERVICE-ADMIN-ERR-0158": "The administrator has been disabled.",
    "SERVICE-ADMIN-ERR-0176": "An error occurred when loading the preview for the RSS feed.",
    "SERVICE-ADMIN-ERR-0177": "The API was disabled by another user.",
    "SERVICE-ADMIN-ERR-0181": "The data were edited by the user {{username}} on {{date}}.",
    "SERVICE-ADMIN-ERR-0182": "The data were deleted by the user {{username}} on {{date}}.",
    "SERVICE-ADMIN-ERR-0183": "The API is already enabled.",
    "SERVICE-ERR-0057": "Timeout. You've been logged out.",
    "SERVICE-ERR-0123": "The email address is invalid.",
    "SERVICE-ERR-0124": "The domain for the email address you indicated is operated by a private email provider. This is not supported due to data privacy concerns.",
    "SERVICE-IMP-1": "This file is too large.",
    "SERVICE-IMP-23": "Incorrect file format.",
    "SERVICE-IMP-30": "The import file format is faulty (e.g. incorrect number of columns).",
    "SERVICE-IMP-35": "A group name used exists multiple times in ginlo Business Cockpit.",
    "SERVICE-IMP-36": "A channel name used exists multiple times in ginlo Business Cockpit.",
    "SERVICE-NO_SERVER_RESPONSE": "No connection to the server.",
    "SERVICE-RSSTRANS-CLI-0001": "The RSS feed at the requested URL is not available. The URL might be blocked by the firewall settings.",
    "SERVICE-RSSTRANS-ERR-0007": "The content under the given URL doesn't comply with RSS 2.0 specifications. Invalid characters were found.",
    "SERVICE-RSSTRANS-ERR-0008": "The content at the given URL does not conform to RSS 2.0 specifications. Not all the mandatory fields have been completed.",
    "SERVICE-RSSTRANS-ERR-0011": "No valid RSS feed was found at the URL given.",
    "SERVICE-RSSTRANS-ERR-0012": "An error occurred during loading of the preview for the RSS feed. The requested URL is not available.",
    "SERVICE-RSSTRANS-ERR-0014": "An Atom feed was found at the URL given. This format is not supported. Please ensure it is an RSS feed which conforms to RSS 2.0.",
    "SERVICE-RSSTRANS-ERR-0015": "No content was found at the URL given.",
    "SERVICE-RSSTRANS-ERR-0016": "The format of the RSS feed is not supported.",
    "SERVICE-RSSTRANS-ERR-0017": "The RSS feed contains invalid, non-permissible characters.",
    "cancel": "Cancel",
    "common-delete-btn": "Delete",
    "common-edit-btn": "Edit",
    "components-ModalDialog-Warning": "Note",
    "components-ModalDialog-cancel": "Cancel",
    "components-ModalDialog-looseDataQuestion": "If you cancel, the changes will not be saved. Cancel?",
    "components-ModalDialog-no": "No",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Yes",
    "components-NavigationLeft-appdesign": "App design",
    "components-NavigationLeft-appsettings": "App settings",
    "components-NavigationLeft-channel": "Channels",
    "components-NavigationLeft-dashboard": "Dashboard",
    "components-NavigationLeft-groups": "Groups",
    "components-NavigationLeft-license": "Licenses",
    "components-NavigationLeft-roles": "User Roles",
    "components-NavigationLeft-user": "Users",
    "components-chatmessagetextfield-default-hint": "Enter message text",
    "components-chatmessagetextfield-preview-time-placeholder": "Now",
    "components-continue": "Continue",
    "components-messagecomposer-datepicker": "Select date",
    "components-messagecomposer-messagesendtime-delayed": "At following date and time",
    "components-messagecomposer-messagesendtime-now": "Send immediately",
    "components-messagecomposer-send": "Send",
    "components-messagecomposer-send-AttachmentTooBig": "This file is too large.",
    "components-messagecomposer-timepicker": "Select time",
    "components-messagecomposer-uploadattachment": "Select image or file",
    "components-messageeditor-button-delete": "Delete messages",
    "components-messageeditor-button-showolder": "Display older",
    "components-messageeditor-confirmdeletemessage": "All messages in this channel will be deleted. Delete anyway?",
    "components-messageeditor-header-messages": "Messages in this channel",
    "components-messageeditor-header-newmessage": "New message",
    "components-messageeditor-header-timedmessages": "Time-delayed",
    "components-messageeditor-rss-linkmore": "more …",
    "components-messageeditor-type-audio": "Audio",
    "components-messageeditor-type-location": "Location",
    "components-messageeditor-type-video": "Video",
    "components-reload-data": "Refresh",
    "email": "Email",
    "err-empty-textfield": "The field can not be empty.",
    "error": "Error",
    "firstName": "First Name",
    "footer-link-details": "ginlo Manual",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/en/",
    "footer-link-faq": "FAQ",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/en/faq/",
    "footer-link-impress": "Imprint",
    "footer-link-impress-url": "https://app-help.ginlo.net/en/imprint",
    "footer-link-press": "Press",
    "footer-link-press-url": "https://www.ginlo.net/en/about-us/",
    "footer-link-privacy": "Privacy Policy",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/en/privacy",
    "footer-link-security": "ginlo Data",
    "footer-link-security-url": "https://www.ginlo.net/en/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Business",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/en/ginlo-business/",
    "footer-link-support": "Support",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "Terms & Conditions",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/en/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "EN",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Forgot password",
    "header-btn-fr": "FR",
    "header-btn-logout": "Log out",
    "header-btn-settings": "Settings",
    "header-btn-support": "Support",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Sample company name",
    "lastName": "Last Name",
    "login-2fa-token-textfield-hint": "Two factor pin (if activated)",
    "login-email-textfield-hint": "Email address",
    "login-forgot-pwd-button": "Set",
    "login-forgot-pwd-desc": "Please enter your recovery password.",
    "login-forgot-pwd-desc-min-char": "At least 8 characters",
    "login-forgot-pwd-desc-spez-char": "At least one number (0-9) or special character",
    "login-forgot-pwd-desc-upper-char": "Lowercase and capital letters (a-z, A-Z)",
    "login-forgot-pwd-err-empty": "This field is required.",
    "login-forgot-pwd-err-not-equal": "The passwords are not identical.",
    "login-forgot-pwd-new-pwd-desc": "Please choose a new password.",
    "login-forgotPwd-button": "Forgot password",
    "login-initial-pwd-button": "Save",
    "login-initial-pwd-new-pwd-desc": "Please choose your own password.",
    "login-login-button": "Login",
    "login-pwd-new-textfield-hint": "Enter new password",
    "login-pwd-repeat-textfield-hint": "Repeat new password",
    "login-pwd-textfield-hint": "Enter password",
    "login-remember-email": "Remember email address",
    "modules-appdesign-appdesign-btnResetLayout": "Reset app design",
    "modules-appdesign-appdesign-btncancel": "Cancel",
    "modules-appdesign-appdesign-btnsave": "Save",
    "modules-appdesign-appdesign-header": "App design",
    "modules-appdesign-colorpicker-actionelements": "Action elements",
    "modules-appdesign-colorpicker-blue": "blue",
    "modules-appdesign-colorpicker-color": "Colour",
    "modules-appdesign-colorpicker-contrast": "contrast",
    "modules-appdesign-colorpicker-contrastdark": "Dark contrast color",
    "modules-appdesign-colorpicker-contrastlight": "Light contrast color",
    "modules-appdesign-colorpicker-dark": "dark",
    "modules-appdesign-colorpicker-green": "Green",
    "modules-appdesign-colorpicker-heavytrust": "High trust status",
    "modules-appdesign-colorpicker-hex": "hexvalue",
    "modules-appdesign-colorpicker-light": "light",
    "modules-appdesign-colorpicker-maincolor": "Main color",
    "modules-appdesign-colorpicker-normaltrust": "Moderate trust status",
    "modules-appdesign-colorpicker-red": "Red",
    "modules-appdesign-colorpicker-unsafetrust": "Unsafe trust status",
    "modules-appdesign-colorpicker-yellow": "Blue",
    "modules-appdesign-cropimage-accept-btn": "Apply",
    "modules-appdesign-cropimage-title": "Please cut your logo to size as necessary.",
    "modules-appdesign-cropimage-zoom-hint": "* You can enlarge or crop the image using the scroll wheel of the mouse button.",
    "modules-appdesign-image-description": "*.png with alpha",
    "modules-appdesign-image-description2": "800 x 160 px recommended",
    "modules-appdesign-mainView-description": "Shape the app to match your corporate identity in just a few steps.",
    "modules-appdesign-mainView-removeCompanyLogo": "Delete image",
    "modules-appdesign-mainView-subheading": "Adjust the app to fit your needs.",
    "modules-appdesign-mainView-uploadImage": "Select image",
    "modules-appsettings-appsettings-btnResetConfig": "Reset app settings",
    "modules-appsettings-header": "App settings",
    "modules-appsettings-hinttext-range1": "0 to 10 minutes",
    "modules-appsettings-hinttext-range2": "0 to 100 repetitions",
    "modules-appsettings-hinttext-range3": "0 to 99 characters",
    "modules-appsettings-hinttext-range4": "0 to 365 days",
    "modules-appsettings-hinttext-range5": "0 to 4 classes",
    "modules-appsettings-label-chars": "Characters",
    "modules-appsettings-label-days": "Days",
    "modules-appsettings-label-minutes": "Min.",
    "modules-appsettings-label-repeat": "Rpt.",
    "modules-appsettings-mainView-contactpolicy": "Contact guidelines",
    "modules-appsettings-mainView-mediapolicy": "Media guidelines",
    "modules-appsettings-mainView-pwcomplexity": "Password complexity",
    "modules-appsettings-mainView-pwpolicy": "Password guidelines",
    "modules-appsettings-selectfield-deleteaccount-inactive": "deactivate",
    "modules-appsettings-selectfield-deleteaccount-tries": "Attempts",
    "modules-appsettings-selectfield-deleteaccount-try": "Attempt",
    "modules-appsettings-table-companyindexname": "Name of the company directory",
    "modules-appsettings-table-companyindexnamedescr": "Change here the name of the company directory that should be displayed to the users under contacts.",
    "modules-appsettings-table-contactClosedUserGroup": "Activated restricted user group",
    "modules-appsettings-table-contactClosedUserGroupDescr": "When this function is activated, the user can only communicate with contacts that have been added in ginlo Cockpit. It is also not possible to chat beyond with administrators.",
    "modules-appsettings-table-deletedatacount": "Delete data after too many failed attempts",
    "modules-appsettings-table-deletedatacountdescr": "Enter the number of permitted login attempts before the contents of the app are automatically deleted.",
    "modules-appsettings-table-disablebackup": "Prevent app backup in iCloud/Google Drive",
    "modules-appsettings-table-disablebackupdescr": "With this setting, users can not back up to iCloud or Google Drive or import backups from these providers. For data backup, coupling to a second device is recommended.",
    "modules-appsettings-table-disablecamera": "Deny access to camera.",
    "modules-appsettings-table-disablecameradescr": "When this function is activated, an employee can no longer record or send photographs and videos from the camera.",
    "modules-appsettings-table-disablecopypaste": "Deny access to copy / paste",
    "modules-appsettings-table-disablecopypastedescr": "When this function is activated, an employee can no longer copy text and media for pasting into chats or third-party applications.",
    "modules-appsettings-table-disableexportchat": "Deactivate exporting of chat content as text file",
    "modules-appsettings-table-disableexportchatdescr": "Setting this option to active prevents individual chats from being exported from the app into a text file.",
    "modules-appsettings-table-disablefaceandtouchid": "Block activation by Touch/Face-ID",
    "modules-appsettings-table-disablefaceandtouchiddescr": "If this setting is selected, users cannot use Touch/Face ID (iPhone) or fingerprint (Android) to unlock the app.",
    "modules-appsettings-table-disablelocation": "Deny access to geo-data",
    "modules-appsettings-table-disablelocationdescr": "When this function is activated, an employee can no longer share location data.",
    "modules-appsettings-table-disablemicrophone": "Deny access to the microphone",
    "modules-appsettings-table-disablemicrophonedescr": "When this function is activated, an employee can no longer send audio recordings.",
    "modules-appsettings-table-disablepushpreview": "Prevent preview in push notifications",
    "modules-appsettings-table-disablepushpreviewdescr": "With this setting, the contents of messages on mobile devices will not be shown in the push preview. This increases security, as content can then only be read with the app.",
    "modules-appsettings-table-disablerecoverycode": "Deactivate recovery code",
    "modules-appsettings-table-disablerecoverycodedescr": "When this function is activated, the user cannot use the 'forgot password' function.",
    "modules-appsettings-table-disablesendmedia": "Deactivate sending and forwarding of media files",
    "modules-appsettings-table-disablesendmediadescr": "When this setting is activated, attachments cannot be sent or forwarded.",
    "modules-appsettings-table-disablesendvcard": "Deny access to contacts",
    "modules-appsettings-table-disablesendvcarddescr": "When this function is activated, an employee can no longer send or receive contacts.",
    "modules-appsettings-table-disablesimsmerecovery": "Force recovery code via administrator",
    "modules-appsettings-table-disablesimsmerecoverydescr": "When this function is activated, users who forget their password must wait for explicit approval by the administrator before a recovery code is sent.",
    "modules-appsettings-table-forcePwComplexity": "Mandate password complexity",
    "modules-appsettings-table-forcePwComplexityDescr": "Force complexity of app password",
    "modules-appsettings-table-localmedia": "Do not allow media to to be saved locally",
    "modules-appsettings-table-localmediadescr": "Setting this option to active removes the 'Save Media' option, meaning that images and videos cannot be saved locally.",
    "modules-appsettings-table-passwordmaxduration": "Password validity in days",
    "modules-appsettings-table-passwordmaxdurationdescr": "Define the length of password validity in days, such as '30 days'. 30 days means that after 30 days the user must select a new password.",
    "modules-appsettings-table-passwordminclasses": "Minimum complexity level (digits, special characters, lowercase letters and capital letters)",
    "modules-appsettings-table-passwordminclassesdescr": "This setting allows you to define the minimum number of character classes (digits, special characters, lowercase letters and capital letters) that must be used in the password. 2 means that the password must contain at least two classes (such as digits + lowercase letters or special characters + digits).",
    "modules-appsettings-table-passwordminlength": "Minimum number of characters",
    "modules-appsettings-table-passwordminlengthdescr": "This parameter defines the mandatory password complexity based on the minimum number of characters. 0 characters means that there is no minimum length.",
    "modules-appsettings-table-passwordminlowcase": "Minimum number of lowercase letters",
    "modules-appsettings-table-passwordminlowcasedescr": "Define how many lowercase letters such as 'abcde' must be used. 0 characters means that no lowercase letters are required.",
    "modules-appsettings-table-passwordminnumbers": "Minimum number of digits",
    "modules-appsettings-table-passwordminnumbersdescr": "Define how many digits such as '12345' must be used. 0 characters means that no digits are required.",
    "modules-appsettings-table-passwordminspecialchars": "Minimum number of special characters",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Define how many special characters such as '!§$%' must be used. 0 characters means that no special characters are required.",
    "modules-appsettings-table-passwordminuppercase": "Minimum number of capital letters",
    "modules-appsettings-table-passwordminuppercasedescr": "Define how many capital letters such as 'ABCDE' must be used. 0 characters means that no capital letters are required.",
    "modules-appsettings-table-pwinact": "Force password check after inactivity of …",
    "modules-appsettings-table-pwinactdescr": "This parameter allows you to define how long the app can be inactive before the user must reenter the password.",
    "modules-appsettings-table-pwpolicy": "Force password check when app is started",
    "modules-appsettings-table-pwpolicydescr": "If this function is activated, the app will request the user's password each time it is started.",
    "modules-appsettings-table-pwwdh": "Password can be reused after X changes",
    "modules-appsettings-table-pwwdhdescr": "You can define how many times a user must use a new password before a previous password can be reused. 1 repetition means the password can be used after 1 different password was set in the interim.",
    "modules-appsettings-table-sharemedia": "Deactivate sharing of media files with other apps",
    "modules-appsettings-table-sharemediadescr": "When this setting is activated, images and videos can no longer be saved and files cannot be displayed.",
    "modules-appsettings-table-simplecode": "Forbid simple, 4-digit code as password",
    "modules-appsettings-table-simplecodedescr": "If this setting is activated, then users can not use a simple 4-digit code as password. To ensure that existing 4-digit passwords need to be changed, define an appropriate password complexity.",
    "modules-channel-commonbutton-btnUp": "to the top",
    "modules-channels-Channel-channel": "Manage channels",
    "modules-channels-Channel-confirmDeleteChannel": "Are you certain that you want to delete Channel?",
    "modules-channels-ChannelList-table-column1": "Channel name",
    "modules-channels-NewChannel": "Create new channel",
    "modules-channels-NewChannel-noMember": "No channel members",
    "modules-channels-channel-btnDeleteChannel": "Delete channel",
    "modules-channels-channel-tab-editChannel": "Edit channel",
    "modules-channels-channel-tab-messages": "Messages",
    "modules-channels-messages-senddateinvalid": "Please choose a date in the future.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Activity",
    "modules-dashboard-AktivitatenChart-displayValue1": "Daily Active Users",
    "modules-dashboard-AktivitatenChart-displayValue2": "Monthly Active Users",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} messages",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Daily Active Users",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Monthly Active Users",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Number of users who open the app daily",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Number of users who open the app monthly",
    "modules-dashboard-AktivitatenChart-timeValue1": "One week",
    "modules-dashboard-AktivitatenChart-timeValue2": "One month",
    "modules-dashboard-AktivitatenChart-timeValue3": "Total period",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} users",
    "modules-dashboard-ChannelChart-channels": "Channels",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Please select a channel.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Please select a group.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "no data available yet",
    "modules-dashboard-ChannelChart-popOver-text1": "The Top 5 are messages whose files, images or links were most frequently clicked by users.",
    "modules-dashboard-ChannelChart-table-column1": "Name",
    "modules-dashboard-ChannelChart-table-column2": "Users",
    "modules-dashboard-ChannelChart-table-column3": "Messages",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "User-generated groups",
    "modules-dashboard-ChannelChart-table-showAll": "Show all …",
    "modules-dashboard-ChannelChart-table-title": "Top 5 conversations",
    "modules-dashboard-ChannelChart-table-titleInfo": "Number of link clicks",
    "modules-dashboard-ChannelChart-timeValue1": "One week",
    "modules-dashboard-ChannelChart-timeValue2": "One month",
    "modules-dashboard-Dashboard-dashboard": "Dashboard",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} of {{v2}} licenses are active",
    "modules-dashboard-GruppenChart-groups": "Administered group",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} messages",
    "modules-dashboard-GruppenChart-table-column1": "Name",
    "modules-dashboard-GruppenChart-table-column2": "Users",
    "modules-dashboard-GruppenChart-table-column3": "Messages",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} messages in user-generated groups",
    "modules-dashboard-GruppenChart-table-showAll": "Show all …",
    "modules-dashboard-GruppenChart-table-title": "Messages",
    "modules-dashboard-GruppenChart-timeValue1": "One week",
    "modules-dashboard-GruppenChart-timeValue2": "One month",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Message mix",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "One week",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "One month",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Total period",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} total messages",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} individual messages",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} groups",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} channels",
    "modules-group-group-identifier": "Group",
    "modules-group-groups-identifier": "Groups",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Check",
    "modules-groups-GroupList-table-column1": "Group name",
    "modules-groups-GroupList-table-column2": "Invited",
    "modules-groups-GroupList-table-column3": "Joined",
    "modules-groups-GroupList-table-column4": "Outstanding",
    "modules-groups-GroupList-table-column5": "Last activity",
    "modules-groups-Groups-btnCancelNewGroup": "Cancel",
    "modules-groups-Groups-btnCreateGroup": "Create new group",
    "modules-groups-Groups-btnDeleteGroup": "Delete group",
    "modules-groups-Groups-confirmDeleteGroup": "Are you certain that you want to delete the group?",
    "modules-groups-Groups-groups": "Manage groups",
    "modules-groups-NewGroup-btnImportImage": "Select image",
    "modules-groups-NewGroup-newgroup": "Create new group",
    "modules-groups-NewGroup-noMember": "No group members",
    "modules-groups-NewGroup-placeholder-RSS-preview": "RSS Preview",
    "modules-groups-NewGroup-placeholder-feed": "Set an external RSS source.",
    "modules-groups-NewGroup-placeholder-name": "Name",
    "modules-groups-NewGroup-searchHint": "Search by first name, last name or keyword",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Close",
    "modules-license-DPAGOrderLicenseDialog-data-link": "data",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "Data means",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) contract and inventory data, e.g. company, addresses, e-mail addresses or phone numbers of personal contacts, and customer service information, as well as payment and billing data, and",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) traffic data, e.g. information on the time, duration, and volume of messages sent by users, as well as the encrypted messages themselves that are still being transmitted.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "Further information can be found {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "here",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "http://sims.me/datenuebergang/business/en",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "I would like to ensure the seamless continuation of the ginlo Business messenger for my users and therefore hereby commission ginlo.net GmbH to provide the ginlo Business services for my existing licenses from the date they expire at Deutsche Post AG. For the provision of services, I agree to the transfer of my data and the {{v1}} of my users from Brabbler AG to ginlo.net GmbH. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "I hereby order",
    "modules-license-DPAGOrderLicenseDialog-order2": "new licenses.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Privacy Policy",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "The {{v1}} and {{v2}} of ginlo.net GmbH apply to all licenses.",
    "modules-license-DPAGOrderLicenseDialog-text1": "SiMSme Business (now ginlo Business) has got a new owner: ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH). Orders for new licenses can only be placed with ginlo.net GmbH. ",
    "modules-license-License-btn-new": "Order new license",
    "modules-license-License-btn-select": "Assign license",
    "modules-license-License-delete-user-btn": "Delete user",
    "modules-license-License-delete-user-text": "Are you certain that you want to delete the user, which will block that user's access to the app?",
    "modules-license-License-delete-user-title": "Delete {{v1}}?",
    "modules-license-License-extend-header": "Renew license",
    "modules-license-License-extend-text": "Assign license to ",
    "modules-license-License-license": "License management",
    "modules-license-License-revoke-btn": "Revoke license",
    "modules-license-License-revoke-text": "Are you certain that you want to revoke the license from the user?",
    "modules-license-License-revoke-title": "Revoke license from {{v1}}?",
    "modules-license-License-searchfield-hint": "Search by first name, last name or keyword",
    "modules-license-License-table-column-count": "Number",
    "modules-license-License-table-column-expireDate": "Valid until",
    "modules-license-License-table-column-license": "License",
    "modules-license-License-table-column-licenses": "Licenses",
    "modules-license-License-table-column-more-assign": "Assign license",
    "modules-license-License-table-column-more-delete": "Delete license",
    "modules-license-License-table-column-more-extend": "Renew license",
    "modules-license-License-table-column-more-revoke": "Revoke license",
    "modules-license-License-table-header-availableUser": "Available users",
    "modules-license-License-table-header-freeLicense": "Available licenses",
    "modules-license-License-table-header-usedLicense": "Active licenses",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/en/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/en/privacy",
    "modules-license-license-assignment-error": "Assignment not possible. There are only {{free}} free license/s available.",
    "modules-roles-NewRole-noMember": "No members",
    "modules-roles-NewRole-placeholder-name": "Name",
    "modules-roles-NewRole-searchHint": "Search by first name, last name or keyword",
    "modules-roles-Newrole-newrole": "Create new role",
    "modules-roles-RoleList-table-column1": "Role name",
    "modules-roles-RoleList-table-column2": "# Users",
    "modules-roles-Roles-btnCreateRole": "Create new role",
    "modules-roles-Roles-btnDeleteRole": "Delete role",
    "modules-roles-Roles-confirmDeleteRole": "Are you certain that you want to delete the role?",
    "modules-roles-Roles-roles": "Manage user roles",
    "modules-settings-Admin-admin": "Representative",
    "modules-settings-Admin-btnActivateAdmin": "Enable",
    "modules-settings-Admin-btnCancelNewAdmin": "Cancel",
    "modules-settings-Admin-btnCreateNewAdmin": "Create a new representative",
    "modules-settings-Admin-btnDeactivateAdmin": "Disable",
    "modules-settings-Admin-btnDeleteAdmin": "Delete",
    "modules-settings-Admin-btnSaveNewAdmin": "Save",
    "modules-settings-Admin-confirmDeleteAdmin": "Are you sure you want to delete the representative?",
    "modules-settings-Admin-search": "Enter search term",
    "modules-settings-Admin-setAdminEnabled": "Are you sure you want {{adminName}} to grant all rights to a representative. This includes the creation, editing, and deletion of new users, groups, and channels.",
    "modules-settings-Admin-setAdminEnabled-title": "Representative has been created",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Cancel",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Enable",
    "modules-settings-AdminDetails-last-login": "Last login on",
    "modules-settings-AdminList-table-adminStatus1": "created",
    "modules-settings-AdminList-table-adminStatus2": "disabled",
    "modules-settings-AdminList-table-adminStatus3": "active",
    "modules-settings-AdminList-table-adminStatus4": "deleted",
    "modules-settings-AdminList-table-column1": "Last name",
    "modules-settings-AdminList-table-column2": "First name",
    "modules-settings-AdminList-table-column3": "Email",
    "modules-settings-AdminList-table-column4": "Mobile phone number",
    "modules-settings-AdminList-table-column6": "Activity",
    "modules-settings-AdminList-table-column7": "Last login",
    "modules-settings-AdminList-table-row-never-log-in": "never",
    "modules-settings-ApiCredentialsDialog-close-button": "Close",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Authentication token",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "User certificate fingerprints",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "Monitoring URL",
    "modules-settings-ApiCredentialsDialog-column-password": "Password",
    "modules-settings-ApiCredentialsDialog-column-username": "Username",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Valid until",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Expired",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "Copy URL",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "You can check the validity of the certificate with the public key for the .CRT file.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Download .CRT certificate",
    "modules-settings-ApiCredentialsDialog-edit-button": "Edit",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "With this URL, you can monitor the status of the imports using your monitoring system. No personal data will be transmitted during this process. The format of the request is outlined in the API documentation.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Send new certificate",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Generate new URL",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Generate new password",
    "modules-settings-ApiCredentialsDialog-title": "Login information",
    "modules-settings-DeleteApiUserDialog-text": "This will stop the automatic synchronization and your login information and monitoring URL will be deleted.",
    "modules-settings-DeleteApiUserDialog-title": "Do you really want to disable the API?",
    "modules-settings-NewAdmin-hint": "He can create, edit and delete users, groups, channels. However, the representative can not create any other representatives.",
    "modules-settings-NewAdmin-hint-title": "The representative receives all administrator rights.",
    "modules-settings-NewAdmin-newadmin": "Create a new representative",
    "modules-user-AdminList-table-auditLog-active": "Activated on",
    "modules-user-AdminList-table-auditLog-created": "Created on",
    "modules-user-AdminList-table-auditLog-deactive": "Disabled on",
    "modules-user-AdminList-table-auditLog-delete": "Deleted on",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Export all used devices into a CSV file.",
    "modules-user-ChooseCSVExport-title": "Select What Data to Export",
    "modules-user-ChooseTemplate-csv-detail": "Simplified CSV for one-time user creation",
    "modules-user-ChooseTemplate-ldap-detail": "Extended CSV for continuous LDAP updating and automatic assignments in groups and channels",
    "modules-user-ChooseTemplate-title": "Download an Import Template",
    "modules-user-Keyword-deleteKeyword": "Do you really want to delete the keyword {{keyword}} and all instances where it is used?",
    "modules-user-Keyword-jumpToUser": "Do you want to discard the current changes and switch to {{displayName}}?",
    "modules-user-Keyword-searchHint": "Search by keyword",
    "modules-user-Keyword-showUsage": "Display",
    "modules-user-Keyword-table-column1": "Keyword",
    "modules-user-Keyword-table-column2": "Apply",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Cancel",
    "modules-user-NewUser-btnNewUserSave": "Save",
    "modules-user-NewUser-keyword": "Keyword",
    "modules-user-NewUser-keywordEdit": "Edit keywords",
    "modules-user-NewUser-keywordEditEnd": "Close editor",
    "modules-user-NewUser-newuser": "Create user data",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "import_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "Do you want to assign a license?",
    "modules-user-User-assignVoucher-title": "{{displayName}} was created.",
    "modules-user-User-assignVoucherBtnCancel": "Assign later ",
    "modules-user-User-assignVoucherBtnOk": "Assign license",
    "modules-user-User-assignVouchersByImportID": "Do you want automatically assign a license to all imported users?",
    "modules-user-User-assignVouchersByImportID-title": "Successfully imported users: {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Assign licenses",
    "modules-user-User-btnCancelNewUser": "Cancel",
    "modules-user-User-btnCreateNewuser": "Create user data",
    "modules-user-User-btnDeleteUser": "Delete user",
    "modules-user-User-btnImportUser": "Import user data",
    "modules-user-User-btnImportVorlage": "CSV Import & Export",
    "modules-user-User-btnRevokeLicence": "Lock license",
    "modules-user-User-btnSaveNewUser": "Save",
    "modules-user-User-btnSaveUser": "Save changes",
    "modules-user-User-confirmDeleteUser": "Are you certain that you want to delete the user?",
    "modules-user-User-confirmRevokeLicense": "Are you certain that you want to block the user's license, which will block that user's access to the app?",
    "modules-user-User-email-hinttext": "e-mail",
    "modules-user-User-importLdap-applyData": "Continue",
    "modules-user-User-importLdap-cancel": "Cancel",
    "modules-user-User-importLdap-done": "Close",
    "modules-user-User-importLdap-exportApplyData": "Export import report",
    "modules-user-User-importLdap-exportPreviewData": "Export report",
    "modules-user-User-importLdap-state-applyData": "Data is being processed",
    "modules-user-User-importLdap-state-done": "Changes adopted",
    "modules-user-User-importLdap-state-fatal": "The action was cancelled due to an error.",
    "modules-user-User-importLdap-state-preflight": "Data is being reviewed.",
    "modules-user-User-importLdap-state-waitConfirm": "Review complete",
    "modules-user-User-importLdap-table-column1": "Category",
    "modules-user-User-importLdap-table-column2": "Number of entries",
    "modules-user-User-importLdap-table-column3": "Notes",
    "modules-user-User-importLdap-table-column4": "Warnings",
    "modules-user-User-importLdap-table-column5": "Errors",
    "modules-user-User-importLdap-table-row1": "Users",
    "modules-user-User-importLdap-table-row2": "Groups",
    "modules-user-User-importLdap-table-row3": "Channels",
    "modules-user-User-importLdap-table-row4": "Keywords",
    "modules-user-User-search": "Enter search term",
    "modules-user-User-user": "User administration ({{v1}})",
    "modules-user-UserList-popover-phone": "Please enter mobile numbers in the format '012345678910'. For numbers outside Germany, please include the country code but not the '0' prefix, i.e. '+4312345678910'.",
    "modules-user-UserList-table-cmdDeleteUser": "Delete user",
    "modules-user-UserList-table-cmdEdit": "Edit",
    "modules-user-UserList-table-cmdRevokeLicense": "Lock license",
    "modules-user-UserList-table-column1": "Last Name",
    "modules-user-UserList-table-column2": "First Name",
    "modules-user-UserList-table-column3": "Email",
    "modules-user-UserList-table-column4": "Mobile number",
    "modules-user-UserList-table-column6": "Keyword",
    "modules-user-UserList-table-placeHolder-column1": "Last Name",
    "modules-user-UserList-table-placeHolder-column2": "First Name",
    "modules-user-UserList-table-placeHolder-column3": "Email",
    "modules-user-UserList-table-placeHolder-column4": "Mobile number",
    "modules-user-UserList-table-placeHolder-column6": "Keyword",
    "modules-user-UserList-table-placeHolder-column8": "Department",
    "modules-user-UserList-table-roomWriterHeader": "Write access",
    "modules-user-UserList-table-userStatus1": "User created",
    "modules-user-UserList-table-userStatus2": "License assigned",
    "modules-user-UserList-table-userStatus3": "License active",
    "modules-user-UserList-table-userStatus4": "License expired",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Licence expired – user blocked",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Licence revoked – user blocked",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Licence active – administration accepted",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "Email validation cancelled",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "Pending email validation",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Mobile phone number validation cancelled",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "Pending mobile phone number validation",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Account deleted by user",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Administration enquiry rejected",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "User created – no licence assigned",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Licence assigned – pending administration ",
    "modules-user-UserList-userCreated": "User {{firstName}} {{lastName}} was successfully created.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Assign license",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Assign license later",
    "modules-user-alert-btnSendManagementRequest": "Re-send request",
    "modules-user-alert-sendManagementRequestHint": "The user has rejected the request for administrative permissions.",
    "modules-user-device-deviceList": "Devices in use",
    "modules-user-device-unknown-devicename": "Unknown device name",
    "modules-user-managementrequest-messagesent": "The request to manage the app has been sent to the user.",
    "modules-user-password-btnSendRecoveryEMail": "Send Recovery Code",
    "modules-user-password-btnShowRecoveryCode": "Show Recovery Code",
    "modules-user-password-revoverPasswordHint": "This user has forgotten their device password. Please send them their recovery code.",
    "modules-user-recover-bottomBarText": "The user has forgotten his or her password.",
    "modules-user-recoverycode-messagesent": "The recovery code is on its way to the user. You'll find a copy of the e-mail in your inbox.",
    "modules-user-recoverycode-title": "Recovery Code",
    "okay": "Okay",
    "phone": "Mobile number",
    "save": "Save",
    "secret": "Secret",
    "settings-admin": "Administrator",
    "settings-admin-change-pwd": "Change password",
    "settings-btnDeleteCompany": "Delete account",
    "settings-company-label-ustIdNr": "VAT number",
    "settings-companycockpittoken": "EMM rollout",
    "settings-companycockpittoken-documentation-link": "Documentation",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/en/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "If your organisation uses an enterprise mobility management solution, you can activate automatic registration for the rollout.",
    "settings-companycockpittoken-switch-enable-label": "Automatic EMM registration",
    "settings-companycockpittoken-switch-force-desc": "If this function is not activated, messenger registration will be performed fully automatically. Please note the instructions in {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Enforce quick registration on the terminal",
    "settings-companycockpittoken-token-desc": "You need the security token for automatic EMM registration. See {{v1}}.",
    "settings-companycockpittoken-token-label": "The security token is: ",
    "settings-companycockpittoken-token-notset": "Automatic registration not activated",
    "settings-companyinformation": "Company data",
    "settings-companylanguage": "Language settings",
    "settings-companylanguage-de": "German",
    "settings-companylanguage-desc": "Language for your company when sending e-mail invitations and other communication.",
    "settings-companylanguage-el": "Greek",
    "settings-companylanguage-en": "English",
    "settings-companylanguage-es": "Spanish",
    "settings-companylanguage-fr": "French",
    "settings-companyprofilimage": "Corporate profile picture",
    "settings-companyprofilimage-btnImportImage": "Select image.",
    "settings-companyprofilimage-desc": "Select a profile picture to appear in channels next to notifications.",
    "settings-companyprofilimage-desc2": "Size recommendation 200 x 200 pixels.",
    "settings-confirmdeletecompanydialog-desc": "By deleting your account all user accounts will be deleted and the matching licences blocked.",
    "settings-confirmdeletecompanydialog-hint": "Enter password",
    "settings-confirmdeletecompanydialog-ok": "Irrevocably delete the account",
    "settings-confirmdeletecompanydialog-title": "Are you sure you want to delete your account?",
    "settings-ldap-sync-delete-api-user-button": "Disable API",
    "settings-ldap-sync-descr-text": "The ginlo API is a RESTful service, which you can use to automate creation, editing, and deletion of users, groups, and channels.",
    "settings-ldap-sync-documentation-link-pretext": "You can find the corresponding {{v1}} here (in German only).",
    "settings-ldap-sync-documentation-link-text": "Documentation",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/en/cockpit/index.html#ldap-data",
    "settings-ldap-sync-ldap-activated": "API is enabled",
    "settings-ldap-sync-ldap-activated-hint": "It is possible that manual changes may be overwritten.",
    "settings-ldap-sync-new-api-user-button": "Enable API",
    "settings-ldap-sync-show-api-user-credentials-button": "Login information",
    "settings-ldap-sync_title": "ginlo Business API",
    "settings-order-in-year": "€ annually",
    "settings-order-input-hint": "0 to 500 licenses",
    "settings-order-tax-info": "plus applicable VAT",
    "settings-orderhistory": "Order history",
    "settings-orderhistory-agb": "Yes, I agree to {{v1}}.",
    "settings-orderhistory-agb-link": "Terms & Conditions",
    "settings-orderhistory-license": "License",
    "settings-orderhistory-licenses": "Licenses",
    "settings-orderhistory-order": "Order now (binding)",
    "settings-orderhistory-privacy": "Yes, I have read and understood the {{v1}}.",
    "settings-orderhistory-privacy-link": "Privacy Policy",
    "settings-orderhistory-table-column-date": "Date",
    "settings-primarydomain": "Main domain",
    "settings-pwd-current-hint": "Enter current password",
    "settings-save-error": "The following error occurred while saving the data:",
    "settings-settings": "Settings",
    "settings-stellvertreter": "Representative",
    "settings-stellvertreter-btn-edit": "Edit representative",
    "settings-stellvertreter-details": "{{activeAdminCount}} active {{adminCount}} assigned",
    "settings-stellvertreter-last-login-never": "Not yet logged in",
    "settings-stellvertreter-last-login-ok": "Last login ",
    "settings-two-fa": "Two Factor Authentication",
    "settings-two-fa-btn-show-recovery-codes": "Show Recovery Codes",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Accept Recovery Codes",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Please copy these Recovery Codes",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "Your Recovery Codes",
    "settings-two-fa-create-recovery-codes-dialog-title": "Your Recovery Codes",
    "settings-two-fa-disable-dialog-btn-submit": "Deactivate",
    "settings-two-fa-disable-dialog-description": "Do you really want to deactivate the Two Factor Authentication?",
    "settings-two-fa-disable-dialog-title": "Deactivation of Two Factor Authentication",
    "settings-two-fa-disabled": "2FA is disabled",
    "settings-two-fa-enable-dialog-btn-submit": "Submit token and activate 2FA",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Activation of Two Factor Authentication",
    "settings-two-fa-enable-dialog-token-help-text": "Please scan the QR code or enter it manually into your authenticator app.",
    "settings-two-fa-enable-dialog-token-not-valid": "Token is not valid",
    "settings-two-fa-enabled": "2FA is enabled",
    "settings-two-fa-label": "Two Factor Authentication is:",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Please copy these Recovery Codes. These provides you a login into the cockpit when you lost your secret.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Recovery Codes",
    "settings-two-fa-show-recovery-codes-dialog-title": "Your Recovery Codes",
    "state": "User status",
    "token": "Token",
    "validation-errormessages-maxvalue": "maximum {{max}} allowed",
    "validation-errormessages-minvalue": "minimum {{min}} required",
    "welcome-channel-btn": "Create channels",
    "welcome-channel-description": "Channels allow you to send push notifications to your users. Messages, images and files can be distributed centrally to your users. You can review the conversion figures on the dashboard afterward.",
    "welcome-channel-subheadline-create": "Create channels",
    "welcome-chat-subheadline": "Simplify communication",
    "welcome-config-subheadline": "Configure app",
    "welcome-design-btn": "Modify app design",
    "welcome-design-description": "You have the option here of modifying the app design to fit your company's corporate design guidelines. You can change the logo and colors at any time.",
    "welcome-design-subheadline-create": "Customize app design",
    "welcome-group-btn": "Create groups",
    "welcome-group-description": "Create groups to make collaboration easier between users. The activity of the groups can be viewed in the dashboard.",
    "welcome-group-subheadline-add": "Create groups",
    "welcome-headline": "Welcome {{v1}}",
    "welcome-intro": "ginlo Business Cockpit provides a convenient tool for configuring the secure ginlo Business Messenger for Android and iPhone to fit your needs.",
    "welcome-license-btn": "Assign license",
    "welcome-license-description": "Assign the available licenses to created user accounts. If you do not have enough licenses available, {{v1}} the desired quantity to close the gap.",
    "welcome-license-description-link": "order",
    "welcome-license-subheadline-add": "Assign license",
    "welcome-settings-btn": "Modify settings",
    "welcome-settings-description": "Customize the security requirements and app settings to meet your security specifications.",
    "welcome-settings-subheadline-create": "Configure app settings",
    "welcome-user-btn": "Create new user",
    "welcome-user-description": "New user accounts can be created quickly and easily. Either use the 'create single user' function, or import users data using the CSV template.",
    "welcome-user-subheadline": "Manage users",
    "welcome-user-subheadline-create": "Create user"
}
;
    
    export default TranslationCommonEn;
    
