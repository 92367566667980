import React, { Component } from 'react';
import FlatButtonUi from 'material-ui/FlatButton';

class FlatButton extends Component {

  render() {
    const {labelStyle, buttonStyle, ...other} = this.props;
    const mergedLabelStyle = Object.assign({}, {
      letterSpacing: '0.45px',
    }, labelStyle);

    return (
      <FlatButtonUi labelStyle={ mergedLabelStyle } hoverColor="#00375D00" rippleColor="#00375D00" {...other} />
      );
  }
}

export default FlatButton;
