import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';
import { Interpolate } from 'react-i18next';

import download from 'downloadjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { renewApiUserCredentials } from '../../actions/index';

import { WaitDialog } from '../../components/WaitDialog';
import ErrorDialog from '../../components/ErrorDialog.js';

const styles = {
  row: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    height: '65px',
    color: '#FFFFFF'
  },
  greyText: {
    fontSize: '12px',
    fontWeight: 'regular',
    color: '#8E9194',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  whiteText: {
    fontSize: '16px',
    fontWeight: 'regular',
    color: '#FFFFFF',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  red16Text: {
    fontSize: '16px',
    fontWeight: 'regular',
    color: '#E94B57',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  rightButton: {
    marginTop: '16px',
    marginBottom: '16px',
    marginLeft: '20px',
    width: '280px'
  },
  leftButton: {
    marginTop: '16px',
    marginBottom: '16px'
  },
};

const initState = {
  open: false,
  credentials: {
    username: "",
    password: "",
    fingerprint: "",
    validTill: "",
    monitoringUrl: "",
    certificateData: "",
    authToken: ""
  },
  editButtonClicked: false
};

class ApiCredentialsDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;

    this.onRenewCredentialsSuccessHandler = this.onRenewCredentialsSuccessHandler.bind(this);
    this.onActionErrorHandler = this.onActionErrorHandler.bind(this);
  }

  open(parameter) {

    let credentials = null;

    if (typeof parameter.credentials !== 'undefined' && parameter.credentials !== null)
    {
      credentials = parameter.credentials
    }

    this.setState({
      credentials: credentials,
      open: true
    });
  }

  handleCancelButton() {
    this.setState(initState);
  }

  handleEditButtonClicked(isClicked) {
    this.setState({
      editButtonClicked: isClicked
    });
  }

  handleDownloadButtonClicked() {
    let adminCertificate = this.state.credentials.certificate;

    download(adminCertificate, "certificate.crt", "text/plain");
  }

  handleRenewCredentialsButtonClicked(mode) {
    const {dispatch} = this.props;

    this.refs.waitDialog.open();

    dispatch(renewApiUserCredentials(mode, this.onRenewCredentialsSuccessHandler, this.onActionErrorHandler));
  }

  onRenewCredentialsSuccessHandler(json) {
    this.refs.waitDialog.close();

    if (typeof json !== 'undefined' && json !== null)
      this.setState({
        credentials: json.Credential
      });
  }

  onActionErrorHandler(errorJO) {
    this.refs.waitDialog.close();

    this.setState(initState);

    this.props.commonErrorHandler(errorJO);
  }


  render() {
    const {t} = this.props;

    let ldapDescLink = <a href={ t('settings-ldap-sync-documentation-link1') } target="_blank" rel="noopener">
                         { t('settings-ldap-sync-documentation-link-text') }
                       </a>;

    // let certificateValidTill;
    // let certificateExpired = false;

    /*
    if (this.state.credentials.validTill !== 'undefined' && this.state.credentials.validTill !== null)
    {
      let validTill = new Date().parseUtcDate(this.state.credentials.validTill);

      let now = new Date();

      // certificateExpired = validTill < now;

      if (certificateExpired)
      {
        certificateValidTill = t('modules-settings-ApiCredentialsDialog-column-validTill-expired');
      }
      else
      {
        certificateValidTill = validTill.getShortDateTime();
      }
    }
    */

    return (
      <div>
        <div>
          <WaitDialog ref="waitDialog" />
          <ErrorDialog ref="errorDialog" t={ t } />
        </div>
        <Dialog title={ t('modules-settings-ApiCredentialsDialog-title') } contentStyle={ { width: "80%", maxWidth: '960px' } } modal={ true } open={ this.state.open }>
          <div style={ Object.assign({}, styles.row, {
                         height: 'auto',
                         marginBottom: '16px'
                       }) }>
            <div style={ { width: '140px', flexShrink: '0' } }>
              <div style={ styles.greyText }>
                { t('modules-settings-ApiCredentialsDialog-column-username') }
              </div>
            </div>
            <div style={ { width: '430px', marginLeft: '20px', flexShrink: '0' } }>
              <div style={ Object.assign({}, styles.whiteText, {
                             float: 'left'
                           }) }>
                { this.state.credentials.username }
              </div>
            </div>
            <div>
            </div>
          </div>
          <div style={ styles.row }>
            <div style={ { width: '140px', flexShrink: '0' } }>
              <div style={ styles.greyText }>
                { t('modules-settings-ApiCredentialsDialog-column-password') }
              </div>
            </div>
            <div style={ { width: '430px', marginLeft: '20px', flexShrink: '0' } }>
              <div style={ Object.assign({}, styles.whiteText, {
                             float: 'left'
                           }) }>
                { this.state.credentials.password }
              </div>
            </div>
            <div>
              { (this.state.editButtonClicked === true) &&
                <RaisedButton label={ t('modules-settings-ApiCredentialsDialog-new-password-button') } primary={ true } style={ styles.rightButton } onTouchTap={ () => this.handleRenewCredentialsButtonClicked('password') } /> }
            </div>
          </div>
          <div style={ styles.row }>
            <div style={ { width: '140px', flexShrink: '0' } }>
              <div style={ styles.greyText }>
                { t('modules-settings-ApiCredentialsDialog-column-monitoring-url') }
              </div>
            </div>
            <div style={ { width: '430px', marginLeft: '20px', flexShrink: '0' } }>
              <div style={ Object.assign({}, styles.whiteText, {
                             float: 'left'
                           }) }>
                { this.state.credentials.monitoringUrl }
              </div>
            </div>
            <div>
            </div>
          </div>
          <div style={ styles.row }>
            <div style={ { width: '140px', flexShrink: '0' } }>
            </div>
            <div style={ { width: '430px', marginLeft: '20px', flexShrink: '0' } }>
              <div style={ styles.greyText }>
                { t('modules-settings-ApiCredentialsDialog-monitoring-url-descr') }
              </div>
            </div>
            <div>
              { (this.state.editButtonClicked === true) &&
                <RaisedButton label={ t('modules-settings-ApiCredentialsDialog-new-monitoring-url-button') } primary={ true } style={ styles.rightButton } onTouchTap={ () => this.handleRenewCredentialsButtonClicked('authToken') } /> }
              { (this.state.editButtonClicked === false) &&
                <CopyToClipboard text={ this.state.credentials.monitoringUrl }>
                  <RaisedButton style={ styles.rightButton } label={ t('modules-settings-ApiCredentialsDialog-copy-url-button') } primary={ true } />
                </CopyToClipboard> }
            </div>
          </div>
          <div style={ styles.row }>
            <div style={ { width: '140px', flexShrink: '0' } }>
              { (this.state.editButtonClicked === true) &&
                <RaisedButton style={ styles.leftButton } secondary={ true } label={ t('modules-settings-ApiCredentialsDialog-close-button') } onTouchTap={ () => this.handleEditButtonClicked(false) } /> }
              { (this.state.editButtonClicked === false) &&
                <RaisedButton style={ styles.leftButton } primary={ true } label={ t('modules-settings-ApiCredentialsDialog-edit-button') } onTouchTap={ () => this.handleEditButtonClicked(true) } /> }
            </div>
            <div style={ { width: '430px', marginLeft: '20px', flexShrink: '0' } }>
              <div style={ Object.assign({}, styles.greyText, {
                             float: 'left',
                             fontSize: '14px'
                           }) }>
                <Interpolate i18nKey='settings-ldap-sync-documentation-link-pretext' v1={ ldapDescLink } />
              </div>
            </div>
            <div>
              { (this.state.editButtonClicked === false) &&
                <RaisedButton style={ styles.rightButton } label={ t('modules-user-User-importLdap-done') } secondary={ true } onTouchTap={ () => this.handleCancelButton() } /> }
            </div>
          </div>
        </Dialog>
      </div>
      );
  }

}

export default ApiCredentialsDialog;
