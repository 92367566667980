/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconUser(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <g fill="none" fillRule="evenodd" transform="translate(8 8)">
                <rect width="24" height="24"/>
                <path fill="#FFF" fillRule="nonzero" d="M12,11.9999774 C9.47499302,11.9999774 7.42857143,9.87362248 7.42857143,7.24998868 C7.42857143,4.62635487 9.47499302,2.5 12,2.5 C14.525007,2.5 16.5714286,4.62635487 16.5714286,7.24998868 C16.5714286,9.87362248 14.525007,11.9999774 12,11.9999774 Z M15.199986,13.1874745 C17.849993,13.1874745 20,15.4214608 20,18.1749771 L20,19.7187089 C20,20.702105 19.2321429,21.4999547 18.2857143,21.4999547 L5.71428571,21.4999547 C4.76785714,21.4999547 4,20.702105 4,19.7187089 L4,18.1749771 C4,15.4214608 6.15000698,13.1874745 8.80001395,13.1874745 L9.39641462,13.1874745 C10.1928711,13.5659965 11.0714286,13.7812231 12,13.7812231 C12.9285714,13.7812231 13.8107213,13.5659965 14.6035854,13.1874745 L15.199986,13.1874745 Z"/>
              </g>
            </svg>
         </SvgIcon>;
}
;

export default IconUser;
