/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconStyle(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <g fill="none" fillRule="evenodd" transform="translate(8 8)">
                <rect width="24" height="24"/>
                <path fill="#FFF" fillRule="nonzero" d="M10.0809596,2.6868511 C16.1594534,1.50305036 21.4809396,6.10460189 21.4994943,11.9530472 C21.4957978,13.277856 20.4196285,14.3762764 19.0910871,14.3762764 L16.1335059,14.3762764 C14.2409323,14.3762764 13.0163259,16.3430685 13.8735359,18.0278446 C14.5749159,19.4045847 13.8253011,21.1932595 12.2963912,21.4307372 C7.57981539,22.1617974 1.31947151,16.9887846 2.69251511,10.049348 C3.41984263,6.371796 6.39231822,3.40678575 10.0809596,2.6868511 Z M6.06203108,14.3762764 C6.7188727,14.3762764 7.24952825,13.8456208 7.24952825,13.1887792 C7.24952825,12.5319376 6.7188727,12.001282 6.06203108,12.001282 C5.40518946,12.001282 4.87453391,12.5319376 4.87453391,13.1887792 C4.87453391,13.8456208 5.40518946,14.3762764 6.06203108,14.3762764 Z M7.24952825,9.62628768 C7.90636987,9.62628768 8.43702542,9.09563213 8.43702542,8.43879051 C8.43702542,7.78194889 7.90636987,7.25129334 7.24952825,7.25129334 C6.59268663,7.25129334 6.06203108,7.78194889 6.06203108,8.43879051 C6.06203108,9.09563213 6.59268663,9.62628768 7.24952825,9.62628768 Z M11.9995169,7.25129334 C12.6563585,7.25129334 13.1870141,6.72063779 13.1870141,6.06379617 C13.1870141,5.40695455 12.6563585,4.876299 11.9995169,4.876299 C11.3426753,4.876299 10.8120198,5.40695455 10.8120198,6.06379617 C10.8120198,6.72063779 11.3426753,7.25129334 11.9995169,7.25129334 Z M16.7495056,9.62628768 C17.4063472,9.62628768 17.9370028,9.09563213 17.9370028,8.43879051 C17.9370028,7.78194889 17.4063472,7.25129334 16.7495056,7.25129334 C16.092664,7.25129334 15.5620084,7.78194889 15.5620084,8.43879051 C15.5620084,9.09563213 16.092664,9.62628768 16.7495056,9.62628768 Z"/>
              </g>
            </svg>
         </SvgIcon>
}
;
export default IconStyle;
