import BaseDataProvider from '../BaseDataProvider';
import DataCache from '../../cache/DataCache';
import { getNumberForUserState } from '../../components/table/UserState.js'

export const KEY_VOUCHER = 'Voucher';
export const KEY_GUID = 'guid';
export const KEY_IDENT = 'ident';
export const KEY_VALID_TILL = 'validTill';
export const KEY_COMPANY_USER = 'CompanyUser';
export const KEY_LICENSE = 'license';
export const KEY_LAST_NAME = 'name';
export const KEY_FIRST_NAME = 'firstName';
export const KEY_EMAIL = 'emailAdress';
export const KEY_PHONE = 'phoneNumber';
export const KEY_KEYWORD = 'keyword';
export const KEY_EXPIRE_DATE = 'expireDate';
export const KEY_VOUCHER_GROUP = 'VoucherGroup';
export const KEY_VOUCHER_COUNT = 'voucherCount';
export const KEY_STATE = 'state';

export class DataProvider extends BaseDataProvider {

  constructor() {
    super();
    this.dataFreeVoucher = [];
    this.dataCache = new DataCache();
    // filtered copy of userData
    this.availableUser = [];
    this.selectedAvailableUserGuids = [];
  }

  setUsedVoucherData(data, multiUpdate) {
    this.dataCache.searchAndUpdateUsedVoucher(data, multiUpdate);
  }

  getFreeVoucherData() {
    return this.dataFreeVoucher;
  }

  setFreeVoucherData(data) {
    this.dataFreeVoucher = data;
  }

  setAvailableUser(data) {

    this.dataCache.searchAndUpdateUser(data, true);
    var tmpAvailableUsers = [];

    // eslint-disable-next-line
    this.dataCache.getUserData().map((user, i) => {
      if (user.hasVoucher === "false")
      {
        tmpAvailableUsers.push(user);
      }
    });

    this.availableUser = tmpAvailableUsers;
  }

  resetSelectedUserAndVoucher() {
    this.selectedAvailableUserGuids = [];
    this.selectedFreeVoucherGuid = null;
    this.selectedUsedVoucherUserGuid = null;
  }


  getSortedData(data, sortColumnName, sortDirection) {
    let t = this;
    //    data = super.sortData(data, sortColumnName, sortDirection);
    data = data.sort(function(a, b) {
      let rc = 0;
      if (sortColumnName === KEY_IDENT)
      {
        rc = a.ident.localeCompare(b.ident);
      }
      if (sortColumnName === KEY_VALID_TILL)
      {
        let dateA = new Date();
        let dateB = new Date();

        dateA = new Date(a.validTill);
        dateB = new Date(b.validTill);
        rc = dateA - dateB;
      }
      if (sortColumnName === KEY_LAST_NAME)
      {
        //Problem: in der AssignLicense-View sind die Nutzer als
        //array von CompanyUsern vorhanden
        // es wird aber dieser dataProvider genutzt
        //Solange es sich nur um die beiden Felder "Name" und "Firstname" handelt,
        //ist dieser "Hack" evtl. vertretbar.
        if (a.CompanyUser)
        {
          rc = a.CompanyUser.name.localeCompare(b.CompanyUser.name);
        }
        else
        {
          rc = a.name.localeCompare(b.name);
        }
      }
      if (sortColumnName === KEY_FIRST_NAME)
      {
        //Problem: in der AssignLicense-View sind die Nutzer als
        //array von CompanyUsern vorhanden
        // es wird aber dieser dataProvider genutzt
        //Solange es sich nur um die beiden Felder "Name" und "Firstname" handelt,
        //ist dieser "Hack" evtl. vertretbar.
        if (a.CompanyUser)
        {
          rc = a.CompanyUser.firstName.localeCompare(b.CompanyUser.firstName);
        }
        else
        {
          rc = a.firstName.localeCompare(b.firstName);
        }
      }
      if (sortColumnName === KEY_EMAIL)
      {
        rc = a.CompanyUser.eMailAdress.localeCompare(b.CompanyUser.eMailAdress);
      }
      if (sortColumnName === KEY_VOUCHER_COUNT)
      {
        //wandelt die Strings implizit in number um
        rc = a.VoucherGroup.voucherCount - b.VoucherGroup.voucherCount;
      //rc = a.VoucherGroup.voucherCount.localeCompare(b.VoucherGroup.voucherCount);
      }
      if (sortColumnName === KEY_EXPIRE_DATE)
      {
        let dateA = new Date(a.VoucherGroup.validTill);
        let dateB = new Date(b.VoucherGroup.validTill);

        rc = dateA - dateB;
      //rc = a.VoucherGroup.validTill.localeCompare(b.VoucherGroup.validTill);
      }
      if (sortColumnName === KEY_STATE)
      {
        rc = t.sortState(a, b);
      }
      if (sortDirection === 0)
      {
        return rc;
      }
      else
      {
        return rc * (-1);
      }
    })
    return data;

  }

  getUserDataLastQueryDate() {
    return this.dataCache.dataUserLastQueryDate
  }

  getUsedVoucherLastQueryDate() {
    return this.dataCache.dataUsedVoucherLastQueryDate
  }


  getUsedVoucherSorted(sortColumnName, sortDirection) {
    let dataUsedVoucher = this.getSortedData(this.dataCache.getUsedVoucher(), sortColumnName, sortDirection);
    return dataUsedVoucher;
  }

  getFreeVoucherSorted(sortColumnName, sortDirection) {
    this.dataFreeVoucher = this.getSortedData(this.dataFreeVoucher, sortColumnName, sortDirection);

    return this.dataFreeVoucher;
  }

  getFreeVoucherCount() {
    if (!this.dataFreeVoucher)
    {
      return 0;
    }

    var count = 0;

    // eslint-disable-next-line
    this.dataFreeVoucher.map((item, i) => {
      if (item.VoucherGroup)
      {
        count += Number(item.VoucherGroup.voucherCount);
      }
    });

    return count;
  }

  getAvailableUserSort(sortColumnName, sortDirection) {
    let availableUser = this.getSortedData(this.availableUser, sortColumnName, sortDirection);

    return availableUser;
  }


  getSelectedAvailableUserGuids() {
    return this.selectedAvailableUserGuids ? this.selectedAvailableUserGuids : [];
  }

  setSelectedAvailableUsers(selectedUserGuids) {
    this.selectedAvailableUserGuids = selectedUserGuids;
    if (typeof this.onAvailableUsersSelected === 'function')
    {
      this.onAvailableUsersSelected(selectedUserGuids);
    }
  }

  setSelectedUsedVoucherUserGuid(guid) {
    this.selectedUsedVoucherUserGuid = guid;
  }

  getSelectedUsedVoucherUserGuid() {
    return this.selectedUsedVoucherUserGuid;
  }

  searchUsedVoucher(guid) {

    return this.dataCache.searchUsedVoucher(guid);
  }

  isUserSelected(guid) {

    if (!guid || !this.selectedAvailableUserGuids)
    {
      return false;
    }

    var found = false
    // eslint-disable-next-line
    this.selectedAvailableUserGuids.map((guidInArray, i) => {
      if (guidInArray === guid)
      {
        found = true;
      }
    });

    return found;

  }

  setSelectedFreeVoucherRow(selectedRows) {

    if (typeof selectedRows === "string")
    {
      this.selectedFreeVoucherGuid = selectedRows;
    }
    else
    {

      if (selectedRows && selectedRows.length > 0)
      {
        this.selectedFreeVoucherGuid = this.dataFreeVoucher[selectedRows[0]].VoucherGroup.guid;
      }
      else
      {
        this.selectedFreeVoucherGuid = null;
      }

    }

    if (typeof this.onFreeVoucherSelected === 'function')
    {
      this.onFreeVoucherSelected(this.selectedFreeVoucherGuid);
    }
  }

  isFreeVoucherSelected(guid) {

    if (!guid || !this.selectedFreeVoucherGuid)
    {
      return false;
    }

    if (this.selectedFreeVoucherGuid === guid)
    {
      return true;
    }

    return false;

  //console.log(this.selectedFreeVoucherGuid, guid,this.selectedFreeVoucherGuid && this.selectedFreeVoucherGuid === guid)
  //return this.selectedFreeVoucherGuid && this.selectedFreeVoucherGuid === guid;
  }

  getSelectedFreeVoucherGuid() {
    return this.selectedFreeVoucherGuid;
  }

  getSelectedUserGuids() {
    return this.selectedAvailableUserGuids ? this.selectedAvailableUserGuids.toString() : "";
  }


  //override
  sortState(a, b) {

    let numA = getNumberForUserState(a.CompanyUser.state, a.CompanyUser.hasVoucher, a.CompanyUser.voucherExpired);
    let numB = getNumberForUserState(b.CompanyUser.state, b.CompanyUser.hasVoucher, b.CompanyUser.voucherExpired);

    return numA > numB ? 1 : (numA === numB ? 0 : -1);
  }
}

export default DataProvider;
