/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React from 'react';
import { translate } from 'react-i18next';

import { GridList, GridTile } from 'material-ui/GridList';


import { PageHeader } from '../../components/pageheader';

import GruppenChart from './GruppenChart';
import AktivitaetenChart from './AktivitaetenChart';
import ChannelChart from './ChannelChart'
import MessageDetailAktivitaetenChart from './MessageDetailAktivitaetenChart'

import { WaitDialog } from '../../components/WaitDialog'
import { ModalDialog } from '../../components/ModalDialog'
import BaseModul from '../BaseModul';


const styles = {
  root: {
    width: 'fill',
    height: '100%'
  },
  gridList: {
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  licenseInfo: {
    color: '#00C1A7'
  }
};





class Dashboard extends BaseModul {

  constructor(props) {
    super(props);
    this.state = {
      usedVoucher: -1,
      allVoucher: -1,
    };
    this.updateDashboardLicense = this.updateDashboardLicense.bind(this);
  }

  showCommonButton() {
    return false;
  }

  updateDashboardLicense(usedVoucher, allVoucher) {

    if (typeof usedVoucher !== "undefined")
    {

      this.setState({
        usedVoucher: usedVoucher,
        allVoucher: allVoucher
      })
    }
  }
  renderLizenzCount() {
    const {t} = this.props;
    if (this.state.usedVoucher >= 0 && this.state.allVoucher >= 0)
    {
      return <div style={ styles.licenseInfo }>
               { t("modules-dashboard-Dashboard-dashboard-license", {
                   v1: this.state.usedVoucher,
                   v2: this.state.allVoucher
                 }) }
             </div>;
    }
    else
    {
      return <div></div>;
    }
  }

  renderHeaderView() {
    const {t} = this.props;

    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
               <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
                 { t("modules-dashboard-Dashboard-dashboard") }
               </PageHeader>
             </div>
             <div style={ this.getBaseStyle().right }>
               { this.renderLizenzCount() }
             </div>
           </div>

  }
  renderCommonButton() {
    // const {t} = this.props;
    return <div></div>;
  }

  renderMainView() {
    // const {t} = this.props;
    return <GridList cellHeight="auto" cols={ 1 } style={ styles.gridList }>
             <GridTile cols={ 1 } key="aktivitaeten">
               <AktivitaetenChart updateDashboardLicenseCallback={ this.updateDashboardLicense } />
             </GridTile>
             <GridTile cols={ 1 } key="gruppen">
               <GruppenChart />
             </GridTile>
             <GridTile cols={ 1 } key="channels">
               <ChannelChart />
             </GridTile>
             <GridTile cols={ 1 } key="messageDetailAktivitaeten">
               <MessageDetailAktivitaetenChart />
             </GridTile>
           </GridList>;


  }

  renderHiddenDialog() {
    return <div>
             <WaitDialog ref="waitDialog" />
             <ModalDialog ref="modalDialog" />
           </div>;
  }

}

export default translate(['common'])(Dashboard);
