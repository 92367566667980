import { RECEIVE_LIST_USED_VOUCHER, REQUEST_LIST_USED_VOUCHER, RECEIVE_LIST_FREE_VOUCHER, REQUEST_LIST_FREE_VOUCHER } from '../actions/index';

export function listUsedVoucher(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_LIST_USED_VOUCHER: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case RECEIVE_LIST_USED_VOUCHER: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: action.result["data"],
        hasData: true
      });
    }
    default:
      return state;
  }
}

export function listFreeVoucher(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_LIST_FREE_VOUCHER: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case RECEIVE_LIST_FREE_VOUCHER: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: action.result["data"],
        hasData: true
      });
    }
    default:
      return state;
  }
}
