/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getStatistics } from '../../actions/index';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Measure from 'react-measure';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
// import IconMenu from 'material-ui/IconMenu';
import Popover from 'react-simple-popover';

import IconButton from 'material-ui/IconButton';
import { SubPageHeader } from '../../components/pageheader';

import CircularProgress from 'material-ui/CircularProgress';

import IconTableAos from '../../ressources/tabelle_aos';
import IconTableIos from '../../ressources/tabelle_ios';

import IconInfo from '../../ressources/icon_info';

import ActionStars from 'material-ui/svg-icons/action/stars';

import { colors, styles } from './DashboardStyle';
import { getAdminMuiTheme } from '../../components/styles.js';

const adminMuiTheme = getAdminMuiTheme();

function AktivitatenChartTooltip(props) {

  const {t} = props;

  if (props.payload != null)
  {
    if (props.payload.length >= 2)
    {
      return <div style={ styles.dashboard_tooltip }>
               { t("modules-dashboard-AktivitatenChart-messagecount", {
                   v1: props.payload[1].value
                 }) }
               <br/>
               { t("modules-dashboard-AktivitatenChart-usercount", {
                   v1: props.payload[0].value
                 }) }
               <br/>
             </div>;
    }
  }
  return <div style={ styles.dashboard_tooltip }></div>;
}
const DISPLAY_TIME_WEEK = 1;
const DISPLAY_TIME_MONTH = 2;
const DISPLAY_TIME_ALL = 3;

export class AktivitaetenChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      displayValue: 1,
      timeValue: DISPLAY_TIME_WEEK,
      showInfoBox: false
    };
    this.handleChangeDisplay = this.handleChangeDisplay.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.currentData = this.currentData.bind(this);

    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleInfoButtonClose = this.handleInfoButtonClose.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.props.dispatch(getStatistics())
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== null)
    {
      this.props.updateDashboardLicenseCallback(nextProps.data.usedVoucher, nextProps.data.allVoucher);
    }
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  currentData() {

    if (this.state.displayValue === 1)
    {
      if (this.state.timeValue === DISPLAY_TIME_WEEK)
      {
        return this.props.data.dataDailyActiveWeek
      }
      if (this.state.timeValue === DISPLAY_TIME_MONTH)
      {
        return this.props.data.dataDailyActiveMonth
      }
      return this.props.data.dataDailyActiveAll
    }

    if (this.state.timeValue === DISPLAY_TIME_WEEK)
    {
      return this.props.data.dataMonthlyActiveWeek
    }
    if (this.state.timeValue === DISPLAY_TIME_MONTH)
    {
      return this.props.data.dataMonthlyActiveMonth
    }
    return this.props.data.dataMonthlyActiveAll
  }

  handleChangeDisplay(event, index, value) {
    this.setState({
      displayValue: value
    });
  }
  handleChangeTime(event, index, value) {
    this.setState({
      timeValue: value
    });
  }

  renderContent() {
    if (this.props.data !== null)
    {
      return this.renderContentWithData();
    }
    else
    {
      return this.renderProgress();
    }
  }

  renderProgress() {
    return <div style={ styles.dashboard_outerDiv }>
             <div style={ { width: '100%' } }>
               <center>
                 <CircularProgress />
               </center>
             </div>
           </div>
  }


  handleInfoButtonClick() {
    this.setState({
      showInfoBox: !this.state.showInfoBox
    });
  }

  handleInfoButtonClose() {
    this.setState({
      showInfoBox: false
    })
  }

  renderContentWithData() {
    const {t} = this.props;

    let {width} = this.state.dimensions;
    let windowWidth = this.state.width;
    if (width > windowWidth - 120)
    {
      width = windowWidth - 120;
    }
    return <div>
             { this.renderChartSubTitle() }
             <div style={ styles.dashboard_right }>
               <div style={ { float: 'left' } }>
                 <IconButton onClick={ this.handleInfoButtonClick } ref="infoButton">
                   <IconInfo />
                 </IconButton>
                 <Popover placement='bottom' show={ this.state.showInfoBox } onHide={ this.handleInfoButtonClose } target={ this.refs.infoButton } style={ styles.infoButton }>
                   <div style={ styles.popupHeaderText }>
                     <span style={ { color: '#FFFFFF', paddingTop: '4px' } }>
                       { t('modules-dashboard-AktivitatenChart-popOver-header1') }
                     </span>
                   </div>
                   <div style={ styles.popupText }>
                     <span style={ { color: '#DDDDDD', marginTop: '4px' } }>
                       { t('modules-dashboard-AktivitatenChart-popOver-text1') }
                     </span>
                   </div>
                   <div style={ { height: '10px' } }> </div>
                   <div style={ styles.popupHeaderText }>
                     <span style={ { color: '#FFFFFF', paddingTop: '14px' } }>
                       { t('modules-dashboard-AktivitatenChart-popOver-header2') }
                     </span>
                   </div>
                   <div style={ styles.popupText }>
                     <span style={ { color: '#DDDDDD', paddingTop: '4px' } }>
                       { t('modules-dashboard-AktivitatenChart-popOver-text2') }
                     </span>
                   </div>
                 </Popover>
               </div>
               <SelectField listStyle={ { backgroundColor: adminMuiTheme.palette.primary2Color } } value={ this.state.displayValue } onChange={ this.handleChangeDisplay } style={ styles.dashboard_selectbox }>
                 <MenuItem value={ 1 } primaryText={ t('modules-dashboard-AktivitatenChart-displayValue1') } />
                 <MenuItem value={ 2 } primaryText={ t('modules-dashboard-AktivitatenChart-displayValue2') } />
               </SelectField>
               <SelectField listStyle={ { backgroundColor: adminMuiTheme.palette.primary2Color } } value={ this.state.timeValue } onChange={ this.handleChangeTime } style={ styles.dashboard_selectbox }>
                 <MenuItem value={ DISPLAY_TIME_WEEK } primaryText={ t('modules-dashboard-AktivitatenChart-timeValue1') } />
                 <MenuItem value={ DISPLAY_TIME_MONTH } primaryText={ t('modules-dashboard-AktivitatenChart-timeValue2') } />
                 <MenuItem value={ DISPLAY_TIME_ALL } primaryText={ t('modules-dashboard-AktivitatenChart-timeValue3') } />
               </SelectField>
             </div>
             <div style={ styles.dashboard_outerDiv }>
               <div style={ styles.dashboard_column1 }>
                 <LineChart width={ width } height={ 200 } data={ this.currentData() } style={ styles.dashboard_lineChart }>
                   <XAxis dataKey="dateStr" />
                   <YAxis yAxisId="left" orientation="left" stroke={ colors.GRAY } type="number" domain={ ['dataMin-5', 'dataMax+5'] } tick={ false } tickLine={ false }
                   />
                   <YAxis yAxisId="right" orientation="right" stroke={ colors.GRAY } type="number" domain={ ['dataMin-5', 'dataMax+5'] } tick={ false } tickLine={ false }
                   />
                   <Tooltip content={ <AktivitatenChartTooltip t={ t } /> } />
                   <CartesianGrid horizontal={ false } />
                   <Line type="monotone" dataKey="users" stroke={ colors.WHITE } yAxisId="left" activeDot={ { r: 8 } } strokeWidth={ 2 } />
                   <Line type="monotone" dataKey="messages" stroke={ colors.YELLOW } yAxisId="left" strokeWidth={ 2 } />
                 </LineChart>
               </div>
             </div>
           </div>
  }

  renderChartSubTitle() {
    const {t} = this.props;

    return <div style={ styles.dashboard_subTitle }>
             <IconTableAos style={ styles.dashboard_subTitleIcon }></IconTableAos>
             <span style={ styles.dashboard_subTitle2 }>{ this.props.data.usersAllAndroid } </span>
             <IconTableIos style={ styles.dashboard_subTitleIcon } />
             <span style={ styles.dashboard_subTitle2 }>{ this.props.data.usersAlliOS } </span>
             <ActionStars style={ styles.dashboard_subTitleIconWhite } /> <span style={ styles.dashboard_subTitle2White }> { t("modules-dashboard-AktivitatenChart-usercount", {
                                                                                                                               v1: this.currentData().length > 0 ? this.currentData().slice(-1)[0].users : "no data yet for"
                                                                                                                             }) } </span>
             <ActionStars style={ styles.dashboard_subTitleIconYellow } /> <span style={ styles.dashboard_subTitle2Yellow }>{ t("modules-dashboard-AktivitatenChart-messagecount", {
                                                                                                                                v1: this.currentData().length > 0 ? this.currentData().slice(-1)[0].messages : "no data yet for"
                                                                                                                              }) }</span>
           </div>;
  }

  render() {
    const {t} = this.props;

    return (
      <Measure onMeasure={ (dimensions) => {
        this.setState({
          dimensions
        })
      } }>
        <div>
          <SubPageHeader>
            { t("modules-dashboard-AktivitatenChart-aktivitaeten") }
          </SubPageHeader>
          { this.renderContent() }
          <div style={ styles.dashboard_separator }>
          </div>
        </div>
      </Measure>
      );
  }
}

AktivitaetenChart.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object
}

function mapStateToProps(state) {
  //debugger;
  const {statistics} = state;
  return {
    isFetching: statistics.isFetching ? statistics.isFetching : false,
    error: statistics.error,
    data: statistics.hasData ? statistics.data : null
  }
}

const AktivitaetenChartTranslated = translate(['common'])(AktivitaetenChart);

export default connect(mapStateToProps)(AktivitaetenChartTranslated);
