import React, { PropTypes } from 'react';
import BaseModul from './modules/BaseModul.js'
import { PageHeader } from './components/pageheader';
import { Interpolate, translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import OrderLicenseDialog from './modules/license/OrderLicenseDialog'
import { WaitDialog } from './components/WaitDialog'
import { ModalDialog } from './components/ModalDialog'
import ErrorDialog from './components/ErrorDialog.js';
import { purchaseCompanyVoucher, getAdminInfo } from './actions/index';
import './Welcome.css';


class Welcome extends BaseModul {
  constructor(props) {
    super(props);
    //TODO: das ganze Zeug ist doppeler Code (vgl. License) -> Auslagern,
    //sodass eine OrderLicense-Komponente das hier alles übernimmt.
    this.orderLicense = this.orderLicense.bind(this);
    this.onOrderLicenseHandler = this.onOrderLicenseHandler.bind(this);
    this.onActionSuccessHandler = this.onActionSuccessHandler.bind(this);
    this.onActionErrorHandler = this.onActionErrorHandler.bind(this);
    this.getAdminInfoSuccessHandler = this.getAdminInfoSuccessHandler.bind(this);

  }
  renderHeaderView() {
    let adminInfo = this.state.adminInfo;
    let name = adminInfo !== undefined ? adminInfo.Admin.firstName + " " + adminInfo.Admin.name : "";

    let link = <Link to={ '/settings' }>
               <a>
                 { name }
               </a>
               </Link>;
    let headline = <Interpolate i18nKey='welcome-headline' v1={ link } />;

    return (<div style={ this.getBaseStyle().headerPageLabelDiv2 }>
              <PageHeader>
                <span className="regular">{ headline }</span>
              </PageHeader>
            </div>);
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadAdminInfo()

  }


  renderHiddenDialog() {}
  renderCommonButton() {}

  showCommonButton() {
    return false;
  }

  loadAdminInfo() {

    this.props.dispatch(getAdminInfo(true, this.getAdminInfoSuccessHandler, this.onLoadDataError));

  }

  getAdminInfoSuccessHandler(json) {
    this.setState({
      adminInfo: json
    })
  }

  onActionSuccessHandler(json) {
    this.refs.waitDialog.close();
  }
  onActionErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t, dispatch} = this.props;

    if (errorJO.ident)
    {
      this.refs.errorDialog.open({
        errorIdent: errorJO.ident
      });
    }
  }

  onOrderLicenseHandler(licenseCount, optInState) {
    const {dispatch} = this.props;

    this.refs.waitDialog.open();
    dispatch(purchaseCompanyVoucher(licenseCount, optInState, this.onActionSuccessHandler, this.onActionErrorHandler));
  }

  orderLicense() {
    this.refs.orderLicenseDialog.open({
      buttonOrderHandler: this.onOrderLicenseHandler,
      price: this.state.adminInfo.LicensePrice,
      optInState: this.state.adminInfo.Company.optInState
    });
  }
  renderMainView() {
    const {t} = this.props;
    let orderLink = <a href="#" onClick={ this.orderLicense }>
                      { t('welcome-license-description-link') }
                    </a>


    return (
      <div>
        <OrderLicenseDialog ref="orderLicenseDialog" t={ t } />
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        <ErrorDialog ref="errorDialog" t={ t } />
        <p>
          { t('welcome-intro') }
        </p>
        <h2>{ t('welcome-user-subheadline') }</h2>
        <div className="section"><img className="bildbreak" src="img/img_01.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-user-subheadline-create') }</h4>
          <p>
            { t('welcome-user-description') }
          </p>
          <a href="#/user" className="btn_blue">
            { t('welcome-user-btn') }
          </a>
        </div>
        <div className="section"><img className="bildbreak" src="img/img_02.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-license-subheadline-add') }</h4>
          <p>
            <Interpolate i18nKey='welcome-license-description' v1={ orderLink } />
          </p>
          <a href="#/license" className="btn_blue">
            { t('welcome-license-btn') }
          </a>
        </div>
        <h2>{ t('welcome-chat-subheadline') }</h2>
        <div className="section"><img className="bildbreak" src="img/img_03.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-channel-subheadline-create') }</h4>
          <p>
            { t('welcome-channel-description') }
          </p>
          <a href="#/channel" className="btn_blue">
            { t('welcome-channel-btn') }
          </a>
        </div>
        <div className="section"><img className="bildbreak" src="img/img_04.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-group-subheadline-add') }</h4>
          <p>
            { t('welcome-group-description') }
          </p>
          <a href="#/groups" className="btn_blue">
            { t('welcome-group-btn') }
          </a>
        </div>
        <h2>{ t('welcome-config-subheadline') }</h2>
        <div className="section"><img className="bildbreak" src="img/img_05.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-design-subheadline-create') }</h4>
          <p>
            { t('welcome-design-description') }
          </p>
          <a href="#/appdesign" className="btn_blue">
            { t('welcome-design-btn') }
          </a>
        </div>
        <div className="section"><img className="bildbreak" src="img/img_06.png" width="200" height="200" alt="" />
          <h4>{ t('welcome-settings-subheadline-create') }</h4>
          <p>
            { t('welcome-settings-description') }
          </p>
          <a href="#/appsettings" className="btn_blue">
            { t('welcome-settings-btn') }
          </a>
        </div>
      </div>);
  }
}

Welcome.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {

  // const {adminInfo} = state;

  return {
  }
}

const WelcomeTranslated = translate(['common'])(Welcome);

export default connect(mapStateToProps)(WelcomeTranslated);
