const TranslationCommonIt ={
    "SERVICE-ADMIN-ERR-0019": "Connessione a ginlo Business fallita",
    "SERVICE-ADMIN-ERR-0022": "Inserimento non valido",
    "SERVICE-ADMIN-ERR-0098": "Il numero di cellulare non è valido. Inserire il numero di cellulare, comprensivo di prefisso o città, nel seguente formato: \"012345678910\". Per i numeri di cellulare al di fuori della Germania, si prega di includere il codice del Paese (ad es. \"+31...\").",
    "SERVICE-ADMIN-ERR-0106": "Le informazioni di registrazione non sono valide.",
    "SERVICE-ADMIN-ERR-0106-1": "La password non è corretta.",
    "SERVICE-ADMIN-ERR-0109": "La sessione è scaduta.",
    "SERVICE-ADMIN-ERR-0110": "Non è stato possibile caricare l'immagine.",
    "SERVICE-ADMIN-ERR-0111": "L'immagine è troppo grande. La risoluzione massima consentita è 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "Il numero di telefono è già utilizzato.",
    "SERVICE-ADMIN-ERR-0114": "L'indirizzo e-mail è già utilizzato.",
    "SERVICE-ADMIN-ERR-0115": "Il formato del file .csv non è valido.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "Il formato del file .csv non è valido. La colonna Nome è vuota.",
    "SERVICE-ADMIN-ERR-0115-NAME": "Il formato del file .csv non è valido. La colonna Cognome è vuota.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "Il formato del file .csv non è valido. La colonna Numero di cellulare è vuota.",
    "SERVICE-ADMIN-ERR-0135": "L'immagine è troppo piccola. La risoluzione minima richiesta è 680 * 80 px.",
    "SERVICE-ADMIN-ERR-0153": "Non ci sono abbastanza licenze per questa azione. È possibile acquistare licenze aggiuntive in Amministrazione licenze.",
    "SERVICE-ADMIN-ERR-0154": "Il rappresentante non è autorizzato a compiere questa azione.",
    "SERVICE-ADMIN-ERR-0156": "Un altro amministratore ha già effettuato l'accesso al sistema. Per evitare conflitti, non è consentita la registrazione di più di un rappresentante.",
    "SERVICE-ADMIN-ERR-0157": "L'amministratore è stato cancellato.",
    "SERVICE-ADMIN-ERR-0158": "L'amministratore è stato disattivato.",
    "SERVICE-ADMIN-ERR-0176": "Si è verificato un errore durante il caricamento dell'anteprima del feed RSS.",
    "SERVICE-ADMIN-ERR-0177": "L'API è stata disattivata da un altro utente.",
    "SERVICE-ADMIN-ERR-0181": "I dati sono stati modificati dall'utente {{username}} su {{date}}.",
    "SERVICE-ADMIN-ERR-0182": "I dati sono stati cancellati dall'utente {{username}} su {{date}}.",
    "SERVICE-ADMIN-ERR-0183": "L'API è già abilitata.",
    "SERVICE-ERR-0057": "Timeout. L'utente è stato disconnesso.",
    "SERVICE-ERR-0123": "L'indirizzo e-mail non è valido.",
    "SERVICE-ERR-0124": "Il dominio dell'indirizzo e-mail indicato è gestito da un provider privato. Questo non è supportato per motivi di privacy.",
    "SERVICE-IMP-1": "Questo file è troppo grande.",
    "SERVICE-IMP-23": "Formato di file non corretto.",
    "SERVICE-IMP-30": "Il formato del file di importazione è errato (ad esempio, numero di colonne non corretto).",
    "SERVICE-IMP-35": "Un nome di gruppo utilizzato esiste più volte in ginlo Business Cockpit.",
    "SERVICE-IMP-36": "Un nome di canale utilizzato esiste più volte in ginlo Business Cockpit.",
    "SERVICE-NO_SERVER_RESPONSE": "Nessuna connessione al server.",
    "SERVICE-RSSTRANS-CLI-0001": "Il feed RSS all'URL richiesto non è disponibile. L'URL potrebbe essere bloccato dalle impostazioni del firewall.",
    "SERVICE-RSSTRANS-ERR-0007": "Il contenuto dell'URL indicato non è conforme alle specifiche RSS 2.0. Sono stati trovati caratteri non validi.",
    "SERVICE-RSSTRANS-ERR-0008": "Il contenuto dell'URL indicato non è conforme alle specifiche RSS 2.0. Non sono stati compilati tutti i campi obbligatori.",
    "SERVICE-RSSTRANS-ERR-0011": "Non è stato trovato alcun feed RSS valido all'URL indicato.",
    "SERVICE-RSSTRANS-ERR-0012": "Si è verificato un errore durante il caricamento dell'anteprima del feed RSS. L'URL richiesto non è disponibile.",
    "SERVICE-RSSTRANS-ERR-0014": "È stato trovato un feed Atom all'URL indicato. Questo formato non è supportato. Assicurarsi che si tratti di un feed RSS conforme a RSS 2.0.",
    "SERVICE-RSSTRANS-ERR-0015": "Non è stato trovato alcun contenuto nell'URL indicato.",
    "SERVICE-RSSTRANS-ERR-0016": "Il formato del feed RSS non è supportato.",
    "SERVICE-RSSTRANS-ERR-0017": "Il feed RSS contiene caratteri non validi e non consentiti.",
    "cancel": "Annullamento",
    "common-delete-btn": "Cancellare",
    "common-edit-btn": "Modifica",
    "components-ModalDialog-Warning": "Nota",
    "components-ModalDialog-cancel": "Annullamento",
    "components-ModalDialog-looseDataQuestion": "Se si annulla, le modifiche non verranno salvate. Annullare?",
    "components-ModalDialog-no": "No",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Sì",
    "components-NavigationLeft-appdesign": "Design dell'app",
    "components-NavigationLeft-appsettings": "Impostazioni dell'app",
    "components-NavigationLeft-channel": "Canali",
    "components-NavigationLeft-dashboard": "Cruscotto",
    "components-NavigationLeft-groups": "Gruppi",
    "components-NavigationLeft-license": "Licenze",
    "components-NavigationLeft-roles": "Ruoli utente",
    "components-NavigationLeft-user": "Utenti",
    "components-chatmessagetextfield-default-hint": "Inserire il testo del messaggio",
    "components-chatmessagetextfield-preview-time-placeholder": "Ora",
    "components-continue": "Continua",
    "components-messagecomposer-datepicker": "Selezionare la data",
    "components-messagecomposer-messagesendtime-delayed": "Alla data e all'ora seguenti",
    "components-messagecomposer-messagesendtime-now": "Inviare immediatamente",
    "components-messagecomposer-send": "Inviare",
    "components-messagecomposer-send-AttachmentTooBig": "Questo file è troppo grande.",
    "components-messagecomposer-timepicker": "Selezionare l'ora",
    "components-messagecomposer-uploadattachment": "Selezionare l'immagine o il file",
    "components-messageeditor-button-delete": "Cancellare i messaggi",
    "components-messageeditor-button-showolder": "Visualizzazione più vecchia",
    "components-messageeditor-confirmdeletemessage": "Tutti i messaggi in questo canale saranno cancellati. Cancellare comunque?",
    "components-messageeditor-header-messages": "Messaggi in questo canale",
    "components-messageeditor-header-newmessage": "Nuovo messaggio",
    "components-messageeditor-header-timedmessages": "Temporizzato",
    "components-messageeditor-rss-linkmore": "di più …",
    "components-messageeditor-type-audio": "Audio",
    "components-messageeditor-type-location": "Posizione",
    "components-messageeditor-type-video": "Video",
    "components-reload-data": "Aggiornare",
    "email": "Email",
    "err-empty-textfield": "Il campo non può essere vuoto.",
    "error": "Errore",
    "firstName": "Nome",
    "footer-link-details": "ginlo Manuale",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/en/",
    "footer-link-faq": "FAQ",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/en/faq/",
    "footer-link-impress": "Impronta",
    "footer-link-impress-url": "https://app-help.ginlo.net/en/imprint",
    "footer-link-press": "Stampa",
    "footer-link-press-url": "https://www.ginlo.net/en/about-us/",
    "footer-link-privacy": "Informativa sulla privacy",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/en/privacy",
    "footer-link-security": "ginlo Dati",
    "footer-link-security-url": "https://www.ginlo.net/en/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Business",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/en/ginlo-business/",
    "footer-link-support": "Supporto",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "Termini e condizioni",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/en/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "IT",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Password dimenticata",
    "header-btn-fr": "FR",
    "header-btn-logout": "Disconnettersi",
    "header-btn-settings": "Impostazioni",
    "header-btn-support": "Supporto",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Nome dell'azienda campione",
    "lastName": "Cognome",
    "login-2fa-token-textfield-hint": "Pin a due fattori (se attivato)",
    "login-email-textfield-hint": "Indirizzo e-mail",
    "login-forgot-pwd-button": "Set",
    "login-forgot-pwd-desc": "Inserire la password di recupero.",
    "login-forgot-pwd-desc-min-char": "Almeno 8 caratteri",
    "login-forgot-pwd-desc-spez-char": "Almeno un numero (0-9) o un carattere speciale",
    "login-forgot-pwd-desc-upper-char": "Lettere minuscole e maiuscole (a-z, A-Z)",
    "login-forgot-pwd-err-empty": "Questo campo è obbligatorio.",
    "login-forgot-pwd-err-not-equal": "Le password non sono identiche.",
    "login-forgot-pwd-new-pwd-desc": "Scegliere una nuova password.",
    "login-forgotPwd-button": "Password dimenticata",
    "login-initial-pwd-button": "Risparmiare",
    "login-initial-pwd-new-pwd-desc": "Scegliere la propria password.",
    "login-login-button": "Accesso",
    "login-pwd-new-textfield-hint": "Inserire la nuova password",
    "login-pwd-repeat-textfield-hint": "Ripetere la nuova password",
    "login-pwd-textfield-hint": "Inserire la password",
    "login-remember-email": "Ricorda l'indirizzo e-mail",
    "modules-appdesign-appdesign-btnResetLayout": "Reset del design dell'app",
    "modules-appdesign-appdesign-btncancel": "Annullamento",
    "modules-appdesign-appdesign-btnsave": "Risparmiare",
    "modules-appdesign-appdesign-header": "Design dell'app",
    "modules-appdesign-colorpicker-actionelements": "Elementi di azione",
    "modules-appdesign-colorpicker-blue": "blu",
    "modules-appdesign-colorpicker-color": "Colore",
    "modules-appdesign-colorpicker-contrast": "contrasto",
    "modules-appdesign-colorpicker-contrastdark": "Colore scuro di contrasto",
    "modules-appdesign-colorpicker-contrastlight": "Colore di contrasto chiaro",
    "modules-appdesign-colorpicker-dark": "scuro",
    "modules-appdesign-colorpicker-green": "Verde",
    "modules-appdesign-colorpicker-heavytrust": "Stato di fiducia elevato",
    "modules-appdesign-colorpicker-hex": "valore esadecimale",
    "modules-appdesign-colorpicker-light": "luce",
    "modules-appdesign-colorpicker-maincolor": "Colore principale",
    "modules-appdesign-colorpicker-normaltrust": "Stato di fiducia moderato",
    "modules-appdesign-colorpicker-red": "Rosso",
    "modules-appdesign-colorpicker-unsafetrust": "Stato fiduciario non sicuro",
    "modules-appdesign-colorpicker-yellow": "Blu",
    "modules-appdesign-cropimage-accept-btn": "Applicare",
    "modules-appdesign-cropimage-title": "Ritagliare il logo su misura, se necessario.",
    "modules-appdesign-cropimage-zoom-hint": "* È possibile ingrandire o ritagliare l'immagine utilizzando la rotella di scorrimento del pulsante del mouse.",
    "modules-appdesign-image-description": "*.png con alfa",
    "modules-appdesign-image-description2": "800 x 160 px consigliati",
    "modules-appdesign-mainView-description": "Modellate l'app per adattarla alla vostra identità aziendale in pochi passi.",
    "modules-appdesign-mainView-removeCompanyLogo": "Cancellare l'immagine",
    "modules-appdesign-mainView-subheading": "Regolate l'app in base alle vostre esigenze.",
    "modules-appdesign-mainView-uploadImage": "Selezionare l'immagine",
    "modules-appsettings-appsettings-btnResetConfig": "Ripristino delle impostazioni dell'app",
    "modules-appsettings-header": "Impostazioni dell'app",
    "modules-appsettings-hinttext-range1": "Da 0 a 10 minuti",
    "modules-appsettings-hinttext-range2": "Da 0 a 100 ripetizioni",
    "modules-appsettings-hinttext-range3": "Da 0 a 99 caratteri",
    "modules-appsettings-hinttext-range4": "Da 0 a 365 giorni",
    "modules-appsettings-hinttext-range5": "Da 0 a 4 classi",
    "modules-appsettings-label-chars": "Personaggi",
    "modules-appsettings-label-days": "Giorni",
    "modules-appsettings-label-minutes": "Min.",
    "modules-appsettings-label-repeat": "Rpt.",
    "modules-appsettings-mainView-contactpolicy": "Linee guida per il contatto",
    "modules-appsettings-mainView-mediapolicy": "Linee guida per i media",
    "modules-appsettings-mainView-pwcomplexity": "Complessità della password",
    "modules-appsettings-mainView-pwpolicy": "Linee guida per le password",
    "modules-appsettings-selectfield-deleteaccount-inactive": "disattivare",
    "modules-appsettings-selectfield-deleteaccount-tries": "Tentativi",
    "modules-appsettings-selectfield-deleteaccount-try": "Tentativo",
    "modules-appsettings-table-companyindexname": "Nome della directory aziendale",
    "modules-appsettings-table-companyindexnamedescr": "Modificare qui il nome della directory aziendale che deve essere visualizzata dagli utenti sotto la voce contatti.",
    "modules-appsettings-table-contactClosedUserGroup": "Gruppo di utenti ristretto attivato",
    "modules-appsettings-table-contactClosedUserGroupDescr": "Quando questa funzione è attivata, l'utente può comunicare solo con i contatti aggiunti in ginlo Cockpit. Non è inoltre possibile chattare oltre che con gli amministratori.",
    "modules-appsettings-table-deletedatacount": "Cancellare i dati dopo un numero eccessivo di tentativi falliti",
    "modules-appsettings-table-deletedatacountdescr": "Inserire il numero di tentativi di accesso consentiti prima che i contenuti dell'applicazione vengano eliminati automaticamente.",
    "modules-appsettings-table-disablebackup": "Impedire il backup delle app in iCloud/Google Drive",
    "modules-appsettings-table-disablebackupdescr": "Con questa impostazione, gli utenti non possono eseguire il backup su iCloud o Google Drive o importare backup da questi provider. Per il backup dei dati, si consiglia l'accoppiamento con un secondo dispositivo.",
    "modules-appsettings-table-disablecamera": "Negare l'accesso alla telecamera.",
    "modules-appsettings-table-disablecameradescr": "Quando questa funzione è attivata, un dipendente non può più registrare o inviare fotografie e video dalla fotocamera.",
    "modules-appsettings-table-disablecopypaste": "Negare l'accesso al copia/incolla",
    "modules-appsettings-table-disablecopypastedescr": "Quando questa funzione è attivata, un dipendente non può più copiare testo e media per incollarli nelle chat o in applicazioni di terzi.",
    "modules-appsettings-table-disableexportchat": "Disattivare l'esportazione del contenuto della chat come file di testo",
    "modules-appsettings-table-disableexportchatdescr": "Impostando questa opzione su attivo si impedisce l'esportazione di singole chat dall'app in un file di testo.",
    "modules-appsettings-table-disablefaceandtouchid": "Blocco dell'attivazione tramite Touch/Face-ID",
    "modules-appsettings-table-disablefaceandtouchiddescr": "Se questa impostazione è selezionata, gli utenti non possono utilizzare Touch/Face ID (iPhone) o l'impronta digitale (Android) per sbloccare l'app.",
    "modules-appsettings-table-disablelocation": "Negare l'accesso ai dati geografici",
    "modules-appsettings-table-disablelocationdescr": "Quando questa funzione è attivata, un dipendente non può più condividere i dati sulla posizione.",
    "modules-appsettings-table-disablemicrophone": "Negare l'accesso al microfono",
    "modules-appsettings-table-disablemicrophonedescr": "Quando questa funzione è attivata, un dipendente non può più inviare registrazioni audio.",
    "modules-appsettings-table-disablepushpreview": "Impedire l'anteprima nelle notifiche push",
    "modules-appsettings-table-disablepushpreviewdescr": "Con questa impostazione, il contenuto dei messaggi sui dispositivi mobili non viene visualizzato nell'anteprima push. Questo aumenta la sicurezza, in quanto il contenuto può essere letto solo con l'app.",
    "modules-appsettings-table-disablerecoverycode": "Disattivare il codice di recupero",
    "modules-appsettings-table-disablerecoverycodedescr": "Quando questa funzione è attivata, l'utente non può utilizzare la funzione \"password dimenticata\".",
    "modules-appsettings-table-disablesendmedia": "Disattivare l'invio e l'inoltro dei file multimediali",
    "modules-appsettings-table-disablesendmediadescr": "Quando questa impostazione è attivata, gli allegati non possono essere inviati o inoltrati.",
    "modules-appsettings-table-disablesendvcard": "Negare l'accesso ai contatti",
    "modules-appsettings-table-disablesendvcarddescr": "Quando questa funzione è attivata, un dipendente non può più inviare o ricevere contatti.",
    "modules-appsettings-table-disablesimsmerecovery": "Forzare il codice di recupero tramite l'amministratore",
    "modules-appsettings-table-disablesimsmerecoverydescr": "Quando questa funzione è attivata, gli utenti che dimenticano la password devono attendere l'approvazione esplicita dell'amministratore prima che venga inviato un codice di recupero.",
    "modules-appsettings-table-forcePwComplexity": "Obbligo di complessità delle password",
    "modules-appsettings-table-forcePwComplexityDescr": "Forzare la complessità della password dell'app",
    "modules-appsettings-table-localmedia": "Non consentire il salvataggio dei media in locale",
    "modules-appsettings-table-localmediadescr": "Impostando questa opzione come attiva si rimuove l'opzione \"Salva media\", il che significa che le immagini e i video non possono essere salvati localmente.",
    "modules-appsettings-table-passwordmaxduration": "Validità della password in giorni",
    "modules-appsettings-table-passwordmaxdurationdescr": "Definire la durata della validità della password in giorni, ad esempio \"30 giorni\". 30 giorni significa che dopo 30 giorni l'utente deve scegliere una nuova password.",
    "modules-appsettings-table-passwordminclasses": "Livello minimo di complessità (cifre, caratteri speciali, lettere minuscole e maiuscole)",
    "modules-appsettings-table-passwordminclassesdescr": "Questa impostazione consente di definire il numero minimo di classi di caratteri (cifre, caratteri speciali, lettere minuscole e maiuscole) che devono essere utilizzate nella password. 2 significa che la password deve contenere almeno due classi (come cifre + lettere minuscole o caratteri speciali + cifre).",
    "modules-appsettings-table-passwordminlength": "Numero minimo di caratteri",
    "modules-appsettings-table-passwordminlengthdescr": "Questo parametro definisce la complessità della password obbligatoria in base al numero minimo di caratteri. 0 caratteri significa che non esiste una lunghezza minima.",
    "modules-appsettings-table-passwordminlowcase": "Numero minimo di lettere minuscole",
    "modules-appsettings-table-passwordminlowcasedescr": "Definisce il numero di lettere minuscole da utilizzare, ad esempio \"abcde\". 0 caratteri significa che non sono richieste lettere minuscole.",
    "modules-appsettings-table-passwordminnumbers": "Numero minimo di cifre",
    "modules-appsettings-table-passwordminnumbersdescr": "Definire il numero di cifre da utilizzare, ad esempio \"12345\". 0 caratteri significa che non sono necessarie cifre.",
    "modules-appsettings-table-passwordminspecialchars": "Numero minimo di caratteri speciali",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Definisce quanti caratteri speciali, come '!§$%', devono essere utilizzati. 0 caratteri significa che non sono richiesti caratteri speciali.",
    "modules-appsettings-table-passwordminuppercase": "Numero minimo di lettere maiuscole",
    "modules-appsettings-table-passwordminuppercasedescr": "Definire il numero di lettere maiuscole, ad esempio \"ABCDE\", da utilizzare. 0 caratteri significa che non sono necessarie lettere maiuscole.",
    "modules-appsettings-table-pwinact": "Forzare il controllo della password dopo l'inattività di …",
    "modules-appsettings-table-pwinactdescr": "Questo parametro consente di definire per quanto tempo l'applicazione può essere inattiva prima che l'utente debba reinserire la password.",
    "modules-appsettings-table-pwpolicy": "Verifica forzata della password all'avvio dell'applicazione",
    "modules-appsettings-table-pwpolicydescr": "Se questa funzione è attivata, l'applicazione richiederà la password dell'utente a ogni avvio.",
    "modules-appsettings-table-pwwdh": "La password può essere riutilizzata dopo X modifiche",
    "modules-appsettings-table-pwwdhdescr": "È possibile definire quante volte un utente deve utilizzare una nuova password prima di poter riutilizzare una password precedente. 1 ripetizione significa che la password può essere utilizzata dopo che nel frattempo è stata impostata una password diversa.",
    "modules-appsettings-table-sharemedia": "Disattivare la condivisione dei file multimediali con altre applicazioni",
    "modules-appsettings-table-sharemediadescr": "Quando questa impostazione è attivata, le immagini e i video non possono più essere salvati e i file non possono essere visualizzati.",
    "modules-appsettings-table-simplecode": "Vietato utilizzare come password un semplice codice a 4 cifre",
    "modules-appsettings-table-simplecodedescr": "Se questa impostazione è attivata, gli utenti non possono utilizzare un semplice codice a 4 cifre come password. Per garantire che le password a 4 cifre esistenti debbano essere modificate, definire una complessità di password appropriata.",
    "modules-channel-commonbutton-btnUp": "in alto",
    "modules-channels-Channel-channel": "Gestire i canali",
    "modules-channels-Channel-confirmDeleteChannel": "Siete sicuri di voler eliminare il Canale?",
    "modules-channels-ChannelList-table-column1": "Nome del canale",
    "modules-channels-NewChannel": "Creare un nuovo canale",
    "modules-channels-NewChannel-noMember": "Nessun membro del canale",
    "modules-channels-channel-btnDeleteChannel": "Cancellare il canale",
    "modules-channels-channel-tab-editChannel": "Modifica del canale",
    "modules-channels-channel-tab-messages": "Messaggi",
    "modules-channels-messages-senddateinvalid": "Scegliere una data futura.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Attività",
    "modules-dashboard-AktivitatenChart-displayValue1": "Utenti attivi giornalieri",
    "modules-dashboard-AktivitatenChart-displayValue2": "Utenti attivi mensili",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} messaggi",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Utenti attivi giornalieri",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Utenti attivi mensili",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Numero di utenti che aprono l'app ogni giorno",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Numero di utenti che aprono l'app mensilmente",
    "modules-dashboard-AktivitatenChart-timeValue1": "Una settimana",
    "modules-dashboard-AktivitatenChart-timeValue2": "Un mese",
    "modules-dashboard-AktivitatenChart-timeValue3": "Periodo totale",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} utenti",
    "modules-dashboard-ChannelChart-channels": "Canali",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Selezionare un canale.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Selezionare un gruppo.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "nessun dato ancora disponibile",
    "modules-dashboard-ChannelChart-popOver-text1": "I Top 5 sono i messaggi i cui file, immagini o link sono stati cliccati più frequentemente dagli utenti.",
    "modules-dashboard-ChannelChart-table-column1": "Nome",
    "modules-dashboard-ChannelChart-table-column2": "Utenti",
    "modules-dashboard-ChannelChart-table-column3": "Messaggi",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "Gruppi generati dagli utenti",
    "modules-dashboard-ChannelChart-table-showAll": "Mostra tutti …",
    "modules-dashboard-ChannelChart-table-title": "Le 5 conversazioni più importanti",
    "modules-dashboard-ChannelChart-table-titleInfo": "Numero di clic sul link",
    "modules-dashboard-ChannelChart-timeValue1": "Una settimana",
    "modules-dashboard-ChannelChart-timeValue2": "Un mese",
    "modules-dashboard-Dashboard-dashboard": "Cruscotto",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} di {{v2}} le licenze sono attive",
    "modules-dashboard-GruppenChart-groups": "Gruppo amministrato",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} messaggi",
    "modules-dashboard-GruppenChart-table-column1": "Nome",
    "modules-dashboard-GruppenChart-table-column2": "Utenti",
    "modules-dashboard-GruppenChart-table-column3": "Messaggi",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} messaggi nei gruppi generati dagli utenti",
    "modules-dashboard-GruppenChart-table-showAll": "Mostra tutti …",
    "modules-dashboard-GruppenChart-table-title": "Messaggi",
    "modules-dashboard-GruppenChart-timeValue1": "Una settimana",
    "modules-dashboard-GruppenChart-timeValue2": "Un mese",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Mix di messaggi",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "Una settimana",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "Un mese",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Periodo totale",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} messaggi totali",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} messaggi individuali",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} gruppi",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} canali",
    "modules-group-group-identifier": "Gruppo",
    "modules-group-groups-identifier": "Gruppi",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Controllo",
    "modules-groups-GroupList-table-column1": "Nome del gruppo",
    "modules-groups-GroupList-table-column2": "Invitato",
    "modules-groups-GroupList-table-column3": "Iscritto",
    "modules-groups-GroupList-table-column4": "Eccezionale",
    "modules-groups-GroupList-table-column5": "Ultima attività",
    "modules-groups-Groups-btnCancelNewGroup": "Annullamento",
    "modules-groups-Groups-btnCreateGroup": "Creare un nuovo gruppo",
    "modules-groups-Groups-btnDeleteGroup": "Cancellare il gruppo",
    "modules-groups-Groups-confirmDeleteGroup": "Siete sicuri di voler eliminare il gruppo?",
    "modules-groups-Groups-groups": "Gestire i gruppi",
    "modules-groups-NewGroup-btnImportImage": "Selezionare l'immagine",
    "modules-groups-NewGroup-newgroup": "Creare un nuovo gruppo",
    "modules-groups-NewGroup-noMember": "Nessun membro del gruppo",
    "modules-groups-NewGroup-placeholder-RSS-preview": "Anteprima RSS",
    "modules-groups-NewGroup-placeholder-feed": "Impostare una fonte RSS esterna.",
    "modules-groups-NewGroup-placeholder-name": "Nome",
    "modules-groups-NewGroup-searchHint": "Ricerca per nome, cognome o parola chiave",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Chiudere",
    "modules-license-DPAGOrderLicenseDialog-data-link": "dati",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "I dati significano",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) dati relativi al contratto e all'inventario, ad esempio società, indirizzi, indirizzi e-mail o numeri di telefono di contatti personali e informazioni sul servizio clienti, nonché dati di pagamento e fatturazione, e",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) dati sul traffico, ad esempio informazioni sull'ora, la durata e il volume dei messaggi inviati dagli utenti, nonché gli stessi messaggi criptati ancora in corso di trasmissione.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "Ulteriori informazioni sono disponibili sul sito {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "qui",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "http://sims.me/datenuebergang/business/en",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "Desidero garantire la continuità del servizio di messaggistica ginlo Business per i miei utenti e pertanto incarico ginlo.net GmbH di fornire i servizi ginlo Business per le mie licenze esistenti a partire dalla data di scadenza delle stesse presso Deutsche Post AG. Per la fornitura dei servizi, acconsento al trasferimento dei miei dati e di {{v1}} dei miei utenti da Brabbler AG a ginlo.net GmbH. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "Con la presente ordino",
    "modules-license-DPAGOrderLicenseDialog-order2": "nuove licenze.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Informativa sulla privacy",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "Il sito {{v1}} e {{v2}} di ginlo.net GmbH si applicano a tutte le licenze.",
    "modules-license-DPAGOrderLicenseDialog-text1": "SiMSme Business (ora ginlo Business) ha un nuovo proprietario: ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH). Gli ordini per le nuove licenze possono essere effettuati solo presso ginlo.net GmbH. ",
    "modules-license-License-btn-new": "Ordinare una nuova licenza",
    "modules-license-License-btn-select": "Assegnazione della licenza",
    "modules-license-License-delete-user-btn": "Cancellare l'utente",
    "modules-license-License-delete-user-text": "Si è certi di voler eliminare l'utente, bloccandone così l'accesso all'applicazione?",
    "modules-license-License-delete-user-title": "Cancellare {{v1}}?",
    "modules-license-License-extend-header": "Rinnovo della licenza",
    "modules-license-License-extend-text": "Assegnare la licenza a ",
    "modules-license-License-license": "Gestione delle licenze",
    "modules-license-License-revoke-btn": "Revoca della licenza",
    "modules-license-License-revoke-text": "Siete sicuri di voler revocare la licenza all'utente?",
    "modules-license-License-revoke-title": "Revocare la licenza a {{v1}}?",
    "modules-license-License-searchfield-hint": "Ricerca per nome, cognome o parola chiave",
    "modules-license-License-table-column-count": "Numero",
    "modules-license-License-table-column-expireDate": "Valido fino a",
    "modules-license-License-table-column-license": "Licenza",
    "modules-license-License-table-column-licenses": "Licenze",
    "modules-license-License-table-column-more-assign": "Assegnazione della licenza",
    "modules-license-License-table-column-more-delete": "Cancellare la licenza",
    "modules-license-License-table-column-more-extend": "Rinnovo della licenza",
    "modules-license-License-table-column-more-revoke": "Revoca della licenza",
    "modules-license-License-table-header-availableUser": "Utenti disponibili",
    "modules-license-License-table-header-freeLicense": "Licenze disponibili",
    "modules-license-License-table-header-usedLicense": "Licenze attive",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/en/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/en/privacy",
    "modules-license-license-assignment-error": "Assegnazione non possibile. Sono disponibili solo {{free}} licenze libere.",
    "modules-roles-NewRole-noMember": "Nessun membro",
    "modules-roles-NewRole-placeholder-name": "Nome",
    "modules-roles-NewRole-searchHint": "Ricerca per nome, cognome o parola chiave",
    "modules-roles-Newrole-newrole": "Creare un nuovo ruolo",
    "modules-roles-RoleList-table-column1": "Nome del ruolo",
    "modules-roles-RoleList-table-column2": "# Utenti",
    "modules-roles-Roles-btnCreateRole": "Creare un nuovo ruolo",
    "modules-roles-Roles-btnDeleteRole": "Eliminare il ruolo",
    "modules-roles-Roles-confirmDeleteRole": "Siete sicuri di voler eliminare il ruolo?",
    "modules-roles-Roles-roles": "Gestire i ruoli degli utenti",
    "modules-settings-Admin-admin": "Rappresentante",
    "modules-settings-Admin-btnActivateAdmin": "Abilitazione",
    "modules-settings-Admin-btnCancelNewAdmin": "Annullamento",
    "modules-settings-Admin-btnCreateNewAdmin": "Creare un nuovo rappresentante",
    "modules-settings-Admin-btnDeactivateAdmin": "Disattivare",
    "modules-settings-Admin-btnDeleteAdmin": "Cancellare",
    "modules-settings-Admin-btnSaveNewAdmin": "Risparmiare",
    "modules-settings-Admin-confirmDeleteAdmin": "È sicuro di voler eliminare il rappresentante?",
    "modules-settings-Admin-search": "Inserire il termine di ricerca",
    "modules-settings-Admin-setAdminEnabled": "Siete sicuri di volere che {{adminName}} conceda tutti i diritti a un rappresentante. Ciò include la creazione, la modifica e l'eliminazione di nuovi utenti, gruppi e canali.",
    "modules-settings-Admin-setAdminEnabled-title": "È stato creato un rappresentante",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Annullamento",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Abilitazione",
    "modules-settings-AdminDetails-last-login": "Ultimo accesso su",
    "modules-settings-AdminList-table-adminStatus1": "creato",
    "modules-settings-AdminList-table-adminStatus2": "disabilitato",
    "modules-settings-AdminList-table-adminStatus3": "attivo",
    "modules-settings-AdminList-table-adminStatus4": "cancellato",
    "modules-settings-AdminList-table-column1": "Cognome",
    "modules-settings-AdminList-table-column2": "Nome",
    "modules-settings-AdminList-table-column3": "Email",
    "modules-settings-AdminList-table-column4": "Numero di cellulare",
    "modules-settings-AdminList-table-column6": "Attività",
    "modules-settings-AdminList-table-column7": "Ultimo accesso",
    "modules-settings-AdminList-table-row-never-log-in": "mai",
    "modules-settings-ApiCredentialsDialog-close-button": "Chiudere",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Token di autenticazione",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "Impronte digitali del certificato utente",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "URL di monitoraggio",
    "modules-settings-ApiCredentialsDialog-column-password": "Password",
    "modules-settings-ApiCredentialsDialog-column-username": "Nome utente",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Valido fino a",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Scaduto",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "Copiare l'URL",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "È possibile verificare la validità del certificato con la chiave pubblica del file .CRT.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Scarica il certificato .CRT",
    "modules-settings-ApiCredentialsDialog-edit-button": "Modifica",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "Con questo URL, potete monitorare lo stato delle importazioni utilizzando il vostro sistema di monitoraggio. Durante questo processo non verranno trasmessi dati personali. Il formato della richiesta è descritto nella documentazione dell'API.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Inviare un nuovo certificato",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Generare un nuovo URL",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Generare una nuova password",
    "modules-settings-ApiCredentialsDialog-title": "Informazioni di accesso",
    "modules-settings-DeleteApiUserDialog-text": "In questo modo si interromperà la sincronizzazione automatica e i dati di accesso e l'URL di monitoraggio verranno eliminati.",
    "modules-settings-DeleteApiUserDialog-title": "Si vuole davvero disabilitare l'API?",
    "modules-settings-NewAdmin-hint": "Può creare, modificare e cancellare utenti, gruppi e canali. Tuttavia, il rappresentante non può creare altri rappresentanti.",
    "modules-settings-NewAdmin-hint-title": "Il rappresentante riceve tutti i diritti di amministratore.",
    "modules-settings-NewAdmin-newadmin": "Creare un nuovo rappresentante",
    "modules-user-AdminList-table-auditLog-active": "Attivato su",
    "modules-user-AdminList-table-auditLog-created": "Creato il",
    "modules-user-AdminList-table-auditLog-deactive": "Disabilitato su",
    "modules-user-AdminList-table-auditLog-delete": "Cancellato il",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Esportazione di tutti i dispositivi utilizzati in un file CSV.",
    "modules-user-ChooseCSVExport-title": "Selezionare i dati da esportare",
    "modules-user-ChooseTemplate-csv-detail": "CSV semplificato per la creazione di utenti una tantum",
    "modules-user-ChooseTemplate-ldap-detail": "CSV esteso per l'aggiornamento continuo di LDAP e l'assegnazione automatica a gruppi e canali",
    "modules-user-ChooseTemplate-title": "Scaricare un modello di importazione",
    "modules-user-Keyword-deleteKeyword": "Si vuole davvero eliminare la parola chiave {{keyword}} e tutte le istanze in cui viene utilizzata?",
    "modules-user-Keyword-jumpToUser": "Si vogliono scartare le modifiche attuali e passare a {{displayName}}?",
    "modules-user-Keyword-searchHint": "Ricerca per parola chiave",
    "modules-user-Keyword-showUsage": "Display",
    "modules-user-Keyword-table-column1": "Parola chiave",
    "modules-user-Keyword-table-column2": "Applicare",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Annullamento",
    "modules-user-NewUser-btnNewUserSave": "Risparmiare",
    "modules-user-NewUser-keyword": "Parola chiave",
    "modules-user-NewUser-keywordEdit": "Modifica parole chiave",
    "modules-user-NewUser-keywordEditEnd": "Chiudere l'editor",
    "modules-user-NewUser-newuser": "Creare dati utente",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "importazione_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "Volete assegnare una licenza?",
    "modules-user-User-assignVoucher-title": "{{displayName}} è stato creato.",
    "modules-user-User-assignVoucherBtnCancel": "Assegnazione successiva ",
    "modules-user-User-assignVoucherBtnOk": "Assegnazione della licenza",
    "modules-user-User-assignVouchersByImportID": "Si desidera assegnare automaticamente una licenza a tutti gli utenti importati?",
    "modules-user-User-assignVouchersByImportID-title": "L'importazione degli utenti è riuscita: {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Assegnare le licenze",
    "modules-user-User-btnCancelNewUser": "Annullamento",
    "modules-user-User-btnCreateNewuser": "Creare dati utente",
    "modules-user-User-btnDeleteUser": "Eliminare l'utente",
    "modules-user-User-btnImportUser": "Importazione dei dati utente",
    "modules-user-User-btnImportVorlage": "Importazione ed esportazione CSV",
    "modules-user-User-btnRevokeLicence": "Licenza di blocco",
    "modules-user-User-btnSaveNewUser": "Risparmiare",
    "modules-user-User-btnSaveUser": "Salva le modifiche",
    "modules-user-User-confirmDeleteUser": "Si è certi di voler eliminare l'utente?",
    "modules-user-User-confirmRevokeLicense": "Si è certi di voler bloccare la licenza dell'utente, bloccando così l'accesso all'applicazione?",
    "modules-user-User-email-hinttext": "e-mail",
    "modules-user-User-importLdap-applyData": "Continua",
    "modules-user-User-importLdap-cancel": "Annullamento",
    "modules-user-User-importLdap-done": "Chiudere",
    "modules-user-User-importLdap-exportApplyData": "Rapporto di esportazione e importazione",
    "modules-user-User-importLdap-exportPreviewData": "Rapporto di esportazione",
    "modules-user-User-importLdap-state-applyData": "I dati sono in fase di elaborazione",
    "modules-user-User-importLdap-state-done": "Modifiche adottate",
    "modules-user-User-importLdap-state-fatal": "L'azione è stata annullata a causa di un errore.",
    "modules-user-User-importLdap-state-preflight": "I dati sono in fase di revisione.",
    "modules-user-User-importLdap-state-waitConfirm": "Recensione completa",
    "modules-user-User-importLdap-table-column1": "Categoria",
    "modules-user-User-importLdap-table-column2": "Numero di voci",
    "modules-user-User-importLdap-table-column3": "Note",
    "modules-user-User-importLdap-table-column4": "Avvertenze",
    "modules-user-User-importLdap-table-column5": "Errori",
    "modules-user-User-importLdap-table-row1": "Utenti",
    "modules-user-User-importLdap-table-row2": "Gruppi",
    "modules-user-User-importLdap-table-row3": "Canali",
    "modules-user-User-importLdap-table-row4": "Parole chiave",
    "modules-user-User-search": "Inserire il termine di ricerca",
    "modules-user-User-user": "Amministrazione degli utenti ({{v1}})",
    "modules-user-UserList-popover-phone": "Inserire i numeri di cellulare nel formato \"012345678910\". Per i numeri al di fuori della Germania, si prega di includere il codice del paese ma non il prefisso \"0\", ad esempio \"+4312345678910\".",
    "modules-user-UserList-table-cmdDeleteUser": "Eliminare l'utente",
    "modules-user-UserList-table-cmdEdit": "Modifica",
    "modules-user-UserList-table-cmdRevokeLicense": "Licenza di blocco",
    "modules-user-UserList-table-column1": "Cognome",
    "modules-user-UserList-table-column2": "Nome",
    "modules-user-UserList-table-column3": "Email",
    "modules-user-UserList-table-column4": "Numero di cellulare",
    "modules-user-UserList-table-column6": "Parola chiave",
    "modules-user-UserList-table-placeHolder-column1": "Cognome",
    "modules-user-UserList-table-placeHolder-column2": "Nome",
    "modules-user-UserList-table-placeHolder-column3": "Email",
    "modules-user-UserList-table-placeHolder-column4": "Numero di cellulare",
    "modules-user-UserList-table-placeHolder-column6": "Parola chiave",
    "modules-user-UserList-table-placeHolder-column8": "Dipartimento",
    "modules-user-UserList-table-roomWriterHeader": "Accesso in scrittura",
    "modules-user-UserList-table-userStatus1": "Utente creato",
    "modules-user-UserList-table-userStatus2": "Licenza assegnata",
    "modules-user-UserList-table-userStatus3": "Licenza attiva",
    "modules-user-UserList-table-userStatus4": "Licenza scaduta",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Licenza scaduta - utente bloccato",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Licenza revocata - utente bloccato",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Licenza attiva - amministrazione accettata",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "Convalida dell'e-mail annullata",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "In attesa della convalida dell'e-mail",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Convalida del numero di cellulare annullata",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "In attesa della convalida del numero di cellulare",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Account cancellato dall'utente",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Richiesta dell'amministrazione respinta",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "Utente creato - nessuna licenza assegnata",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Licenza assegnata - in attesa di amministrazione ",
    "modules-user-UserList-userCreated": "L'utente {{firstName}} {{lastName}} è stato creato con successo.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Assegnazione della licenza",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Assegnare la licenza in un secondo momento",
    "modules-user-alert-btnSendManagementRequest": "Reinvio della richiesta",
    "modules-user-alert-sendManagementRequestHint": "L'utente ha rifiutato la richiesta di autorizzazioni amministrative.",
    "modules-user-device-deviceList": "Dispositivi in uso",
    "modules-user-device-unknown-devicename": "Nome del dispositivo sconosciuto",
    "modules-user-managementrequest-messagesent": "La richiesta di gestione dell'app è stata inviata all'utente.",
    "modules-user-password-btnSendRecoveryEMail": "Inviare il codice di recupero",
    "modules-user-password-btnShowRecoveryCode": "Mostra codice di recupero",
    "modules-user-password-revoverPasswordHint": "Questo utente ha dimenticato la password del proprio dispositivo. Si prega di inviare il codice di recupero.",
    "modules-user-recover-bottomBarText": "L'utente ha dimenticato la password.",
    "modules-user-recoverycode-messagesent": "Il codice di recupero è in arrivo all'utente. Troverete una copia dell'e-mail nella vostra casella di posta.",
    "modules-user-recoverycode-title": "Codice di recupero",
    "okay": "Ok",
    "phone": "Numero di cellulare",
    "save": "Risparmiare",
    "secret": "Segreto",
    "settings-admin": "Amministratore",
    "settings-admin-change-pwd": "Modifica della password",
    "settings-btnDeleteCompany": "Cancellare l'account",
    "settings-company-label-ustIdNr": "Numero di partita IVA",
    "settings-companycockpittoken": "Introduzione dell'EMM",
    "settings-companycockpittoken-documentation-link": "Documentazione",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/en/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "Se la vostra organizzazione utilizza una soluzione di gestione della mobilità aziendale, potete attivare la registrazione automatica per il rollout.",
    "settings-companycockpittoken-switch-enable-label": "Registrazione automatica EMM",
    "settings-companycockpittoken-switch-force-desc": "Se questa funzione non è attivata, la registrazione del messenger avverrà in modo completamente automatico. Osservare le istruzioni riportate in {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Eseguire una registrazione rapida sul terminale",
    "settings-companycockpittoken-token-desc": "Il token di sicurezza è necessario per la registrazione automatica di EMM. Vedere {{v1}}.",
    "settings-companycockpittoken-token-label": "Il token di sicurezza è: ",
    "settings-companycockpittoken-token-notset": "Registrazione automatica non attivata",
    "settings-companyinformation": "Dati aziendali",
    "settings-companylanguage": "Impostazioni della lingua",
    "settings-companylanguage-de": "Tedesco",
    "settings-companylanguage-desc": "La lingua della vostra azienda per l'invio di inviti via e-mail e altre comunicazioni.",
    "settings-companylanguage-el": "Greco",
    "settings-companylanguage-en": "Inglese",
    "settings-companylanguage-es": "Spagnolo",
    "settings-companylanguage-fr": "Francese",
    "settings-companyprofilimage": "Immagine del profilo aziendale",
    "settings-companyprofilimage-btnImportImage": "Selezionare l'immagine.",
    "settings-companyprofilimage-desc": "Selezionare un'immagine del profilo da visualizzare nei canali accanto alle notifiche.",
    "settings-companyprofilimage-desc2": "Dimensioni raccomandate 200 x 200 pixel.",
    "settings-confirmdeletecompanydialog-desc": "Eliminando l'account, tutti gli account utente verranno cancellati e le relative licenze bloccate.",
    "settings-confirmdeletecompanydialog-hint": "Inserire la password",
    "settings-confirmdeletecompanydialog-ok": "Cancellare irrevocabilmente l'account",
    "settings-confirmdeletecompanydialog-title": "Sei sicuro di voler cancellare il tuo account?",
    "settings-ldap-sync-delete-api-user-button": "Disattivare l'API",
    "settings-ldap-sync-descr-text": "L'API di ginlo è un servizio RESTful che si può usare per automatizzare la creazione, la modifica e la cancellazione di utenti, gruppi e canali.",
    "settings-ldap-sync-documentation-link-pretext": "Il sito {{v1}} è disponibile qui (solo in tedesco).",
    "settings-ldap-sync-documentation-link-text": "Documentazione",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/en/cockpit/index.html#ldap-data",
    "settings-ldap-sync-ldap-activated": "L'API è abilitata",
    "settings-ldap-sync-ldap-activated-hint": "È possibile che le modifiche manuali vengano sovrascritte.",
    "settings-ldap-sync-new-api-user-button": "Abilitare l'API",
    "settings-ldap-sync-show-api-user-credentials-button": "Informazioni di accesso",
    "settings-ldap-sync_title": "ginlo Business API",
    "settings-order-in-year": "€ annualmente",
    "settings-order-input-hint": "Da 0 a 500 licenze",
    "settings-order-tax-info": "più l'IVA applicabile",
    "settings-orderhistory": "Storia dell'ordine",
    "settings-orderhistory-agb": "Sì, sono d'accordo con {{v1}}.",
    "settings-orderhistory-agb-link": "Termini e condizioni",
    "settings-orderhistory-license": "Licenza",
    "settings-orderhistory-licenses": "Licenze",
    "settings-orderhistory-order": "Ordina ora (vincolante)",
    "settings-orderhistory-privacy": "Sì, ho letto e compreso il sito {{v1}}.",
    "settings-orderhistory-privacy-link": "Informativa sulla privacy",
    "settings-orderhistory-table-column-date": "Data",
    "settings-primarydomain": "Dominio principale",
    "settings-pwd-current-hint": "Inserire la password corrente",
    "settings-save-error": "Si è verificato il seguente errore durante il salvataggio dei dati:",
    "settings-settings": "Impostazioni",
    "settings-stellvertreter": "Rappresentante",
    "settings-stellvertreter-btn-edit": "Modifica rappresentante",
    "settings-stellvertreter-details": "{{activeAdminCount}} attivo {{adminCount}} assegnato",
    "settings-stellvertreter-last-login-never": "Non è ancora stato effettuato l'accesso",
    "settings-stellvertreter-last-login-ok": "Ultimo accesso ",
    "settings-two-fa": "Autenticazione a due fattori",
    "settings-two-fa-btn-show-recovery-codes": "Mostra codici di recupero",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Accettare i codici di recupero",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Copiare i codici di recupero",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "I vostri codici di recupero",
    "settings-two-fa-create-recovery-codes-dialog-title": "I vostri codici di recupero",
    "settings-two-fa-disable-dialog-btn-submit": "Disattivare",
    "settings-two-fa-disable-dialog-description": "Si vuole davvero disattivare l'Autenticazione a due fattori?",
    "settings-two-fa-disable-dialog-title": "Disattivazione dell'autenticazione a due fattori",
    "settings-two-fa-disabled": "Il 2FA è disattivato",
    "settings-two-fa-enable-dialog-btn-submit": "Invia il token e attiva il 2FA",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Attivazione dell'autenticazione a due fattori",
    "settings-two-fa-enable-dialog-token-help-text": "Eseguire la scansione del codice QR o inserirlo manualmente nell'app Authenticator.",
    "settings-two-fa-enable-dialog-token-not-valid": "Il token non è valido",
    "settings-two-fa-enabled": "Il 2FA è abilitato",
    "settings-two-fa-label": "L'autenticazione a due fattori è:",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Copiare i codici di recupero. Questi codici consentono di accedere alla cabina di pilotaggio quando si perde il segreto.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Codici di recupero",
    "settings-two-fa-show-recovery-codes-dialog-title": "I vostri codici di recupero",
    "state": "Stato dell'utente",
    "token": "Gettone",
    "validation-errormessages-maxvalue": "massimo consentito {{max}}",
    "validation-errormessages-minvalue": "{{min}} minimo richiesto",
    "welcome-channel-btn": "Creare canali",
    "welcome-channel-description": "I canali consentono di inviare notifiche push agli utenti. Messaggi, immagini e file possono essere distribuiti centralmente ai vostri utenti. È possibile rivedere i dati di conversione sulla dashboard.",
    "welcome-channel-subheadline-create": "Creare canali",
    "welcome-chat-subheadline": "Semplificare la comunicazione",
    "welcome-config-subheadline": "Configurare l'applicazione",
    "welcome-design-btn": "Modificare il design dell'app",
    "welcome-design-description": "Avete la possibilità di modificare il design dell'app per adattarlo alle linee guida del design aziendale della vostra azienda. Potete cambiare il logo e i colori in qualsiasi momento.",
    "welcome-design-subheadline-create": "Personalizzazione del design dell'app",
    "welcome-group-btn": "Creare gruppi",
    "welcome-group-description": "Creare gruppi per facilitare la collaborazione tra gli utenti. L'attività dei gruppi può essere visualizzata nella dashboard.",
    "welcome-group-subheadline-add": "Creare gruppi",
    "welcome-headline": "Benvenuti {{v1}}",
    "welcome-intro": "ginlo Business Cockpit offre un comodo strumento per configurare il ginlo Business Messenger sicuro per Android e iPhone in base alle proprie esigenze.",
    "welcome-license-btn": "Assegnazione della licenza",
    "welcome-license-description": "Assegnare le licenze disponibili agli account utente creati. Se non si dispone di un numero sufficiente di licenze, {{v1}} la quantità desiderata per colmare il divario.",
    "welcome-license-description-link": "ordine",
    "welcome-license-subheadline-add": "Assegnazione della licenza",
    "welcome-settings-btn": "Modificare le impostazioni",
    "welcome-settings-description": "Personalizzate i requisiti di sicurezza e le impostazioni dell'applicazione per soddisfare le vostre specifiche di sicurezza.",
    "welcome-settings-subheadline-create": "Configurare le impostazioni dell'app",
    "welcome-user-btn": "Creare un nuovo utente",
    "welcome-user-description": "È possibile creare nuovi account utente in modo semplice e veloce. Si può utilizzare la funzione \"Crea utente singolo\" o importare i dati degli utenti utilizzando il modello CSV.",
    "welcome-user-subheadline": "Gestire gli utenti",
    "welcome-user-subheadline-create": "Creare un utente"
}
;
    
    export default TranslationCommonIt;
    
