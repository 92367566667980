import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn, TableFooter } from 'material-ui/Table';
import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';
import ModalDialog from '../../../components/ModalDialog'
import { TableRowColumnText, TableHeaderColumnSort, TableHeaderColumnMore, TableRowColumnMoreIcon, sharedTableStyles } from '../../../components/table/TableCells'
import TableFooterPagination from '../../../components/table/TableFooterPagination';
import { IsNullOrEmpty } from '../../../components/StringExtensions';
import { getTableStyles } from '../../../components/styles.js';
import { getAdminMuiTheme } from '../../../components/styles.js';

require('../../../components/DateExtensions');
require('../../../components/StringExtensions');

const styles = {
  root: {
    padding: 7
  },
  tablePlaceHolder: {
    color: '#7B98AB',
    verticalAlign: 'bottom'
  },
  right: {
    float: 'right'
  },
  tableText: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  tableSelection: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  placeHolder: {
  },
  placeHolderKeyword: {
  },
  floatLabel: {
  },
  chip: {
    maxWidth:'100%',
    display:'inline-flex',
  },
  chipLabelStyle: {
    fontSize: '14px',
    marginLeft: '14px',
    marginRight: '14px',
    overflow: 'hidden',
  },
  selectedBackground: {
    backgroundColor: 'rgba(123, 152, 171, 0.2)',
  },
};

const moreMenuEntries = [{
    id: 'edit',
    textId: 'common-edit-btn'
  }, {
    id: 'delete',
    textId: 'common-delete-btn'
  }];


const tableStyles = getTableStyles();
const adminMuiTheme = getAdminMuiTheme();

export class GroupList extends Component {

  constructor(props) {
    super(props);
    this.dataprovider = props.dataprovider;
    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      selectedRow: -1,
      tabledata: this.dataprovider.getDataSorted(1, 0, props.filterText),
      filterText: props.filterText
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleDataproviderCallback = this.handleDataproviderCallback.bind(this);
    this.dataprovider.addCallback(this.handleDataproviderCallback);
    this.onRowSelection = this.onRowSelection.bind(this);
    this.moreMenuEntryClick = this.moreMenuEntryClick.bind(this);
  }

  handleSortTableButtonClicked(index) {
    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.dataprovider.getDataSorted(index, newDirection, this.state.filterText)
      });

    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.dataprovider.getDataSorted(index, newDirection, this.state.filterText)
      });
    }
  }

  notifyParent(nextState) {
    if (nextState.placeHolder === undefined) {
      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(false, false);
      }
    } else {
      let visible = (!this.isEmpty(nextState.placeHolder.name) || !this.isEmpty(nextState.placeHolder.firstName)
        || !this.isEmpty(nextState.placeHolder.eMailAdress) || !this.isEmpty(nextState.placeHolder.phoneNumber)) || !this.isEmpty(nextState.keyword);

      let enabled = visible && !this.isEmpty(nextState.placeHolder.name) && !this.isEmpty(nextState.placeHolder.firstName)
        && (!this.isEmpty(nextState.placeHolder.phoneNumber) || !this.isEmpty(nextState.placeHolder.eMailAdress));

      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(visible, enabled);
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this.notifyParent(nextState);
    if (nextProps.filterText !== this.state.filterText) {
      this.setState({
        tabledata: this.dataprovider.getDataSorted(this.state.sortColumn, this.state.sortDirection, nextProps.filterText),
        filterText: nextProps.filterText,
      });
      this.refs.pagination && this.refs.pagination.forcePaginationToStart();
    }
  }

  handleDataproviderCallback() {
    this.setState({
      tabledata: this.dataprovider.getDataSorted(this.state.sortColumn, this.state.sortDirection, this.state.filterText)
    });
  }

  getGroupAvatar(group) {
    if (group.smallImage != null && typeof group.smallImage !== "undefined") {
      return <Avatar src={ "data:image/jpeg;base64," + group.smallImage } />;
    }
    return <div></div>;
  }

  renderFirstColumnText() {
    const {t} = this.props;
    if (typeof this.props.customGroupNameColumnText !== 'undefined') {
      return this.props.customGroupNameColumnText;
    } else {
      return t('modules-groups-GroupList-table-column1');
    }
  }

  getTextColorStyle(count) {
    if (count === 0) {
      return {
        color: '#7B98AB'
      }
    } else {
      return {
        color: '#FFFFFF'
      }
    }
  }

  /**
  * Callback fuer ... menu
  */
  moreMenuEntryClick(event, menuItem, index) {
    if (menuItem) {
      if (menuItem.key === "edit")
      {
        this.props.onRowSelection(menuItem.props.value, event)
      } else {
        this.props.onDeleteGroup(menuItem.props.value, event)
      }
    }
  }
  onRowSelection(item, i, event) {
    this.setState({
      selectedRow: i
    });
  }
  render() {
    const {t} = this.props;
    var dataPaginated = this.refs.pagination && this.refs.pagination.getPaginatedData(this.state.tabledata);

    return (
      <div style={ styles.root }>
        <ModalDialog ref="modalDialog" />
        <Table selectable={ false }>
          <TableHeader displaySelectAll={ false } adjustForCheckbox={ false }>
            <TableRow style={ sharedTableStyles.headerStyle } displayBorder={ false }>
              <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ this.renderFirstColumnText() } onClickCallback={ this.handleSortTableButtonClicked.bind(this)}
              />
              <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-groups-GroupList-table-column2') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 3 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-groups-GroupList-table-column3') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 4 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-groups-GroupList-table-column4') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 5 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-groups-GroupList-table-column5') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnMore />
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={ false }>
            { dataPaginated && dataPaginated.map((group, i) => <TableRow key={ i } value={ group } style={ i === this.state.selectedRow ? Object.assign({}, tableStyles.tableRow, styles.selectedBackground) : tableStyles.tableRow } displayBorder={ false } onClick={  (event) => this.onRowSelection(group, i, event) }>
                                                       <TableRowColumn onMouseDown={ (event) => this.props.onRowSelection(group, event) } style={ {height: 40} }>
                                                         <Chip style={ styles.chip } labelStyle={ styles.chipLabelStyle } backgroundColor={ this.props.GroupChipColor != null ? this.props.GroupChipColor : '#737373' } onClick={ (event) => this.props.onRowSelection(group, event) }>
                                                           { this.getGroupAvatar(group) }
                                                           { group.name }
                                                         </Chip>
                                                       </TableRowColumn>
                                                       <TableRowColumnText text={ group.memberCount } style={ this.getTextColorStyle(group.memberCount) } />
                                                       <TableRowColumnText text={ group.activeMemberCount } style={ this.getTextColorStyle(group.activeMemberCount) } />
                                                       <TableRowColumnText text={ group.pendingMemberCount } style={ this.getTextColorStyle(group.pendingMemberCount) } />
                                                       <TableRowColumnText text={ !IsNullOrEmpty(group.lastMessage) ? new Date().parseUtcDate(group.lastMessage).getShortDateTime() : group.lastMessage } />
                                                       <TableRowColumnMoreIcon menuStyle={ { backgroundColor: adminMuiTheme.palette.primary2Color } } menuItemStyle={ { fontSize: "14px" } } menuEntries={ moreMenuEntries } t={ t } callBack={ this.moreMenuEntryClick }
                                                         value={ group } />
                                                     </TableRow>
              ) }
            <TableRow key={ -2 } displayBorder={ true }>
            </TableRow>
          </TableBody>
          <TableFooter style={ tableStyles.tableFooter}>
            <TableFooterPagination ref="pagination" data={ this.state.tabledata } parent={ this } />
          </TableFooter>
        </Table>
      </div>
      );
  }
}

export default translate(['common'], {
  withRef: true
})(GroupList);
