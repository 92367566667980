/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconGroups(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <g fill="none" fillRule="evenodd" transform="translate(8 8)">
                <rect width="24" height="24"/>
                <path fill="#FFF" fillRule="nonzero" d="M3.6,10.8571429 C2.27625732,10.8571429 1.2,9.83213588 1.2,8.57142857 C1.2,7.31072126 2.27625732,6.28571429 3.6,6.28571429 C4.92374268,6.28571429 6,7.31072126 6,8.57142857 C6,9.83213588 4.92374268,10.8571429 3.6,10.8571429 Z M20.4,10.8571429 C19.0762573,10.8571429 18,9.83213588 18,8.57142857 C18,7.31072126 19.0762573,6.28571429 20.4,6.28571429 C21.7237427,6.28571429 22.8,7.31072126 22.8,8.57142857 C22.8,9.83213588 21.7237427,10.8571429 20.4,10.8571429 Z M21.6,12 C22.9237427,12 24,13.025007 24,14.2857143 L24,15.4285714 C24,16.0607213 23.4637573,16.5714286 22.8,16.5714286 L20.325,16.5714286 C20.0924927,14.8785575 19.0200073,13.4535784 17.5087646,12.6642718 C17.94375,12.2535575 18.5400146,12 19.2,12 L21.6,12 Z M12,12 C9.67873535,12 7.8,10.2107282 7.8,8 C7.8,5.78927176 9.67873535,4 12,4 C14.3212646,4 16.2,5.78927176 16.2,8 C16.2,10.2107282 14.3212646,12 12,12 Z M14.8799927,13.1428571 C17.2650146,13.1428571 19.2,14.9857003 19.2,17.2571498 L19.2,18.2857143 C19.2,19.2321429 18.39375,20 17.4,20 L6.6,20 C5.60625,20 4.8,19.2321429 4.8,18.2857143 L4.8,17.2571498 C4.8,14.9857003 6.73498535,13.1428571 9.12000732,13.1428571 L9.43125,13.1428571 C10.2150146,13.5 11.0775146,13.7142857 12,13.7142857 C12.9224854,13.7142857 13.7887573,13.5 14.56875,13.1428571 L14.8799927,13.1428571 Z M6.49123535,12.6642718 C4.97999268,13.4535784 3.90750732,14.8785575 3.67126465,16.5714286 L1.2,16.5714286 C0.536242676,16.5714286 0,16.0607213 0,15.4285714 L0,14.2857143 C0,13.025007 1.07625732,12 2.4,12 L4.8,12 C5.45998535,12 6.05625,12.2535575 6.49123535,12.6642718 Z"/>
              </g>
            </svg>
        </SvgIcon>;
}
;

export default IconGroups;
