import { fade } from 'material-ui/utils/colorManipulator.js'
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { COLOR_PRIMARY_1, COLOR_PRIMARY_2 } from '../util/Constants.js';


export function getTableStyles() {
  return ({
    tableHeader: {
      background: 'linear-gradient(to bottom, #00375D 0%,#00375D 100%)',
      borderBottomWidth: '0px'
    },
    tableHeaderRow: {
      height: '60px',
      verticalAlign: 'bottom',
      borderBottomWidth: '0px'
    },
    tableHeaderRowWithoutBackground: {
      height: '60px',
      verticalAlign: 'bottom',
      borderBottomWidth: '0px'
    },
    tableRow: {
      borderTopColor: '#00375D',
      borderBottomColor: '#737373',
      borderBottom: '1px solid',
    },
    tableRowWithoutBorder: {
      borderTopColor: '#00375D',
      borderBottomColor: '#737373',
      borderBottom: '1px solid',
    },
    tableBody: {
      borderBottom: '1px solid',
      borderBottomColor: '#737373'
    },
    tableBodyWithoutBorder: {
      borderBottom: '1px solid',
      borderBottomColor: '#737373'
    },
    tableFooter: {
      position: 'fixed',
      bottom: 0
    }
  });
}


export function getAdminMuiTheme() {
  return getMuiTheme(darkBaseTheme,
    {
      fontFamily: 'Raleway',
      palette: {
        primary1Color: COLOR_PRIMARY_1,
        primary2Color: COLOR_PRIMARY_2,
        primary3Color: '#7B98AB',
        accent1Color: '#7B98AB',
        textColor: '#FFFFFF',
        secondaryTextColor: '#FFFFFF',
        alternateTextColor: '#FFFFFF',
        canvasColor: '#00375D'
      },
      flatButton: {
        textTransform: 'none',
        textColor: '#7B98AB', //117AAF
        secondaryTextColor: '#7B98AB'
      },
      raisedButton: {
        color: '#7B98AB',
        disabledColor: fade('#FFFFFF', 0.2),
        disabledTextColor: '#00375D',
        textTransform: 'none',
        fontSize: '14',
        fontWeight: '500',
        primaryColor: '#00C1A7',
        primaryTextColor: '#FFFFFF',
        secondaryColor: '#7B98AB',
        secondaryTextColor: '#FFFFFF',
        textColor: '#FFFFFF',
        defaultHoverColor: "#6C8392",
        primaryHoverColor: "#0AA48F",
        secondaryHoverColor: "#6C8392",
        dangerColor: "#DC004B",
        dangerHoverColor: "#B0003C"
      },
      table: {
        backgroundColor: '#00375D00'
      },
      tableHeaderColumn: {
        spacing: 12,
        height: '28px',
        textColor: '#6C8392'
      },
      tableRowColumn: {
        spacing: 12,
        height: '28px'
      },
      tableRow: {
        spacing: 12,
        height: '28px',
        selectedColor: 'transparent'
      },
      menu: {
        backgroundColor: '#7B98AB',
        containerBackgroundColor: '#7B98AB',
      },
      menuItem: {
        hoverColor: '#6C8392',
        selectedTextColor: '#FFFFFF'
      }
    });
}
