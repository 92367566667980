/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconTabelleAlertState(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <title>IconTabelleAlertState</title>
             <desc id="desc6">Created with Sketch.</desc>
             <path fill="#FFF" d="M24.3497053,21.2347972 C24.9625674,22.2971001 24.1932878,23.6249949 22.9692498,23.6249949 L7.03554906,23.6249949 C5.80914398,23.6249949 5.04339873,22.2950573 5.65506109,21.2347972 L13.6220087,7.42142219 C14.2351626,6.35860048 15.7709008,6.36054597 16.3829847,7.42142219 L24.3497053,21.2347972 Z M15.0024967,18.3789137 C14.1589642,18.3789137 13.4751566,19.0627213 13.4751566,19.9062538 C13.4751566,20.7497863 14.1589642,21.4335939 15.0024967,21.4335939 C15.8460292,21.4335939 16.5298368,20.7497863 16.5298368,19.9062538 C16.5298368,19.0627213 15.8460292,18.3789137 15.0024967,18.3789137 Z M13.552425,12.8889327 L13.7987242,17.404547 C13.810235,17.6158273 13.9849401,17.7812589 14.1965446,17.7812589 L15.8084488,17.7812589 C16.0200533,17.7812589 16.1947584,17.6158273 16.2062692,17.404547 L16.4525684,12.8889327 C16.4650195,12.6606942 16.2833107,12.4687715 16.0547479,12.4687715 L13.9502131,12.4687715 C13.7216503,12.4687715 13.5399739,12.6606942 13.552425,12.8889327 Z"
             />
           </svg>
         </SvgIcon>;
}

export default IconTabelleAlertState;
