import React, { Component } from 'react';
import { translate } from 'react-i18next';

import IconTabelleStatusCreated from '../../ressources/tabelle_status_on_pending';
import IconTabelleStatusDeactive from '../../ressources/tabelle_status_eingeladen';
import IconTabelleStatusActive from '../../ressources/tabelle_status_on';
import IconTabelleStatusDeleted from '../../ressources/tabelle_status_alert';

import IconInfo from '../../ressources/icon_info';

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

const styles = {
  adminStatusStyle: {
    margin: 'auto',
    display: 'block'
  },
  adminStatusHeaderStyle: {
    margin: 'auto',
    display: 'block',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    width: '36px',
    height: '36px'
  },
  adminStatusMenuTextStyle: {
    fontSize: "14px",
  }
}

export function getNumberForAdminState(state) {
  if (typeof state === "undefined" || state === null)
  {
    return 1;
  }

  if (state === "created")
  {
    return 1;
  }
  if (state === "deactive")
  {
    return 2;
  }
  if (state === "active")
  {
    return 3;
  }
  if (state === "deleted")
  {
    return 4;
  }

  return 0;
}


export class AdminStateIcon extends Component {
  render() {

    const {t} = this.props;

    if (typeof this.props.state === "undefined" || this.props.state === null)
    {
      return <IconTabelleStatusCreated style={ styles.adminStatusStyle } alt={ t('modules-settings-AdminList-table-adminStatus1') } />
    }
    if (this.props.state === "created")
    {
      return <IconTabelleStatusCreated style={ styles.adminStatusStyle } alt={ t('modules-settings-AdminList-table-adminStatus1') } />
    }
    if (this.props.state === "deactive")
    {
      return <IconTabelleStatusDeactive style={ styles.adminStatusStyle } alt={ t('modules-settings-AdminList-table-adminStatus2') } />
    }
    if (this.props.state === "active")
    {
      return <IconTabelleStatusActive style={ styles.adminStatusStyle } alt={ t('modules-settings-AdminList-table-adminStatus3') } />
    }
    if (this.props.state === "deleted")
    {
      return <IconTabelleStatusDeleted style={ styles.adminStatusStyle } alt={ t('modules-settings-AdminList-table-adminStatus4') } />
    }

    return <div>
            { this.props.state }
           </div>;
  }
}

export class AdminStateTableHeader extends Component {

  render() {
    const {t} = this.props;
    return (
      <IconMenu style={ { width: '36px', height: '36px' } } iconButtonElement={ <IconButton style={ styles.adminStatusHeaderStyle }>
                                                                            <IconInfo />
                                                                          </IconButton> }>
        <MenuItem style={ styles.adminStatusMenuTextStyle } primaryText={ t('modules-settings-AdminList-table-adminStatus1') } leftIcon={ <IconTabelleStatusCreated /> } />
        <MenuItem style={ styles.adminStatusMenuTextStyle } primaryText={ t('modules-settings-AdminList-table-adminStatus2') } leftIcon={ <IconTabelleStatusDeactive /> } />
        <MenuItem style={ styles.adminStatusMenuTextStyle } primaryText={ t('modules-settings-AdminList-table-adminStatus3') } leftIcon={ <IconTabelleStatusActive /> } />
        <MenuItem style={ styles.adminStatusMenuTextStyle } primaryText={ t('modules-settings-AdminList-table-adminStatus4') } leftIcon={ <IconTabelleStatusDeleted /> } />
      </IconMenu>);
  }
}

export default translate(['common'])(AdminStateTableHeader);
