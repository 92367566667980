/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconTableIos(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <title>tabelle_ios</title>
             <desc>Created with Sketch.</desc>
             <defs></defs>
             <path fill="#7B98AB" d="M19.7017372,15.3463122 C19.7017372,15.4167981 19.5718547,17.6174078 21.9913874,18.7566339 C21.5386469,20.1222846 19.987486,23.157817 18.1839529,23.1838007 C17.1411964,23.1838007 16.5288932,22.51953 15.3488252,22.51953 C14.1316116,22.51953 13.4710735,23.1615134 12.5359122,23.1838007 C10.7472736,23.2394647 9.03281734,19.8996289 8.55782572,18.5377108 C8.20155483,17.5023472 8.0234375,16.4967001 8.0234375,15.5244295 C8.0234375,12.2328285 10.2240109,10.6148781 12.2947019,10.5814653 C13.2966526,10.5814653 14.5732266,11.3125255 15.1298659,11.3125255 C15.656825,11.3125255 17.0966581,10.4404572 18.4140377,10.544356 C19.805636,10.6519874 20.8595542,11.2011976 21.5572015,12.1994519 C20.3103078,12.9564596 19.6943081,13.9843941 19.7017372,15.3463122 Z M17.6013299,9.25296008 C16.8740023,10.1027772 15.9908086,10.6000199 15.0222344,10.5258014 C14.940623,9.50159955 15.3228414,8.59242203 15.9722539,7.8576654 C16.5622698,7.1711436 17.5976334,6.6145043 18.4919527,6.56253681 C18.5216692,6.95588075 18.6144424,8.0506047 17.6013299,9.25296008 Z"
             />
           </svg>
         </SvgIcon>;
}

export default IconTableIos;
