const TranslationCommonFr ={
    "SERVICE-ADMIN-ERR-0019": "Échec de la connexion à ginlo Business",
    "SERVICE-ADMIN-ERR-0022": "Entrée non valide",
    "SERVICE-ADMIN-ERR-0098": "Le numéro de portable n'est pas valide. Veuillez saisir le numéro de téléphone mobile, y compris l'indicatif régional ou urbain, au format suivant : \"012345678910\". Pour les numéros de téléphone mobile en dehors de l'Allemagne, veuillez indiquer l'indicatif du pays (par exemple, \"+31...\").",
    "SERVICE-ADMIN-ERR-0106": "Les informations d'enregistrement ne sont pas valides.",
    "SERVICE-ADMIN-ERR-0106-1": "Le mot de passe n'est pas correct.",
    "SERVICE-ADMIN-ERR-0109": "La session a expiré.",
    "SERVICE-ADMIN-ERR-0110": "L'image n'a pas pu être téléchargée.",
    "SERVICE-ADMIN-ERR-0111": "L'image est trop grande. La résolution maximale autorisée est de 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "Le numéro de téléphone est déjà utilisé.",
    "SERVICE-ADMIN-ERR-0114": "L'adresse électronique est déjà utilisée.",
    "SERVICE-ADMIN-ERR-0115": "Le format du fichier .csv n'est pas valide.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "Le format du fichier .csv n'est pas valide. La colonne Prénom est vide.",
    "SERVICE-ADMIN-ERR-0115-NAME": "Le format du fichier .csv n'est pas valide. La colonne Nom est vide.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "Le format du fichier .csv n'est pas valide. La colonne Numéro de portable est vide.",
    "SERVICE-ADMIN-ERR-0135": "L'image est trop petite. La résolution minimale requise est de 680 * 80 px.",
    "SERVICE-ADMIN-ERR-0153": "Il n'y a pas assez de licences pour cette action. Vous avez la possibilité d'acheter des licences supplémentaires sous Administration des licences.",
    "SERVICE-ADMIN-ERR-0154": "Le représentant n'est pas autorisé à effectuer cette action.",
    "SERVICE-ADMIN-ERR-0156": "Un autre administrateur est déjà connecté au système. Afin d'éviter les conflits, l'enregistrement de plus d'un représentant n'est pas autorisé.",
    "SERVICE-ADMIN-ERR-0157": "L'administrateur a été supprimé.",
    "SERVICE-ADMIN-ERR-0158": "L'administrateur a été désactivé.",
    "SERVICE-ADMIN-ERR-0176": "Une erreur s'est produite lors du chargement de l'aperçu du flux RSS.",
    "SERVICE-ADMIN-ERR-0177": "L'API a été désactivée par un autre utilisateur.",
    "SERVICE-ADMIN-ERR-0181": "Les données ont été éditées par l'utilisateur {{username}} sur {{date}}.",
    "SERVICE-ADMIN-ERR-0182": "Les données ont été supprimées par l'utilisateur {{username}} sur {{date}}.",
    "SERVICE-ADMIN-ERR-0183": "L'API est déjà activée.",
    "SERVICE-ERR-0057": "Temps mort. Vous avez été déconnecté.",
    "SERVICE-ERR-0123": "L'adresse électronique n'est pas valide.",
    "SERVICE-ERR-0124": "Le domaine de l'adresse électronique que vous avez indiquée est géré par un fournisseur d'accès privé. Pour des raisons de confidentialité des données, ce domaine n'est pas pris en charge.",
    "SERVICE-IMP-1": "Ce fichier est trop volumineux.",
    "SERVICE-IMP-23": "Format de fichier incorrect.",
    "SERVICE-IMP-30": "Le format du fichier d'importation est défectueux (par exemple, nombre incorrect de colonnes).",
    "SERVICE-IMP-35": "Un nom de groupe utilisé existe plusieurs fois dans ginlo Business Cockpit.",
    "SERVICE-IMP-36": "Un nom de canal utilisé existe plusieurs fois dans ginlo Business Cockpit.",
    "SERVICE-NO_SERVER_RESPONSE": "Pas de connexion au serveur.",
    "SERVICE-RSSTRANS-CLI-0001": "Le flux RSS à l'URL demandé n'est pas disponible. L'URL peut être bloquée par les paramètres du pare-feu.",
    "SERVICE-RSSTRANS-ERR-0007": "Le contenu de l'URL indiqué n'est pas conforme aux spécifications RSS 2.0. Des caractères non valides ont été trouvés.",
    "SERVICE-RSSTRANS-ERR-0008": "Le contenu de l'URL indiqué n'est pas conforme aux spécifications RSS 2.0. Tous les champs obligatoires n'ont pas été remplis.",
    "SERVICE-RSSTRANS-ERR-0011": "Aucun flux RSS valide n'a été trouvé à l'URL indiquée.",
    "SERVICE-RSSTRANS-ERR-0012": "Une erreur s'est produite lors du chargement de l'aperçu du flux RSS. L'URL demandée n'est pas disponible.",
    "SERVICE-RSSTRANS-ERR-0014": "Un flux Atom a été trouvé à l'URL indiquée. Ce format n'est pas pris en charge. Veuillez vous assurer qu'il s'agit d'un flux RSS conforme à RSS 2.0.",
    "SERVICE-RSSTRANS-ERR-0015": "Aucun contenu n'a été trouvé à l'URL indiqué.",
    "SERVICE-RSSTRANS-ERR-0016": "Le format du flux RSS n'est pas pris en charge.",
    "SERVICE-RSSTRANS-ERR-0017": "Le flux RSS contient des caractères non valides et non autorisés.",
    "cancel": "Annuler",
    "common-delete-btn": "Supprimer",
    "common-edit-btn": "Editer",
    "components-ModalDialog-Warning": "Note",
    "components-ModalDialog-cancel": "Annuler",
    "components-ModalDialog-looseDataQuestion": "Si vous annulez, les modifications ne seront pas enregistrées. Annuler ?",
    "components-ModalDialog-no": "Non",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Oui",
    "components-NavigationLeft-appdesign": "Conception de l'application",
    "components-NavigationLeft-appsettings": "Paramètres de l'application",
    "components-NavigationLeft-channel": "Canaux",
    "components-NavigationLeft-dashboard": "Tableau de bord",
    "components-NavigationLeft-groups": "Groupes",
    "components-NavigationLeft-license": "Licences",
    "components-NavigationLeft-roles": "Rôles des utilisateurs",
    "components-NavigationLeft-user": "Utilisateurs",
    "components-chatmessagetextfield-default-hint": "Saisir le texte du message",
    "components-chatmessagetextfield-preview-time-placeholder": "Maintenant",
    "components-continue": "Continuer",
    "components-messagecomposer-datepicker": "Sélectionner la date",
    "components-messagecomposer-messagesendtime-delayed": "À la date et à l'heure suivantes",
    "components-messagecomposer-messagesendtime-now": "Envoyer immédiatement",
    "components-messagecomposer-send": "Envoyer",
    "components-messagecomposer-send-AttachmentTooBig": "Ce fichier est trop volumineux.",
    "components-messagecomposer-timepicker": "Sélectionner l'heure",
    "components-messagecomposer-uploadattachment": "Sélectionner une image ou un fichier",
    "components-messageeditor-button-delete": "Supprimer des messages",
    "components-messageeditor-button-showolder": "Afficher les plus anciens",
    "components-messageeditor-confirmdeletemessage": "Tous les messages de ce canal seront supprimés. Supprimer quand même ?",
    "components-messageeditor-header-messages": "Messages dans ce canal",
    "components-messageeditor-header-newmessage": "Nouveau message",
    "components-messageeditor-header-timedmessages": "Temporisé",
    "components-messageeditor-rss-linkmore": "plus …",
    "components-messageeditor-type-audio": "Audio",
    "components-messageeditor-type-location": "Localisation",
    "components-messageeditor-type-video": "Vidéo",
    "components-reload-data": "Rafraîchir",
    "email": "Courriel",
    "err-empty-textfield": "Le champ ne peut pas être vide.",
    "error": "Erreur",
    "firstName": "Prénom",
    "footer-link-details": "ginlo Manuel",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/en/",
    "footer-link-faq": "FAQ",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/en/faq/",
    "footer-link-impress": "Impression",
    "footer-link-impress-url": "https://app-help.ginlo.net/en/imprint",
    "footer-link-press": "Presse",
    "footer-link-press-url": "https://www.ginlo.net/en/about-us/",
    "footer-link-privacy": "Politique de confidentialité",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/en/privacy",
    "footer-link-security": "ginlo Data",
    "footer-link-security-url": "https://www.ginlo.net/en/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Business",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/en/ginlo-business/",
    "footer-link-support": "Soutien",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "Conditions générales d'utilisation",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/en/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "FR",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Mot de passe oublié",
    "header-btn-fr": "FR",
    "header-btn-logout": "Déconnexion",
    "header-btn-settings": "Paramètres",
    "header-btn-support": "Soutien",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Exemple de nom d'entreprise",
    "lastName": "Nom de famille",
    "login-2fa-token-textfield-hint": "Code à deux facteurs (si activé)",
    "login-email-textfield-hint": "Adresse électronique",
    "login-forgot-pwd-button": "Set (jeu de mots)",
    "login-forgot-pwd-desc": "Veuillez saisir votre mot de passe de récupération.",
    "login-forgot-pwd-desc-min-char": "Au moins 8 caractères",
    "login-forgot-pwd-desc-spez-char": "Au moins un chiffre (0-9) ou un caractère spécial",
    "login-forgot-pwd-desc-upper-char": "Lettres minuscules et majuscules (a-z, A-Z)",
    "login-forgot-pwd-err-empty": "Ce champ est obligatoire.",
    "login-forgot-pwd-err-not-equal": "Les mots de passe ne sont pas identiques.",
    "login-forgot-pwd-new-pwd-desc": "Veuillez choisir un nouveau mot de passe.",
    "login-forgotPwd-button": "Mot de passe oublié",
    "login-initial-pwd-button": "Économiser",
    "login-initial-pwd-new-pwd-desc": "Veuillez choisir votre propre mot de passe.",
    "login-login-button": "Connexion",
    "login-pwd-new-textfield-hint": "Saisir le nouveau mot de passe",
    "login-pwd-repeat-textfield-hint": "Répéter le nouveau mot de passe",
    "login-pwd-textfield-hint": "Saisir le mot de passe",
    "login-remember-email": "Mémoriser l'adresse électronique",
    "modules-appdesign-appdesign-btnResetLayout": "Réinitialiser la conception de l'application",
    "modules-appdesign-appdesign-btncancel": "Annuler",
    "modules-appdesign-appdesign-btnsave": "Économiser",
    "modules-appdesign-appdesign-header": "Conception de l'application",
    "modules-appdesign-colorpicker-actionelements": "Éléments d'action",
    "modules-appdesign-colorpicker-blue": "bleu",
    "modules-appdesign-colorpicker-color": "Couleur",
    "modules-appdesign-colorpicker-contrast": "contraste",
    "modules-appdesign-colorpicker-contrastdark": "Couleur contrastée foncée",
    "modules-appdesign-colorpicker-contrastlight": "Couleur contrastée claire",
    "modules-appdesign-colorpicker-dark": "sombre",
    "modules-appdesign-colorpicker-green": "Vert",
    "modules-appdesign-colorpicker-heavytrust": "Niveau de confiance élevé",
    "modules-appdesign-colorpicker-hex": "valeur hexagonale",
    "modules-appdesign-colorpicker-light": "lumière",
    "modules-appdesign-colorpicker-maincolor": "Couleur principale",
    "modules-appdesign-colorpicker-normaltrust": "Confiance modérée",
    "modules-appdesign-colorpicker-red": "Rouge",
    "modules-appdesign-colorpicker-unsafetrust": "Statut de confiance non sécurisé",
    "modules-appdesign-colorpicker-yellow": "Bleu",
    "modules-appdesign-cropimage-accept-btn": "Appliquer",
    "modules-appdesign-cropimage-title": "Veuillez découper votre logo à la taille voulue.",
    "modules-appdesign-cropimage-zoom-hint": "* Vous pouvez agrandir ou recadrer l'image à l'aide de la molette de défilement du bouton de la souris.",
    "modules-appdesign-image-description": "*.png avec alpha",
    "modules-appdesign-image-description2": "800 x 160 px recommandé",
    "modules-appdesign-mainView-description": "Façonnez l'application pour qu'elle corresponde à l'identité de votre entreprise en quelques étapes seulement.",
    "modules-appdesign-mainView-removeCompanyLogo": "Supprimer l'image",
    "modules-appdesign-mainView-subheading": "Adaptez l'application à vos besoins.",
    "modules-appdesign-mainView-uploadImage": "Sélectionner l'image",
    "modules-appsettings-appsettings-btnResetConfig": "Réinitialiser les paramètres de l'application",
    "modules-appsettings-header": "Paramètres de l'application",
    "modules-appsettings-hinttext-range1": "0 à 10 minutes",
    "modules-appsettings-hinttext-range2": "0 à 100 répétitions",
    "modules-appsettings-hinttext-range3": "0 à 99 caractères",
    "modules-appsettings-hinttext-range4": "0 à 365 jours",
    "modules-appsettings-hinttext-range5": "0 à 4 classes",
    "modules-appsettings-label-chars": "Personnages",
    "modules-appsettings-label-days": "Jours",
    "modules-appsettings-label-minutes": "Min.",
    "modules-appsettings-label-repeat": "Rpt.",
    "modules-appsettings-mainView-contactpolicy": "Lignes directrices pour les contacts",
    "modules-appsettings-mainView-mediapolicy": "Lignes directrices pour les médias",
    "modules-appsettings-mainView-pwcomplexity": "Complexité du mot de passe",
    "modules-appsettings-mainView-pwpolicy": "Lignes directrices concernant les mots de passe",
    "modules-appsettings-selectfield-deleteaccount-inactive": "désactiver",
    "modules-appsettings-selectfield-deleteaccount-tries": "Tentatives",
    "modules-appsettings-selectfield-deleteaccount-try": "Tentative",
    "modules-appsettings-table-companyindexname": "Nom de l'annuaire de l'entreprise",
    "modules-appsettings-table-companyindexnamedescr": "Modifiez ici le nom de l'annuaire de l'entreprise qui doit être affiché aux utilisateurs sous contacts.",
    "modules-appsettings-table-contactClosedUserGroup": "Groupe d'utilisateurs restreint activé",
    "modules-appsettings-table-contactClosedUserGroupDescr": "Lorsque cette fonction est activée, l'utilisateur ne peut communiquer qu'avec les contacts qui ont été ajoutés dans ginlo Cockpit. Il n'est pas non plus possible de chatter au-delà avec les administrateurs.",
    "modules-appsettings-table-deletedatacount": "Supprimer les données après un trop grand nombre de tentatives infructueuses",
    "modules-appsettings-table-deletedatacountdescr": "Entrez le nombre de tentatives de connexion autorisées avant que le contenu de l'application ne soit automatiquement supprimé.",
    "modules-appsettings-table-disablebackup": "Empêcher la sauvegarde des applications dans iCloud/Google Drive",
    "modules-appsettings-table-disablebackupdescr": "Avec ce paramètre, les utilisateurs ne peuvent pas sauvegarder sur iCloud ou Google Drive ou importer des sauvegardes à partir de ces fournisseurs. Pour la sauvegarde des données, il est recommandé de coupler à un deuxième appareil.",
    "modules-appsettings-table-disablecamera": "Interdire l'accès à la caméra.",
    "modules-appsettings-table-disablecameradescr": "Lorsque cette fonction est activée, un employé ne peut plus enregistrer ou envoyer des photos et des vidéos à partir de la caméra.",
    "modules-appsettings-table-disablecopypaste": "Interdire l'accès au copier / coller",
    "modules-appsettings-table-disablecopypastedescr": "Lorsque cette fonction est activée, un employé ne peut plus copier du texte et des médias pour les coller dans des chats ou des applications tierces.",
    "modules-appsettings-table-disableexportchat": "Désactiver l'exportation du contenu du chat sous forme de fichier texte",
    "modules-appsettings-table-disableexportchatdescr": "En activant cette option, vous empêchez l'exportation des chats individuels de l'application dans un fichier texte.",
    "modules-appsettings-table-disablefaceandtouchid": "Blocage de l'activation par Touch/Face-ID",
    "modules-appsettings-table-disablefaceandtouchiddescr": "Si ce paramètre est sélectionné, les utilisateurs ne peuvent pas utiliser Touch/Face ID (iPhone) ou l'empreinte digitale (Android) pour déverrouiller l'application.",
    "modules-appsettings-table-disablelocation": "Interdire l'accès aux données géographiques",
    "modules-appsettings-table-disablelocationdescr": "Lorsque cette fonction est activée, un employé ne peut plus partager ses données de localisation.",
    "modules-appsettings-table-disablemicrophone": "Interdire l'accès au microphone",
    "modules-appsettings-table-disablemicrophonedescr": "Lorsque cette fonction est activée, un employé ne peut plus envoyer d'enregistrements audio.",
    "modules-appsettings-table-disablepushpreview": "Empêcher la prévisualisation dans les notifications push",
    "modules-appsettings-table-disablepushpreviewdescr": "Avec ce paramètre, le contenu des messages sur les appareils mobiles ne sera pas affiché dans l'aperçu push. Cela renforce la sécurité, car le contenu ne peut être lu qu'à l'aide de l'application.",
    "modules-appsettings-table-disablerecoverycode": "Désactiver le code de récupération",
    "modules-appsettings-table-disablerecoverycodedescr": "Lorsque cette fonction est activée, l'utilisateur ne peut pas utiliser la fonction 'mot de passe oublié'.",
    "modules-appsettings-table-disablesendmedia": "Désactiver l'envoi et le transfert de fichiers multimédias",
    "modules-appsettings-table-disablesendmediadescr": "Lorsque ce paramètre est activé, les pièces jointes ne peuvent pas être envoyées ou transférées.",
    "modules-appsettings-table-disablesendvcard": "Refuser l'accès aux contacts",
    "modules-appsettings-table-disablesendvcarddescr": "Lorsque cette fonction est activée, un employé ne peut plus envoyer ou recevoir de contacts.",
    "modules-appsettings-table-disablesimsmerecovery": "Forcer le code de récupération par l'intermédiaire de l'administrateur",
    "modules-appsettings-table-disablesimsmerecoverydescr": "Lorsque cette fonction est activée, les utilisateurs qui oublient leur mot de passe doivent attendre l'approbation explicite de l'administrateur avant qu'un code de récupération ne soit envoyé.",
    "modules-appsettings-table-forcePwComplexity": "Exiger la complexité des mots de passe",
    "modules-appsettings-table-forcePwComplexityDescr": "Forcer la complexité du mot de passe de l'application",
    "modules-appsettings-table-localmedia": "Ne pas autoriser l'enregistrement local des médias",
    "modules-appsettings-table-localmediadescr": "En activant cette option, vous supprimez l'option 'Enregistrer les médias', ce qui signifie que les images et les vidéos ne peuvent pas être enregistrées localement.",
    "modules-appsettings-table-passwordmaxduration": "Validité du mot de passe en jours",
    "modules-appsettings-table-passwordmaxdurationdescr": "Définissez la durée de validité du mot de passe en jours, par exemple \"30 jours\". 30 jours signifie qu'après 30 jours, l'utilisateur doit choisir un nouveau mot de passe.",
    "modules-appsettings-table-passwordminclasses": "Niveau de complexité minimal (chiffres, caractères spéciaux, lettres minuscules et majuscules)",
    "modules-appsettings-table-passwordminclassesdescr": "Ce paramètre permet de définir le nombre minimum de classes de caractères (chiffres, caractères spéciaux, minuscules et majuscules) qui doivent être utilisées dans le mot de passe. 2 signifie que le mot de passe doit contenir au moins deux classes (telles que chiffres + lettres minuscules ou caractères spéciaux + chiffres).",
    "modules-appsettings-table-passwordminlength": "Nombre minimum de caractères",
    "modules-appsettings-table-passwordminlengthdescr": "Ce paramètre définit la complexité obligatoire du mot de passe en fonction du nombre minimum de caractères. 0 caractère signifie qu'il n'y a pas de longueur minimale.",
    "modules-appsettings-table-passwordminlowcase": "Nombre minimum de lettres minuscules",
    "modules-appsettings-table-passwordminlowcasedescr": "Définissez le nombre de lettres minuscules à utiliser, par exemple 'abcde'. 0 caractère signifie qu'aucune lettre minuscule n'est nécessaire.",
    "modules-appsettings-table-passwordminnumbers": "Nombre minimum de chiffres",
    "modules-appsettings-table-passwordminnumbersdescr": "Définissez le nombre de chiffres à utiliser, par exemple '12345'. 0 caractère signifie qu'aucun chiffre n'est nécessaire.",
    "modules-appsettings-table-passwordminspecialchars": "Nombre minimum de caractères spéciaux",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Définissez le nombre de caractères spéciaux tels que '!§$%' qui doivent être utilisés. 0 caractère signifie qu'aucun caractère spécial n'est requis.",
    "modules-appsettings-table-passwordminuppercase": "Nombre minimum de lettres majuscules",
    "modules-appsettings-table-passwordminuppercasedescr": "Définissez le nombre de lettres majuscules, telles que 'ABCDE', qui doivent être utilisées. 0 caractère signifie qu'aucune majuscule n'est requise.",
    "modules-appsettings-table-pwinact": "Forcer la vérification du mot de passe après l'inactivité de …",
    "modules-appsettings-table-pwinactdescr": "Ce paramètre permet de définir la durée d'inactivité de l'application avant que l'utilisateur ne doive ressaisir le mot de passe.",
    "modules-appsettings-table-pwpolicy": "Forcer la vérification du mot de passe au démarrage de l'application",
    "modules-appsettings-table-pwpolicydescr": "Si cette fonction est activée, l'application demandera le mot de passe de l'utilisateur à chaque démarrage.",
    "modules-appsettings-table-pwwdh": "Le mot de passe peut être réutilisé après X changements",
    "modules-appsettings-table-pwwdhdescr": "Vous pouvez définir combien de fois un utilisateur doit utiliser un nouveau mot de passe avant de pouvoir réutiliser un mot de passe précédent. 1 répétition signifie que le mot de passe peut être utilisé après qu'un mot de passe différent a été défini dans l'intervalle.",
    "modules-appsettings-table-sharemedia": "Désactiver le partage des fichiers multimédias avec d'autres applications",
    "modules-appsettings-table-sharemediadescr": "Lorsque ce paramètre est activé, les images et les vidéos ne peuvent plus être enregistrées et les fichiers ne peuvent pas être affichés.",
    "modules-appsettings-table-simplecode": "Interdiction d'utiliser un simple code à 4 chiffres comme mot de passe",
    "modules-appsettings-table-simplecodedescr": "Si ce paramètre est activé, les utilisateurs ne peuvent pas utiliser un simple code à 4 chiffres comme mot de passe. Pour s'assurer que les mots de passe à 4 chiffres existants doivent être modifiés, définissez une complexité de mot de passe appropriée.",
    "modules-channel-commonbutton-btnUp": "au sommet",
    "modules-channels-Channel-channel": "Gérer les canaux",
    "modules-channels-Channel-confirmDeleteChannel": "Êtes-vous certain de vouloir supprimer Channel ?",
    "modules-channels-ChannelList-table-column1": "Nom du canal",
    "modules-channels-NewChannel": "Créer un nouveau canal",
    "modules-channels-NewChannel-noMember": "Pas de membres de la chaîne",
    "modules-channels-channel-btnDeleteChannel": "Supprimer un canal",
    "modules-channels-channel-tab-editChannel": "Modifier le canal",
    "modules-channels-channel-tab-messages": "Messages",
    "modules-channels-messages-senddateinvalid": "Veuillez choisir une date dans le futur.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Activité",
    "modules-dashboard-AktivitatenChart-displayValue1": "Utilisateurs actifs quotidiens",
    "modules-dashboard-AktivitatenChart-displayValue2": "Utilisateurs actifs mensuels",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} messages",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Utilisateurs actifs quotidiens",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Utilisateurs actifs mensuels",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Nombre d'utilisateurs qui ouvrent l'application quotidiennement",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Nombre d'utilisateurs qui ouvrent l'application chaque mois",
    "modules-dashboard-AktivitatenChart-timeValue1": "Une semaine",
    "modules-dashboard-AktivitatenChart-timeValue2": "Un mois",
    "modules-dashboard-AktivitatenChart-timeValue3": "Période totale",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} utilisateurs",
    "modules-dashboard-ChannelChart-channels": "Canaux",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Veuillez sélectionner une chaîne.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Veuillez sélectionner un groupe.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "pas de données disponibles pour l'instant",
    "modules-dashboard-ChannelChart-popOver-text1": "Le Top 5 est constitué des messages dont les fichiers, les images ou les liens ont été le plus souvent cliqués par les utilisateurs.",
    "modules-dashboard-ChannelChart-table-column1": "Nom",
    "modules-dashboard-ChannelChart-table-column2": "Utilisateurs",
    "modules-dashboard-ChannelChart-table-column3": "Messages",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "Groupes d'utilisateurs",
    "modules-dashboard-ChannelChart-table-showAll": "Tout afficher …",
    "modules-dashboard-ChannelChart-table-title": "Top 5 des conversations",
    "modules-dashboard-ChannelChart-table-titleInfo": "Nombre de clics sur les liens",
    "modules-dashboard-ChannelChart-timeValue1": "Une semaine",
    "modules-dashboard-ChannelChart-timeValue2": "Un mois",
    "modules-dashboard-Dashboard-dashboard": "Tableau de bord",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} des licences {{v2}} sont actives",
    "modules-dashboard-GruppenChart-groups": "Groupe administré",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} messages",
    "modules-dashboard-GruppenChart-table-column1": "Nom",
    "modules-dashboard-GruppenChart-table-column2": "Utilisateurs",
    "modules-dashboard-GruppenChart-table-column3": "Messages",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} les messages dans les groupes générés par les utilisateurs",
    "modules-dashboard-GruppenChart-table-showAll": "Tout afficher …",
    "modules-dashboard-GruppenChart-table-title": "Messages",
    "modules-dashboard-GruppenChart-timeValue1": "Une semaine",
    "modules-dashboard-GruppenChart-timeValue2": "Un mois",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Mélange de messages",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "Une semaine",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "Un mois",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Période totale",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} Nombre total de messages",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} messages individuels",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} groupes",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} canaux",
    "modules-group-group-identifier": "Groupe",
    "modules-group-groups-identifier": "Groupes",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Vérifier",
    "modules-groups-GroupList-table-column1": "Nom du groupe",
    "modules-groups-GroupList-table-column2": "Sur invitation",
    "modules-groups-GroupList-table-column3": "Rejoint",
    "modules-groups-GroupList-table-column4": "Remarquable",
    "modules-groups-GroupList-table-column5": "Dernière activité",
    "modules-groups-Groups-btnCancelNewGroup": "Annuler",
    "modules-groups-Groups-btnCreateGroup": "Créer un nouveau groupe",
    "modules-groups-Groups-btnDeleteGroup": "Supprimer un groupe",
    "modules-groups-Groups-confirmDeleteGroup": "Êtes-vous certain de vouloir supprimer le groupe ?",
    "modules-groups-Groups-groups": "Gérer les groupes",
    "modules-groups-NewGroup-btnImportImage": "Sélectionner l'image",
    "modules-groups-NewGroup-newgroup": "Créer un nouveau groupe",
    "modules-groups-NewGroup-noMember": "Aucun membre du groupe",
    "modules-groups-NewGroup-placeholder-RSS-preview": "Aperçu du RSS",
    "modules-groups-NewGroup-placeholder-feed": "Définir une source RSS externe.",
    "modules-groups-NewGroup-placeholder-name": "Nom",
    "modules-groups-NewGroup-searchHint": "Recherche par prénom, nom ou mot-clé",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Fermer",
    "modules-license-DPAGOrderLicenseDialog-data-link": "données",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "Moyens de données",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) les données contractuelles et d'inventaire, par exemple la société, les adresses, les adresses électroniques ou les numéros de téléphone des contacts personnels, et les informations relatives au service clientèle, ainsi que les données relatives au paiement et à la facturation, et",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) les données relatives au trafic, c'est-à-dire les informations sur l'heure, la durée et le volume des messages envoyés par les utilisateurs, ainsi que les messages cryptés eux-mêmes qui sont encore en cours de transmission.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "De plus amples informations sont disponibles à l'adresse suivante : {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "ici",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "http://sims.me/datenuebergang/business/en",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "Je souhaite assurer la continuité du messager ginlo Business pour mes utilisateurs et charge donc par la présente la société ginlo.net GmbH de fournir les services ginlo Business pour mes licences existantes à partir de la date d'expiration de celles-ci à la Deutsche Post AG. Pour la fourniture des services, j'accepte que mes données et le site {{v1}} de mes utilisateurs soient transférés de Brabbler AG à ginlo.net GmbH. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "J'ordonne par la présente",
    "modules-license-DPAGOrderLicenseDialog-order2": "nouvelles licences.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Politique de confidentialité",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "Les sites {{v1}} et {{v2}} de ginlo.net GmbH s'appliquent à toutes les licences.",
    "modules-license-DPAGOrderLicenseDialog-text1": "SiMSme Business (désormais ginlo Business) a un nouveau propriétaire : ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH). Les commandes de nouvelles licences ne peuvent être passées qu'auprès de ginlo.net GmbH. ",
    "modules-license-License-btn-new": "Commander une nouvelle licence",
    "modules-license-License-btn-select": "Attribuer une licence",
    "modules-license-License-delete-user-btn": "Supprimer un utilisateur",
    "modules-license-License-delete-user-text": "Êtes-vous certain de vouloir supprimer l'utilisateur, ce qui bloquera son accès à l'application ?",
    "modules-license-License-delete-user-title": "Supprimer {{v1}} ?",
    "modules-license-License-extend-header": "Renouvellement de la licence",
    "modules-license-License-extend-text": "Attribuer une licence à ",
    "modules-license-License-license": "Gestion des licences",
    "modules-license-License-revoke-btn": "Retrait de la licence",
    "modules-license-License-revoke-text": "Êtes-vous certain de vouloir révoquer la licence de l'utilisateur ?",
    "modules-license-License-revoke-title": "Retirer la licence à {{v1}} ?",
    "modules-license-License-searchfield-hint": "Recherche par prénom, nom ou mot-clé",
    "modules-license-License-table-column-count": "Nombre",
    "modules-license-License-table-column-expireDate": "Valable jusqu'au",
    "modules-license-License-table-column-license": "Licence",
    "modules-license-License-table-column-licenses": "Licences",
    "modules-license-License-table-column-more-assign": "Attribuer une licence",
    "modules-license-License-table-column-more-delete": "Supprimer la licence",
    "modules-license-License-table-column-more-extend": "Renouvellement de la licence",
    "modules-license-License-table-column-more-revoke": "Retrait de la licence",
    "modules-license-License-table-header-availableUser": "Utilisateurs disponibles",
    "modules-license-License-table-header-freeLicense": "Licences disponibles",
    "modules-license-License-table-header-usedLicense": "Licences actives",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/en/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/en/privacy",
    "modules-license-license-assignment-error": "La cession n'est pas possible. Il n'y a que {{free}} licences gratuites disponibles.",
    "modules-roles-NewRole-noMember": "Pas de membres",
    "modules-roles-NewRole-placeholder-name": "Nom",
    "modules-roles-NewRole-searchHint": "Recherche par prénom, nom ou mot-clé",
    "modules-roles-Newrole-newrole": "Créer un nouveau rôle",
    "modules-roles-RoleList-table-column1": "Nom du rôle",
    "modules-roles-RoleList-table-column2": "# Utilisateurs",
    "modules-roles-Roles-btnCreateRole": "Créer un nouveau rôle",
    "modules-roles-Roles-btnDeleteRole": "Supprimer un rôle",
    "modules-roles-Roles-confirmDeleteRole": "Êtes-vous certain de vouloir supprimer le rôle ?",
    "modules-roles-Roles-roles": "Gérer les rôles des utilisateurs",
    "modules-settings-Admin-admin": "Représentant",
    "modules-settings-Admin-btnActivateAdmin": "Activer",
    "modules-settings-Admin-btnCancelNewAdmin": "Annuler",
    "modules-settings-Admin-btnCreateNewAdmin": "Créer un nouveau représentant",
    "modules-settings-Admin-btnDeactivateAdmin": "Désactiver",
    "modules-settings-Admin-btnDeleteAdmin": "Supprimer",
    "modules-settings-Admin-btnSaveNewAdmin": "Économiser",
    "modules-settings-Admin-confirmDeleteAdmin": "Êtes-vous sûr de vouloir supprimer le représentant ?",
    "modules-settings-Admin-search": "Saisir le terme de recherche",
    "modules-settings-Admin-setAdminEnabled": "Êtes-vous sûr de vouloir que {{adminName}} accorde tous les droits à un représentant ? Cela inclut la création, l'édition et la suppression de nouveaux utilisateurs, groupes et canaux.",
    "modules-settings-Admin-setAdminEnabled-title": "Un représentant a été créé",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Annuler",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Activer",
    "modules-settings-AdminDetails-last-login": "Dernière connexion le",
    "modules-settings-AdminList-table-adminStatus1": "créé",
    "modules-settings-AdminList-table-adminStatus2": "handicapé",
    "modules-settings-AdminList-table-adminStatus3": "actif",
    "modules-settings-AdminList-table-adminStatus4": "supprimé",
    "modules-settings-AdminList-table-column1": "Nom de famille",
    "modules-settings-AdminList-table-column2": "Prénom",
    "modules-settings-AdminList-table-column3": "Courriel",
    "modules-settings-AdminList-table-column4": "Numéro de téléphone mobile",
    "modules-settings-AdminList-table-column6": "Activité",
    "modules-settings-AdminList-table-column7": "Dernière connexion",
    "modules-settings-AdminList-table-row-never-log-in": "jamais",
    "modules-settings-ApiCredentialsDialog-close-button": "Fermer",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Jeton d'authentification",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "Empreintes digitales du certificat de l'utilisateur",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "URL de surveillance",
    "modules-settings-ApiCredentialsDialog-column-password": "Mot de passe",
    "modules-settings-ApiCredentialsDialog-column-username": "Nom d'utilisateur",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Valable jusqu'au",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Expiré",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "Copier l'URL",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "Vous pouvez vérifier la validité du certificat à l'aide de la clé publique du fichier .CRT.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Télécharger le certificat .CRT",
    "modules-settings-ApiCredentialsDialog-edit-button": "Editer",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "Cette URL vous permet de contrôler l'état des importations à l'aide de votre système de surveillance. Aucune donnée personnelle n'est transmise au cours de ce processus. Le format de la demande est décrit dans la documentation de l'API.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Envoyer un nouveau certificat",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Générer une nouvelle URL",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Générer un nouveau mot de passe",
    "modules-settings-ApiCredentialsDialog-title": "Informations de connexion",
    "modules-settings-DeleteApiUserDialog-text": "Cela arrêtera la synchronisation automatique et vos informations de connexion ainsi que l'URL de surveillance seront supprimées.",
    "modules-settings-DeleteApiUserDialog-title": "Voulez-vous vraiment désactiver l'API ?",
    "modules-settings-NewAdmin-hint": "Il peut créer, modifier et supprimer des utilisateurs, des groupes et des canaux. Cependant, le représentant ne peut pas créer d'autres représentants.",
    "modules-settings-NewAdmin-hint-title": "Le représentant reçoit tous les droits d'administrateur.",
    "modules-settings-NewAdmin-newadmin": "Créer un nouveau représentant",
    "modules-user-AdminList-table-auditLog-active": "Activé le",
    "modules-user-AdminList-table-auditLog-created": "Créé le",
    "modules-user-AdminList-table-auditLog-deactive": "Désactivé le",
    "modules-user-AdminList-table-auditLog-delete": "Supprimé le",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Exporter tous les appareils utilisés dans un fichier CSV.",
    "modules-user-ChooseCSVExport-title": "Sélectionner les données à exporter",
    "modules-user-ChooseTemplate-csv-detail": "CSV simplifié pour la création d'un seul utilisateur",
    "modules-user-ChooseTemplate-ldap-detail": "CSV étendu pour une mise à jour LDAP continue et des affectations automatiques dans les groupes et les canaux",
    "modules-user-ChooseTemplate-title": "Télécharger un modèle d'importation",
    "modules-user-Keyword-deleteKeyword": "Voulez-vous vraiment supprimer le mot-clé {{keyword}} et toutes les occurrences où il est utilisé ?",
    "modules-user-Keyword-jumpToUser": "Souhaitez-vous annuler les modifications actuelles et passer à {{displayName}} ?",
    "modules-user-Keyword-searchHint": "Recherche par mot-clé",
    "modules-user-Keyword-showUsage": "Affichage",
    "modules-user-Keyword-table-column1": "Mot-clé",
    "modules-user-Keyword-table-column2": "Appliquer",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Annuler",
    "modules-user-NewUser-btnNewUserSave": "Économiser",
    "modules-user-NewUser-keyword": "Mot-clé",
    "modules-user-NewUser-keywordEdit": "Modifier les mots-clés",
    "modules-user-NewUser-keywordEditEnd": "Fermer l'éditeur",
    "modules-user-NewUser-newuser": "Créer des données utilisateur",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "import_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "Souhaitez-vous attribuer une licence ?",
    "modules-user-User-assignVoucher-title": "{{displayName}} a été créé.",
    "modules-user-User-assignVoucherBtnCancel": "Attribuer plus tard ",
    "modules-user-User-assignVoucherBtnOk": "Attribuer une licence",
    "modules-user-User-assignVouchersByImportID": "Voulez-vous attribuer automatiquement une licence à tous les utilisateurs importés ?",
    "modules-user-User-assignVouchersByImportID-title": "Importation réussie des utilisateurs : {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Attribuer des licences",
    "modules-user-User-btnCancelNewUser": "Annuler",
    "modules-user-User-btnCreateNewuser": "Créer des données utilisateur",
    "modules-user-User-btnDeleteUser": "Supprimer un utilisateur",
    "modules-user-User-btnImportUser": "Importer les données des utilisateurs",
    "modules-user-User-btnImportVorlage": "Importation et exportation CSV",
    "modules-user-User-btnRevokeLicence": "Licence de verrouillage",
    "modules-user-User-btnSaveNewUser": "Économiser",
    "modules-user-User-btnSaveUser": "Enregistrer les modifications",
    "modules-user-User-confirmDeleteUser": "Êtes-vous certain de vouloir supprimer l'utilisateur ?",
    "modules-user-User-confirmRevokeLicense": "Êtes-vous certain de vouloir bloquer la licence de l'utilisateur, ce qui lui bloquera l'accès à l'application ?",
    "modules-user-User-email-hinttext": "e-mail",
    "modules-user-User-importLdap-applyData": "Continuer",
    "modules-user-User-importLdap-cancel": "Annuler",
    "modules-user-User-importLdap-done": "Fermer",
    "modules-user-User-importLdap-exportApplyData": "Rapport d'exportation et d'importation",
    "modules-user-User-importLdap-exportPreviewData": "Exporter le rapport",
    "modules-user-User-importLdap-state-applyData": "Les données sont traitées",
    "modules-user-User-importLdap-state-done": "Modifications adoptées",
    "modules-user-User-importLdap-state-fatal": "L'action a été annulée en raison d'une erreur.",
    "modules-user-User-importLdap-state-preflight": "Les données sont en cours de révision.",
    "modules-user-User-importLdap-state-waitConfirm": "Révision complète",
    "modules-user-User-importLdap-table-column1": "Catégorie",
    "modules-user-User-importLdap-table-column2": "Nombre d'entrées",
    "modules-user-User-importLdap-table-column3": "Notes",
    "modules-user-User-importLdap-table-column4": "Avertissements",
    "modules-user-User-importLdap-table-column5": "Erreurs",
    "modules-user-User-importLdap-table-row1": "Utilisateurs",
    "modules-user-User-importLdap-table-row2": "Groupes",
    "modules-user-User-importLdap-table-row3": "Canaux",
    "modules-user-User-importLdap-table-row4": "Mots clés",
    "modules-user-User-search": "Saisir le terme de recherche",
    "modules-user-User-user": "Administration des utilisateurs ({{v1}})",
    "modules-user-UserList-popover-phone": "Veuillez saisir les numéros de téléphone mobile au format \"012345678910\". Pour les numéros en dehors de l'Allemagne, veuillez inclure l'indicatif du pays mais pas le préfixe \"0\", c'est-à-dire \"+4312345678910\".",
    "modules-user-UserList-table-cmdDeleteUser": "Supprimer un utilisateur",
    "modules-user-UserList-table-cmdEdit": "Editer",
    "modules-user-UserList-table-cmdRevokeLicense": "Licence de verrouillage",
    "modules-user-UserList-table-column1": "Nom de famille",
    "modules-user-UserList-table-column2": "Prénom",
    "modules-user-UserList-table-column3": "Courriel",
    "modules-user-UserList-table-column4": "Numéro de téléphone mobile",
    "modules-user-UserList-table-column6": "Mot-clé",
    "modules-user-UserList-table-placeHolder-column1": "Nom de famille",
    "modules-user-UserList-table-placeHolder-column2": "Prénom",
    "modules-user-UserList-table-placeHolder-column3": "Courriel",
    "modules-user-UserList-table-placeHolder-column4": "Numéro de téléphone mobile",
    "modules-user-UserList-table-placeHolder-column6": "Mot-clé",
    "modules-user-UserList-table-placeHolder-column8": "Département",
    "modules-user-UserList-table-roomWriterHeader": "Accès en écriture",
    "modules-user-UserList-table-userStatus1": "Utilisateur créé",
    "modules-user-UserList-table-userStatus2": "Licence attribuée",
    "modules-user-UserList-table-userStatus3": "Licence active",
    "modules-user-UserList-table-userStatus4": "Licence expirée",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Licence expirée - utilisateur bloqué",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Retrait de la licence - blocage de l'utilisateur",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Licence active - administration acceptée",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "Annulation de la validation de l'e-mail",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "Validation du courrier électronique en attente",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Annulation de la validation du numéro de téléphone mobile",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "Validation du numéro de téléphone mobile en attente",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Compte supprimé par l'utilisateur",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Demande de renseignements administratifs rejetée",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "Utilisateur créé - pas de licence attribuée",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Licence attribuée - en attente d'administration ",
    "modules-user-UserList-userCreated": "L'utilisateur {{firstName}} {{lastName}} a été créé avec succès.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Attribuer une licence",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Attribuer une licence ultérieurement",
    "modules-user-alert-btnSendManagementRequest": "Renvoyer la demande",
    "modules-user-alert-sendManagementRequestHint": "L'utilisateur a rejeté la demande d'autorisations administratives.",
    "modules-user-device-deviceList": "Dispositifs utilisés",
    "modules-user-device-unknown-devicename": "Nom de l'appareil inconnu",
    "modules-user-managementrequest-messagesent": "La demande de gestion de l'application a été envoyée à l'utilisateur.",
    "modules-user-password-btnSendRecoveryEMail": "Envoyer le code de récupération",
    "modules-user-password-btnShowRecoveryCode": "Afficher le code de recouvrement",
    "modules-user-password-revoverPasswordHint": "Cet utilisateur a oublié le mot de passe de son appareil. Veuillez lui envoyer son code de récupération.",
    "modules-user-recover-bottomBarText": "L'utilisateur a oublié son mot de passe.",
    "modules-user-recoverycode-messagesent": "Le code de récupération est en cours d'acheminement vers l'utilisateur. Vous trouverez une copie de l'e-mail dans votre boîte de réception.",
    "modules-user-recoverycode-title": "Code de recouvrement",
    "okay": "D'accord",
    "phone": "Numéro de téléphone mobile",
    "save": "Économiser",
    "secret": "Secret",
    "settings-admin": "Administrateur",
    "settings-admin-change-pwd": "Modifier le mot de passe",
    "settings-btnDeleteCompany": "Supprimer le compte",
    "settings-company-label-ustIdNr": "Numéro de TVA",
    "settings-companycockpittoken": "Déploiement de l'EMM",
    "settings-companycockpittoken-documentation-link": "Documentation",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/en/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "Si votre organisation utilise une solution de gestion de la mobilité d'entreprise, vous pouvez activer l'enregistrement automatique pour le déploiement.",
    "settings-companycockpittoken-switch-enable-label": "Enregistrement automatique de l'EMM",
    "settings-companycockpittoken-switch-force-desc": "Si cette fonction n'est pas activée, l'inscription au service de messagerie s'effectuera de manière entièrement automatique. Veuillez tenir compte des instructions figurant sur le site {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Assurer un enregistrement rapide sur le terminal",
    "settings-companycockpittoken-token-desc": "Vous avez besoin du jeton de sécurité pour l'enregistrement automatique de l'EMM. Voir {{v1}}.",
    "settings-companycockpittoken-token-label": "Le jeton de sécurité est : ",
    "settings-companycockpittoken-token-notset": "L'enregistrement automatique n'est pas activé",
    "settings-companyinformation": "Données de l'entreprise",
    "settings-companylanguage": "Paramètres linguistiques",
    "settings-companylanguage-de": "Allemand",
    "settings-companylanguage-desc": "Langue de votre entreprise pour l'envoi d'invitations par courrier électronique et d'autres communications.",
    "settings-companylanguage-el": "Grecque",
    "settings-companylanguage-en": "Anglais",
    "settings-companylanguage-es": "Espagnol",
    "settings-companylanguage-fr": "Français",
    "settings-companyprofilimage": "Photo du profil de l'entreprise",
    "settings-companyprofilimage-btnImportImage": "Sélectionner l'image.",
    "settings-companyprofilimage-desc": "Sélectionnez une photo de profil qui apparaîtra dans les canaux à côté des notifications.",
    "settings-companyprofilimage-desc2": "Recommandation de taille 200 x 200 pixels.",
    "settings-confirmdeletecompanydialog-desc": "En supprimant votre compte, tous les comptes d'utilisateurs seront supprimés et les licences correspondantes seront bloquées.",
    "settings-confirmdeletecompanydialog-hint": "Saisir le mot de passe",
    "settings-confirmdeletecompanydialog-ok": "Supprimer irrévocablement le compte",
    "settings-confirmdeletecompanydialog-title": "Êtes-vous sûr de vouloir supprimer votre compte ?",
    "settings-ldap-sync-delete-api-user-button": "Désactiver l'API",
    "settings-ldap-sync-descr-text": "L'API ginlo est un service RESTful que vous pouvez utiliser pour automatiser la création, l'édition et la suppression d'utilisateurs, de groupes et de canaux.",
    "settings-ldap-sync-documentation-link-pretext": "Vous trouverez le site {{v1}} correspondant ici (en allemand uniquement).",
    "settings-ldap-sync-documentation-link-text": "Documentation",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/en/cockpit/index.html#ldap-data",
    "settings-ldap-sync-ldap-activated": "L'API est activée",
    "settings-ldap-sync-ldap-activated-hint": "Il est possible que des modifications manuelles soient écrasées.",
    "settings-ldap-sync-new-api-user-button": "Activer l'API",
    "settings-ldap-sync-show-api-user-credentials-button": "Informations de connexion",
    "settings-ldap-sync_title": "ginlo Business API",
    "settings-order-in-year": "€ par an",
    "settings-order-input-hint": "0 à 500 licences",
    "settings-order-tax-info": "plus la TVA applicable",
    "settings-orderhistory": "Historique des commandes",
    "settings-orderhistory-agb": "Oui, je suis d'accord avec {{v1}}.",
    "settings-orderhistory-agb-link": "Conditions générales d'utilisation",
    "settings-orderhistory-license": "Licence",
    "settings-orderhistory-licenses": "Licences",
    "settings-orderhistory-order": "Commander maintenant (contraignant)",
    "settings-orderhistory-privacy": "Oui, j'ai lu et compris le site {{v1}}.",
    "settings-orderhistory-privacy-link": "Politique de confidentialité",
    "settings-orderhistory-table-column-date": "Date",
    "settings-primarydomain": "Domaine principal",
    "settings-pwd-current-hint": "Saisir le mot de passe actuel",
    "settings-save-error": "L'erreur suivante s'est produite lors de l'enregistrement des données :",
    "settings-settings": "Paramètres",
    "settings-stellvertreter": "Représentant",
    "settings-stellvertreter-btn-edit": "Modifier le représentant",
    "settings-stellvertreter-details": "{{activeAdminCount}} actif {{adminCount}} assigné",
    "settings-stellvertreter-last-login-never": "Pas encore connecté",
    "settings-stellvertreter-last-login-ok": "Dernière connexion ",
    "settings-two-fa": "Authentification à deux facteurs",
    "settings-two-fa-btn-show-recovery-codes": "Afficher les codes de récupération",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Accepter les codes de récupération",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Veuillez copier ces codes de récupération",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "Vos codes de récupération",
    "settings-two-fa-create-recovery-codes-dialog-title": "Vos codes de récupération",
    "settings-two-fa-disable-dialog-btn-submit": "Désactiver",
    "settings-two-fa-disable-dialog-description": "Voulez-vous vraiment désactiver l'authentification à deux facteurs ?",
    "settings-two-fa-disable-dialog-title": "Désactivation de l'authentification à deux facteurs",
    "settings-two-fa-disabled": "2FA est désactivé",
    "settings-two-fa-enable-dialog-btn-submit": "Soumettre le jeton et activer le 2FA",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Activation de l'authentification à deux facteurs",
    "settings-two-fa-enable-dialog-token-help-text": "Veuillez scanner le code QR ou le saisir manuellement dans votre application d'authentification.",
    "settings-two-fa-enable-dialog-token-not-valid": "Le jeton n'est pas valide",
    "settings-two-fa-enabled": "2FA est activé",
    "settings-two-fa-label": "L'authentification à deux facteurs est :",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Veuillez copier ces codes de récupération. Ils vous permettent de vous connecter au cockpit lorsque vous avez perdu votre secret.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Codes de recouvrement",
    "settings-two-fa-show-recovery-codes-dialog-title": "Vos codes de récupération",
    "state": "Statut de l'utilisateur",
    "token": "Token",
    "validation-errormessages-maxvalue": "{{max}} maximum autorisé",
    "validation-errormessages-minvalue": "minimum requis {{min}}",
    "welcome-channel-btn": "Créer des canaux",
    "welcome-channel-description": "Les canaux vous permettent d'envoyer des notifications push à vos utilisateurs. Les messages, les images et les fichiers peuvent être distribués de manière centralisée à vos utilisateurs. Vous pouvez ensuite consulter les chiffres de conversion sur le tableau de bord.",
    "welcome-channel-subheadline-create": "Créer des canaux",
    "welcome-chat-subheadline": "Simplifier la communication",
    "welcome-config-subheadline": "Configurer l'application",
    "welcome-design-btn": "Modifier la conception de l'application",
    "welcome-design-description": "Vous avez la possibilité de modifier le design de l'application pour l'adapter aux lignes directrices de votre entreprise en matière de design. Vous pouvez modifier le logo et les couleurs à tout moment.",
    "welcome-design-subheadline-create": "Personnaliser le design de l'application",
    "welcome-group-btn": "Créer des groupes",
    "welcome-group-description": "Créez des groupes pour faciliter la collaboration entre les utilisateurs. L'activité des groupes peut être visualisée dans le tableau de bord.",
    "welcome-group-subheadline-add": "Créer des groupes",
    "welcome-headline": "Bienvenue {{v1}}",
    "welcome-intro": "ginlo Business Cockpit est un outil pratique qui permet de configurer le logiciel sécurisé ginlo Business Messenger pour Android et iPhone en fonction de vos besoins.",
    "welcome-license-btn": "Attribuer une licence",
    "welcome-license-description": "Attribuez les licences disponibles aux comptes d'utilisateurs créés. Si vous n'avez pas assez de licences disponibles, {{v1}} la quantité souhaitée pour combler l'écart.",
    "welcome-license-description-link": "commande",
    "welcome-license-subheadline-add": "Attribuer une licence",
    "welcome-settings-btn": "Modifier les paramètres",
    "welcome-settings-description": "Personnalisez les exigences de sécurité et les paramètres de l'application pour répondre à vos spécifications de sécurité.",
    "welcome-settings-subheadline-create": "Configurer les paramètres de l'application",
    "welcome-user-btn": "Créer un nouvel utilisateur",
    "welcome-user-description": "De nouveaux comptes d'utilisateurs peuvent être créés rapidement et facilement. Il suffit d'utiliser la fonction \"créer un seul utilisateur\" ou d'importer les données des utilisateurs à l'aide du modèle CSV.",
    "welcome-user-subheadline": "Gérer les utilisateurs",
    "welcome-user-subheadline-create": "Créer un utilisateur"
}
;
    
    export default TranslationCommonFr;
    
