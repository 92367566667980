/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';


export class PlatzhalterPieChart extends Component {
  render() {
    return (
      <svg width="160" height="160" viewBox="0 0 160 160">
        <title>platzhalter_pie_chart</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g fill="none" fillRule="evenodd">
          <rect width="160" height="160" fill="#00375D" />
          <g stroke-width="30" transform="translate(15 15)">
            <path stroke="#7B98AB" d="M65,130 C29.1011724,130 0,100.898828 0,65 C0,29.1011724 29.1011724,0 65,0 C100.898828,0 130,29.1011724 130,65 C130,100.898828 100.898828,130 65,130 Z"
              opacity=".5" />
            <path stroke="#00375D" d="M48.7023034,127.923676 C20.1193379,120.520848 -0.000179310345,94.5271241 -0.000179310345,65.0000897 C-0.000179310345,29.3415379 29.3421655,8.96551724e-05 64.9998207,8.96551724e-05"
              opacity=".2" />
            <path stroke="#00375D" d="M121.175421,97.700469 C109.548938,117.673848 88.1114897,129.999641 65.0001793,129.999641 C29.3416276,129.999641 0.000179310345,100.658193 0.000179310345,64.9996414 C0.000179310345,29.3419862 29.3416276,-0.00035862069 65.0001793,-0.00035862069"
              opacity=".2" />
            <path stroke="#00375D" d="M116.845883,25.7955862 C125.380159,37.0813793 130.00009,50.8506207 130.00009,65 C130.00009,100.657655 100.657745,130 65.0000897,130 C29.3415379,130 8.96551724e-05,100.657655 8.96551724e-05,65 C8.96551724e-05,29.3423448 29.3415379,0 65.0000897,0"
              opacity=".2" />
          </g>
        </g>
      </svg>
    )
  }
}

export default PlatzhalterPieChart;
