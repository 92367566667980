/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Table, TableBody, } from 'material-ui/Table';

import BaseModul from '../BaseModul';
import { WaitDialog } from '../../components/WaitDialog'
import { ModalDialog } from '../../components/ModalDialog'
import { PageHeader } from '../../components/pageheader';
// import { BackSubPageHeader } from '../../components/pageheader';

import { getAppConfig, setAppConfig, resetAppConfig } from '../../actions/index';

import { ToggledSettingsTableRow, MenueSettingsTableRow, InputValueSettingsTableRow } from '../../components/table/SettingTableRows';

// import ToggleButton from 'material-ui/Toggle';
import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';
import DataProvider from './DataProvider';

import { maxValue, minValue } from "../../components/validation";
import { numeric } from "../../components/inputTypes";

import IconReset from '../../ressources/icon_reset.js';


const styles = {
  root: {
    width: '100%',
    height: 'fill'
  },
  subPageHeader: {
    fontSize: '20px',
    fontWeight: 'medium',
    color: '#EEC643',
    marginLeft: '25px',
    marginTop: '36px',
  },
  tableStyle: {
    marginLeft: '25px',
    marginTop: '8px',
    marginBottom: '8px',
    marginRight: '3px',
    width: 'auto',
    height: 'auto',
  },
  scrollable: {
    overflowY: 'auto',
    maxHeight: '800px', /*TODO: Kommt man hier auch ohne maxHeight aus? */
  },
};

class AppSettings extends BaseModul {
  state = {
    isPendingChanges: false,
    isFormValid: true,
    invalidFields: [],
    AppConfig: {
      disableNoPwLogin: "",
      simsLockApplicationDelay: "",
      forceComplexPin: "false",
      simsPasswordTries: "",
      disableSaveToCameraRoll: "",
      disableSendMedia: "",
      disableOpenIn: "false",
      forcePasswordKomplexity: "false",
      passwordMinLength: "",
      passwordMinSpecialChar: "",
      passwordMinDigit: "",
      passwordMinLowercase: "",
      passwordMinUppercase: "",
      passwordMinClasses: "",
      passwordMaxDuration: "",
      passwordReuseEntries: "",
      disableExportChat: "false",
      userRestrictedIndex: "0",
    },
  }

  constructor(props) {
    super(props);

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleDateProviderDataUpdated = this.handleDateProviderDataUpdated.bind(this);
    this.dataprovider = new DataProvider();
    this.dataprovider.addCallback(this.handleDateProviderDataUpdated);

    this.appConfigLoadedHandler = this.appConfigLoadedHandler.bind(this);
    this.appConfigSavedHandler = this.appConfigSavedHandler.bind(this);
    this.appConfigErrorHandler = this.appConfigErrorHandler.bind(this);

    this.handleTheCancelConfirmation = this.handleTheCancelConfirmation.bind(this);

    this.handleResetConfigClicked = this.handleResetConfigClicked.bind(this);
    this.appConfigResetHandler = this.appConfigResetHandler.bind(this);

    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.appConfigLoadedNullHandler = this.appConfigLoadedNullHandler.bind(this);


  }

  trySelectFieldValue(tries) {
    const {t} = this.props;
    let label = tries === 1 ? t("modules-appsettings-selectfield-deleteaccount-try") : t("modules-appsettings-selectfield-deleteaccount-tries");
    return ({
      labelvalue: tries + " " + label,
      fieldvalue: tries,
    })
  }

  generateSelectFieldValues() {
    const {t} = this.props;

    return [
      {
        labelvalue: t("modules-appsettings-selectfield-deleteaccount-inactive"),
        fieldvalue: ''
      },
      this.trySelectFieldValue(3)
      ,
      this.trySelectFieldValue(5)
      ,
      this.trySelectFieldValue(10)
      ,
    ]
  }

  setIsPendingChanges(pending) {
    this.setState({
      isPendingChanges: pending
    });
    if (pending)
    {
      this.setDirty();
    }
    else
    {
      this.clearDirty();
    }
  }

  setIsFormInvalid(isInvalid) {
    this.setState({
      isFormInvalid: isInvalid
    });
  }

  updateStateFromDataProvider() {
    const appConfigData = this.dataprovider.getData();
    this.setState({
      AppConfig: appConfigData.AppConfig,
      csrftoken: appConfigData.csrftoken
    });
  }

  handleDateProviderDataUpdated() {
    this.updateStateFromDataProvider();
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadAppConfig();
  }

  loadAppConfig() {
    this.refs.waitDialog.open();
    this.props.dispatch(getAppConfig(this.appConfigLoadedHandler, this.appConfigErrorHandler))
  }

  handleResetConfigClicked() {
    this.refs.waitDialog.open();
    this.props.dispatch(resetAppConfig(this.appConfigResetHandler, this.appConfigErrorHandler));
  }

  appConfigResetHandler(json) {
    this.dataprovider.updateFromJsonString(json);
    this.updateStateFromDataProvider();

    this.setIsPendingChanges(false);
    this.setIsFormInvalid(false);
    this.refs.waitDialog.close();
  }
  appConfigLoadedHandler(json) {
    this.dataprovider.updateFromJsonString(json);
    this.refs.waitDialog.close();
  }

  appConfigLoadedNullHandler(json) {
    this.refs.waitDialog.close();
  }

  appConfigSavedHandler(json) {
    this.refs.waitDialog.close();
    this.setIsPendingChanges(false);
  }

  appConfigErrorHandler(errorJO) {
    this.refs.waitDialog.close();

    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);
  }

  handleReloadData() {
    this.loadAppConfig();
  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    this.props.dispatch(getAppConfig(this.appConfigLoadedNullHandler, this.appConfigErrorHandler))

  }


  /*TODO: extract!*/
  /* getLabeledValues(label, min, max) {
    var values = [];
    for (var value = min; value <= max; value++)
    {
      values.push({
        labelvalue: value + " " + label,
        fieldvalue: value
      });
    }
    return values;
  } */

  handleSaveClicked() {
    this.refs.waitDialog.open();
    this.dataprovider.update(this.state);
    const appConfigData = this.dataprovider.getData();
    this.props.dispatch(setAppConfig(JSON.stringify(appConfigData), this.appConfigSavedHandler, this.appConfigErrorHandler));
  }

  handleTheCancelConfirmation(e) {
    this.updateStateFromDataProvider();
    this.setIsPendingChanges(false);
    this.setIsFormInvalid(false);
  }

  handleCancelClicked() {
    const {t} = this.props;
    let yesNoDialog = {
      text: t("components-ModalDialog-looseDataQuestion"),
      button1Text: t("components-ModalDialog-no"),
      button1Handler: null,
      button2Text: t("components-ModalDialog-yes"),
      button2Handler: this.handleTheCancelConfirmation,
      title: t("components-ModalDialog-Warning"),
    };
    this.refs.modalDialog.open(yesNoDialog);
  }

  renderHiddenDialog() {
    return (
      <div>
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        <ModalDialog ref="commonErrorDialog" />
      </div>
      );
  }

  renderHeaderView() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
               <PageHeader style={ { paddingLeft: '25px', margin: 'auto' } }>
                 { t("modules-appsettings-header") }
               </PageHeader>
             </div>
             <div style={ this.getBaseStyle().right }>
               <FlatButton style={ { margin: 'auto', color: '#FFFFFF' } } label={ t('modules-appsettings-appsettings-btnResetConfig') } secondary={ true } icon={ <IconReset /> } labelPosition="before" onClick={ this.handleResetConfigClicked }
               />
             </div>
           </div>;
  }

  renderCommonButton() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().fabRight }>
               <FlatButton label={ t('modules-appdesign-appdesign-btncancel') } primary={ false } style={ this.getBaseStyle().cancelButton } onClick={ this.handleCancelClicked } disabled={ !(this.state.isPendingChanges) } />
               <RaisedButton label={ t('modules-appdesign-appdesign-btnsave') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleSaveClicked } disabled={ !(this.state.isPendingChanges && this.state.isFormValid) } />
             </div>
           </div>;
  }

  handleChangeData(value, name) {
    let oldData = JSON.parse(JSON.stringify(this.state.AppConfig))
    //der server erwartet die Werte als String
    if (name === 'forcePasswordKomplexity' && value === false)
    {
      oldData.passwordMinLength = "";
      oldData.passwordMinSpecialChar = "";
      oldData.passwordMinDigit = "";
      oldData.passwordMinLowercase = "";
      oldData.passwordMinUppercase = "";
      oldData.passwordMaxDuration = "";
      oldData.passwordMinClasses = "";
    }
    if (name === 'disableNoPwLogin' && value === false)
    {
      oldData.simsLockApplicationDelay = "";
    }
    if (name === 'disableRecoveryCode' && value === true)
    {
      oldData.disableSimsmeRecovery = "false";
    }
    if (name === 'userRestrictedIndex')
    {
      let v = (value) ? 1 : 0;
      oldData[name] = v + "";
    }
    else
    {
      oldData[name] = value + "";
    }


    this.setState({
      AppConfig: oldData
    });
    this.setIsPendingChanges(true);
  }

  handleValidation(isInvalid, fieldName) {
    const index = this.state.invalidFields.indexOf(fieldName);
    if (isInvalid)
    {
      if (index === -1)
      {
        this.state.invalidFields.push(fieldName);
      }
    }
    else
    {
      if (index > -1)
      {
        //remove field from array, as it is valid now!
        this.state.invalidFields.splice(index, 1);
      }
    }

    //set Form valid, when there are no invalidFields anymore
    var formIsValid = this.state.invalidFields.length === 0;
    this.setState({
      isFormValid: formIsValid
    });
  }

  renderMainView() {
    const {t} = this.props;
    //const d = this.state.data.disableNoPwLogin;
    // var repeats = this.getLabeledValues(t('modules-appsettings-label-repeat'), 1, 100);
    // var passwordInactivityValues = this.getLabeledValues(t('modules-appsettings-label-minutes'), 0, 10);
    // var passwordCharValues = this.getLabeledValues(t('modules-appsettings-label-chars'), 0, 99);
    // var passwordMaxDuration = this.getLabeledValues(t('modules-appsettings-label-days'), 0, 365);
    // var isPasswordKomplexity = this.state.forcePwComplexity;
    return (
      <div>
        <div style={ styles.subPageHeader }>
          { t('modules-appsettings-mainView-contactpolicy') }
        </div>
        <Table selectable={ false } style={ styles.tableStyle }>
          <TableBody displayRowCheckbox={ false }>
            <InputValueSettingsTableRow number={ '1.' } settingText={ t('modules-appsettings-table-companyindexname') } settingDescription={ t('modules-appsettings-table-companyindexnamedescr') } defaultValue={ this.state.AppConfig.companyIndexName === null ? "" : this.state.AppConfig.companyIndexName }
              onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'companyIndexName') } />
            <ToggledSettingsTableRow number={ '2.' } settingText={ t('modules-appsettings-table-contactClosedUserGroup') } settingDescription={ t('modules-appsettings-table-contactClosedUserGroupDescr') } defaultValue={ this.state.AppConfig.userRestrictedIndex === '1' }
              onToggle={ (event, value) => this.handleChangeData(value, 'userRestrictedIndex') } />
            <ToggledSettingsTableRow number={ '3.' } settingText={ t('modules-appsettings-table-disablesendvcard') } settingDescription={ t('modules-appsettings-table-disablesendvcarddescr') } defaultValue={ this.state.AppConfig.disableSendVCard === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableSendVCard') } />
          </TableBody>
        </Table>
        <div style={ styles.subPageHeader }>
          { t('modules-appsettings-mainView-pwpolicy') }
        </div>
        <Table selectable={ false } style={ styles.tableStyle }>
          <TableBody displayRowCheckbox={ false }>
            <ToggledSettingsTableRow number={ '4.' } settingText={ t('modules-appsettings-table-pwpolicy') } settingDescription={ t('modules-appsettings-table-pwpolicydescr') } defaultValue={ this.state.AppConfig.disableNoPwLogin === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableNoPwLogin') } />
            <InputValueSettingsTableRow number={ '5.' } settingText={ t('modules-appsettings-table-pwinact') } settingDescription={ t('modules-appsettings-table-pwinactdescr') } disabled={ this.state.AppConfig.disableNoPwLogin === 'false' }
              defaultValue={ this.state.AppConfig.simsLockApplicationDelay } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'simsLockApplicationDelay') } type={ numeric } validations={ [minValue(0, { t }), maxValue(10, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'simsLockApplicationDelay') }
              hintText={ t('modules-appsettings-hinttext-range1') } />
            <ToggledSettingsTableRow number={ '6.' } settingText={ t('modules-appsettings-table-simplecode') } settingDescription={ t('modules-appsettings-table-simplecodedescr') } defaultValue={ this.state.AppConfig.forceComplexPin === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'forceComplexPin') } />
            <ToggledSettingsTableRow number={ '7.' } settingText={ t('modules-appsettings-table-disablefaceandtouchid') } settingDescription={ t('modules-appsettings-table-disablefaceandtouchiddescr') } defaultValue={ this.state.AppConfig.disableFaceAndTouchID === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableFaceAndTouchID') } />
            <MenueSettingsTableRow number={ '8.' } settingText={ t('modules-appsettings-table-deletedatacount') } settingDescription={ t('modules-appsettings-table-deletedatacountdescr') } fields={ this.generateSelectFieldValues() } defaultValue={ parseInt(this.state.AppConfig.simsPasswordTries, 10) }
              onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'simsPasswordTries') } />
            <InputValueSettingsTableRow number={ '9.' } settingText={ t('modules-appsettings-table-pwwdh') } settingDescription={ t('modules-appsettings-table-pwwdhdescr') } defaultValue={ this.state.AppConfig.passwordReuseEntries }
              onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordReuseEntries') } type={ numeric } validations={ [minValue(0, { t }), maxValue(100, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordReuseEntries') } hintText={ t('modules-appsettings-hinttext-range2') }
            />
            <ToggledSettingsTableRow number={ '10.' } settingText={ t('modules-appsettings-table-disablerecoverycode') } settingDescription={ t('modules-appsettings-table-disablerecoverycodedescr') } defaultValue={ this.state.AppConfig.disableRecoveryCode === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableRecoveryCode') } />
            <ToggledSettingsTableRow number={ '11.' } settingText={ t('modules-appsettings-table-disablesimsmerecovery') } settingDescription={ t('modules-appsettings-table-disablesimsmerecoverydescr') } defaultValue={ this.state.AppConfig.disableSimsmeRecovery === 'true' }
              disabled={ this.state.AppConfig.disableRecoveryCode === 'true' } onToggle={ (event, value) => this.handleChangeData(value, 'disableSimsmeRecovery') } />
          </TableBody>
        </Table>
        <div style={ styles.subPageHeader }>
          { t('modules-appsettings-mainView-pwcomplexity') }
        </div>
        <Table selectable={ false } style={ styles.tableStyle }>
          <TableBody displayRowCheckbox={ false }>
            <ToggledSettingsTableRow number={ '12.' } settingText={ t('modules-appsettings-table-forcePwComplexity') } settingDescription={ t('modules-appsettings-table-forcePwComplexityDescr') } defaultValue={ this.state.AppConfig.forcePasswordKomplexity === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'forcePasswordKomplexity') } />
            <InputValueSettingsTableRow number={ '13.' } settingText={ t('modules-appsettings-table-passwordminlength') } settingDescription={ t('modules-appsettings-table-passwordminlengthdescr') } defaultValue={ this.state.AppConfig.passwordMinLength }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinLength') } type={ numeric } validations={ [minValue(0, { t }), maxValue(99, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinLength') }
              hintText={ t('modules-appsettings-hinttext-range3') } />
            <InputValueSettingsTableRow number={ '14.' } settingText={ t('modules-appsettings-table-passwordminspecialchars') } settingDescription={ t('modules-appsettings-table-passwordminspecialcharsdescr') } defaultValue={ this.state.AppConfig.passwordMinSpecialChar }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinSpecialChar') } type={ numeric } validations={ [minValue(0, { t }), maxValue(99, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinSpecialChar') }
              hintText={ t('modules-appsettings-hinttext-range3') } />
            <InputValueSettingsTableRow number={ '15.' } settingText={ t('modules-appsettings-table-passwordminnumbers') } settingDescription={ t('modules-appsettings-table-passwordminnumbersdescr') } defaultValue={ this.state.AppConfig.passwordMinDigit }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinDigit') } type={ numeric } validations={ [minValue(0, { t }), maxValue(99, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinDigit') }
              hintText={ t('modules-appsettings-hinttext-range3') } />
            <InputValueSettingsTableRow number={ '16.' } settingText={ t('modules-appsettings-table-passwordminlowcase') } settingDescription={ t('modules-appsettings-table-passwordminlowcasedescr') } defaultValue={ this.state.AppConfig.passwordMinLowercase }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinLowercase') } type={ numeric } validations={ [minValue(0, { t }), maxValue(99, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinLowercase') }
              hintText={ t('modules-appsettings-hinttext-range3') } />
            <InputValueSettingsTableRow number={ '17.' } settingText={ t('modules-appsettings-table-passwordminuppercase') } settingDescription={ t('modules-appsettings-table-passwordminuppercasedescr') } defaultValue={ this.state.AppConfig.passwordMinUppercase }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinUppercase') } type={ numeric } validations={ [minValue(0, { t }), maxValue(99, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinUppercase') }
              hintText={ t('modules-appsettings-hinttext-range3') } />
            <InputValueSettingsTableRow number={ '18.' } settingText={ t('modules-appsettings-table-passwordmaxduration') } settingDescription={ t('modules-appsettings-table-passwordmaxdurationdescr') } defaultValue={ this.state.AppConfig.passwordMaxDuration }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMaxDuration') } type={ numeric } validations={ [minValue(0, { t }), maxValue(365, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMaxDuration') }
              hintText={ t('modules-appsettings-hinttext-range4') } />
            <InputValueSettingsTableRow number={ '19.' } settingText={ t('modules-appsettings-table-passwordminclasses') } settingDescription={ t('modules-appsettings-table-passwordminclassesdescr') } defaultValue={ this.state.AppConfig.passwordMinClasses }
              disabled={ this.state.AppConfig.forcePasswordKomplexity === 'false' } onMenuItemSelected={ (event, value) => this.handleChangeData(value, 'passwordMinClasses') } type={ numeric } validations={ [minValue(0, { t }), maxValue(4, { t })] } onValidation={ (event, isValid) => this.handleValidation(isValid, 'passwordMinClasses') }
              hintText={ t('modules-appsettings-hinttext-range5') } />
          </TableBody>
        </Table>
        <div style={ styles.subPageHeader }>
          { t('modules-appsettings-mainView-mediapolicy') }
        </div>
        <Table selectable={ false } style={ styles.tableStyle }>
          <TableBody displayRowCheckbox={ false }>
            <ToggledSettingsTableRow number={ '20.' } settingText={ t('modules-appsettings-table-disablecamera') } settingDescription={ t('modules-appsettings-table-disablecameradescr') } defaultValue={ this.state.AppConfig.disableCamera === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableCamera') } />
            <ToggledSettingsTableRow number={ '21.' } settingText={ t('modules-appsettings-table-disablemicrophone') } settingDescription={ t('modules-appsettings-table-disablemicrophonedescr') } defaultValue={ this.state.AppConfig.disableMicrophone === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableMicrophone') } />
            <ToggledSettingsTableRow number={ '22.' } settingText={ t('modules-appsettings-table-localmedia') } settingDescription={ t('modules-appsettings-table-localmediadescr') } defaultValue={ this.state.AppConfig.disableSaveToCameraRoll === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableSaveToCameraRoll') } />
            <ToggledSettingsTableRow number={ '23.' } settingText={ t('modules-appsettings-table-disablesendmedia') } settingDescription={ t('modules-appsettings-table-disablesendmediadescr') } defaultValue={ this.state.AppConfig.disableSendMedia === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableSendMedia') } />
            <ToggledSettingsTableRow number={ '24.' } settingText={ t('modules-appsettings-table-disablelocation') } settingDescription={ t('modules-appsettings-table-disablelocationdescr') } defaultValue={ this.state.AppConfig.disableLocation === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableLocation') } />
            <ToggledSettingsTableRow number={ '25.' } settingText={ t('modules-appsettings-table-disableexportchat') } settingDescription={ t('modules-appsettings-table-disableexportchatdescr') } defaultValue={ this.state.AppConfig.disableExportChat === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableExportChat') } />
            <ToggledSettingsTableRow number={ '26.' } settingText={ t('modules-appsettings-table-sharemedia') } settingDescription={ t('modules-appsettings-table-sharemediadescr') } defaultValue={ this.state.AppConfig.disableOpenIn === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableOpenIn') } />
            <ToggledSettingsTableRow number={ '27.' } settingText={ t('modules-appsettings-table-disablecopypaste') } settingDescription={ t('modules-appsettings-table-disablecopypastedescr') } defaultValue={ this.state.AppConfig.disableCopyPaste === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableCopyPaste') } />
            <ToggledSettingsTableRow number={ '28.' } settingText={ t('modules-appsettings-table-disablepushpreview') } settingDescription={ t('modules-appsettings-table-disablepushpreviewdescr') } defaultValue={ this.state.AppConfig.disablePushPreview === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disablePushPreview') } />
            <ToggledSettingsTableRow number={ '29.' } settingText={ t('modules-appsettings-table-disablebackup') } settingDescription={ t('modules-appsettings-table-disablebackupdescr') } defaultValue={ this.state.AppConfig.disableBackup === 'true' }
              onToggle={ (event, value) => this.handleChangeData(value, 'disableBackup') } />
          </TableBody>
        </Table>
      </div>
      );
  }
}

function mapStateToProps(state) {
  const { appConfig } = state;
  return {
    AppConfig: appConfig
  }
}

const AppSettingsTranslated = translate(['common'])(AppSettings);

export default connect(mapStateToProps)(AppSettingsTranslated);
