/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function CollapseContent(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 30 30">
              <g fill="none" fillRule="evenodd" transform="translate(8 13)">
                <circle cx="2" cy="2" r="2"/>
                <circle cx="8" cy="2" r="2"/>
                <circle cx="14" cy="2" r="2"/>
                <path fill="#7B98AB" fillRule="nonzero" d="M9,2 C9,3.10555013 8.10555013,4 7,4 C5.89444987,4 5,3.10555013 5,2 C5,0.89444987 5.89444987,0 7,0 C8.10555013,0 9,0.89444987 9,2 Z M12,0 C13.1055501,0 14,0.89444987 14,2 C14,3.10555013 13.1055501,4 12,4 C10.8944499,4 10,3.10555013 10,2 C10,0.89444987 10.8944499,0 12,0 Z M2,0 C3.10555013,0 4,0.89444987 4,2 C4,3.10555013 3.10555013,4 2,4 C0.89444987,4 0,3.10555013 0,2 C0,0.89444987 0.89444987,0 2,0 Z"/>
              </g>
            </svg>
         </SvgIcon>;
}
;

export default CollapseContent;
