import React, { Component } from 'react';
import IconTabelleNotActivated from '../../ressources/tabelle_not_activated';

const styles= {
  verticalContentSpacing:{
    marginTop:'20px',
  },
  floatingIcon:{
    float:"left", margin:12,
  },
  passwordRecoveryHint:{
    color:'#FFFFFF',
    maxWidth:"320px",
    display:'inline-block',
    wordWrap:'break-word',
  },
}

// Rendert eine Seite für die Alert-States
// Die Seite zeigt ein Alert-Icon und eine entsprechende Meldung (HintText)
// an.
// Es kann zusätzlich beliebiger Content dargestellt werden
// Verwendung:
// <AlertContentPage HintText="..." >
//  <div>
//    ... Diverser Content...
//  </div>
// </AlertContentPage>
export class AlertContentPage extends Component{

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div>
        <div style={styles.verticalContentSpacing}>
          <span style={styles.floatingIcon}><IconTabelleNotActivated /></span>
          <span  style={styles.passwordRecoveryHint}>
              {this.props.HintText}
          </span>
        </div>
          {this.props.children}
      </div>
    )
  }
}
