/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconChannels(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <path fill="#FFF" d="M15.6785714,0.5 C16.6845224,0.5 17.5,1.31547764 17.5,2.32142857 L17.5,15.6785714 C17.5,16.6845224 16.6845224,17.5 15.6785714,17.5 L2.32142857,17.5 C1.31547764,17.5 0.5,16.6845224 0.5,15.6785714 L0.5,2.32142857 C0.5,1.31547764 1.31547764,0.5 2.32142857,0.5 L15.6785714,0.5 Z M4.75,15.0714286 C5.75595093,15.0714286 6.57142857,14.2559509 6.57142857,13.25 C6.57142857,12.2440491 5.75595093,11.4285714 4.75,11.4285714 C3.74404907,11.4285714 2.92857143,12.2440491 2.92857143,13.25 C2.92857143,14.2559509 3.74404907,15.0714286 4.75,15.0714286 Z M10.7278224,15.0714286 C10.9758083,15.0714286 11.1746935,14.8639461 11.161353,14.6162938 C10.9349343,10.4259556 7.5785283,7.0653251 3.38370623,6.83864702 C3.1360539,6.82530648 2.92857143,7.0241917 2.92857143,7.2721776 L2.92857143,8.5750667 C2.92857143,8.8031158 3.10448129,8.9944044 3.33204869,9.0092272 C6.36320496,9.2066672 8.7928881,11.6303101 8.9907357,14.6679143 C9.0055956,14.8955187 9.19681,15.0714286 9.4249333,15.0714286 L10.7278224,15.0714286 Z M14.6373051,15.0714286 C14.8822152,15.0714286 15.0799146,14.8688747 15.0711321,14.6241499 C14.8436388,8.2865775 9.7444022,3.15750994 3.37585013,2.92886789 C3.13112531,2.92008536 2.92857143,3.11778477 2.92857143,3.36269488 L2.92857143,4.66554696 C2.92857143,4.89930289 3.11381967,5.08959089 3.34746443,5.09918867 C8.5292642,5.31163679 12.6884373,9.4715511 12.9008484,14.6525726 C12.9104091,14.8861803 13.1006971,15.0714286 13.334453,15.0714286 L14.6373051,15.0714286 Z" transform="translate(11 11)"/>
            </svg>
         </SvgIcon>;
}
;

export default IconChannels;
