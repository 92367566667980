/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import BaseDataProvider from '../BaseDataProvider';
import DataCache from '../../cache/DataCache';

import { getNumberForUserState } from '../../components/table/UserState.js'
import { IsNullOrEmpty } from '../../components/StringExtensions';

export class DataProvider extends BaseDataProvider {

  constructor() {
    super();
    this.dataCache = new DataCache();
  }

  getUsersSorted(sortColumn, sortDirection, searchText) {
    var sortColumnName = "name";
    if (sortColumn === 2)
    {
      sortColumnName = "firstName";
    }
    if (sortColumn === 3)
    {
      sortColumnName = "eMailAdress";
    }
    if (sortColumn === 4)
    {
      sortColumnName = "phoneNumber";
    }
    if (sortColumn === 5)
    {
      sortColumnName = "state";
    }
    let data = super.sortData(this.dataCache.getUserData(), sortColumnName, sortDirection)

    if (!IsNullOrEmpty(searchText) && data != null && data.length > 0)
    {
      var searchData = [];
      let searchTextLower = searchText.toLowerCase();
      let columns = ["name", "firstName", "eMailAdress", "phoneNumber"];

      for (let user of data) {
        var pushUser = false;
        for (let column of columns) {
          var value = user[column];
          if (value !== undefined && value.toLowerCase().indexOf(searchTextLower, 0) > -1)
          {
            pushUser = true;
            break;
          }
        }

        if (!pushUser)
        {
          let keywordData = user["keyword"];
          if (keywordData !== undefined && keywordData !== null)
          {
            for (let keyword of keywordData) {
              if (keyword !== undefined && keyword != null && keyword.toLowerCase().indexOf(searchTextLower, 0) > -1)
              {
                pushUser = true;
                break;
              }
            }
          }
        }

        if (pushUser)
        {
          searchData.push(user);
        }
      }
      return searchData;
    }

    return data;
  }

  //override
  sortState(a, b) {

    let numA = getNumberForUserState(a["state"], a["hasVoucher"], a["voucherExpired"]);
    let numB = getNumberForUserState(b["state"], b["hasVoucher"], b["voucherExpired"]);

    return numA > numB ? 1 : (numA === numB ? 0 : -1);
  }

  getKeywordSorted(sortColumn, sortDirection) {
    var sortColumnName = "name";
    var keywordsDetail = [];
    if (sortColumn === 1)
    {
      sortColumnName = "name";
      keywordsDetail = this.dataCache.getKeywordsDetail().sort(function(a, b) {
        if (sortDirection === 0)
        {
          return a[sortColumnName].localeCompare(b[sortColumnName]);
        }
        else
        {
          return a[sortColumnName].localeCompare(b[sortColumnName]) * (-1);
        }

      });
    }
    if (sortColumn === 2)
    {
      keywordsDetail = this.dataCache.getKeywordsDetail().sort(function(a, b) {
        if (sortDirection === 0)
        {
          return a.usage.length - b.usage.length;
        }
        else
        {
          return b.usage.length - a.usage.length;
        }

      });
    }
    return keywordsDetail;
  }

  getKeywords() {
    return this.dataCache.getKeywords();
  }

  addKeyword(keyword) {
    this.dataCache.addKeyword(keyword);
  }


  searchUser(userGuid) {
    return this.dataCache.searchUser(userGuid);
  }

  updateData(newData, multiUpdate) {

    this.dataCache.searchAndUpdateUser(newData, multiUpdate)

    if (typeof this.callback === "function")
    {
      this.callback();
    }
  }

  searchAndUpdate(newData, multiUpdate) {
    this.dataCache.searchAndUpdateUser(newData, multiUpdate)
  }

  getDisplayName(user) {
    return user.firstName.concat(" ", user.name);

  }

  insertUser(json) {

    if (!(json.CompanyUser === undefined))
    {
      let newData = JSON.parse(JSON.stringify(json.CompanyUser));
      this.dataCache.mapDataUser(newData)
    }
    if (typeof this.callback === "function")
    {
      this.callback();
    }

  }
  deleteUser(user) {
    this.dataCache.deleteUser(user);
  }

  addCallback(callback) {
    this.callback = callback;
  }

  getDataCount() {
    return this.dataCache.getUserData().length;
  }

  getUserDataLastQueryDate() {
    return this.dataCache.dataUserLastQueryDate
  }

  updateKeyword(newData) {
    this.dataCache.resetKeyword();
    for (let k of newData) {
      if (!(k.Keyword === undefined))
      {
        this.dataCache.addKeyword(k.Keyword.name)
        let newData = JSON.parse(JSON.stringify(k.Keyword));
        this.dataCache.addKeywordsDetail(newData);

      }
    }
    //this.keywords = this.keywords.sort();
    if (typeof this.callback === "function")
    {
      this.callback();
    }
  }

  deleteKeyword(keyword) {
    this.dataCache.deleteKeyword(keyword)
  }


}

export default DataProvider;
