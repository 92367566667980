/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';

import { Table, TableBody, TableHeader, TableRow, TableRowColumn, TableFooter } from 'material-ui/Table';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import TextField from '../../components/textfields/TextField';
import ModalDialog from '../../components/ModalDialog'
import { TableRowColumnText, TableRowColumnUserState, TableHeaderColumnSort, TableHeaderColumnEmailSort, TableHeaderColumnPhoneSort, TableHeaderColumnUserState, TableHeaderColumnKeywords, TableRowColumnKeywords, sharedTableStyles } from '../../components/table/TableCells'
import TableFooterPagination from '../../components/table/TableFooterPagination';
import { getTableStyles } from '../../components/styles.js';

const tableStyles = getTableStyles();

const styles = {
  root: {
    padding: 7
  },
  tablePlaceHolder: {
    color: '#7B98AB',
    verticalAlign: 'bottom'
  },
  right: {
    float: 'right'
  },
  tableText: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  tableSelection: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  placeHolder: {
    textOverflow: 'none',
  },
  placeHolderKeyword: {
  },
  floatLabel: {
  },
  alertStyle: {
    backgroundColor: '#DC004B',
  },
  tableHeaderSort: {
    lineHeight: '36px',
    height: '36px',
    width: '100%'
  }
}

export class UserList extends Component {
  constructor(props) {
    super(props);
    this.dataprovider = props.dataprovider;
    this.isInit = false;
    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      tabledata: this.dataprovider.getUsersSorted(1, 0, props.searchText),
      placeHolder: {
        name: '',
        firstName: '',
        eMailAdress: '',
        phoneNumber: '',
        keywords: []
      },
      keyword: null,
      searchText: props.searchText,
      isUserStateExpanded: false,
      isEmailExpanded: true,
      isPhoneExpanded: true,
      isTagExpanded: true
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleSubmitPlaceHolder = this.handleSubmitPlaceHolder.bind(this);
    this.handleDataproviderCallback = this.handleDataproviderCallback.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.dataprovider.addCallback(this.handleDataproviderCallback);
    this.notifyParent = this.notifyParent.bind(this);

    this.handleExpandIconClicked = this.handleExpandIconClicked.bind(this);
  }

  handleChangeData(event, name, newValue) {
    /*let oldData = JSON.parse(JSON.stringify(this.state.placeHolder))
    oldData[name] = newValue;
    this.setState({
      placeHolder: oldData
    },this.notifyParent);*/

    /**Bug 44314 - Eingabefelder reagieren sehr langsam auf IE 11 **/
    /** IE11 Bug -> as setState has high performance cost and as we do not need
                    to update the userList on typing in - we can manually
                    notify User.js on changed Input ...
        **/
    // eslint-disable-next-line
    this.state.placeHolder[name] = newValue;
    this.notifyParent(this.state);
  }

  handleChangeKeyword(event, index, value) {
    let oldData = JSON.parse(JSON.stringify(this.state.placeHolder))
    oldData.keywords = [value];
    this.setState({
      keyword: value,
      placeHolder: oldData
    });
  }

  handleSubmitPlaceHolder() {
    if (typeof this.props.onCreateUser === 'function') {
      this.props.onCreateUser(this.state.placeHolder);
    }
  }

  clearPlaceHolder() {
    this.setState({
      placeHolder: {
        name: '',
        firstName: '',
        eMailAdress: '',
        phoneNumber: '',
        keywords: []
      },
      keyword: null
    });
  }

  handleSortTableButtonClicked(index) {
    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.dataprovider.getUsersSorted(index, newDirection, this.props.searchText),

      });
    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.dataprovider.getUsersSorted(index, newDirection, this.props.searchText),
      });
    }
  }

  handleExpandIconClicked(isExpanded, index) {
    isExpanded = !isExpanded;

    if (index === 3) {
      this.setState({
        isEmailExpanded: isExpanded,
      });
    } else if (index === 4) {
      this.setState({
        isPhoneExpanded: isExpanded,
      });
    } else if (index === 5) {
      this.setState({
        isUserStateExpanded: isExpanded,
      });
    } else if (index === 6) {
      this.setState({
        isTagExpanded: isExpanded,
      });
    }
  }

  isEmpty(value) {
    if (value === null || value.length === 0) {
      return true;
    }
    return false;
  }

  handleDataproviderCallback() {
    this.setState({
      tabledata: this.dataprovider.getUsersSorted(
        this.state.sortColumn,
        this.state.sortDirection,
        this.props.searchText
      )
    });
  }

  notifyParent(nextState) {
    if (nextState.placeHolder === undefined) {
      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(false, false);
      }
    } else {
      let visible = (!this.isEmpty(nextState.placeHolder.name) || !this.isEmpty(nextState.placeHolder.firstName)
        || !this.isEmpty(nextState.placeHolder.eMailAdress) || !this.isEmpty(nextState.placeHolder.phoneNumber)) || !this.isEmpty(nextState.keyword);

      let enabled = visible && !this.isEmpty(nextState.placeHolder.name) && !this.isEmpty(nextState.placeHolder.firstName)
        && (!this.isEmpty(nextState.placeHolder.phoneNumber) || !this.isEmpty(nextState.placeHolder.eMailAdress));

      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(visible, enabled);
      }
    }
  }

  //** not used anymore, as we do not update state by setState (performance-hack for IE11)
  componentWillUpdate(nextProps, nextState) {
    this.notifyParent(nextState);

    if (nextProps.searchText !== this.state.searchText) {
      this.setState({
        tabledata: this.dataprovider.getUsersSorted(this.state.sortColumn, this.state.sortDirection, nextProps.searchText),
        searchText: nextProps.searchText,
      });
      this.refs.pagination && this.refs.pagination.forcePaginationToStart();
    }
  /**/
  }

  renderPlaceHolderTextfield(ident, name) {
    return <TextField style={ styles.placeHolder } hintText={ name } onChange={ (event, newValue) => this.handleChangeData(event, ident, newValue) } />
  }

  getKeywords() {
    return this.dataprovider.getKeywords()
  }

  renderPlaceHolderkeyword(name) {
    return <SelectField style={ styles.placeHolderKeyword } hintText={ name } value={ this.state.keyword } onChange={ this.handleChangeKeyword } fullWidth={ true } floatingLabelText={ <div style={ styles.floatLabel }></div> }
             floatingLabelFixed={ true } floatingLabelStyle={ { height: '2px' } }>
             <MenuItem value={ null } primaryText="" />
             { this.getKeywords().map((keyword, i) => <MenuItem key={ i } value={ keyword } primaryText={ keyword } />) }
           </SelectField>;
  }

  render() {
    const {t} = this.props;

    // Breite der einzelnen Spalten ermitteln
    let windowWidth = window.innerWidth,
        columnWidth = windowWidth / 10,
        dataPaginated = this.refs.pagination && this.refs.pagination.getPaginatedData(this.state.tabledata);

    return (
      <div style={ styles.root }>
        <ModalDialog ref="modalDialog" />
        <Table selectable={ false }>
          <TableHeader displaySelectAll={ false } adjustForCheckbox={ false } style={ tableStyles.tableHeader }>
            <TableRow style={ tableStyles.tableHeaderRow }>
              <TableHeaderColumnSort index={ 1 } style={ { width: columnWidth } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column1') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
              <TableHeaderColumnSort index={ 2 } style={ { width: columnWidth } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column2') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
              <TableHeaderColumnEmailSort index={ 3 } style={ this.state.isEmailExpanded ? {
                                                                width: (1.5 * columnWidth)
                                                              } : {
                                                                width: 55
                                                              } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column3') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } contentExpand={ this.state.isEmailExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
              <TableHeaderColumnPhoneSort index={ 4 } style={ this.state.isPhoneExpanded ? {
                                                                width: columnWidth
                                                              } : {
                                                                width: 55
                                                              } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column4') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } contentExpand={ this.state.isPhoneExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
              <TableHeaderColumnUserState index={ 5 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('state') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                contentExpand={ this.state.isUserStateExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
              <TableHeaderColumnKeywords index={ 6 } text={ t('modules-user-UserList-table-column6') } style={ styles.tableHeaderSort } contentExpand={ this.state.isTagExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) }
              />
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={ false }>
            { dataPaginated && dataPaginated.map((user, i) => <TableRow key={ i } value={ user } style={ sharedTableStyles.rowStyle } displayBorder={ false } onClick={ (event) => this.props.onRowSelection(user, event) }>
                <TableRowColumnText text={ user.name } style={ Object.assign({}, { width: columnWidth}, user.alertState !== '' ? styles.alertStyle : null) } />
                <TableRowColumnText text={ user.firstName } style={ Object.assign({}, { width: columnWidth }, user.alertState !== '' ? styles.alertStyle : null) } />
                <TableRowColumnText text={ user.eMailAdress } style={ Object.assign({}, this.state.isEmailExpanded ? { width: (1.5 * columnWidth) } : { width: 55 }, user.alertState !== '' ? styles.alertStyle : null) } isExpand={ this.state.isEmailExpanded } />
                <TableRowColumnText text={ user.phoneNumber } style={ Object.assign({}, this.state.isPhoneExpanded ? { width: columnWidth } : { width: 55 }, user.alertState !== '' ? styles.alertStyle : null) } isExpand={ this.state.isPhoneExpanded } />
                <TableRowColumnUserState t={ t } user={ user } style={ user.alertState !== '' ? styles.alertStyle : null } isExpand={ this.state.isUserStateExpanded } />
                <TableRowColumnKeywords keywords={ user.keyword } style={ Object.assign({}, { width: '100%' }, user.alertState !== '' ? styles.alertStyle : null) } isExpand={ this.state.isTagExpanded } />
              </TableRow>
              ) }
              <TableRow key={ -1 } style={ styles.tablePlaceHolder } displayBorder={ false }>
                <TableRowColumn style={ styles.tableText }>{ this.renderPlaceHolderTextfield('name', t('modules-user-UserList-table-placeHolder-column1')) }</TableRowColumn>
                <TableRowColumn style={ styles.tableText }>{ this.renderPlaceHolderTextfield('firstName', t('modules-user-UserList-table-placeHolder-column2')) }</TableRowColumn>
                <TableRowColumn style={ styles.tableText }>{ this.renderPlaceHolderTextfield('eMailAdress', t('modules-user-UserList-table-placeHolder-column3')) }</TableRowColumn>
                <TableRowColumn style={ styles.tableText }>{ this.renderPlaceHolderTextfield('phoneNumber', t('modules-user-UserList-table-placeHolder-column4')) }</TableRowColumn>
                <TableRowColumn colSpan='2' style={ styles.tableSelection }>{ this.renderPlaceHolderkeyword(t('modules-user-UserList-table-placeHolder-column6')) }</TableRowColumn>
            </TableRow>
            <TableRow key={ -2 } displayBorder={ true }>
            </TableRow>
          </TableBody>
          <TableFooter style={ tableStyles.tableFooter }>
            <TableFooterPagination ref="pagination" data={ this.state.tabledata } parent={ this } />
          </TableFooter>
        </Table>
      </div>
      );
  }
}


export default translate(['common'], {
  withRef: true
})(UserList);
