import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

// import CircularProgress from 'material-ui/CircularProgress';

// import FlatButton from "../../../components/buttons/FlatButton";

import MessageComposer from '../../../components/MessageComposer';
import ChatMessagePreview from '../../../components/textfields/MessageTextField';
import SubPageHeader from '../../../components/pageheader';
import { WaitDialog } from '../../../components/WaitDialog';
import { ModalDialog } from '../../../components/ModalDialog';

// import { DataProviderMessageList } from '../groups/DataProvider';

import { sendTextMessage, sendImageMessage, sendFileMessage } from '../../../actions/index';


const styles = {
  left: {
    width: "50%",
    float: "left",
  },
  right: {
    marginLeft: "50%",
  },
  clear: {
    clear: 'both',
  },
  topSpacing: {
    paddingTop: "100px",
  },
  leftSpacing: {
    marginLeft: '20px',
  },
  innerCategorySpacing: {
    marginTop: '10px',
  },
  gradientBackground: {
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
  },
  subPageHeaderStyle: {
    color: '#EEC643',
    fontSize: '20px',
    fontWeight: 'medium',
  },
}

const someMoreMessages = 10;

const MessageTypeEnum = {
  TextMessage: 0,
  PictureMessage: 1,
  FileMessage: 2,
  LocationMessage: 3,
}

class MessageEditor extends Component {
  state = {
    messageType: MessageTypeEnum.TextMessage,
    message: {
      sendTime: '',
      sendDate: '',
      messageText: '',
      picture: '',
      attachment: '',
      location: '',
    },
    channelMessages: {
    },
    timedChannelMessages: {
    },
    messagesVisible: someMoreMessages,
    isMessagesFetching: false,
    isTimedMessagesFetching: false,
    latestFeedMessagePreview: {
      title: '',
      text: '',
      link: '',
      guid: '',
    },
  }
  constructor(props) {
    super(props);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.onMessageReset = this.onMessageReset.bind(this);
    this.sendMessageHandler = this.sendMessageHandler.bind(this);

    this.sendMessageSuccess = this.sendMessageSuccess.bind(this);
    // this.roomMessageGuidsSuccess = this.roomMessageGuidsSuccess.bind(this);
    // this.roomTimedMessageGuidsSuccess = this.roomTimedMessageGuidsSuccess.bind(this);
    // this.messageBatchSuccess = this.messageBatchSuccess.bind(this);
    // this.timedMessageBatchSuccess = this.timedMessageBatchSuccess.bind(this);
    // this.loadLatestFeedMessageSuccess = this.loadLatestFeedMessageSuccess.bind(this);

    // this.showOlderMessagesHandler = this.showOlderMessagesHandler.bind(this);
    // this.removeMessagesHandler = this.removeMessagesHandler.bind(this);
    // this.removeRoomMessagesSuccessHandler = this.removeRoomMessagesSuccessHandler.bind(this);
    // this.handleRemoveTimedMessageSuccess = this.handleRemoveTimedMessageSuccess.bind(this);

    this.errorHandler = this.errorHandler.bind(this);

    // this.timedMessagesChangeHandler = this.timedMessagesChangeHandler.bind(this);
    // this.messagesChangedHandler = this.messagesChangedHandler.bind(this);

    // this.messageDataProvider = new DataProviderMessageList();
    // this.messageDataProvider.addCallback(this.messagesChangedHandler);
    // this.timedMessageDataProvider = new DataProviderMessageList();
    // this.timedMessageDataProvider.addCallback(this.timedMessagesChangeHandler);

    // this.handleMessageDeleteConfirmation = this.handleMessageDeleteConfirmation.bind(this);
  }

  setInitialMessageText() {
    // const {t} = this.props;
    /*let oldMessageState=this.state.message;
    oldMessageState.messageText=t("components-chatmessagetextfield-default-hint");
    this.setState({message:oldMessageState});*/
  }

  // no longer show old room messages
  /* roomMessageGuidsSuccess(json) {
    this.messageDataProvider.updateMessageGuids(json.data);
    let messageGuidsToUpdate = this.messageDataProvider.updateableMessages();
    if (messageGuidsToUpdate.length > 0) {
      this.props.dispatch(getRoomMessageBatch(this.props.Channel.guid, messageGuidsToUpdate, this.messageBatchSuccess, this.errorHandler));
    } else {
      this.setState({
        isMessagesFetching: false,
      });
    }
  }
  */

  /*
  messageBatchSuccess(json) {
    this.messageDataProvider.updateMessageBatch(json.data);
    this.setState({
      channelMessages: this.messageDataProvider.getMessages(),
      isMessagesFetching: false,
    });
  }
  */

  /*
  //TODO: Refactor: ist dasselbe Prozedere, wie messageDataProvider - doppelter Code!
  timedMessageBatchSuccess(json) {
    this.timedMessageDataProvider.updateMessageBatch(json.data);
    this.setState({
      timedChannelMessages: this.timedMessageDataProvider.getMessages(),
      isTimedMessagesFetching: false,
    });
  }
  */

  /* no longer show old messages
  roomTimedMessageGuidsSuccess(json) {
    this.timedMessageDataProvider.updateMessageGuids(json.data);
    let messageGuidsToUpdate = this.timedMessageDataProvider.updateableMessages();
    if (messageGuidsToUpdate.length > 0) {
      this.props.dispatch(getRoomMessageBatch(this.props.Channel.guid, messageGuidsToUpdate, this.timedMessageBatchSuccess, this.errorHandler));
    } else {
      this.setState({
        isTimedMessagesFetching: false
      });
    }
  }

  /* no longer show messages in channels
  updateMessages(limit) {
    this.setState({
      isMessagesFetching: true,
      isTimedMessagesFetching: true,
    })
    this.props.dispatch(getRoomMessageGuids(this.props.Channel.guid, limit, this.roomMessageGuidsSuccess, this.errorHandler));
    //Auskommentiert, bis dieser Part der Schnittstelle vorhanden ist.
    this.props.dispatch(getRoomTimedMessageGuids(this.props.Channel.guid, 10, this.roomTimedMessageGuidsSuccess, this.errorHandler));
  }
  */

  /*
  loadLatestFeedMessage() {
    if ((typeof this.props.Channel.feed !== 'undefined') && this.props.Channel.feed.length > 0)
    {
        this.props.dispatch(loadLatestFeedMessage(this.props.Channel.feed, this.loadLatestFeedMessageSuccess, this.errorHandler));
    }
  }
  */

  /*
  loadLatestFeedMessageSuccess(json) {
    if(typeof json.latestMessage.RssMessage !== 'undefined') {
      this.setState({
        latestFeedMessagePreview: json.latestMessage.RssMessage,
      });
    }
  }
  */


  /* 
  showOlderMessagesHandler() {
    let current = this.state.messagesVisible + someMoreMessages;
    this.setState({
      messagesVisible: current,
    });
    // no longer show old messages in channels
    // this.updateMessages(current);
  }
  */

  /*
  removeRoomMessagesSuccessHandler(json) {
    this.refs.waitDialog.close();
    this.setState({
      channelMessages: {},
      timedChannelMessages: {}
    });
    this.messageDataProvider.reset();
    this.timedMessageDataProvider.reset();
    // no longer show old messages in channels
    // this.updateMessages(this.state.messagesVisible);
  }
  */

  /*
  handleMessageDeleteConfirmation() {
    this.refs.waitDialog.open();
    this.props.dispatch(removeRoomMessages(this.props.Channel.guid, this.removeRoomMessagesSuccessHandler, this.errorHandler))
  }
  */

  /*
  removeMessagesHandler() {
    const {t} = this.props;
    let yesNoDialog = {
      text: t("components-messageeditor-confirmdeletemessage"),
      button1Text: t("components-ModalDialog-no"),
      button1Handler: null,
      button2Text: t("components-ModalDialog-yes"),
      button2Handler: this.handleMessageDeleteConfirmation,
      title: t("components-ModalDialog-Warning"),
    };
    this.refs.modalDialog.open(yesNoDialog);
  }
  */

  /*
  handleRemoveTimedMessageSuccess(json) {
    // no longer show old messages in channels
    // this.updateMessages(this.state.messagesVisible);
    this.refs.waitDialog.close();
  }
  */

  getIndexOfTimedMessage(messageGuid) {
    for (var i = 0; i < this.state.timedChannelMessages.length; i++) {
      if (this.state.timedChannelMessages[i].guid === messageGuid) {
        return i;
      }
    }
    return -1;
  }

  /*
  timedMessagesChangeHandler(timedMessages) {
    this.setState({
      timedChannelMessages: timedMessages
    });
  }
  */

  /*
  messagesChangedHandler(messages) {
    this.setState({
      channelMessages: messages
    });
  }
  */

  /*
  handleRemoveTimedMessage(messageGuid) {
    this.refs.waitDialog.open();
    this.timedMessageDataProvider.removeMessage(messageGuid);
    this.props.dispatch(removeTimedRoomMessage(this.props.Channel.guid, messageGuid, this.handleRemoveTimedMessageSuccess, this.errorHandler));
  }
  */

  componentDidMount() {
    this.setInitialMessageText();
    // no longer show old messages in channels
    // this.updateMessages(this.state.messagesVisible);
    // this.loadLatestFeedMessage();
  }

  onMessageReset(resetMessage) {
    // const {t} = this.props;
    //Problem: resetMessage wird nicht korrekt weitergegeben,
    //daher wird hier einfach neu initialisiert...
    let resettedMessage = {
      sendTime: '',
      sendDate: '',
      messageText: '',
      picture: '',
      attachment: '',
      attachmentInfo: '',
      location: '',
    };
    this.setState({
      message: resettedMessage,
      messageType: MessageTypeEnum.TextMessage
    });
  }


  handleChangeData(value, name) {
    // const {t} = this.props;
    let oldData = this.state.message;
    let newMessageType = this.state.messageType;
    //der server erwartet die Werte als String
    if (name === 'attachment') {
      if (value.substring(0, 10) === "data:image") {
        oldData['attachment'] = '';
        oldData['picture'] = value;
        newMessageType = MessageTypeEnum.PictureMessage;
      //this.setMessageTypeToPicture();
      } else {
        oldData['picture'] = '';
        oldData['attachment'] = value;
        oldData['messageText'] = '';
        newMessageType = MessageTypeEnum.FileMessage;
      }
    } else {
      if (name === 'attachmentInfo') {
        oldData[name] = value ;
      } else {
        oldData[name] = value + "";
      }
    }
    this.setState({
      message: oldData,
      messageType: newMessageType
    });
  }

  renderCategoryHeader(header, component) {
    return (
      <div style={ styles.gradientBackground }>
        <SubPageHeader style={ Object.assign({}, styles.subPageHeaderStyle, {
                                 padding: "0 0 0 20px"
                               }) }>
          { header }
          { component }
        </SubPageHeader>
      </div>
    )
  }

  errorHandler(errorJO) {
    this.refs.waitDialog.close();
    this.setState({
      isMessagesFetching: false,
      isTimedMessagesFetching: false,
    })
    const {t} = this.props;

    let errorCode = "SERVICE-" + errorJO.ident;
    let errorText = t(errorCode);
    this.refs.modalDialog.open({
      text: errorText,
      button1Text: t("components-ModalDialog-ok")
    });
  }

  sendMessageSuccess(json) {
    // no longer show old messages in channels
    // this.updateMessages(this.state.messagesVisible);
    this.refs.waitDialog.close();
  }
  sendMessageHandler() {
    var dateString = '';
    var datum = new Date().parseUtcDate(this.state.message.sendDate)
    var zeit = new Date().parseUtcDate(this.state.message.sendTime);

    if (!(this.state.message.sendDate === null || this.state.message.sendDate === '')) {
      var mergedDate = new Date(datum.getFullYear(), (datum.getMonth()), datum.getDate(), zeit.getHours(), zeit.getMinutes(), 0, 0);
      dateString = mergedDate.getUTCDateMEZString();
    }

    this.refs.waitDialog.open();
    switch (this.state.messageType) {
      case MessageTypeEnum.TextMessage: {
        this.props.dispatch(sendTextMessage(
          this.props.Channel.guid,
          this.state.message.messageText,
          dateString,
          this.sendMessageSuccess,
          this.errorHandler
        ));
        break;
      }
      case MessageTypeEnum.PictureMessage: {
        this.props.dispatch(sendImageMessage(
          this.props.Channel.guid,
          this.state.message.picture.split(',')[1],
          this.state.message.messageText,
          dateString,
          this.sendMessageSuccess,
          this.errorHandler
        ));
        break;
      }
      case MessageTypeEnum.FileMessage: {
        this.props.dispatch(sendFileMessage(
          this.props.Channel.guid,
          this.state.message.attachment.split(',')[1],
          this.state.message.attachmentInfo.name,
          dateString,
          this.sendMessageSuccess,
          this.errorHandler
        ));
        break;
      }
        case MessageTypeEnum.LocationMessage: {
            this.props.dispatch(sendTextMessage(
              this.props.Channel.guid,
              this.state.message.location,
              dateString,
              this.sendMessageSuccess,
              this.errorHandler
            ));
            break;
      }
      default: {
        this.refs.waitDialog.close();
      }
    }
  }

  renderMessage(message, hasDeleteButton) {
    //da es löschen einzelner Nachrichten nur bei timedMessages gibt,
    //können wir hier einfach den handleRemoveTimedMessage-Handler verwenden
    var sendDate = message.datesend;
    if (typeof message.datetosend !== 'undefined') {
      sendDate = message.datetosend;
    }

    switch (message.MessageBody.type) {
      //eigentlich sollte das die ChatMessagePreview entscheiden!
      //TODO: refactor -> dieser Part hier zur ChatMessagePreview!
      //      Achtung: beim refactor muss auch der MessageComposer so angepasst werden
      //                dass er korrekte Messages liefert!

      case "application/octetstream": {
        let fileName = message.MessageBody.filename;
        let fileSize = 0;
        if (typeof message.MessageBody.filesize !== 'undefined') {
          fileSize = parseInt(message.MessageBody.filesize, 10)
        }
        let attachmentInfo = {
          name: fileName,
          size: fileSize,
        }
        let attachment = message.MessageBody['content-data'];
        return (
          <ChatMessagePreview attachmentInfo={ attachmentInfo } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton } Attachment={ attachment }
            ShowMessagePreview={ false } />
        )
      }
      case "text/plain": {
        return (
          <ChatMessagePreview messageText={ message.MessageBody.content } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton } ShowMessagePreview={ false } />
        )
      }
      case "text/rss": {
        // Aufbereiten des Message Textes
        var messageDataJso;

        try {
          messageDataJso = JSON.parse(message.MessageBody.content)
        } catch (e) {
          messageDataJso = {
            title: '',
            text: '',
            link: '',
            guid: '',
          };
          break;
        } finally {}

        return (
          <ChatMessagePreview messageText='' rssMessage={messageDataJso} sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton } ShowMessagePreview={ false } />
        )
      }
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
      case "image/bmp": {
        let image = "data:" + message.MessageBody.type + ";base64," + message.MessageBody["content-preview"];
        return (
          <ChatMessagePreview messageText={ message.MessageBody['content-desc'] } picture={ image } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton }
            ShowMessagePreview={ false } />
        )
      }
      case "video/mpeg": {
        return (<ChatMessagePreview messageText={ message.MessageBody['content-desc'] } video={ true } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton }
                  ShowMessagePreview={ false } />
        )
      }
      case "model/location": {
        // let preview = "data:image/jpg;base64," + message.MessageBody["content-preview"];
        //Wir liefern erstmal nur das Vorschaubild... und Nutzen die Bildanzeigen-Funktion
        //  falls man mit den Locations was machen soll,
        //dann müsste man ein Locationobjekt aus Vorschau + Koordinaten bauen
        return (
          <ChatMessagePreview location={ true } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton } ShowMessagePreview={ false } />
        )
      }
      case "audio/mpeg": {
        return (
          <ChatMessagePreview audio={ true } sendTime={ sendDate } sendDate={ sendDate } HasDeleteButton={ hasDeleteButton } ShowMessagePreview={ false } />
        )
      }
      default: {
        return("")
      }
    }
  }

  /*
  renderMessageList(channelMessages, hasDeleteButton) {
    var renderedChannelMessages = [];
    for (var i = 0; i < channelMessages.length; i++) {
      let currentMessage = channelMessages[i].Message;
      let messagePreview = this.renderMessage(currentMessage, hasDeleteButton);
      renderedChannelMessages.push(
        <div key={ i } style={ { marginTop: 7 } }>
          { messagePreview }
        </div>);
    }
    return renderedChannelMessages;
  }
  */

  render() {
    const {t} = this.props;
    // let channelMessages = this.renderMessageList(this.state.channelMessages, false);
    // let timedChannelMessages = this.renderMessageList(this.state.timedChannelMessages, true);
    // let messageFetchingProgress = (this.state.isMessagesFetching) ? <CircularProgress size={ 30 } /> : null;
    // let timedMessageFetchingProgress = (this.state.isTimedMessagesFetching) ? <CircularProgress size={ 30 } /> : null;
    // let isMoreMessagesFetching = (this.state.isMessagesFetching && this.state.isTimedMessagesFetching);

    let messageText = this.state.message.messageText;
    let rssMessage = null;

    if ((typeof this.props.Channel.feed !== 'undefined') && this.props.Channel.feed.length > 0) {
        if (this.state.latestFeedMessagePreview.title.length > 0) {
            rssMessage = this.state.latestFeedMessagePreview;
        } else {
          messageText = t('modules-groups-NewGroup-placeholder-RSS-preview');
        }
    }

    return (
      <div style={ { width: "100%" } }>
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        { this.renderCategoryHeader(t('components-messageeditor-header-newmessage')) }
        <div style={ Object.assign(styles.leftSpacing, styles.innerCategorySpacing) }>
          <div style={ styles.left }>
            <MessageComposer onMessageTextChange={ (messageText) => this.handleChangeData(messageText, 'messageText') } onSendDateChange={ (newDate) => this.handleChangeData(newDate, 'sendDate') } onSendTimeChange={ (newDate) => this.handleChangeData(newDate, 'sendTime') } onAttachmentChange={ (pictureData, file) => {
                                                                                                                                                                                                                                                                                                         this.handleChangeData(pictureData, 'attachment');
                                                                                                                                                                                                                                                                                                         this.handleChangeData(file, 'attachmentInfo');
                                                                                                                                                                                                                                                                                                       } }
              messageTextFieldDisabled={ (this.state.messageType === MessageTypeEnum.FileMessage) } sendMessageDisabled={ ((typeof this.props.Channel.feed !== 'undefined') && this.props.Channel.feed.length > 0) } onReset={ this.onMessageReset } onMessageSend={ this.sendMessageHandler } />
          </div>
          <div style={ styles.right }>
            <div style={ this.state.message.picture !== '' ? {
                           paddingTop: 30
                         } : {
                           paddingTop: 100
                         } }>
              <ChatMessagePreview picture={ this.state.message.picture } attachmentInfo={ this.state.message.attachmentInfo } messageText={ messageText } rssMessage={ rssMessage } sendTime={ this.state.message.sendTime } sendDate={ this.state.message.sendDate }
                ShowMessagePreview={ true } attachment='' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  // const {session} = state;
  return {

  }
}

/*
function getBase64ByteSize(base64String) {
  let n = typeof base64String === 'string' ? base64String.length : 0;
  var result = 4 * Math.ceil(n / 3);
  return result;
}
*/

const MessageEditorTranslated = translate(['common'])(MessageEditor);
export default connect(mapStateToProps)(MessageEditorTranslated);
