/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import { translate } from 'react-i18next';
// sru_230904: unused
// import Link from 'react-router';
// import { Redirect } from 'react-router';

import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';

// sru_230904: unused
// import { PageHeader } from '../../components/pageheader';
import { BackSubPageHeader } from '../../components/pageheader';
import { WaitDialog } from '../../components/WaitDialog';
import { ModalDialog } from '../../components/ModalDialog';

import AdminList from './AdminList';
import AdminDetail from './AdminDetail';

import IconDelete from '../../ressources/icon_delete_white';
// sru_230904: unused
// import IconWarning from '../../ressources/tabelle_alert_state';
import IconSearch from '../../ressources/icon_search';

import { connect } from 'react-redux';
import { getAdminList, createAdmin, deleteAdmin, setAdminEnabled } from '../../actions/index'
import DataProvider from './DataProvider';
import BaseModul from '../BaseModul';
import TextField from '../../components/textfields/TextField';

// sru_230904: unused
// import { IsNullOrEmpty } from '../../components/StringExtensions';

import ErrorDialog from '../../components/ErrorDialog.js';

const LIST_ADMIN = 0;
const NEW_ADMIN = 1;
const SHOW_ADMIN = 2;

const styles = {
  searchText: {
    marginLeft: '25px',
    color: '#7B98AB',
    float: 'left',
    height: '60px',
    display: 'inline-flex',
  },
}

class Admin extends BaseModul {
  constructor(props) {
    super(props);
    this.state = {
      adminList: {},
      editModeButton: {
        visible: false,
        enabled: false
      },
      currentViewMode: LIST_ADMIN,
      showAdmin: null,
      isPendingChanges: false,
      searchText: ""
    };

    this.dataprovider = new DataProvider();
    this.handleNewAdminClicked = this.handleNewAdminClicked.bind(this);
    this.handleCancelNewAdminClicked = this.handleCancelNewAdminClicked.bind(this);
    this.handleSaveNewAdminClicked = this.handleSaveNewAdminClicked.bind(this);

    this.createAdminHandler = this.createAdminHandler.bind(this);
    this.createAdminSuccessHandler = this.createAdminSuccessHandler.bind(this);
    this.createAdminErrorHandler = this.createAdminErrorHandler.bind(this);
    this.listEditModeListener = this.listEditModeListener.bind(this)

    this.getAdminListSuccessHandler = this.getAdminListSuccessHandler.bind(this);

    this.onRowSelection = this.onRowSelection.bind(this);

    this.beginDeleteAdminClicked = this.beginDeleteAdminClicked.bind(this);
    this.callDeleteAdmin = this.callDeleteAdmin.bind(this);
    this.deleteAdminSuccessHandler = this.deleteAdminSuccessHandler.bind(this);

    this.beginSwitchAdminClicked = this.beginSwitchAdminClicked.bind(this);
    this.callSwitchAdmin = this.callSwitchAdmin.bind(this);

    this.handleOnPendingChanges = this.handleOnPendingChanges.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.cancelNewAdminConfirm = this.cancelNewAdminConfirm.bind(this);

    this.beginSetAdminDisabledClicked = this.beginSetAdminDisabledClicked.bind(this);
    this.beginSetAdminEnabledClicked = this.beginSetAdminEnabledClicked.bind(this);
    this.callSetAdminEnabled = this.callSetAdminEnabled.bind(this);
    this.setAdminEnabledSuccessHandler = this.setAdminEnabledSuccessHandler.bind(this);

    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.getAdminListNullHandler = this.getAdminListNullHandler.bind(this);

  }

  handleOnPendingChanges(pending) {
    this.setState({
      isPendingChanges: pending
    });
    if (pending)
    {
      this.setDirty();
    }
    else
    {
      this.clearDirty();
    }
  }

  handleNewAdminClicked() {
    this.setState(
      {
        currentViewMode: NEW_ADMIN
      }
    );
  }

  handleSaveNewAdminClicked() {
    this.refs.newadmin.getWrappedInstance().handleNewAdminSaveClicked();
  }

  cancelNewAdminConfirm() {
    this.loadAdminList(false);
    this.setState({
      isPendingChanges: false
    });
    this.setState(
      {
        currentViewMode: LIST_ADMIN
      }
    );
    this.clearDirty();
  }

  handleCancelNewAdminClicked() {
    const {t} = this.props;
    //TODO: das ganze isPending-Zeug sollte in BaseModul allgemein bereitgestellt werden ->Vgl. auch AbstractGroups
    if (this.state.isPendingChanges)
    {
      let yesNoDialog = {
        text: t("components-ModalDialog-looseDataQuestion"),
        button1Text: t("components-ModalDialog-no"),
        button1Handler: null,
        button2Text: t("components-ModalDialog-yes"),
        button2Handler: this.cancelNewAdminConfirm,
        title: t("components-ModalDialog-Warning"),
      };
      this.refs.modalDialog.open(yesNoDialog);
    }
    else
    {
      this.cancelNewAdminConfirm();
    }
  }

  createAdminHandler(adminData) {
    this.refs.waitDialog.open();

    this.props.dispatch(createAdmin(adminData.name, adminData.firstName, adminData.mail, adminData.phoneNumber, 'userAdmin', this.createAdminSuccessHandler, this.createAdminErrorHandler))
  }

  createAdminSuccessHandler(json) {
    const {t} = this.props;
    if (!(typeof json.Admin === "undefined"))
    {
      this.dataprovider.insertAdmin(json)
    }
    if (this.state.currentViewMode === NEW_ADMIN)
    {
      this.setState(
        {
          currentViewMode: LIST_ADMIN,
          isPendingChanges: false
        }
      );
      this.clearDirty();
    }

    this.refs.waitDialog.close();
    this.loadAdminList(false);

    if (!(typeof json.Admin === "undefined"))
    {
      let displayName = this.dataprovider.getDisplayName(json.Admin);
      let title1 = t("modules-settings-Admin-setAdminEnabled-title", {
        displayName: displayName
      })

      this.refs.modalDialog.open({
        title: title1,
        text: t("modules-settings-Admin-setAdminEnabled"),
        button1Text: t("modules-settings-Admin-setAdminEnabledBtnCancel"),

        button2Text: t("modules-settings-Admin-setAdminEnabledBtnOk"),
        button2Handler: () => this.callSetAdminEnabled(json.Admin.guid, 'true')
      });
    }
  }

  createAdminErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handleReloadData);

  }

  handleChangeData(event, name) {
    if (name === "search")
    {
      this.setState({
        searchText: event.target.value
      });
    }
    else
    {
      let oldData = JSON.parse(JSON.stringify(this.state.placeHolder))
      oldData[name] = event.target.value;
      this.setState({
        placeHolder: oldData
      });
    }
  }

  beginSwitchAdminClicked(admin) {
    let displayName = this.dataprovider.getDisplayName(admin)
    const {t} = this.props;
    let text1 = t('modules-user-Keyword-jumpToUser', {
      displayName: displayName
    })
    this.refs.modalDialog.open({
      text: text1,
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: () => this.callSwitchAdmin(admin)
    });
  }

  callSwitchAdmin(admin) {
    let newShowAdmin = this.dataprovider.searchAdmin(admin.guid)
    // let oldState = this.state.currentViewMode;
    this.setState(
      {
        currentViewMode: SHOW_ADMIN,
        showAdmin: newShowAdmin
      });
    if (typeof this.refs.showadmin !== "undefined" &&
      typeof this.refs.showadmin.getWrappedInstance() !== "undefined")
    {
      this.refs.showadmin.getWrappedInstance().loadAdmin(newShowAdmin)
    }
    if (typeof this.refs.newadmin !== "undefined" &&
      typeof this.refs.newadmin.getWrappedInstance() !== "undefined")
    {
      this.refs.newadmin.getWrappedInstance().loadAdmin(newShowAdmin)
    }

  }

  componentDidMount() {
    super.componentDidMount();
    let showWaitDialog = true;
    if (!(typeof this.dataprovider === "undefined"))
    {
      showWaitDialog = this.dataprovider.getDataCount() === 0;
    }
    this.loadAdminList(showWaitDialog);
  }

  handleReloadData() {
    this.loadAdminList();
  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    this.props.dispatch(getAdminList(this.getAdminListNullHandler, this.companyDataErrorHandler));

  }

  getAdminListNullHandler() {
    this.refs.waitDialog.open();
  }


  loadAdminList(showWaitDialog) {
    if (showWaitDialog)
    {
      this.refs.waitDialog.open();
    }
    this.props.dispatch(getAdminList(this.getAdminListSuccessHandler));
  }

  getAdminListSuccessHandler(json) {
    this.dataprovider.updateData(json.admins);
    this.refs.waitDialog.close();
  }

  listEditModeListener(visible, enabled) {
    if (visible !== this.state.editModeButton.visible
      || enabled !== this.state.editModeButton.enabled)
    {
      this.setState({
        editModeButton: {
          visible: visible,
          enabled: enabled
        }
      })
    }
  }

  onRowSelection(admin) {
    this.setState(
      {
        currentViewMode: SHOW_ADMIN,
        showAdmin: admin
      }
    );
  }

  beginDeleteAdminClicked() {
    this.refs.waitDialog.close();
    const {t} = this.props;

    this.refs.modalDialog.open({
      text: t("modules-settings-Admin-confirmDeleteAdmin"),
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: this.callDeleteAdmin
    });
  }

  callDeleteAdmin() {
    this.refs.waitDialog.open();
    this.props.dispatch(deleteAdmin(this.state.showAdmin.guid, this.deleteAdminSuccessHandler, this.createAdminErrorHandler))
  }

  deleteAdminSuccessHandler() {
    this.dataprovider.deleteAdmin(this.state.showAdmin)
    this.refs.waitDialog.close();
    this.setState(
      {
        currentViewMode: LIST_ADMIN,
        showAdmin: null
      }
    );
    this.loadAdminList(false);
  }

  beginSetAdminDisabledClicked() {
    this.refs.waitDialog.close();
    this.callSetAdminEnabled(this.state.showAdmin.guid, 'false')
  }

  beginSetAdminEnabledClicked() {
    this.refs.waitDialog.close();
    this.callSetAdminEnabled(this.state.showAdmin.guid, 'true')
  }

  callSetAdminEnabled(adminGuid, enabled) {
    this.refs.waitDialog.open();
    this.props.dispatch(setAdminEnabled(adminGuid, enabled, this.setAdminEnabledSuccessHandler, this.createAdminErrorHandler))
  }

  setAdminEnabledSuccessHandler(json) {
    if (!(typeof json.Admin === "undefined"))
    {
      if (this.state.showAdmin != null)
      {
        this.setState(
          {
            showAdmin: json.Admin
          }
        );
      }

      this.refs.waitDialog.close();

      this.loadAdminList(false);
    }
  }

  renderCommonButton() {
    const {t} = this.props;

    if (this.state.currentViewMode === LIST_ADMIN)
    {
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <RaisedButton label={ t('modules-settings-Admin-btnCreateNewAdmin') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleNewAdminClicked } />
               </div>
             </div>
    }
    if (this.state.currentViewMode === NEW_ADMIN)
    {
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <FlatButton label={ t('modules-settings-Admin-btnCancelNewAdmin') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleCancelNewAdminClicked } />
                 <RaisedButton disabled={ !this.state.isPendingChanges || !this.state.editModeButton.enabled } label={ t('modules-settings-Admin-btnSaveNewAdmin') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleSaveNewAdminClicked }
                 />
               </div>
             </div>
    }
    if (this.state.currentViewMode === SHOW_ADMIN)
    {
      return <div style={ null }>
             </div>
    }

  }

  renderHeaderView() {
    const {t} = this.props;

    if (this.state.currentViewMode === LIST_ADMIN)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
                 <a href='#/settings'>
                   <BackSubPageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } } label={ t('modules-settings-Admin-admin') } />
                 </a>
               </div>
               <div style={ styles.searchText }>
                 <TextField style={ { margin: 'auto 0px' } } hintText={ t('modules-settings-Admin-search') } onChange={ (event) => this.handleChangeData(event, 'search') } />
                 <IconSearch style={ { margin: 'auto 0px', height: '40px', width: '40px' } } />
               </div>
             </div>

    }
    if (this.state.currentViewMode === NEW_ADMIN)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
                 <BackSubPageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } } label={ t('modules-settings-NewAdmin-newadmin') } onBackClicked={ this.handleCancelNewAdminClicked } />
               </div>
               <div style={ this.getBaseStyle().right }>
               </div>
             </div>

    }
    if (this.state.currentViewMode === SHOW_ADMIN)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
                 <BackSubPageHeader label={ this.state.showAdmin.firstName + " " + this.state.showAdmin.name } onBackClicked={ this.handleCancelNewAdminClicked } />
               </div>
               <div style={ this.getBaseStyle().right }>
                 { this.renderAdminEditButtons() }
               </div>
             </div>

    }
  }

  renderAdminEditButtons() {
    const {t} = this.props;

    if (this.state.showAdmin && this.state.showAdmin.role && this.state.showAdmin.role.includes('companyAdmin') === false)
    {
      if (this.state.showAdmin.state && this.state.showAdmin.state === 'active')
      {
        return <div>
                 <FlatButton style={ { margin: 'auto', color: '#FFFFFF' } } label={ t('modules-settings-Admin-btnDeleteAdmin') } secondary={ true } icon={ <IconDelete /> } labelPosition="before" onClick={ this.beginDeleteAdminClicked }
                 />
                 <RaisedButton label={ t('modules-settings-Admin-btnDeactivateAdmin') } primary={ true } style={ this.getBaseStyle().fab } buttonStyle={ { backgroundColor: '#EEC643' } } labelStyle={ { color: '#00375D' } }
                   onClick={ this.beginSetAdminDisabledClicked } />
               </div>

      }
      else if (this.state.showAdmin.state && (this.state.showAdmin.state === 'deactive' || this.state.showAdmin.state === 'created'))
      {
        return <div>
                 <FlatButton style={ { margin: 'auto', color: '#FFFFFF' } } label={ t('modules-settings-Admin-btnDeleteAdmin') } secondary={ true } icon={ <IconDelete /> } labelPosition="before" onClick={ this.beginDeleteAdminClicked }
                 />
                 <RaisedButton label={ t('modules-settings-Admin-btnActivateAdmin') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.beginSetAdminEnabledClicked } />
               </div>
      }
    }
  }

  renderMainView() {

    if (this.state.currentViewMode === LIST_ADMIN) {
      return <div>
               <AdminList ref="adminlist" dataprovider={ this.dataprovider } onRowSelection={ this.onRowSelection } searchText={ this.state.searchText } />
             </div>;

    }
    if (this.state.currentViewMode === NEW_ADMIN) {
      return <div>
               <AdminDetail ref="newadmin" onPendingChanges={ this.handleOnPendingChanges } viewMode={ this.state.currentViewMode } onCreateAdmin={ this.createAdminHandler } listEditModeListener={ this.listEditModeListener }
                 viewPortHeight={ this.state.height } onSwitchAdminClicked={ this.beginSwitchAdminClicked } />
             </div>;

    }
    if (this.state.currentViewMode === SHOW_ADMIN) {
      return <div>
               <AdminDetail ref="showadmin" viewMode={ this.state.currentViewMode } admin={ this.state.showAdmin } onSwitchAdminClicked={ this.beginSwitchAdminClicked } viewPortHeight={ this.state.height }
               />
             </div>;
    }
  }

  renderHiddenDialog() {
    const {t} = this.props;

    return (<div>
              <WaitDialog ref="waitDialog" />
              <ModalDialog ref="modalDialog" />
              <ErrorDialog ref="errorDialog" t={ t } />
              <ModalDialog ref="commonErrorDialog" />
            </div>);
  }
}

function mapStateToProps(state) {

  const { adminList } = state;

  return {
    adminList: adminList
  }
}

const AdminTranslated = translate(['common'])(Admin);

export default connect(mapStateToProps)(AdminTranslated);
