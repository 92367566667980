import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function DeviceComputer(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 24 24">
              <path fill="#FFF" d="M19.9687082,3.62503546 C20.8485889,3.62503546 21.5624544,4.33890095 21.5624544,5.21878166 L21.5624544,15.8437563 C21.5624544,16.723637 20.8485889,17.4375025 19.9687082,17.4375025 L13.5937234,17.4375025 L14.1249721,19.0312487 L16.5155914,19.0312487 C16.9571855,19.0312487 17.3124645,19.3865278 17.3124645,19.8281218 C17.3124645,20.2697159 16.9571855,20.6249949 16.5155914,20.6249949 L7.48436297,20.6249949 C7.04276894,20.6249949 6.68748987,20.2697159 6.68748987,19.8281218 C6.68748987,19.3865278 7.04276894,19.0312487 7.48436297,19.0312487 L9.87498227,19.0312487 L10.406231,17.4375025 L4.0312462,17.4375025 C3.15136549,17.4375025 2.4375,16.723637 2.4375,15.8437563 L2.4375,5.21878166 C2.4375,4.33890095 3.15136549,3.62503546 4.0312462,3.62503546 L19.9687082,3.62503546 Z M19.4374595,15.3125076 L19.4374595,5.7500304 L4.56249493,5.7500304 L4.56249493,15.3125076 L19.4374595,15.3125076 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default DeviceComputer;
