/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconSearch(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <path fill="#7B98AB" d="M21.8495573,20.1480156 C22.0515183,20.3499347 22.0515183,20.6765216 21.8495573,20.8784826 L20.8784826,21.8495573 C20.6765216,22.0515183 20.3499347,22.0515183 20.1480156,21.8495573 L14.931605,16.6331467 C14.8370656,16.5343272 14.7812148,16.4054212 14.7812148,16.2679132 L14.7812148,15.7007606 C13.2128591,17.054273 11.1718484,17.8749574 8.93747869,17.8749574 C4.0003643,17.8749574 0,13.8745931 0,8.93747869 C0,4.0003643 4.0003643,0 8.93747869,0 C13.8745931,0 17.8749574,4.0003643 17.8749574,8.93747869 C17.8749574,11.1718484 17.054273,13.2128591 15.7007606,14.7812148 L16.2679132,14.7812148 C16.4054212,14.7812148 16.5343272,14.8327855 16.6331467,14.931605 L21.8495573,20.1480156 Z M8.93747869,15.8124623 C12.7359239,15.8124623 15.8124623,12.7359239 15.8124623,8.93747869 C15.8124623,5.13903346 12.7359239,2.06249508 8.93747869,2.06249508 C5.13903346,2.06249508 2.06249508,5.13903346 2.06249508,8.93747869 C2.06249508,12.7359239 5.13903346,15.8124623 8.93747869,15.8124623 Z" transform="translate(9 9)"/>
            </svg>
         </SvgIcon>;
}
;

export default IconSearch;
