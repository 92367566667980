/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import BaseDataProvider from '../BaseDataProvider';
import { getNumberForAdminState } from '../../components/table/AdminState.js'
import { IsNullOrEmpty } from '../../components/StringExtensions';

let SINGLETON = null;
export class DataProvider extends BaseDataProvider {

  constructor() {
    super();
    if (!SINGLETON)
    {
      SINGLETON = this;
      this.data = JSON.parse("[]");
    }
    return SINGLETON;
  }

  getAdminsSorted(sortColumn, sortDirection, searchText) {
    var sortColumnName = "name";
    if (sortColumn === 2)
    {
      sortColumnName = "firstName";
    }
    if (sortColumn === 3)
    {
      sortColumnName = "mail";
    }
    if (sortColumn === 4)
    {
      sortColumnName = "phoneNumber";
    }
    if (sortColumn === 5)
    {
      sortColumnName = "state";
    }
    if (sortColumn === 6)
    {
      sortColumnName = "lastAuditLog";
    }
    if (sortColumn === 7)
    {
      sortColumnName = "lastLogin";
    }
    this.data = super.sortData(this.data, sortColumnName, sortDirection)

    if (!IsNullOrEmpty(searchText) && this.data != null && this.data.length > 0)
    {
      var searchData = [];
      let searchTextLower = searchText.toLowerCase();
      let columns = ["name", "firstName", "mail", "phoneNumber"];

      for (let admin of this.data) {
        var pushAdmin = false;
        for (let column of columns) {
          var value = admin[column];
          if (value !== undefined && value.toLowerCase().indexOf(searchTextLower, 0) > -1)
          {
            pushAdmin = true;
            break;
          }
        }

        if (pushAdmin)
        {
          searchData.push(admin);
        }
      }
      return searchData;
    }

    return this.data;
  }

  //override
  sortState(a, b) {

    let numA = getNumberForAdminState(a["state"]);
    let numB = getNumberForAdminState(b["state"]);

    return numA > numB ? 1 : (numA === numB ? 0 : -1);
  }

  updateData(newData) {
    this.data = []
    for (let admin of newData) {
      if (!(admin.Admin === undefined))
      {
        let newData = JSON.parse(JSON.stringify(admin.Admin));
        this.mapData(newData);
      }
    }
    if (typeof this.callback === "function")
    {
      this.callback();
    }
  }

  mapData(newData) {
    this.data.push(newData);
  }

  searchAdmin(adminGuid) {
    for (let admin of this.data) {
      if (admin.guid === adminGuid)
      {
        return admin;
      }
    }
    return null;
  }

  getDisplayName(admin) {
    return admin.firstName.concat(" ", admin.name);
  }

  insertAdmin(json) {
    if (!(json.Admin === undefined))
    {
      let newData = JSON.parse(JSON.stringify(json.Admin));
      this.mapData(newData)
    }
    if (typeof this.callback === "function")
    {
      this.callback();
    }
  }

  deleteAdmin(admin) {
    let index = this.data.indexOf(admin)
    if (index !== -1)
    {
      this.data.splice(index, 1)
    }
  }

  addCallback(callback) {
    this.callback = callback;
  }

  getDataCount() {
    return this.data.length;
  }
}

export default DataProvider;
