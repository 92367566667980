/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconUserState(props) {
  return <SvgIcon {...props}>
  <svg viewBox="0 0 30 30">
    <path fill="#7B98AB" d="M14.9999823,23.1249949 C13.8272403,23.1249949 12.8759925,22.1737147 12.8759925,21 L17.123972,21 C17.123972,22.1737147 16.1727242,23.1249949 14.9999823,23.1249949 Z M22.1515746,18.1541682 C22.3507929,18.3680101 22.4391182,18.6243286 22.4375095,18.8750051 C22.4341572,19.419548 22.0064734,19.9375025 21.3716597,19.9375025 L8.6283048,19.9375025 C7.9934911,19.9375025 7.56616401,19.419548 7.56245506,18.8750051 C7.56084633,18.6243286 7.64917162,18.3683344 7.8483899,18.1541682 C8.48985069,17.4648808 9.69015377,16.4279341 9.69015377,13.031269 C9.69015377,10.4513859 11.4990388,8.38614992 13.9381333,7.87947922 L13.9381333,7.18753293 C13.9381333,6.60083771 14.4136113,6.12503546 14.9999823,6.12503546 C15.5863532,6.12503546 16.0618312,6.60083771 16.0618312,7.18753293 L16.0618312,7.87947922 C18.5009257,8.38614992 20.3098108,10.4513859 20.3098108,13.031269 C20.3098108,16.4279341 21.5101138,17.4648808 22.1515746,18.1541682 Z"/>
  </svg>
         </SvgIcon>;
}
;

export default IconUserState;
