//Inspiriert von https://spin.atomicobject.com/2016/10/05/form-validation-react/
import * as ErrorMessage from './validationErrorMessages';

export const maxValue = (max, {t}) => {
  return (number) => {
    return number <= max ? null : ErrorMessage.maxValue(max,{t});
  }
}

export const minValue = (min,{t}) => {
  return (number) => {
    return number >= min ? null :  ErrorMessage.minValue(min,{t})
  }
}

export const validate = (value, validationrules) => {
  var validationErrors =[];
  if (validationrules != null) {
    for (let v of validationrules) {
      let errorMessage = v(value);
      if (errorMessage != null) {
        //we have a validation error!
        validationErrors.push(errorMessage);
      }
    }
  }
  return validationErrors;
}
