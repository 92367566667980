import React, { Component } from 'react';
import FlatButton from '../buttons/FlatButton';

const styles = {
  deactiveLabel: {
    color: '#4A4521',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  inactiveLabel: {
    color: '#FFFFFF',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  activeLabel: {
    color: '#EEC643',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  paginationButtonStyle: {
    minWidth: '42px'
  },
  paginationHalfButtonStyle: {
    minWidth: '21px'
  },
  hidden: {
    display: "none",
  }
}

export class TableFooterPagination extends Component {
  constructor(props) {
    super(props);
    var pagination = 20;
    if (pagination in props) {
      pagination = props.pagination;
    }
    this.state = {
      index: 1,
      pagination: pagination
    };
    this.handleNavigateButtonClicked = this.handleNavigateButtonClicked.bind(this);
  }

  handleNavigateButtonClicked(newIndex) {
    this.setState({
      index: newIndex
    }, function() {
      this.props.parent.forceUpdate();
    });
  }

  forcePaginationToStart() {
    this.handleNavigateButtonClicked(1);
  }

  getNavigationButtonLeft(selectedIndex, maxIndex) {
    if (maxIndex === 1) {
      return <FlatButton label="<" style={ styles.paginationButtonStyle } labelStyle={ styles.deactiveLabel } />
    }
    if (selectedIndex === 1) {
      return <FlatButton label="<" style={ styles.paginationButtonStyle } labelStyle={ styles.deactiveLabel } />
    }
    return <FlatButton label="<" style={ styles.paginationButtonStyle } labelStyle={ styles.inactiveLabel } onClick={ () => this.handleNavigateButtonClicked(selectedIndex - 1) } />
  }

  getNavigationButtonRight(selectedIndex, maxIndex) {
    if (maxIndex === 1) {
      return <FlatButton label=">" style={ styles.paginationButtonStyle } labelStyle={ styles.deactiveLabel } />
    }
    if (selectedIndex === maxIndex) {
      return <FlatButton label=">" style={ styles.paginationButtonStyle } labelStyle={ styles.deactiveLabel } />
    }
    return <FlatButton label=">" style={ styles.paginationButtonStyle } labelStyle={ styles.inactiveLabel } onClick={ () => this.handleNavigateButtonClicked(selectedIndex + 1) } />
  }

  getNavigationButtonNumber(index, selectedIndex, maxIndex) {
    if (maxIndex === 1) {
      return <FlatButton label="1" style={ styles.paginationButtonStyle } labelStyle={ styles.activeLabel } />
    }
    if (index === selectedIndex) {
      return <FlatButton label={ index } style={ styles.paginationButtonStyle } labelStyle={ styles.activeLabel } />
    }
    if (index > maxIndex) {
      return <div style={ styles.hidden }></div>
    }
    return <FlatButton label={ index } style={ styles.paginationButtonStyle } labelStyle={ styles.inactiveLabel } onClick={ () => this.handleNavigateButtonClicked(index) } />
  }

  getNavigationButtonPlaceholder() {
    return <FlatButton label="..." style={ styles.paginationButtonStyle } labelStyle={ styles.inactiveLabel } />
  }

  getNavigationHalfButtonPlaceholder() {
    return <FlatButton label="..." style={ styles.paginationHalfButtonStyle } labelStyle={ styles.inactiveLabel } />
  }

  getPaginatedData(data) {
    if (typeof data === "undefined") {
      return []
    }
    let pagination = this.state.pagination;
    var index = 1

    if (typeof this.state !== "undefined") {
      index = this.state.index
    }

    let pages = Math.floor((data.length) / pagination);
    if (pages * pagination < data.length) {
      pages += 1
    }
    if (pages === 0) {
      pages = 1;
    }

    if (index > pages) {
      index = pages
    }

    let rc = data.slice((index - 1) * pagination, index * pagination);
    return rc
  }

  render() {
    let data = this.props.data;
    if (typeof data === "undefined") {
      return (<div></div>)
    }

    let pagination = this.state.pagination;
    var index = 1
    if (typeof this.state !== "undefined") {
      index = this.state.index
    }

    let pages = Math.floor((data.length) / pagination);
    if (pages * pagination < data.length) {
      pages += 1
    }
    if (pages === 0) {
      pages = 1;
    }

    if (index > pages) {
      index = pages
    }

    if (pages === 1) {
      return (
        <tr><td>
          { this.getNavigationButtonLeft(index, pages) }
          { this.getNavigationButtonNumber(1, index, pages) }
          { this.getNavigationButtonRight(index, pages) }
        </td></tr>)

    }

    if (pages <= 6) {
      return (
        <tr><td>
          { this.getNavigationButtonLeft(index, pages) }
          { this.getNavigationButtonNumber(1, index, pages) }
          { this.getNavigationButtonNumber(2, index, pages) }
          { this.getNavigationButtonNumber(3, index, pages) }
          { this.getNavigationButtonNumber(4, index, pages) }
          { this.getNavigationButtonNumber(5, index, pages) }
          { this.getNavigationButtonNumber(6, index, pages) }
          { this.getNavigationButtonRight(index, pages) }
        </td></tr>)

    }

    if (index < 4) {
      return (
        <tr><td>
          { this.getNavigationButtonLeft(index, pages) }
          { this.getNavigationButtonNumber(1, index, pages) }
          { this.getNavigationButtonNumber(2, index, pages) }
          { this.getNavigationButtonNumber(3, index, pages) }
          { this.getNavigationButtonNumber(4, index, pages) }
          { this.getNavigationButtonPlaceholder(5, index, pages) }
          { this.getNavigationButtonNumber(pages, index, pages) }
          { this.getNavigationButtonRight(index, pages) }
        </td></tr>)

    }
    if (pages - index < 3) {
      return (
        <tr><td>
          { this.getNavigationButtonLeft(index, pages) }
          { this.getNavigationButtonNumber(1, index, pages) }
          { this.getNavigationButtonPlaceholder(2, index, pages) }
          { this.getNavigationButtonNumber(pages - 3, index, pages) }
          { this.getNavigationButtonNumber(pages - 2, index, pages) }
          { this.getNavigationButtonNumber(pages - 1, index, pages) }
          { this.getNavigationButtonNumber(pages, index, pages) }
          { this.getNavigationButtonRight(index, pages) }
        </td></tr>)
    }
    return (
      <tr><td>
        { this.getNavigationButtonLeft(index, pages) }
        { this.getNavigationButtonNumber(1, index, pages) }
        { this.getNavigationHalfButtonPlaceholder(2, index, pages) }
        { this.getNavigationButtonNumber(index - 1, index, pages) }
        { this.getNavigationButtonNumber(index, index, pages) }
        { this.getNavigationButtonNumber(index + 1, index, pages) }
        { this.getNavigationHalfButtonPlaceholder(pages - 1, index, pages) }
        { this.getNavigationButtonNumber(pages, index, pages) }
        { this.getNavigationButtonRight(index, pages) }
      </td></tr>)

  }
}
export default TableFooterPagination;