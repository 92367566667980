/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconTabelleMore(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 40 30">
             <title>tabelle_more</title>
             <desc>Created with Sketch.</desc>
             <defs></defs>
             <path fill="#7B98AB" d="M23.0937164,14.7500197 C23.0937164,16.4601634 21.7101176,17.8437623 19.9999738,17.8437623 C18.28983,17.8437623 16.9062312,16.4601634 16.9062312,14.7500197 C16.9062312,13.0398759 18.28983,11.656277 19.9999738,11.656277 C21.7101176,11.656277 23.0937164,13.0398759 23.0937164,14.7500197 Z M27.5624557,11.656277 C29.2725995,11.656277 30.6561984,13.0398759 30.6561984,14.7500197 C30.6561984,16.4601634 29.2725995,17.8437623 27.5624557,17.8437623 C25.852312,17.8437623 24.4687131,16.4601634 24.4687131,14.7500197 C24.4687131,13.0398759 25.852312,11.656277 27.5624557,11.656277 Z M12.4374918,11.656277 C14.1476356,11.656277 15.5312344,13.0398759 15.5312344,14.7500197 C15.5312344,16.4601634 14.1476356,17.8437623 12.4374918,17.8437623 C10.727348,17.8437623 9.34374918,16.4601634 9.34374918,14.7500197 C9.34374918,13.0398759 10.727348,11.656277 12.4374918,11.656277 Z"
             />
           </svg>
         </SvgIcon>;
}
;

export default IconTabelleMore;
