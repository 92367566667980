import React, { Component } from 'react';
import { SplitToBlocksOfFour } from './StringExtensions';

const styles = {
  splitstring: {
    color: '#00375D',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: 12,
    margin: 4,
    fontWeight: 'medium',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '12px',
    float: 'both',
    textAlign: 'center',
  },
  splitstringviewbox: {
    float: 'both',
    marginTop: '25px',
  },
  wallpaper: {
    backgroundColor: '#00C1A7',
    borderRadius: 8,
    position: 'absolute'
  },

}

class SplitStringView extends Component {
  constructor(props) {
    super(props);
    if (typeof this.props.StringSplitFunction === 'function')
    {
      this.stringsplitter = this.props.StringSplitFunction;
    }
    else
    {
      //default
      this.stringsplitter = SplitToBlocksOfFour;
    }
  }

  renderSplittedString() {
    var renderedSplittedString = [];
    let splittedString = this.stringsplitter(this.props.Value);
    for (var i = 0; i < splittedString.length; i++)
    {
      renderedSplittedString.push(
        <span key={ i } style={ styles.splitstring }>{ splittedString[i] }</span>
      );
    }
    return renderedSplittedString;
  }

  render() {
    let splittedStringView = this.renderSplittedString();
    return (<div style={ styles.wallpaper }>
              <div style={ { position: 'relative', top: -5, margin: '20px' } }>
                <div style={ styles.title }>
                  { this.props.Title.toUpperCase() }
                </div>
                <div style={ styles.splitstringviewbox }>
                  { splittedStringView }
                </div>
              </div>
            </div>)
  }
}

export default SplitStringView;
