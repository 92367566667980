import React from 'react';
import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';
import { WaitDialog } from '../../components/WaitDialog'
import { ModalDialog } from '../../components/ModalDialog'
import { getGroupList, getSingleGroup, createGroup, updateGroup, deleteGroup, getGroupImage, getUserList, setRoomFeed } from '../../actions/index'
import DataProvider from './groups/DataProvider';
import BaseModul from '../BaseModul';

const LIST_GROUPS = 0;
const NEW_GROUP = 1;
const EDIT_GROUP = 2;

//const ROOM_TYPE = "ManagedRoom";
export class AbstractGroups extends BaseModul {
  constructor(props) {
    super(props);
    this.state = {
      groupList: {},
      editModeButton: {
        visible: false,
        enabled: false
      },
      currentViewMode: LIST_GROUPS,
      editGroup: null,
      isPendingChanges: false,
      feedUrl: null,
      groupDataCount: 0
    };
    this.handleNewGroupClicked = this.handleNewGroupClicked.bind(this);
    this.handleCancelNewGroupClicked = this.handleCancelNewGroupClicked.bind(this);
    this.listEditModeListener = this.listEditModeListener.bind(this);
    this.createGroupHandler = this.createGroupHandler.bind(this);
    this.handleSaveNewGroupClicked = this.handleSaveNewGroupClicked.bind(this);

    this.createGroupSuccessHandler = this.createGroupSuccessHandler.bind(this);
    this.groupErrorHandler = this.groupErrorHandler.bind(this);

    this.loadGroupList = this.loadGroupList.bind(this);
    this.getGroupListSuccessHandler = this.getGroupListSuccessHandler.bind(this);

    this.loadUserList = this.loadUserList.bind(this);
    this.getUserListSuccessHandler = this.getUserListSuccessHandler.bind(this);

    this.dataprovider = new DataProvider();
    this.dataprovider.setFetchRoomDataJsonFetchFunction(this.getRoomObjectFrom);
    this.onRowSelection = this.onRowSelection.bind(this);
    this.handleSaveUpdateGroupClicked = this.handleSaveUpdateGroupClicked.bind(this);
    this.updateGroupHandler = this.updateGroupHandler.bind(this);
    this.updateGroupSuccessHandler = this.updateGroupSuccessHandler.bind(this);

    this.beginDeleteGroupClicked = this.beginDeleteGroupClicked.bind(this);
    this.callDeleteGroup = this.callDeleteGroup.bind(this);
    this.deleteGroupSuccessHandler = this.deleteGroupSuccessHandler.bind(this);

    this.loadGroupImage = this.loadGroupImage.bind(this);
    this.loadGroupImageSuccessHandler = this.loadGroupImageSuccessHandler.bind(this);

    this.handleCancelNewGroupClickedConfirmation = this.handleCancelNewGroupClickedConfirmation.bind(this);
    this.handleOnPendingChanges = this.handleOnPendingChanges.bind(this);

    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.handlerReloadDeletedData = this.handlerReloadDeletedData.bind(this);
    this.getSingleGroupSuccessHandler = this.getSingleGroupSuccessHandler.bind(this);
    this.getGroupListSuccessNullHandler = this.getGroupListSuccessNullHandler.bind(this);

  }

  handleOnPendingChanges(isPending) {
    this.setState({
      isPendingChanges: isPending
    });
    if (isPending) {
      this.setDirty();
    } else {
      this.clearDirty();
    }
  }

  getRoomObjectFrom(json) {
    throw new Error("Implement getRoomObjectFrom(json)");
  }

  handleNewGroupClicked() {
    this.setState({
      currentViewMode: NEW_GROUP
    });
  }

  handleSaveNewGroupClicked() {
    this.refs.newgroup.getWrappedInstance().handleNewGroupSaveClicked();
    this.setState({
      isPendingChanges: false,
    });
    this.clearDirty();
  }

  handleSaveUpdateGroupClicked() {
    this.refs.updategroup.getWrappedInstance().handleUpdateGroupSaveClicked();
  }

  handleCancelNewGroupClickedConfirmation() {
    this.setState({
      currentViewMode: LIST_GROUPS,
      isPendingChanges: false,
    });
    this.clearDirty();
    this.loadGroupList(false);
  }

  handleCancelNewGroupClicked() {
    const {t} = this.props;
    if (this.state.isPendingChanges) {
      let yesNoDialog = {
        text: t("components-ModalDialog-looseDataQuestion"),
        button1Text: t("components-ModalDialog-no"),
        button1Handler: null,
        button2Text: t("components-ModalDialog-yes"),
        button2Handler: this.handleCancelNewGroupClickedConfirmation,
        title: t("components-ModalDialog-Warning"),
      };
      this.refs.modalDialog.open(yesNoDialog);
    } else {
      this.handleCancelNewGroupClickedConfirmation();
    }
  }

  createGroupHandler(groupData, groupImage, groupMember, roomWriter) {
    this.refs.waitDialog.open();
    let member = [];
    for (var i = 0; i < groupMember.length; i++) {
      member.push(groupMember[i].guid)
    }
    var writers = roomWriter; //writers darf auch undefined sein.
    if (typeof roomWriter !== 'undefined') {
      writers = roomWriter.join(',');
    }
    this.props.dispatch(createGroup(groupData.name, this.props.RoomType, groupImage, member.join(','), writers, this.createGroupSuccessHandler, this.groupErrorHandler))

    if (typeof groupData.feed !== 'undefined' && groupData.feed !== null && groupData.feed.length > 0) {
      this.setState({
        feedUrl: groupData.feed
      });
    }
  }

  createGroupSuccessHandler(json) {
    if (!(typeof this.getRoomObjectFrom(json) === "undefined")) {
      this.dataprovider.insertGroup(json)
    }

    if (typeof json.RestrictedRoom !== 'undefined' && typeof json.RestrictedRoom.guid !== 'undefined') {
      if (this.state.feedUrl !== null && this.state.feedUrl.length > 0) {
        this.props.dispatch(setRoomFeed(json.RestrictedRoom.guid, this.state.feedUrl, this.updateGroupSuccessHandler, this.groupErrorHandler))
      }
    }

    this.setState({
      currentViewMode: LIST_GROUPS
    });
    this.refs.waitDialog.close();
    this.loadGroupList(false);
  }

  groupErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);
  }

  updateGroupHandler(groupData, groupImage, groupMember, roomWriter) {
    this.refs.waitDialog.open();
    let member = [];
    for (var i = 0; i < groupMember.length; i++) {
      member.push(groupMember[i].guid)
    }
    var writers = '';
    if (typeof roomWriter !== 'undefined') {
      writers = roomWriter.join(',');
    }
    this.props.dispatch(updateGroup(groupData.guid, groupData.name, groupImage, member.join(','), writers, groupData.feed, this.updateGroupSuccessHandler, this.groupErrorHandler))

  }

  updateGroupSuccessHandler(json) {
    if (!(typeof this.getRoomObjectFrom(json) === "undefined")) {
      // TODO : Update User im Datenmodell
      //this.dataprovider.insertUser(json)
    }

    this.handleOnPendingChanges(false);

    this.setState({
      currentViewMode: LIST_GROUPS,
      feedUrl: null
    });
    this.refs.waitDialog.close();
    this.loadGroupList(false);

  }

  renderDeleteGroupConfirmMessageText() {
    const {t} = this.props;
    return t("modules-groups-Groups-confirmDeleteGroup");
  }

  beginDeleteGroupClicked(currentGroup) {
    this.refs.waitDialog.close();
    const {t} = this.props;

    if (this.state.currentViewMode === 2)
    {
      this.state.deleteGroup = this.state.editGroup;
    } else {
      this.state.deleteGroup = currentGroup;
    }

    this.refs.modalDialog.open({
      text: this.renderDeleteGroupConfirmMessageText(),
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: this.callDeleteGroup
    });
  }

  callDeleteGroup() {
    this.refs.waitDialog.open();
    this.props.dispatch(deleteGroup(this.state.deleteGroup.guid, this.deleteGroupSuccessHandler, this.groupErrorHandler))
  }

  deleteGroupSuccessHandler() {
    this.dataprovider.deleteGroup(this.state.deleteGroup)
    this.refs.waitDialog.close();
    this.setState({
      currentViewMode: LIST_GROUPS,
      editGroup: null
    });
    this.loadGroupList(false);
  }

  componentDidMount() {
    super.componentDidMount();
    var showWaitDialog = true;
    //WaitDialog immer anzeigen
    //das liegt daran, dass wir den dataprovider (SINGLETON!)
    //wiederverwenden und er beim Wechsel vom Menü 'Gruppen'
    //auf 'Kanäle' (oder anders herum) erst einmal noch
    //die "alten" Daten anzeigt..

    //Idee -> jeweils für Gruppen und Kanäle einen
    //eigenen DataProvider initialisieren
    //dann können wir den u.a. auskommentierten Teil wieder einkommentieren

    //if (!(typeof this.dataprovider === "undefined"))
    //{
    //  showWaitDialog = this.dataprovider.getDataCount() == 0;
    //}
    this.loadGroupList(showWaitDialog);
  }

  loadGroupList(showWaitDialog) {
    if (showWaitDialog) {
      this.refs.waitDialog.open();
    }
    this.props.dispatch(getGroupList(this.props.RoomType, this.getGroupListSuccessHandler, this.groupErrorHandler));
  }

  getGroupListSuccessHandler(json) {
    this.dataprovider.updateData(json.data);
    this.setState({
      groupDataCount: json.data.length
    });

    this.loadUserList(false);
    if (this.dataprovider.hasUserData()) {
      this.refs.waitDialog.close();
    }
  }

  loadGroupImage(groupGuid, callback) {
    this.loadGroupImageSuccessHandlerCallback = callback;
    this.props.dispatch(getGroupImage(groupGuid, this.loadGroupImageSuccessHandler));
  }

  loadGroupImageSuccessHandler(json) {
    this.loadGroupImageSuccessHandlerCallback(json.RoomImage);
  }

  handleReloadData() {
    if (this.state.currentViewMode === EDIT_GROUP) {
      if (typeof this.state.editGroup !== "undefined") {
        this.loadSingleGroup(this.state.editGroup.guid);
      } else {
        this.loadGroupList(true);
      }
    } else {
      this.loadGroupList(true);
    }
  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    if (this.state.currentViewMode === EDIT_GROUP) {
      if (typeof this.state.editGroup !== "undefined") {
        this.props.dispatch(getSingleGroup(this.props.RoomType, this.state.editGroup.guid, this.getGroupListSuccessNullHandler));
      } else {
        this.props.dispatch(getGroupList(this.props.RoomType, this.getGroupListSuccessNullHandler));
      }
    } else {
      this.props.dispatch(getGroupList(this.props.RoomType, this.getGroupListSuccessNullHandler));
    }
  }

  handlerReloadDeletedData() {
    if (this.state.currentViewMode === EDIT_GROUP) {
      this.handleOnPendingChanges(false)
      this.deleteGroupSuccessHandler();
    } else {
      this.loadGroupList(true);
    }
  }
  loadSingleGroup(guid) {
    this.props.dispatch(getSingleGroup(this.props.RoomType, guid, this.getSingleGroupSuccessHandler, this.groupErrorHandler));
  }

  getGroupListSuccessNullHandler(json) {
    this.refs.waitDialog.close();
  }

  getSingleGroupSuccessHandler(json) {
    this.dataprovider.searchAndUpdate(json.data)

    if (this.state.currentViewMode === EDIT_GROUP) {
      let newEditGroup = this.dataprovider.searchGroup(this.state.editGroup.guid)
      this.setState({
        editGroup: newEditGroup
      });
      if (
        typeof this.refs.updategroup !== "undefined"
        && typeof this.refs.updategroup.getWrappedInstance() !== "undefined"
      ) {
        this.refs.updategroup.getWrappedInstance().loadGroup(newEditGroup)
      }
    }
  }

  listEditModeListener(visible, enabled) {
    if (
      visible !== this.state.editModeButton.visible
      || enabled !== this.state.editModeButton.enabled
    ) {
      this.setState({
        editModeButton: {
          visible: visible,
          enabled: enabled
        }
      })
    }
  }

  loadUserList(showWaitDialog) {
    if (showWaitDialog) {
      this.refs.waitDialog.open();
    }
    this.props.dispatch(getUserList(this.dataprovider.getUserDataLastQueryDate(), this.getUserListSuccessHandler, this.groupErrorHandler));
  }

  getUserListSuccessHandler(json) {
    this.dataprovider.updateUserData(json);
    this.refs.waitDialog.close();
  }

  onRowSelection(group) {
    this.loadSingleGroup(group.guid);
    this.setState({
      currentViewMode: EDIT_GROUP,
      editGroup: group
    });
  }

  renderCreateGroupText() {
    const {t} = this.props;
    return t('modules-groups-Groups-btnCreateGroup');
  }

  renderCommonButton() {
    const {t} = this.props;
    if (this.state.currentViewMode === LIST_GROUPS) {
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <RaisedButton label={ this.renderCreateGroupText() } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleNewGroupClicked } />
               </div>
             </div>
    }
    if (this.state.currentViewMode === NEW_GROUP) {
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <FlatButton
                  label={ t('modules-user-User-btnCancelNewUser') }
                  style={ this.getBaseStyle().cancelButton }
                  secondary={ true }
                  onClick={ this.handleCancelNewGroupClicked }
                  disabled={ !this.state.isPendingChanges }
                />
                <RaisedButton
                  label={ t('modules-user-User-btnSaveNewUser') }
                  primary={ true } style={ this.getBaseStyle().fab }
                  onClick={ this.handleSaveNewGroupClicked }
                  disabled={ !(this.state.isPendingChanges && this.state.editModeButton.enabled) }
                />
               </div>
             </div>;
    }
    if (this.state.currentViewMode === EDIT_GROUP) {
      if (this.isCustomEditGroupCommonButtons()) {
        return this.renderCustomEditGroupCommonButtons()
      } else {
        return this.renderEditGroupCommonButton();
      }
    }
  }

  isCustomEditGroupCommonButtons() {
    return false;
  }

  renderCustomEditGroupCommonButtons() {
    throw new Error('Implement renderCustomEditGroupCommonButtons()');
  }

  renderEditGroupCommonButton() {
    const {t} = this.props;
    return <div>
             <div style={ this.getBaseStyle().fabRight }>
              <FlatButton
                label={ t('modules-user-User-btnCancelNewUser') }
                style={ this.getBaseStyle().cancelButton }
                onClick={ this.handleCancelNewGroupClicked } disabled={ !this.state.isPendingChanges }
              />
              <RaisedButton
                label={ t('modules-user-User-btnSaveNewUser') }
                primary={ true }
                style={ this.getBaseStyle().fab }
                onClick={ this.handleSaveUpdateGroupClicked }
                disabled={ !(this.state.isPendingChanges && this.state.editModeButton.enabled) }
              />
             </div>
           </div>;
  }

  renderEditGroupHeader() {
    throw new Error("Implement renderEditGroupHeader");
  }

  renderNewGroupHeader() {
    throw new Error("Implement renderNewGroupHeader");
  }

  renderListGroupsHeader() {
    throw new Error("Implement renderListGroupsHeader");
  }
  renderHeaderView() {
    // const {t} = this.props;
    if (this.state.currentViewMode === LIST_GROUPS) {
      return this.renderListGroupsHeader();
    }
    if (this.state.currentViewMode === NEW_GROUP) {
      return this.renderNewGroupHeader();
    }
    if (this.state.currentViewMode === EDIT_GROUP) {
      return this.renderEditGroupHeader();
    }
  }

  renderListGroupsMainView() {
    throw new Error('Implement renderListGroupsMainView()');
  }

  renderNewGroupMainView() {
    throw new Error('Implement renderNewGroupMainView()');
  }

  renderEditGroupMainView(group) {
    throw new Error('Implement renderNewGroupMainView()');
  }

  renderMainView() {
    // const {t} = this.props;
    if (this.state.currentViewMode === LIST_GROUPS) {
      return this.renderListGroupsMainView();

    }
    if (this.state.currentViewMode === NEW_GROUP) {
      return this.renderNewGroupMainView();

    }
    if (this.state.currentViewMode === EDIT_GROUP) {
      return this.renderEditGroupMainView(this.state.editGroup);
    }
  }

  renderHiddenDialog() {
    return <div>
             <WaitDialog ref="waitDialog" />
             <ModalDialog ref="modalDialog" />
             <ModalDialog ref="commonErrorDialog" />
           </div>;
  }

}
