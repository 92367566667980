/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconMail(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <path fill="#7B98AB" d="M23.1778834,12.8601397 C23.3073883,12.7572233 23.4999595,12.8534926 23.4999595,13.0162005 L23.4999595,19.8062234 C23.4999595,20.6861041 22.786094,21.3999696 21.9062133,21.3999696 L8.0937462,21.3999696 C7.21386549,21.3999696 6.5,20.6861041 6.5,19.8062234 L6.5,13.0195079 C6.5,12.8534926 6.68926385,12.7605306 6.82207603,12.8634795 C7.5658048,13.441193 8.55194175,14.1749998 11.9386524,16.6353325 C12.6392561,17.1466724 13.8212716,18.2224316 14.9999797,18.2157845 C16.185335,18.2257714 17.390599,17.1267311 18.0646144,16.6353325 C21.4513251,14.1749998 22.4341547,13.4378857 23.1778834,12.8601397 Z M14.9999797,17.1499797 C14.2296626,17.1632739 13.1207003,16.1804443 12.5628632,15.7753607 C8.15684496,12.5779138 7.82147474,12.2956879 6.80547451,11.5021546 C6.61290333,11.3494011 6.5,11.1203196 6.5,10.8746041 L6.5,10.2437462 C6.5,9.36386549 7.21386549,8.65 8.0937462,8.65 L21.9062133,8.65 C22.786094,8.65 23.4999595,9.36386549 23.4999595,10.2437462 L23.4999595,10.8746041 C23.4999595,11.1203196 23.3870561,11.3527409 23.194485,11.5021546 C22.1784847,12.2990277 21.8431145,12.5779138 17.4370963,15.7753607 C16.8792592,16.1804443 15.7702969,17.1632739 14.9999797,17.1499797 Z"
             />
           </svg>
         </SvgIcon>;
}
;

export default IconMail;
