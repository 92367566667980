/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component }  from 'react'
import Paper from 'material-ui/Paper';

const styles = {
  root: {
  },
  page: {
    position: 'absolute',
    width: 300,
    height: 200,
    zIndex: 15,
    top: '50%',
    left: '50%',
    margin: '-100px 0 0 -150px',
    textAlign: 'center',
    paddingTop: 10

  }
};

export class NotFoundPage extends Component{
  render() {
    return (
      <div style={ styles.root }>
        <Paper style={ styles.page }>
          Page Not Found!!!
        </Paper>
      </div>
    )
  }
}

export default (NotFoundPage);
