/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconChannelsActive(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 40 40">
              <g fill="none" fillRule="evenodd" transform="translate(8 8)">
                <path d="M12,9 C10.343,9 9,10.343 9,12 C9,13.657 10.343,15 12,15 C13.657,15 15,13.657 15,12 C15,10.343 13.657,9 12,9"/>
                <path d="M12,17.5 C8.968,17.5 6.5,15.032 6.5,12 C6.5,8.968 8.968,6.5 12,6.5 C15.032,6.5 17.5,8.968 17.5,12 C17.5,15.032 15.032,17.5 12,17.5 M12,3.5 C7.313,3.5 3.5,7.313 3.5,12 C3.5,16.687 7.313,20.5 12,20.5 C16.687,20.5 20.5,16.687 20.5,12 C20.5,7.313 16.687,3.5 12,3.5"/>
                <path d="M12,22 C6.486,22 2,17.514 2,12 C2,6.486 6.486,2 12,2 C17.514,2 22,6.486 22,12 C22,17.514 17.514,22 12,22 M12,0 C5.383,0 0,5.383 0,12 C0,18.617 5.383,24 12,24 C18.617,24 24,18.617 24,12 C24,5.383 18.617,0 12,0"/>
                <path fill="#EEC643" fillRule="nonzero" d="M18.6785714,3.5 C19.6845224,3.5 20.5,4.31547764 20.5,5.32142857 L20.5,18.6785714 C20.5,19.6845224 19.6845224,20.5 18.6785714,20.5 L5.32142857,20.5 C4.31547764,20.5 3.5,19.6845224 3.5,18.6785714 L3.5,5.32142857 C3.5,4.31547764 4.31547764,3.5 5.32142857,3.5 L18.6785714,3.5 Z M7.75,18.0714286 C8.75595093,18.0714286 9.57142857,17.2559509 9.57142857,16.25 C9.57142857,15.2440491 8.75595093,14.4285714 7.75,14.4285714 C6.74404907,14.4285714 5.92857143,15.2440491 5.92857143,16.25 C5.92857143,17.2559509 6.74404907,18.0714286 7.75,18.0714286 Z M13.7278224,18.0714286 C13.9758083,18.0714286 14.1746935,17.8639461 14.161353,17.6162938 C13.9349343,13.4259556 10.5785283,10.0653251 6.38370623,9.83864702 C6.1360539,9.82530648 5.92857143,10.0241917 5.92857143,10.2721776 L5.92857143,11.5750667 C5.92857143,11.8031158 6.10448129,11.9944044 6.33204869,12.0092272 C9.36320496,12.2066672 11.7928881,14.6303101 11.9907357,17.6679143 C12.0055956,17.8955187 12.19681,18.0714286 12.4249333,18.0714286 L13.7278224,18.0714286 Z M17.6373051,18.0714286 C17.8822152,18.0714286 18.0799146,17.8688747 18.0711321,17.6241499 C17.8436388,11.2865775 12.7444022,6.15750994 6.37585013,5.92886789 C6.13112531,5.92008536 5.92857143,6.11778477 5.92857143,6.36269488 L5.92857143,7.66554696 C5.92857143,7.89930289 6.11381967,8.08959089 6.34746443,8.09918867 C11.5292642,8.31163679 15.6884373,12.4715511 15.9008484,17.6525726 C15.9104091,17.8861803 16.1006971,18.0714286 16.334453,18.0714286 L17.6373051,18.0714286 Z"/>
              </g>
            </svg>
         </SvgIcon>;
}
;

export default IconChannelsActive;
