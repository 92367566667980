//Modaler Dialog mit Close-Button oben rechts
//inspired by:
//https://stackoverflow.com/questions/42363198/how-to-add-close-icon-in-material-ui-dialog-header-top-right-corner

import React,{Component} from 'react';
import Dialog from 'material-ui/Dialog';
import IconClose from '../ressources/icon_delete_white';
// import FlatButton from 'material-ui/FlatButton';

let closeImg = {cursor:'pointer', float:'right', marginTop: '0px', width: '20px'};

export class CustomModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
    this.handleClose=this.handleClose.bind(this);
  }

  open() {
    this.setState({open:true});
  }

  handleClose() {
    this.setState({open:false});
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  render() {
    return(
      <div>
        <Dialog
          modal={true}
          open={this.state.open}
          contentStyle={this.props.style}
          title={
            <div style={{padding:10}}>
              <IconClose style={closeImg} onClick={this.handleClose}/>
            </div>
          }
          >
          <span>{this.props.children}</span>
        </Dialog>
      </div>
    )
  }

}
