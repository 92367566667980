/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconTabelleSortUp(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 16 16">
             <title>tabelle_sort_up</title>
             <desc>Created with Sketch.</desc>
             <defs></defs>
             <path fill="#00C1A7" d="M6.08971278,6.45457736 C5.91343396,6.62195301 5.62765583,6.62195301 5.45139537,6.45457736 L5.13220912,6.15152942 C4.95593029,5.9841712 4.95593029,5.71280919 5.13220912,5.54543354 L7.68085048,3.12553174 C7.8571293,2.95815609 8.14288907,2.95815609 8.31916789,3.12553174 L10.8677909,5.54543354 C11.0440697,5.71279176 11.0440697,5.98415376 10.8677909,6.15152942 L10.5486046,6.45457736 C10.3723442,6.62195301 10.086566,6.62195301 9.91028722,6.45457736 L8.67705173,5.28361044 L8.67705173,12.571429 C8.67705173,12.8081231 8.47496839,13 8.22568391,13 L7.77431609,13 C7.52503161,13 7.32294827,12.8081231 7.32294827,12.571429 L7.32294827,5.28361044 L6.08971278,6.45457736 Z"
             />
           </svg>
         </SvgIcon>;
}
;

export default IconTabelleSortUp;
