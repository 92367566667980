/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconOverlayExpand(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 30 30">
              <path fill="#00C1A7" d="M25,6.3237616 L25,-0.712430734 C25,-1.19965034 25.5890641,-1.44363398 25.9335572,-1.09911423 L29.4516534,2.41898194 C29.6652225,2.63252436 29.6652225,2.9788065 29.4516534,3.19234893 L25.9335572,6.71044509 C25.5890641,7.05499154 25,6.8109812 25,6.3237616 Z" transform="rotate(-45 27.306 2.806)"/>
            </svg>
         </SvgIcon>;
}
;

export default IconOverlayExpand;
