export const colors = {
  BLACK: '#00375D',
  WHITE: '#FFFFFF',
  YELLOW: '#EEC643',
  GREEN: '#00C1A7',
  BLUE: '#7B98AB',
  GRAY: '#7B98AB',
  pie: {
    group: [
      '#7B98AB',
      '#0A87C3',
      '#0E73A4',
      '#11638B',
      '#135677',
      '#154B67',
      '#16435A',
      '#173C50',
      '#183748',
      '#193341'
    ],
    groupChannel: [
      '#00C1A7',
      '#0AA350',
      '#0E8948',
      '#117441',
      '#13633C',
      '#155638',
      '#164B34',
      '#174331',
      '#183C2F',
      '#19372D'
    ]
  }
}


export const styles = {

  dashboard_outerDiv: {
    display: 'flex',
    flexDirection: 'row'
  },

  dashboard_column1: {
    flex: '0 0 auto',
    order: '1',
    alignSelf: 'auto',
    minWidth: '600px',
    overflowX: 'disabled'
  },

  dashboard_chartColumn1: {
    minWidth: '250px',
    width: '250px',
    flex: '0 1 auto',
    order: '1',
    alignSelf: 'auto'
  },
  dashboard_chartColumn2: {
    minWidth: '350px',
    width: '360px',
    flex: '0 1 auto',
    order: '2',
    alignSelf: 'auto'
  },
  dashboard_chartColumn3: {
    flex: '1 1 auto',
    order: '3',
    alignSelf: 'auto',
    paddingRight: '10px',
    paddingLeft: '30px',
    paddingTop: '3px'

  },
  dashboard_chartColumn4: {
    minWidth: '5px',
    width: '5px',
    flex: '0 1 auto',
    order: '4',
    alignSelf: 'auto'
  },

  dashboard_subTitle: {
    marginLeft: '24px',
    marginTop: '24px',
    fontSize: '10pt',
    fontWeight: 'normal',
    display: 'inline-flex',
    alignItems: 'center'
  },

  dashboard_subTitleIcon: {
    height: '30px',
    color: colors.GRAY
  },

  dashboard_subTitleIconWhite: {
    height: '15px',
    color: colors.WHITE
  },

  dashboard_subTitleIconYellow: {
    height: '15px',
    color: colors.YELLOW
  },

  dashboard_subTitleIconBlue: {
    height: '15px',
    color: colors.BLUE
  },

  dashboard_subTitleIconGreen: {
    height: '15px',
    color: colors.GREEN
  },

  dashboard_subTitle2: {
    paddingRight: '15px',
    color: colors.GRAY
  },

  dashboard_subTitle2White: {
    paddingRight: '15px',
    color: colors.WHITE
  },

  dashboard_subTitle2Yellow: {
    paddingRight: '15px',
    color: colors.YELLOW
  },

  dashboard_subTitle2Blue: {
    paddingRight: '15px',
    color: colors.BLUE
  },

  dashboard_subTitle2Green: {
    paddingRight: '15px',
    color: colors.GREEN
  },

  dashboard_chartSubTitle: {
    fontSize: '10pt',
    fontWeight: 'normal',
    color: colors.GRAY
  },

  dashboard_detailTitle: {
    fontSize: '9pt',
    fontWeight: 'normal',
    color: colors.YELLOW,
    marginLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px'
  },

  dashboard_detailTitleLegend: {
    color: colors.GRAY
  },

  dashboard_chartTableColumn1: {
    width: '170px'
  },

  dashboard_chartTableColumn2: {
    display: 'inline-flex',
    alignItems: 'center'
  },

  dashboard_tableRowUnselected: {
    color: colors.WHITE,
    backgroundColor: 'transparent'
  },

  dashboard_tableRowSelected: {
    color: colors.YELLOW,
    backgroundColor: 'transparent'
  },

  dashboard_usergeneratedgroups: {
    color: colors.GREEN,
    backgroundColor: 'transparent'
  },

  dashboard_usergeneratedgroupsSelected: {
    color: colors.YELLOW,
    backgroundColor: 'transparent'
  },

  dashboard_smallIcon: {
    width: 24,
    height: 24,
  },
  dashboard_small: {
    width: 24,
    height: 24,
    padding: 0,
    marginLeft: 6
  },

  dashboard_chartChannelMessageDiv: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px'
  },

  dashboard_chartChannelMessageDivCol1: {
    color: colors.YELLOW,
    flex: '0 1 auto',
    padding: '5px',
    minWidth: '30px',
    textAlign: 'right'
  },

  dashboard_chartChannelMessageDivCol2: {
    color: colors.GRAY,
    flex: '0 1 auto',
    paddingRight: '10px'
  },

  dashboard_chartChannelMessageDivCol3: {
    flex: '1 1 auto',
    border: '1px',
    borderColor: colors.BLACK,
    backgroundColor: colors.WHITE,
    padding: '10px',
    borderRadius: '5px',
    maxWidth: '500px'
  },

  dashboard_lineChart: {
    marginTop: '30px',
    marginRight: '0px',
    marginLeft: '0px',
    marginBottom: '20px'
  },

  dashboard_selectbox: {
    marginRight: '15px'
  },

  dashboard_right: {
    float: 'right',
    position: 'absolute',
    right: '10px',
    top: '10px',
    alignItems: 'flex-start'
  },
  dashboard_center: {
    float: 'right',
    position: 'absolute',
    right: '10px',
    left: '10px',
    top: '20px',
    textAlign: 'center',
    color: colors.GREEN,
    fontSize: '9pt',
  },
  dashboard_tooltip: {
    width: 'fill',
    border: '1px',
    borderColor: colors.BLACK,
    backgroundColor: colors.WHITE,
    padding: '10px',
    textAlign: 'center',
    borderRadius: '5px'
  },
  dashboard_separator: {
    minHeight: '60px',
    height: '60px',
    background: 'linear-gradient(to bottom,#00375D, #00375D )',

  },
  infoButton: {
    backgroundColor: colors.BLUE,
  },
  popupHeaderText: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingTop: '2px',
    paddingBottom: '4px'
  },
  popupText: {
    fontSize: '12px',
    fontWeight: 'medium',
  }
}
