import React, { Component }  from 'react'
import ChannelManager from '../common/channels/ChannelManager'

/*
const styles = {
  root: {
    width: '100%',
    height: 'fill'
  }
};
*/
export class Channel extends Component{

  render() {
    return (
        <ChannelManager
          GroupsIdentifier="Kanäle"
          GroupIdentifier="Kanal"
          RoomType="RestrictedRoom"
          GroupChipColor="#F5A623"
          />
    )
  }
}

export default (Channel);
