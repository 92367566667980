import React from 'react';
import Dialog from 'material-ui/Dialog';
import { WaitDialog } from '../../components/WaitDialog';
import ErrorDialog from '../../components/ErrorDialog';
import TextField from '../../components/textfields/TextField';
import RaisedButton from '../../components/buttons/RaisedButton';
import {setRecoveryCodes} from "../../actions";


/**
 *
 * @type {{secret: string, open: boolean}}
 */
const initState = {
  open: false,
  recoveryCodes: []
};


class ApiTwoFaCreateRecoveryCodesDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }


  /**
   *
   * @returns {void}
   */
  open( param ) {
    const that = this;
    const /** @type {string[]} */
      recoveryCodes =  that.createRecoveryCodes();

    this.setState({
      open: true,
      recoveryCodes: recoveryCodes
    });

  }


  /**
   *
   * @returns {void}
   */
  close() {
    this.setState(initState);
  }


  /**
   *
   * @returns {string[]}
   */
  createRecoveryCodes() {
    const /** @type {number} */
      numberOfCodes = 5;

    let recoveryCodes = [];
    for (let i = 0; i < numberOfCodes; i++ ) {
      recoveryCodes.push( this.createRecoveryCode() );
    }

    return recoveryCodes;
  }


  /**
   *
   * @returns {string}
   */
  createRecoveryCode() {
    const /** @type {string} */
      possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      /** @type {number} */
      blockLength = 5,
      /** @type {number} */
      numberOfBlocks = 5;

    let /** @type {string} */
      code,
      /** @type {string[]} */
      blocks = [];

    for (let i = 0; i < numberOfBlocks; i++) {
      let /** @type {string} */
        block = '';

      for (let j = 0; j < blockLength; j++) {
        const /** @type {number} */
          index = Math.floor( Math.random() * possibleCharacters.length );
        block += possibleCharacters.at( index );
      }
      blocks.push( block );
    }

    code = blocks.join('-');

    return code;
  }


  /**
   *
   * @param {string[]} recoveryCodes
   * @returns {string}
   */
  recoveryCodeToTextFieldValue( recoveryCodes ) {
    return recoveryCodes.join('\n');
  }


  /**
   *
   * @param {string[]} recoveryCodes
   * @returns {string}
   */
  recoveryCodeToCsvString( recoveryCodes ) {
    return recoveryCodes.join(',');
  }


  /**
   *
   * @returns {void}
   */
  processRecoveryCodes() {
    const that = this;
    const /** {string} */
      recoveryCodesCsv = that.recoveryCodeToCsvString(that.state.recoveryCodes),
      callback = that.props.onTwoFaSetRecoveryCodeSuccess.bind(that, {recoveryCodes: recoveryCodesCsv});

    that.props.dispatch( setRecoveryCodes( recoveryCodesCsv, callback, that.props.commonErrorHandler ) );
    that.close();
  }


  /**
   *
   * @returns {void}
   */
  handleSubmitButton() {
    this.processRecoveryCodes();
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const that = this;
    const {t} = this.props,
      /** @type {string} */
      recoveryCodeTextFieldValue = that.recoveryCodeToTextFieldValue( this.state.recoveryCodes );


    return (
      <div>
        <div>
          <WaitDialog ref="waitDialog"/>
          <ErrorDialog ref="errorDialog" t={t}/>
        </div>

        <Dialog title={t('settings-two-fa-create-recovery-codes-dialog-title')}
                contentStyle={{width: "80%", maxWidth: '960px'}}
                modal={true}
                open={this.state.open}>
          <div className="dialog__content"
               style={{width: "50%"}}
            >
            <div>{t('settings-two-fa-create-recovery-codes-dialog-get-the-codes')}</div>

            <TextField
              type="text"
              className="dialog__recovery-codes"
              id="recovery-codes"
              value={recoveryCodeTextFieldValue}
              multiLine // "multiLine" has to be "camelCase" (in React 15?) to render the line breaks "\n" !!!
              rows={5} // Has to be type {number} !
              fullWidth
              hintText={t('settings-two-fa-create-recovery-codes-dialog-text-recovery-codes')}
              floatingLabelText={t('settings-two-fa-create-recovery-codes-dialog-text-recovery-codes')}/>
          </div>

          <div className="dialog__actions">
            <RaisedButton
              className="a-btn btn--primary"
              primary={true}
              label={t('settings-two-fa-create-recovery-codes-dialog-btn-okay')}
              onTouchTap={this.handleSubmitButton.bind(this)}/>
          </div>
        </Dialog>

      </div>
      );
    }
  }


  export default ApiTwoFaCreateRecoveryCodesDialog;
