/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconTabelleNotActivated(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <title>tabelle_not_activated</title>
             <desc>Created with Sketch.</desc>
             <g fill="none" transform="translate(5 6.625)">
               <path fill="#DC004B" d="M18.9097053,14.6097619 C19.5225674,15.6720648 18.7532878,16.9999596 17.5292498,16.9999596 L1.59554906,16.9999596 C0.369143979,16.9999596 -0.396601265,15.670022 0.215061095,14.6097619 L8.18200873,0.796386894 C8.79516263,-0.266434821 10.3309008,-0.26448933 10.9429847,0.796386894 L18.9097053,14.6097619 Z"
               />
               <path fill="#FFF" d="M9.56249669 11.7538784C8.71896424 11.7538784 8.03515659 12.437686 8.03515659 13.2812185 8.03515659 14.124751 8.71896424 14.8085586 9.56249669 14.8085586 10.4060292 14.8085586 11.0898368 14.124751 11.0898368 13.2812185 11.0898368 12.437686 10.4060292 11.7538784 9.56249669 11.7538784zM8.112425 6.26389743L8.35872416 10.7795117C8.37023498 10.990792 8.54494007 11.1562236 8.75654464 11.1562236L10.3684488 11.1562236C10.5800533 11.1562236 10.7547584 10.990792 10.7662692 10.7795117L11.0125684 6.26389743C11.0250195 6.03565892 10.8433107 5.84373623 10.6147479 5.84373623L8.51021306 5.84373623C8.28165029 5.84373623 8.09997386 6.03565892 8.112425 6.26389743z"
               />
             </g>
           </svg>
         </SvgIcon>;
}

export default IconTabelleNotActivated;
