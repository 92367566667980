import React, { Component, PropTypes } from 'react';
import TextField from '../textfields/TextField';
// import Checkbox from 'material-ui/Checkbox';
import IconCheck from '../../ressources/icon_check.js';
import IconCheckFilled from '../../ressources/icon_check_filled.js';

export const NEW_PASSWORD = 'newPwd';
export const REPEAT_PASSWORD = 'repeatPwd';

const styles = {
  iconTextComb: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  iconLeft: {
    marginRight: '20px'
  },
  iconText: {
    fontSize: '14px',
    color: '#FFFFFF'
  },
  textField: {
    width: '100%',
    fontSize: '16px'
  }
}

const defaultState = {
  dataSetPwd: {
    newPwd: '',
    repeatPwd: ''
  },
  errors: {
    newPwd: '',
    repeatPwd: ''
  },
  pwdHasMinLength: false,
  pwdHasUpperCase: false,
  pwdHasSpezChar: false
};
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  resetForm() {
    this.setState(defaultState);
  }

  validatePassword() {
    let dataSetPwd = JSON.parse(JSON.stringify(this.state.dataSetPwd));
    let errors = JSON.parse(JSON.stringify(this.state.errors));

    let newPwd = dataSetPwd['newPwd'];
    if (!newPwd || newPwd.length < 1)
    {
      errors['newPwd'] = 'login-forgot-pwd-err-empty';
    }
    else
    {
      errors['newPwd'] = '';
    }

    let repeatPwd = dataSetPwd['repeatPwd'];
    if (!repeatPwd || repeatPwd.length < 1)
    {
      errors['repeatPwd'] = 'login-forgot-pwd-err-empty';
    }
    else if (repeatPwd !== newPwd)
    {
      errors['repeatPwd'] = 'login-forgot-pwd-err-not-equal';
    }
    else
    {
      errors['repeatPwd'] = '';
    }

    this.setState({
      errors: errors
    });

    return (!errors['newPwd'] && !errors['repeatPwd']);
  }

  hasPasswordPassedAllGuidelines() {
    return (this.state.pwdHasMinLength && this.state.pwdHasSpezChar && this.state.pwdHasUpperCase);
  }

  handleChangeData(event, newValue, name) {
    const {onChange} = this.props;

    if (name === 'newPwd')
    {
      let newPwd = event.target.value;
      var hasMinLength = false;
      var hasUpperLowerCase = false;
      var hasSpezChar = false;

      if (newPwd.length > 7)
      {
        hasMinLength = true;
      }

      var rxUpperLowercase = new RegExp('^(?=.*[a-z])(?=.*[A-Z]).*$');
      if (newPwd.match(rxUpperLowercase))
      {
        hasUpperLowerCase = true;
      }

      var rxSpezChar = new RegExp('^(?=.*\\W).*$');
      if (newPwd.match(rxSpezChar))
      {
        hasSpezChar = true;
      }

      var rxDigitChar = new RegExp('^(?=.*\\d).*$');
      if (newPwd.match(rxDigitChar))
      {
        hasSpezChar = true;
      }

      let oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
      oldData['newPwd'] = newPwd;

      this.setState({
        dataSetPwd: oldData,
        pwdHasMinLength: hasMinLength,
        pwdHasUpperCase: hasUpperLowerCase,
        pwdHasSpezChar: hasSpezChar
      });
    }

    if (name === 'repeatPwd')
    {
      let oldData = JSON.parse(JSON.stringify(this.state.dataSetPwd))
      oldData['repeatPwd'] = newValue;

      this.setState({
        dataSetPwd: oldData
      });
    }

    onChange(name, newValue);
  }

  getIconTextCombo(isChecked, text) {
    return (
      <div style={ styles.iconTextComb }>
        { isChecked && <IconCheckFilled style={ styles.iconLeft } /> }
        { !isChecked && <IconCheck style={ styles.iconLeft } /> }
        <span style={ styles.iconText }>{ text }</span>
      </div>
      );
  }

  getPwdTextField(hintText, errorTextKey, changeDataKey) {
    const {t} = this.props;
    return (
      <TextField type='password' style={ styles.textfield } hintText={ hintText } errorText={ errorTextKey ? t(errorTextKey) : '' } fullWidth={ true } onChange={ (event, newValue) => this.handleChangeData(event, newValue, changeDataKey) }
      />
      );
  }

  render() {
    const {t} = this.props;

    return (
      <div>
        { this.getIconTextCombo(this.state.pwdHasMinLength, t('login-forgot-pwd-desc-min-char')) }
        { this.getIconTextCombo(this.state.pwdHasUpperCase, t('login-forgot-pwd-desc-upper-char')) }
        { this.getIconTextCombo(this.state.pwdHasSpezChar, t('login-forgot-pwd-desc-spez-char')) }
        { this.getPwdTextField(t('login-pwd-new-textfield-hint'), this.state.errors.newPwd, 'newPwd') }
        { this.getPwdTextField(t('login-pwd-repeat-textfield-hint'), this.state.errors.repeatPwd, 'repeatPwd') }
      </div>);
  }
}

PasswordChangeForm.propTypes = {
  onChange: PropTypes.func.isRequired,
}
export default PasswordChangeForm;
