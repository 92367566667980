/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconDeleteRed(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 20 20">
              <path fill="#DC004B" d="M10,0 C15.5241935,0 20,4.47580645 20,10 C20,15.5241935 15.5241935,20 10,20 C4.47580645,20 0,15.5241935 0,10 C0,4.47580645 4.47580645,0 10,0 Z M14.9032101,12.6249842 L12.2580645,10 L14.9072659,7.37903226 C15.0967899,7.18950825 15.0967899,6.88307239 14.9072659,6.69354839 L13.3104681,5.09678994 C13.1209835,4.90726594 12.8145083,4.90726594 12.6249842,5.09678994 L10,7.74193548 L7.37903226,5.09273406 C7.18950825,4.90321006 6.88307239,4.90321006 6.69354839,5.09273406 L5.09678994,6.68953188 C4.90726594,6.87901651 4.90726594,7.18549175 5.09678994,7.37501575 L7.74193548,10 L5.09678994,12.6209677 C4.90726594,12.8104917 4.90726594,13.1169276 5.09678994,13.3064516 L6.68953188,14.9032101 C6.87901651,15.0927341 7.18549175,15.0927341 7.37501575,14.9032101 L10,12.2580645 L12.6209677,14.9032101 C12.8104917,15.0927341 13.1169276,15.0927341 13.3064516,14.9032101 L14.9032101,13.3104681 C15.0927341,13.1209835 15.0927341,12.8145083 14.9032101,12.6249842 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default IconDeleteRed;
