import React, { Component }  from 'react';
import {
  TableRow,
} from 'material-ui/Table';

import { TableRowColumnNumber,
  TableRowColumnDescriptionText,
  TableRowColumnToggle,
  TableRowColumnValuePicker,
  WrappedTableRowColumnText,
  TableRowColumnInput } from './TableCells';

const styles={
  SettingTableRowStyle:{
    marginTop:'20px',
    marginBottom:'15px',
    height:'60px',
  },
  ToggleButtonCenterRowStyle:{
    marginTop:'16px',
    marginLeft:'-7px',
  }
}


class BaseSettingsTableRow extends Component {
state = {
  disabled:false,
  value:false,
}
constructor(props) {
  super(props);
  this.handleMenuSettingChange = this.handleMenuSettingChange.bind(this);
}

updateState(props) {
  const dis=props.disabled;
  const def=props.defaultValue;
  this.setState({disabled:dis, value:def});
}

componentDidMount() {
  this.updateState(this.props);
}
componentWillMount() {
  //Force init state!
  this.updateState(this.props);
}

componentWillReceiveProps(nextProps) {
  this.updateState(nextProps);
}

handleMenuSettingChange(event, value) {

  if (this.props.onMenuItemSelected != null) {
    this.props.onMenuItemSelected(event, value);
  }
}

renderInputComponent() {
  return <div>Implement renderInputComponent</div>
}

render() {
  return (
  <TableRow displayBorder={ false } style={styles.SettingTableRowStyle} >
    <TableRowColumnNumber text={this.props.number} disabled={this.props.disabled}/>
    <WrappedTableRowColumnText text={this.props.settingText} disabled={this.props.disabled} />
    {this.renderInputComponent()}
    <TableRowColumnDescriptionText text={this.props.settingDescription} disabled={this.props.disabled} />
  </TableRow>);
}
}

//TODO: Refactor: eine 'abstrakte' Komponente, von der die u.a. erben und dann nur noch das
//      "Inputelement (Toggle, Menü, Input...)" ausgetauscht wird!
export class ToggledSettingsTableRow extends BaseSettingsTableRow {
  constructor(props) {
    super(props);
    this.handleToggle=this.handleToggle.bind(this);
  }
  //Diese Methode ist beim Refactoring verblieben, damit
  //in den AppSettings nicht so viel umgebaut werden muss...
  handleToggle(event, toggled) {
    //this.setState({defaultValue:toggled});
    if (this.props.onToggle != null) {
      this.props.onToggle(event, toggled);
    }
  }

  renderInputComponent() {
    return <TableRowColumnToggle
      style={styles.ToggleButtonCenterRowStyle}
      onToggle={this.handleToggle}
      disabled={this.state.disabled}
      defaultValue={this.state.value}/>
  }

}
export class MenueSettingsTableRow extends BaseSettingsTableRow {

  renderInputComponent() {
    return <TableRowColumnValuePicker
    fields={this.props.fields}
    defaultValue={this.state.value}
    disabled={this.state.disabled}
    onChange={this.handleMenuSettingChange}/>
  }

}


export class InputValueSettingsTableRow extends BaseSettingsTableRow {

  renderInputComponent() {
    return <TableRowColumnInput
      value={this.state.value}
      type={this.props.type}
      validations={this.props.validations}
      disabled={this.state.disabled}
      onChange={this.handleMenuSettingChange}
      onValidation={this.props.onValidation}
      hintText={this.props.hintText}
      />
  }
}
