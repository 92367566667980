/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconReset(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 20 20">
              <path fill="#FFF" d="M16.5955078,0 C16.8188883,0 17,0.181233724 17,0.404761905 L17,7.16199312 C17,7.3855213 16.8188883,7.56675502 16.5955078,7.56675502 L9.8427072,7.56675502 C9.61932668,7.56675502 9.43821267,7.3855213 9.43821267,7.16199312 L9.43821267,5.56280808 C9.43821267,5.33167085 9.63130877,5.14747256 9.8620629,5.15850733 L13.2842355,5.32261246 C12.2193101,3.73927138 10.4120873,2.6984127 8.35956058,2.6984127 C5.08031733,2.6984127 2.42697408,5.35410273 2.42697408,8.63492063 C2.42697408,11.9163315 5.08090985,14.5714286 8.35956058,14.5714286 C9.86314919,14.5714286 11.2350993,14.0131352 12.2800765,13.0921766 C12.4398597,12.9513928 12.6816742,12.9598582 12.8322405,13.110524 L13.9773208,14.2563612 C14.1415479,14.4206969 14.1334172,14.6885948 13.9610594,14.8443662 C12.4787357,16.1841847 10.514363,17 8.35956058,17 C3.74480726,17 0.00339740945,13.2582582 0,8.6412121 C-0.00338366754,4.03164334 3.75303673,0.26984127 8.3595935,0.26984127 C10.9102989,0.26984127 13.1938101,1.41327389 14.7270577,3.21559749 L14.5933454,0.424130394 C14.5823179,0.193223741 14.7663945,0 14.9973791,0 L16.5955078,0 Z" transform="matrix(-1 0 0 1 18.5 1.5)"/>
            </svg>
          </SvgIcon>;
}
;

export default IconReset;
