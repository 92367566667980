/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export function IconTabelleStatusOn(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <title>
               { props.alt }
             </title>
             <desc>Created with Sketch.</desc>
             <defs></defs>
             <circle cx="15.5" cy="15.5" r="6.5" fill="#00C1A7" fillRule="evenodd" />
           </svg>
         </SvgIcon>;
}

export default IconTabelleStatusOn;
