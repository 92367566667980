/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconClose(props) {
  return  <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g fill="none" fillRule="evenodd">
                <rect width="20" height="20" fill="#FFF" opacity="0"/>
                <path fill="#7B98AB" fillRule="nonzero" d="M11.9335682,10.0001984 L16.310192,14.375989 C16.5632693,14.6295028 16.5632693,15.040203 16.310192,15.29332 L15.29332,16.310192 C15.0398063,16.5632693 14.629106,16.5632693 14.375989,16.310192 L9.99980163,11.933965 L5.62401102,16.3105887 C5.37049725,16.5636661 4.95979699,16.5636661 4.70667995,16.3105887 L3.68980803,15.2937168 C3.43673066,15.040203 3.43673066,14.6295028 3.68980803,14.3763857 L8.06603504,10.0001984 L3.68980803,5.62401102 C3.43673066,5.37049725 3.43673066,4.95975732 3.68980803,4.70667995 L4.70628322,3.69020476 C4.95979699,3.43712739 5.37049725,3.43712739 5.62361429,3.69020476 L9.99980163,8.06643178 L14.3755922,3.68980803 C14.629106,3.43673066 15.0398063,3.43673066 15.2929233,3.68980803 L16.3097952,4.70667995 C16.5628726,4.96019373 16.5628726,5.37089398 16.3097952,5.62401102 L11.9335682,10.0001984 Z"/>
              </g>
            </svg>
          </SvgIcon>;
}
;

export default IconClose;
