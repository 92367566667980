import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';

const styles = {
  button: {
    //padding: '5px',
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: '8px',
    color: '#FFFFFF'
  },
  whiteText: {
    fontSize: '16px',
    fontWeight: 'regular',
    color: '#FFFFFF',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  }
};

const initState = {
  open: false
};

class DeleteApiUserDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  open(parameter) {

    let buttonDeleteHandler = null;

    if (typeof parameter.buttonDeleteHandler === "function")
    {
      buttonDeleteHandler = parameter.buttonDeleteHandler
    }

    this.setState({
      buttonDeleteHandler: buttonDeleteHandler,
      open: true
    });
  }

  handleCancelButton() {
    this.setState(initState);
  }

  handleDeleteButton() {
    if (typeof this.state.buttonDeleteHandler === "function")
    {
      this.state.buttonDeleteHandler();
    }
    this.setState(initState);
  }

  render() {
    const {t} = this.props;

    var actions = [
      <RaisedButton style={ Object.assign({}, styles.button, { marginRight: '16px', marginBottom: '16px' }) } danger={true}  label={ t('settings-ldap-sync-delete-api-user-button') } onTouchTap={ () => this.handleDeleteButton() } />,
      <RaisedButton style={ styles.button } label={ t('components-ModalDialog-cancel') } onTouchTap={ () => this.handleCancelButton() } />
    ];

    return (
      <div>
        <Dialog title={ t('modules-settings-DeleteApiUserDialog-title') } actions={ actions } modal={ true } open={ this.state.open }>
          <div style={ styles.whiteText }>
            { t('modules-settings-DeleteApiUserDialog-text') }
          </div>
        </Dialog>
      </div>
      );
  }

}

export default DeleteApiUserDialog;
