/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconBack(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 40 40">
             <title>icon_back</title>
             <desc>Created with Sketch.</desc>
             <defs></defs>
             <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
               <g id="icon_back" fill="#FFFFFF">
                 <g id="Page-1" transform="translate(0.000000, 7.000000)">
                   <path d="M11.5859,25.4141 L0.5859,14.4141 C-0.1951,13.6331 -0.1951,12.3671 0.5859,11.5861 L11.5859,0.5861 L14.4139,3.4141 L4.8289,13.0001 L14.4139,22.5861 L11.5859,25.4141 Z"
                     id="Fill-1"></path>
                 </g>
               </g>
             </g>
           </svg>
         </SvgIcon>;
}
;

export default IconBack;
