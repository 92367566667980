/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import Dialog from 'material-ui/Dialog';
// import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

const styles = {
  button: {
    padding: '5px',
    boxShadow: '',
  },
  titleElement: {
    margin: '0px',
    padding: '24px 24px 20px',
    fontSize: '22px',
    lineHeight: '32px',
    fontWeight: '400',
    borderBottom: 'none'
  }

};

export class ModalDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      text: "c",
      button1: "c",
      button2: "c",
    };
    this.handleCloseButton1 = this.handleCloseButton1.bind(this);
    this.handleCloseButton2 = this.handleCloseButton2.bind(this);
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  // TODO :: ButtonHandler registrien
  isOpen() {
    return this.state.open;
  }

  open(parameter) {
    let text = null;
    let button1Text = null;
    let button1Handler = null;
    let button2Text = null;
    let button2Handler = null;
    let title = null;

    if (parameter.title)
    {
      if (React.isValidElement(parameter.title))
      {
        title = <TitleElement value={ parameter.title } />;
      }
      else
      {
        title = parameter.title
      }

    }
    if (typeof parameter.text === "string")
    {
      text = parameter.text
    }
    if (typeof parameter.button1Text === "string")
    {
      button1Text = parameter.button1Text
    }
    if (typeof parameter.button1Handler === "function")
    {
      button1Handler = parameter.button1Handler
    }
    if (typeof parameter.button2Text === "string")
    {
      button2Text = parameter.button2Text
    }
    if (typeof parameter.button2Handler === "function")
    {
      button2Handler = parameter.button2Handler
    }
    this.setState({
      text: text,
      title: title,
      button1: button1Text,
      button1Handler: button1Handler,
      button2: button2Text,
      button2Handler: button2Handler,
      open: true

    });

  }

  handleCloseButton1() {
    if (typeof this.state.button1Handler === "function")
    {
      this.state.button1Handler();
    }
    this.setState({
      open: false
    });
  }
  handleCloseButton2() {
    if (typeof this.state.button2Handler === "function")
    {
      this.state.button2Handler();
    }
    this.setState({
      open: false
    });
  }

  render() {
    var actions = [];
    if (this.state.button2 == null)
    {
      actions = [
        <RaisedButton style={ styles.button } label={ this.state.button1 } primary={ true } onTouchTap={ this.handleCloseButton1 } />,
      ];

    }
    else
    {
      actions = [
        <RaisedButton style={ styles.button } label={ this.state.button1 } secondary={ true } onTouchTap={ this.handleCloseButton1 } />,
        <RaisedButton style={ styles.button } label={ this.state.button2 } primary={ true } onTouchTap={ this.handleCloseButton2 } />,
      ];
    }


    return (
      <div>
        <Dialog title={ this.state.title ? this.state.title : "" } actions={ actions } modal={ true } open={ this.state.open }>
          <span>{ this.state.text }</span>
        </Dialog>
      </div>
      );
  }
}

class TitleElement extends React.Component {

  render() {
    return (
      <h3 style={ styles.titleElement }>{ this.props.value }</h3>
      );
  }
}

export default ModalDialog;
