/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';

import { Table, TableBody, TableHeader, TableRow } from 'material-ui/Table';
// import SelectField from 'material-ui/SelectField';
// import MenuItem from 'material-ui/MenuItem';

// import TextField from '../../components/textfields/TextField';

// import DataProvider from './DataProvider';

import ModalDialog from '../../components/ModalDialog'

import { TableRowColumnText, TableRowColumnAdminState, TableHeaderColumnSort, TableHeaderColumnAdminState, sharedTableStyles } from '../../components/table/TableCells'

import { getTableStyles } from '../../components/styles.js';

const tableStyles = getTableStyles();


export class AdminList extends Component {

  constructor(props) {
    super(props);
    this.dataprovider = props.dataprovider;
    this.isInit = false;
    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      tabledata: this.dataprovider.getAdminsSorted(1, 0, props.searchText),
      placeHolder: {
        name: '',
        firstName: '',
        mail: '',
        phoneNumber: '',
        lastAuditLog: '',
        lastLogin: ''
      },
      searchText: props.searchText
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleDataproviderCallback = this.handleDataproviderCallback.bind(this);
    this.dataprovider.addCallback(this.handleDataproviderCallback);
  }

  handleSortTableButtonClicked(index) {

    if (this.state.sortColumn === index)
    {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.dataprovider.getAdminsSorted(index, newDirection, this.props.searchText)
      });

    }
    else
    {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.dataprovider.getAdminsSorted(index, newDirection, this.props.searchText)
      });
    }
  }

  isEmpty(value) {
    if (value === null || value.length === 0)
    {
      return true;
    }
    return false;
  }

  handleDataproviderCallback() {
    this.setState({
      tabledata: this.dataprovider.getAdminsSorted(this.state.sortColumn, this.state.sortDirection, this.props.searchText)
    });
  }

  //** not used anymore, as we do not update state by setState (performance-hack for IE11)
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.searchText !== this.state.searchText)
    {
      this.setState({
        tabledata: this.dataprovider.getAdminsSorted(this.state.sortColumn, this.state.sortDirection, nextProps.searchText),
        searchText: nextProps.searchText
      });
    }
  /**/
  }

  getAdminLastLogin(admin) {
    const {t} = this.props;

    let lastLogin = "";

    // durch die Admin Infos iterieren
    if (admin && admin.lastLogin && admin.lastLogin.length > 0)
    {
      lastLogin = new Date().parseUtcDate(admin.lastLogin).getShortDateTime();
    }
    else
    {
        lastLogin = t("modules-settings-AdminList-table-row-never-log-in");
    }

    return lastLogin;
  }

  getAdminLastAuditLog(admin) {
    const {t} = this.props;

    let lastAuditLogDate = "";
    let retLastAuditLog = "";

    if (admin && admin.lastAuditLog && admin.lastAuditLog.length > 0)
    {
        lastAuditLogDate = new Date().parseUtcDate(admin.lastAuditLog).getShortDate();
    }

    if (admin && admin.state)
    {
      // eslint-disable-next-line
      switch (admin.state) {
        case 'created': {
          retLastAuditLog = t("modules-user-AdminList-table-auditLog-created").concat(" ", lastAuditLogDate);
          break;
        }
        case 'active': {
          retLastAuditLog = t("modules-user-AdminList-table-auditLog-active").concat(" ", lastAuditLogDate);
          break;
        }
        case 'deactive': {
          retLastAuditLog = t("modules-user-AdminList-table-auditLog-deactive").concat(" ", lastAuditLogDate);
          break;
        }
        case 'deleted': {
          retLastAuditLog = t("modules-user-AdminList-table-auditLog-delete").concat(" ", lastAuditLogDate);
          break;
        }
      }
    }

    return retLastAuditLog;
  }



  render() {
    const {t} = this.props;
    return (
      <div>
        <ModalDialog ref="modalDialog" />
        <Table selectable={ false } className="adminlist__table">
          <TableHeader displaySelectAll={ false } adjustForCheckbox={ false } style={ tableStyles.tableHeader }>
            <TableRow style={ tableStyles.tableHeaderRow }>
              <th width="7px" />
              <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column1') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column2') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 3 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column3') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 4 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column4') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnAdminState index={ 5 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('state') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 6 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column6') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
              <TableHeaderColumnSort index={ 7 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-settings-AdminList-table-column7') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={ false }>
            { this.state.tabledata.map((admin, i) => <TableRow key={ i } className="adminlist__row" value={ admin } style={ sharedTableStyles.rowStyle } displayBorder={ false } onClick={ (event) => this.props.onRowSelection(admin, event) }>
                                                      <td width="7px" height="40px" />
                                                      <TableRowColumnText text={ admin.name } />
                                                      <TableRowColumnText text={ admin.firstName } />
                                                      <TableRowColumnText text={ admin.mail } />
                                                      <TableRowColumnText text={ admin.phoneNumber } />
                                                      <TableRowColumnAdminState t={ t } admin={ admin } />
                                                      <TableRowColumnText text={ this.getAdminLastAuditLog(admin) } />
                                                      <TableRowColumnText text={ this.getAdminLastLogin(admin) } />
                                                    </TableRow>
              ) }
            <TableRow key={ -1 } displayBorder={ true }>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      );
  }
}

export default translate(['common'], {
  withRef: true
})(AdminList);
