const defaultValue = {
  AppConfig: {
    disableNoPwLogin: "",
    simsLockApplicationDelay: "",
    forceComplexPin: "false",
    simsPasswordTries: "",
    disableSaveToCameraRoll: "",
    disableSendMedia: "",
    disableOpenIn: "false",
    forcePasswordKomplexity: "false",
    passwordMinLength: "",
    passwordMinSpecialChar: "",
    passwordMinDigit: "",
    passwordMinLowercase: "",
    passwordMinUppercase: "",
    passwordMinClasses: "",
    passwordMaxDuration: "",
    passwordReuseEntries: "",
    disabledExportChat: "false",
    userRestrictedIndex: "0",
    disableSendVCard: "false",
    disableCamera: "false",
    disableMicrophone: "false",
    disableLocation: "false",
    disableCopyPaste: "false",
    disableSimsmeRecovery: "false",
    disableRecoveryCode: "false",
    disablePushPreview: "false",
    disableBackup: "false",
    disableFaceAndTouchID: "false",
    companyIndexName: "",
  },
}

export class DataProvider {

  constructor() {
    this.data = JSON.parse(JSON.stringify(defaultValue));
  }

  update(data) {
    //damit hier nicht der gesamte State hineinwandert
    this.data.AppConfig = data.AppConfig;
  }

  updateFromJsonString(json) {
    this.data = JSON.parse(JSON.stringify(json));
    for (var key in this.data.AppConfig) {
      if (this.data.AppConfig.hasOwnProperty(key))
      {
        if (this.data.AppConfig[key] === null)
        {
          this.data.AppConfig[key] = defaultValue.AppConfig[key];
        }
      }
    }

    this.notifyDataUpdated();
  }

  getData() {
    return this.data;
  }

  notifyDataUpdated() {
    if (typeof this.callback === "function")
    {
      this.callback();
    }
  }

  addCallback(callback) {
    this.callback = callback;
  }

}

export default DataProvider;
