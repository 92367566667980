import React, { Component } from 'react';
import { translate } from 'react-i18next';

import IconTabelleStatusAlert from '../../ressources/tabelle_status_alert';
import IconTabelleStatusEingeladen from '../../ressources/tabelle_status_eingeladen';
import IconTabelleStatusOn from '../../ressources/tabelle_status_on';
import IconTabelleStatusOnPending from '../../ressources/tabelle_status_on_pending';
import IconTabelleAlertState from '../../ressources/tabelle_alert_state';
// import IconTabelleSortUp from '../../ressources/tabelle_sort_up';

import IconInfo from '../../ressources/icon_info';

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
// import MenuItem from 'material-ui/MenuItem';

const styles = {
  userStatusStyle: {
    margin: 'auto',
    display: 'block',
    paddingRight: "10px"
  },
  userStatusHeaderStyle: {
    margin: 'auto',
    display: 'block',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    width: '36px',
    height: '36px'
  },
  userStatusMenuTextStyle: {
    fontSize: "14px",
  },
  userStatusDiv: {
    fontSize: "14px",
    padding: "8px",
    paddingLeft: "0px",
    display: "inline-flex",
    alignItems: "center"
  },
  userStatusIcon: {
    paddingRight: "8px"
  }
}

export function getNumberForUserState(state, hasVoucher, voucherExpired) {
  if (typeof voucherExpired !== 'undefined' && voucherExpired === 'true')
  {
    return 4;
  }
  if (typeof state === "undefined" || state === null)
  {
    return 1;
  }
  if (hasVoucher === 'true')
  {
    if (state === "ManagedAccountNew")
    {
      return 2;
    }
    if (state === "ManagedAccountAsked")
    {
      return 2;
    }
    if (state === "ManagedAccountAcceptedEmailRequired")
    {
      return 2;
    }
    if (state === "ManagedAccountAcceptedPhoneRequired")
    {
      return 2;
    }
    if (state === "ManagedAccountPendingValidation")
    {
      return 2;
    }
    if (state === "ManagedAccountAccepted")
    {
      return 3;
    }
  }
  else
  {
    return 4;
  }

  if (state === "ManagedAccountDenied")
  {
    return 4;
  }
  if (state === "ManagedAccountDeleted")
  {
    return 4;
  }
  if (state === "ManagedAccountAcceptedEmailFailed")
  {
    return 4;
  }
  if (state === "ManagedAccountAcceptedPhoneFailed")
  {
    return 4;
  }
  return 0;
}


export class UserStateIcon extends Component {
  render() {

    const {t} = this.props;

    let isExpand = this.props.isExpand;

    if (typeof this.props.alertState !== 'undefined' && this.props.alertState !== '')
    {
      return(
        <div style={ styles.userStatusDiv }>
          <IconTabelleAlertState style={ styles.userStatusStyle } />
        </div>
      )
    }
    if (typeof this.props.voucherExpired !== 'undefined' && this.props.voucherExpired === 'true')
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-LicenseExpired') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-LicenseExpired') }
        </div>
      )
    }
    if (typeof this.props.state === "undefined" || this.props.state === null)
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusOnPending style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountNew') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountNew') }
        </div>
      )
    }
    if (this.props.hasVoucher === 'true')
    {
      if (this.props.state === "ManagedAccountNew")
      {
        return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusEingeladen style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') }
        </div>
      )
      }
      if (this.props.state === "ManagedAccountAsked")
      {
        return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusEingeladen style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') }
        </div>
      )
      }
      if (this.props.state === "ManagedAccountAcceptedEmailRequired")
      {
        return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusEingeladen style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired') }
        </div>
      )
      }
      if (this.props.state === "ManagedAccountAcceptedPhoneRequired")
      {
        return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusEingeladen style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired') }
        </div>
      )
      }
      if (this.props.state === "ManagedAccountPendingValidation")
      {
        return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusEingeladen style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation') }
        </div>
      )
      }
      if (this.props.state === "ManagedAccountAccepted")
      {
        return (
          <div style={ styles.userStatusDiv }>
            <IconTabelleStatusOn style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted') } />
            { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted') }
          </div>
        )

      }
    }
    else
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-LicenseRevoke') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-LicenseRevoke') }
        </div>
      )
    }

    if (this.props.state === "ManagedAccountDenied")
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountDenied') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountDenied') }
        </div>
      )
    }
    if (this.props.state === "ManagedAccountDeleted")
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted') }
        </div>
      )
    }
    if (this.props.state === "ManagedAccountAcceptedEmailFailed")
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed') }
        </div>
      )
    }
    if (this.props.state === "ManagedAccountAcceptedPhoneFailed")
    {
      return (
        <div style={ styles.userStatusDiv }>
          <IconTabelleStatusAlert style={ styles.userStatusStyle } alt={ t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed') } />
          { isExpand && t('modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed') }
        </div>
      )
    }
    return <div>
             { this.props.state }
           </div>;
  }
}

export class UserStateTableHeader extends Component {

  render() {
    const {t} = this.props;
    return (
      <IconMenu style={ { width: '36px', height: '36px' } } iconButtonElement={ <IconButton style={ styles.userStatusHeaderStyle }>
                                                                            <IconInfo />
                                                                          </IconButton> }>
        <div style={ { display: "flex", flexDirection: "column" } }>
          <div style={ styles.userStatusDiv }>
            <IconTabelleStatusOnPending style={ styles.userStatusIcon } />
            { t('modules-user-UserList-table-userStatus1') }
          </div>
          <div style={ styles.userStatusDiv }>
            <IconTabelleStatusEingeladen style={ styles.userStatusIcon } />
            { t('modules-user-UserList-table-userStatus2') }
          </div>
          <div style={ styles.userStatusDiv }>
            <IconTabelleStatusOn style={ styles.userStatusIcon } />
            { t('modules-user-UserList-table-userStatus3') }
          </div>
          <div style={ styles.userStatusDiv }>
            <IconTabelleStatusAlert style={ styles.userStatusIcon } />
            { t('modules-user-UserList-table-userStatus4') }
          </div>
        </div>
      </IconMenu>);
  }
}

export default translate(['common'])(UserStateTableHeader);
