/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';
// import DataProvider from './DataProvider.js';
import * as constants from './DataProvider';
// import { translate } from 'react-i18next';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import { TableRowColumnText, TableHeaderColumnSort, TableHeaderColumnDefault } from '../../components/table/TableCells'
import { getTableStyles } from '../../components/styles.js';
import IconCheck from '../../ressources/icon_check';
import IconCheckFilled from '../../ressources/icon_check_filled';
import IconButton from 'material-ui/IconButton';

const styles = {
  button: {
    padding: '5px',
  },
  tableHeader: {
    background: 'linear-gradient(to bottom, #303030 0%,#282828 100%)'
  },
  tableColumnLicenseState: {
    width: '28px',
  },
};

const tableStyles = getTableStyles();

class ExtendLicenseDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      sortColumn: 0,
      sortDirection: 0,
      sortColumnName: constants.KEY_VOUCHER_COUNT,
      voucher: null
    };
    this.handleSelectButton = this.handleSelectButton.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.handleOnFreeVoucherTableRowSelection = this.handleOnFreeVoucherTableRowSelection.bind(this);
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
  }

  // TODO :: ButtonHandler registrien

  open(parameter) {
    let dataProvider = null;
    let buttonSelectHandler = null;

    if (typeof parameter.dataProvider === "object")
    {
      dataProvider = parameter.dataProvider
      dataProvider.setSelectedFreeVoucherRow(null);
    }
    if (typeof parameter.buttonSelectHandler === "function")
    {
      buttonSelectHandler = parameter.buttonSelectHandler
    }

    this.setState({
      dataProvider: dataProvider,
      buttonSelectHandler: buttonSelectHandler,
      open: true,
      voucher: parameter.voucher
    });
  }

  /**
  * Callback fuer sortier-buttons
  */
  handleSortTableButtonClicked(index) {

    var sortColumnName;
    switch (index) {
      case 2: {
        sortColumnName = constants.KEY_EXPIRE_DATE;
        break;
      }
      default: {
        sortColumnName = constants.KEY_VOUCHER_COUNT;
      }
    }

    if (this.state.sortColumn === index)
    {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortColumnName: sortColumnName,
        sortDirection: newDirection
      });

    }
    else
    {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortColumnName: sortColumnName,
        sortDirection: newDirection
      });
    }
  }

  handleSelectButton() {
    if (typeof this.state.buttonSelectHandler === "function")
    {
      this.state.buttonSelectHandler();
    }
    this.setState({
      open: false
    });
  }
  handleCancelButton() {
    this.setState({
      open: false
    });
  }
  renderFirstColumnLicense(guid) {
    let dataProvider = this.state.dataProvider;
    if (dataProvider.isFreeVoucherSelected(guid))
    {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnFreeVoucherTableRowSelection(guid) }>
                 <IconCheckFilled />
               </IconButton>
             </TableRowColumn>;
    }
    else
    {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnFreeVoucherTableRowSelection(guid) }>
                 <IconCheck />
               </IconButton>
             </TableRowColumn>;
    }

  }

  handleOnFreeVoucherTableRowSelection(selectedRows) {
    this.state.dataProvider.setSelectedFreeVoucherRow(selectedRows);
  }

  render() {
    const {t} = this.props;
    let dataProvider = this.state.dataProvider;

    let data = dataProvider && dataProvider.getFreeVoucherSorted(this.state.sortColumnName, this.state.sortDirection);
    let voucher = this.state.voucher;
    let assignEnabled = dataProvider && (typeof dataProvider.getSelectedFreeVoucherGuid() === "string")

    var actions = [
      <RaisedButton style={ styles.button } label={ t('cancel') } secondary={ true } onTouchTap={ this.handleCancelButton } />,
      <RaisedButton style={ styles.button } label={ t('modules-license-License-btn-select') } primary={ true } disabled={ !assignEnabled } onTouchTap={ this.handleSelectButton } />,
    ];

    return (
      <div>
        <Dialog title={ t('modules-license-License-extend-header') } actions={ actions } modal={ true } open={ this.state.open }>
          <span>{ t('modules-license-License-extend-text') }</span>
          { voucher && voucher.CompanyUser.firstName + " " + voucher.CompanyUser.name }
          <Table selectable={ true }>
            <TableHeader displaySelectAll={ false } adjustForCheckbox={ true } style={ tableStyles.tableHeader }>
              <TableRow style={ Object.assign({}, tableStyles.tableHeaderRowWithoutBackground, styles.tableHeader) }>
                <TableHeaderColumnSort style={ styles.tableHeader } index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-count') }
                  onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
                <TableHeaderColumnSort style={ styles.tableHeader } index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-expireDate') }
                  onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
                <TableHeaderColumnDefault style={ styles.tableHeader } />
                <TableHeaderColumnDefault style={ styles.tableHeader } />
              </TableRow>
            </TableHeader>
            <TableBody deselectOnClickaway={ false } displayRowCheckbox={ false } style={ tableStyles.tableBody }>
              { data && data.map((item, i) => <TableRow selected={ dataProvider.isFreeVoucherSelected(item.VoucherGroup.guid) } key={ i } value={ item } displayBorder={ true } style={ Object.assign({}, tableStyles.tableRow, {
                                                                                                                                                            borderBottomColor: '#595959'
                                                                                                                                                          }) }>
                                                { this.renderFirstColumnLicense(item.VoucherGroup.guid) }
                                                <TableRowColumnText text={ item.VoucherGroup.voucherCount + " Lizenzen" } />
                                                <TableRowColumnText text={ item.VoucherGroup.validTill } />
                                                <TableRowColumnText />
                                                <TableRowColumnText />
                                              </TableRow>
                ) }
            </TableBody>
          </Table>
        </Dialog>
      </div>
      );
  }
}

export default ExtendLicenseDialog;
