/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconPhone(props) {
  return <SvgIcon {...props}>
           <svg viewBox="0 0 30 30">
             <path fill="#7B98AB" d="M19.1562259,5.48 C20.139622,5.48 20.9374717,6.27784966 20.9374717,7.26124575 L20.9374717,22.6987089 C20.9374717,23.682105 20.139622,24.4799547 19.1562259,24.4799547 L10.8437458,24.4799547 C9.86034966,24.4799547 9.0625,23.682105 9.0625,22.6987089 L9.0625,7.26124575 C9.0625,6.27784966 9.86034966,5.48 10.8437458,5.48 L19.1562259,5.48 Z M16.7812316,22.2533975 L16.7812316,21.9565232 C16.7812316,21.7116164 16.580827,21.5112118 16.3359202,21.5112118 L13.6640515,21.5112118 C13.4191447,21.5112118 13.2187401,21.7116164 13.2187401,21.9565232 L13.2187401,22.2533975 C13.2187401,22.4983043 13.4191447,22.6987089 13.6640515,22.6987089 L16.3359202,22.6987089 C16.580827,22.6987089 16.7812316,22.4983043 16.7812316,22.2533975 Z M19.1562259,19.2846546 L19.1562259,7.70655719 C19.1562259,7.4616504 18.9558213,7.26124575 18.7109145,7.26124575 L11.2890572,7.26124575 C11.0441504,7.26124575 10.8437458,7.4616504 10.8437458,7.70655719 L10.8437458,19.2846546 C10.8437458,19.5295614 11.0441504,19.729966 11.2890572,19.729966 L18.7109145,19.729966 C18.9558213,19.729966 19.1562259,19.5295614 19.1562259,19.2846546 Z"
             />
           </svg>
         </SvgIcon>;
}

export default IconPhone;
