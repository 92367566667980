import React from 'react';
import Dialog from 'material-ui/Dialog';

import { enableTwoFa } from '../../actions';
import qrcode from 'qrcode';
import { authenticator } from 'otplib';

import RaisedButton from '../../components/buttons/RaisedButton';
import TextField from '../../components/textfields/TextField';
import { WaitDialog } from '../../components/WaitDialog';
import ErrorDialog from '../../components/ErrorDialog.js';
import FlatButton from "../../components/buttons/FlatButton";


/**
 *
 * @type {{secret: string, open: boolean}}
 */
const initState = {
  open: false,
  secret: '',
  token: '',
  errorTextWrongToken: '',
  qrCodeUrl: ''
};


/**
 * @class ApiTwoFaEnableDialog
 */
class ApiTwoFaEnableDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }


  /**
   *
   * @returns {void}
   */
  open() {
    console.log( 'ApiTwoFaEnableDialog#open' );
    const secret = this.getSecret();

    this.setState({
      open: true,
      secret: secret
    });

    this.generateQRCode( secret );
  }


  /**
   *
   * @returns {void}
   */
  close() {
    this.setState(initState);
  }



  /**
   *
   * @returns {string}
   */
  getSecret() {
    return this.generateSecret();
  }


  /**
   *
   * @returns {string|*}
   */
  generateSecret() {
    return authenticator.generateSecret();
  }


  /**
   *
   * @returns {boolean}
   */
  isTokenValid() {
    const that = this,
      /** @type {boolean} */
      isValid = authenticator.check( that.state.token, that.state.secret );

    return isValid;
  }

  /**
   *
   * @param {InputEvent} ev
   * @returns {void}
   */
  handleOnChange( ev ) {
    const { target } = ev;
    this.setState( { token: target.value } );
  }


  /**
   *
   * @returns {void}
   */
  processSecret() {
    const that = this,
      {t} = this.props,
      isValid = that.isTokenValid();

    if ( isValid ) {
      that.setState( { errorTextWrongToken: '' } );
      this.props.dispatch( enableTwoFa( that.state.secret, that.props.onTwoFaEnablePostSuccess, that.props.commonErrorHandler ) );
      that.close();
    } else {
      that.setState( { errorTextWrongToken: t('settings-two-fa-enable-dialog-token-not-valid') } );
    }
  }



  generateQRCode( secret ) {
    const
      that = this,
      {t} = that.props,
      user = '',
      service = t('settings-two-fa-enable-dialog-otp-service-name');

    const otpauth = authenticator.keyuri(
      encodeURIComponent(user),
      encodeURIComponent(service),
      secret
    );

    qrcode.toDataURL(otpauth, (err, imageUrl) => {
      if (err) {
        console.log('Error with QR');
        return;
      }
      that.setState({qrCodeUrl: imageUrl});
    });

  }


  /**
   *
   * @param {KeyboardEvent} ev
   * @returns {void}
   */
  handleKeydown( ev ) {
    const { nativeEvent } = ev,
      { key } = nativeEvent;

    if ( key === 'Enter' ) {
      this.processSecret();
    }
  }


  /**
   *
   * @returns {void}
   */
  handleSubmitButton() {
    this.processSecret();
  }


  /**
   *
   * @returns {void}
   */
  handleCancelButton() {
    this.close();
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <div>
          <WaitDialog ref="waitDialog" />
          <ErrorDialog ref="errorDialog" t={ t } />
        </div>
        <Dialog
          title={ t('settings-two-fa-enable-dialog-title') }
          contentStyle={ { width: "80%", maxWidth: '960px' } }
          modal={ true }
          open={ this.state.open }>

          <div className="dialog__content">
            <div>
              <span className="dialog__label">{ t('secret') }</span>
              {this.state.secret}
            </div>

            <div>
              <img src={this.state.qrCodeUrl} width="212" height="212" alt="QR Code" />
            </div>

            <div>{t('settings-two-fa-enable-dialog-token-help-text')}</div>

            <TextField
              type="text"
              className="dialog__token"
              id="twoFAToken"
              hintText="XXXXXX"
              value={ this.state.token }
              floatingLabelText={ t('token') }
              errorText={ this.state.errorTextWrongToken }
              onChange={ this.handleOnChange.bind(this) }
              onKeyDown={ this.handleKeydown.bind(this) } />
          </div>

          <div className="dialog__actions">
              <FlatButton
                className="a-btn btn--flat"
                secondary={ true }
                label={ t('cancel') }
                onTouchTap={ this.handleCancelButton.bind(this) } />
              <RaisedButton
                className="a-btn btn--primary"
                primary={ true }
                label={ t('settings-two-fa-enable-dialog-btn-submit') }
                onTouchTap={ this.handleSubmitButton.bind(this) } />
          </div>

        </Dialog>
      </div>
      );
  }

}

export default ApiTwoFaEnableDialog;
