require('./StringExtensions');

// eslint-disable-next-line no-extend-native
Date.prototype.getShortDate = function() {
  let month = ('' + (this.getMonth() + 1)).leadingZeroString();
  let dateString = this.getDate().toString().leadingZeroString() + "." + month + "." + this.getFullYear();
  return dateString;
}

// eslint-disable-next-line no-extend-native
Date.prototype.getShortTime = function() {
  let dateString = this.getHours().toString().leadingZeroString() + ":" + this.getMinutes().toString().leadingZeroString();
  return dateString;
}

// eslint-disable-next-line no-extend-native
Date.prototype.getShortDateTime = function() {
  let dateString = this.getShortDate() + " " + this.getShortTime();
  return dateString;
}

// eslint-disable-next-line no-extend-native
Date.prototype.getUTCDateMEZString = function() {
  var moment = require('moment');
  // see https://github.com/moment/moment/issues/1407
  let momentParse = moment.utc(new Date(this));

  return momentParse.local().format("YYYY-MM-DD HH:mm:ssZZ");
}

// eslint-disable-next-line no-extend-native
Date.prototype.parseUtcDate = function(dateString) {
  var moment = require('moment');
  return moment(dateString).toDate();
}
