const TranslationCommonEs ={
    "SERVICE-ADMIN-ERR-0019": "Fallo en la conexión con ginlo Business",
    "SERVICE-ADMIN-ERR-0022": "Entrada no válida",
    "SERVICE-ADMIN-ERR-0098": "El número de móvil no es válido. Introduce el número de móvil, incluido el prefijo o el código de ciudad, en el siguiente formato: '012345678910'. Para números de móvil de fuera de Alemania, incluya el prefijo del país (por ejemplo, \"+31...\").",
    "SERVICE-ADMIN-ERR-0106": "La información de registro no es válida.",
    "SERVICE-ADMIN-ERR-0106-1": "La contraseña no es correcta.",
    "SERVICE-ADMIN-ERR-0109": "La sesión ha expirado.",
    "SERVICE-ADMIN-ERR-0110": "No se ha podido cargar la imagen.",
    "SERVICE-ADMIN-ERR-0111": "La imagen es demasiado grande. La resolución máxima permitida es de 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "El número de teléfono ya está siendo utilizado.",
    "SERVICE-ADMIN-ERR-0114": "La dirección de correo electrónico ya está siendo utilizada.",
    "SERVICE-ADMIN-ERR-0115": "El formato del archivo .csv no es válido.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "El formato del archivo .csv no es válido. La columna Nombre está en blanco.",
    "SERVICE-ADMIN-ERR-0115-NAME": "El formato del archivo .csv no es válido. La columna Apellido está en blanco.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "El formato del archivo .csv no es válido. La columna Número de móvil está en blanco.",
    "SERVICE-ADMIN-ERR-0135": "La imagen es demasiado pequeña. La resolución mínima requerida es de 680 * 80 px.",
    "SERVICE-ADMIN-ERR-0153": "No hay suficientes licencias para esta acción. Tiene la opción de adquirir licencias adicionales en Administración de licencias.",
    "SERVICE-ADMIN-ERR-0154": "El representante no está autorizado para esta acción.",
    "SERVICE-ADMIN-ERR-0156": "Otro administrador ya está registrado en el sistema. Para evitar conflictos, no se permite el registro de más de 1 representante.",
    "SERVICE-ADMIN-ERR-0157": "El administrador ha sido eliminado.",
    "SERVICE-ADMIN-ERR-0158": "El administrador ha sido desactivado.",
    "SERVICE-ADMIN-ERR-0176": "Se ha producido un error al cargar la vista previa del canal RSS.",
    "SERVICE-ADMIN-ERR-0177": "La API ha sido desactivada por otro usuario.",
    "SERVICE-ADMIN-ERR-0181": "Los datos han sido editados por el usuario {{username}} en {{date}}.",
    "SERVICE-ADMIN-ERR-0182": "Los datos fueron borrados por el usuario {{username}} en {{date}}.",
    "SERVICE-ADMIN-ERR-0183": "La API ya está activada.",
    "SERVICE-ERR-0057": "Tiempo de espera. Se ha cerrado la sesión.",
    "SERVICE-ERR-0123": "La dirección de correo electrónico no es válida.",
    "SERVICE-ERR-0124": "El dominio de la dirección de correo electrónico que has indicado está gestionado por un proveedor de correo electrónico privado. No se admite por motivos de privacidad de los datos.",
    "SERVICE-IMP-1": "Este archivo es demasiado grande.",
    "SERVICE-IMP-23": "Formato de archivo incorrecto.",
    "SERVICE-IMP-30": "El formato del fichero de importación es defectuoso (por ejemplo, número incorrecto de columnas).",
    "SERVICE-IMP-35": "Un nombre de grupo utilizado existe varias veces en ginlo Business Cockpit.",
    "SERVICE-IMP-36": "Un nombre de canal utilizado existe varias veces en ginlo Business Cockpit.",
    "SERVICE-NO_SERVER_RESPONSE": "No hay conexión con el servidor.",
    "SERVICE-RSSTRANS-CLI-0001": "El canal RSS de la URL solicitada no está disponible. Es posible que la URL esté bloqueada por la configuración del cortafuegos.",
    "SERVICE-RSSTRANS-ERR-0007": "El contenido de la URL indicada no cumple las especificaciones RSS 2.0. Se han encontrado caracteres no válidos.",
    "SERVICE-RSSTRANS-ERR-0008": "El contenido de la URL indicada no se ajusta a las especificaciones RSS 2.0. No se han rellenado todos los campos obligatorios.",
    "SERVICE-RSSTRANS-ERR-0011": "No se ha encontrado ningún canal RSS válido en la URL indicada.",
    "SERVICE-RSSTRANS-ERR-0012": "Se ha producido un error al cargar la vista previa del canal RSS. La URL solicitada no está disponible.",
    "SERVICE-RSSTRANS-ERR-0014": "Se ha encontrado un feed Atom en la URL indicada. Este formato no es compatible. Asegúrese de que se trata de un canal RSS conforme a RSS 2.0.",
    "SERVICE-RSSTRANS-ERR-0015": "No se ha encontrado contenido en la URL indicada.",
    "SERVICE-RSSTRANS-ERR-0016": "El formato del canal RSS no es compatible.",
    "SERVICE-RSSTRANS-ERR-0017": "El canal RSS contiene caracteres no válidos y no permitidos.",
    "cancel": "Cancelar",
    "common-delete-btn": "Borrar",
    "common-edit-btn": "Editar",
    "components-ModalDialog-Warning": "Nota",
    "components-ModalDialog-cancel": "Cancelar",
    "components-ModalDialog-looseDataQuestion": "Si cancela, los cambios no se guardarán. ¿Cancelar?",
    "components-ModalDialog-no": "No",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Sí",
    "components-NavigationLeft-appdesign": "Diseño de aplicaciones",
    "components-NavigationLeft-appsettings": "Configuración de la aplicación",
    "components-NavigationLeft-channel": "Canales",
    "components-NavigationLeft-dashboard": "Cuadro de mandos",
    "components-NavigationLeft-groups": "Grupos",
    "components-NavigationLeft-license": "Licencias",
    "components-NavigationLeft-roles": "Funciones de los usuarios",
    "components-NavigationLeft-user": "Usuarios",
    "components-chatmessagetextfield-default-hint": "Introduzca el texto del mensaje",
    "components-chatmessagetextfield-preview-time-placeholder": "Ahora",
    "components-continue": "Continúe en",
    "components-messagecomposer-datepicker": "Seleccione la fecha",
    "components-messagecomposer-messagesendtime-delayed": "En la fecha y hora siguientes",
    "components-messagecomposer-messagesendtime-now": "Enviar inmediatamente",
    "components-messagecomposer-send": "Enviar",
    "components-messagecomposer-send-AttachmentTooBig": "Este archivo es demasiado grande.",
    "components-messagecomposer-timepicker": "Seleccionar hora",
    "components-messagecomposer-uploadattachment": "Seleccionar imagen o archivo",
    "components-messageeditor-button-delete": "Borrar mensajes",
    "components-messageeditor-button-showolder": "Mostrar más antiguo",
    "components-messageeditor-confirmdeletemessage": "Todos los mensajes de este canal serán eliminados. ¿Borrar de todos modos?",
    "components-messageeditor-header-messages": "Mensajes en este canal",
    "components-messageeditor-header-newmessage": "Nuevo mensaje",
    "components-messageeditor-header-timedmessages": "En diferido",
    "components-messageeditor-rss-linkmore": "más …",
    "components-messageeditor-type-audio": "Audio",
    "components-messageeditor-type-location": "Ubicación",
    "components-messageeditor-type-video": "Vídeo",
    "components-reload-data": "Actualizar",
    "email": "Correo electrónico",
    "err-empty-textfield": "El campo no puede estar vacío.",
    "error": "Error",
    "firstName": "Nombre",
    "footer-link-details": "ginlo Manual",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/en/",
    "footer-link-faq": "PREGUNTAS FRECUENTES",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/en/faq/",
    "footer-link-impress": "Pie de imprenta",
    "footer-link-impress-url": "https://app-help.ginlo.net/en/imprint",
    "footer-link-press": "Pulse",
    "footer-link-press-url": "https://www.ginlo.net/en/about-us/",
    "footer-link-privacy": "Política de privacidad",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/en/privacy",
    "footer-link-security": "ginlo Datos",
    "footer-link-security-url": "https://www.ginlo.net/en/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Negocios",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/en/ginlo-business/",
    "footer-link-support": "Ayuda",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "Condiciones generales",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/en/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "EN",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Contraseña olvidada",
    "header-btn-fr": "FR",
    "header-btn-logout": "Cerrar sesión",
    "header-btn-settings": "Ajustes",
    "header-btn-support": "Ayuda",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Ejemplo de nombre de empresa",
    "lastName": "Apellido",
    "login-2fa-token-textfield-hint": "Pin de dos factores (si está activado)",
    "login-email-textfield-hint": "Dirección de correo electrónico",
    "login-forgot-pwd-button": "Establecer",
    "login-forgot-pwd-desc": "Por favor, introduzca su contraseña de recuperación.",
    "login-forgot-pwd-desc-min-char": "Al menos 8 caracteres",
    "login-forgot-pwd-desc-spez-char": "Al menos un número (0-9) o carácter especial",
    "login-forgot-pwd-desc-upper-char": "Minúsculas y mayúsculas (a-z, A-Z)",
    "login-forgot-pwd-err-empty": "Este campo es obligatorio.",
    "login-forgot-pwd-err-not-equal": "Las contraseñas no son idénticas.",
    "login-forgot-pwd-new-pwd-desc": "Por favor, elija una nueva contraseña.",
    "login-forgotPwd-button": "Contraseña olvidada",
    "login-initial-pwd-button": "Guardar",
    "login-initial-pwd-new-pwd-desc": "Elija su propia contraseña.",
    "login-login-button": "Inicio de sesión",
    "login-pwd-new-textfield-hint": "Introducir nueva contraseña",
    "login-pwd-repeat-textfield-hint": "Repetir nueva contraseña",
    "login-pwd-textfield-hint": "Introduzca la contraseña",
    "login-remember-email": "Recordar dirección de correo electrónico",
    "modules-appdesign-appdesign-btnResetLayout": "Restablecer el diseño de la aplicación",
    "modules-appdesign-appdesign-btncancel": "Cancelar",
    "modules-appdesign-appdesign-btnsave": "Guardar",
    "modules-appdesign-appdesign-header": "Diseño de aplicaciones",
    "modules-appdesign-colorpicker-actionelements": "Elementos de acción",
    "modules-appdesign-colorpicker-blue": "azul",
    "modules-appdesign-colorpicker-color": "Color",
    "modules-appdesign-colorpicker-contrast": "contraste",
    "modules-appdesign-colorpicker-contrastdark": "Color de contraste oscuro",
    "modules-appdesign-colorpicker-contrastlight": "Color de contraste claro",
    "modules-appdesign-colorpicker-dark": "oscuro",
    "modules-appdesign-colorpicker-green": "Verde",
    "modules-appdesign-colorpicker-heavytrust": "Alto nivel de confianza",
    "modules-appdesign-colorpicker-hex": "valor hexadecimal",
    "modules-appdesign-colorpicker-light": "luz",
    "modules-appdesign-colorpicker-maincolor": "Color principal",
    "modules-appdesign-colorpicker-normaltrust": "Estado de confianza moderado",
    "modules-appdesign-colorpicker-red": "Rojo",
    "modules-appdesign-colorpicker-unsafetrust": "Estado de confianza inseguro",
    "modules-appdesign-colorpicker-yellow": "Azul",
    "modules-appdesign-cropimage-accept-btn": "Solicitar",
    "modules-appdesign-cropimage-title": "Recorte su logotipo a la medida necesaria.",
    "modules-appdesign-cropimage-zoom-hint": "* Puedes ampliar o recortar la imagen utilizando la rueda de desplazamiento del botón del ratón.",
    "modules-appdesign-image-description": "*.png con alfa",
    "modules-appdesign-image-description2": "800 x 160 px recomendado",
    "modules-appdesign-mainView-description": "Adapte la aplicación a su identidad corporativa en unos pocos pasos.",
    "modules-appdesign-mainView-removeCompanyLogo": "Borrar imagen",
    "modules-appdesign-mainView-subheading": "Ajusta la aplicación a tus necesidades.",
    "modules-appdesign-mainView-uploadImage": "Seleccionar imagen",
    "modules-appsettings-appsettings-btnResetConfig": "Restablecer la configuración de la aplicación",
    "modules-appsettings-header": "Configuración de la aplicación",
    "modules-appsettings-hinttext-range1": "De 0 a 10 minutos",
    "modules-appsettings-hinttext-range2": "De 0 a 100 repeticiones",
    "modules-appsettings-hinttext-range3": "0 a 99 caracteres",
    "modules-appsettings-hinttext-range4": "De 0 a 365 días",
    "modules-appsettings-hinttext-range5": "De 0 a 4 clases",
    "modules-appsettings-label-chars": "Personajes",
    "modules-appsettings-label-days": "Días",
    "modules-appsettings-label-minutes": "Min.",
    "modules-appsettings-label-repeat": "Rpt.",
    "modules-appsettings-mainView-contactpolicy": "Directrices de contacto",
    "modules-appsettings-mainView-mediapolicy": "Directrices para los medios de comunicación",
    "modules-appsettings-mainView-pwcomplexity": "Complejidad de la contraseña",
    "modules-appsettings-mainView-pwpolicy": "Directrices sobre contraseñas",
    "modules-appsettings-selectfield-deleteaccount-inactive": "desactivar",
    "modules-appsettings-selectfield-deleteaccount-tries": "Intentos",
    "modules-appsettings-selectfield-deleteaccount-try": "Inténtelo",
    "modules-appsettings-table-companyindexname": "Nombre del directorio de empresas",
    "modules-appsettings-table-companyindexnamedescr": "Cambie aquí el nombre del directorio de la empresa que debe mostrarse a los usuarios en contactos.",
    "modules-appsettings-table-contactClosedUserGroup": "Grupo de usuarios restringido activado",
    "modules-appsettings-table-contactClosedUserGroupDescr": "Cuando esta función está activada, el usuario sólo puede comunicarse con contactos que hayan sido añadidos en ginlo Cockpit. Tampoco es posible chatear más allá de con los administradores.",
    "modules-appsettings-table-deletedatacount": "Borrar datos tras demasiados intentos fallidos",
    "modules-appsettings-table-deletedatacountdescr": "Introduzca el número de intentos de inicio de sesión permitidos antes de que el contenido de la aplicación se elimine automáticamente.",
    "modules-appsettings-table-disablebackup": "Evitar la copia de seguridad de aplicaciones en iCloud/Google Drive",
    "modules-appsettings-table-disablebackupdescr": "Con esta configuración, los usuarios no pueden hacer copias de seguridad en iCloud o Google Drive ni importar copias de seguridad de estos proveedores. Para la copia de seguridad de datos, se recomienda el acoplamiento a un segundo dispositivo.",
    "modules-appsettings-table-disablecamera": "Denegar el acceso a la cámara.",
    "modules-appsettings-table-disablecameradescr": "Cuando se activa esta función, un empleado ya no puede grabar ni enviar fotografías y vídeos desde la cámara.",
    "modules-appsettings-table-disablecopypaste": "Denegar el acceso a copiar / pegar",
    "modules-appsettings-table-disablecopypastedescr": "Cuando se activa esta función, un empleado ya no puede copiar texto y medios para pegarlos en chats o aplicaciones de terceros.",
    "modules-appsettings-table-disableexportchat": "Desactivar la exportación del contenido del chat como archivo de texto",
    "modules-appsettings-table-disableexportchatdescr": "Si activas esta opción, no podrás exportar chats individuales de la aplicación a un archivo de texto.",
    "modules-appsettings-table-disablefaceandtouchid": "Bloqueo de la activación mediante Touch/Face-ID",
    "modules-appsettings-table-disablefaceandtouchiddescr": "Si se selecciona esta opción, los usuarios no podrán utilizar Touch/Face ID (iPhone) o la huella dactilar (Android) para desbloquear la aplicación.",
    "modules-appsettings-table-disablelocation": "Denegar el acceso a los datos geográficos",
    "modules-appsettings-table-disablelocationdescr": "Cuando se activa esta función, un empleado ya no puede compartir datos de localización.",
    "modules-appsettings-table-disablemicrophone": "Denegar el acceso al micrófono",
    "modules-appsettings-table-disablemicrophonedescr": "Cuando se activa esta función, un empleado ya no puede enviar grabaciones de audio.",
    "modules-appsettings-table-disablepushpreview": "Evitar la vista previa en las notificaciones push",
    "modules-appsettings-table-disablepushpreviewdescr": "Con esta configuración, el contenido de los mensajes en dispositivos móviles no se mostrará en la vista previa push. Esto aumenta la seguridad, ya que el contenido solo puede leerse con la aplicación.",
    "modules-appsettings-table-disablerecoverycode": "Desactivar código de recuperación",
    "modules-appsettings-table-disablerecoverycodedescr": "Cuando esta función está activada, el usuario no puede utilizar la función 'olvido de contraseña'.",
    "modules-appsettings-table-disablesendmedia": "Desactivar el envío y reenvío de archivos multimedia",
    "modules-appsettings-table-disablesendmediadescr": "Cuando esta opción está activada, no se pueden enviar ni reenviar archivos adjuntos.",
    "modules-appsettings-table-disablesendvcard": "Denegar el acceso a los contactos",
    "modules-appsettings-table-disablesendvcarddescr": "Cuando se activa esta función, un empleado ya no puede enviar ni recibir contactos.",
    "modules-appsettings-table-disablesimsmerecovery": "Forzar código de recuperación a través del administrador",
    "modules-appsettings-table-disablesimsmerecoverydescr": "Cuando esta función está activada, los usuarios que olvidan su contraseña deben esperar la aprobación explícita del administrador antes de que se envíe un código de recuperación.",
    "modules-appsettings-table-forcePwComplexity": "Complejidad obligatoria de las contraseñas",
    "modules-appsettings-table-forcePwComplexityDescr": "Forzar la complejidad de la contraseña de la aplicación",
    "modules-appsettings-table-localmedia": "No permitir que los medios se guarden localmente",
    "modules-appsettings-table-localmediadescr": "Al activar esta opción se elimina la opción 'Guardar medios', lo que significa que las imágenes y los vídeos no se pueden guardar localmente.",
    "modules-appsettings-table-passwordmaxduration": "Validez de la contraseña en días",
    "modules-appsettings-table-passwordmaxdurationdescr": "Defina la duración de la validez de la contraseña en días, por ejemplo \"30 días\". 30 días significa que después de 30 días el usuario debe seleccionar una nueva contraseña.",
    "modules-appsettings-table-passwordminclasses": "Nivel mínimo de complejidad (cifras, caracteres especiales, minúsculas y mayúsculas)",
    "modules-appsettings-table-passwordminclassesdescr": "Este parámetro permite definir el número mínimo de clases de caracteres (cifras, caracteres especiales, minúsculas y mayúsculas) que deben utilizarse en la contraseña. 2 significa que la contraseña debe contener al menos dos clases (como dígitos + letras minúsculas o caracteres especiales + dígitos).",
    "modules-appsettings-table-passwordminlength": "Número mínimo de caracteres",
    "modules-appsettings-table-passwordminlengthdescr": "Este parámetro define la complejidad obligatoria de la contraseña basada en el número mínimo de caracteres. 0 caracteres significa que no hay longitud mínima.",
    "modules-appsettings-table-passwordminlowcase": "Número mínimo de minúsculas",
    "modules-appsettings-table-passwordminlowcasedescr": "Define cuántas letras minúsculas como 'abcde' deben utilizarse. 0 caracteres significa que no se requieren letras minúsculas.",
    "modules-appsettings-table-passwordminnumbers": "Número mínimo de dígitos",
    "modules-appsettings-table-passwordminnumbersdescr": "Defina cuántos dígitos, como por ejemplo '12345', deben utilizarse. 0 caracteres significa que no se requieren dígitos.",
    "modules-appsettings-table-passwordminspecialchars": "Número mínimo de caracteres especiales",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Defina cuántos caracteres especiales como '!§$%' deben utilizarse. 0 caracteres significa que no se requieren caracteres especiales.",
    "modules-appsettings-table-passwordminuppercase": "Número mínimo de mayúsculas",
    "modules-appsettings-table-passwordminuppercasedescr": "Defina cuántas letras mayúsculas como 'ABCDE' deben utilizarse. 0 caracteres significa que no se necesitan mayúsculas.",
    "modules-appsettings-table-pwinact": "Forzar comprobación de contraseña tras inactividad de …",
    "modules-appsettings-table-pwinactdescr": "Este parámetro permite definir el tiempo que la aplicación puede estar inactiva antes de que el usuario deba volver a introducir la contraseña.",
    "modules-appsettings-table-pwpolicy": "Forzar la comprobación de la contraseña al iniciar la aplicación",
    "modules-appsettings-table-pwpolicydescr": "Si esta función está activada, la aplicación solicitará la contraseña del usuario cada vez que se inicie.",
    "modules-appsettings-table-pwwdh": "Contraseña reutilizable tras X cambios",
    "modules-appsettings-table-pwwdhdescr": "Puede definir cuántas veces un usuario debe utilizar una nueva contraseña antes de poder reutilizar una contraseña anterior. 1 repetición significa que la contraseña se puede utilizar después de haber establecido 1 contraseña diferente en el ínterin.",
    "modules-appsettings-table-sharemedia": "Desactivar el uso compartido de archivos multimedia con otras aplicaciones",
    "modules-appsettings-table-sharemediadescr": "Cuando este ajuste está activado, las imágenes y los vídeos ya no se pueden guardar y los archivos no se pueden visualizar.",
    "modules-appsettings-table-simplecode": "Prohibir un código simple de 4 cifras como contraseña",
    "modules-appsettings-table-simplecodedescr": "Si esta opción está activada, los usuarios no podrán utilizar un simple código de 4 dígitos como contraseña. Para que sea necesario cambiar las contraseñas de 4 dígitos existentes, defina una complejidad de contraseña adecuada.",
    "modules-channel-commonbutton-btnUp": "a la cima",
    "modules-channels-Channel-channel": "Gestionar canales",
    "modules-channels-Channel-confirmDeleteChannel": "Estás seguro de que quieres borrar Channel?",
    "modules-channels-ChannelList-table-column1": "Nombre del canal",
    "modules-channels-NewChannel": "Crear un nuevo canal",
    "modules-channels-NewChannel-noMember": "No hay miembros del canal",
    "modules-channels-channel-btnDeleteChannel": "Borrar canal",
    "modules-channels-channel-tab-editChannel": "Editar canal",
    "modules-channels-channel-tab-messages": "Mensajes",
    "modules-channels-messages-senddateinvalid": "Elija una fecha futura.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Actividad",
    "modules-dashboard-AktivitatenChart-displayValue1": "Usuarios activos diarios",
    "modules-dashboard-AktivitatenChart-displayValue2": "Usuarios activos mensuales",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} mensajes",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Usuarios activos diarios",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Usuarios activos mensuales",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Número de usuarios que abren la aplicación diariamente",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Número de usuarios que abren la aplicación mensualmente",
    "modules-dashboard-AktivitatenChart-timeValue1": "Una semana",
    "modules-dashboard-AktivitatenChart-timeValue2": "Un mes",
    "modules-dashboard-AktivitatenChart-timeValue3": "Período total",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} usuarios",
    "modules-dashboard-ChannelChart-channels": "Canales",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Seleccione un canal.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Seleccione un grupo.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "aún no hay datos disponibles",
    "modules-dashboard-ChannelChart-popOver-text1": "Los 5 primeros son los mensajes en cuyos archivos, imágenes o enlaces han hecho clic con más frecuencia los usuarios.",
    "modules-dashboard-ChannelChart-table-column1": "Nombre",
    "modules-dashboard-ChannelChart-table-column2": "Usuarios",
    "modules-dashboard-ChannelChart-table-column3": "Mensajes",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "Grupos generados por los usuarios",
    "modules-dashboard-ChannelChart-table-showAll": "Mostrar todo …",
    "modules-dashboard-ChannelChart-table-title": "Las 5 mejores conversaciones",
    "modules-dashboard-ChannelChart-table-titleInfo": "Número de clics en el enlace",
    "modules-dashboard-ChannelChart-timeValue1": "Una semana",
    "modules-dashboard-ChannelChart-timeValue2": "Un mes",
    "modules-dashboard-Dashboard-dashboard": "Cuadro de mandos",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} de {{v2}} licencias están activas",
    "modules-dashboard-GruppenChart-groups": "Grupo administrado",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} mensajes",
    "modules-dashboard-GruppenChart-table-column1": "Nombre",
    "modules-dashboard-GruppenChart-table-column2": "Usuarios",
    "modules-dashboard-GruppenChart-table-column3": "Mensajes",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} mensajes en grupos generados por usuarios",
    "modules-dashboard-GruppenChart-table-showAll": "Mostrar todo …",
    "modules-dashboard-GruppenChart-table-title": "Mensajes",
    "modules-dashboard-GruppenChart-timeValue1": "Una semana",
    "modules-dashboard-GruppenChart-timeValue2": "Un mes",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Mezcla de mensajes",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "Una semana",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "Un mes",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Período total",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} total de mensajes",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} mensajes individuales",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} grupos",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} canales",
    "modules-group-group-identifier": "Grupo",
    "modules-group-groups-identifier": "Grupos",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Consulte",
    "modules-groups-GroupList-table-column1": "Nombre del grupo",
    "modules-groups-GroupList-table-column2": "Invitado",
    "modules-groups-GroupList-table-column3": "Se unió a",
    "modules-groups-GroupList-table-column4": "Destacado",
    "modules-groups-GroupList-table-column5": "Última actividad",
    "modules-groups-Groups-btnCancelNewGroup": "Cancelar",
    "modules-groups-Groups-btnCreateGroup": "Crear un nuevo grupo",
    "modules-groups-Groups-btnDeleteGroup": "Borrar grupo",
    "modules-groups-Groups-confirmDeleteGroup": "¿Estás seguro de que quieres borrar el grupo?",
    "modules-groups-Groups-groups": "Gestionar grupos",
    "modules-groups-NewGroup-btnImportImage": "Seleccionar imagen",
    "modules-groups-NewGroup-newgroup": "Crear un nuevo grupo",
    "modules-groups-NewGroup-noMember": "Ningún miembro del grupo",
    "modules-groups-NewGroup-placeholder-RSS-preview": "Vista previa RSS",
    "modules-groups-NewGroup-placeholder-feed": "Establecer una fuente RSS externa.",
    "modules-groups-NewGroup-placeholder-name": "Nombre",
    "modules-groups-NewGroup-searchHint": "Búsqueda por nombre, apellido o palabra clave",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Cerrar",
    "modules-license-DPAGOrderLicenseDialog-data-link": "datos",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "Datos",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) datos contractuales y de inventario, por ejemplo, empresa, direcciones, direcciones de correo electrónico o números de teléfono de contactos personales, e información de servicio al cliente, así como datos de pago y facturación, y",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) datos de tráfico, por ejemplo, información sobre la hora, la duración y el volumen de los mensajes enviados por los usuarios, así como los propios mensajes cifrados que se siguen transmitiendo.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "Más información en {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "aquí",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "http://sims.me/datenuebergang/business/en",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "Deseo garantizar a mis usuarios la continuidad sin problemas del messenger ginlo Business y, por ello, encargo a ginlo.net GmbH la prestación de los servicios ginlo Business para mis licencias existentes a partir de la fecha de expiración de las mismas en Deutsche Post AG. Para la prestación de los servicios, acepto la transferencia de mis datos y de {{v1}} de mis usuarios de Brabbler AG a ginlo.net GmbH. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "Por la presente ordeno",
    "modules-license-DPAGOrderLicenseDialog-order2": "nuevas licencias.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Política de privacidad",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "Las páginas {{v1}} y {{v2}} de ginlo.net GmbH se aplican a todas las licencias.",
    "modules-license-DPAGOrderLicenseDialog-text1": "SiMSme Business (ahora ginlo Business) tiene un nuevo propietario: ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH). Los pedidos de nuevas licencias sólo pueden realizarse a ginlo.net GmbH. ",
    "modules-license-License-btn-new": "Solicitar nueva licencia",
    "modules-license-License-btn-select": "Asignar licencia",
    "modules-license-License-delete-user-btn": "Borrar usuario",
    "modules-license-License-delete-user-text": "¿Estás seguro de que quieres eliminar el usuario, lo que bloqueará su acceso a la aplicación?",
    "modules-license-License-delete-user-title": "Borrar {{v1}}?",
    "modules-license-License-extend-header": "Renovar licencia",
    "modules-license-License-extend-text": "Asignar licencia a ",
    "modules-license-License-license": "Gestión de licencias",
    "modules-license-License-revoke-btn": "Revocar la licencia",
    "modules-license-License-revoke-text": "¿Está seguro de que desea revocar la licencia al usuario?",
    "modules-license-License-revoke-title": "¿Revocar la licencia de {{v1}}?",
    "modules-license-License-searchfield-hint": "Búsqueda por nombre, apellido o palabra clave",
    "modules-license-License-table-column-count": "Número",
    "modules-license-License-table-column-expireDate": "Válido hasta el",
    "modules-license-License-table-column-license": "Licencia",
    "modules-license-License-table-column-licenses": "Licencias",
    "modules-license-License-table-column-more-assign": "Asignar licencia",
    "modules-license-License-table-column-more-delete": "Borrar licencia",
    "modules-license-License-table-column-more-extend": "Renovar licencia",
    "modules-license-License-table-column-more-revoke": "Revocar la licencia",
    "modules-license-License-table-header-availableUser": "Usuarios disponibles",
    "modules-license-License-table-header-freeLicense": "Licencias disponibles",
    "modules-license-License-table-header-usedLicense": "Licencias activas",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/en/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/en/privacy",
    "modules-license-license-assignment-error": "No es posible la asignación. Sólo hay disponibles {{free}} licencia/s gratuita/s.",
    "modules-roles-NewRole-noMember": "No hay miembros",
    "modules-roles-NewRole-placeholder-name": "Nombre",
    "modules-roles-NewRole-searchHint": "Búsqueda por nombre, apellido o palabra clave",
    "modules-roles-Newrole-newrole": "Crear un nuevo rol",
    "modules-roles-RoleList-table-column1": "Nombre del rol",
    "modules-roles-RoleList-table-column2": "# Usuarios",
    "modules-roles-Roles-btnCreateRole": "Crear un nuevo rol",
    "modules-roles-Roles-btnDeleteRole": "Borrar rol",
    "modules-roles-Roles-confirmDeleteRole": "¿Está seguro de que desea eliminar el papel?",
    "modules-roles-Roles-roles": "Gestionar las funciones de los usuarios",
    "modules-settings-Admin-admin": "Representante",
    "modules-settings-Admin-btnActivateAdmin": "Activar",
    "modules-settings-Admin-btnCancelNewAdmin": "Cancelar",
    "modules-settings-Admin-btnCreateNewAdmin": "Crear un nuevo representante",
    "modules-settings-Admin-btnDeactivateAdmin": "Desactivar",
    "modules-settings-Admin-btnDeleteAdmin": "Borrar",
    "modules-settings-Admin-btnSaveNewAdmin": "Guardar",
    "modules-settings-Admin-confirmDeleteAdmin": "¿Seguro que quieres borrar al representante?",
    "modules-settings-Admin-search": "Introduzca el término de búsqueda",
    "modules-settings-Admin-setAdminEnabled": "¿Está seguro de que desea que {{adminName}} conceda todos los derechos a un representante? Esto incluye la creación, edición y eliminación de nuevos usuarios, grupos y canales.",
    "modules-settings-Admin-setAdminEnabled-title": "Se ha creado un representante",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Cancelar",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Activar",
    "modules-settings-AdminDetails-last-login": "Última conexión el",
    "modules-settings-AdminList-table-adminStatus1": "creado",
    "modules-settings-AdminList-table-adminStatus2": "desactivado",
    "modules-settings-AdminList-table-adminStatus3": "activo",
    "modules-settings-AdminList-table-adminStatus4": "suprimido",
    "modules-settings-AdminList-table-column1": "Apellido",
    "modules-settings-AdminList-table-column2": "Nombre",
    "modules-settings-AdminList-table-column3": "Correo electrónico",
    "modules-settings-AdminList-table-column4": "Número de teléfono móvil",
    "modules-settings-AdminList-table-column6": "Actividad",
    "modules-settings-AdminList-table-column7": "Último acceso",
    "modules-settings-AdminList-table-row-never-log-in": "nunca",
    "modules-settings-ApiCredentialsDialog-close-button": "Cerrar",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Ficha de autenticación",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "Huellas dactilares de certificados de usuario",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "URL de supervisión",
    "modules-settings-ApiCredentialsDialog-column-password": "Contraseña",
    "modules-settings-ApiCredentialsDialog-column-username": "Nombre de usuario",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Válido hasta el",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Caducado",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "Copiar URL",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "Puede comprobar la validez del certificado con la clave pública del archivo .CRT.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Descargar certificado .CRT",
    "modules-settings-ApiCredentialsDialog-edit-button": "Editar",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "Con esta URL, puede supervisar el estado de las importaciones utilizando su sistema de supervisión. No se transmitirá ningún dato personal durante este proceso. El formato de la solicitud se describe en la documentación de la API.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Enviar nuevo certificado",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Generar nueva URL",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Generar nueva contraseña",
    "modules-settings-ApiCredentialsDialog-title": "Información de acceso",
    "modules-settings-DeleteApiUserDialog-text": "Esto detendrá la sincronización automática y tu información de acceso y URL de monitorización serán eliminadas.",
    "modules-settings-DeleteApiUserDialog-title": "¿De verdad quieres desactivar la API?",
    "modules-settings-NewAdmin-hint": "Puede crear, editar y eliminar usuarios, grupos y canales. Sin embargo, el representante no puede crear otros representantes.",
    "modules-settings-NewAdmin-hint-title": "El representante recibe todos los derechos de administrador.",
    "modules-settings-NewAdmin-newadmin": "Crear un nuevo representante",
    "modules-user-AdminList-table-auditLog-active": "Activado el",
    "modules-user-AdminList-table-auditLog-created": "Creado el",
    "modules-user-AdminList-table-auditLog-deactive": "Desactivado el",
    "modules-user-AdminList-table-auditLog-delete": "Suprimido el",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Exporte todos los dispositivos utilizados a un archivo CSV.",
    "modules-user-ChooseCSVExport-title": "Seleccione qué datos exportar",
    "modules-user-ChooseTemplate-csv-detail": "CSV simplificado para crear usuarios una sola vez",
    "modules-user-ChooseTemplate-ldap-detail": "CSV ampliado para actualización LDAP continua y asignaciones automáticas en grupos y canales",
    "modules-user-ChooseTemplate-title": "Descargar una plantilla de importación",
    "modules-user-Keyword-deleteKeyword": "¿Realmente desea eliminar la palabra clave {{keyword}} y todas las instancias en las que se utiliza?",
    "modules-user-Keyword-jumpToUser": "¿Desea descartar los cambios actuales y pasar a {{displayName}}?",
    "modules-user-Keyword-searchHint": "Buscar por palabra clave",
    "modules-user-Keyword-showUsage": "Mostrar",
    "modules-user-Keyword-table-column1": "Palabra clave",
    "modules-user-Keyword-table-column2": "Solicitar",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Cancelar",
    "modules-user-NewUser-btnNewUserSave": "Guardar",
    "modules-user-NewUser-keyword": "Palabra clave",
    "modules-user-NewUser-keywordEdit": "Editar palabras clave",
    "modules-user-NewUser-keywordEditEnd": "Cerrar editor",
    "modules-user-NewUser-newuser": "Crear datos de usuario",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "import_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "¿Desea asignar una licencia?",
    "modules-user-User-assignVoucher-title": "{{displayName}} fue creado.",
    "modules-user-User-assignVoucherBtnCancel": "Asignar más tarde ",
    "modules-user-User-assignVoucherBtnOk": "Asignar licencia",
    "modules-user-User-assignVouchersByImportID": "¿Desea asignar automáticamente una licencia a todos los usuarios importados?",
    "modules-user-User-assignVouchersByImportID-title": "Usuarios importados con éxito: {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Asignar licencias",
    "modules-user-User-btnCancelNewUser": "Cancelar",
    "modules-user-User-btnCreateNewuser": "Crear datos de usuario",
    "modules-user-User-btnDeleteUser": "Borrar usuario",
    "modules-user-User-btnImportUser": "Importar datos de usuario",
    "modules-user-User-btnImportVorlage": "Importación y exportación de CSV",
    "modules-user-User-btnRevokeLicence": "Licencia de bloqueo",
    "modules-user-User-btnSaveNewUser": "Guardar",
    "modules-user-User-btnSaveUser": "Guardar cambios",
    "modules-user-User-confirmDeleteUser": "¿Está seguro de que desea eliminar el usuario?",
    "modules-user-User-confirmRevokeLicense": "¿Estás seguro de que quieres bloquear la licencia del usuario, lo que bloqueará su acceso a la aplicación?",
    "modules-user-User-email-hinttext": "correo electrónico",
    "modules-user-User-importLdap-applyData": "Continúe en",
    "modules-user-User-importLdap-cancel": "Cancelar",
    "modules-user-User-importLdap-done": "Cerrar",
    "modules-user-User-importLdap-exportApplyData": "Informe de exportación e importación",
    "modules-user-User-importLdap-exportPreviewData": "Exportar informe",
    "modules-user-User-importLdap-state-applyData": "Se están procesando datos",
    "modules-user-User-importLdap-state-done": "Cambios adoptados",
    "modules-user-User-importLdap-state-fatal": "La acción se canceló debido a un error.",
    "modules-user-User-importLdap-state-preflight": "Se están revisando los datos.",
    "modules-user-User-importLdap-state-waitConfirm": "Revisión completa",
    "modules-user-User-importLdap-table-column1": "Categoría",
    "modules-user-User-importLdap-table-column2": "Número de entradas",
    "modules-user-User-importLdap-table-column3": "Notas",
    "modules-user-User-importLdap-table-column4": "Advertencias",
    "modules-user-User-importLdap-table-column5": "Errores",
    "modules-user-User-importLdap-table-row1": "Usuarios",
    "modules-user-User-importLdap-table-row2": "Grupos",
    "modules-user-User-importLdap-table-row3": "Canales",
    "modules-user-User-importLdap-table-row4": "Palabras clave",
    "modules-user-User-search": "Introduzca el término de búsqueda",
    "modules-user-User-user": "Administración de usuarios ({{v1}})",
    "modules-user-UserList-popover-phone": "Introduzca los números de móvil en el formato \"012345678910\". Para los números de fuera de Alemania, incluya el prefijo del país, pero no el \"0\", es decir, \"+4312345678910\".",
    "modules-user-UserList-table-cmdDeleteUser": "Borrar usuario",
    "modules-user-UserList-table-cmdEdit": "Editar",
    "modules-user-UserList-table-cmdRevokeLicense": "Licencia de bloqueo",
    "modules-user-UserList-table-column1": "Apellido",
    "modules-user-UserList-table-column2": "Nombre",
    "modules-user-UserList-table-column3": "Correo electrónico",
    "modules-user-UserList-table-column4": "Número de móvil",
    "modules-user-UserList-table-column6": "Palabra clave",
    "modules-user-UserList-table-placeHolder-column1": "Apellido",
    "modules-user-UserList-table-placeHolder-column2": "Nombre",
    "modules-user-UserList-table-placeHolder-column3": "Correo electrónico",
    "modules-user-UserList-table-placeHolder-column4": "Número de móvil",
    "modules-user-UserList-table-placeHolder-column6": "Palabra clave",
    "modules-user-UserList-table-placeHolder-column8": "Departamento",
    "modules-user-UserList-table-roomWriterHeader": "Acceso de escritura",
    "modules-user-UserList-table-userStatus1": "Usuario creado",
    "modules-user-UserList-table-userStatus2": "Licencia asignada",
    "modules-user-UserList-table-userStatus3": "Licencia activa",
    "modules-user-UserList-table-userStatus4": "Licencia caducada",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Licencia caducada - usuario bloqueado",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Licencia revocada - usuario bloqueado",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Licencia activa - administración aceptada",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "Validación de correo electrónico cancelada",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "Validación de correo electrónico pendiente",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Anulación de la validación del número de teléfono móvil",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "Pendiente de validación del número de teléfono móvil",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Cuenta eliminada por el usuario",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Consulta administrativa rechazada",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "Usuario creado - sin licencia asignada",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Licencia asignada - pendiente de administración ",
    "modules-user-UserList-userCreated": "Se ha creado correctamente el usuario {{firstName}} {{lastName}}.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Asignar licencia",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Asignar licencia más tarde",
    "modules-user-alert-btnSendManagementRequest": "Reenviar solicitud",
    "modules-user-alert-sendManagementRequestHint": "El usuario ha rechazado la solicitud de permisos administrativos.",
    "modules-user-device-deviceList": "Dispositivos en uso",
    "modules-user-device-unknown-devicename": "Nombre de dispositivo desconocido",
    "modules-user-managementrequest-messagesent": "La solicitud para gestionar la aplicación ha sido enviada al usuario.",
    "modules-user-password-btnSendRecoveryEMail": "Enviar código de recuperación",
    "modules-user-password-btnShowRecoveryCode": "Mostrar código de recuperación",
    "modules-user-password-revoverPasswordHint": "Este usuario ha olvidado la contraseña de su dispositivo. Envíale el código de recuperación.",
    "modules-user-recover-bottomBarText": "El usuario ha olvidado su contraseña.",
    "modules-user-recoverycode-messagesent": "El código de recuperación está en camino para el usuario. Encontrará una copia del correo electrónico en su bandeja de entrada.",
    "modules-user-recoverycode-title": "Código de recuperación",
    "okay": "Vale",
    "phone": "Número de móvil",
    "save": "Guardar",
    "secret": "Secreto",
    "settings-admin": "Administrador",
    "settings-admin-change-pwd": "Cambiar contraseña",
    "settings-btnDeleteCompany": "Eliminar cuenta",
    "settings-company-label-ustIdNr": "Número de IVA",
    "settings-companycockpittoken": "Implantación de EMM",
    "settings-companycockpittoken-documentation-link": "Documentación",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/en/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "Si su organización utiliza una solución de gestión de la movilidad empresarial, puede activar el registro automático para la implantación.",
    "settings-companycockpittoken-switch-enable-label": "Registro automático de EMM",
    "settings-companycockpittoken-switch-force-desc": "Si esta función no está activada, el registro del mensajero se realizará de forma totalmente automática. Tenga en cuenta las instrucciones de {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Imponer el registro rápido en el terminal",
    "settings-companycockpittoken-token-desc": "Necesita el token de seguridad para el registro automático de EMM. Consulte {{v1}}.",
    "settings-companycockpittoken-token-label": "La ficha de seguridad es: ",
    "settings-companycockpittoken-token-notset": "Registro automático no activado",
    "settings-companyinformation": "Datos de la empresa",
    "settings-companylanguage": "Ajustes de idioma",
    "settings-companylanguage-de": "Alemán",
    "settings-companylanguage-desc": "Idioma de su empresa cuando envíe invitaciones por correo electrónico y otras comunicaciones.",
    "settings-companylanguage-el": "Griego",
    "settings-companylanguage-en": "Inglés",
    "settings-companylanguage-es": "Español",
    "settings-companylanguage-fr": "Francés",
    "settings-companyprofilimage": "Foto de perfil de la empresa",
    "settings-companyprofilimage-btnImportImage": "Selecciona la imagen.",
    "settings-companyprofilimage-desc": "Selecciona una foto de perfil para que aparezca en los canales junto a las notificaciones.",
    "settings-companyprofilimage-desc2": "Recomendación de tamaño 200 x 200 píxeles.",
    "settings-confirmdeletecompanydialog-desc": "Al eliminar su cuenta, se borrarán todas las cuentas de usuario y se bloquearán las licencias correspondientes.",
    "settings-confirmdeletecompanydialog-hint": "Introduzca la contraseña",
    "settings-confirmdeletecompanydialog-ok": "Eliminar la cuenta de forma irrevocable",
    "settings-confirmdeletecompanydialog-title": "¿Seguro que quieres eliminar tu cuenta?",
    "settings-ldap-sync-delete-api-user-button": "Desactivar API",
    "settings-ldap-sync-descr-text": "La API de ginlo es un servicio RESTful que puedes utilizar para automatizar la creación, edición y eliminación de usuarios, grupos y canales.",
    "settings-ldap-sync-documentation-link-pretext": "Encontrará la dirección {{v1}} correspondiente aquí (sólo en alemán).",
    "settings-ldap-sync-documentation-link-text": "Documentación",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/en/cockpit/index.html#ldap-data",
    "settings-ldap-sync-ldap-activated": "API activada",
    "settings-ldap-sync-ldap-activated-hint": "Es posible que los cambios manuales se sobrescriban.",
    "settings-ldap-sync-new-api-user-button": "Activar API",
    "settings-ldap-sync-show-api-user-credentials-button": "Información de acceso",
    "settings-ldap-sync_title": "API empresarial de ginlo",
    "settings-order-in-year": "€ anuales",
    "settings-order-input-hint": "De 0 a 500 licencias",
    "settings-order-tax-info": "más IVA aplicable",
    "settings-orderhistory": "Historial de pedidos",
    "settings-orderhistory-agb": "Sí, estoy de acuerdo con {{v1}}.",
    "settings-orderhistory-agb-link": "Condiciones generales",
    "settings-orderhistory-license": "Licencia",
    "settings-orderhistory-licenses": "Licencias",
    "settings-orderhistory-order": "Pedir ahora (encuadernación)",
    "settings-orderhistory-privacy": "Sí, he leído y comprendido {{v1}}.",
    "settings-orderhistory-privacy-link": "Política de privacidad",
    "settings-orderhistory-table-column-date": "Fecha",
    "settings-primarydomain": "Dominio principal",
    "settings-pwd-current-hint": "Introduzca la contraseña actual",
    "settings-save-error": "Se ha producido el siguiente error al guardar los datos:",
    "settings-settings": "Ajustes",
    "settings-stellvertreter": "Representante",
    "settings-stellvertreter-btn-edit": "Editar representante",
    "settings-stellvertreter-details": "{{activeAdminCount}} activo {{adminCount}} asignado",
    "settings-stellvertreter-last-login-never": "Aún no ha iniciado sesión",
    "settings-stellvertreter-last-login-ok": "Último acceso ",
    "settings-two-fa": "Autenticación de dos factores",
    "settings-two-fa-btn-show-recovery-codes": "Mostrar códigos de recuperación",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Aceptar códigos de recuperación",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Copie estos códigos de recuperación",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "Sus códigos de recuperación",
    "settings-two-fa-create-recovery-codes-dialog-title": "Sus códigos de recuperación",
    "settings-two-fa-disable-dialog-btn-submit": "Desactivar",
    "settings-two-fa-disable-dialog-description": "¿Realmente desea desactivar la autenticación de dos factores?",
    "settings-two-fa-disable-dialog-title": "Desactivación de la autenticación de dos factores",
    "settings-two-fa-disabled": "2FA está desactivado",
    "settings-two-fa-enable-dialog-btn-submit": "Enviar token y activar 2FA",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Activación de la autenticación de dos factores",
    "settings-two-fa-enable-dialog-token-help-text": "Escanee el código QR o introdúzcalo manualmente en su aplicación de autenticación.",
    "settings-two-fa-enable-dialog-token-not-valid": "El token no es válido",
    "settings-two-fa-enabled": "2FA está activado",
    "settings-two-fa-label": "Autenticación de dos factores es:",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Por favor, copie estos Códigos de Recuperación. Estos le proporciona un inicio de sesión en la cabina cuando se pierde su secreto.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Códigos de recuperación",
    "settings-two-fa-show-recovery-codes-dialog-title": "Sus códigos de recuperación",
    "state": "Estado del usuario",
    "token": "Ficha",
    "validation-errormessages-maxvalue": "máximo permitido {{max}}",
    "validation-errormessages-minvalue": "mínimo requerido {{min}}",
    "welcome-channel-btn": "Crear canales",
    "welcome-channel-description": "Los canales le permiten enviar notificaciones push a sus usuarios. Los mensajes, imágenes y archivos pueden distribuirse de forma centralizada a sus usuarios. Puede revisar las cifras de conversión en el panel de control después.",
    "welcome-channel-subheadline-create": "Crear canales",
    "welcome-chat-subheadline": "Simplificar la comunicación",
    "welcome-config-subheadline": "Configurar la aplicación",
    "welcome-design-btn": "Modificar el diseño de la aplicación",
    "welcome-design-description": "Aquí tienes la opción de modificar el diseño de la aplicación para adaptarlo a las directrices de diseño corporativo de tu empresa. Puedes cambiar el logotipo y los colores en cualquier momento.",
    "welcome-design-subheadline-create": "Personalizar el diseño de la aplicación",
    "welcome-group-btn": "Crear grupos",
    "welcome-group-description": "Crea grupos para facilitar la colaboración entre usuarios. La actividad de los grupos puede verse en el panel de control.",
    "welcome-group-subheadline-add": "Crear grupos",
    "welcome-headline": "Bienvenido {{v1}}",
    "welcome-intro": "ginlo Business Cockpit proporciona una cómoda herramienta para configurar el seguro ginlo Business Messenger para Android y iPhone de forma que se adapte a sus necesidades.",
    "welcome-license-btn": "Asignar licencia",
    "welcome-license-description": "Asigne las licencias disponibles a las cuentas de usuario creadas. Si no tiene suficientes licencias disponibles, {{v1}} la cantidad deseada para cerrar la brecha.",
    "welcome-license-description-link": "pedir",
    "welcome-license-subheadline-add": "Asignar licencia",
    "welcome-settings-btn": "Modificar la configuración",
    "welcome-settings-description": "Personalice los requisitos de seguridad y la configuración de la aplicación para cumplir sus especificaciones de seguridad.",
    "welcome-settings-subheadline-create": "Configurar los ajustes de la aplicación",
    "welcome-user-btn": "Crear un nuevo usuario",
    "welcome-user-description": "Se pueden crear nuevas cuentas de usuario de forma rápida y sencilla. Utilice la función \"crear usuario único\" o importe los datos de los usuarios utilizando la plantilla CSV.",
    "welcome-user-subheadline": "Gestionar usuarios",
    "welcome-user-subheadline-create": "Crear usuario"
}
;
    
    export default TranslationCommonEs;
    
