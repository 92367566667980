import { REQUEST_GET_STATISTIC, RECEIVE_GET_STATISTIC, REQUEST_GET_GROUP_STATISTIC, RECEIVE_GET_GROUP_STATISTIC, REQUEST_GET_GROUP_STATISTIC_DETAILS, RECEIVE_GET_GROUP_STATISTIC_DETAILS, REQUEST_GET_GROUPCHANNEL_STATISTIC, RECEIVE_GET_GROUPCHANNEL_STATISTIC, REQUEST_GET_GROUPCHANNEL_STATISTIC_DETAILS, RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS } from '../actions/index';


export function statistics(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_GET_STATISTIC: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case RECEIVE_GET_STATISTIC: {

      let data = action.result['data']
      let dataActivities = data['activities']
      let dataDays = dataActivities['days']
      var dataDailyActiveAll = []
      var dataMonthlyActiveAll = []
      var dataMessagesAll = []

      for (var dayIdx in dataDays) {

        let day = dataDays[dayIdx]
        let date = new Date().parseUtcDate(day['day'])
        let dateStr = date.toLocaleDateString(navigator.userLanguage, {
          month: '2-digit',
          day: '2-digit'
        })

        let dataDailyActive = {
          date: date,
          dateStr: dateStr,
          users: day['usersActiveDaily'],
          messages: day['msgAll']
        }
        let dataMonthlyActive = {
          date: date,
          dateStr: dateStr,
          users: day['usersActiveMonthly'],
          messages: day['msgAll']
        }
        let dataMessages = {
          date: date,
          dateStr: dateStr,
          messages: day['msgAll'],
          messagesSingle: day['msgSingle'],
          messagesGroup: day['msgGroup'],
          messagesChannel: day['msgChannel']
        }

        dataDailyActiveAll.push(dataDailyActive)
        dataMonthlyActiveAll.push(dataMonthlyActive)
        dataMessagesAll.push(dataMessages)
      }

      var dataDailyActiveWeek = dataDailyActiveAll.slice(-7)
      var dataMonthlyActiveWeek = dataMonthlyActiveAll.slice(-7)
      var dataMessagesWeek = dataMessagesAll.slice(-7)

      var dataDailyActiveMonth = dataDailyActiveAll.slice(-31)
      var dataMonthlyActiveMonth = dataMonthlyActiveAll.slice(-31)
      var dataMessagesMonth = dataMessagesAll.slice(-31)

      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: {
          usersAlliOS: dataActivities['usersAlliOS'],
          usersAllAndroid: dataActivities['usersAllAndroid'],
          usedVoucher: dataActivities['usedVoucher'],
          allVoucher: dataActivities['allVoucher'],
          dataDailyActiveWeek: dataDailyActiveWeek,
          dataDailyActiveMonth: dataDailyActiveMonth,
          dataDailyActiveAll: dataDailyActiveAll,
          dataMonthlyActiveWeek: dataMonthlyActiveWeek,
          dataMonthlyActiveMonth: dataMonthlyActiveMonth,
          dataMonthlyActiveAll: dataMonthlyActiveAll,
          dataMessagesWeek: dataMessagesWeek,
          dataMessagesMonth: dataMessagesMonth,
          dataMessagesAll: dataMessagesAll,
        },
        hasData: true
      })
    }
    default:
      return state;

  }
}

export function statisticsGroup(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_GET_GROUP_STATISTIC: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case RECEIVE_GET_GROUP_STATISTIC: {

      let data = action.result['data']
      let dataGroups = data['groups']
      var dataAll = []

      // var countGroups = 0

      for (var groupIdx in dataGroups) {

        let group = dataGroups[groupIdx]
        let groupInfo = {
          guid: group['guid'],
          name: group['name'],
          userCount: group['userCount'],
          messageCountWeek: group['messageCountWeek'],
          messageCountMonth: group['messageCountMonth']
        }


        dataAll.push(groupInfo)
      }



      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: {
          dataAll: dataAll,
          dataUserDefined: data["groupUserdefined"]
        },
        hasData: true
      })
    }
    default:
      return state;

  }
}

export function statisticsGroupDetails(state = {
    data: {},
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_GET_GROUP_STATISTIC_DETAILS: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case RECEIVE_GET_GROUP_STATISTIC_DETAILS: {

      let data = action.result['data']
      let dataGroup = data['group']
      let dataDetails = dataGroup['messageCount']
      let groupGuid = dataGroup['guid']
      var dataMessageCountWeek = []
      var dataMessageCountMonth = []

      var countWeek = -1 * (dataDetails.length - 7)
      for (var dayIdx in dataDetails) {

        let detailsDay = dataDetails[dayIdx]
        let date = new Date().parseUtcDate(detailsDay['day'])
        let dateStr = date.toLocaleDateString(navigator.userLanguage, {
          month: '2-digit',
          day: '2-digit'
        })

        let dataMessageCount = {
          date: date,
          dateStr: dateStr,
          messageCount: detailsDay['messageCount']
        }

        //Bug 44506
        // >=(!) 0, bei > 0 haben wir in den Details für die Woche nur 6 Tage, stat 7
        if (countWeek >= 0)
        {
          dataMessageCountWeek.push(dataMessageCount)
        }
        countWeek++

        dataMessageCountMonth.push(dataMessageCount)
      }

      var dataAll = state.data

      dataAll[groupGuid] = {
        dataWeek: dataMessageCountWeek,
        dataMonth: dataMessageCountMonth
      }

      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: dataAll,
        hasData: true
      })
    }
    default:
      return state;
  }
}

export function statisticsGroupChannel(state = {
    data: [],
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_GET_GROUPCHANNEL_STATISTIC: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case RECEIVE_GET_GROUPCHANNEL_STATISTIC: {

      let data = action.result['data']
      let dataGroups = data['groupChannels']
      var dataTopTen = []
      var dataAll = []

      var countGroups = 0


      for (var groupIdx in dataGroups) {

        let group = dataGroups[groupIdx]
        let groupInfo = {
          guid: group['guid'],
          name: group['name'],
          userCount: group['userCount'],
          messageCountWeek: group['messageCountWeek'],
          messageCountMonth: group['messageCountMonth']
        }

        if (countGroups < 10)
        {
          dataTopTen.push(groupInfo)
        }
        countGroups++

        dataAll.push(groupInfo)
      }

      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: {
          dataTopTen: dataTopTen,
          dataAll: dataAll
        },
        hasData: true
      })
    }
    default:
      return state;

  }
}

export function statisticsGroupChannelDetails(state = {
    data: {},
    error: {},
    isFetching: false,
    hasData: false
  }, action) {
  switch (action.type) {
    case REQUEST_GET_GROUPCHANNEL_STATISTIC_DETAILS: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case RECEIVE_GET_GROUPCHANNEL_STATISTIC_DETAILS: {

      let data = action.result['data']
      let dataGroup = data['groupChannel']
      let dataDetails = dataGroup['messageCount']
      let groupGuid = dataGroup['guid']
      var dataMessageCountWeek = []
      var dataMessageCountMonth = []

      var countWeek = -1 * (dataDetails.length - 7)
      for (var dayIdx in dataDetails) {

        let detailsDay = dataDetails[dayIdx]
        let date = new Date().parseUtcDate(detailsDay['day'])
        let dateStr = date.toLocaleDateString(navigator.userLanguage, {
          month: '2-digit',
          day: '2-digit'
        })

        let dataMessageCount = {
          date: date,
          dateStr: dateStr,
          messageCount: detailsDay['messageCount']
        }

        //Bug 44506
        // >=(!) 0, bei > 0 haben wir in den Details für die Woche nur 6 Tage, stat 7
        if (countWeek >= 0)
        {
          dataMessageCountWeek.push(dataMessageCount)
        }
        countWeek++

        dataMessageCountMonth.push(dataMessageCount)
      }

      var dataAll = state.data

      dataAll[groupGuid] = {
        // messages: dataGroup.messages
        dataWeek: dataMessageCountWeek,
        dataMonth: dataMessageCountMonth
      }

      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        data: dataAll,
        hasData: true
      })
    }
    default:
      return state;
  }
}
