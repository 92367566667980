/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
// import { connect, compose } from 'react-redux';

import TextField from '../../components/textfields/TextField';
// import Snackbar from 'material-ui/Snackbar';
import Popover from 'react-simple-popover';

import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import { TableHeaderColumnSort } from '../../components/table/TableCells'

import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';

import FlatButton from '../../components/buttons/FlatButton';
import RaisedButton from '../../components/buttons/RaisedButton';
import IconButton from 'material-ui/IconButton';

import SplitStringView from '../../components/SplitStringView';

import { WaitDialog } from '../../components/WaitDialog';
import { ModalDialog } from '../../components/ModalDialog';
import { CustomModalDialog } from '../../components/CustomModalDialog';
import { AlertContentPage } from './AlertContentPage';

import DataProvider from './DataProvider';

import IconSearch from '../../ressources/icon_search';
// import IconTabelleNotActivated from '../../ressources/tabelle_not_activated';
import IconInfo from '../../ressources/icon_info';
import DeviceAndroid from '../../ressources/device_android.js';
import DeviceIPhone from '../../ressources/device_iphone.js';
import DeviceComputer from '../../ressources/device_computer.js';
import DeviceSmartphone from '../../ressources/device_smartphone.js';
import IconFingerprint from '../../ressources/fingerprint.js';

import { showRecoveryKey, sendRecoveryPasswordMail, sendResetCompanyUserState } from '../../actions/index';

import { IsNullOrEmpty } from '../../components/StringExtensions';
import { COLOR_PRIMARY_1 } from '../../util/Constants.js';

require('../../components/StringExtensions');

const styles = {
  root: {
    width: 'fill',
    height: 'fill',
    display: 'flex',

  },
  newUserDiv: {
    minWidth: '470px',
    width: '450px',
    flex: '0 1 auto',
    overflowY: 'auto'
  },
  spacerDiv: {
    minWidth: '60px',
    width: '60px',
    flex: '0 1 auto',
    overflowY: 'none',
    background: 'linear-gradient(to right,#00375D, #00375D)',

  },
  keywordsDiv: {
    minWidth: '250px',
    flex: '1 1 auto',
    margin: '10px',
    height: 'fill'
  },
  keywordsHeaderDiv: {
    display: 'block',
    width: 'fill',
    height: '30px'
  },
  greyText: {
    color: '#7B98AB',
    float: 'left',
    paddingTop: '10px'
  },
  searchText: {
    color: '#7B98AB',
    float: 'left',
    paddingTop: '0px',
    height: '30px'
  },
  right: {
    float: 'right',
    top: '0px',
    height: '30px'
  },
  keywordListDiv: {
    display: 'block',

  },
  keywordList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    overflowY: 'auto'

  },
  chip: {
    margin: '4px',
    maxWidth: '100%'
  },
  button: {
    margin: '5px'
  },
  editFieldDiv: {
    width: 'fill',
    display: 'flex',
    paddingRight: '20px',
    paddingLeft: '20px'

  },
  editField: {
    flex: '1 1 auto',
    width: 'fill',
  },
  editFieldFirstName: {
    flex: '1 1 auto',
    width: 'fill'
  },
  editFieldLastName: {
    flex: '1 1 auto',
    width: 'fill',
    marginLeft: '10px'
  },
  buttonNewUserCancel: {
    flex: '1 1 auto',
    width: 'fill'
  },
  selectedKeywordsDiv: {
    width: 'fill',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '16px',
    marginLeft: '16px',
    paddingBottom: '5px'
  },
  deviceSectionDiv: {
    width: 'fill',
    marginTop: '45px',
    marginLeft: '20px',
  },
  smallText: {
    color: '#FFFFFF',
    margin: '4px',
    lineHeight: '32px'
  },
  greyLabelSpan: {
    color: '#7B98AB',
    fontSize: '16px',
    marginTop: '14px' //gleiche margin hoehe wie texteingabe felder
  },
  greenLabelSpan: {
    color: COLOR_PRIMARY_1,
    fontSize: '16px',
    marginTop: '14px' //gleiche margin hoehe wie texteingabe felder
  },
  fingerPrintImageSpan: {
    marginTop: '12px',
    marginLeft: '6px',
    marginRight: '3px'
  },
  iconDownload: {
    width: "24psx",
    height: "24px",
    padding: 0,
  },
  greyDeviceLabelSpan: {
    color: '#7B98AB',
    fontSize: '16px',
    lineHeight: '30px'
  },
  deviceListDiv: {
    marginTop: '15px',
  },
  deviceEntryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  deviceLabelDiv: {
    marginLeft: '20px',
  },
  whiteBoldDeviceLabel: {
    color: '#FFFFFF',
    fontStyle: 'bold',
    fontSize: '12px',
    lineHeight: '16px'
  },
  greyDeviceSubLabel: {
    color: '#7B98AB',
    fontSize: '10px',
    lineHeight: '16px'
  },
  editKeywordField: {
    width: '60px',
    fontSize: '10pt',
    marginTop: '0px',
    height: '30px'
  },
  emptyKeyword: {
    color: '#7B98AB'
  },
  listUser: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  listUserDiv: {
  },
  listUserCountDiv: {
    paddingLeft: '16px'
  },
  listUserCell: {
    paddingLeft: '0px'
  },
  listKeywordCell: {
    verticalAlign: 'top'
  },
  keywordUsageTable: {
    marginTop: '20px'
  },
  passwordRecoveryHint: {
    color: '#FFFFFF',
    maxWidth: "320px",
    display: 'inline-block',
    wordWrap: 'break-word',
  },
  clearStyle: {
    clear: 'both',
  },
  verticalContentSpacing: {
    marginTop: '20px',
  },
  leftMargin: {
    marginLeft: '10px',
  },
  floatingIcon: {
    float: "left",
    margin: 12,
  },
  bottomHintBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '240px',
    backgroundColor: '#FFFFFF',
  },
  infoButton: {
    backgroundColor: '#FFFFFF',
  },
  popupText: {
    color: '#333333',
    fontSize: '12px',
    fontWeight: 'medium',
  },
  fakeChipEditMode: {
    backgroundColor: "#6C8392",
    borderRadius: "16px",
    border: "10px",
    margin: "4px",
    maxHeight: 32,
    width: "fit-content",
    paddingLeft: 10,
    paddingRight: 4,
  },
  addKeywordButtonRight: {
    backgroundColor: "#FFFFFF",
    color: "#6C8392",
    fontSize: "18px",
    fontWeight: "600",
    margin: "4px 4px 0 0",
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },
  addKeywordButtonLeft: {
    backgroundColor: "#FFFFFF",
    color: "#6C8392",
    fontSize: "18px",
    fontWeight: "600",
    margin: "4px 0 0 4px",
    height: "24px",
    width: "24px"
  }
};

const NEW_USER = 1;
const EDIT_USER = 2;
const SHOW_KEYWORD = 3;
const EDIT_KEYWORD = 4;
const REQUEST_PASSWORD = 5;
const SHOW_PASSWORD = 6;


class UserDetail extends Component {

  constructor(props) {
    super(props);
    this.dataprovider = new DataProvider();

    var userData = props.user;
    if (typeof userData === 'undefined') {
      userData = {
        name: '',
        firstName: '',
        eMailAdress: '',
        phoneNumber: '',
        keywords: []
      }
    }

    this.state = {
      visible: true,
      userData: userData,
      editKeyword: false,
      viewMode: props.viewMode,
      keywordViewMode: SHOW_KEYWORD,
      sortColumn: 1,
      sortDirection: 0,
      tabledata: this.dataprovider.getKeywordSorted(1, 0),
      expandedKeyword: null,
      searchText: '',
      passwordViewMode: REQUEST_PASSWORD,
      recoveryPassword: '',
      showPhoneInfoBox: false,
    }
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleKeywordTouchTap = this.handleKeywordTouchTap.bind(this);

    this.loadUser = this.loadUser.bind(this);

    this.beginEditKeyword = this.beginEditKeyword.bind(this);
    this.handleEditKeyword = this.handleEditKeyword.bind(this);
    this.endEditKeyword = this.endEditKeyword.bind(this);

    this.toggleKeywordEditMode = this.toggleKeywordEditMode.bind(this);
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleKeywordDeleteClicked = this.handleKeywordDeleteClicked.bind(this);
    this.deleteKeyword = this.deleteKeyword.bind(this);
    this.handleKeywordExpandUsage = this.handleKeywordExpandUsage.bind(this);
    this.handleSwitchUserClicked = this.handleSwitchUserClicked.bind(this);
    this.handleUpdateUserSaveClicked = this.handleUpdateUserSaveClicked.bind(this);

    this.handleShowRecoveryCode = this.handleShowRecoveryCode.bind(this);
    this.requestRecoveryCodeSuccess = this.requestRecoveryCodeSuccess.bind(this);
    this.handleSendRecoveryCode = this.handleSendRecoveryCode.bind(this);
    this.handleSendRecoveryCodeSuccess = this.handleSendRecoveryCodeSuccess.bind(this);

    this.handleSendManagementRequest = this.handleSendManagementRequest.bind(this);
    this.handleManagementRequestSuccess = this.handleManagementRequestSuccess.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.handlePhoneInfoButtonClick = this.handlePhoneInfoButtonClick.bind(this);
    this.handlePhoneInfoButtonClose = this.handlePhoneInfoButtonClose.bind(this);

    this.handlePasswordRecoverDialogConfirm = this.handlePasswordRecoverDialogConfirm.bind(this);
    this.handleManagementRequestDialogConfirm = this.handleManagementRequestDialogConfirm.bind(this);
  }

  errorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    if (typeof this.props.requestFailedErrorHandler !== "undefined") {
      this.props.requestFailedErrorHandler(errorJO);
    }
  }


  loadUser(user) {
    this.setState({
      userData: user,
      viewMode: EDIT_USER,
      tabledata: this.dataprovider.getKeywordSorted(1, 0),
      keywordViewMode: SHOW_KEYWORD,
    });
  }

  toggleKeywordEditMode() {
    let newState = -1;
    if (this.state.keywordViewMode === SHOW_KEYWORD) {
      newState = EDIT_KEYWORD;
    } else {
      newState = SHOW_KEYWORD;
    }
    this.setState({
      keywordViewMode: newState
    });

  }

  handleSortTableButtonClicked(index) {
    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.dataprovider.getKeywordSorted(index, newDirection)
      });

    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.dataprovider.getKeywordSorted(index, newDirection)
      });
    }
  }

  notifyPendingChanges(isPending) {
    if (typeof this.props.onPendingChanges === 'function') {
      this.props.onPendingChanges(isPending);
    }
  }

  handleChangeData(event, name) {
    let oldData = JSON.parse(JSON.stringify(this.state.userData))
    let filteredValue = event.target.value;
    if (name === "phoneNumber") {
      if (typeof filteredValue === "string") {
        var matches = filteredValue.match(/\+?[0-9 ]*/i)
        if (matches.length === 1) {
          filteredValue = matches[0]
        }
      }
    }
    oldData[name] = filteredValue;
    if (name === "search") {
      this.setState({
        searchText: event.target.value
      })
    } else {
      this.notifyPendingChanges(true);
    }
    this.setState({
      userData: oldData
    });
  }

  handleNewUserSaveClicked() {
    if (typeof this.props.onCreateUser === 'function') {
      this.props.onCreateUser(this.state.userData);
    }
  }


  handleUpdateUserSaveClicked() {
    if (typeof this.props.onUpdateUser === 'function') {
      this.props.onUpdateUser(this.state.userData);
    }
  }


  getKeywords() {
    return this.dataprovider.getKeywords();
  }


  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.userData === undefined) {
      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(false, false);
      }
    } else {
      let visible = true;
      let enabled = visible && !this.isEmpty(nextState.userData.name) && !this.isEmpty(nextState.userData.firstName);

      if (this.state.viewMode === NEW_USER) {
        visible = (!this.isEmpty(nextState.userData.name) || !this.isEmpty(nextState.userData.firstName)
          || !this.isEmpty(nextState.userData.eMailAdress) || !this.isEmpty(nextState.userData.phoneNumber));
        enabled = visible && !this.isEmpty(nextState.userData.name) && !this.isEmpty(nextState.userData.firstName)
          && (!this.isEmpty(nextState.userData.eMailAdress) || !this.isEmpty(nextState.userData.phoneNumber));

      }

      if (typeof this.props.listEditModeListener === "function") {
        this.props.listEditModeListener(visible, enabled);
      }
    }
  }

  handleKeywordTouchTap(keyword) {
    let selectedKeywords = this.state.userData.keywords;
    let index = selectedKeywords.indexOf(keyword)
    if (index === -1) {
      selectedKeywords.push(keyword);
    } else {
      selectedKeywords.splice(index, 1);
    }
    let oldData = JSON.parse(JSON.stringify(this.state.userData))
    oldData.keywords = selectedKeywords;
    this.setState({
      userData: oldData
    });
    this.notifyPendingChanges(true);
  }

  handleKeywordDeleteClicked(keyword) {
    if (typeof this.props.onDeleteKeyword === 'function') {
      this.props.onDeleteKeyword(keyword);
    }
  }

  deleteKeyword(keyword) {
    let selectedKeywords = this.state.userData.keywords;
    let index = selectedKeywords.indexOf(keyword.name)
    if (index !== -1) {
      selectedKeywords.splice(index, 1);
      let oldData = JSON.parse(JSON.stringify(this.state.userData))
      oldData.keywords = selectedKeywords;
      this.setState({
        userData: oldData
      });
      this.notifyPendingChanges(true);
    }
  }

  handleSwitchUserClicked(user) {
    if (typeof this.props.onSwitchUserClicked === 'function') {
      this.props.onSwitchUserClicked(user);
    }

  }

  chipBackgroundColor(keyword) {
    let selectedKeywords = this.state.userData.keywords;
    let index = selectedKeywords.indexOf(keyword)
    if (index === -1) {
      return '#7B98AB';
    } else {
      return '#7B98AB';
    }
  }

  beginEditKeyword() {
    this.setState({
      editKeyword: true,
      keywordText: ""
    });
  }

  endEditKeyword(event) {
    let newKeyword = this.state.keywordText;
    if (newKeyword.length > 0) {
      this.dataprovider.addKeyword(newKeyword);
      let selectedKeywords = this.state.userData.keywords;
      let index = selectedKeywords.indexOf(newKeyword)
      if (index === -1) {
        selectedKeywords.push(newKeyword);
      }
    }
    this.setState({
      editKeyword: false,
      keywordText: ""
    });
    this.notifyPendingChanges(true);
  }

  handleEditKeyword(event, newValue) {
    this.setState({
      keywordText: newValue
    });
  }

  renderkeywordInput() {
    if (this.state.editKeyword) {
      return <div style={ styles.fakeChipEditMode }>
               <TextField style={ styles.editKeywordField } value={ this.state.keywordText } onChange={ this.handleEditKeyword } />
               <Avatar size={ 24 } onClick={ this.endEditKeyword } style={styles.addKeywordButtonRight }>
                 {this.state.keywordText===""?"x":"+"}
               </Avatar>
             </div>

    }
    return <Chip style={ styles.chip } onTouchTap={ this.beginEditKeyword } backgroundColor="#7B98AB">
             <Avatar size={ 24 } color="#FFFFFF"  style={ styles.addKeywordButtonLeft }>+</Avatar>
             ...</Chip>;

  }

  getInnerHeight(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "maxHeight": currentHeight + "px",
    });
  }

  getInnerHeightFixed(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 210;
    return Object.assign({}, inputStyle, {
      "minHeight": currentHeight + "px",
      "maxHeight": currentHeight + "px",
    });
  }

  handlePhoneInfoButtonClick() {
    this.setState({
      showPhoneInfoBox: !this.state.showPhoneInfoBox
    });
  }

  handlePhoneInfoButtonClose() {
    this.setState({
      showPhoneInfoBox: false
    })
  }

  renderUserInput() {
    const {t} = this.props;
    if (this.state.viewMode === NEW_USER) {
      return <div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editFieldFirstName } onChange={ (event) => this.handleChangeData(event, 'firstName') } value={ this.state.userData['firstName'] } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column2') } />
                 <TextField style={ styles.editFieldLastName } onChange={ (event) => this.handleChangeData(event, 'name') } value={ this.state.userData['name'] } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column1') } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-User-email-hinttext') } onChange={ (event) => this.handleChangeData(event, 'eMailAdress') } value={ this.state.userData['eMailAdress'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column4') } onChange={ (event) => this.handleChangeData(event, 'phoneNumber') } value={ this.state.userData['phoneNumber'] } />
                 <IconButton onClick={ this.handlePhoneInfoButtonClick } ref="phoneInfoButton">
                   <IconInfo />
                 </IconButton>
                 <Popover placement='right' show={ this.state.showPhoneInfoBox } onHide={ this.handlePhoneInfoButtonClose } target={ this.refs.phoneInfoButton } style={ styles.infoButton }>
                   <span style={ styles.popupText }>{ t('modules-user-UserList-popover-phone') }</span>
                 </Popover>
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column8') } onChange={ (event) => this.handleChangeData(event, 'department') } value={ this.state.userData['department'] } />
               </div>
             </div>
    } else {
      return <div>
               <div style={ styles.editFieldDiv }>
                 <span style={ styles.greyLabelSpan }>{ t('modules-user-User-account-id') }</span>
                 <span style={ styles.fingerPrintImageSpan }><IconFingerprint style={ styles.iconFingerprint }/></span>
                 <span style={ styles.greenLabelSpan }>{ this.state.userData['accountID'] }</span>
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editFieldFirstName } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column2') } onChange={ (event) => this.handleChangeData(event, 'firstName') } value={ this.state.userData['firstName'] } />
                 <TextField style={ styles.editFieldLastName } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column1') } onChange={ (event) => this.handleChangeData(event, 'name') } value={ this.state.userData['name'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-User-email-hinttext') } onChange={ (event) => this.handleChangeData(event, 'eMailAdress') } value={ this.state.userData['eMailAdress'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column4') } onChange={ (event) => this.handleChangeData(event, 'phoneNumber') } value={ this.state.userData['phoneNumber'] } />
               </div>
               <div style={ styles.editFieldDiv }>
                 <TextField style={ styles.editField } floatingLabelText={ t('modules-user-UserList-table-placeHolder-column8') } onChange={ (event) => this.handleChangeData(event, 'department') } value={ this.state.userData['department'] } />
               </div>
             </div>
    }

  }

  handleKeywordExpandUsage(keyword) {
    this.setState({
      expandedKeyword: keyword
    });
  }


  getKeywordUsageText(keyword) {
    const {t} = this.props;

    let usageCount = t('modules-user-Keyword-usageKeyword', {
      count: keyword.usage.length
    })
    if (keyword.usage.length === 0) {
      return <div style={ styles.listUserCountDiv }>
               <span style={ styles.emptyKeyword }>{ usageCount } </span>
             </div>
    } else {
      if (this.state.expandedKeyword === keyword) {
        return <div style={ styles.listUser }>
                 { keyword.usage.map((user, i) => <div key={ i } style={ styles.listUserDiv }>
                                                    <FlatButton key={ i } label={ this.dataprovider.getDisplayName(user.CompanyUser) } secondary={ true } onClick={ () => this.handleSwitchUserClicked(user.CompanyUser) } />
                                                  </div>
                   ) }
               </div>
      } else {
        return <div style={ styles.listUserCountDiv }>
                 <span>{ usageCount }</span>
                 <FlatButton label={ t('modules-user-Keyword-showUsage') } secondary={ true } onClick={ () => this.handleKeywordExpandUsage(keyword) } />
               </div>
      }
    }
  }

  getFilteredKeyword() {
    if (this.state.searchText.length > 0) {
      let rc = [];
      let searchtextLower = this.state.searchText.toLowerCase();
      for (let keyword of this.state.tabledata) {
        if (keyword.name.toLowerCase().indexOf(searchtextLower) >= 0) {
          rc.push(keyword);
        }
      }
      return rc;
    }
    return this.state.tabledata;
  }

  renderKeywordSection() {
    const {t} = this.props;

    if (this.state.keywordViewMode === SHOW_KEYWORD) {
      return <div style={ this.getInnerHeight(styles.keywordList) }>
               { this.getKeywords().map((keyword, i) => <Chip key={ i } style={ styles.chip } onTouchTap={ () => this.handleKeywordTouchTap(keyword) } backgroundColor={ this.chipBackgroundColor(keyword) }>
                                                          { keyword } </Chip>) }
             </div>

    } else {
      return <div style={ this.getInnerHeight(styles.keywordList) }>
               <Table selectable={ false } style={ styles.keywordUsageTable }>
                 <TableHeader displaySelectAll={ false } adjustForCheckbox={ false }>
                   <TableRow>
                     <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-Keyword-table-column1') } onClickCallback={ this.handleSortTableButtonClicked }
                     />
                     <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-Keyword-table-column2') } onClickCallback={ this.handleSortTableButtonClicked }
                     />
                   </TableRow>
                 </TableHeader>
                 <TableBody displayRowCheckbox={ false }>
                   { this.getFilteredKeyword().map((keyword, i) => <TableRow key={ i } value={ keyword } displayBorder={ false }>
                                                                     <TableRowColumn style={ styles.listKeywordCell }>
                                                                       <Chip key={ i } style={ styles.chip } backgroundColor="#7B98AB" onRequestDelete={ () => this.handleKeywordDeleteClicked(keyword) }>
                                                                         { keyword.name } </Chip>
                                                                     </TableRowColumn>
                                                                     <TableRowColumn style={ styles.listUserCell }>
                                                                       { this.getKeywordUsageText(keyword) }
                                                                     </TableRowColumn>
                                                                   </TableRow>
                     ) }
                 </TableBody>
               </Table>
             </div>;
    }
  }

  renderKeywordSearch() {
    const {t} = this.props;

    if (this.state.keywordViewMode === SHOW_KEYWORD) {
      return <div style={ styles.greyText }>
               { t('modules-user-NewUser-keyword') }
             </div>;

    } else {
      return <div style={ styles.searchText }>
               <TextField hintText={ t('modules-user-Keyword-searchHint') } onChange={ (event) => this.handleChangeData(event, 'search') } />
               <IconSearch />
             </div>;
    }
  }

  requestRecoveryCodeSuccess(json) {
    let receivedRecoveryPassword = json.recoveryKey;
    this.setState({
      recoveryPassword: receivedRecoveryPassword,
      passwordViewMode: SHOW_PASSWORD
    });
    this.notifyUserAlertStateObsolete(false);
    this.refs.waitDialog.close();
    this.refs.recoveryPasswordDialog.open();
  }

  handleShowRecoveryCode() {
    let accountGuid = this.state.userData.guid;
    this.refs.waitDialog.open();
    this.props.dispatch(showRecoveryKey(accountGuid, this.requestRecoveryCodeSuccess, this.errorHandler));
  }

  handleSendRecoveryCode() {
    let accountGuid = this.state.userData.guid;
    this.refs.waitDialog.open();
    this.props.dispatch(sendRecoveryPasswordMail(accountGuid, this.handleSendRecoveryCodeSuccess, this.errorHandler));
  }
  handlePasswordRecoverDialogConfirm() {
    //aktualisiere Liste und kehre zur Listenansicht zurück
    this.notifyUserAlertStateObsolete(true);
  }
  handleSendRecoveryCodeSuccess() {
    const {t} = this.props;
    this.refs.waitDialog.close();
    this.refs.modalDialog.open({
      text: t("modules-user-recoverycode-messagesent"),

      button1Text: t("components-ModalDialog-ok"),
      button1Handler: this.handlePasswordRecoverDialogConfirm
    });
  }

  handleSendManagementRequest() {
    let accountGuid = this.state.userData.guid;
    this.refs.waitDialog.open();
    this.props.dispatch(sendResetCompanyUserState(accountGuid, this.handleManagementRequestSuccess, this.errorHandler));
  }

  handleManagementRequestDialogConfirm() {
    this.notifyUserAlertStateObsolete(true);
  }

  handleManagementRequestSuccess() {
    const {t} = this.props;
    this.refs.waitDialog.close();
    this.refs.modalDialog.open({
      text: t("modules-user-managementrequest-messagesent"),

      button1Text: t("components-ModalDialog-ok"),
      button1Handler: this.handleManagementRequestDialogConfirm
    });

  }

  notifyUserAlertStateObsolete(toListView) {
    //var user = this.state.userData;
    //user.alertState='';
    //this.setState({userData:user});
    if (typeof this.props.onUserAlertStateReset === 'function') {
      this.props.onUserAlertStateReset(toListView);
    }
  }

  renderPasswordRecovery() {
    const {t} = this.props;
    let hasNoEMailAddress = IsNullOrEmpty(this.state.userData.eMailAdress);
    return (
      <AlertContentPage HintText={ t('modules-user-password-revoverPasswordHint') }>
        <div style={{ ...styles.clearStyle, ...styles.verticalContentSpacing }}>
          <RaisedButton style={ hasNoEMailAddress ? {
                                  display: 'none'
                                } : {
                                  marginLeft: '12px'
                                } } label={ t('modules-user-password-btnSendRecoveryEMail') } primary={ true } onClick={ this.handleSendRecoveryCode } />
        </div>
        <div style={ styles.verticalContentSpacing }>
          <FlatButton label={ t('modules-user-password-btnShowRecoveryCode') } secondary={ true } onClick={ this.handleShowRecoveryCode } />
          <CustomModalDialog ref="recoveryPasswordDialog" onClose={ this.handlePasswordRecoverDialogConfirm } style={ this.state.recoveryPassword.length > 16 ? {
                                                                                                                        width: "520px"
                                                                                                                      } : {
                                                                                                                        width: "370px"
                                                                                                                      } }>
            <div style={ { display: 'inline-block', height: "110px", marginTop: 30 } }>
              <SplitStringView Value={ this.state.recoveryPassword } Title={ t("modules-user-recoverycode-title") } />
            </div>
          </CustomModalDialog>
        </div>
      </AlertContentPage>
    )
  }

  renderDeniedManagement() {
    const {t} = this.props;
    return (
      <AlertContentPage HintText={ t('modules-user-alert-sendManagementRequestHint') }>
        <div style={{ ...styles.clearStyle, ...styles.verticalContentSpacing }}>
          <RaisedButton style={ { marginLeft: '12px' } } label={ t('modules-user-alert-btnSendManagementRequest') } primary={ true } onClick={ this.handleSendManagementRequest } />
        </div>
      </AlertContentPage>
    )
  }

  renderAlertMode() {
    switch (this.state.userData.alertState) {
      case 'recoverPassword':
        return this.renderPasswordRecovery();
      case 'deniedManagement':
        return this.renderDeniedManagement();
      default:
    //TODO throw? <- hier sollten wir nicht landen
    //- es sei denn, es wurde ein neuer AlertState eingeführt :)
    }
  }

  getDeviceName(deviceName) {
    if (IsNullOrEmpty(deviceName)) {
      const {t} = this.props;
      return t('modules-user-device-unknown-devicename');
    } else {
      return deviceName;
    }
  }

  renderDeviceIcon(os) {
    if (IsNullOrEmpty(os)) {
      return <DeviceSmartphone />;
    }

    if (os.toLowerCase().indexOf("android", 0) > -1) {
      return <DeviceAndroid />;
    }

    if (os.toLowerCase().indexOf("ios", 0) > -1) {
      return <DeviceIPhone />;
    }

    return <DeviceComputer />;
  }

  getDeviceInfos(device) {
    var deviceInfos = "";
    if (!IsNullOrEmpty(device.os)) {
      deviceInfos = device.os;
    }

    if (!IsNullOrEmpty(device.appName)) {
      if (!IsNullOrEmpty(deviceInfos)) {
        deviceInfos += " | ";
      }
      deviceInfos += device.appName + " " + device.appVersion;
    }

    return deviceInfos;
  }

  renderDeviceList() {

    if (this.state.viewMode === NEW_USER) {
      return;
    } else {
      const {t} = this.props;
      return (
        <div style={ styles.deviceSectionDiv }>
          <span style={ styles.greyDeviceLabelSpan }>{ t('modules-user-device-deviceList') } </span>
          <div style={ styles.deviceListDiv }>
            { this.state.userData.devices.map((device, i) => <div key={ i } style={ styles.deviceEntryDiv }>
                                                               { this.renderDeviceIcon(device.Device.os) }
                                                               <div style={ styles.deviceLabelDiv }>
                                                                 <div>
                                                                   <span style={ styles.whiteBoldDeviceLabel }>{ this.getDeviceName(device.Device.deviceName) }</span>
                                                                 </div>
                                                                 <div>
                                                                   <span style={ styles.greyDeviceSubLabel }>{ this.getDeviceInfos(device.Device) }</span>
                                                                 </div>
                                                               </div>
                                                             </div>) }
          </div>
        </div>
        );
    }
  }

  render() {
    const {t} = this.props;
    let isShowKeyWordState = (this.state.keywordViewMode === SHOW_KEYWORD);
    let isAlertMode = (!IsNullOrEmpty(this.state.userData.alertState));
    if (!this.state.visible) {
      return <div></div>;
    }

    return (
      <div style={ styles.root }>
        <WaitDialog ref="waitDialog" />
        <ModalDialog ref="modalDialog" />
        <div style={ this.getInnerHeight(styles.newUserDiv) }>
          { this.renderUserInput() }
          <div style={ styles.selectedKeywordsDiv }>
            <span style={ styles.smallText }>{ t('modules-user-NewUser-keyword') } </span>
            { this.state.userData.keywords.map((keyword, i) => <Chip key={ i } style={ styles.chip } onTouchTap={ () => this.handleKeywordTouchTap(keyword) } backgroundColor="#7B98AB" onRequestDelete={ () => this.handleKeywordTouchTap(keyword) }>
                                                                 { keyword } </Chip>) }
            { this.renderkeywordInput() }
          </div>
          { this.renderDeviceList() }
        </div>
        <div style={ this.getInnerHeightFixed(styles.spacerDiv) }>
        </div>
        { isAlertMode
          ? this.renderAlertMode()
          : <div style={ styles.keywordsDiv }>
              <div style={ styles.keywordsHeaderDiv }>
                { this.renderKeywordSearch() }
                <div style={ styles.right }>
                  <FlatButton label={ isShowKeyWordState ? t('modules-user-NewUser-keywordEdit') : t('modules-user-NewUser-keywordEditEnd') } primary={ !isShowKeyWordState } secondary={ isShowKeyWordState } onClick={ this.toggleKeywordEditMode } />
                </div>
              </div>
              <div style={ styles.keywordListDiv }>
                { this.renderKeywordSection() }
              </div>
            </div> }
      </div>)
  }
}

/*
function mapStateToProps(state) {
  //TODO
  return {
  }
}
*/

/*
const UserDetailTranslated = translate(['common'], {
  withRef: true
})(UserDetail);
*/

/* export default connect(  mapStateToProps,
  null,
  null,
  // IMPORTANT: must pass this flag to react-redux/connect
 {
    withRef: true,
  })(translate(['common'])(UserDetail)); */
export default translate(['common'], {
  withRef: true
})(UserDetail);
