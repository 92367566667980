import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

// import FlatButton from './components/buttons/FlatButton';
import { ModalDialogLink } from './components/ModalDialogLink';

import "./footer.css";

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#002C4A'
  },
  left: {
  },
  center: {
    flex: '1',
    display: 'flex',
    flexFlow: 'row',
  },
  right: {
    marginLeft: '80px',
    textAlign: 'left'
  },
  centerLeft: {
    flex: '1',
  },
  centerRight: {

  },
  link: {
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '30px',
    hover: '#FFFFFF'
  //letterSpacing: '0.45px'
  },
  button: {
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '30px',
    //letterSpacing: '0.45px',
    color: '#FFFFFF',
    height: '30px',
    textAlign: 'left'
  }
}

class Footer extends Component {

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {t} = this.props;

    return (
      <div style={ styles.root }>
        <ModalDialogLink ref="modalDialogLink" />
        <div className="footerLink" style={ styles.left }>
          <div>
            <a style={ styles.link } href={ t('footer-link-details-url') }>
              { t('footer-link-details') }
            </a>
          </div>
          <div>
            <a style={ styles.link } href={ t('footer-link-faq-url') }>
              { t('footer-link-faq') }
            </a>
          </div>
          <div>
            <a style={ styles.link } href={ 'mailto:' + t('footer-link-support-email') }>
              { t('footer-link-support') }
            </a>
          </div>
        </div>
        <div className="footerLink" style={ styles.center }>
          <div style={ styles.centerLeft }>
          </div>
          <div style={ styles.centerRight }>
            <div>
              <a style={ styles.link } href={ t('footer-link-simsme-bu-url') }>
                { t('footer-link-simsme-bu') }
              </a>
            </div>
            <div>
              <a style={ styles.link } href={ t('footer-link-press-url') }>
                { t('footer-link-press') }
              </a>
            </div>
            <div>
              <a style={ styles.link } href={ t('footer-link-security-url') }>
                { t('footer-link-security') }
              </a>
            </div>
          </div>
        </div>
        <div style={ styles.right }>
          <div>
            <a style={ styles.link } href={ t('footer-link-impress-url') }>
              { t('footer-link-impress') }
            </a>
          </div>
          <div>
            <a style={ styles.link } href={ t('footer-link-tc-url') }>
              { t('footer-link-tc') }
            </a>
          </div>
          <div>
            <a style={ styles.link } href={ t('footer-link-privacy-url') }>
              { t('footer-link-privacy') }
            </a>
          </div>
        </div>
      </div>);
  }
}
//
// Footer.propTypes = {
//   handleImprintClick: PropTypes.func.isRequired
// }

const FooterTranslated = translate(['common'])(Footer);

export default connect()(FooterTranslated);
