/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component, PropTypes } from 'react';
import { translate } from 'react-i18next';

import { Table, TableBody, TableHeader, TableRow, TableFooter } from 'material-ui/Table';

import * as constants from './DataProvider';

import { TableRowColumnText, TableRowColumnUserState, TableRowColumnMoreIcon, TableHeaderColumnSort, TableHeaderColumnUserState, TableHeaderColumnMore, TableHeaderColumnEmailSort } from '../../components/table/TableCells'
import TableFooterPagination from '../../components/table/TableFooterPagination';

import { getTableStyles } from '../../components/styles.js';
import { getAdminMuiTheme } from '../../components/styles.js';

require('../../components/DateExtensions');

const moreMenuEntries = [{
    id: 'revoke',
    textId: 'modules-license-License-table-column-more-revoke'
  }, {
    id: 'extend',
    textId: 'modules-license-License-table-column-more-extend'
  }, {
    id: 'deleteUser',
    textId: 'modules-user-UserList-table-cmdDeleteUser'
}];

const tableStyles = getTableStyles();
const adminMuiTheme = getAdminMuiTheme();
const styles = {
  root: {
    padding: 7,
    height: '100%',
  },
  alertStyle: {
    backgroundColor: '#DC004B',
  },
  selectedBackground: {
    backgroundColor: 'rgba(123, 152, 171, 0.2)',
  },
  licenseColumnHeader: {
    width: 100
  },
  licenseColumnRow: {
    width: 100
  },
  validTillColumnHeader: {
    width: 120
  },
  validTillColumnRow: {
    width: 120
  },
  tableHeaderSort: {
    lineHeight: '36px',
    height: '36px',
    width: '100%'
  }
}


export class UsedLicenseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortColumn: 1,
      sortColumnName: constants.KEY_IDENT,
      sortDirection: 0,
      selectedRow: -1,
      isUserStateExpanded: false,
      isEmailExpanded: true,
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleOnTableRowSelection = this.handleOnTableRowSelection.bind(this);
    this.onRowSelection = this.onRowSelection.bind(this);

    this.handleExpandIconClicked = this.handleExpandIconClicked.bind(this);
  }

  /**
  * Callback fuer sortier-buttons
  */
  handleSortTableButtonClicked(index) {

    var sortColumnName;
    switch (index) {
      case 2: {
        sortColumnName = constants.KEY_VALID_TILL;
        break;
      }
      case 3: {
        sortColumnName = constants.KEY_LAST_NAME;
        break;
      }
      case 4: {
        sortColumnName = constants.KEY_FIRST_NAME;
        break;
      }
      case 5: {
        sortColumnName = constants.KEY_EMAIL;
        break;
      }
      case 6: {
        sortColumnName = constants.KEY_STATE;
        break;
      }
      default: {
        sortColumnName = constants.KEY_IDENT;
      }
    }

    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        data: this.props.dataProvider.getUsedVoucherSorted(this.state.sortColumnName, newDirection),
      });

    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortColumnName: sortColumnName,
        sortDirection: newDirection,
        data: this.props.dataProvider.getUsedVoucherSorted(sortColumnName, newDirection),
      });
    }
  }

  handleExpandIconClicked(isExpanded, index) {
    isExpanded = !isExpanded;

    if (index === 5) {
      this.setState({
        isEmailExpanded: isExpanded,
      });
    } else if (index === 6) {
      this.setState({
        isUserStateExpanded: isExpanded,
      });
    }
  }

  /**
  * Callback fuer ... menu
  */
  moreMenuEntryClick(event, menuItem, index) {
    if (menuItem) {
      this.props.onUserAction(menuItem.key, menuItem.props.value.guid, menuItem.props.value.CompanyUser.guid);
    }
  }

  handleOnTableRowSelection(selectedRows) {
    //not used / called
  }

  onRowSelection(item, i, event) {
    this.setState({
      selectedRow: i
    });
  }

  render() {
    const { t } = this.props;
    var data = this.props.dataProvider.getUsedVoucherSorted(this.state.sortColumnName, this.state.direction)

    let dataPaginated = this.refs.pagination && this.refs.pagination.getPaginatedData(data);
    // Breite der einzelnen Spalten ermitteln
    let windowWidth = window.innerWidth;

    let columnWidth = windowWidth / 10;

    return (
      <div style={styles.root}>
        <Table onRowSelection={ this.handleOnTableRowSelection } selectable={ false }>
          <TableHeader displaySelectAll={ false } adjustForCheckbox={ false } style={ tableStyles.tableHeader }>
            <TableRow style={ tableStyles.tableHeaderRow }>
              <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-license') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                style={ styles.licenseColumnHeader } />
              <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-expireDate') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                style={ styles.validTillColumnHeader } />
              <TableHeaderColumnSort index={ 3 } style={ { width: columnWidth } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('lastName') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
              <TableHeaderColumnSort index={ 4 } style={ { width: columnWidth } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('firstName') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } />
              <TableHeaderColumnEmailSort index={ 5 } style={ this.state.isEmailExpanded ? {
                                                                width: (1.5 * columnWidth)
                                                              } : {
                                                                width: 55
                                                              } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('email') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } contentExpand={ this.state.isEmailExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
              <TableHeaderColumnUserState index={ 6 } style={ { width: '100%' } } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('state') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) } contentExpand={ this.state.isUserStateExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
              <TableHeaderColumnMore />
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={ false } style={ tableStyles.tableBody }>
            { dataPaginated && dataPaginated.map((item, i) => item !== undefined && item.CompanyUser !== undefined &&
                  <TableRow key={i} value={item} displayBorder={true}
                            style={i === this.state.selectedRow ? Object.assign({}, tableStyles.tableRow, styles.selectedBackground) : item.CompanyUser.alertState !== '' ? Object.assign({}, tableStyles.tableRow, styles.alertStyle) : tableStyles.tableRow}
                            onClick={(event) => this.onRowSelection(item, i, event)}>
                    <TableRowColumnText text={item.ident} style={styles.licenseColumnRow}/>
                    <TableRowColumnText text={new Date().parseUtcDate(item.validTill).getShortDate()}
                                        style={styles.validTillColumnRow}/>
                    <TableRowColumnText text={item.CompanyUser.name} style={{width: columnWidth}}/>
                    <TableRowColumnText text={item.CompanyUser.firstName} style={{width: columnWidth}}/>
                    <TableRowColumnText text={item.CompanyUser.eMailAdress} style={this.state.isEmailExpanded ? {
                      width: (1.5 * columnWidth)
                    } : {
                      width: 55
                    }} isExpand={this.state.isEmailExpanded}/>
                    <TableRowColumnUserState t={t} user={item.CompanyUser} style={{width: '100%'}}
                                             isExpand={this.state.isUserStateExpanded}/>
                    <TableRowColumnMoreIcon menuStyle={{backgroundColor: adminMuiTheme.palette.primary2Color}}
                                            menuItemStyle={{fontSize: "14px"}} menuEntries={moreMenuEntries} t={t}
                                            callBack={this.moreMenuEntryClick.bind(this)}
                                            value={item}/>
                  </TableRow>

              ) }
          </TableBody>
          <TableFooter style={ tableStyles.tableFooter }>
            <TableFooterPagination ref="pagination" data={ data } parent={ this } />
          </TableFooter>
        </Table>
      </div>
      );
  }
}

UsedLicenseList.propTypes = {
  dataProvider: PropTypes.object.isRequired,
  onUserAction: PropTypes.func.isRequired
}

export default translate(['common'])(UsedLicenseList);
