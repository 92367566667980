const TranslationCommonEl ={
    "SERVICE-ADMIN-ERR-0019": "Η σύνδεση στο ginlo Business απέτυχε",
    "SERVICE-ADMIN-ERR-0022": "Μη έγκυρη εισαγωγή",
    "SERVICE-ADMIN-ERR-0098": "Ο αριθμός κινητού είναι άκυρος. Παρακαλώ εισάγετε τον αριθμό κινητού, συμπεριλαμβανομένου του κωδικού περιοχής ή πόλης, με την ακόλουθη μορφή: \"012345678910\". Για αριθμούς κινητών εκτός Γερμανίας, αναφέρετε τον κωδικό χώρας (π.χ. \"+31...\").",
    "SERVICE-ADMIN-ERR-0106": "Οι πληροφορίες εγγραφής είναι άκυρες.",
    "SERVICE-ADMIN-ERR-0106-1": "Ο κωδικός πρόσβασης δεν είναι σωστός.",
    "SERVICE-ADMIN-ERR-0109": "Η σύνοδος έχει λήξει.",
    "SERVICE-ADMIN-ERR-0110": "Η εικόνα δεν μπόρεσε να μεταφορτωθεί.",
    "SERVICE-ADMIN-ERR-0111": "Η εικόνα είναι πολύ μεγάλη. Η μέγιστη επιτρεπόμενη ανάλυση είναι 800 * 160 px.",
    "SERVICE-ADMIN-ERR-0113": "Ο αριθμός τηλεφώνου χρησιμοποιείται ήδη.",
    "SERVICE-ADMIN-ERR-0114": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου χρησιμοποιείται ήδη.",
    "SERVICE-ADMIN-ERR-0115": "Η μορφή του αρχείου .csv είναι άκυρη.",
    "SERVICE-ADMIN-ERR-0115-FIRSTNAME": "Η μορφή του αρχείου .csv είναι άκυρη. Η στήλη First Name είναι κενή.",
    "SERVICE-ADMIN-ERR-0115-NAME": "Η μορφή του αρχείου .csv είναι άκυρη. Η στήλη Επώνυμο είναι κενή.",
    "SERVICE-ADMIN-ERR-0115-PHONENUMBER": "Η μορφή του αρχείου .csv είναι άκυρη. Η στήλη Αριθμός κινητού είναι κενή.",
    "SERVICE-ADMIN-ERR-0135": "Η εικόνα είναι πολύ μικρή. Η ελάχιστη απαιτούμενη ανάλυση είναι 680 * 80 px.",
    "SERVICE-ADMIN-ERR-0153": "Δεν υπάρχουν αρκετές άδειες για αυτή τη δράση. Έχετε τη δυνατότητα να αγοράσετε πρόσθετες άδειες στη Διαχείριση αδειών.",
    "SERVICE-ADMIN-ERR-0154": "Ο εκπρόσωπος δεν είναι εξουσιοδοτημένος για την ενέργεια αυτή.",
    "SERVICE-ADMIN-ERR-0156": "Ένας άλλος διαχειριστής έχει ήδη συνδεθεί στο σύστημα. Για την αποφυγή συγκρούσεων, δεν επιτρέπεται η εγγραφή περισσότερων από 1 εκπροσώπων.",
    "SERVICE-ADMIN-ERR-0157": "Ο διαχειριστής έχει διαγραφεί.",
    "SERVICE-ADMIN-ERR-0158": "Ο διαχειριστής έχει απενεργοποιηθεί.",
    "SERVICE-ADMIN-ERR-0176": "Προέκυψε σφάλμα κατά τη φόρτωση της προεπισκόπησης για τη ροή RSS.",
    "SERVICE-ADMIN-ERR-0177": "Το API απενεργοποιήθηκε από άλλον χρήστη.",
    "SERVICE-ADMIN-ERR-0181": "Τα δεδομένα επεξεργάστηκε ο χρήστης {{username}} στο {{date}}.",
    "SERVICE-ADMIN-ERR-0182": "Τα δεδομένα διαγράφηκαν από τον χρήστη {{username}} στη διεύθυνση {{date}}.",
    "SERVICE-ADMIN-ERR-0183": "Το API είναι ήδη ενεργοποιημένο.",
    "SERVICE-ERR-0057": "Τάιμ άουτ. Έχετε αποσυνδεθεί.",
    "SERVICE-ERR-0123": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου είναι άκυρη.",
    "SERVICE-ERR-0124": "Ο τομέας της διεύθυνσης ηλεκτρονικού ταχυδρομείου που δηλώσατε λειτουργεί από ιδιωτικό πάροχο ηλεκτρονικού ταχυδρομείου. Αυτό δεν υποστηρίζεται λόγω ανησυχιών για το απόρρητο των δεδομένων.",
    "SERVICE-IMP-1": "Αυτό το αρχείο είναι πολύ μεγάλο.",
    "SERVICE-IMP-23": "Λανθασμένη μορφή αρχείου.",
    "SERVICE-IMP-30": "Η μορφή του αρχείου εισαγωγής είναι ελαττωματική (π.χ. λανθασμένος αριθμός στηλών).",
    "SERVICE-IMP-35": "Ένα όνομα ομάδας που χρησιμοποιείται υπάρχει πολλές φορές στο ginlo Business Cockpit.",
    "SERVICE-IMP-36": "Ένα όνομα καναλιού που χρησιμοποιείται υπάρχει πολλές φορές στο ginlo Business Cockpit.",
    "SERVICE-NO_SERVER_RESPONSE": "Δεν υπάρχει σύνδεση με το διακομιστή.",
    "SERVICE-RSSTRANS-CLI-0001": "Η ροή RSS στη ζητούμενη διεύθυνση URL δεν είναι διαθέσιμη. Η διεύθυνση URL ενδέχεται να έχει αποκλειστεί από τις ρυθμίσεις του τείχους προστασίας.",
    "SERVICE-RSSTRANS-ERR-0007": "Το περιεχόμενο της συγκεκριμένης διεύθυνσης URL δεν συμμορφώνεται με τις προδιαγραφές RSS 2.0. Βρέθηκαν μη έγκυροι χαρακτήρες.",
    "SERVICE-RSSTRANS-ERR-0008": "Το περιεχόμενο στη συγκεκριμένη διεύθυνση URL δεν συμμορφώνεται με τις προδιαγραφές RSS 2.0. Δεν έχουν συμπληρωθεί όλα τα υποχρεωτικά πεδία.",
    "SERVICE-RSSTRANS-ERR-0011": "Δεν βρέθηκε έγκυρη τροφοδοσία RSS στη διεύθυνση URL που δόθηκε.",
    "SERVICE-RSSTRANS-ERR-0012": "Προέκυψε σφάλμα κατά τη φόρτωση της προεπισκόπησης της ροής RSS. Η ζητούμενη διεύθυνση URL δεν είναι διαθέσιμη.",
    "SERVICE-RSSTRANS-ERR-0014": "Βρέθηκε μια τροφοδοσία Atom στη διεύθυνση URL που δόθηκε. Αυτή η μορφή δεν υποστηρίζεται. Παρακαλούμε βεβαιωθείτε ότι πρόκειται για τροφοδοσία RSS που συμμορφώνεται με το RSS 2.0.",
    "SERVICE-RSSTRANS-ERR-0015": "Δεν βρέθηκε περιεχόμενο στη διεύθυνση URL που δόθηκε.",
    "SERVICE-RSSTRANS-ERR-0016": "Η μορφή της ροής RSS δεν υποστηρίζεται.",
    "SERVICE-RSSTRANS-ERR-0017": "Η ροή RSS περιέχει μη έγκυρους, μη επιτρεπτές χαρακτήρες.",
    "cancel": "Ακύρωση",
    "common-delete-btn": "Διαγραφή",
    "common-edit-btn": "Επεξεργασία",
    "components-ModalDialog-Warning": "Σημείωση",
    "components-ModalDialog-cancel": "Ακύρωση",
    "components-ModalDialog-looseDataQuestion": "Εάν ακυρώσετε, οι αλλαγές δεν θα αποθηκευτούν. Ακύρωση;",
    "components-ModalDialog-no": "Όχι",
    "components-ModalDialog-ok": "OK",
    "components-ModalDialog-yes": "Ναι",
    "components-NavigationLeft-appdesign": "Σχεδιασμός εφαρμογών",
    "components-NavigationLeft-appsettings": "Ρυθμίσεις εφαρμογής",
    "components-NavigationLeft-channel": "Κανάλια",
    "components-NavigationLeft-dashboard": "Ταμπλό",
    "components-NavigationLeft-groups": "Ομάδες",
    "components-NavigationLeft-license": "Άδειες",
    "components-NavigationLeft-roles": "Ρόλοι χρηστών",
    "components-NavigationLeft-user": "Χρήστες",
    "components-chatmessagetextfield-default-hint": "Εισάγετε το κείμενο του μηνύματος",
    "components-chatmessagetextfield-preview-time-placeholder": "Τώρα",
    "components-continue": "Συνεχίστε",
    "components-messagecomposer-datepicker": "Επιλέξτε ημερομηνία",
    "components-messagecomposer-messagesendtime-delayed": "Την επόμενη ημερομηνία και ώρα",
    "components-messagecomposer-messagesendtime-now": "Στείλτε αμέσως",
    "components-messagecomposer-send": "Στείλτε",
    "components-messagecomposer-send-AttachmentTooBig": "Αυτό το αρχείο είναι πολύ μεγάλο.",
    "components-messagecomposer-timepicker": "Επιλέξτε χρόνο",
    "components-messagecomposer-uploadattachment": "Επιλογή εικόνας ή αρχείου",
    "components-messageeditor-button-delete": "Διαγραφή μηνυμάτων",
    "components-messageeditor-button-showolder": "Εμφάνιση παλαιότερων",
    "components-messageeditor-confirmdeletemessage": "Όλα τα μηνύματα σε αυτό το κανάλι θα διαγραφούν. Διαγραφή ούτως ή άλλως;",
    "components-messageeditor-header-messages": "Μηνύματα σε αυτό το κανάλι",
    "components-messageeditor-header-newmessage": "Νέο μήνυμα",
    "components-messageeditor-header-timedmessages": "Χρονοκαθυστέρηση",
    "components-messageeditor-rss-linkmore": "περισσότερα …",
    "components-messageeditor-type-audio": "Ήχος",
    "components-messageeditor-type-location": "Τοποθεσία",
    "components-messageeditor-type-video": "Βίντεο",
    "components-reload-data": "Ανανέωση",
    "email": "Ηλεκτρονικό ταχυδρομείο",
    "err-empty-textfield": "Το πεδίο δεν μπορεί να είναι κενό.",
    "error": "Σφάλμα",
    "firstName": "Όνομα",
    "footer-link-details": "ginlo Εγχειρίδιο",
    "footer-link-details-url": "https://app-help.ginlo.net/docs/en/",
    "footer-link-faq": "ΣΥΧΝΈΣ ΕΡΩΤΉΣΕΙΣ",
    "footer-link-faq-url": "https://app-help.ginlo.net/docs/en/faq/",
    "footer-link-impress": "Εκτύπωση",
    "footer-link-impress-url": "https://app-help.ginlo.net/en/imprint",
    "footer-link-press": "Τύπος",
    "footer-link-press-url": "https://www.ginlo.net/en/about-us/",
    "footer-link-privacy": "Πολιτική απορρήτου",
    "footer-link-privacy-url": "https://app-help.ginlo.net/business/en/privacy",
    "footer-link-security": "ginlo Δεδομένα",
    "footer-link-security-url": "https://www.ginlo.net/en/ginlo-data-netfiles-professional/",
    "footer-link-simsme-bu": "ginlo Επιχειρήσεις",
    "footer-link-simsme-bu-url": "https://www.ginlo.net/en/ginlo-business/",
    "footer-link-support": "Υποστήριξη",
    "footer-link-support-email": "b2b-support@ginlo.net",
    "footer-link-tc": "Όροι & Προϋποθέσεις",
    "footer-link-tc-url": "https://app-help.ginlo.net/business/en/terms",
    "header-btn-de": "DE",
    "header-btn-el": "GR",
    "header-btn-en": "EL",
    "header-btn-es": "ES",
    "header-btn-forgotPwd": "Ξεχάσατε τον κωδικό πρόσβασης",
    "header-btn-fr": "FR",
    "header-btn-logout": "Αποσύνδεση",
    "header-btn-settings": "Ρυθμίσεις",
    "header-btn-support": "Υποστήριξη",
    "header-title": "ginlo Business Cockpit",
    "header-title-subtitle": "Δείγμα επωνυμίας εταιρείας",
    "lastName": "Επώνυμο",
    "login-2fa-token-textfield-hint": "Καρφίτσα δύο παραγόντων (εάν είναι ενεργοποιημένη)",
    "login-email-textfield-hint": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
    "login-forgot-pwd-button": "Ορίστε",
    "login-forgot-pwd-desc": "Παρακαλώ εισάγετε τον κωδικό πρόσβασης ανάκτησης.",
    "login-forgot-pwd-desc-min-char": "Τουλάχιστον 8 χαρακτήρες",
    "login-forgot-pwd-desc-spez-char": "Τουλάχιστον ένας αριθμός (0-9) ή ειδικός χαρακτήρας",
    "login-forgot-pwd-desc-upper-char": "Μικρά και κεφαλαία γράμματα (a-z, A-Z)",
    "login-forgot-pwd-err-empty": "Αυτό το πεδίο είναι υποχρεωτικό.",
    "login-forgot-pwd-err-not-equal": "Οι κωδικοί πρόσβασης δεν είναι πανομοιότυποι.",
    "login-forgot-pwd-new-pwd-desc": "Παρακαλώ επιλέξτε έναν νέο κωδικό πρόσβασης.",
    "login-forgotPwd-button": "Ξεχάσατε τον κωδικό πρόσβασης",
    "login-initial-pwd-button": "Αποθήκευση",
    "login-initial-pwd-new-pwd-desc": "Παρακαλώ επιλέξτε τον δικό σας κωδικό πρόσβασης.",
    "login-login-button": "Σύνδεση",
    "login-pwd-new-textfield-hint": "Εισάγετε νέο κωδικό πρόσβασης",
    "login-pwd-repeat-textfield-hint": "Επαναλάβετε τον νέο κωδικό πρόσβασης",
    "login-pwd-textfield-hint": "Εισάγετε τον κωδικό πρόσβασης",
    "login-remember-email": "Θυμηθείτε τη διεύθυνση ηλεκτρονικού ταχυδρομείου",
    "modules-appdesign-appdesign-btnResetLayout": "Επαναφορά σχεδιασμού εφαρμογής",
    "modules-appdesign-appdesign-btncancel": "Ακύρωση",
    "modules-appdesign-appdesign-btnsave": "Αποθήκευση",
    "modules-appdesign-appdesign-header": "Σχεδιασμός εφαρμογών",
    "modules-appdesign-colorpicker-actionelements": "Στοιχεία δράσης",
    "modules-appdesign-colorpicker-blue": "μπλε",
    "modules-appdesign-colorpicker-color": "Χρώμα",
    "modules-appdesign-colorpicker-contrast": "αντίθεση",
    "modules-appdesign-colorpicker-contrastdark": "Σκούρο χρώμα αντίθεσης",
    "modules-appdesign-colorpicker-contrastlight": "Χρώμα ανοιχτής αντίθεσης",
    "modules-appdesign-colorpicker-dark": "σκοτεινό",
    "modules-appdesign-colorpicker-green": "Πράσινο",
    "modules-appdesign-colorpicker-heavytrust": "Υψηλό καθεστώς εμπιστοσύνης",
    "modules-appdesign-colorpicker-hex": "hexvalue",
    "modules-appdesign-colorpicker-light": "φως",
    "modules-appdesign-colorpicker-maincolor": "Κύριο χρώμα",
    "modules-appdesign-colorpicker-normaltrust": "Μέτρια κατάσταση εμπιστοσύνης",
    "modules-appdesign-colorpicker-red": "Κόκκινο",
    "modules-appdesign-colorpicker-unsafetrust": "Κατάσταση μη ασφαλούς εμπιστοσύνης",
    "modules-appdesign-colorpicker-yellow": "Μπλε",
    "modules-appdesign-cropimage-accept-btn": "Εφαρμογή",
    "modules-appdesign-cropimage-title": "Παρακαλούμε κόψτε το λογότυπό σας στο μέγεθος που απαιτείται.",
    "modules-appdesign-cropimage-zoom-hint": "* Μπορείτε να μεγεθύνετε ή να περικόψετε την εικόνα χρησιμοποιώντας τον τροχό κύλισης του πλήκτρου του ποντικιού.",
    "modules-appdesign-image-description": "*.png με άλφα",
    "modules-appdesign-image-description2": "Συνιστάται 800 x 160 px",
    "modules-appdesign-mainView-description": "Διαμορφώστε την εφαρμογή ώστε να ταιριάζει με την εταιρική σας ταυτότητα σε λίγα μόνο βήματα.",
    "modules-appdesign-mainView-removeCompanyLogo": "Διαγραφή εικόνας",
    "modules-appdesign-mainView-subheading": "Προσαρμόστε την εφαρμογή ανάλογα με τις ανάγκες σας.",
    "modules-appdesign-mainView-uploadImage": "Επιλέξτε εικόνα",
    "modules-appsettings-appsettings-btnResetConfig": "Επαναφορά ρυθμίσεων εφαρμογής",
    "modules-appsettings-header": "Ρυθμίσεις εφαρμογής",
    "modules-appsettings-hinttext-range1": "0 έως 10 λεπτά",
    "modules-appsettings-hinttext-range2": "0 έως 100 επαναλήψεις",
    "modules-appsettings-hinttext-range3": "0 έως 99 χαρακτήρες",
    "modules-appsettings-hinttext-range4": "0 έως 365 ημέρες",
    "modules-appsettings-hinttext-range5": "0 έως 4 τάξεις",
    "modules-appsettings-label-chars": "Χαρακτήρες",
    "modules-appsettings-label-days": "Ημέρες",
    "modules-appsettings-label-minutes": "Min.",
    "modules-appsettings-label-repeat": "Rpt.",
    "modules-appsettings-mainView-contactpolicy": "Κατευθυντήριες γραμμές επικοινωνίας",
    "modules-appsettings-mainView-mediapolicy": "Κατευθυντήριες γραμμές για τα μέσα ενημέρωσης",
    "modules-appsettings-mainView-pwcomplexity": "Πολυπλοκότητα κωδικού πρόσβασης",
    "modules-appsettings-mainView-pwpolicy": "Οδηγίες για τον κωδικό πρόσβασης",
    "modules-appsettings-selectfield-deleteaccount-inactive": "απενεργοποίηση",
    "modules-appsettings-selectfield-deleteaccount-tries": "Προσπάθειες",
    "modules-appsettings-selectfield-deleteaccount-try": "Προσπάθεια",
    "modules-appsettings-table-companyindexname": "Όνομα του καταλόγου της εταιρείας",
    "modules-appsettings-table-companyindexnamedescr": "Αλλάξτε εδώ το όνομα του εταιρικού καταλόγου που θα πρέπει να εμφανίζεται στους χρήστες στο πλαίσιο των επαφών.",
    "modules-appsettings-table-contactClosedUserGroup": "Ενεργοποιημένη περιορισμένη ομάδα χρηστών",
    "modules-appsettings-table-contactClosedUserGroupDescr": "Όταν αυτή η λειτουργία είναι ενεργοποιημένη, ο χρήστης μπορεί να επικοινωνεί μόνο με επαφές που έχουν προστεθεί στο ginlo Cockpit. Επίσης, δεν είναι δυνατή η συνομιλία πέραν της συνομιλίας με τους διαχειριστές.",
    "modules-appsettings-table-deletedatacount": "Διαγραφή δεδομένων μετά από πολλές αποτυχημένες προσπάθειες",
    "modules-appsettings-table-deletedatacountdescr": "Εισάγετε τον αριθμό των επιτρεπόμενων προσπαθειών σύνδεσης πριν διαγραφούν αυτόματα τα περιεχόμενα της εφαρμογής.",
    "modules-appsettings-table-disablebackup": "Αποτροπή δημιουργίας αντιγράφων ασφαλείας εφαρμογών στο iCloud/Google Drive",
    "modules-appsettings-table-disablebackupdescr": "Με αυτή τη ρύθμιση, οι χρήστες δεν μπορούν να δημιουργήσουν αντίγραφα ασφαλείας στο iCloud ή στο Google Drive ή να εισάγουν αντίγραφα ασφαλείας από αυτούς τους παρόχους. Για τη δημιουργία αντιγράφων ασφαλείας δεδομένων, συνιστάται η σύζευξη σε μια δεύτερη συσκευή.",
    "modules-appsettings-table-disablecamera": "Άρνηση πρόσβασης στην κάμερα.",
    "modules-appsettings-table-disablecameradescr": "Όταν ενεργοποιηθεί αυτή η λειτουργία, ο υπάλληλος δεν μπορεί πλέον να εγγράφει ή να αποστέλλει φωτογραφίες και βίντεο από την κάμερα.",
    "modules-appsettings-table-disablecopypaste": "Άρνηση πρόσβασης στην αντιγραφή / επικόλληση",
    "modules-appsettings-table-disablecopypastedescr": "Όταν ενεργοποιηθεί αυτή η λειτουργία, ένας υπάλληλος δεν μπορεί πλέον να αντιγράψει κείμενο και μέσα ενημέρωσης για επικόλληση σε συνομιλίες ή εφαρμογές τρίτων.",
    "modules-appsettings-table-disableexportchat": "Απενεργοποίηση της εξαγωγής περιεχομένου συνομιλίας ως αρχείο κειμένου",
    "modules-appsettings-table-disableexportchatdescr": "Η ενεργοποίηση αυτής της επιλογής εμποδίζει την εξαγωγή μεμονωμένων συνομιλιών από την εφαρμογή σε αρχείο κειμένου.",
    "modules-appsettings-table-disablefaceandtouchid": "Ενεργοποίηση αποκλεισμού μέσω Touch/Face-ID",
    "modules-appsettings-table-disablefaceandtouchiddescr": "Αν επιλεγεί αυτή η ρύθμιση, οι χρήστες δεν μπορούν να χρησιμοποιήσουν το Touch/Face ID (iPhone) ή το δακτυλικό αποτύπωμα (Android) για να ξεκλειδώσουν την εφαρμογή.",
    "modules-appsettings-table-disablelocation": "Άρνηση πρόσβασης σε γεωγραφικά δεδομένα",
    "modules-appsettings-table-disablelocationdescr": "Όταν ενεργοποιηθεί αυτή η λειτουργία, ένας εργαζόμενος δεν μπορεί πλέον να μοιράζεται δεδομένα τοποθεσίας.",
    "modules-appsettings-table-disablemicrophone": "Απαγόρευση πρόσβασης στο μικρόφωνο",
    "modules-appsettings-table-disablemicrophonedescr": "Όταν ενεργοποιείται αυτή η λειτουργία, ένας υπάλληλος δεν μπορεί πλέον να στέλνει ηχογραφήσεις.",
    "modules-appsettings-table-disablepushpreview": "Αποτροπή προεπισκόπησης σε ειδοποιήσεις push",
    "modules-appsettings-table-disablepushpreviewdescr": "Με αυτή τη ρύθμιση, τα περιεχόμενα των μηνυμάτων σε κινητές συσκευές δεν θα εμφανίζονται στην προεπισκόπηση push. Αυτό αυξάνει την ασφάλεια, καθώς το περιεχόμενο μπορεί τότε να διαβαστεί μόνο με την εφαρμογή.",
    "modules-appsettings-table-disablerecoverycode": "Απενεργοποιήστε τον κωδικό ανάκτησης",
    "modules-appsettings-table-disablerecoverycodedescr": "Όταν η λειτουργία αυτή είναι ενεργοποιημένη, ο χρήστης δεν μπορεί να χρησιμοποιήσει τη λειτουργία 'ξεχάσατε τον κωδικό πρόσβασης'.",
    "modules-appsettings-table-disablesendmedia": "Απενεργοποίηση της αποστολής και προώθησης αρχείων πολυμέσων",
    "modules-appsettings-table-disablesendmediadescr": "Όταν αυτή η ρύθμιση είναι ενεργοποιημένη, δεν είναι δυνατή η αποστολή ή η προώθηση συνημμένων.",
    "modules-appsettings-table-disablesendvcard": "Άρνηση πρόσβασης σε επαφές",
    "modules-appsettings-table-disablesendvcarddescr": "Όταν ενεργοποιείται αυτή η λειτουργία, ένας υπάλληλος δεν μπορεί πλέον να στέλνει ή να λαμβάνει επαφές.",
    "modules-appsettings-table-disablesimsmerecovery": "Επιβολή κωδικού ανάκτησης μέσω του διαχειριστή",
    "modules-appsettings-table-disablesimsmerecoverydescr": "Όταν αυτή η λειτουργία είναι ενεργοποιημένη, οι χρήστες που ξεχνούν τον κωδικό πρόσβασής τους πρέπει να περιμένουν τη ρητή έγκριση του διαχειριστή πριν από την αποστολή ενός κωδικού ανάκτησης.",
    "modules-appsettings-table-forcePwComplexity": "Υποχρεωτική πολυπλοκότητα κωδικού πρόσβασης",
    "modules-appsettings-table-forcePwComplexityDescr": "Επιβολή πολυπλοκότητας του κωδικού πρόσβασης της εφαρμογής",
    "modules-appsettings-table-localmedia": "Μην επιτρέπετε την τοπική αποθήκευση των πολυμέσων",
    "modules-appsettings-table-localmediadescr": "Η ενεργοποίηση αυτής της επιλογής καταργεί την επιλογή 'Αποθήκευση πολυμέσων', πράγμα που σημαίνει ότι οι εικόνες και τα βίντεο δεν μπορούν να αποθηκευτούν τοπικά.",
    "modules-appsettings-table-passwordmaxduration": "Ισχύς κωδικού πρόσβασης σε ημέρες",
    "modules-appsettings-table-passwordmaxdurationdescr": "Καθορίστε τη διάρκεια ισχύος του κωδικού πρόσβασης σε ημέρες, π.χ. '30 ημέρες'. 30 ημέρες σημαίνει ότι μετά από 30 ημέρες ο χρήστης πρέπει να επιλέξει νέο κωδικό πρόσβασης.",
    "modules-appsettings-table-passwordminclasses": "Ελάχιστο επίπεδο πολυπλοκότητας (ψηφία, ειδικοί χαρακτήρες, πεζά και κεφαλαία γράμματα)",
    "modules-appsettings-table-passwordminclassesdescr": "Αυτή η ρύθμιση σας επιτρέπει να ορίσετε τον ελάχιστο αριθμό κατηγοριών χαρακτήρων (ψηφία, ειδικοί χαρακτήρες, πεζά και κεφαλαία γράμματα) που πρέπει να χρησιμοποιούνται στον κωδικό πρόσβασης. 2 σημαίνει ότι ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον δύο κλάσεις (όπως ψηφία + πεζά γράμματα ή ειδικοί χαρακτήρες + ψηφία).",
    "modules-appsettings-table-passwordminlength": "Ελάχιστος αριθμός χαρακτήρων",
    "modules-appsettings-table-passwordminlengthdescr": "Αυτή η παράμετρος ορίζει την υποχρεωτική πολυπλοκότητα του κωδικού πρόσβασης με βάση τον ελάχιστο αριθμό χαρακτήρων. 0 χαρακτήρες σημαίνει ότι δεν υπάρχει ελάχιστο μήκος.",
    "modules-appsettings-table-passwordminlowcase": "Ελάχιστος αριθμός πεζών γραμμάτων",
    "modules-appsettings-table-passwordminlowcasedescr": "Καθορίστε πόσα πεζά γράμματα πρέπει να χρησιμοποιούνται, όπως 'abcde'. 0 χαρακτήρες σημαίνει ότι δεν απαιτούνται πεζά γράμματα.",
    "modules-appsettings-table-passwordminnumbers": "Ελάχιστος αριθμός ψηφίων",
    "modules-appsettings-table-passwordminnumbersdescr": "Καθορίστε πόσα ψηφία πρέπει να χρησιμοποιούνται, όπως το '12345'. 0 χαρακτήρες σημαίνει ότι δεν απαιτούνται ψηφία.",
    "modules-appsettings-table-passwordminspecialchars": "Ελάχιστος αριθμός ειδικών χαρακτήρων",
    "modules-appsettings-table-passwordminspecialcharsdescr": "Καθορίστε πόσοι ειδικοί χαρακτήρες όπως '!§$%' πρέπει να χρησιμοποιούνται. 0 χαρακτήρες σημαίνει ότι δεν απαιτούνται ειδικοί χαρακτήρες.",
    "modules-appsettings-table-passwordminuppercase": "Ελάχιστος αριθμός κεφαλαίων γραμμάτων",
    "modules-appsettings-table-passwordminuppercasedescr": "Καθορίστε πόσα κεφαλαία γράμματα πρέπει να χρησιμοποιούνται, όπως 'ABCDE'. 0 χαρακτήρες σημαίνει ότι δεν απαιτούνται κεφαλαία γράμματα.",
    "modules-appsettings-table-pwinact": "Αναγκαστικός έλεγχος κωδικού πρόσβασης μετά από αδράνεια …",
    "modules-appsettings-table-pwinactdescr": "Αυτή η παράμετρος σάς επιτρέπει να ορίσετε για πόσο χρονικό διάστημα η εφαρμογή μπορεί να είναι ανενεργή πριν ο χρήστης πρέπει να εισάγει ξανά τον κωδικό πρόσβασης.",
    "modules-appsettings-table-pwpolicy": "Αναγκαστικός έλεγχος κωδικού πρόσβασης κατά την εκκίνηση της εφαρμογής",
    "modules-appsettings-table-pwpolicydescr": "Εάν αυτή η λειτουργία είναι ενεργοποιημένη, η εφαρμογή θα ζητά τον κωδικό πρόσβασης του χρήστη κάθε φορά που θα εκκινείται.",
    "modules-appsettings-table-pwwdh": "Ο κωδικός πρόσβασης μπορεί να επαναχρησιμοποιηθεί μετά από X αλλαγές",
    "modules-appsettings-table-pwwdhdescr": "Μπορείτε να ορίσετε πόσες φορές πρέπει να χρησιμοποιήσει ένας χρήστης έναν νέο κωδικό πρόσβασης προτού επαναχρησιμοποιήσει έναν προηγούμενο κωδικό πρόσβασης. 1 επανάληψη σημαίνει ότι ο κωδικός πρόσβασης μπορεί να χρησιμοποιηθεί αφού στο μεταξύ έχει οριστεί 1 διαφορετικός κωδικός πρόσβασης.",
    "modules-appsettings-table-sharemedia": "Απενεργοποιήστε την κοινή χρήση αρχείων πολυμέσων με άλλες εφαρμογές",
    "modules-appsettings-table-sharemediadescr": "Όταν αυτή η ρύθμιση είναι ενεργοποιημένη, οι εικόνες και τα βίντεο δεν μπορούν πλέον να αποθηκευτούν και τα αρχεία δεν μπορούν να εμφανιστούν.",
    "modules-appsettings-table-simplecode": "Απαγόρευση απλού, 4ψήφιου κωδικού ως κωδικού πρόσβασης",
    "modules-appsettings-table-simplecodedescr": "Εάν αυτή η ρύθμιση είναι ενεργοποιημένη, τότε οι χρήστες δεν μπορούν να χρησιμοποιήσουν έναν απλό τετραψήφιο κωδικό ως κωδικό πρόσβασης. Για να διασφαλίσετε ότι οι υπάρχοντες τετραψήφιοι κωδικοί πρόσβασης πρέπει να αλλάξουν, ορίστε μια κατάλληλη πολυπλοκότητα κωδικού πρόσβασης.",
    "modules-channel-commonbutton-btnUp": "στην κορυφή",
    "modules-channels-Channel-channel": "Διαχείριση καναλιών",
    "modules-channels-Channel-confirmDeleteChannel": "Είστε σίγουροι ότι θέλετε να διαγράψετε το κανάλι?",
    "modules-channels-ChannelList-table-column1": "Όνομα καναλιού",
    "modules-channels-NewChannel": "Δημιουργία νέου καναλιού",
    "modules-channels-NewChannel-noMember": "Δεν υπάρχουν μέλη του καναλιού",
    "modules-channels-channel-btnDeleteChannel": "Διαγραφή καναλιού",
    "modules-channels-channel-tab-editChannel": "Επεξεργασία καναλιού",
    "modules-channels-channel-tab-messages": "Μηνύματα",
    "modules-channels-messages-senddateinvalid": "Παρακαλώ επιλέξτε μια ημερομηνία στο μέλλον.",
    "modules-dashboard-AktivitatenChart-aktivitaeten": "Δραστηριότητα",
    "modules-dashboard-AktivitatenChart-displayValue1": "Ημερήσιοι ενεργοί χρήστες",
    "modules-dashboard-AktivitatenChart-displayValue2": "Μηνιαίοι ενεργοί χρήστες",
    "modules-dashboard-AktivitatenChart-messagecount": "{{v1}} μηνύματα",
    "modules-dashboard-AktivitatenChart-popOver-header1": "Ημερήσιοι ενεργοί χρήστες",
    "modules-dashboard-AktivitatenChart-popOver-header2": "Μηνιαίοι ενεργοί χρήστες",
    "modules-dashboard-AktivitatenChart-popOver-text1": "Αριθμός χρηστών που ανοίγουν την εφαρμογή καθημερινά",
    "modules-dashboard-AktivitatenChart-popOver-text2": "Αριθμός χρηστών που ανοίγουν την εφαρμογή σε μηνιαία βάση",
    "modules-dashboard-AktivitatenChart-timeValue1": "Μία εβδομάδα",
    "modules-dashboard-AktivitatenChart-timeValue2": "Ένας μήνας",
    "modules-dashboard-AktivitatenChart-timeValue3": "Συνολική περίοδος",
    "modules-dashboard-AktivitatenChart-usercount": "{{v1}} χρήστες",
    "modules-dashboard-ChannelChart-channels": "Κανάλια",
    "modules-dashboard-ChannelChart-placeholder-channel-1": "Παρακαλώ επιλέξτε ένα κανάλι.",
    "modules-dashboard-ChannelChart-placeholder-group-1": "Παρακαλώ επιλέξτε μια ομάδα.",
    "modules-dashboard-ChannelChart-placeholder-group-2": "δεν υπάρχουν ακόμη διαθέσιμα στοιχεία",
    "modules-dashboard-ChannelChart-popOver-text1": "Τα Top 5 είναι τα μηνύματα των οποίων τα αρχεία, οι εικόνες ή οι σύνδεσμοι επιλέχθηκαν συχνότερα από τους χρήστες.",
    "modules-dashboard-ChannelChart-table-column1": "Όνομα",
    "modules-dashboard-ChannelChart-table-column2": "Χρήστες",
    "modules-dashboard-ChannelChart-table-column3": "Μηνύματα",
    "modules-dashboard-ChannelChart-table-row-usergenerated": "Ομάδες που δημιουργούνται από χρήστες",
    "modules-dashboard-ChannelChart-table-showAll": "Εμφάνιση όλων …",
    "modules-dashboard-ChannelChart-table-title": "Top 5 συζητήσεις",
    "modules-dashboard-ChannelChart-table-titleInfo": "Αριθμός κλικ σε συνδέσμους",
    "modules-dashboard-ChannelChart-timeValue1": "Μία εβδομάδα",
    "modules-dashboard-ChannelChart-timeValue2": "Ένας μήνας",
    "modules-dashboard-Dashboard-dashboard": "Ταμπλό",
    "modules-dashboard-Dashboard-dashboard-license": "{{v1}} των αδειών {{v2}} είναι ενεργές",
    "modules-dashboard-GruppenChart-groups": "Διαχειριζόμενη ομάδα",
    "modules-dashboard-GruppenChart-messagecount": "{{v1}} μηνύματα",
    "modules-dashboard-GruppenChart-table-column1": "Όνομα",
    "modules-dashboard-GruppenChart-table-column2": "Χρήστες",
    "modules-dashboard-GruppenChart-table-column3": "Μηνύματα",
    "modules-dashboard-GruppenChart-table-row-usergenerated": "{{v1}} μηνύματα σε ομάδες που δημιουργούνται από χρήστες",
    "modules-dashboard-GruppenChart-table-showAll": "Εμφάνιση όλων …",
    "modules-dashboard-GruppenChart-table-title": "Μηνύματα",
    "modules-dashboard-GruppenChart-timeValue1": "Μία εβδομάδα",
    "modules-dashboard-GruppenChart-timeValue2": "Ένας μήνας",
    "modules-dashboard-MessageDetailAktivitaetenChart-messages": "Μείγμα μηνυμάτων",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue1": "Μία εβδομάδα",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue2": "Ένας μήνας",
    "modules-dashboard-MessageDetailAktivitaetenChart-timeValue3": "Συνολική περίοδος",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key1": "{{v1}} συνολικά μηνύματα",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key2": "{{v1}} μεμονωμένα μηνύματα",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key3": "{{v1}} ομάδες",
    "modules-dashboard-MessageDetailAktivitaetenChart-tooltip-key4": "{{v1}} κανάλια",
    "modules-group-group-identifier": "Ομάδα",
    "modules-group-groups-identifier": "Ομάδες",
    "modules-groups-GroupDetail-btnCheckFeedUrl": "Ελέγξτε το",
    "modules-groups-GroupList-table-column1": "Όνομα ομάδας",
    "modules-groups-GroupList-table-column2": "Προσκεκλημένοι",
    "modules-groups-GroupList-table-column3": "Έγινε μέλος στο",
    "modules-groups-GroupList-table-column4": "Εξαιρετικό",
    "modules-groups-GroupList-table-column5": "Τελευταία δραστηριότητα",
    "modules-groups-Groups-btnCancelNewGroup": "Ακύρωση",
    "modules-groups-Groups-btnCreateGroup": "Δημιουργία νέας ομάδας",
    "modules-groups-Groups-btnDeleteGroup": "Διαγραφή ομάδας",
    "modules-groups-Groups-confirmDeleteGroup": "Είστε σίγουροι ότι θέλετε να διαγράψετε την ομάδα;",
    "modules-groups-Groups-groups": "Διαχείριση ομάδων",
    "modules-groups-NewGroup-btnImportImage": "Επιλέξτε εικόνα",
    "modules-groups-NewGroup-newgroup": "Δημιουργία νέας ομάδας",
    "modules-groups-NewGroup-noMember": "Δεν υπάρχουν μέλη της ομάδας",
    "modules-groups-NewGroup-placeholder-RSS-preview": "Προεπισκόπηση RSS",
    "modules-groups-NewGroup-placeholder-feed": "Ορισμός εξωτερικής πηγής RSS.",
    "modules-groups-NewGroup-placeholder-name": "Όνομα",
    "modules-groups-NewGroup-searchHint": "Αναζήτηση με βάση το όνομα, το επώνυμο ή τη λέξη-κλειδί",
    "modules-license-DPAGOrderLicenseDialog-data-details-close-button-title": "Κλείστε το",
    "modules-license-DPAGOrderLicenseDialog-data-link": "δεδομένα",
    "modules-license-DPAGOrderLicenseDialog-data-text1": "Δεδομένα σημαίνει",
    "modules-license-DPAGOrderLicenseDialog-data-text2": "(i) δεδομένα συμβάσεων και απογραφής, π.χ. εταιρεία, διευθύνσεις, διευθύνσεις ηλεκτρονικού ταχυδρομείου ή αριθμούς τηλεφώνου προσωπικών επαφών και πληροφορίες εξυπηρέτησης πελατών, καθώς και δεδομένα πληρωμής και τιμολόγησης, και",
    "modules-license-DPAGOrderLicenseDialog-data-text3": "(ii) δεδομένα κίνησης, π.χ. πληροφορίες σχετικά με τον χρόνο, τη διάρκεια και τον όγκο των μηνυμάτων που αποστέλλονται από τους χρήστες, καθώς και τα ίδια τα κρυπτογραφημένα μηνύματα που εξακολουθούν να μεταδίδονται.",
    "modules-license-DPAGOrderLicenseDialog-data-text4": "Περισσότερες πληροφορίες μπορείτε να βρείτε στη διεύθυνση {{v1}}.",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-text": "εδώ",
    "modules-license-DPAGOrderLicenseDialog-data-transition-link-url": "http://sims.me/datenuebergang/business/en",
    "modules-license-DPAGOrderLicenseDialog-optIn-Checkbox-text": "Θα ήθελα να διασφαλίσω την απρόσκοπτη συνέχιση του ginlo Business messenger για τους χρήστες μου και, ως εκ τούτου, αναθέτω στην ginlo.net GmbH να παρέχει τις υπηρεσίες ginlo Business για τις υπάρχουσες άδειες χρήσης μου από την ημερομηνία λήξης τους στην Deutsche Post AG. Για την παροχή των υπηρεσιών, συμφωνώ με τη μεταφορά των δεδομένων μου και του {{v1}} των χρηστών μου από την Brabbler AG στην ginlo.net GmbH. ",
    "modules-license-DPAGOrderLicenseDialog-order1": "Δια του παρόντος διατάσσω",
    "modules-license-DPAGOrderLicenseDialog-order2": "νέες άδειες.  ",
    "modules-license-DPAGOrderLicenseDialog-privacy-link": "Πολιτική απορρήτου",
    "modules-license-DPAGOrderLicenseDialog-t-and-c-and-privacy": "Οι ιστοσελίδες {{v1}} και {{v2}} της ginlo.net GmbH ισχύουν για όλες τις άδειες χρήσης.",
    "modules-license-DPAGOrderLicenseDialog-text1": "Η SiMSme Business (τώρα ginlo Business) έχει νέο ιδιοκτήτη: ginlo.net Gesellschaft für Datenkommunikation mbH (ginlo.net GmbH). Οι παραγγελίες για νέες άδειες μπορούν να γίνουν μόνο στην ginlo.net GmbH. ",
    "modules-license-License-btn-new": "Παραγγελία νέας άδειας",
    "modules-license-License-btn-select": "Εκχώρηση άδειας χρήσης",
    "modules-license-License-delete-user-btn": "Διαγραφή χρήστη",
    "modules-license-License-delete-user-text": "Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη, γεγονός που θα εμποδίσει την πρόσβαση του χρήστη στην εφαρμογή;",
    "modules-license-License-delete-user-title": "Διαγραφή {{v1}}?",
    "modules-license-License-extend-header": "Ανανέωση άδειας",
    "modules-license-License-extend-text": "Εκχώρηση άδειας χρήσης σε ",
    "modules-license-License-license": "Διαχείριση αδειών χρήσης",
    "modules-license-License-revoke-btn": "Ανάκληση άδειας",
    "modules-license-License-revoke-text": "Είστε σίγουροι ότι θέλετε να ανακαλέσετε την άδεια χρήσης από τον χρήστη;",
    "modules-license-License-revoke-title": "Ανάκληση άδειας από το {{v1}}?",
    "modules-license-License-searchfield-hint": "Αναζήτηση με βάση το όνομα, το επώνυμο ή τη λέξη-κλειδί",
    "modules-license-License-table-column-count": "Αριθμός",
    "modules-license-License-table-column-expireDate": "Ισχύει μέχρι",
    "modules-license-License-table-column-license": "Άδεια",
    "modules-license-License-table-column-licenses": "Άδειες",
    "modules-license-License-table-column-more-assign": "Εκχώρηση άδειας χρήσης",
    "modules-license-License-table-column-more-delete": "Διαγραφή άδειας",
    "modules-license-License-table-column-more-extend": "Ανανέωση άδειας",
    "modules-license-License-table-column-more-revoke": "Ανάκληση άδειας",
    "modules-license-License-table-header-availableUser": "Διαθέσιμοι χρήστες",
    "modules-license-License-table-header-freeLicense": "Διαθέσιμες άδειες",
    "modules-license-License-table-header-usedLicense": "Ενεργές άδειες",
    "modules-license-OrderLicenseDialog-agb-link-url": "https://app-help.ginlo.net/business/en/terms",
    "modules-license-OrderLicenseDialog-privacy-link-url": "https://app-help.ginlo.net/business/en/privacy",
    "modules-license-license-assignment-error": "Η ανάθεση δεν είναι δυνατή. Υπάρχουν διαθέσιμες μόνο {{free}} δωρεάν άδεια/ες.",
    "modules-roles-NewRole-noMember": "Δεν υπάρχουν μέλη",
    "modules-roles-NewRole-placeholder-name": "Όνομα",
    "modules-roles-NewRole-searchHint": "Αναζήτηση με βάση το όνομα, το επώνυμο ή τη λέξη-κλειδί",
    "modules-roles-Newrole-newrole": "Δημιουργία νέου ρόλου",
    "modules-roles-RoleList-table-column1": "Όνομα ρόλου",
    "modules-roles-RoleList-table-column2": "# Χρήστες",
    "modules-roles-Roles-btnCreateRole": "Δημιουργία νέου ρόλου",
    "modules-roles-Roles-btnDeleteRole": "Διαγραφή ρόλου",
    "modules-roles-Roles-confirmDeleteRole": "Είστε σίγουροι ότι θέλετε να διαγράψετε τον ρόλο;",
    "modules-roles-Roles-roles": "Διαχείριση ρόλων χρηστών",
    "modules-settings-Admin-admin": "Εκπρόσωπος",
    "modules-settings-Admin-btnActivateAdmin": "Ενεργοποίηση",
    "modules-settings-Admin-btnCancelNewAdmin": "Ακύρωση",
    "modules-settings-Admin-btnCreateNewAdmin": "Δημιουργία νέου αντιπροσώπου",
    "modules-settings-Admin-btnDeactivateAdmin": "Απενεργοποίηση",
    "modules-settings-Admin-btnDeleteAdmin": "Διαγραφή",
    "modules-settings-Admin-btnSaveNewAdmin": "Αποθήκευση",
    "modules-settings-Admin-confirmDeleteAdmin": "Είστε σίγουροι ότι θέλετε να διαγράψετε τον εκπρόσωπο;",
    "modules-settings-Admin-search": "Εισάγετε τον όρο αναζήτησης",
    "modules-settings-Admin-setAdminEnabled": "Είστε σίγουρος ότι θέλετε το {{adminName}} να παραχωρήσει όλα τα δικαιώματα σε έναν αντιπρόσωπο. Αυτό περιλαμβάνει τη δημιουργία, επεξεργασία και διαγραφή νέων χρηστών, ομάδων και καναλιών.",
    "modules-settings-Admin-setAdminEnabled-title": "Έχει δημιουργηθεί αντιπρόσωπος",
    "modules-settings-Admin-setAdminEnabledBtnCancel": "Ακύρωση",
    "modules-settings-Admin-setAdminEnabledBtnOk": "Ενεργοποίηση",
    "modules-settings-AdminDetails-last-login": "Τελευταία σύνδεση στις",
    "modules-settings-AdminList-table-adminStatus1": "δημιουργήθηκε",
    "modules-settings-AdminList-table-adminStatus2": "απενεργοποιημένο",
    "modules-settings-AdminList-table-adminStatus3": "ενεργό",
    "modules-settings-AdminList-table-adminStatus4": "διαγράφηκε",
    "modules-settings-AdminList-table-column1": "Επώνυμο",
    "modules-settings-AdminList-table-column2": "Όνομα",
    "modules-settings-AdminList-table-column3": "Ηλεκτρονικό ταχυδρομείο",
    "modules-settings-AdminList-table-column4": "Αριθμός κινητού τηλεφώνου",
    "modules-settings-AdminList-table-column6": "Δραστηριότητα",
    "modules-settings-AdminList-table-column7": "Τελευταία σύνδεση",
    "modules-settings-AdminList-table-row-never-log-in": "ποτέ",
    "modules-settings-ApiCredentialsDialog-close-button": "Κλείστε το",
    "modules-settings-ApiCredentialsDialog-column-authtoken": "Κουπόνι ελέγχου ταυτότητας",
    "modules-settings-ApiCredentialsDialog-column-fingerprint": "Αποτυπώματα πιστοποιητικού χρήστη",
    "modules-settings-ApiCredentialsDialog-column-monitoring-url": "URL παρακολούθησης",
    "modules-settings-ApiCredentialsDialog-column-password": "Κωδικός πρόσβασης",
    "modules-settings-ApiCredentialsDialog-column-username": "Όνομα χρήστη",
    "modules-settings-ApiCredentialsDialog-column-validTill": "Ισχύει μέχρι",
    "modules-settings-ApiCredentialsDialog-column-validTill-expired": "Λειτούργησε",
    "modules-settings-ApiCredentialsDialog-copy-url-button": "Αντιγραφή URL",
    "modules-settings-ApiCredentialsDialog-download-cert-descr": "Μπορείτε να ελέγξετε την εγκυρότητα του πιστοποιητικού με το δημόσιο κλειδί για το αρχείο .CRT.",
    "modules-settings-ApiCredentialsDialog-download-crt-certifcate-button": "Λήψη πιστοποιητικού .CRT",
    "modules-settings-ApiCredentialsDialog-edit-button": "Επεξεργασία",
    "modules-settings-ApiCredentialsDialog-monitoring-url-descr": "Με αυτή τη διεύθυνση URL, μπορείτε να παρακολουθείτε την κατάσταση των εισαγωγών χρησιμοποιώντας το σύστημα παρακολούθησης. Κατά τη διάρκεια αυτής της διαδικασίας δεν διαβιβάζονται προσωπικά δεδομένα. Η μορφή του αιτήματος περιγράφεται στην τεκμηρίωση του API.",
    "modules-settings-ApiCredentialsDialog-new-certificate-button": "Αποστολή νέου πιστοποιητικού",
    "modules-settings-ApiCredentialsDialog-new-monitoring-url-button": "Δημιουργία νέας διεύθυνσης URL",
    "modules-settings-ApiCredentialsDialog-new-password-button": "Δημιουργία νέου κωδικού πρόσβασης",
    "modules-settings-ApiCredentialsDialog-title": "Πληροφορίες σύνδεσης",
    "modules-settings-DeleteApiUserDialog-text": "Αυτό θα σταματήσει τον αυτόματο συγχρονισμό και οι πληροφορίες σύνδεσης και η διεύθυνση URL παρακολούθησης θα διαγραφούν.",
    "modules-settings-DeleteApiUserDialog-title": "Θέλετε πραγματικά να απενεργοποιήσετε το API;",
    "modules-settings-NewAdmin-hint": "Μπορεί να δημιουργεί, να επεξεργάζεται και να διαγράφει χρήστες, ομάδες και κανάλια. Ωστόσο, ο εκπρόσωπος δεν μπορεί να δημιουργήσει άλλους εκπροσώπους.",
    "modules-settings-NewAdmin-hint-title": "Ο εκπρόσωπος λαμβάνει όλα τα δικαιώματα διαχειριστή.",
    "modules-settings-NewAdmin-newadmin": "Δημιουργία νέου αντιπροσώπου",
    "modules-user-AdminList-table-auditLog-active": "Ενεργοποιείται στις",
    "modules-user-AdminList-table-auditLog-created": "Δημιουργήθηκε στις",
    "modules-user-AdminList-table-auditLog-deactive": "Απενεργοποιημένο σε",
    "modules-user-AdminList-table-auditLog-delete": "Διαγράφηκε στις",
    "modules-user-ChooseCSVExport-ExportDevices-descr": "Εξαγωγή όλων των χρησιμοποιούμενων συσκευών σε αρχείο CSV.",
    "modules-user-ChooseCSVExport-title": "Επιλέξτε ποια δεδομένα θα εξαγάγετε",
    "modules-user-ChooseTemplate-csv-detail": "Απλοποιημένο CSV για τη δημιουργία χρηστών μίας χρήσης",
    "modules-user-ChooseTemplate-ldap-detail": "Εκτεταμένο CSV για συνεχή ενημέρωση LDAP και αυτόματες αναθέσεις σε ομάδες και κανάλια",
    "modules-user-ChooseTemplate-title": "Κατεβάστε ένα πρότυπο εισαγωγής",
    "modules-user-Keyword-deleteKeyword": "Θέλετε πραγματικά να διαγράψετε τη λέξη-κλειδί {{keyword}} και όλες τις περιπτώσεις όπου χρησιμοποιείται;",
    "modules-user-Keyword-jumpToUser": "Θέλετε να απορρίψετε τις τρέχουσες αλλαγές και να μεταβείτε στο {{displayName}}?",
    "modules-user-Keyword-searchHint": "Αναζήτηση με λέξη-κλειδί",
    "modules-user-Keyword-showUsage": "Εμφάνιση",
    "modules-user-Keyword-table-column1": "Λέξη-κλειδί",
    "modules-user-Keyword-table-column2": "Εφαρμογή",
    "modules-user-Keyword-usageKeyword": "{{count}} x",
    "modules-user-NewUser-btnNewUserCancel": "Ακύρωση",
    "modules-user-NewUser-btnNewUserSave": "Αποθήκευση",
    "modules-user-NewUser-keyword": "Λέξη-κλειδί",
    "modules-user-NewUser-keywordEdit": "Επεξεργασία λέξεων-κλειδιών",
    "modules-user-NewUser-keywordEditEnd": "Κλείσιμο συντάκτη",
    "modules-user-NewUser-newuser": "Δημιουργία δεδομένων χρήστη",
    "modules-user-User-ImportVorlageFile": "import_de.csv",
    "modules-user-User-ImportVorlageLdapFile": "import_ldap_de.csv",
    "modules-user-User-account-id": "ginlo ID",
    "modules-user-User-assignVoucher": "Θέλετε να εκχωρήσετε μια άδεια χρήσης;",
    "modules-user-User-assignVoucher-title": "{{displayName}} δημιουργήθηκε.",
    "modules-user-User-assignVoucherBtnCancel": "Αναθέστε αργότερα ",
    "modules-user-User-assignVoucherBtnOk": "Εκχώρηση άδειας χρήσης",
    "modules-user-User-assignVouchersByImportID": "Θέλετε να εκχωρήσετε αυτόματα μια άδεια χρήσης σε όλους τους εισαγόμενους χρήστες;",
    "modules-user-User-assignVouchersByImportID-title": "Επιτυχής εισαγωγή χρηστών: {{importedUserCount}}",
    "modules-user-User-assignVouchersByImportIDBtnOk": "Εκχώρηση αδειών χρήσης",
    "modules-user-User-btnCancelNewUser": "Ακύρωση",
    "modules-user-User-btnCreateNewuser": "Δημιουργία δεδομένων χρήστη",
    "modules-user-User-btnDeleteUser": "Διαγραφή χρήστη",
    "modules-user-User-btnImportUser": "Εισαγωγή δεδομένων χρήστη",
    "modules-user-User-btnImportVorlage": "Εισαγωγή & εξαγωγή CSV",
    "modules-user-User-btnRevokeLicence": "Άδεια κλειδώματος",
    "modules-user-User-btnSaveNewUser": "Αποθήκευση",
    "modules-user-User-btnSaveUser": "Αποθήκευση αλλαγών",
    "modules-user-User-confirmDeleteUser": "Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;",
    "modules-user-User-confirmRevokeLicense": "Είστε σίγουροι ότι θέλετε να μπλοκάρετε την άδεια χρήσης του χρήστη, η οποία θα μπλοκάρει την πρόσβαση του χρήστη στην εφαρμογή;",
    "modules-user-User-email-hinttext": "e-mail",
    "modules-user-User-importLdap-applyData": "Συνεχίστε",
    "modules-user-User-importLdap-cancel": "Ακύρωση",
    "modules-user-User-importLdap-done": "Κλείστε το",
    "modules-user-User-importLdap-exportApplyData": "Εξαγωγή έκθεσης εισαγωγής",
    "modules-user-User-importLdap-exportPreviewData": "Έκθεση εξαγωγής",
    "modules-user-User-importLdap-state-applyData": "Τα δεδομένα υποβάλλονται σε επεξεργασία",
    "modules-user-User-importLdap-state-done": "Υιοθετημένες αλλαγές",
    "modules-user-User-importLdap-state-fatal": "Η ενέργεια ακυρώθηκε λόγω σφάλματος.",
    "modules-user-User-importLdap-state-preflight": "Τα δεδομένα επανεξετάζονται.",
    "modules-user-User-importLdap-state-waitConfirm": "Αναθεώρηση ολοκληρώθηκε",
    "modules-user-User-importLdap-table-column1": "Κατηγορία",
    "modules-user-User-importLdap-table-column2": "Αριθμός καταχωρήσεων",
    "modules-user-User-importLdap-table-column3": "Σημειώσεις",
    "modules-user-User-importLdap-table-column4": "Προειδοποιήσεις",
    "modules-user-User-importLdap-table-column5": "Σφάλματα",
    "modules-user-User-importLdap-table-row1": "Χρήστες",
    "modules-user-User-importLdap-table-row2": "Ομάδες",
    "modules-user-User-importLdap-table-row3": "Κανάλια",
    "modules-user-User-importLdap-table-row4": "Λέξεις-κλειδιά",
    "modules-user-User-search": "Εισάγετε τον όρο αναζήτησης",
    "modules-user-User-user": "Διαχείριση χρηστών ({{v1}})",
    "modules-user-UserList-popover-phone": "Παρακαλούμε εισάγετε αριθμούς κινητών τηλεφώνων με τη μορφή '012345678910'. Για αριθμούς εκτός Γερμανίας, παρακαλείστε να συμπεριλάβετε τον κωδικό της χώρας, αλλά όχι το πρόθεμα \"0\", δηλαδή \"+4312345678910\".",
    "modules-user-UserList-table-cmdDeleteUser": "Διαγραφή χρήστη",
    "modules-user-UserList-table-cmdEdit": "Επεξεργασία",
    "modules-user-UserList-table-cmdRevokeLicense": "Άδεια κλειδώματος",
    "modules-user-UserList-table-column1": "Επώνυμο",
    "modules-user-UserList-table-column2": "Όνομα",
    "modules-user-UserList-table-column3": "Ηλεκτρονικό ταχυδρομείο",
    "modules-user-UserList-table-column4": "Αριθμός κινητού τηλεφώνου",
    "modules-user-UserList-table-column6": "Λέξη-κλειδί",
    "modules-user-UserList-table-placeHolder-column1": "Επώνυμο",
    "modules-user-UserList-table-placeHolder-column2": "Όνομα",
    "modules-user-UserList-table-placeHolder-column3": "Ηλεκτρονικό ταχυδρομείο",
    "modules-user-UserList-table-placeHolder-column4": "Αριθμός κινητού τηλεφώνου",
    "modules-user-UserList-table-placeHolder-column6": "Λέξη-κλειδί",
    "modules-user-UserList-table-placeHolder-column8": "Τμήμα",
    "modules-user-UserList-table-roomWriterHeader": "Πρόσβαση εγγραφής",
    "modules-user-UserList-table-userStatus1": "Χρήστης που δημιουργήθηκε",
    "modules-user-UserList-table-userStatus2": "Άδεια που έχει εκχωρηθεί",
    "modules-user-UserList-table-userStatus3": "Άδεια ενεργή",
    "modules-user-UserList-table-userStatus4": "Η άδεια έληξε",
    "modules-user-UserList-table-userStatusDesc-LicenseExpired": "Η άδεια χρήσης έληξε - ο χρήστης μπλοκαρίστηκε",
    "modules-user-UserList-table-userStatusDesc-LicenseRevoke": "Ανάκληση άδειας - αποκλεισμός χρήστη",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAccepted": "Άδεια ενεργή - αποδεκτή η διαχείριση",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailFailed": "Η επικύρωση email ακυρώθηκε",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedEmailRequired": "Εκκρεμεί επικύρωση email",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneFailed": "Η επικύρωση αριθμού κινητού τηλεφώνου ακυρώθηκε",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountAcceptedPhoneRequired": "Εκκρεμεί επικύρωση αριθμού κινητού τηλεφώνου",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDeleted": "Διαγραφή λογαριασμού από τον χρήστη",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountDenied": "Απορρίφθηκε το αίτημα της διοίκησης",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountNew": "Δημιουργήθηκε χρήστης - δεν έχει εκχωρηθεί άδεια χρήσης",
    "modules-user-UserList-table-userStatusDesc-ManagedAccountPendingValidation": "Εκχωρηθείσα άδεια - εκκρεμεί η διαχείριση ",
    "modules-user-UserList-userCreated": "Ο χρήστης {{firstName}} {{lastName}} δημιουργήθηκε με επιτυχία.",
    "modules-user-UserList-userCreated-btnAssignLicense": "Εκχώρηση άδειας χρήσης",
    "modules-user-UserList-userCreated-btnAssignLicenseLater": "Εκχώρηση άδειας αργότερα",
    "modules-user-alert-btnSendManagementRequest": "Επαναποστολή αιτήματος",
    "modules-user-alert-sendManagementRequestHint": "Ο χρήστης απέρριψε το αίτημα για δικαιώματα διαχείρισης.",
    "modules-user-device-deviceList": "Συσκευές σε χρήση",
    "modules-user-device-unknown-devicename": "Άγνωστο όνομα συσκευής",
    "modules-user-managementrequest-messagesent": "Το αίτημα για τη διαχείριση της εφαρμογής έχει αποσταλεί στον χρήστη.",
    "modules-user-password-btnSendRecoveryEMail": "Αποστολή κωδικού ανάκτησης",
    "modules-user-password-btnShowRecoveryCode": "Εμφάνιση κωδικού ανάκτησης",
    "modules-user-password-revoverPasswordHint": "Αυτός ο χρήστης έχει ξεχάσει τον κωδικό πρόσβασης της συσκευής του. Παρακαλούμε στείλτε του τον κωδικό ανάκτησης.",
    "modules-user-recover-bottomBarText": "Ο χρήστης έχει ξεχάσει τον κωδικό πρόσβασής του.",
    "modules-user-recoverycode-messagesent": "Ο κωδικός ανάκτησης βρίσκεται καθ' οδόν προς τον χρήστη. Θα βρείτε ένα αντίγραφο του e-mail στα εισερχόμενά σας.",
    "modules-user-recoverycode-title": "Κωδικός ανάκτησης",
    "okay": "Εντάξει",
    "phone": "Αριθμός κινητού τηλεφώνου",
    "save": "Αποθήκευση",
    "secret": "Μυστικό",
    "settings-admin": "Διαχειριστής",
    "settings-admin-change-pwd": "Αλλαγή κωδικού πρόσβασης",
    "settings-btnDeleteCompany": "Διαγραφή λογαριασμού",
    "settings-company-label-ustIdNr": "Αριθμός ΦΠΑ",
    "settings-companycockpittoken": "Εγκατάσταση EMM",
    "settings-companycockpittoken-documentation-link": "Τεκμηρίωση",
    "settings-companycockpittoken-documentation-link2": "https://app-help.ginlo.net/docs/en/cockpit/index.html?highlight=token#emm-rollout",
    "settings-companycockpittoken-switch-enable-desc": "Εάν ο οργανισμός σας χρησιμοποιεί μια λύση διαχείρισης κινητικότητας, μπορείτε να ενεργοποιήσετε την αυτόματη εγγραφή για την ανάπτυξη.",
    "settings-companycockpittoken-switch-enable-label": "Αυτόματη εγγραφή EMM",
    "settings-companycockpittoken-switch-force-desc": "Εάν αυτή η λειτουργία δεν είναι ενεργοποιημένη, η εγγραφή στον αγγελιοφόρο θα πραγματοποιηθεί πλήρως αυτόματα. Λάβετε υπόψη τις οδηγίες στο {{v1}}.",
    "settings-companycockpittoken-switch-force-label": "Επιβολή γρήγορης εγγραφής στο τερματικό",
    "settings-companycockpittoken-token-desc": "Χρειάζεστε το διακριτικό ασφαλείας για την αυτόματη εγγραφή στο EMM. Ανατρέξτε στη διεύθυνση {{v1}}.",
    "settings-companycockpittoken-token-label": "Το διακριτικό ασφαλείας είναι: ",
    "settings-companycockpittoken-token-notset": "Δεν έχει ενεργοποιηθεί η αυτόματη εγγραφή",
    "settings-companyinformation": "Στοιχεία της εταιρείας",
    "settings-companylanguage": "Ρυθμίσεις γλώσσας",
    "settings-companylanguage-de": "Γερμανικά",
    "settings-companylanguage-desc": "Γλώσσα για την εταιρεία σας κατά την αποστολή προσκλήσεων ηλεκτρονικού ταχυδρομείου και άλλων επικοινωνιών.",
    "settings-companylanguage-el": "Ελληνική",
    "settings-companylanguage-en": "Αγγλικά",
    "settings-companylanguage-es": "Ισπανικά",
    "settings-companylanguage-fr": "Γαλλικά",
    "settings-companyprofilimage": "Εικόνα εταιρικού προφίλ",
    "settings-companyprofilimage-btnImportImage": "Επιλέξτε εικόνα.",
    "settings-companyprofilimage-desc": "Επιλέξτε μια εικόνα προφίλ που θα εμφανίζεται στα κανάλια δίπλα στις ειδοποιήσεις.",
    "settings-companyprofilimage-desc2": "Σύσταση μεγέθους 200 x 200 pixels.",
    "settings-confirmdeletecompanydialog-desc": "Με τη διαγραφή του λογαριασμού σας θα διαγραφούν όλοι οι λογαριασμοί χρηστών και θα μπλοκαριστούν οι αντίστοιχες άδειες χρήσης.",
    "settings-confirmdeletecompanydialog-hint": "Εισάγετε τον κωδικό πρόσβασης",
    "settings-confirmdeletecompanydialog-ok": "Διαγράψτε αμετάκλητα τον λογαριασμό",
    "settings-confirmdeletecompanydialog-title": "Είστε σίγουροι ότι θέλετε να διαγράψετε τον λογαριασμό σας;",
    "settings-ldap-sync-delete-api-user-button": "Απενεργοποίηση API",
    "settings-ldap-sync-descr-text": "Το API του ginlo είναι μια υπηρεσία RESTful, την οποία μπορείτε να χρησιμοποιήσετε για να αυτοματοποιήσετε τη δημιουργία, επεξεργασία και διαγραφή χρηστών, ομάδων και καναλιών.",
    "settings-ldap-sync-documentation-link-pretext": "Μπορείτε να βρείτε την αντίστοιχη διεύθυνση {{v1}} εδώ (μόνο στα γερμανικά).",
    "settings-ldap-sync-documentation-link-text": "Τεκμηρίωση",
    "settings-ldap-sync-documentation-link1": "https://app-help.ginlo.net/docs/en/cockpit/index.html#ldap-data",
    "settings-ldap-sync-ldap-activated": "Το API είναι ενεργοποιημένο",
    "settings-ldap-sync-ldap-activated-hint": "Είναι πιθανό οι χειροκίνητες αλλαγές να αντικατασταθούν.",
    "settings-ldap-sync-new-api-user-button": "Ενεργοποίηση API",
    "settings-ldap-sync-show-api-user-credentials-button": "Πληροφορίες σύνδεσης",
    "settings-ldap-sync_title": "ginlo Business API",
    "settings-order-in-year": "€ ετησίως",
    "settings-order-input-hint": "0 έως 500 άδειες",
    "settings-order-tax-info": "συν τον αναλογούντα ΦΠΑ",
    "settings-orderhistory": "Ιστορικό παραγγελιών",
    "settings-orderhistory-agb": "Ναι, συμφωνώ με το {{v1}}.",
    "settings-orderhistory-agb-link": "Όροι & Προϋποθέσεις",
    "settings-orderhistory-license": "Άδεια",
    "settings-orderhistory-licenses": "Άδειες",
    "settings-orderhistory-order": "Παραγγείλετε τώρα (δεσμευτικό)",
    "settings-orderhistory-privacy": "Ναι, έχω διαβάσει και κατανοήσει το {{v1}}.",
    "settings-orderhistory-privacy-link": "Πολιτική απορρήτου",
    "settings-orderhistory-table-column-date": "Ημερομηνία",
    "settings-primarydomain": "Κύριος τομέας",
    "settings-pwd-current-hint": "Εισάγετε τον τρέχοντα κωδικό πρόσβασης",
    "settings-save-error": "Κατά την αποθήκευση των δεδομένων προέκυψε το ακόλουθο σφάλμα:",
    "settings-settings": "Ρυθμίσεις",
    "settings-stellvertreter": "Εκπρόσωπος",
    "settings-stellvertreter-btn-edit": "Επεξεργασία αντιπροσώπου",
    "settings-stellvertreter-details": "{{activeAdminCount}} ενεργό {{adminCount}} που έχει εκχωρηθεί",
    "settings-stellvertreter-last-login-never": "Δεν έχετε συνδεθεί ακόμα",
    "settings-stellvertreter-last-login-ok": "Τελευταία σύνδεση ",
    "settings-two-fa": "Αυθεντικοποίηση δύο παραγόντων",
    "settings-two-fa-btn-show-recovery-codes": "Εμφάνιση κωδικών ανάκτησης",
    "settings-two-fa-create-recovery-codes-dialog-btn-okay": "Αποδοχή κωδικών ανάκτησης",
    "settings-two-fa-create-recovery-codes-dialog-get-the-codes": "Αντιγράψτε αυτούς τους κωδικούς ανάκτησης",
    "settings-two-fa-create-recovery-codes-dialog-text-recovery-codes": "Οι κωδικοί ανάκτησης σας",
    "settings-two-fa-create-recovery-codes-dialog-title": "Οι κωδικοί ανάκτησης σας",
    "settings-two-fa-disable-dialog-btn-submit": "Απενεργοποίηση",
    "settings-two-fa-disable-dialog-description": "Θέλετε πραγματικά να απενεργοποιήσετε τον έλεγχο ταυτότητας δύο παραγόντων;",
    "settings-two-fa-disable-dialog-title": "Απενεργοποίηση του ελέγχου ταυτότητας δύο παραγόντων",
    "settings-two-fa-disabled": "Η 2FA είναι απενεργοποιημένη",
    "settings-two-fa-enable-dialog-btn-submit": "Υποβολή token και ενεργοποίηση 2FA",
    "settings-two-fa-enable-dialog-otp-service-name": "ginlo Business Cockpit",
    "settings-two-fa-enable-dialog-title": "Ενεργοποίηση του ελέγχου ταυτότητας δύο παραγόντων",
    "settings-two-fa-enable-dialog-token-help-text": "Παρακαλούμε σαρώστε τον κωδικό QR ή εισαγάγετε τον χειροκίνητα στην εφαρμογή ελέγχου ταυτότητας.",
    "settings-two-fa-enable-dialog-token-not-valid": "Το Token δεν είναι έγκυρο",
    "settings-two-fa-enabled": "Το 2FA είναι ενεργοποιημένο",
    "settings-two-fa-label": "Ο έλεγχος ταυτότητας δύο παραγόντων είναι:",
    "settings-two-fa-show-recovery-codes-dialog-get-the-codes": "Αντιγράψτε αυτούς τους κωδικούς ανάκτησης. Αυτοί σας παρέχουν μια σύνδεση στο πιλοτήριο όταν χάσετε το μυστικό σας.",
    "settings-two-fa-show-recovery-codes-dialog-text-recovery-codes": "Κωδικοί ανάκτησης",
    "settings-two-fa-show-recovery-codes-dialog-title": "Οι κωδικοί ανάκτησης σας",
    "state": "Κατάσταση χρήστη",
    "token": "Token",
    "validation-errormessages-maxvalue": "μέγιστο επιτρεπόμενο {{max}}",
    "validation-errormessages-minvalue": "ελάχιστο απαιτούμενο {{min}}",
    "welcome-channel-btn": "Δημιουργία καναλιών",
    "welcome-channel-description": "Τα κανάλια σας επιτρέπουν να στέλνετε ειδοποιήσεις push στους χρήστες σας. Μηνύματα, εικόνες και αρχεία μπορούν να διανεμηθούν κεντρικά στους χρήστες σας. Μπορείτε να επανεξετάσετε τα στοιχεία μετατροπής στο ταμπλό στη συνέχεια.",
    "welcome-channel-subheadline-create": "Δημιουργία καναλιών",
    "welcome-chat-subheadline": "Απλοποίηση της επικοινωνίας",
    "welcome-config-subheadline": "Διαμόρφωση εφαρμογής",
    "welcome-design-btn": "Τροποποίηση του σχεδιασμού της εφαρμογής",
    "welcome-design-description": "Εδώ έχετε τη δυνατότητα να τροποποιήσετε το σχεδιασμό της εφαρμογής ώστε να ταιριάζει στις εταιρικές οδηγίες σχεδιασμού της εταιρείας σας. Μπορείτε να αλλάξετε το λογότυπο και τα χρώματα ανά πάσα στιγμή.",
    "welcome-design-subheadline-create": "Προσαρμογή του σχεδιασμού της εφαρμογής",
    "welcome-group-btn": "Δημιουργία ομάδων",
    "welcome-group-description": "Δημιουργήστε ομάδες για να διευκολύνετε τη συνεργασία μεταξύ των χρηστών. Η δραστηριότητα των ομάδων μπορεί να προβληθεί στο ταμπλό.",
    "welcome-group-subheadline-add": "Δημιουργία ομάδων",
    "welcome-headline": "Καλώς ήρθατε {{v1}}",
    "welcome-intro": "Το ginlo Business Cockpit παρέχει ένα βολικό εργαλείο για τη διαμόρφωση του ασφαλούς ginlo Business Messenger για Android και iPhone ώστε να ταιριάζει στις ανάγκες σας.",
    "welcome-license-btn": "Εκχώρηση άδειας χρήσης",
    "welcome-license-description": "Εκχωρήστε τις διαθέσιμες άδειες χρήσης σε δημιουργημένους λογαριασμούς χρηστών. Εάν δεν έχετε αρκετές διαθέσιμες άδειες, {{v1}} την επιθυμητή ποσότητα για να καλύψετε το κενό.",
    "welcome-license-description-link": "παραγγελία",
    "welcome-license-subheadline-add": "Εκχώρηση άδειας χρήσης",
    "welcome-settings-btn": "Τροποποίηση ρυθμίσεων",
    "welcome-settings-description": "Προσαρμόστε τις απαιτήσεις ασφαλείας και τις ρυθμίσεις της εφαρμογής ώστε να ανταποκρίνονται στις προδιαγραφές ασφαλείας σας.",
    "welcome-settings-subheadline-create": "Διαμόρφωση των ρυθμίσεων της εφαρμογής",
    "welcome-user-btn": "Δημιουργία νέου χρήστη",
    "welcome-user-description": "Νέοι λογαριασμοί χρηστών μπορούν να δημιουργηθούν γρήγορα και εύκολα. Χρησιμοποιήστε τη λειτουργία \"Δημιουργία μεμονωμένου χρήστη\" ή εισαγάγετε τα δεδομένα των χρηστών χρησιμοποιώντας το πρότυπο CSV.",
    "welcome-user-subheadline": "Διαχείριση χρηστών",
    "welcome-user-subheadline-create": "Δημιουργία χρήστη"
}
;
    
    export default TranslationCommonEl;
    
