/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconInfo(props) {
  return <SvgIcon {...props}>
              <svg viewBox="0 0 20 21">
                <path fill="#7B98AB" d="M9,0 C13.9702148,0 18,4.03123819 18,9 C18,13.9716679 13.9702148,18 9,18 C4.02978516,18 0,13.9716679 0,9 C0,4.03123819 4.02978516,0 9,0 Z M9,3.99193548 C8.15819919,3.99193548 7.47580645,4.67432822 7.47580645,5.51612903 C7.47580645,6.35792985 8.15819919,7.04032258 9,7.04032258 C9.84180081,7.04032258 10.5241935,6.35792985 10.5241935,5.51612903 C10.5241935,4.67432822 9.84180081,3.99193548 9,3.99193548 Z M11.0322581,13.2096774 L11.0322581,12.3387097 C11.0322581,12.0982154 10.8372685,11.9032258 10.5967742,11.9032258 L10.1612903,11.9032258 L10.1612903,8.27419355 C10.1612903,8.03369928 9.96630072,7.83870968 9.72580645,7.83870968 L7.40322581,7.83870968 C7.16273154,7.83870968 6.96774194,8.03369928 6.96774194,8.27419355 L6.96774194,9.14516129 C6.96774194,9.38565556 7.16273154,9.58064516 7.40322581,9.58064516 L7.83870968,9.58064516 L7.83870968,11.9032258 L7.40322581,11.9032258 C7.16273154,11.9032258 6.96774194,12.0982154 6.96774194,12.3387097 L6.96774194,13.2096774 C6.96774194,13.4501717 7.16273154,13.6451613 7.40322581,13.6451613 L10.5967742,13.6451613 C10.8372685,13.6451613 11.0322581,13.4501717 11.0322581,13.2096774 Z" transform="translate(1 1.5)"/>
              </svg>
         </SvgIcon>;
}
;

export default IconInfo;
