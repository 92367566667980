import React from 'react';
import { translate } from 'react-i18next';
import TimerMixin from 'react-timer-mixin';

import RaisedButton from '../../components/buttons/RaisedButton';
import FlatButton from '../../components/buttons/FlatButton';

import { PageHeader } from '../../components/pageheader';
import { BackSubPageHeader } from '../../components/pageheader';
import { WaitDialog } from '../../components/WaitDialog';
import { ModalDialog } from '../../components/ModalDialog';

import LinearProgress from 'material-ui/LinearProgress';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';

import UserList from './UserList';
import UserDetail from './UserDetail';

import IconDownload from '../../ressources/download';
import IconDelete from '../../ressources/icon_delete_white';
import IconWarning from '../../ressources/tabelle_alert_state';
import IconSearch from '../../ressources/icon_search';
import IconTabelleStatusOn from '../../ressources/tabelle_status_on.js'
import IconTabelleStatusOnPending from '../../ressources/tabelle_status_on_pending.js'
import IconTabelleStatusInvented from '../../ressources/tabelle_status_eingeladen.js'
import IconTabelleStatusAlert from '../../ressources/tabelle_status_alert.js'
import IconCheckFilled from '../../ressources/icon_check_filled.js'

import { connect } from 'react-redux';
import { getUserList, getSingleUser, createUser, importUsers, updateUser, deleteUser, getKeywordList, deleteKeyword, assignVouchersByImportID, startImportLdap, getImportState, startApplyImport, discardImport, downloadReport } from '../../actions/index'
import DataProvider from './DataProvider';
import BaseModul from '../BaseModul';
import TextField from '../../components/textfields/TextField';

import { IsNullOrEmpty } from '../../components/StringExtensions';

import ErrorDialog from '../../components/ErrorDialog.js';

import ChooseTemplate from './ChooseTemplate.js'

import download from 'downloadjs';


const LIST_USER = 0;
const NEW_USER = 1;
const EDIT_USER = 2;
const IMPORT_USER = 3;

const styles = {
  download: {
    display: "inline-flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  iconDownload: {
    width: "32px",
    height: "32px",
    paddingRight: "10px",
    float: 'left',
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  searchText: {
    marginLeft: '25px',
    color: '#7B98AB',
    float: 'left',
    height: '60px',
    display: 'inline-flex',
    maxWidth: '260px',
  },
  importText: {
    display: "inline-flex",
    alignItems: "center",
    paddingRight: "20px",
    paddingLeft: "10px",
    fontSize: '14px',
    fontWeight: "bold",
  },
  grayText: {
    fontSize: '14px',
    color: '#7B98AB'

  },
  importNumberDiv: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  importPageHeaderDiv: {
    display: "inline-flex",
    alignItems: "center",
  }
}

class User extends BaseModul {
  constructor(props) {
    super(props);
    this.state = {
      userList: {},
      editModeButton: {
        visible: false,
        enabled: false
      },
      currentViewMode: LIST_USER,
      editUser: null,
      isPendingChanges: false,
      searchText: "",
      userDataCount: 0
    };

    this.dataprovider = new DataProvider();
    this.handleNewUserClicked = this.handleNewUserClicked.bind(this);
    this.handleCancelNewUserClicked = this.handleCancelNewUserClicked.bind(this);
    this.handleSaveNewUserClicked = this.handleSaveNewUserClicked.bind(this);
    this.handleSaveUserClicked = this.handleSaveUserClicked.bind(this);
    this.handleDownloadImportFileClicked = this.handleDownloadImportFileClicked.bind(this);
    this.handleImportFileClicked = this.handleImportFileClicked.bind(this);

    this.createUserHandler = this.createUserHandler.bind(this);
    this.createUserSuccessHandler = this.createUserSuccessHandler.bind(this);
    this.jumpToAssignVoucher = this.jumpToAssignVoucher.bind(this);
    this.requestFailedErrorHandler = this.requestFailedErrorHandler.bind(this);
    this.importUsersSuccessHandler = this.importUsersSuccessHandler.bind(this);
    this.listEditModeListener = this.listEditModeListener.bind(this)

    this.getUserListSuccessHandler = this.getUserListSuccessHandler.bind(this);
    this.getKeywordSuccessHandler = this.getKeywordSuccessHandler.bind(this);

    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.fileContentLoaded = this.fileContentLoaded.bind(this);
    this.onFileInputClicked = this.onFileInputClicked.bind(this);

    this.onRowSelection = this.onRowSelection.bind(this);
    this.handleSaveUpdateUserClicked = this.handleSaveUpdateUserClicked.bind(this);
    this.updateUserHandler = this.updateUserHandler.bind(this);
    this.updateUserSuccessHandler = this.updateUserSuccessHandler.bind(this);
    this.updateUserErrorHandler = this.updateUserErrorHandler.bind(this);

    this.beginDeleteUserClicked = this.beginDeleteUserClicked.bind(this);
    this.callDeleteUser = this.callDeleteUser.bind(this);
    this.deleteUserSuccessHandler = this.deleteUserSuccessHandler.bind(this);

    this.callDeleteKeyword = this.callDeleteKeyword.bind(this);
    this.beginKeywordDeleteClicked = this.beginKeywordDeleteClicked.bind(this);
    this.deleteKeywordSuccessHandler = this.deleteKeywordSuccessHandler.bind(this);
    this.deleteKeywordErrorHandler = this.deleteKeywordErrorHandler.bind(this);

    this.beginSwitchUserClicked = this.beginSwitchUserClicked.bind(this);
    this.callSwitchUser = this.callSwitchUser.bind(this);

    this.handleUserAlertStateReset = this.handleUserAlertStateReset.bind(this);
    this.handleOnPendingChanges = this.handleOnPendingChanges.bind(this);
    this.cancelNewUserConfirm = this.cancelNewUserConfirm.bind(this);

    this.callAssignVouchersByImportID = this.callAssignVouchersByImportID.bind(this);
    this.assignVouchersByImportIDSuccessHandler = this.assignVouchersByImportIDSuccessHandler.bind(this);
    this.assignVouchersByImportIDErrorHandler = this.assignVouchersByImportIDErrorHandler.bind(this);

    this.handleStartApplyImport = this.handleStartApplyImport.bind(this);
    this.startApplyImportSuccessHandler = this.startApplyImportSuccessHandler.bind(this);
    this.startLdapImportSuccessHandler = this.startLdapImportSuccessHandler.bind(this);

    this.handleDiscardImport = this.handleDiscardImport.bind(this);
    this.discardImportSuccessHandler = this.discardImportSuccessHandler.bind(this);

    this.handleDownloadReport = this.handleDownloadReport.bind(this);
    this.downloadReportSuccessHandler = this.downloadReportSuccessHandler.bind(this);
    this.handleImportDoneClicked = this.handleImportDoneClicked.bind(this);

    this.handleGetImportState = this.handleGetImportState.bind(this);
    this.getImportStateSuccessHandler = this.getImportStateSuccessHandler.bind(this);
    this.getImportStateErrorHandler = this.getImportStateErrorHandler.bind(this);

    this.handleReloadData = this.handleReloadData.bind(this);
    this.handleReloadDiscardData = this.handleReloadDiscardData.bind(this);
    this.getUserListSuccessNullHandler = this.getUserListSuccessNullHandler.bind(this);
    this.getSingleUserSuccessHandler = this.getSingleUserSuccessHandler.bind(this);
    this.handlerReloadDeletedData = this.handlerReloadDeletedData.bind(this);

  }

  handleOnPendingChanges(pending) {
    this.setState({
      isPendingChanges: pending
    });
    if (pending)
    {
      this.setDirty();
    }
    else
    {
      this.clearDirty();
    }
  }

  onFileInputClicked(evt) {
    //falls es bereits eine Datei hochgeladen (analog: Dialog abgebrochen) wurde
    //und der Nutzer wählt für den Upload dieselbe Datei erneut
    //aus, dann wird der onChange-Event nicht ausgelöst
    //-> daher lösen wir den FileInput manuell aus
    evt.target.value = null;
  }

  handleUserAlertStateReset(toListView) {
    this.loadUserList(false);
    if (toListView === true)
    {
      this.setState(
        {
          currentViewMode: LIST_USER
        }
      );
    }
  }

  handleNewUserClicked() {
    this.setState(
      {
        currentViewMode: NEW_USER
      }
    );
  }

  handleSaveNewUserClicked() {
    this.refs.newuser.getWrappedInstance().handleNewUserSaveClicked();
  }

  handleSaveUpdateUserClicked() {
    this.refs.updateuser.getWrappedInstance().handleUpdateUserSaveClicked();
  }

  cancelNewUserConfirm() {
    this.loadUserList(false);
    this.setState({
      isPendingChanges: false
    });
    this.setState(
      {
        currentViewMode: LIST_USER
      }
    );
    this.clearDirty();
  }

  handleCancelNewUserClicked() {
    const {t} = this.props;
    //TODO: das ganze isPending-Zeug sollte in BaseModul allgemein bereitgestellt werden ->Vgl. auch AbstractGroups
    if (this.state.isPendingChanges)
    {
      let yesNoDialog = {
        text: t("components-ModalDialog-looseDataQuestion"),
        button1Text: t("components-ModalDialog-no"),
        button1Handler: null,
        button2Text: t("components-ModalDialog-yes"),
        button2Handler: this.cancelNewUserConfirm,
        title: t("components-ModalDialog-Warning"),
      };
      this.refs.modalDialog.open(yesNoDialog);
    }
    else
    {
      this.cancelNewUserConfirm();
    }
  /*this.loadUserList(false);
  this.setState({isPendingChanges:false});
  this.setState(
    {
      currentViewMode: LIST_USER
    }
  );*/
  }

  createUserHandler(userData) {
    this.refs.waitDialog.open();
    if (userData.department === undefined)
    {
      userData.department = ''
    }
    this.props.dispatch(createUser(userData.name, userData.firstName, userData.eMailAdress, userData.phoneNumber, userData.keywords.join(","), userData.department, this.createUserSuccessHandler, this.requestFailedErrorHandler))
  }

  createUserSuccessHandler(json) {
    const {t} = this.props;
    if (!(typeof json.CompanyUser === "undefined"))
    {
      this.dataprovider.insertUser(json)
    }
    if (this.state.currentViewMode === NEW_USER)
    {
      this.setState(
        {
          currentViewMode: LIST_USER
        }
      );
    }
    else
    {
      this.refs.userlist.getWrappedInstance().clearPlaceHolder();
    }
    this.handleOnPendingChanges(false)

    this.refs.waitDialog.close();
    this.loadUserList(false);

    if (!(typeof json.CompanyUser === "undefined"))
    {
      let displayName = this.dataprovider.getDisplayName(json.CompanyUser);
      let title1 = t("modules-user-User-assignVoucher-title", {
        displayName: displayName
      })
      this.refs.modalDialog.open({
        title: title1,
        text: t("modules-user-User-assignVoucher"),
        button1Text: t("modules-user-User-assignVoucherBtnCancel"),

        button2Text: t("modules-user-User-assignVoucherBtnOk"),
        button2Handler: this.jumpToAssignVoucher
      });
    }
  }

  jumpToAssignVoucher() {
    const {router} = this.props;
    router.push("/license/assignFreeVoucher")
  }

  requestFailedErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);

  }


  updateUserHandler(userData) {
    this.refs.waitDialog.open();

    this.props.dispatch(updateUser(userData.guid, userData.name, userData.firstName, userData.eMailAdress, userData.phoneNumber, userData.keywords.join(","), userData.department, this.updateUserSuccessHandler, this.updateUserErrorHandler))
  }

  updateUserSuccessHandler(json) {
    if (!(typeof json.CompanyUser === "undefined"))
    {
      // TODO : Update User im Datenmodell
      //this.dataprovider.insertUser(json)
    }
    this.handleOnPendingChanges(false)

    this.setState(
      {
        currentViewMode: LIST_USER
      }
    );
    this.refs.waitDialog.close();
    this.loadUserList(false);
  }

  updateUserErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    /// einzelnen Datensatz aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);
  }

  handleChangeData(event, name) {
    if (name === "search")
    {
      this.setState({
        searchText: event.target.value
      });
    }
    else
    {
      let oldData = JSON.parse(JSON.stringify(this.state.placeHolder))
      oldData[name] = event.target.value;
      this.setState({
        placeHolder: oldData
      });
    }
  }

  onFileLoaded(e) {
    e.preventDefault();

    var files;
    if (e.dataTransfer)
    {
      files = e.dataTransfer.files;
    }
    else if (e.target)
    {
      files = e.target.files;
    }

    if (files.length === 1)
    {
      this.refs.waitDialog.open();
      var fileReader = new FileReader();
      fileReader.onloadend = this.fileContentLoaded;
      fileReader.readAsDataURL(files[0])
    }
  }

  importUsersSuccessHandler(json) {
    const {t} = this.props;

    let importID;

    if (!(typeof json.data === "undefined"))
    {
      for (var i = 0; i < json.data.length; i++)
      {
        if (!(typeof json.data[i].CompanyUser === "undefined"))
        {
          if (IsNullOrEmpty(importID) && !(IsNullOrEmpty(json.data[i].CompanyUser.importID)))
          {
            importID = json.data[i].CompanyUser.importID;
          }

          this.dataprovider.insertUser(json.data[i])
        }
      }

      this.refs.waitDialog.close();
      this.loadUserList(false);

      let title1 = t("modules-user-User-assignVouchersByImportID-title", {
        importedUserCount: json.data.length
      })

      if (!IsNullOrEmpty(importID))
      {
        this.refs.modalDialog.open({
          title: title1,
          text: t("modules-user-User-assignVouchersByImportID"),
          button1Text: t("modules-user-User-assignVoucherBtnCancel"),

          button2Text: t("modules-user-User-assignVouchersByImportIDBtnOk"),
          button2Handler: () => this.callAssignVouchersByImportID(importID)
        });
      }
    }
  }

  discardImportSuccessHandler(json) {
    this.refs.waitDialog.close();
    this.loadUserList(false);
    this.setState(
      {
        currentViewMode: LIST_USER,
        importState: null
      });
  }

  startLdapImportSuccessHandler(json) {
    this.refs.waitDialog.close();
    if (typeof json.data !== "undefined")
    {
      this.setState(
        {
          currentViewMode: IMPORT_USER,
          importState: JSON.parse(JSON.stringify(json.data))
        });
      if (this.showImportProgress(this.state.importState.ImportState.mode))
      {
        this.timer = TimerMixin.setTimeout(this.handleGetImportState, 1000);
      }
    }
  }

  getImportStateSuccessHandler(json) {
    if (typeof json.data !== "undefined")
    {
      this.setState(
        {
          currentViewMode: IMPORT_USER,
          importState: JSON.parse(JSON.stringify(json.data))
        });
      if (this.showImportProgress(this.state.importState.ImportState.mode))
      {
        setTimeout(this.handleGetImportState, 1000);
      }
    }
  }

  getImportStateErrorHandler(errorJO) {
    this.refs.waitDialog.close();

    var newImportState = this.state.importState;

    if (newImportState != null)
    {
      newImportState.ImportState.mode = "fatal";
    }

    this.setState({
      importState: newImportState
    })

    if (errorJO.ident)
    {
      this.refs.errorDialog.open({
        errorIdent: errorJO.ident
      });
    }
  }

  handleImportDoneClicked() {
    this.discardImportSuccessHandler();
  }

  startApplyImportSuccessHandler(json) {
    this.refs.waitDialog.close();
    if (typeof json.data !== "undefined")
    {
      this.setState(
        {
          currentViewMode: IMPORT_USER,
          importState: JSON.parse(JSON.stringify(json.data))
        });
      if (this.showImportProgress(this.state.importState.ImportState.mode))
      {
        setTimeout(this.handleGetImportState, 1000);
      }
    }
  }

  downloadReportSuccessHandler(json) {
    this.refs.waitDialog.close();
    // IN das Filesystem speichern
    if (typeof json.data === "string")
    {
      download("data:;base64," + json.data, "report.csv", "text/csv");
    }
  }

  callAssignVouchersByImportID(importID) {
    this.setState({
      importID: importID
    })
    this.refs.waitDialog.open();
    this.props.dispatch(assignVouchersByImportID(importID, this.assignVouchersByImportIDSuccessHandler, this.assignVouchersByImportIDErrorHandler))
  }

  assignVouchersByImportIDSuccessHandler() {
    this.refs.waitDialog.close();

    this.setState({
      importID: null
    })

    this.loadUserList(false);
  }

  assignVouchersByImportIDErrorHandler(errorJO) {
    this.refs.waitDialog.close();

    this.setState({
      importID: null
    })

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);

  }

  fileContentLoaded(e) {
    var content = e.target.result;
    var base64 = content.substr(content.indexOf(',') + 1)

    if (base64.indexOf("IkFDVElPT") === 0 || base64.indexOf("77u/IkFDVElPT") === 0)
    {
      this.progressStartTime = Date.now();
      this.props.dispatch(startImportLdap(base64, this.startLdapImportSuccessHandler, this.requestFailedErrorHandler))
      this.refs.waitDialog.close();

    }
    else
    {
      this.props.dispatch(importUsers(base64, this.importUsersSuccessHandler, this.requestFailedErrorHandler))
    }
  }

  handleImportFileClicked() {
    //this.refs.fileInput.click();
  }

  handleStartApplyImport() {
    this.progressStartTime = Date.now();
    this.refs.waitDialog.open();
    this.props.dispatch(startApplyImport(this.state.importState.ImportState.guid, this.startApplyImportSuccessHandler, this.requestFailedErrorHandler))

  }

  handleDiscardImport() {
    this.refs.waitDialog.open();
    this.props.dispatch(discardImport(this.state.importState.ImportState.guid, this.discardImportSuccessHandler, this.requestFailedErrorHandler))

  }

  handleGetImportState() {
    this.props.dispatch(getImportState(this.state.importState.ImportState.guid, this.getImportStateSuccessHandler, this.getImportStateErrorHandler))

  }

  handleDownloadReport() {
    this.refs.waitDialog.open();
    this.props.dispatch(downloadReport(this.state.importState.ImportState.guid, this.downloadReportSuccessHandler, this.requestFailedErrorHandler))

  }

  beginKeywordDeleteClicked(keyword) {
    const {t} = this.props;
    let text1 = t('modules-user-Keyword-deleteKeyword', {
      keyword: keyword.name
    })
    this.refs.modalDialog.open({
      text: text1,
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: () => this.callDeleteKeyword(keyword)
    });
  }

  callDeleteKeyword(keyword) {
    this.setState({
      deleteKeyword: keyword
    })
    this.refs.waitDialog.open();
    this.props.dispatch(deleteKeyword(keyword.guid, this.deleteKeywordSuccessHandler, this.deleteKeywordErrorHandler))
  }

  deleteKeywordSuccessHandler() {
    this.dataprovider.deleteKeyword(this.state.deleteKeyword)
    this.refs.waitDialog.close();
    if (typeof this.refs.updateuser !== "undefined" &&
      typeof this.refs.updateuser.getWrappedInstance() !== "undefined")
    {
      this.refs.updateuser.getWrappedInstance().deleteKeyword(this.state.deleteKeyword)
    }
    if (typeof this.refs.newuser !== "undefined" &&
      typeof this.refs.newuser.getWrappedInstance() !== "undefined")
    {
      this.refs.newuser.getWrappedInstance().deleteKeyword(this.state.deleteKeyword)
    }
    this.setState({
      deleteKeyword: null
    })
  }

  deleteKeywordErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    /// Nutzer aktualisieren
    this.commonErrorHandler(errorJO, this.handleReloadData, this.handleReloadDiscardData, this.handlerReloadDeletedData);
  }

  beginSwitchUserClicked(user) {
    let displayName = this.dataprovider.getDisplayName(user)
    const {t} = this.props;
    let text1 = t('modules-user-Keyword-jumpToUser', {
      displayName: displayName
    })
    this.refs.modalDialog.open({
      text: text1,
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: () => this.callSwitchUser(user)
    });
  }

  callSwitchUser(user) {
    let newEditUser = this.dataprovider.searchUser(user.guid)
    // let oldState = this.state.currentViewMode;
    this.setState(
      {
        currentViewMode: EDIT_USER,
        editUser: newEditUser
      });
    if (typeof this.refs.updateuser !== "undefined" &&
      typeof this.refs.updateuser.getWrappedInstance() !== "undefined")
    {
      this.refs.updateuser.getWrappedInstance().loadUser(newEditUser)
    }
    if (typeof this.refs.newuser !== "undefined" &&
      typeof this.refs.newuser.getWrappedInstance() !== "undefined")
    {
      this.refs.newuser.getWrappedInstance().loadUser(newEditUser)
    }

  }

  componentDidMount() {
    super.componentDidMount();
    var showWaitDialog = true;
    if (!(typeof this.dataprovider === "undefined"))
    {
      showWaitDialog = this.dataprovider.getDataCount() === 0;
    }

    this.loadUserList(showWaitDialog);
  }

  componentWillUnmount() {
    if (typeof this.timer !== "undefined")
    {
      TimerMixin.clearTimeout(this.timer);
    }
  }

  loadUserList(showWaitDialog) {
    if (showWaitDialog)
    {
      this.refs.waitDialog.open();
    }
    this.props.dispatch(getUserList(this.dataprovider.getUserDataLastQueryDate(), this.getUserListSuccessHandler, this.requestFailedErrorHandler));
  }

  getUserListSuccessHandler(json) {
    this.dataprovider.updateData(json, true);

    this.setState({
      userDataCount: this.dataprovider.getDataCount()
    });

    this.props.dispatch(getKeywordList(this.getKeywordSuccessHandler));
    this.refs.waitDialog.close();
  }

  loadSingleUser(guid) {
    this.props.dispatch(getSingleUser(guid, this.getSingleUserSuccessHandler, this.requestFailedErrorHandler));
  }

  getSingleUserSuccessHandler(json) {
    this.dataprovider.searchAndUpdate(json, false)

    if (this.state.currentViewMode === EDIT_USER)
    {
      this.callSwitchUser(this.state.editUser);
    }
  }

  getKeywordSuccessHandler(json) {
    this.dataprovider.updateKeyword(json.data);
  }

  handleReloadData() {
    if (this.state.currentViewMode === EDIT_USER)
    {
      if (typeof this.state.editUser !== "undefined")
      {
        this.loadSingleUser(this.state.editUser.guid);
      }
      else
      {
        this.loadUserList(true);
      }
    }
    else
    {
      this.loadUserList(true);
    }

  }

  handleReloadDiscardData() {
    this.refs.waitDialog.open();
    if (this.state.currentViewMode === EDIT_USER)
    {
      if (typeof this.state.editUser !== "undefined")
      {
        this.props.dispatch(getSingleUser(this.state.editUser.guid, this.getUserListSuccessNullHandler));
      }
      else
      {
        this.props.dispatch(getUserList(null, this.getUserListSuccessNullHandler));
      }
    }
    else
    {
      this.props.dispatch(getUserList(null, this.getUserListSuccessNullHandler));
    }
  }

  handlerReloadDeletedData() {
    if (this.state.currentViewMode === EDIT_USER)
    {
      this.handleOnPendingChanges(false)
      this.deleteUserSuccessHandler();
    }
    else
    {
      this.loadUserList(true);
    }

  }

  getUserListSuccessNullHandler(json) {

    this.refs.waitDialog.close();
  }


  listEditModeListener(visible, enabled) {
    if (visible !== this.state.editModeButton.visible
      || enabled !== this.state.editModeButton.enabled)
    {
      this.setState({
        editModeButton: {
          visible: visible,
          enabled: enabled
        }
      })
    }

  }

  handleSaveUserClicked() {
    this.refs.userlist.getWrappedInstance().handleSubmitPlaceHolder();
  }

  handleDownloadImportFileClicked() {
    this.refs.chooseTemplateDialog.open();
  }

  onRowSelection(user) {
    this.loadSingleUser(user.guid);

    this.setState(
      {
        currentViewMode: EDIT_USER,
        editUser: user
      }
    );
  }

  beginDeleteUserClicked() {
    this.refs.waitDialog.close();
    const {t} = this.props;

    this.refs.modalDialog.open({
      text: t("modules-user-User-confirmDeleteUser"),
      button1Text: t("components-ModalDialog-cancel"),

      button2Text: t("components-ModalDialog-ok"),
      button2Handler: this.callDeleteUser
    });
  //this.loadUserList(false);
  }

  callDeleteUser() {
    this.refs.waitDialog.open();
    this.props.dispatch(deleteUser(this.state.editUser.guid, this.deleteUserSuccessHandler, this.requestFailedErrorHandler))
  }

  deleteUserSuccessHandler() {
    this.dataprovider.deleteUser(this.state.editUser)
    this.refs.waitDialog.close();
    this.setState(
      {
        currentViewMode: LIST_USER,
        editUser: null
      }
    );
    this.loadUserList(false);
  }

  renderCommonButton() {
    const {t} = this.props;

    if (this.state.currentViewMode === LIST_USER)
    {
      if (this.state.editModeButton.visible)
      {
        return <div>
                 <div style={ this.getBaseStyle().fabRight }>
                   <RaisedButton label={ t('modules-user-User-btnSaveUser') } primary={ true } style={ this.getBaseStyle().fab } disabled={ !this.state.editModeButton.enabled } onClick={ this.handleSaveUserClicked } />
                 </div>
               </div>
      }
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <RaisedButton label={ t('modules-user-User-btnCreateNewuser') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleNewUserClicked } />
               </div>
             </div>
    }
    if (this.state.currentViewMode === NEW_USER)
    {
      return <div>
               <div style={ this.getBaseStyle().fabRight }>
                 <FlatButton label={ t('modules-user-User-btnCancelNewUser') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleCancelNewUserClicked } />
                 <RaisedButton disabled={ !this.state.isPendingChanges || !this.state.editModeButton.enabled } label={ t('modules-user-User-btnSaveNewUser') } primary={ true } style={ this.getBaseStyle().fab } onClick={ this.handleSaveNewUserClicked }
                 />
               </div>
             </div>
    }
    if (this.state.currentViewMode === EDIT_USER)
    {
      let isAlertMode = !IsNullOrEmpty(this.state.editUser.alertState);
      let alertStateText = this.getALertStateText(this.state.editUser.alertState);
      return <div style={ isAlertMode ? {
               backgroundColor: '#991d2b',
               width: "100%",
               height: "100%",
               position: 'relative',
             } : null }>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
               </div>
               { isAlertMode
                 ?
                 //TODO: in BottomBar-Komponente auslagern (Oder als Eigenschaft des BaseModuls implementieren -> showWarning() <- ändert dann den Footer im BaseModul)
                 <div style={ { position: 'absolute', top: "50%", left: "50%", transform: ' translate(-50%,-50%)', color: '#00375D' } }>
                   <span style={ { float: 'left', textAlign: 'center', display: 'table-cell' } }><IconWarning /></span><span style={ { color: '#FFFFFF', marginLeft: 16, fontWeight: 'medium' } }>{ alertStateText }</span>
                 </div>
                 :
                 <div style={ this.getBaseStyle().fabRight }>
                   <FlatButton label={ t('modules-user-User-btnCancelNewUser') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleCancelNewUserClicked } />
                   <RaisedButton label={ t('modules-user-User-btnSaveNewUser') } primary={ true } style={ this.getBaseStyle().fab } disabled={ !this.state.editModeButton.enabled || !this.state.isPendingChanges } onClick={ this.handleSaveUpdateUserClicked } />
                 </div> }
             </div>
    }
    if (this.state.currentViewMode === IMPORT_USER)
    {
      if (this.state.importState.ImportState.mode === "waitConfirm")
      {
        return <div style={ { display: "inline-flex", width: "100%" } }>
                 <RaisedButton label={ t('modules-user-User-importLdap-cancel') } primary={ false } style={ { margin: 'auto', marginLeft: '20px' } } onClick={ this.handleDiscardImport } />
                 <div style={ { flexGrow: '1' } } />
                 <FlatButton label={ t('modules-user-User-importLdap-exportPreviewData') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleDownloadReport } />
                 <div style={ { flexGrow: '1' } } />
                 <RaisedButton label={ t('modules-user-User-importLdap-applyData') } primary={ true } style={ { margin: 'auto', marginRight: '20px' } } onClick={ this.handleStartApplyImport } />
               </div>
      }
      if (this.state.importState.ImportState.mode === "done")
      {
        return <div style={ { display: "inline-flex", width: "100%" } }>
                 <div style={ { flexGrow: '1' } } />
                 <FlatButton label={ t('modules-user-User-importLdap-exportApplyData') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleDownloadReport } />
                 <div style={ { flexGrow: '1' } } />
                 <RaisedButton label={ t('modules-user-User-importLdap-done') } primary={ true } style={ { margin: 'auto', marginRight: '20px' } } onClick={ this.handleImportDoneClicked } />
               </div>
      }

      if (this.state.importState.ImportState.mode === "fatal")
      {
        return <div style={ { display: "inline-flex", width: "100%" } }>
                 <div style={ { flexGrow: '1' } } />
                 <FlatButton label={ t('modules-user-User-importLdap-exportPreviewData') } style={ this.getBaseStyle().cancelButton } secondary={ true } onClick={ this.handleDownloadReport } />
                 <div style={ { flexGrow: '1' } } />
                 <RaisedButton label={ t('modules-user-User-importLdap-done') } primary={ true } style={ { margin: 'auto', marginRight: '20px' } } onClick={ this.handleImportDoneClicked } />
               </div>
      }
    }


  }

  getALertStateText(alertState) {
    const {t} = this.props;
    switch (alertState) {
      case 'recoverPassword':
        return t('modules-user-recover-bottomBarText');
      case 'deniedManagement':
        return t('modules-user-alert-sendManagementRequestHint');
      default:

    }
  }

  getImportStateText(importMode) {
    const {t} = this.props;
    switch (importMode) {
      case 'preflight':
        return t('modules-user-User-importLdap-state-preflight');
      case 'waitConfirm':
        return t('modules-user-User-importLdap-state-waitConfirm');
      case 'applyData':
        return t('modules-user-User-importLdap-state-applyData');
      case 'done':
        return t('modules-user-User-importLdap-state-done');
      case 'fatal':
        return t('modules-user-User-importLdap-state-fatal');
      default:

    }

  }
  showImportProgress(importMode) {
    if (importMode === "preflight" || importMode === "applyData")
    {
      return true;
    }
    return false;
  }

  renderProgressBar() {
    if (this.showImportProgress(this.state.importState.ImportState.mode))
    {
      let max = parseInt(this.state.importState.ImportState.progress.all, 10)
      let val = parseInt(this.state.importState.ImportState.progress.done, 10)
      let percent = 100 * val / max
      return <div style={ { flexGrow: '1', padding: '28px' } }>
               <LinearProgress mode="determinate" value={ percent } />
             </div>
    }
    return <div></div>
  }

  renderProgressTime() {
    if (this.showImportProgress(this.state.importState.ImportState.mode))
    {
      let max = parseInt(this.state.importState.ImportState.progress.all, 10)
      let val = parseInt(this.state.importState.ImportState.progress.done, 10)
      let percent = 100 * val / max
      var text = "Zeit wird berechnet"
      if (percent > 10)
      {

        let elapsed = Date.now() - this.progressStartTime
        // let estimated = (100 * elapsed / percent)
        let seconds = (elapsed / 1000) % 60
        let minutes = (elapsed / 1000) / 60
        if (minutes < 10)
        {
          text = "0" + minutes.toFixed(0)
        }
        else
        {
          text = minutes.toFixed(0)
        }
        text += ":"
        if (seconds < 10)
        {
          text += "0" + seconds.toFixed(0)
        }
        else
        {
          text += seconds.toFixed(0)
        }
      }
      return <div style={ this.getBaseStyle().right }><span style={ styles.grayText }>{ text }</span> </div>
    }
    return <div></div>

  }

  renderImportHeader() {
    if (this.state.importState.ImportState.mode === "waitConfirm" || this.state.importState.ImportState.mode === "done")
    {
      return <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
               <div style={ styles.importPageHeaderDiv }>
                 <IconCheckFilled style={ { width: '30px', height: '30px', marginRight: '25px' } } />
                 <span style={ { color: '#00C1A7' } }>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               { this.getImportStateText(this.state.importState.ImportState.mode) }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               </span>
               </div>
             </PageHeader>
    }
    return <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
             { this.getImportStateText(this.state.importState.ImportState.mode) }
           </PageHeader>
  }

  renderHeaderView() {
    const {t} = this.props;
    if (this.state.currentViewMode === LIST_USER)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
                 <PageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } }>
                   { t("modules-user-User-user", {
                       v1: this.state.userDataCount
                     }) }
                 </PageHeader>
               </div>
               <div style={ styles.searchText }>
                 <TextField style={ { margin: 'auto 0px' } } hintText={ t('modules-user-User-search') } onChange={ (event) => this.handleChangeData(event, 'search') } value={ this.state.searchText } />
                 <IconSearch style={ { margin: 'auto 0px', height: '40px', width: '40px' } } />
               </div>
               <div style={ this.getBaseStyle().right }>
                 <div style={ styles.download }>
                   <FlatButton style={ { margin: 'auto' } } label={ t('modules-user-User-btnImportVorlage') } secondary={ true } icon={ <IconDownload /> } labelPosition="after" onClick={ this.handleDownloadImportFileClicked }
                   />
                 </div>
                 <RaisedButton label={ t('modules-user-User-btnImportUser') } style={ this.getBaseStyle().button } containerElement="label">
                   <input style={ styles.uploadInput } accept=".csv" type='file' ref='fileInput' onChange={ this.onFileLoaded } onClick={ this.onFileInputClicked } />
                 </RaisedButton>
               </div>
             </div>

    }
    if (this.state.currentViewMode === NEW_USER)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
                 <BackSubPageHeader style={ { paddingLeft: '25px', margin: 'auto 0px' } } label={ t('modules-user-NewUser-newuser') } onBackClicked={ this.handleCancelNewUserClicked } />
               </div>
               <div style={ this.getBaseStyle().right }>
               </div>
             </div>

    }
    if (this.state.currentViewMode === EDIT_USER)
    {
      return <div>
               <div style={ this.getBaseStyle().headerPageLabelDiv }>
                 <BackSubPageHeader label={ this.state.editUser.firstName + " " + this.state.editUser.name } onBackClicked={ this.handleCancelNewUserClicked } />
               </div>
               <div style={ this.getBaseStyle().right }>
                 <FlatButton style={ { margin: 'auto', color: '#FFFFFF' } } label={ t('modules-user-User-btnDeleteUser') } secondary={ true } icon={ <IconDelete /> } labelPosition="before" onClick={ this.beginDeleteUserClicked }
                 />
               </div>
             </div>

    }
    if (this.state.currentViewMode === IMPORT_USER)
    {
      return <div style={ { display: 'flex', alignItems: 'stretch' } }>
               <div style={ this.getBaseStyle().headerPageLabelDiv2 }>
                 { this.renderImportHeader() }
               </div>
               { this.renderProgressBar() }
               { this.renderProgressTime() }
             </div>

    }
  }

  renderImportColumn(column, val1, val2) {
    var ivalue = 0
    if (typeof val1 === "string")
    {
      ivalue += parseInt(val1, 10)
    }
    if (typeof val2 === "string")
    {
      ivalue += parseInt(val2, 10)
    }
    if (ivalue > 0)
    {
      if (column === 1)
      {
        return <div style={ styles.importNumberDiv }>
                 <IconTabelleStatusOn />
                 { ivalue }
               </div>
      }
      if (column === 2)
      {
        return <div style={ styles.importNumberDiv }>
                 <IconTabelleStatusOnPending />
                 { ivalue }
               </div>
      }
      if (column === 3)
      {
        return <div style={ styles.importNumberDiv }>
                 <IconTabelleStatusInvented />
                 { ivalue }
               </div>
      }
      if (column === 4)
      {
        return <div style={ styles.importNumberDiv }>
                 <IconTabelleStatusAlert />
                 { ivalue }
               </div>
      }
    }
    if (column === 1)
    {
      return <div style={ styles.importNumberDiv }>
               <IconTabelleStatusOnPending />
               <div>
                 { ivalue }
               </div>
             </div>

    }
    return <div></div>
  }

  renderMainView() {
    const {t} = this.props;

    if (this.state.currentViewMode === LIST_USER)
    {
      return <div>
               <UserList ref="userlist" onCreateUser={ this.createUserHandler } dataprovider={ this.dataprovider } listEditModeListener={ this.listEditModeListener } onRowSelection={ this.onRowSelection }
                 searchText={ this.state.searchText } />
             </div>;

    }
    if (this.state.currentViewMode === NEW_USER)
    {
      return <div>
               <UserDetail ref="newuser" onPendingChanges={ this.handleOnPendingChanges } viewMode={ this.state.currentViewMode } onCreateUser={ this.createUserHandler } listEditModeListener={ this.listEditModeListener }
                 viewPortHeight={ this.state.height } onDeleteKeyword={ this.beginKeywordDeleteClicked } onSwitchUserClicked={ this.beginSwitchUserClicked } dispatch={ this.props.dispatch } requestFailedErrorHandler={ this.requestFailedErrorHandler }
               />
             </div>;

    }
    if (this.state.currentViewMode === EDIT_USER)
    {
      return <div>
               <UserDetail ref="updateuser" onPendingChanges={ this.handleOnPendingChanges } viewMode={ this.state.currentViewMode } onUpdateUser={ this.updateUserHandler } listEditModeListener={ this.listEditModeListener }
                 user={ this.state.editUser } onDeleteKeyword={ this.beginKeywordDeleteClicked } onSwitchUserClicked={ this.beginSwitchUserClicked } viewPortHeight={ this.state.height } dispatch={ this.props.dispatch }
                 requestFailedErrorHandler={ this.requestFailedErrorHandler } onUserAlertStateReset={ this.handleUserAlertStateReset } />
             </div>;
    }
    if (this.state.currentViewMode === IMPORT_USER)
    {
      return <div>
               <Table selectable={ false }>
                 <TableHeader displaySelectAll={ false } adjustForCheckbox={ false }>
                   <TableRow>
                     <th width="7px" />
                     <TableHeaderColumn>
                       { t("modules-user-User-importLdap-table-column1") } </TableHeaderColumn>
                     <TableHeaderColumn>
                       { t("modules-user-User-importLdap-table-column2") } </TableHeaderColumn>
                     <TableHeaderColumn>
                       { t("modules-user-User-importLdap-table-column3") } </TableHeaderColumn>
                     <TableHeaderColumn>
                       { t("modules-user-User-importLdap-table-column4") } </TableHeaderColumn>
                     <TableHeaderColumn>
                       { t("modules-user-User-importLdap-table-column5") } </TableHeaderColumn>
                   </TableRow>
                 </TableHeader>
                 <TableBody displayRowCheckbox={ false }>
                   <TableRow key={ 1 } style={ styles.tablePlaceHolder } displayBorder={ true }>
                     <td width="7px" height="40px" />
                     <TableRowColumn>
                       { t("modules-user-User-importLdap-table-row1") }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(1, this.state.importState.ImportState.User.new, this.state.importState.ImportState.User.updated) }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(2, this.state.importState.ImportState.Error.INFO) }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(3, this.state.importState.ImportState.Error.WARNING) }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(4, this.state.importState.ImportState.Error.ERROR) }
                     </TableRowColumn>
                   </TableRow>
                   <TableRow key={ 2 } style={ styles.tablePlaceHolder } displayBorder={ true }>
                     <td width="7px" height="40px" />
                     <TableRowColumn>
                       { t("modules-user-User-importLdap-table-row2") }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(1, this.state.importState.ImportState.Group.new) }
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                   </TableRow>
                   <TableRow key={ 3 } style={ styles.tablePlaceHolder } displayBorder={ true }>
                     <td width="7px" height="40px" />
                     <TableRowColumn>
                       { t("modules-user-User-importLdap-table-row3") }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(1, this.state.importState.ImportState.Channel.new) }
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                   </TableRow>
                   <TableRow key={ 4 } style={ styles.tablePlaceHolder } displayBorder={ true }>
                     <td width="7px" height="40px" />
                     <TableRowColumn>
                       { t("modules-user-User-importLdap-table-row4") }
                     </TableRowColumn>
                     <TableRowColumn>
                       { this.renderImportColumn(1, this.state.importState.ImportState.Keyword.new) }
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                     <TableRowColumn>
                     </TableRowColumn>
                   </TableRow>
                   <TableRow key={ -2 } displayBorder={ true }>
                   </TableRow>
                 </TableBody>
               </Table>
             </div>;

    }
  }

  renderHiddenDialog() {
    const {t, dispatch} = this.props;

    return (<div>
              <WaitDialog ref="waitDialog" />
              <ModalDialog ref="modalDialog" />
              <ErrorDialog ref="errorDialog" t={ t } />
              <ChooseTemplate ref="chooseTemplateDialog" t={ t } dispatch={ dispatch } />
              <ModalDialog ref="commonErrorDialog" />
            </div>);
  }
}

function mapStateToProps(state) {

  // const {session} = state;
  return {}
}


const UserTranslated = translate(['common'])(User);


export default connect(mapStateToProps)(UserTranslated);
