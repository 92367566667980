import React, { PropTypes } from 'react';
import { SubPageHeader } from '../../components/pageheader';
import { translate } from 'react-i18next';
import { getTableStyles } from '../../components/styles.js';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn, TableFooter } from 'material-ui/Table';
import * as constants from './DataProvider';
import { TableRowColumnText, TableHeaderColumnSort, TableHeaderColumnDefault, TableHeaderColumnKeywords, TableRowColumnKeywords, TableHeaderColumnUserState, TableRowColumnUserState } from '../../components/table/TableCells'
import TableFooterPagination from '../../components/table/TableFooterPagination';

import TextField from '../../components/textfields/TextField';
import IconSearch from '../../ressources/icon_search';
import IconCheck from '../../ressources/icon_check';
import IconCheckFilled from '../../ressources/icon_check_filled';
import IconButton from 'material-ui/IconButton';
import { IsNullOrEmpty } from '../../components/StringExtensions';
require('../../components/DateExtensions');

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
  },
  leftRootContent: {
    width: '30%'
  },
  rightRootContent: {
    width: '70%',
    minWidth: '505px'
  },
  userHeader: {
    display: 'flex',
    flexFlow: 'row',
    paddingLeft: '60px'
  },
  leftUserContent: {
    width: '40%',
    minWidth: '206px'
  },
  rightUserContent: {
    width: '60%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    minWidth: '305px'
  },
  rightUserTable: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
  },
  rigthUserTableLeft: {
    minWidth: '60px',
    width: '60px',
    height: '100%',
  },
  rigthUserTableLeftTop: {
    minWidth: '60px',
    width: '60px',
    height: '60px',
    background: 'linear-gradient(to bottom, #00375D 0%,#00375D 100%)',
  },
  rigthUserTableLeftBottom: {
    minWidth: '60px',
    width: '60px',
    background: 'linear-gradient(to right, #00375D 0%, #00375D 100%)',
  },
  search: {
    height: '35px',
    width: '35px'
  },
  searchTextFieldHint: {
    fontSize: '14px'
  },
  userContentHeader: {
    marginLeft: '0px'
  },
  tableHeaderSort: {
    lineHeight: '36px',
    height: '36px'
  },
  tableHeaderSortFill: {
    width: '100%'
  },
  tableColumnLicenseState: {
    width: '28px',
  },
}

const tableStyles = getTableStyles();

class LicenseAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      userSortColumn: 0,
      userSortDirection: 0,
      placeHolder: {},
      searchText: '',
      isUserStateExpanded: false,
      isTagExpanded: true
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleOnUserTableRowSelection = this.handleOnUserTableRowSelection.bind(this);
    this.handleOnFreeVoucherTableRowSelection = this.handleOnFreeVoucherTableRowSelection.bind(this);
    this.checkkeyword = this.checkkeyword.bind(this);
    this.handleExpandIconClicked = this.handleExpandIconClicked.bind(this);
  //this.props.dataProvider.resetSelectedUserAndVoucher();
  }
  /**
  * Callback fuer sortier-buttons
  */
  handleSortTableButtonClicked(index) {
    // const {dataProvider} = this.props;
    var sortColumnName;
    switch (index) {
      case 2: {
        sortColumnName = constants.KEY_EXPIRE_DATE;
        break;
      }
      default: {
        sortColumnName = constants.KEY_VOUCHER_COUNT;
      }
    }

    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        sortColumnName: sortColumnName,
      });
    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        sortColumnName: sortColumnName,
      });
    }
  }

  handleUserSortTableButtonClicked(index) {
    // const {dataProvider} = this.props;
    var sortColumnName;
    switch (index) {
      case 2: {
        sortColumnName = constants.KEY_FIRST_NAME;
        break;
      }
      default: {
        sortColumnName = constants.KEY_LAST_NAME;
      }
    }

    if (this.state.userSortColumn === index) {
      let newDirection = (this.state.userSortDirection + 1) % 2;
      this.setState({
        userSortDirection: newDirection,
        userSortColumnName: sortColumnName,
      });
    } else {
      let newDirection = 0;
      this.setState({
        userSortColumn: index,
        userSortDirection: newDirection,
        userSortColumnName: sortColumnName,
      });
    }
  }

  handleChangeData(event, name) {
    if (name === "search") {
      this.setState({
        searchText: event.target.value
      })
    }
  }

  handleOnUserTableRowSelection(selectedRows) {

    const {dataProvider} = this.props;
    let currentFilteredUsers = this.getFilteredUserData();

    //Bugfix 44700: die Auswahl in einer gefilterten Tabelle ist nicht korrekt
    var tmpSelectedUserGuids = dataProvider.getSelectedAvailableUserGuids();
    if (selectedRows === "all") {
      // eslint-disable-next-line
      currentFilteredUsers.map((user, i) => {
        tmpSelectedUserGuids.push(user.guid);
      })

    } else if (selectedRows === "none") {
      // eslint-disable-next-line
      currentFilteredUsers.map((user, i) => {
        // Remove selected User
        tmpSelectedUserGuids = tmpSelectedUserGuids.filter(function(item) {
          return item !== user.guid
        })
      })

    } else if (typeof selectedRows === "string") {
      /*selectedRows.map((row, i) => {
        let user = currentFilteredUsers[row];
        tmpSelectedUserGuids.push(user.CompanyUser.guid);
      })*/
      if (dataProvider.isUserSelected(selectedRows)) {
        // Remove selected User
        tmpSelectedUserGuids = tmpSelectedUserGuids.filter(function(item) {
          return item !== selectedRows
        })
      } else {
        tmpSelectedUserGuids.push(selectedRows);
      }
    } else {
      return
    }
    dataProvider.setSelectedAvailableUsers(tmpSelectedUserGuids);
  }

  handleOnFreeVoucherTableRowSelection(selectedRows) {
    const {dataProvider} = this.props;

    dataProvider.setSelectedFreeVoucherRow(selectedRows);
  }

  getInnerHeightFixed(inputStyle) {
    let currentHeight = parseFloat(this.props.viewPortHeight) - 318;
    return Object.assign({}, inputStyle, {
      "minHeight": currentHeight + "px",
      "maxHeight": currentHeight + "px",

    });
  }
  checkkeyword(keyword) {
    //wir wandeln das keyword-Array einfach in einen string um und suchen darin.... o.O
    return keyword.toString().toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0;
  }

  getFilteredUserData() {
    const {dataProvider} = this.props;
    let userData = dataProvider.getAvailableUserSort(this.state.userSortColumnName, this.state.userSortDirection);
    if (!IsNullOrEmpty(this.state.searchText)) {
      let searchtextLower = this.state.searchText.toLowerCase();
      let filteredUsers = userData.filter((user) => user.firstName.toLowerCase().indexOf(searchtextLower) >= 0 ||
        user.name.toLowerCase().indexOf(searchtextLower) >= 0
        || (this.checkkeyword(user.keyword))) //schöner wäre CompanyUser.keyword.find(this.checkkeyword) - aus nicht bekannten Gründen funktioniert das aber nicht!
      return filteredUsers;
    }
    return userData;
  }

  handleExpandIconClicked(isExpanded, index) {
    isExpanded = !isExpanded;

    if (index === 3) {
      this.setState({
        isTagExpanded: isExpanded,
      });
    } else if (index === 4) {
      this.setState({
        isUserStateExpanded: isExpanded,
      });
    }
  }

  renderFirstColumnLicense(guid) {
    const { dataProvider } = this.props;
    if (dataProvider.isFreeVoucherSelected(guid)) {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnFreeVoucherTableRowSelection(guid) }>
                 <IconCheckFilled />
               </IconButton>
             </TableRowColumn>;
    } else {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnFreeVoucherTableRowSelection(guid) }>
                 <IconCheck />
               </IconButton>
             </TableRowColumn>;
    }

  }


  renderFirstColumnUser(guid) {
    const { dataProvider } = this.props;
    if (dataProvider.isUserSelected(guid)) {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnUserTableRowSelection(guid) }>
                 <IconCheckFilled />
               </IconButton>
             </TableRowColumn>;
    } else {
      return <TableRowColumn style={ styles.tableColumnLicenseState }>
               <IconButton onClick={ (event) => this.handleOnUserTableRowSelection(guid) }>
                 <IconCheck />
               </IconButton>
             </TableRowColumn>;
    }

  }
  render() {
    const {t, dataProvider} = this.props;

    let data = dataProvider.getFreeVoucherSorted(this.state.sortColumnName, this.state.sortDirection);
    // let userData = dataProvider.getAvailableUserSort(this.state.userSortColumnName, this.state.userSortDirection);
    let voucherCount = dataProvider.getFreeVoucherCount();

    // Breite der einzelnen Spalten ermitteln
    let windowWidth = window.innerWidth;

    let columnWidth = windowWidth / 10;

    return (
      <div style={ styles.root }>
        <div style={ styles.leftRootContent }>
          <SubPageHeader>
            { t('modules-license-License-table-header-freeLicense') + " (" + voucherCount + ")" }
          </SubPageHeader>
          <Table selectable={ true } onRowSelection={ this.handleOnFreeVoucherTableRowSelection.bind(this) }>
            <TableHeader displaySelectAll={ false } adjustForCheckbox={ true } style={ tableStyles.tableHeader }>
              <TableRow style={ tableStyles.tableHeaderRow }>
                <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-count') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                />
                <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-license-License-table-column-expireDate') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                />
                <TableHeaderColumnDefault />
                <TableHeaderColumnDefault />
              </TableRow>
            </TableHeader>
            <TableBody deselectOnClickaway={ false } displayRowCheckbox={ false } style={ tableStyles.tableBody }>
              { data.map((item, i) => <TableRow selected={ dataProvider.isFreeVoucherSelected(item.VoucherGroup.guid) } key={ i } value={ item } displayBorder={ true } style={ tableStyles.tableRow }>
                                        { this.renderFirstColumnLicense(item.VoucherGroup.guid) }
                                        <TableRowColumnText text={ item.VoucherGroup.voucherCount + " " + t("modules-license-License-table-column-licenses") } />
                                        <TableRowColumnText text={ new Date().parseUtcDate(item.VoucherGroup.validTill).getShortDate() } />
                                        <TableRowColumnText />
                                        <TableRowColumnText />
                                      </TableRow>
                ) }
            </TableBody>
            <TableFooter style={ tableStyles.tableFooter}>
              <TableFooterPagination ref="pagination" data={ data } parent={ this } />
            </TableFooter>
          </Table>
        </div>
        <div style={ styles.rightRootContent }>
          <div style={ styles.userHeader }>
            <div style={ styles.leftUserContent }>
              <SubPageHeader style={ styles.userContentHeader }>
                { t('modules-license-License-table-header-availableUser') + " (" + this.getFilteredUserData().length + ")"}
              </SubPageHeader>
            </div>
            <div style={ styles.rightUserContent }>
              <TextField hintText={ t('modules-license-License-searchfield-hint') } hintStyle={ styles.searchTextFieldHint } fullWidth={ true } onChange={ (event) => this.handleChangeData(event, 'search') } rows={ 1 }
              />
              <IconSearch style={ styles.search } />
            </div>
          </div>
          <div style={ styles.rightUserTable }>
            <div style={ styles.rigthUserTableLeft }>
              <div style={ styles.rigthUserTableLeftTop }>
              </div>
              <div style={ this.getInnerHeightFixed(styles.rigthUserTableLeftBottom) }>
              </div>
            </div>
            <div>
              <Table multiSelectable={ true } selectable={ true } onRowSelection={ this.handleOnUserTableRowSelection.bind(this) }>
                <TableHeader displaySelectAll={ true } enableSelectAll={ true } adjustForCheckbox={ true } style={ tableStyles.tableHeader }>
                  <TableRow style={ tableStyles.tableHeaderRow }>
                    <TableHeaderColumnSort index={ 1 } style={ { width: columnWidth } } sortColumn={ this.state.userSortColumn } sortDirection={ this.state.userSortDirection } text={ t('modules-user-UserList-table-column1') }
                      onClickCallback={ this.handleUserSortTableButtonClicked.bind(this) } />
                    <TableHeaderColumnSort index={ 2 } style={ { width: columnWidth } } sortColumn={ this.state.userSortColumn } sortDirection={ this.state.userSortDirection } text={ t('modules-user-UserList-table-column2') }
                      onClickCallback={ this.handleUserSortTableButtonClicked.bind(this) } />
                    <TableHeaderColumnKeywords index={ 3 } text={ t('modules-user-UserList-table-column6') } style={ Object.assign({}, styles.tableHeaderSort, this.state.isTagExpanded ? {
                                                                                                                       width: columnWidth
                                                                                                                     } : {
                                                                                                                       width: 55
                                                                                                                     }) } contentExpand={ this.state.isTagExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) }
                    />
                    <TableHeaderColumnUserState index={ 4 } sortColumn={ this.state.userSortColumn } sortDirection={ this.state.userSortDirection } text={ t('state') } onClickCallback={ this.handleUserSortTableButtonClicked.bind(this) }
                      style={ styles.tableHeaderSortFill } contentExpand={ this.state.isUserStateExpanded } onExpandClickCallback={ this.handleExpandIconClicked.bind(this) } />
                  </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={ false } displayRowCheckbox={ false } style={ tableStyles.tableBody }>
                  { this.getFilteredUserData().map((item, i) => <TableRow selected={ dataProvider.isUserSelected(item.guid) } key={ i } value={ item } displayBorder={ true } style={ tableStyles.tableRow }>
                                                                  { this.renderFirstColumnUser(item.guid) }
                                                                  <TableRowColumnText text={ item.name } style={ { width: columnWidth } } />
                                                                  <TableRowColumnText text={ item.firstName } style={ { width: columnWidth } } />
                                                                  <TableRowColumnKeywords keywords={ item.keyword } style={ this.state.isTagExpanded ? {
                                                                                                                              width: columnWidth
                                                                                                                            } : {
                                                                                                                              width: 55
                                                                                                                            } } isExpand={ this.state.isTagExpanded } />
                                                                  <TableRowColumnUserState t={ t } user={ item } style={ styles.tableHeaderSortFill } isExpand={ this.state.isUserStateExpanded } />
                                                                </TableRow>
                    ) }
                </TableBody>
                <TableFooter style={ tableStyles.tableFooter}>
                  <TableFooterPagination ref="pagination" data={ this.getFilteredUserData() } pagination={ 10 } parent={ this } />
                </TableFooter>
              </Table>
            </div>
          </div>
        </div>
      </div>
      );
  }
}

LicenseAssignment.propTypes = {
  dataProvider: PropTypes.object.isRequired
}
export default translate(['common'])(LicenseAssignment);
