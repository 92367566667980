// eslint-disable-next-line no-extend-native
String.prototype.leadingZeroString = function() {
  let returnValue = '0' + this;
  return returnValue.substring(returnValue.length - 2);
}

// eslint-disable-next-line no-extend-native
String.prototype.isEmpty = function() {
  return (this === '' || this === "");
}

// eslint-disable-next-line no-extend-native
export function IsNullOrEmpty(string) {
  return (typeof string === "undefined" || !(string) || string.isEmpty());
}

// eslint-disable-next-line no-extend-native
export function SplitToBlocksOfFour(string) {
  return string.match(/.{1,4}/g);
}
