import React from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from '../../components/buttons/RaisedButton';

import { WaitDialog } from '../../components/WaitDialog';
import ErrorDialog from '../../components/ErrorDialog.js';
import FlatButton from "../../components/buttons/FlatButton";
import {disableTwoFa} from "../../actions";


/**
 *
 * @type {{secret: string, open: boolean}}
 */
const initState = {
  open: false
};


/**
 * @class ApiTwoFaDisableDialog
 */
class ApiTwoFaDisableDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }


  /**
   *
   * @returns {void}
   */
  open() {
    this.setState({
      open: true
    });
  }


  /**
   *
   * @returns {void}
   */
  close() {
    this.setState(initState);
  }


  /**
   *
   * @returns {void}
   */
  processDisablingTwoFa() {
    let callback = this.props.onTwoFaDisableSuccess;
    this.props.dispatch( disableTwoFa( callback, this.props.commonErrorHandler ) );
  }



  /**
   *
   * @returns {void}
   */
  handleSubmitButton() {
    this.processDisablingTwoFa();
    this.close();
  }


  /**
   *
   */
  handleCancelButton() {
    this.close();
  }


  /**
   *
   * @returns {JSX.Element}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <div>
          <WaitDialog ref="waitDialog" />
          <ErrorDialog ref="errorDialog" t={ t } />
        </div>
        <Dialog title={ t('settings-two-fa-disable-dialog-title') } contentStyle={ { width: "80%", maxWidth: '960px' } } modal={ true } open={ this.state.open }>

          <div className="dialog__content">
            <div>{ t('settings-two-fa-disable-dialog-description') }</div>
          </div>

          <div className="dialog__actions">
              <FlatButton className="a-btn btn--flat" secondary={ true } label={ t('cancel') } onTouchTap={ this.handleCancelButton.bind(this) } />
              <RaisedButton className="a-btn btn--primary" primary={ true } label={ t('settings-two-fa-disable-dialog-btn-submit') } onTouchTap={ this.handleSubmitButton.bind(this) } />
          </div>

        </Dialog>
      </div>
      );
  }

}

export default ApiTwoFaDisableDialog;
