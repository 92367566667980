import React, { Component } from 'react';
import { translate } from 'react-i18next';

import TextField from './textfields/TextField';
import RaisedButton from './buttons/RaisedButton';
import FlatButton from "./buttons/FlatButton";
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { ModalDialog } from './ModalDialog';
import { correctImageOrientation } from '../util/ImageUtil'
import { MAXFILE_SIZE } from '../util/Constants.js';

const styles = {
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  uploadButton: {

  },
  float: {
    float: "left",
  },
  hElementMargin: {
    marginLeft: "10px",
  },
  vElementMargin: {
    marginTop: "10px",
  },
  picker: {
    width: "200px",
    maxWidth: "200px",
  },
}

class MessageComposer extends Component {
  state = {
    sendDate: new Date(),
    sendTime: new Date(),
    sendTimeSelection: 0,
    disableMessageText: false,
    message: {
      messageText: "",
      attachment: "",
    },
    disableSendMessage: false,
  }

  constructor(props) {
    super(props);
    this.messageTextChangedHandler = this.messageTextChangedHandler.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleSendTimeValueChangeHandler = this.handleSendTimeValueChangeHandler.bind(this);
    this.messageSendDateChangeHandler = this.messageSendDateChangeHandler.bind(this);
    this.messageSendTimeChangeHandler = this.messageSendTimeChangeHandler.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleSendMessageClicked = this.handleSendMessageClicked.bind(this);
    this.onFileInputClicked = this.onFileInputClicked.bind(this);
    this.onImageAdjust = this.onImageAdjust.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    let {messageTextFieldDisabled, sendMessageDisabled} = nextProps;

    this.setState({
      disableMessageText: messageTextFieldDisabled,
      disableSendMessage: sendMessageDisabled,
    })
  }

  setPendingChanges(pending) {
    this.setState({
      isPendingChanges: pending
    });
  }

  canSendMessage() {
    return ((this.state.message.messageText && this.state.message.messageText.length > 0)
      || (this.state.message.attachment && this.state.message.attachment.length > 0)
      || (this.state.disableMessageText === true)) // das ist bei Datei- oder Bild-Nachrichten der Fall
  }

  messageTextChangedHandler(event, newValue) {
    let oldData = JSON.parse(JSON.stringify(this.state.message))
    oldData.messageText = newValue

    this.setState({
      message: oldData,
    });

    this.setPendingChanges(true);

    if (typeof this.props.onMessageTextChange === 'function')
    {
      this.props.onMessageTextChange(newValue);
    }
  }
  onFileInputClicked(evt) {
    //reset on Click
    evt.target.value = null;
  }

  onImageAdjust(base64Image) {
    if (typeof this.props.onAttachmentChange === 'function')
    {
      this.props.onAttachmentChange(base64Image, null);
    }
    ;
  }

  onFileUpload(evt) {
    //http://stackoverflow.com/questions/36580196/reactjs-base64-file-upload
    //this.refs.waitDialog.open();
    const {t} = this.props;
    var self = this;
    var reader = new FileReader();
    var file = evt.target.files[0];

    if (file.size <= MAXFILE_SIZE)
    {
      reader.onload = function(upload) {

        let oldData = JSON.parse(JSON.stringify(self.state.message))

        oldData.attachment = upload.target.result;

        if (upload.target.result.substring(0, 10) !== 'data:image')
        {
          oldData.messageText = ''
        }
        else
        {
          //IMAGE!
          correctImageOrientation(upload.target.result, self.onImageAdjust);
        }

        self.setState({
          message: oldData,
        });

        if (typeof self.props.onAttachmentChange === 'function')
        {
          self.props.onAttachmentChange(upload.target.result, file);
        }

      };
      reader.readAsDataURL(file);
      setTimeout(function() {
        console.log("attachment loaded: ", file.name);
      }, 1000);
      //this.refs.waitDialog.close();
      this.setPendingChanges(true);
    }
    else
    {
      //Datei zu Groß
      //components-messagecomposer-send-AttachmentTooBig
      this.refs.modalDialog.open({
        text: t("components-messagecomposer-send-AttachmentTooBig"),
        button1Text: t("components-ModalDialog-ok"),
      });
    }
  }

  handleSendTimeValueChangeHandler(event, key, payload) {
    this.setState({
      sendTimeSelection: key
    });

    if (key === 1)
    {
      //Setzt die Uhrzeit auf eine Minute in die Zukunft
      this.sendTime = new Date();
      this.sendDate = new Date();

      let hours = this.sendTime.getHours();
      let minutes = this.sendTime.getMinutes() + 1;
      let seconds = this.sendTime.getSeconds();

      this.state.sendTime.setHours(hours, minutes, seconds);
    }
  }

  messageSendDateChangeHandler(event, date) {
    this.setState({
      sendDate: date
    });
    this.setPendingChanges(true);
    if (typeof this.props.onSendDateChange === 'function')
    {
      this.props.onSendDateChange(date);
    }
  }

  messageSendTimeChangeHandler(event, time) {
    // only want the time-part!
    this.setState({
      sendTime: time
    });
    this.setPendingChanges(true);
    if (typeof this.props.onSendTimeChange === 'function')
    {
      this.props.onSendTimeChange(time);
    }
  }

  resetForm() {
    let resetMessage = {
      sendTime: '',
      sendDate: '',
      messageText: '',
      picture: '',
      attachment: '',
    };
    //Manually reset Date and timePicker
    //https://github.com/callemall/material-ui/issues/1529

    if (this.state.sendTimeSelection > 0)
    {
      this.refs.timePicker.setState({
        date: null
      });
      this.refs.datePicker.setState({
        date: null
      });
    }

    this.setState({
      message: resetMessage,
      sendTimeSelection: 0,
      disableMessageText: false,
    });
    if (typeof this.props.onReset === 'function')
    {
      this.props.onReset(resetMessage);
    }
  }

  isSendDateValid() {
    if (this.state.sendTimeSelection > 0)
    {
      let now = new Date();
      let sendDate = new Date();
      sendDate.setTime(0);

      if (this.state.sendDate)
      {
        let year = this.state.sendDate.getFullYear();
        let month = this.state.sendDate.getMonth();
        let day = this.state.sendDate.getDate();
        sendDate.setFullYear(year, month, day);
      }
      if (this.state.sendTime)
      {
        let hours = this.state.sendTime.getHours();
        let minutes = this.state.sendTime.getMinutes();
        let seconds = this.state.sendTime.getSeconds();
        sendDate.setHours(hours, minutes, seconds);
      }

      this.setPendingChanges(true);
      this.props.onSendTimeChange(sendDate);
      this.props.onSendDateChange(sendDate);

      return sendDate > now;
    }
    return true;
  }

  handleSendMessageClicked() {
    const {t} = this.props;
    let isMessageValid = this.isSendDateValid();
    if (isMessageValid === true)
    {
      this.resetForm();
      this.setPendingChanges(false);
      //wir geben hier das SendEvent zum "MessageEditor" weiter,
      //da dort die gesamte "Datenanbindung" usw. ist
      //und auch alle Daten dort hin gegeben wurden
      //grundsätzlich könnte sich auch diese Komponente um das Versenden kümmern,
      //was aus Sicht der Kapselung besser wäre. (TODO: Refactor!)
      if (typeof this.props.onMessageSend === 'function')
      {
        this.props.onMessageSend();
      }
    }
    else
    {
      this.refs.modalDialog.open({
        text: t("modules-channels-messages-senddateinvalid"),
        button1Text: t("components-ModalDialog-ok"),
      });
    }
  }

  handleCancelClicked() {
    this.resetForm();
    this.setPendingChanges(false);
  }

  render() {
    const {t} = this.props;
    let locale = this.props.i18n.language === 'en' ? 'en-GB' : 'de-DE';
    let DateTimeFormat = global.Intl.DateTimeFormat;
    let dateTimePicker = null;
    let canSendMessage = this.canSendMessage();
    if (this.state.sendTimeSelection === 1)
    {
      dateTimePicker = (
        <div>
          <DatePicker ref="datePicker" style={ styles.float } textFieldStyle={ styles.picker } hintText={ t("components-messagecomposer-datepicker") } onChange={ this.messageSendDateChangeHandler } okLabel={ t("components-ModalDialog-ok") }
            cancelLabel={ t("cancel") } DateTimeFormat={ DateTimeFormat } locale={ locale } value={ this.state.sendDate } />
          <TimePicker ref="timePicker" format="24hr" textFieldStyle={{ ...styles.picker, ...styles.hElementMargin }} hintText={ t("components-messagecomposer-timepicker") } onChange={ this.messageSendTimeChangeHandler } okLabel={ t("components-ModalDialog-ok") }
            cancelLabel={ t("cancel") } value={ this.state.sendTime } />
        </div>
      )
    }

    let openFileFilter = '*';
    if ((this.state.message.messageText) && (this.state.message.messageText.length > 0))
    {
      openFileFilter = 'image/*';
    }

    let fileInputButton = null;
    if (!this.state.disableSendMessage)
    {
      fileInputButton = (
        <RaisedButton label={ t('components-messagecomposer-uploadattachment') } labelPosition="before" secondary={ true } style={{ ...styles.uploadButton, ...styles.vElementMargin }} containerElement="label">
          <input type="file" style={ styles.uploadInput } disabled={ this.state.disableSendMessage } onChange={ this.onFileUpload } onClick={ this.onFileInputClicked } accept={ openFileFilter } />
        </RaisedButton>
      )
    }

    return (
      <div style={ { minWidth: "500px" } }>
        <ModalDialog ref="modalDialog" />
        <div>
          <TextField rows={ 1 } multiLine={ true } rowsMax={ 10 } disabled={ this.state.disableMessageText || this.state.disableSendMessage } value={ this.state.message.messageText } hintText={ t("components-chatmessagetextfield-default-hint") }
            onChange={ this.messageTextChangedHandler } />
        </div>
        <div>
        { fileInputButton }
        </div>
        <div>
          <SelectField style={ styles.vElementMargin } disabled={ this.state.disableSendMessage } value={ this.state.sendTimeSelection } onChange={ this.handleSendTimeValueChangeHandler }>
            <MenuItem value={ 0 } primaryText={ t("components-messagecomposer-messagesendtime-now") } />
            <MenuItem value={ 1 } primaryText={ t("components-messagecomposer-messagesendtime-delayed") } />
          </SelectField>
        </div>
        { dateTimePicker }
        <div style={ styles.vElementMargin }>
          <FlatButton label={ t("cancel") } disabled={ !(this.state.isPendingChanges) || this.state.disableSendMessage } onClick={ this.handleCancelClicked } />
          <RaisedButton primary={ true } label={ t("components-messagecomposer-send") } disabled={ (!(this.state.isPendingChanges && canSendMessage)) || this.state.disableSendMessage } onClick={ this.handleSendMessageClicked } />
        </div>
      </div>);
  }
}

export default translate(['common'])(MessageComposer);
