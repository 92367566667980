let SINGLETON = null;
export class DataCache {

  constructor() {

    if (!SINGLETON)
    {
      SINGLETON = this;
      this.dataUser = {};
      this.dataUserLastQueryDate = null;
      this.keywords = [];
      this.keywordsDetail = [];
      this.dataUsedVoucher = {};
      this.dataUsedVoucherLastQueryDate = null;

    }
    return SINGLETON;
  }

  resetCache() {
    SINGLETON = null;
  }

  getUserData() {
    return Object.values(this.dataUser);
  }

  pushUser(newUser) {
    this.dataUser[newUser.guid] = newUser;
  }
  deleteUser(user) {
    delete this.dataUser[user.guid]
  }

  mapDataUser(newData) {
    // Adapt the Datastructure from Backend call to the required Datastructure inside Cockpit Client
    newData.keywords = [];
    if (typeof newData.keyword !== "undefined")
    {

      for (var i = 0; i < newData.keyword.length; i++)
      {
        let kw = newData.keyword[i];
        if (typeof kw === "string" && kw.length > 0)
        {
          newData.keywords.push(kw)
        }
      }
    }
    this.pushUser(newData);

  }

  searchAndUpdateUser(newData, multiUpdate) {

    // no query date --> refresh cache
    if (this.dataUserLastQueryDate == null)
    {
      this.dataUser = JSON.parse("{}");
    }

    //remind lastQueryDate
    if (typeof newData.lastQueryDate !== "undefined" && multiUpdate)
    {
      this.dataUserLastQueryDate = newData.lastQueryDate
    }
    if (typeof newData.data !== "undefined")
    {
      for (let user of newData.data) {
        // new / updated user
        if (typeof user.CompanyUser !== "undefined")
        {
          let newData = JSON.parse(JSON.stringify(user.CompanyUser));
          this.mapDataUser(newData);
        }
        // Deleted User
        if (typeof user.DeletedObject !== "undefined")
        {
          delete this.dataUser[user.DeletedObject.guid]
        }
      }
    }
  }

  searchUser(userGuid) {
    return this.dataUser[userGuid];
  }


  getKeywords() {
    return this.keywords;
  }

  addKeyword(keyword) {
    if (this.keywords.indexOf(keyword) === -1)
    {
      this.keywords.push(keyword)
    }
  }

  resetKeyword() {
    this.keywords = []
    this.keywordsDetail = []
  }

  getKeywordsDetail() {
    return this.keywordsDetail;
  }

  addKeywordsDetail(keywordDetail) {
    this.keywordsDetail.push(keywordDetail);
  }

  deleteKeyword(keyword) {
    let index = this.keywordsDetail.indexOf(keyword)
    if (index !== -1)
    {
      this.keywordsDetail.splice(index, 1)
    }
    index = this.keywords.indexOf(keyword.name)
    if (index !== -1)
    {
      this.keywords.splice(index, 1)
    }
  }

  searchAndUpdateUsedVoucher(newData, multiUpdate) {
    // no query date --> refresh cache
    if (this.dataUsedVoucherLastQueryDate == null)
    {
      this.dataUsedVoucher = JSON.parse("{}");
    }

    //remind lastQueryDate
    if (typeof newData.lastQueryDate !== "undefined" && multiUpdate)
    {
      this.dataUsedVoucherLastQueryDate = newData.lastQueryDate
    }
    if (typeof newData.data !== "undefined")
    {
      for (let voucher of newData.data) {

        if (typeof voucher.Voucher !== "undefined")
        {
          let newData = JSON.parse(JSON.stringify(voucher.Voucher));
          this.dataUsedVoucher[newData.guid] = newData
        }

        // Deleted Voucher
        if (typeof voucher.DeletedObject !== "undefined")
        {
          delete this.dataUsedVoucher[voucher.DeletedObject.guid]
        }
      }
    }
  }

  getUsedVoucher() {
    return Object.values(this.dataUsedVoucher);
  }

  searchUsedVoucher(guid) {
    return this.dataUsedVoucher[guid];
  }

}

export default DataCache;
