/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';



export function IconFingerprint(props) {
  return <SvgIcon {...props}>
            <svg viewBox="0 0 24 24">
              <path fill="#00C1A7" d="M18.1874656,1 C19.3261347,1 20.2499607,1.92382592 20.2499607,3.06249508 L20.2499607,20.9374525 C20.2499607,22.0761216 19.3261347,22.9999475 18.1874656,22.9999475 L5.81249508,22.9999475 C4.67382592,22.9999475 3.75,22.0761216 3.75,20.9374525 L3.75,3.06249508 C3.75,1.92382592 4.67382592,1 5.81249508,1 L18.1874656,1 Z M9.93748525,2.37499672 C9.55936954,2.37499672 9.24998689,2.68437938 9.24998689,3.06249508 C9.24998689,3.44061079 9.55936954,3.74999344 9.93748525,3.74999344 L14.0624754,3.74999344 C14.4405911,3.74999344 14.7499738,3.44061079 14.7499738,3.06249508 C14.7499738,2.68437938 14.4405911,2.37499672 14.0624754,2.37499672 L9.93748525,2.37499672 Z M11.9999803,7.87498361 C10.4831955,7.87498361 9.24998689,9.10819219 9.24998689,10.6249771 C9.24998689,12.1417619 10.4831955,13.3749705 11.9999803,13.3749705 C13.5167652,13.3749705 14.7499738,12.1417619 14.7499738,10.6249771 C14.7499738,9.10819219 13.5167652,7.87498361 11.9999803,7.87498361 Z M16.8124689,18.049951 L16.8124689,17.2249445 C16.8124689,15.8585499 15.5191293,14.7499672 13.9249673,14.7499672 L13.7101241,14.7499672 C13.1858982,14.9690905 12.6058215,15.0937164 11.9999803,15.0937164 C11.3941392,15.0937164 10.8183425,14.9690905 10.2898366,14.7499672 L10.0749933,14.7499672 C8.48083131,14.7499672 7.1874918,15.8585499 7.1874918,17.2249445 L7.1874918,18.049951 C7.1874918,18.5054438 7.61717828,18.8749574 8.15000629,18.8749574 L15.8499544,18.8749574 C16.3827824,18.8749574 16.8124689,18.5054438 16.8124689,18.049951 Z"/>
            </svg>
         </SvgIcon>;
}
;

export default IconFingerprint;
