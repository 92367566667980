import React from 'react';
import Dialog from 'material-ui/Dialog';
import IconXlsCopy from '../../ressources/icon-xls-copy.js';
import IconDevices from '../../ressources/icon-devices.js';
import IconXls from '../../ressources/icon-xls.js';
import IconClose from '../../ressources/icon_close';
import { WaitDialog } from '../../components/WaitDialog';
import download from 'downloadjs';
import { listUserExport } from '../../actions/index'



const styles = {
  content: {
    width: 450,
    height: 480,
    backgroundColor: 'transparent',
  },

  subTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#FFFFFF',
    letterSpacing: 0.33
  },

  headerCancelButton: {
    width: '20px',
    height: '20px',
    objectFit: 'contain',
    float: 'right'

  },
  button: {
    height: '100px',
    borderRadius: '5px',
    backgroundColor: '#6C8392',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  buttonIcon: {
    width: '100px',
    height: '100px',
    objectFit: 'contain'
  },

  buttonTitle: {
    width: 250,
    verticalAlign: 'middle',
    color: '#FFFFFF',
    fontSize: 14,
    letterSpacing: 0.31,
    fontFamily: 'Roboto'
  }
};
const initState = {
  open: false
};


class ChooseTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = initState;

    this.handleListUserExport = this.handleListUserExport.bind(this);
    this.listUserExportSuccessHandler = this.listUserExportSuccessHandler.bind(this);
    this.requestFailedErrorHandler = this.requestFailedErrorHandler.bind(this);
  }

  open(parameter) {

    this.setState({
      open: true
    });
  }

  handleCancelButton() {
    this.setState(initState);
  }

  handleListUserExport() {
    this.refs.waitDialog.open();
    this.props.dispatch(listUserExport(this.listUserExportSuccessHandler, this.requestFailedErrorHandler));
  }

  listUserExportSuccessHandler(json) {
    this.refs.waitDialog.close();

    let fileName = "ginlo-cockpit_export-devices.csv";

    if (typeof json.fileName === "string")
    {
      fileName = json.fileName;
    }

    // save to file system with specified name
    if (typeof json.data === "string")
    {
      download("data:;base64," + json.data, fileName, "text/csv");
    }
  }


  requestFailedErrorHandler(errorJO) {
    this.refs.waitDialog.close();
    // const {t} = this.props;

    /// Liste aktualisieren
    this.commonErrorHandler(errorJO);

  }


  render() {
    const {t} = this.props;


    return (
      <Dialog
        title={<div>
          <IconClose style={ styles.headerCancelButton } onClick={ this.handleCancelButton.bind(this) } />
        </div>}
        contentStyle={ styles.content }
        modal={ true }
        open={ this.state.open }
      >
        <WaitDialog ref="waitDialog" />
        <div style={ styles.subTitle }>
          { t('modules-user-ChooseTemplate-title') }
        </div>
        <a href={ t('modules-user-User-ImportVorlageLdapFile')} download>
          <div style={ styles.button }>
            <div>
              <IconXlsCopy style={ styles.buttonIcon } />
            </div>
            <div style={ styles.buttonTitle }>
              { t('modules-user-ChooseTemplate-ldap-detail') }
            </div>
          </div>
        </a>
        <a href={ t('modules-user-User-ImportVorlageFile') } download>
          <div style={ styles.button }>
            <div>
              <IconXls style={ styles.buttonIcon } />
            </div>
            <div style={ styles.buttonTitle }>
              { t('modules-user-ChooseTemplate-csv-detail') }
            </div>
          </div>
        </a>

        <div style={ {...styles.subTitle, ...{marginTop: 30} } }>
          { t('modules-user-ChooseCSVExport-title') }
        </div>
          <div style={ styles.button } onClick={ this.handleListUserExport }>
            <div>
              <IconDevices style={ styles.buttonIcon } />
            </div>
            <div style={ styles.buttonTitle }>
              { t('modules-user-ChooseCSVExport-ExportDevices-descr') }
            </div>
          </div>
      </Dialog>
    );
  }
}

export default ChooseTemplate;
