import React, { Component, PropTypes } from 'react';
import { translate } from 'react-i18next';

import { Table, TableBody, TableHeader, TableRow, TableFooter } from 'material-ui/Table';

import DataProvider from './DataProvider';
import * as constants from './DataProvider';

import { TableRowColumnText, TableHeaderColumnSort } from '../../components/table/TableCells'
import TableFooterPagination from '../../components/table/TableFooterPagination';

import { getTableStyles } from '../../components/styles.js';
require('../../components/DateExtensions');

const styles = {
  root: {
    padding: 7
  },
  tablePlaceHolder: {
    color: '#7B98AB'
  },
  button: {
    float: 'right'
  },
  tableHeaderRow: {
    height: '60px',
    verticalAlign: 'bottom',
    background: 'linear-gradient(to bottom, #00375D 0%,#00375D 100%)'
  },
  countColumnHeader: {
    width: '128px'
  },
  countColumnRow: {
    width: '96px'
  }
}

const tableStyles = getTableStyles();

export class FreeLicenseList extends Component {
  constructor(props) {
    super(props);
    this.dataprovider = new DataProvider();
    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      placeHolder: {},
      sortColumnName: constants.KEY_VOUCHER_COUNT,
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
  }
  /**
  * Callback fuer sortier-buttons
  */
  handleSortTableButtonClicked(index) {

    var sortColumnName;
    switch (index) {
      case 2: {
        sortColumnName = constants.KEY_EXPIRE_DATE;
        break;
      }
      default: {
        sortColumnName = constants.KEY_VOUCHER_COUNT;
      }
    }

    if (this.state.sortColumn === index) {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        sortColumnName: sortColumnName,
        data: this.props.dataProvider.getFreeVoucherSorted(sortColumnName, newDirection),
      });

    } else {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        sortColumnName: sortColumnName,
        data: this.props.dataProvider.getFreeVoucherSorted(sortColumnName, newDirection),
      });
    }
  }

  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    }
    return false;
  }

  render() {
    const { t } = this.props;

    var data = this.state.data;
    if (typeof data === "undefined" || data.length === 0) {
      if (this.props.dataProvider.dataFreeVoucher !== "undefined" && this.props.dataProvider.dataFreeVoucher.length > 0) {
        data = this.props.dataProvider.getFreeVoucherSorted(this.state.sortColumnName, this.state.sprtDirection)
      }
    }

    let dataPaginated = this.refs.pagination && this.refs.pagination.getPaginatedData(data);
    //let data = this.dataprovider.getFreeVoucherSorted(constants.KEY_LICENSE, 0);

    //debugger
    return (
      <div style={ styles.root }>
        <Table
          onRowSelection={ this.handleOnTableRowSelection }
          selectable={ false }
        >
          <TableHeader
            displaySelectAll={ false }
            adjustForCheckbox={ false }
            style={ tableStyles.tableHeader }
          >
            <TableRow style={ tableStyles.tableHeaderRow }>
              <TableHeaderColumnSort
                index={ 1 }
                sortColumn={ this.state.sortColumn }
                sortDirection={ this.state.sortDirection }
                text={ t('modules-license-License-table-column-count') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                style={ styles.countColumnRow } />
              <TableHeaderColumnSort
                index={ 2 }
                sortColumn={ this.state.sortColumn }
                sortDirection={ this.state.sortDirection }
                text={ t('modules-license-License-table-column-expireDate') }
                onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
              />
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={ false }
            style={ tableStyles.tableBody }
          >
            { dataPaginated && dataPaginated.map((item, i) => <TableRow key={ i } value={ item } displayBorder={ true } style={ tableStyles.tableRow }>
                                                                <td width="7px" height="40px" />
                                                                <TableRowColumnText text={ item.VoucherGroup.voucherCount + " Lizenzen" } style={ styles.countColumnRow } />
                                                                <TableRowColumnText text={ new Date().parseUtcDate(item.VoucherGroup.validTill).getShortDate() } />
                                                              </TableRow>
              ) }
          </TableBody>
          <TableFooter style={ tableStyles.tableFooter}>
            <TableFooterPagination ref="pagination" data={ data } parent={ this } />
          </TableFooter>
        </Table>
      </div>
      );
  }
}

FreeLicenseList.propTypes = {
  dataProvider: PropTypes.object.isRequired
}

export default translate(['common'])(FreeLicenseList);
