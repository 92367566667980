import React, { Component } from 'react';
import { translate } from 'react-i18next';

import { Table, TableBody, TableHeader, TableRow, TableRowColumn, TableHeaderColumn, TableFooter } from 'material-ui/Table';
import { TableRowColumnText, TableHeaderColumnSort, TableRowColumnKeywords } from '../../../components/table/TableCells'
import TableFooterPagination from '../../../components/table/TableFooterPagination';
import IconDeleteWhite from '../../../ressources/icon_delete_white';
import IconCheck from '../../../ressources/icon_check';
import IconCheckFilled from '../../../ressources/icon_check_filled';
import IconButton from 'material-ui/IconButton';
import { getTableStyles } from '../../../components/styles.js';
import { IsNullOrEmpty } from '../../../components/StringExtensions'

const styles = {
  headerStyleNoBorder: {
    borderBottomWidth: '1px',
    borderBottomColor: '#00375D',
    borderBottomStyle: 'solid',

  },
  tableColumnUserState: {
    width: '28px',
  },
  tableColumnRoomWriter: {
    width: 'auto',
  },
  userStatusHeaderStyle: {
    margin: 'auto',
    display: 'block',
  },
}

const tableStyles = getTableStyles();
const USERLIST_ASSIGNED_USER = 1;
const USERLIST_AVAILABLE_USER = 2;

class UserList extends Component {

  constructor(props) {
    super(props);

    this.dataprovider = props.dataprovider;
    this.state = {
      sortColumn: 1,
      sortDirection: 0,
      tabledata: this.dataprovider.getDataSorted(1, 0),
      roomWriterDataProvider: this.props.roomWriterDataProvider,
      viewMode: this.props.viewMode,
      searchText: '',
    };
    this.handleSortTableButtonClicked = this.handleSortTableButtonClicked.bind(this);
    this.handleDataproviderCallback = this.handleDataproviderCallback.bind(this);
    this.dataprovider.addCallback(this.handleDataproviderCallback);
    this.handleOnTableRowDelete = this.handleOnTableRowDelete.bind(this);
    this.handleOnTableRowChecked = this.handleOnTableRowChecked.bind(this);
    this.handleOnSetRoomWriter = this.handleOnSetRoomWriter.bind(this);
    this.checkkeyword = this.checkkeyword.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let {searchText} = nextProps;
    if (searchText !== this.state.searchText)
    {
      this.setState({
        searchText: searchText,

      });
      this.refs.pagination && this.refs.pagination.forcePaginationToStart();
    }
  }

  handleSortTableButtonClicked(index) {
    if (this.state.sortColumn === index)
    {
      let newDirection = (this.state.sortDirection + 1) % 2;
      this.setState({
        sortDirection: newDirection,
        tabledata: this.dataprovider.getDataSorted(index, newDirection),
      });

    }
    else
    {
      let newDirection = 0;
      this.setState({
        sortColumn: index,
        sortDirection: newDirection,
        tabledata: this.dataprovider.getDataSorted(index, newDirection),
      });
    }
  }


  handleDataproviderCallback() {
    this.setState({
      tabledata: this.dataprovider.getDataSorted(this.state.sortColumn, this.state.sortDirection),
    });
  }

  handleOnTableRowDelete(user) {
    if (typeof this.props.onTableRowDelete === 'function')
    {
      this.props.onTableRowDelete(user);
    }
  }
  handleOnTableRowChecked(user) {
    if (this.dataprovider.isSelected(user))
    {
      this.dataprovider.unsetSelectedUser(user);
    }
    else
    {
      this.dataprovider.setSelectedUser(user);

    }
    if (typeof this.props.onTableRowSelection === 'function')
    {
      this.props.onTableRowSelection(user);
    }
  }

  handleOnSetRoomWriter(user) {
    var roomWriter = this.state.roomWriterDataProvider;
    if (roomWriter.isRoomWriter(user))
    {
      roomWriter.removeRoomWriter(user);
    }
    else
    {
      roomWriter.addRoomWriter(user);
    }
    this.setState(roomWriter);
    //Wird benötigt, damit das Setzen / Entfernen eines Schreibrechts
    //als "PendingChange" weitergegeben werden kann
    if (typeof this.props.onRoomWriterUpdate === 'function')
    {
      this.props.onRoomWriterUpdate(roomWriter);
    }
  }

  renderFirstColumn(user) {
    if (this.state.viewMode === USERLIST_ASSIGNED_USER)
    {
      return <TableRowColumn style={ styles.tableColumnUserState }>
               <IconButton style={ styles.userStatusHeaderStyle } onClick={ (event) => this.handleOnTableRowDelete(user) }>
                 <IconDeleteWhite />
               </IconButton>
             </TableRowColumn>;
    }
    else if (this.dataprovider.isSelected(user))
    {
      return <TableRowColumn style={ styles.tableColumnUserState }>
               <IconButton style={ styles.userStatusHeaderStyle } onClick={ (event) => this.handleOnTableRowChecked(user) }>
                 <IconCheckFilled />
               </IconButton>
             </TableRowColumn>;
    }
    else
    {
      return <TableRowColumn style={ styles.tableColumnUserState }>
               <IconButton style={ styles.userStatusHeaderStyle } onClick={ (event) => this.handleOnTableRowChecked(user) }>
                 <IconCheck />
               </IconButton>
             </TableRowColumn>;
    }

  }

  renderRoomWriterColumn(user) {
    var roomWriter = this.state.roomWriterDataProvider;
    if (typeof roomWriter !== 'undefined' && roomWriter.isRoomWritersCapable())
    {
      if (this.state.roomWriterDataProvider.isRoomWriter(user))
      {
        return <TableRowColumn style={ styles.tableColumnRoomWriter }>
                 < IconButton style={ styles.userStatusHeaderStyle } onClick={ (event) => this.handleOnSetRoomWriter(user) }>
                   <IconCheckFilled />
                   </IconButton>
               </TableRowColumn>;

      }
      else
      {
        return <TableRowColumn style={ styles.tableColumnRoomWriter }>
                 < IconButton style={ styles.userStatusHeaderStyle } onClick={ (event) => this.handleOnSetRoomWriter(user) }>
                   <IconCheck />
                   </IconButton>
               </TableRowColumn>;
      }
    }
    return null;
  }

  renderRoomWriterHeaderColumn() {
    const {t} = this.props;
    var roomWriter = this.state.roomWriterDataProvider;
    if (typeof roomWriter !== 'undefined' && roomWriter.isRoomWritersCapable())
    {
      return <TableHeaderColumnSort index={ 4 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-roomWriterHeader') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
             />
    }
    return null;
  }

  handleSelectAll() {
    this.getFilteredUserList().filter((user) => this.handleOnTableRowChecked(user))
  }

  renderSelectAllHeaderColumn() {
    // const {t} = this.props;
    /* onClick={ (event) => this.handleOnTableRowCheckAll() }*/
    //style={ styles.tableColumnUserState } text="all" onClickCallback={ this.handleSelectAll.bind(this) }
    if (this.state.viewMode === USERLIST_AVAILABLE_USER)
    {
      return <TableHeaderColumn style={ { width: '28px' } }>
               <a href="" onClick={ this.handleSelectAll.bind(this) }>☐/☑</a>
             </TableHeaderColumn>;
    }

    return null;
  }

  checkkeyword(keyword) {
    return keyword.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0;
  }

  getFilteredUserList() {
    if (!IsNullOrEmpty(this.state.searchText))
    {
      let searchtextLower = this.state.searchText.toLowerCase();
      let filteredUsers = this.state.tabledata.filter((user) => user.firstName.toLowerCase().indexOf(searchtextLower) >= 0 ||
        user.name.toLowerCase().indexOf(searchtextLower) >= 0
        || (user.keywords.find(this.checkkeyword))
      )
      return filteredUsers;
    }
    return this.state.tabledata;
  }

  render() {
    const {t} = this.props;
    let data = this.getFilteredUserList();
    let dataPaginated = this.refs.pagination && this.refs.pagination.getPaginatedData(data);

    return <Table selectable={ false }>
             <TableHeader displaySelectAll={ false } adjustForCheckbox={ false }>
               <TableRow displayBorder={ false } style={ styles.headerStyleNoBorder }>
                 { this.renderSelectAllHeaderColumn() }
                 <TableHeaderColumnSort index={ 1 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column1') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                 />
                 <TableHeaderColumnSort index={ 2 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column2') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                 />
                 <TableHeaderColumnSort index={ 3 } sortColumn={ this.state.sortColumn } sortDirection={ this.state.sortDirection } text={ t('modules-user-UserList-table-column6') } onClickCallback={ this.handleSortTableButtonClicked.bind(this) }
                 />
                 { this.renderRoomWriterHeaderColumn() }
               </TableRow>
             </TableHeader>
             <TableBody deselectOnClickaway={ false } displayRowCheckbox={ false }>
               { dataPaginated && dataPaginated.map((user, i) => <TableRow key={ i } value={ user } selected={ this.dataprovider.isSelected(user) } displayBorder={ false }>
                                                                   { this.renderFirstColumn(user) }
                                                                   <TableRowColumnText text={ user.name } />
                                                                   <TableRowColumnText text={ user.firstName } />
                                                                   <TableRowColumnKeywords style={ this.props.CustomKeywordColumnStyle } keywords={ user.keyword } />
                                                                   { this.renderRoomWriterColumn(user) }
                                                                 </TableRow>
                 ) }
             </TableBody>
             <TableFooter style={ tableStyles.tableFooter}>
               <TableFooterPagination ref="pagination" data={ data } parent={ this } />
             </TableFooter>
           </Table>;
  }
}


export default translate(['common'], {
  withRef: true
})(UserList);
