import React, { Component } from 'react'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import App from './App'
import { translate } from 'react-i18next';


import Dashboard from './modules/dashboard/Dashboard';
import License from './modules/license/License';
import User from './modules/user/User';
import Groups from './modules/groups/Groups';
import Channel from './modules/channel/Channel';
import AppSettings from './modules/appsettings/AppSettings';
import AppDesign from './modules/appdesign/AppDesign';
import Settings from './Settings';
import InitialLogin from './initialLogin';
import Admin from './modules/settings/Admin';
import NotFoundPage from './NotFoundPage';

import { Router, Route, hashHistory } from 'react-router';

const store = configureStore()

class Root extends Component {
  render() {
    // const {t} = this.props;
    return (
      <Provider store={ store }>
        <Router history={ hashHistory }>
          <Route path="/initialLogin/(:data)" component={ InitialLogin } />
          <Route path="/" component={ App }>
            <Route path="/dashboard" component={ Dashboard } />
            <Route path="/license(/:mode)" component={ License } />
            <Route path="/user" component={ User } />
            <Route path="/groups" component={ Groups } />
            <Route path="/channel" component={ Channel } />
            <Route path="/appsettings" component={ AppSettings } />
            <Route path="/appdesign" component={ AppDesign } />
            <Route path="/settings" component={ Settings } />
            <Route path="/admin" component={ Admin } />
            <Route path='*' component={ NotFoundPage } />
          </Route>
        </Router>
      </Provider>
    )
  }
}
export default translate(['common'])(Root);
