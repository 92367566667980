import React, { Component } from 'react';

import { TableRowColumn, TableHeaderColumn } from 'material-ui/Table';
import { UserStateIcon } from '../../components/table/UserState';
import AdminStateTableHeader, { AdminStateIcon } from '../../components/table/AdminState';
import { validate } from '../../components/validation';

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconTabelleMore from '../../ressources/tabelle_more';
import FlatButton from '../buttons/FlatButton';
import IconTabelleSortUp from '../../ressources/tabelle_sort_up';
import Chip from 'material-ui/Chip';
import ToggleButton from 'material-ui/Toggle';
import SelectField from 'material-ui/SelectField';
import TextField from '../textfields//TextField';

import IconUserState from '../../ressources/alert-copy';
import IconOverlayCollapse from '../../ressources/collapse';
import IconOverlayExpand from '../../ressources/expand';
import CollapseContent from '../../ressources/collapseContent';
import IconPhone from '../../ressources/phone';
import IconMail from '../../ressources/mail';
import IconTag from '../../ressources/tag';


const styles = {

  tableCellText: {
    fontSize: '14px',
    paddingLeft: '16px',
    paddingRight: '16px',
    userSelect: 'none',
    textOverflow: 'none',
  },

  wrappedTableCellText: {
    fontSize: '14px',
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '35px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    width: '360px',
    userSelect: 'none'
  },
  disabledWrappedTableCellText: {
    fontSize: '14px',
    color: '#FFFFFF',
    opacity: "0.2",
    paddingLeft: '20px',
    paddingRight: '35px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    width: '360px',
    userSelect: 'none'
  },
  tableHeaderColumnUserStateExpand: {
    width: '407px',
    paddingLeft: '12px',
    paddingRight: '20px'
  },
  tableHeaderColumnUserStateCollapse: {
    width: '55px',
    paddingLeft: '12px',
    paddingRight: '20px'
  },
  tableColumnUserStateExpand: {
    width: '403px',
  },
  tableColumnUserStateCollapse: {
    width: '55px',
  },
  tableHeaderColumnAdminState: {
    width: '177px',
    //paddingLeft: '18px',
    //paddingRight: '6px'
    padding: '0px',
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  tableColumnAdminState: {
    width: '153px',
  },
  tableColumnMoreIcon: {
    width: '40px',
    textOverflow: 'inherit'
  },

  tableHeaderColumn: {
    paddingLeft: '12px',
    paddingRight: '20px'
  },

  tableSortLabel: {
    textAlign: 'left',
    fontSize: '14px',
    color: "#7B98AB",
    paddingLeft: '2px'
  },

  sortIcon: {
    margin: '0px'
  },
  sortIconDown: {
    margin: '0px',
    transform: 'rotate(180deg)',
  },
  tableCellDefault: {
    fontSize: '14px'
  },

  tableCellKeywords: {
    fontSize: '14px',
    width: '30%'
  },

  keywordList: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  chip: {
    margin: '4px',
    maxWidth: '100%',
    overflow: "hidden",
  },
  tableCellDescription: {
    fontSize: '12px',
    color: '#809BAE',
    letterSpacing: "0.38px",
    lineHeight: "16px",
    paddingRight: '80px',
    paddingLeft: '25px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: '400px',
  },
  tableRowColumnMoreIcon: {
    padding: 0,
    height: '40px'
  },
  disabledTableCellDescription: {
    fontSize: '12px',
    color: '#FFFFFF',
    opacity: "0.2",
    letterSpacing: "0.38px",
    lineHeight: "16px",
    paddingRight: '80px',
    paddingLeft: '25px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: '400px',
  },
  tableCellNumber: {
    fontSize: '20px',
    fontWeight: 'regular',
    color: '#EEC643',
    textAlign: 'left',
    width: '40px',
  },
  disabledTableCellNumber: {
    fontSize: '20px',
    fontWeight: 'regular',
    color: '#EEC643',
    opacity: "0.2",
    textAlign: 'left',
    width: '40px',
  },

  selectFieldCustomWidth: {
    width: '160px',
  },
  tableCellInputField: {
    width: '160px',
    padding: 0,
    margin: 0,
  },
  tableCellInputFieldDisabled: {
    width: '160px',
    padding: 0,
    margin: 0,
    color: "#FFFFFF",
    opacity: "0.5"
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "inline",
  },
  disabledUnderline: {
    borderColor: "#34393F",
  },
  overlayIcon: {
    position: 'absolute'
  },
  inactiveLabel: {
    color: '#FFFFFF',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  activeLabel: {
    color: '#EEC643',
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '14px'
  },
  menuListStyle: {
    backgroundColor: "#7B98AB"
  },
  selectedMenuItem: {
    backgroundColor: "#6C8392"
  }
}

export const sharedTableStyles = {
  headerStyle: {
    minHeight: '60px',
    height: '60px',
    background: 'linear-gradient(to bottom,#00375D, #00375D)',
    borderBottomWidth: '1px',
    borderBottomColor: '#00375D',
    borderBottomStyle: 'solid',

  },
  rowStyle: {
    borderBottomColor: '#737373',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px'
  }
}

export class TableRowColumnText extends Component {
  render() {
    let isExpanded = this.props.isExpand;

    if (typeof isExpanded === 'undefined')
    {
      isExpanded = true;
    }

    return (
      <TableRowColumn style={ Object.assign({}, styles.tableCellText, this.props.style) }>
        { isExpanded ? this.props.text : <CollapseContent /> }
      </TableRowColumn>);
  }
}

export class WrappedTableRowColumnText extends Component {
  render() {
    const isDisabled = this.props.disabled;
    return (
      <TableRowColumn style={ isDisabled ? styles.disabledWrappedTableCellText : styles.wrappedTableCellText }>
        { this.props.text }
      </TableRowColumn>);
  }
}

export class TableRowColumnDescriptionText extends Component {

  render() {
    const isDisabled = this.props.disabled;
    return (
      <TableRowColumn style={ isDisabled ? styles.disabledTableCellDescription : styles.tableCellDescription }>
        { this.props.text }
      </TableRowColumn>);
  }
}

export class TableRowColumnInput extends Component {
  state = {
    errorMessage: '',
    isInvalid: false,
    value: '',
  }
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
    //should always be valid -
    //we use it to "reset" the errorMessage
    this.validate(nextProps.value);
  }

  validate(value) {
    var validationErrors = validate(value, this.props.validations);

    var isInvalid = false;
    if (validationErrors.length > 0)
    {
      this.setState({
        errorMessage: validationErrors.pop()
      });
      isInvalid = true;
    }
    else
    {
      this.setState({
        errorMessage: ''
      });
    }
    return isInvalid;
  }

  handleChange(event, newValue) {
    var value = newValue;
    if (typeof this.props.type === "function")
    {
      value = this.props.type(newValue);
    }
    this.setState({
      value: value
    });
    if (typeof this.props.onChange === "function")
    {
      this.props.onChange(event, value);
    }

    let isInvalid = this.validate(value);

    if (typeof this.props.onValidation === "function")
    {
      this.props.onValidation(event, isInvalid);
    }
  }
  render() {
    return (
      <TableRowColumn style={ styles.tableCellInputField }>
        <TextField type="text" disabled={ this.props.disabled } value={ this.state.value } onChange={ this.handleChange }
                   errorText={ this.state.errorMessage }
                   style={ this.props.disabled ? styles.tableCellInputFieldDisabled : styles.tableCellInputField }
          underlineDisabledStyle={ styles.disabledUnderline } hintText={ this.props.hintText } />
      </TableRowColumn>
      );
  }
}

export class TableRowColumnNumber extends Component {
  render() {
    const isDisabled = this.props.disabled;
    return (
      <TableRowColumn style={ isDisabled ? styles.disabledTableCellNumber : styles.tableCellNumber }>
        { this.props.text }
      </TableRowColumn>)
  }
}

export class TableRowColumnToggle extends Component {
  state = {
    value: false,
    disabled: false,
  };
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);

  }

  updateState(props) {
    var defaultValue = props.defaultValue;
    this.setState({
      value: defaultValue
    });
    var disabledValue = props.disabled;
    this.setState({
      disabled: disabledValue
    });
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  handleToggle(event, newToggleValue) {
    this.setState({
      value: newToggleValue
    });
    this.props.onToggle(event, newToggleValue);
  }

  render() {
    return (
      <ToggleButton disabled={ this.state.disabled } style={ Object.assign(this.props.style, styles.selectFieldCustomWidth) } toggled={ this.state.value } onToggle={ this.handleToggle } thumbStyle={ { backgroundColor: 'white' } }
        trackStyle={ { backgroundColor: 'white' } } />)
  }
}

export class TableRowColumnValuePicker extends Component {
  state = {
    value: 0,
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  updateState(props) {
    if (props.defaultValue != null)
    {
      this.setState({
        value: props.defaultValue
      });
    }
    ;
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }
  /*  handleChange = (event, index, value) => {
      this.setState({value});
      this.props.onValueChange(event, index, value);
    }; */
  handleChange(event, index, value) {
    this.setState({
      value: value
    });
    this.props.onChange(event, value);
  }
  /* this.props.onMenuItemSelected(value); */

  render() {
    return (
      <SelectField disabled={ this.props.disabled } floatingLabelText={ this.props.hint } value={ this.state.value }
                   onChange={ this.handleChange } style={ styles.selectFieldCustomWidth }
                   listStyle={styles.menuListStyle} selectedMenuItemStyle={styles.selectedMenuItem}>
        { this.props.fields.map((fieldvalues, i) => <MenuItem key={ i } value={ fieldvalues.fieldvalue } primaryText={ fieldvalues.labelvalue } />) }
      </SelectField>)
  }
}

export class TableRowColumnUserState extends Component {

  render() {
    const {t, isExpand} = this.props;

    return (
      <TableRowColumn style={ Object.assign({}, isExpand ? styles.tableColumnUserStateExpand : styles.tableColumnUserStateCollapse, this.props.style) }>
        <UserStateIcon t={ t } state={ this.props.user.state } hasVoucher={ this.props.user.hasVoucher } alertState={ this.props.user.alertState } voucherExpired={ this.props.user.voucherExpired }
          isExpand={ isExpand } />
      </TableRowColumn>);
  }
}

export class TableRowColumnMoreIcon extends Component {

  getMenuList() {
    const {t} = this.props;
    if (this.props.menuEntries)
    {
      return this.props.menuEntries.map((entry, i) => <MenuItem key={ entry.id } primaryText={ t(entry.textId) } value={ this.props.value } style={ Object.assign({}, this.props.menuItemStyle) } />)
    }
  }

  render() {
    return (
      <TableRowColumn style={ Object.assign({}, styles.tableColumnMoreIcon, this.props.style) }>
        <IconMenu listStyle={ Object.assign({}, this.props.menuStyle) } iconButtonElement={ <IconButton style={ styles.tableRowColumnMoreIcon }>
                                                                                              <IconTabelleMore />
                                                                                            </IconButton> } anchorOrigin={ { horizontal: 'left', vertical: 'top' } } targetOrigin={ { horizontal: 'left', vertical: 'top' } } onItemTouchTap={ this.props.callBack }>
          { this.getMenuList() }
        </IconMenu>
      </TableRowColumn>
      );
  }
}

export class TableHeaderColumnSort extends Component {

  renderSortButton(index, sortColumn, text, onClickCallback) {
    let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    if (index === sortColumn)
    {
      return <FlatButton label={ text } style={ styles.tableSortLabel } labelStyle={ styles.tableSortLabel } secondary={ false } icon={ <IconTabelleSortUp style={ sortDirectionDown ? styles.sortIconDown : styles.sortIcon } /> } labelPosition="before"
               onClick={ () => onClickCallback(index) } />
    }

    return <FlatButton label={ text } style={ styles.tableSortLabel } labelStyle={ styles.tableSortLabel } secondary={ false } labelPosition="before" onClick={ () => onClickCallback(index) }
           />
  }

  render() {
    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableHeaderColumn, this.props.style) }>
        { this.renderSortButton(this.props.index, this.props.sortColumn, this.props.text, this.props.onClickCallback) }
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnEmailSort extends Component {

  renderSortButton(index, sortColumn, text, contentIsExpanded, onClickCallback) {
    let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!

    if (index === sortColumn)
    {
      return <FlatButton label={ contentIsExpanded ? text : '' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } icon={ <IconTabelleSortUp style={ sortDirectionDown ? styles.sortIconDown : styles.sortIcon } /> } labelPosition="before"
               onClick={ () => onClickCallback(index) } />
    }

    return <FlatButton label={ contentIsExpanded ? text : '' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } labelPosition="before" onClick={ () => onClickCallback(index) }
           />
  }

  render() {
    let contentIsExpanded = this.props.contentExpand;

    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableHeaderColumn, this.props.style) }>
        <div style={ { display: 'flex', flexFlow: 'row', alignItems: 'center' } }>
          { contentIsExpanded ? <IconOverlayCollapse /> : <IconOverlayExpand /> }
          { <IconMail style={ styles.overlayIcon } onClick={ () => this.props.onExpandClickCallback(contentIsExpanded, this.props.index) } /> }
          { this.renderSortButton(this.props.index, this.props.sortColumn, this.props.text, contentIsExpanded, this.props.onClickCallback) }
        </div>
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnPhoneSort extends Component {

  renderSortButton(index, sortColumn, text, contentIsExpanded, onClickCallback) {

    let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    if (index === sortColumn)
    {
      return <FlatButton label={ contentIsExpanded ? text : '' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } icon={ <IconTabelleSortUp style={ sortDirectionDown ? styles.sortIconDown : styles.sortIcon } /> } labelPosition="before"
               onClick={ () => onClickCallback(index) } />
    }

    return <FlatButton label={ contentIsExpanded ? text : '' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } labelPosition="before" onClick={ () => onClickCallback(index) }
           />
  }

  render() {
    let contentIsExpanded = this.props.contentExpand;

    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableHeaderColumn, this.props.style) }>
        <div style={ { display: 'flex', flexFlow: 'row', alignItems: 'center' } }>
          { contentIsExpanded ? <IconOverlayCollapse /> : <IconOverlayExpand /> }
          { <IconPhone style={ styles.overlayIcon } onClick={ () => this.props.onExpandClickCallback(contentIsExpanded, this.props.index) } /> }
          { this.renderSortButton(this.props.index, this.props.sortColumn, this.props.text, contentIsExpanded, this.props.onClickCallback) }
        </div>
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnUserState extends Component {
  renderSortButton(index, sortColumn, text, contentIsExpanded, onClickCallback) {
    let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    if (index === sortColumn)
    {
      return <FlatButton label={ contentIsExpanded ? text : ' ' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } icon={ <IconTabelleSortUp style={ sortDirectionDown ? styles.sortIconDown : styles.sortIcon } /> } labelPosition="before"
               onClick={ () => onClickCallback(index) } />
    }

    return <FlatButton label={ contentIsExpanded ? text : ' ' } style={ contentIsExpanded ? styles.tableSortLabel : {
                                                              minWidth: 0
                                                            } } labelStyle={ styles.tableSortLabel } secondary={ false } labelPosition="before" onClick={ () => onClickCallback(index) }
           />
  }

  render() {
    //this.renderSortButton(this.props.index, this.props.sortColumn)
    // let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    // let showSortIcon = this.props.index === this.props.sortColumn ? 1 : 0;
    let contentIsExpand = this.props.contentExpand;

    //onClick={ () => this.props.onClickCallback(this.props.index) }
    return (
      <TableHeaderColumn style={ Object.assign({}, contentIsExpand ? styles.tableHeaderColumnUserStateExpand : styles.tableHeaderColumnUserStateCollapse, this.props.style) }>
        <div style={ { display: 'flex', flexFlow: 'row', alignItems: 'center' } }>
          { contentIsExpand ? <IconOverlayCollapse /> : <IconOverlayExpand /> }
          <IconUserState style={ styles.overlayIcon } onClick={ () => this.props.onExpandClickCallback(contentIsExpand, this.props.index) } />
          { this.renderSortButton(this.props.index, this.props.sortColumn, this.props.text, contentIsExpand, this.props.onClickCallback) }
        </div>
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnMore extends Component {
  render() {
    return (
      <TableHeaderColumn style={ styles.tableColumnMoreIcon }>
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnDefault extends Component {
  render() {
    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableCellDefault, this.props.style) }>
        { this.props.text }
      </TableHeaderColumn>
      );
  }
}

export class TableHeaderColumnKeywords extends Component {
  render() {
    let contentIsExpanded = this.props.contentExpand;

    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableCellKeywords, this.props.style) }>
        <div style={ { display: 'flex', flexFlow: 'row', alignItems: 'center' } }>
          { contentIsExpanded ? <IconOverlayCollapse /> : <IconOverlayExpand /> }
          { <IconTag style={ styles.overlayIcon } onClick={ () => this.props.onExpandClickCallback(contentIsExpanded, this.props.index) } /> }
          <span style={ { paddingLeft: '5px', height: '36px' } }>{ contentIsExpanded ? this.props.text : ' ' }</span>
        </div>
      </TableHeaderColumn>
      );
  }
}

export class TableRowColumnKeywords extends Component {
  render() {
    let isExpanded = this.props.isExpand;

    if (typeof isExpanded === 'undefined')
    {
      isExpanded = true;
    }

    return (
      <TableRowColumn style={ Object.assign({}, styles.tableCellKeywords, this.props.style) }>
        <div style={ styles.keywordList }>
          { isExpanded ? (this.props.keywords && this.props.keywords.map((keyword, i) => <Chip key={ i } style={ styles.chip } backgroundColor="#7B98AB">
                                                                                           { keyword } </Chip>)) : <CollapseContent /> }
        </div>
      </TableRowColumn>
      );
  }
}

export class TableRowColumnAdminState extends Component {

  render() {
    const {t} = this.props;
    return (
      <TableRowColumn style={ Object.assign({}, styles.tableColumnAdminState, this.props.style) }>
        <AdminStateIcon t={ t } state={ this.props.admin.state } />
      </TableRowColumn>);
  }
}

export class TableHeaderColumnAdminState extends Component {
  renderSortButton(index, sortColumn, text, onClickCallback) {
    let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    if (index === sortColumn)
    {
      return <FlatButton label={ text } style={ styles.tableSortLabel } labelStyle={ styles.tableSortLabel } secondary={ false } icon={ <IconTabelleSortUp style={ sortDirectionDown ? styles.sortIconDown : styles.sortIcon } /> } labelPosition="before"
               onClick={ () => onClickCallback(index) } />
    }

    return <FlatButton label={ text } style={ styles.tableSortLabel } labelStyle={ styles.tableSortLabel } secondary={ false } labelPosition="before" onClick={ () => onClickCallback(index) }
           />
  }

  render() {
    //this.renderSortButton(this.props.index, this.props.sortColumn)
    // let sortDirectionDown = this.props.sortDirection === 1; //daher 0 als default!
    // let showSortIcon = this.props.index === this.props.sortColumn ? 1 : 0;
    //onClick={ () => this.props.onClickCallback(this.props.index) }
    return (
      <TableHeaderColumn style={ Object.assign({}, styles.tableHeaderColumnAdminState, this.props.style) }>
        <div style={ { display: 'flex', flexFlow: 'row', alignItems: 'center' } }>
          { this.renderSortButton(this.props.index, this.props.sortColumn, this.props.text, this.props.onClickCallback) }
          <AdminStateTableHeader />
        </div>
      </TableHeaderColumn>
      );
  }
}